import { Injectable } from '@angular/core';
import { WindowService } from '@zipari/web-services';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { APIService, ConfigService } from '../../../shared/services';

@Injectable({
  providedIn: 'root',
})
export class DocumentCenterService {
  tabsConfig: any;
  activeTabObj: any;
  constructor(
    private api: APIService,
    private configService: ConfigService,
    private window: WindowService
  ) {}

  getTargetRoute(route: ActivatedRoute): UrlSegment | undefined {
    const urlSegmentLength = route.snapshot?.['_urlSegment']?.segments?.length;

    if (urlSegmentLength >= 3) {
      return route.snapshot['_urlSegment'].segments[2];
    } else {
      this.window.nativeWindow.location.assign(
        `${this.window.nativeWindow.location.origin}/${this.configService.appRoute}/dashboard`
      );
    }
  }
}
