<div [formGroup]="form" [style.grid-area]="setGridPosition(config.grid)">
  <ng-container *ngIf="config.type === customFormElements.hidden">
    <hidden [group]="form" [config]="config"> </hidden>
  </ng-container>

  <ng-container *ngIf="config.type === customFormElements.brandColor">
    <brand-color [config]="config" [group]="form" [direction]="direction">
    </brand-color>
  </ng-container>

  <ng-container
    *ngIf="
      config.type === customFormElements.systemIcon ||
      config.type === customFormElements.productIcon
    "
  >
    <system-icon
      [config]="config"
      [group]="form"
      [direction]="direction"
      [set]="
        config.type === customFormElements.systemIcon ? 'system' : 'product'
      "
    >
    </system-icon>
  </ng-container>

  <ng-container *ngIf="config.type === customFormElements.termsAndConditions">
    <terms-and-conditions [config]="config" [group]="form">
    </terms-and-conditions>
  </ng-container>

  <ng-container *ngIf="config.type === customFormElements.autopay">
    <autopay [form]="form" [config]="config"> </autopay>
  </ng-container>

  <ng-container *ngIf="config.type === customFormElements.passwords">
    <passwords [config]="config" [group]="form"> </passwords>
  </ng-container>

  <ng-container *ngIf="config.type === customFormElements.whoseCovered">
    <whose-covered
      (formCreated)="formCreated.emit($event)"
      [group]="form"
      [direction]="direction"
      [config]="config"
    >
    </whose-covered>
  </ng-container>

  <ng-container *ngIf="config.type === customFormElements.industryGroup">
    <industries
      [formControlName]="config.prop"
      [direction]="direction"
      [config]="config"
    >
    </industries>
  </ng-container>

  <ng-container *ngIf="config.type === customFormElements.basicAddress">
    <basic-address [direction]="direction" [config]="config" [group]="form">
    </basic-address>
  </ng-container>

  <ng-container *ngIf="config.type === customFormElements.companyAddress">
    <company-address
      (addressValidationChange)="updateAddressForm($event)"
      [formControlName]="config.prop"
      [direction]="direction"
      [config]="config"
    >
    </company-address>
  </ng-container>

  <ng-container *ngIf="config.type === customFormElements.officeHours">
    <office-hours [direction]="direction" [config]="config" [group]="form">
    </office-hours>
  </ng-container>

  <ng-container *ngIf="config.type === customFormElements.formArray">
    <form-array
      [group]="form"
      [formArrayConfig]="config"
      [direction]="direction"
    >
    </form-array>
  </ng-container>

  <ng-container *ngIf="config.type === customFormElements.richText">
    <rich-text [group]="form" [config]="config" [direction]="direction">
    </rich-text>
  </ng-container>

  <div
    *ngIf="
      config.type === customFormElements.dropdownOther && form.get(config.prop)
    "
  >
    <dropdown-other [direction]="direction" [config]="config" [group]="form">
    </dropdown-other>
  </div>

  <div *ngIf="config.type === customFormElements.optionalDate">
    <optional-date [direction]="direction" [config]="config" [group]="form">
    </optional-date>
  </div>

  <div *ngIf="config.type === customFormElements.inputNA">
    <input-na
      [control]="form.get(config.prop)"
      [formControlName]="config.prop"
      [direction]="direction"
      [config]="config"
    >
    </input-na>
  </div>

  <div *ngIf="config.type === customFormElements.ssnMultiField">
    <ssn-multi-field
      [formControlName]="config.prop"
      [config]="config"
      [group]="form"
    >
    </ssn-multi-field>
  </div>

  <div *ngIf="config.type === customFormElements.speechRecognition">
    <speech-recognition
      [config]="config"
      [group]="form"
      [direction]="direction"
    ></speech-recognition>
  </div>

  <div #registeredComponentContainer></div>
</div>
