<section *ngFor="let data of dataList">
  <div class="link__list">
    <ng-container *ngIf="data.icon">
      <icon
        [name]="data.icon"
        [config]="{
          size: 'small',
          type: 'link'
        }"
      >
      </icon>
    </ng-container>

    <ng-container *ngIf="data.route">
      <a
        class="t-data t-bold t-link"
        [routerLink]="[data.route]"
        (click)="linkClicked(data)"
      >
        {{ data.label }}
      </a>
    </ng-container>

    <ng-container *ngIf="data.href">
      <a
        class="t-data t-bold t-link"
        href="{{ data.href }}"
        (click)="linkClicked(data, 'link')"
        [attr.target]="data.target ? data.target : '_blank'"
      >
        {{ data.label }}
      </a>
    </ng-container>
  </div>
</section>
