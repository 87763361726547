import { HttpClient } from '@angular/common/http';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneObject } from '@zipari/web-utils';
import { Subscription } from 'rxjs';

import { ProviderGroup } from '../../../shared/models/shared/ProviderGroup.model';
import { APIService, ConfigService } from '../../../shared/services';
import { ActionButtonSubNavConfig } from './provider-group-detail.model';

@Component({
  selector: 'provider-group-detail',
  templateUrl: './provider-group-detail.component.html',
  styleUrls: ['./provider-group-detail.component.scss'],
})
export class ProviderGroupDetailComponent implements OnInit, AfterViewChecked {
  busy: Subscription;
  config: any;
  dataLoaded: boolean = false;
  context: ProviderGroup;
  showConfirmModal: boolean = false;
  showActivateGroupModal: boolean;
  showSuspendGroupModal: boolean;
  activeModal;
  groupStatus: string;

  selectedTab: number = 0;
  currentTab: number = 0;
  editModal: boolean;
  openAccordionIdx: number = 0;
  editFields = new UntypedFormGroup({});
  error: boolean = false;
  editError: string;
  actionBtn: ActionButtonSubNavConfig;

  constructor(
    private configService: ConfigService,
    private api: APIService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.config = this.configService.getPageConfig(
      this.route.snapshot.data?.pageName
    );
    this.getGroupDetails();
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  /*
   * Submitting edit Modal
   */

  submitModal(ev): void {
    const endpoint: string = this.config.editModal.endpoint.replace(
      '{group_id}',
      this.context?.id
    );

    const payload = {};

    this.config.tabs.tabConfigs[
      this.currentTab
    ]?.editFlyout.formFields[0].controls.forEach((control) => {
      if (!control.hidden) {
        payload[control.prop] = this.editFields.value[control.prop];
      }
    });

    this.editError = '';
    this.api.patch(endpoint, payload).subscribe(
      () => {
        this.toggleEdit();
        this.getGroupDetails();
      },
      (e: any) => {
        this.editError = e.error?.errors[0].user_error.non_field_errors[0];
        this.error = true;
      }
    );
  }

  getGroupDetails(): void {
    this.busy = this.api
      .get(`${this.config.endpoints.group}/${this.route.snapshot.params.id}`)
      .subscribe((group: ProviderGroup) => {
        this.context = cloneObject(group);
        this.setActionBtnConfig();
        this.groupStatus = group.is_active ? 'active' : 'Suspended';
      });
  }

  setActionBtnConfig(): void {
    if (this.config?.actionButton) {
      this.actionBtn = cloneObject(this.config.actionButton);
      this.actionBtn.subnav.items = [];
      const listItems: any[] = this.config?.actionButton?.subnav?.items;
      const actionItem = this.context.is_active
        ? listItems.find((item) => item.prop?.action?.targetModal === 'suspend')
        : listItems.find(
            (item) => item.prop?.action?.targetModal === 'activate'
          );
      this.actionBtn.subnav.items.push(actionItem);
    }

    this.dataLoaded = true;
  }

  cancelModal(): void {
    this.showConfirmModal = false;
    this.showActivateGroupModal = this.showSuspendGroupModal = false;
  }

  confirmButtonClicked(): void {
    const endpoint: string = this.activeModal?.endpoint?.replace(
      '{group_id}',
      this.context?.id
    );
    const payload: {} = {};
    this.editError = '';

    this.api.patch(endpoint, payload).subscribe(
      () => {
        this.getGroupDetails();
        this.cancelModal();
      },
      (err: any) => {
        this.cancelModal();
        this.toggleError();
      }
    );
  }

  toggleError(): void {
    this.error = !this.error;
  }

  navigateSubNav(event): void {
    if (event.relative) {
      this.router.navigate([event.route], { relativeTo: this.route });
    }
    if (event.action) {
      if (event.action.openModal) {
        if (event.action.targetModal === 'suspend') {
          this.showSuspendGroupModal = !this.showSuspendGroupModal;
          this.activeModal = this.showSuspendGroupModal
            ? this.config.suspendGroupModal
            : null;
        } else {
          this.showActivateGroupModal = !this.showActivateGroupModal;
          this.activeModal = this.showActivateGroupModal
            ? this.config.activateGroupModal
            : null;
        }
      }
      // this.showConfirmModal = !this.showConfirmModal;
    }
  }

  handleTabSelected(event: any): void {
    this.currentTab = event;
  }

  setHeaderCSS(headerEditIcon: boolean): any {
    return {
      ['isCursor']: headerEditIcon,
    };
  }

  openEditModalIfNeeded(
    event,
    headerEditIcon: boolean,
    target,
    idx: number
  ): void {
    if (headerEditIcon) this.openEditModal(target, idx);
    else {
      event?.stopPropagation();
    }
  }

  openEditModal(target?: any, idx?: number): void {
    this.editModal = true;
    this.openAccordionIdx = idx;
  }

  toggleEdit(): void {
    this.editModal = !this.editModal;
  }

  isDisabled() {
    let isDisabledFlag = false;
    for (const key in this.context) {
      if (this.editFields?.value[key]) {
        if (this.context[key] === this.editFields?.value[key]) {
          isDisabledFlag = true;
        } else {
          isDisabledFlag = false;
          break;
        }
      }
    }

    return isDisabledFlag;
  }

  routeBack(): void {
    this.router.navigate(['provider-portal/payer-admin/provider-groups']);
  }
}
