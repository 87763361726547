<ng-container class="layout--detail" [zipBusy]="busy">
  <article class="layout--detail__content" *ngIf="claim && claimLineReady">
    <aside class="layout--detail__aside">
      <ng-container *ngFor="let section of config?.sidebar?.sections">
        <div [ngSwitch]="section.format">
          <header class="layout--detail__aside__header" *ngIf="section.label">
            <h6 role="heading" aria-level="1">{{ section.label }}</h6>
          </header>

          <section
            class="claim-detail__aside__attrs"
            *ngSwitchCase="'attribute-list'"
          >
            <model-attribute
              *ngFor="let attribute of section.attributes"
              [context]="activeClaimLine"
              [config]="attribute"
              direction="column"
            ></model-attribute>
          </section>
        </div>
      </ng-container>
    </aside>

    <main class="layout--detail__main">
      <header class="layout--detail__main__breadcrumb">
        <zip-button
          [config]="config.backButton"
          (zipButtonClicked)="routeBack()"
          content="Claim No. {{ claim.claim_number }}"
        ></zip-button>
      </header>

      <section class="claim-line__main__badges">
        <div class="claim-line__badge" *ngFor="let badge of config.badges">
          <badge class="u-fillRemaining" [config]="badge"></badge>
        </div>
      </section>

      <tab-view
        [config]="config.tabs"
        [data]="activeClaimLine"
        [selectedTab]="0"
      ></tab-view>
    </main>
  </article>
</ng-container>
