<ng-container>
  <form>
    <file-uploader-dropzone
      formControlName="dropzone"
      [disabled]="disabled"
      [fileUploadAlignment]="config?.fileUploadAlignment"
      [fileUploadText]="config?.fileUploadText"
      data-test-id="fileUploaderDropZone"
    >
      <file-uploader-button
        [disabled]="disabled"
        [fileUploadButton]="config?.fileUpload"
        data-test-id="fileUploaderButton"
      ></file-uploader-button>
    </file-uploader-dropzone>
  </form>
  <message-banner
    *ngIf="errorMessage"
    [config]="{
      icon: 'warning',
      message: errorMessage,
      type: 'error'
    }"
    data-test-id="errorMessage"
  ></message-banner>

  <message-banner
    *ngIf="uploadErrorMessage"
    data-test-id="uploadErrorMessage"
    [config]="errorMessageConfig"
  ></message-banner>
</ng-container>
<div
  class="FileUploader__AddDocumentation"
  *ngIf="config?.addDocumentationButton"
>
  <zip-button
    class="FileUpload__Button"
    (zipButtonClicked)="addDocumentationClicked()"
    data-test-id="addAttachmentButton"
    [disabled]="!disabled || disableAddAttachmentButton"
    [config]="config?.addDocumentationButton"
  >
  </zip-button>
</div>

<div class="u-flex">
  <file-uploader-progress
    class="u-maxX"
    (closeClicked)="clearFile($event)"
    [file]="file"
    [loaded]="loaded"
    [formArray]="fileUploadService.fileAttachment"
    [total]="progress"
    [showCloseIcon]="showCloseIcon"
    data-test-id="fileUploaderProgress"
  >
  </file-uploader-progress>
</div>
