import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule, FormControlModule } from '@zipari/design-system';
import { TableModule } from '../../../shared/modules';

import { PortalUsersListComponent } from './list/portal-users-list.component';

@NgModule({
  declarations: [PortalUsersListComponent],
  imports: [CommonModule, FormControlModule, ButtonModule, TableModule],
})
export class PortalUsersModule {}
