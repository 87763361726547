<svg
  *ngIf="icon.format === formats.svg; else fontIcon"
  class="Icon"
  [ngClass]="iconModifiers"
  [ngStyle]="{ fill: icon.fill }"
  aria-hidden="true"
  role="button"
>
  <title>{{ label || name + 'Icon' }}</title>
  <use [attr.xlink:href]="'#icon-' + name"></use>
</svg>

<ng-template #fontIcon>
  <i
    class="Icon Icon--font"
    [ngClass]="iconModifiers"
    [ngStyle]="{ color: icon.fill }"
  >
    {{ name }}
  </i>
</ng-template>
