<ng-container *ngIf="config">
  <div class="container">
    <section
      [ngStyle]="{ 'margin-bottom': row.marginBottom }"
      *ngFor="let row of rows"
      class="grid__row"
    >
      <div *ngFor="let col of row.columns" class="grid__column">
        <ng-container *ngFor="let line of col.lines">
          <ng-container [ngSwitch]="line.type">
            <!-- Image column line -->
            <ng-container *ngSwitchCase="types.image">
              <a
                *ngIf="line.route; else noRoute"
                [attr.href]="line.route"
                target="_blank"
              >
                <img
                  class="column__line--image"
                  [attr.title]="line.title"
                  [src]="line.source"
                  [ngStyle]="{
                    width: line.width,
                    height: line.height
                  }"
                />
                <img
                  class="column__line--image--mobile"
                  [attr.title]="line.title"
                  [src]="line.source"
                />
              </a>

              <ng-template #noRoute>
                <img
                  class="column__line--image--mobile"
                  [attr.title]="line.title"
                  [src]="line.source"
                />
                <img
                  class="column__line--image"
                  [attr.title]="line.title"
                  [src]="line.source"
                  [ngStyle]="{
                    width: line.width,
                    height: line.height
                  }"
                />
              </ng-template>
            </ng-container>

            <ng-container *ngSwitchCase="types.header">
              <div class="column__line--header">
                <h4>{{ line.label }}</h4>
              </div>
            </ng-container>

            <!-- column long text/paragraph line -->
            <ng-container *ngSwitchCase="types.longText">
              <div class="column__line__paragraphs">
                <ng-container *ngFor="let paragraph of line.paragraphs">
                  <p class="t-data" [innerHTML]="paragraph.text"></p>
                </ng-container>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="types.button">
              <div class="column__line__button">
                <zip-button
                  [config]="line.config"
                  (zipButtonClicked)="buttonClick(line)"
                ></zip-button>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="types.link">
              <div class="column__line">
                <a
                  class="t-data t-bold t-link"
                  [attr.href]="line.route"
                  target="_blank"
                  [innerHTML]="line.label"
                ></a>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </section>
  </div>
</ng-container>
