import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlService } from '../../../../notes/form-control/form-control.service';
import { BaseCVAComponent } from './../base-cva.component';

@Component({
  selector: 'hidden',
  templateUrl: './hidden.component.html',
  styleUrls: ['./hidden.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HiddenComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => HiddenComponent),
      multi: true,
    },
  ],
})
export class HiddenComponent
  extends BaseCVAComponent
  implements OnInit, OnDestroy
{
  @Input() group;
  @Input() config;

  /* istanbul ignore next */
  constructor(private formControlService: FormControlService) {
    super();
  }

  /* istanbul ignore next */
  ngOnInit() {
    this.formControlService.addControlToFormGroup(this.group, this.config, {
      [this.config.prop]: this.config.value,
    });
  }

  /* istanbul ignore next */
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
