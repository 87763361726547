import { Component, Input } from '@angular/core';

import { BannerConfiguration } from '@zipari/shared-ds-util-messages';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input() config: BannerConfiguration;
  @Input() reverse = false;

  constructor() {}
}
