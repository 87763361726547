export const createUserConstants = {
  manageAccess: 'Manage Access',
  accessRequestId: 'access_request_id',
  cards: 'cards',
  formConfig: 'formConfig',
  dropdown: 'dropdown',
  dropdownPropKey: 'dropdowns_api_populate_by_prop_key',
  zipari: 'zipari',
  okta: 'okta',
  userName: 'user_name',
  providerAdmin: 'Provider Admin',
  adminCreated: 'pp_provideradmin_created',
  role: 'role',
  officeStaff: 'Provider Office Staff',
  officeStaffCreated: 'pp_providerofficestaff_created',
  jobTitle: 'job_title',
  postMessage: 'Unable to contact servers. Please try again. ',
  profileStateProp: 'state',
  practiceStateProp: 'practice_state',
  accessRequestedFor: 'access_requested_for',
  accessRequested: 'access_requested',
  practiceTaxId: 'practice_tax_ids',
  groupId: 'group_id',
  multipleGroup: 'multiple_group',
  row: 'row',
  groups: 'groups',
  groupName: 'group_name',
  dropdownsApiPropKey: 'dropdownsApiPopulateByPropKey',
  emailAddress: 'Email address',
  id: 'id',
  groupIds: 'groupIds',
  auth0: 'auth0',
};

export const FormControlConstant = {
  required: 'required',
  invalid: 'INVALID',
};
