import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ButtonModule,
  FormControlModule,
  FormGroupModule,
  ModalModule,
  TableModule,
} from '@zipari/design-system';
import { SiteLocationAndPractitionerComponent } from './site-location-and-practitioner.component';
import { ProviderSelectionCardComponent } from './provider-selection-card/provider-selection-card.component';
import { ProviderSelectionPopupComponent } from './provider-selection-popup/provider-selection-popup.component';
import { ProviderFlyoutComponent } from './provider-flyout/provider-flyout.component';

@NgModule({
  declarations: [
    SiteLocationAndPractitionerComponent,
    ProviderSelectionCardComponent,
    ProviderSelectionPopupComponent,
    ProviderFlyoutComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    ModalModule,
    TableModule,
    FormGroupModule,
    FormControlModule,
  ],
  exports: [
    SiteLocationAndPractitionerComponent,
    ProviderSelectionCardComponent,
    ProviderSelectionPopupComponent,
    ProviderFlyoutComponent,
  ],
})
export class SiteLocationAndPractitionerModule {}
