import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'equalityFilter',
})
export class EqualityFilterPipe implements PipeTransform {
  transform(
    list: any[],
    prop: string,
    value: string | number | boolean,
    notEqual = false
  ): any[] {
    if (!list) {
      return [];
    }

    if (!prop) {
      return list;
    }

    return notEqual
      ? list.filter((item) => item[prop] !== value)
      : list.filter((item) => item[prop] === value);
  }
}
