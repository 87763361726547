import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AccordionModule,
  ButtonModule,
  CardModule,
  ExpandingArticleModule,
  FormGroupModule,
  IconModule,
  MessageBannerModule,
  ModalModule,
  ModelAttributeModule,
  PaginatorModule,
  SpinnerModule,
  TableModule,
} from '@zipari/design-system';
import { UiAdditionalDetailModalModule } from '@zipari/provider-portal/ui-additional-detail-modal';
import { SiteLocationsProfileComponent } from '../shared/modules/site-locations-profile/site-locations-profile.component';
import { SiteLocationsProfileModule } from '../shared/modules/site-locations-profile/site-locations-profile.module';
import { SiteLocationsProfileEditService } from '../shared/services/site-locations-profile-edit.service';
import { SiteLocationsProfileService } from '../shared/services/site-locations-profile.service';
import { SiteLocationsProfileHttpService } from '../shared/services/site-locations-profille-http.service';
import { DropdownArrayModule } from '../controls/dropdown-array/dropdown-array.module';
import { InputArrayModule } from '../controls/input-array/input-array.module';
import { ControlsGroupArrayModule } from '../controls/controls-group-array/controls-group-array.module';
import { PractitionersEditService } from '../shared/services/practitioners-edit.service';
import { AttestationWrapperComponent } from './attestation-wrapper/attestation-wrapper.component';
import { SiteLocationsListComponent } from './site-locations-list/site-locations-list.component';
import { PractitionersListComponent } from './practitioners-list/practitioners-list.component';
import { ConfirmSiteLocationAttestationComponent } from './confirm-site-location-attestation/confirm-site-location-attestation.component';
import { AttestationDynamicButtonComponent } from './attestation-wrapper/attestation-dynamic-button/attestation-dynamic-button.component';
import { SiteLocationsAlertComponent } from './site-locations-alert/site-locations-alert.component';

const routes: Routes = [
  {
    path: '',
    component: AttestationWrapperComponent,
    data: {
      pageName: 'site-locations-attestation',
    },
    children: [
      {
        path: 'locations',
        component: SiteLocationsListComponent,
        data: {
          pageName: 'siteLocationsList',
        },
      },
      {
        path: 'locations/:id',
        component: SiteLocationsProfileComponent,
        data: {
          pageName: 'siteLocationsReview',
        },
      },
      {
        path: 'locations/:id/practitioners',
        component: PractitionersListComponent,
        data: {
          pageName: 'reviewPractitioners',
        },
      },
      {
        path: 'confirmation',
        component: ConfirmSiteLocationAttestationComponent,
        data: {
          pageName: 'confirmSiteLocationAttestation',
        },
      },
      {
        path: '',
        redirectTo: 'locations',
      },
    ],
  },
];

@NgModule({
  declarations: [
    AttestationWrapperComponent,
    SiteLocationsListComponent,
    PractitionersListComponent,
    ConfirmSiteLocationAttestationComponent,
    AttestationDynamicButtonComponent,
    SiteLocationsAlertComponent,
  ],
  exports: [
    AttestationWrapperComponent,
    SiteLocationsListComponent,
    SiteLocationsAlertComponent,
    RouterModule,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    FormGroupModule,
    RouterModule.forChild(routes),
    CardModule,
    IconModule,
    ModelAttributeModule,
    PaginatorModule,
    SpinnerModule,
    SiteLocationsProfileModule,
    TableModule,
    ModalModule,
    AccordionModule,
    ExpandingArticleModule,
    MessageBannerModule,
    DropdownArrayModule,
    InputArrayModule,
    ControlsGroupArrayModule,
    UiAdditionalDetailModalModule,
  ],
  providers: [
    SiteLocationsProfileService,
    SiteLocationsProfileHttpService,
    SiteLocationsProfileEditService,
    PractitionersEditService,
  ],
})
export class SiteLocationAttestationModule {}
