import { Injectable, isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  log(message: string, message2?: string, isDev = true) {
    if (isDev && isDevMode()) {
      console.log(
        `${new Date().toISOString()}-${message} ${message2 ? message2 : ''}`
      );
    }
  }

  warn(message: string, message2?: string) {
    if (isDevMode()) {
      console.warn(
        `${new Date().toISOString()}-${message} ${message2 ? message2 : ''}`
      );
    }
  }

  error(error: Error | string, extraMessage = '') {
    let formattedError = error;

    if (typeof error === 'string') {
      formattedError = new Error(error);
    }

    if (isDevMode()) {
      if (extraMessage) {
        console.error(extraMessage, formattedError);
      } else {
        console.error(formattedError);
      }
    }
  }
}
