import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DropdownService } from '../../../shared/services/dropdown.service';
import {
  defaultDropdownPlaceholderText,
  DropdownConfiguration,
} from '@zipari/shared-ds-util-form';

const requireAtLeastTwoOptions = 2;

@Component({
  selector: 'control-dropdown',
  styleUrls: ['./dropdown.component.scss'],
  templateUrl: './dropdown.component.html',
})
export class ControlDropdownComponent implements OnInit, OnChanges {
  @Input() config: DropdownConfiguration;
  @Input() control: UntypedFormControl;
  @Input() selectedIndex: number;

  oneOptionNotIncludingPlaceholder = requireAtLeastTwoOptions;

  constructor(private dropdownService: DropdownService) {}

  public get isDisabled() {
    return this.config.isDisabled || this.control.disabled;
  }

  ngOnInit(): void {
    this.intializeOptions();
  }

  intializeOptions() {
    this.undefinedToNull();
    this.setPlaceholder();

    if (
      this.config.defaultValue === 'true' &&
      this.config.options?.length > 0
    ) {
      this.control.setValue(this.config.options[0].value);
    } else if (this.config.defaultValue?.length > 0) {
      this.control.setValue(this.config.defaultValue);
    }
  }

  /** Accessibility and display */
  undefinedToNull() {
    if (this.control.value === undefined) this.control.setValue(null);
  }

  setPlaceholder() {
    const options = this.config?.options;

    if (
      options &&
      Object.isExtensible(this.config) &&
      // This is actually necessary - otherwise it checks falsy, not "present and falsy"
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
      this.config.usePlaceholder !== false
    ) {
      const noneAreNull = !options?.some((option) => option.value === null);
      const hasOneOption = options.length === 1;
      const hidePlaceholderIfOneOption =
        !!this.config.hidePlaceholderIfOneOption && hasOneOption;
      const ifOneOptionShowStaticText =
        !!this.config.ifOneOptionShowStaticText && hasOneOption;
      const addPlaceholder =
        noneAreNull &&
        !(hidePlaceholderIfOneOption || ifOneOptionShowStaticText);

      if (addPlaceholder) {
        const placeholder =
          this.config.placeholder || defaultDropdownPlaceholderText;
        const placeholderOption = {
          label: placeholder,
          value: null,
        };
        this.config = {
          ...this.config,
          options: this.config.putPlaceholderDefaultAsLastItem
            ? [...options, placeholderOption]
            : [placeholderOption, ...options],
        };
      }
    }
  }

  hideOrShowOptions() {
    return (
      this.config.hideIfOnlyOneOption &&
      this.config.options.length > this.oneOptionNotIncludingPlaceholder
    );
  }

  onOptionSelected(event, config) {
    this.dropdownService.announceChange({ event: event, config: config });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config) {
      this.intializeOptions();
    }
  }
}
