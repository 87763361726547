export enum ModalTypes {
  popup = 'popup',
  flyout = 'flyout',
}

export enum ModalSizes {
  small = 'small',
  large = 'large',
  extraLarge = 'extra-large',
  full = 'full',
}

export enum ModalDirections {
  right = 'right',
  left = 'left',
  center = 'center',
  bottom = 'bottom',
}

export enum ModalTitleDirections {
  left = 'left',
  center = 'center',
}

export class ModalHeader {
  title? = '';
  icon? = '';
  closeIcon?: string;
  description?: string;
  showClose? = true;
  showBack?: boolean = true;
  alignTitle?: ModalTitleDirections;
  constructor(options: any) {
    Object.assign(this, options || {});
  }
}

// handle defaulting the direction
const validModalDirectionCombo = {
  [ModalTypes.flyout]: {
    right: true,
    left: true,
    bottom: true,
    none: true,
    '': false,
  },
  [ModalTypes.popup]: {
    center: true,
    '': false,
  },
};

const defaultedModalDirections = {
  [ModalTypes.flyout]: ModalDirections.right,
  [ModalTypes.popup]: ModalDirections.center,
};

export class ModalConfig {
  type: ModalTypes;
  size?: ModalSizes = ModalSizes.small;
  direction?: ModalDirections;
  backdrop? = true;
  clickOutside? = true;
  header: ModalHeader;
  altStyle? = false;
  isBelowNavigation?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  isConfirmed?: boolean;
  hidePageScroll?: boolean;
  partialWidth?: boolean;

  constructor(options?: any) {
    Object.assign(this, options || {});

    // handle defaulting the type
    this.type = options['type'] || ModalTypes.popup;
    // handle defaulting the header
    this.header = new ModalHeader(options['header'] || {});

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!validModalDirectionCombo[this.type][this.direction]) {
      if (this.direction) {
        console.warn(
          `Modal type ${this.type} does not support direction ${this.direction}`
        );
      }

      this.direction = defaultedModalDirections[this.type];
    }

    if (this.isConfirmed || this.isError) {
      this.header.showClose = false;
      this.header.showBack = false;
    }
  }
}

export const animationDirectionDefaults = {
  center: {
    fromX: '-50%',
    fromY: '-100%',
    toX: '-50%',
    toY: '-100%',
    time: '0ms',
    bezier: 'ease',
  },
  top: {
    fromX: 0,
    fromY: '-100%',
    toX: 0,
    toY: 0,
    time: '500ms',
    bezier: 'ease',
  },
  right: {
    fromX: '100%',
    fromY: 0,
    toX: 0,
    toY: 0,
    time: '500ms',
    bezier: 'ease',
  },
  bottom: {
    fromX: 0,
    fromY: '100%',
    toX: 0,
    toY: 0,
    time: '500ms',
    bezier: 'ease',
  },
  left: {
    fromX: '-100%',
    fromY: 0,
    toX: 0,
    toY: 0,
    time: '500ms',
    bezier: 'ease',
  },
  none: {
    fromX: 0,
    fromY: 0,
    toX: 0,
    toY: 0,
    time: '0ms',
    bezier: 'linear',
  },
};
