<mat-form-field
  appearance="outline"
  class="DatePicker"
  [ngClass]="{
    'DatePicker--Readonly': config.readonly,
    'DatePicker--Disabled': config.isDisabled
  }"
>
  <input
    matInput
    class="DatePicker__Input"
    [attr.aria-label]="config?.prop || config.type"
    [attr.disabled]="config?.isDisabled || null"
    [attr.aria-labelledby]="
      control.invalid ? 'error_' + dateRangeProp + '-input' : null
    "
    [attr.aria-invalid]="control.invalid"
    [readonly]="config?.readonly || null"
    [matDatepicker]="picker"
    [placeholder]="config?.placeholder || 'mm/dd/yyyy'"
    [formControl]="dummyControl"
    [min]="minDate$ | async"
    [max]="maxDate$ | async"
    [id]="config?.prop || config.type"
    (dateChange)="convertToISO('input', $event)"
  />
  <input type="hidden" [formControl]="control" />
  <mat-datepicker-toggle class="DatePicker__Button" matSuffix [for]="picker">
    <icon
      matDatepickerToggleIcon
      name="calendar"
      [config]="{ fill: iconFill }"
    ></icon>
  </mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
