import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../../notes/button/button.module';
import { GridLayoutComponent } from './grid-layout.component';

@NgModule({
  declarations: [GridLayoutComponent],
  imports: [CommonModule, ButtonModule],
  exports: [GridLayoutComponent],
})
export class GridLayoutModule {}
