import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { getValue } from '@zipari/web-utils';
import { camelCase } from '../../../../../shared/utilities/case-conversion.utils';
import {
  NoValue,
  ProviderDisplayFields,
  RemoveLable,
} from '../../../../templates/authorization-submission/authorization-submission.constants';
import {
  AddressLocationConfig,
  DisplayFieldsContext,
  OutOfNetworkConfig,
  ProviderMultiLocationModalConfig,
  ProviderSelectionConfig,
  ProviderSelectionModalConfig,
} from '../../../../templates/authorization-submission/authorization-submission.model';
import {
  PractitionerDetails,
  SiteLocation,
} from '../../../../templates/provider-update-tool/site-locations-attestation/site-locations-attestation.model';

@Component({
  selector: 'provider-selection-card',
  templateUrl: './provider-selection-card.component.html',
  styleUrls: ['./provider-selection-card.component.scss'],
})
export class ProviderSelectionCardComponent implements OnInit {
  @Input() config: ProviderSelectionConfig;
  @Input() group: UntypedFormGroup;
  @Input() providerSelectionModalConfig: ProviderSelectionModalConfig;
  @Input() providerMultiLocationModal: ProviderMultiLocationModalConfig;
  @Input() outOfNetwork: OutOfNetworkConfig;
  @Input() showOutOfNetworkStatus: boolean;

  providerDetail: SiteLocation | PractitionerDetails | null;
  shouldCloseProviderSelectionPopup: boolean = false;
  displayFieldsData: DisplayFieldsContext[];
  removeCard = RemoveLable;
  multiLocationSelectedAddress;

  @Input()
  set providerData(provider: SiteLocation | PractitionerDetails | null) {
    this.providerDetail = camelCase(provider);
  }

  ngOnInit(): void {
    this.group
      ?.get(this.config.providerInformation.prop)
      ?.setValue(this.providerDetail);
    this.updateDisplayFields(this.providerDetail);
  }

  addProviderOrLocation(): void {
    this.shouldCloseProviderSelectionPopup = true;
  }

  closeSelectionPopup(): void {
    this.shouldCloseProviderSelectionPopup = false;
  }

  getSelectedProvider(
    provider: SiteLocation | PractitionerDetails | null
  ): void {
    this.providerDetail = provider;
    const status =
      this.outOfNetwork?.isOutOfNetworkEnabled &&
      this.providerDetail?.memberNetwork &&
      !this.providerDetail?.memberNetwork?.isInNetwork;
    if (!status) {
      this.updateDisplayFields(this.providerDetail);
      this.group
        ?.get(this.config.providerInformation.prop)
        ?.setValue(this.providerDetail);
    }
  }

  updateDisplayFields(providerDetail): void {
    let updatedDisplayFields: [string, string][] = [];
    if (providerDetail) {
      providerDetail.providerId &&
        (updatedDisplayFields = Object.entries(
          this.config.providerFlyout?.displayFields
        ));
      providerDetail.facilityId &&
        (updatedDisplayFields = Object.entries(
          this.config.facilityFlyout?.displayFields
        ));
    }

    if (updatedDisplayFields.length > 0) {
      this.displayFieldsData = updatedDisplayFields.map((key) => ({
        label: ProviderDisplayFields[`${camelCase(key[0])}`],
        value: getValue(providerDetail, `${camelCase(key[1])}`)
          ? getValue(providerDetail, `${camelCase(key[1])}`)
          : NoValue,
      }));
    }
  }

  getSelectedAddress(selectedAddress: AddressLocationConfig): void {
    this.multiLocationSelectedAddress = selectedAddress;
    const status =
      this.outOfNetwork?.isOutOfNetworkEnabled &&
      this.providerDetail?.memberNetwork &&
      !this.providerDetail?.memberNetwork?.isInNetwork;
    if (!status) {
      this.providerDetail &&
        this.providerDetail.providerLocations &&
        (this.providerDetail.providerLocations = selectedAddress);
      this.updateDisplayFields(this.providerDetail);
      this.group
        ?.get(this.config.providerInformation.prop)
        ?.setValue(this.providerDetail);
    }
  }

  getOutOfNetwork() {
    this.providerDetail &&
      this.providerDetail.providerLocations &&
      (this.providerDetail.providerLocations =
        this.multiLocationSelectedAddress);
    this.updateDisplayFields(this.providerDetail);
    this.showOutOfNetworkStatus = true;
    this.group
      ?.get(this.config.providerInformation.prop)
      ?.setValue(this.providerDetail);
  }

  remove(): void {
    this.providerDetail = null;
    this.displayFieldsData = [];
    this.group?.get('multi_location')?.reset();
    this.group?.get(this.config.providerInformation.prop)?.reset();
    this.showOutOfNetworkStatus = false;
  }
}
