import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ButtonModule,
  CardModule,
  FormControlModule,
} from '@zipari/design-system';
import { PipeModule } from '../../../../shared/pipes/pipe.module';
import { SharedModule } from '../../../../shared/shared.module';
import { ClinicalReviewListModule } from '../clinical-review-list/clinical-review-list.module';
import { ClinicalReviewCardComponent } from './clinical-review-card.component';

@NgModule({
  declarations: [ClinicalReviewCardComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormControlModule,
    ButtonModule,
    SharedModule,
    PipeModule,
    CardModule,
    ClinicalReviewListModule,
  ],
  exports: [ClinicalReviewCardComponent],
})
export class ClinicalReviewCardModule {}
