import { UrlSerializer, UrlTree } from '@angular/router';

export function isObj(item: any): boolean {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function isEmptyObj<T extends object>(object: T): boolean {
  return Object.keys(object).length === 0 && object.constructor === Object;
}

/**
 * Handles getting a clone of an object that goes recursively down the structure and is faster than JSON.parse and
 * JSON.stringify also dynamically handles array objects as well.
 */
export function cloneObject(obj: any, justCopyProps = null): any {
  let clone: any;

  if (Array.isArray(obj)) {
    clone = obj.map((individualObj) =>
      cloneObject(individualObj, justCopyProps)
    );
  } else {
    if (typeof obj === 'object') {
      clone = {};
      for (const i in obj) {
        if (justCopyProps && justCopyProps[i]) {
          clone[i] = obj[i];
        } else if (obj[i] && typeof obj[i] === 'object') {
          clone[i] = cloneObject(obj[i], justCopyProps);
        } else {
          clone[i] = obj[i];
        }
      }
    } else {
      return obj;
    }
  }

  return clone;
}

export function deepCompare(obj: any, obj2: any, ignoreProps?: any): boolean {
  if (!obj || !obj2 || typeof obj !== 'object' || typeof obj2 !== 'object') {
    return false;
  }
  if (ignoreProps) {
    obj = cloneObject(obj);
    obj2 = cloneObject(obj2);
    for (const key in ignoreProps) {
      if (key) {
        delete obj[key];
        delete obj2[key];
      }
    }
  }

  return JSON.stringify(obj) === JSON.stringify(obj2);
}

/**
 * (zip-ui)
 * Deep merge two objects.
 * Arrays in target are overwritten by source
 * @param target
 * @param src
 */
export function deepMerge<T = object>(target: T, src: T): T {
  if (!target) {
    return src;
  }
  if (!isObj(target)) {
    return src;
  }

  let output: any = { ...target };

  if (isObj(target) && isObj(src)) {
    Object.keys(src as unknown as object).forEach((key) => {
      if (!isObj((<any>src)[key]) || !(key in (target as unknown as object))) {
        output = { ...output, [key]: (<any>src)[key] };
      } else {
        output[key] = deepMerge((<any>target)[key], (<any>src)[key]);
      }
    });
  }

  return output;
}

export function deepMergeIncludingNulls(target: any, src: any): object {
  if (!target) {
    return src;
  }
  if (!isObj(target)) {
    return src;
  }

  let output: any = { ...target };

  if (isObj(target) && isObj(src)) {
    const keys = Object.keys(src);

    keys.forEach((key) => {
      if (!isObj(src[key]) || !(key in target)) {
        if (src[key] === null) {
          output[key] = null;
        } else {
          output = { ...output, [key]: src[key] };
        }
      } else {
        output[key] = deepMerge(target[key], src[key]);
      }
    });
  }

  return output;
}

export function enumToArray(Enum: any) {
  return Object.keys(Enum).map((key) => ({ id: Enum[key], name: key }));
}

export function isInEnum(Enum: any, value: any): boolean {
  return Object.values(Enum).includes(value);
}

export function convertObjectToQueryParamString<T extends object>(
  options: T
): string {
  const params = new URLSearchParams();

  Object.entries(options).forEach(([key, value]) => {
    params.set(key, value);
  });

  return params.toString();
}

export function getNewUrlWithMergedQueryParams(
  urlSerializer: UrlSerializer,
  urlBase: string,
  queryParamsToAdd: any
): string {
  const url: UrlTree = urlSerializer.parse(urlBase);

  // merge query params
  const newQueryParams = Object.keys(queryParamsToAdd);

  newQueryParams.forEach((queryParam) => {
    url.queryParams[queryParam] = queryParamsToAdd[queryParam];
  });

  let formattedParams = '?';

  const paramKeys = Object.keys(url.queryParams);

  paramKeys.forEach((param, ind) => {
    if (ind !== 0) {
      formattedParams += '&';
    }

    formattedParams += `${param}=${url.queryParams[param]}`;
  });

  const strippedShoppingUrl = urlBase.split('?')[0];

  return `${strippedShoppingUrl}${formattedParams}`;
}

/**
 * Returns an object stripped of key/value pairs where the value is either null or undefined.
 * @param {object} obj - the object to be filtered
 * @returns {object} object w/o null or undefined values
 */
export function removeNullValues<T extends object>(obj: T): T {
  return Object.entries(obj)
    .map(([k, v]) => [k, v && typeof v === 'object' ? removeNullValues(v) : v])
    .reduce((a: any, [k, v]) => (v == null ? a : ((a[k] = v), a)), {}) as T; // double equals checks for "undefined"
}
