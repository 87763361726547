<article class="AttestationPage">
  <div class="AttestationPage__Content">
    <router-outlet></router-outlet>
  </div>
  <div
    class="AttestationPage__FooterContainer"
    *ngIf="config"
    [ngStyle]="{
      'justify-content':
        pageName !== 'confirmSiteLocationAttestation'
          ? 'space-between'
          : 'right'
    }"
  >
    <zip-button
      class="AttestationPage__FooterButton"
      *ngIf="pageName !== 'confirmSiteLocationAttestation'"
      [config]="config.cancelButton"
      (zipButtonClicked)="navigateToPreviousPage()"
    >
    </zip-button>
    <attestation-dynamic-button
      class="AttestationPage__FooterButton"
    ></attestation-dynamic-button>
  </div>
</article>
