import { Injectable } from '@angular/core';
import {
  SquidexConfig,
  SquidexDataService,
} from '@zipari/shared-data-access-squidex';
import { ConfigService } from './config.service';
@Injectable({
  providedIn: 'root',
})
export class SquidexService {
  constructor(
    public configService: ConfigService,
    private squidexDataService: SquidexDataService
  ) {}

  // call this before making any api call for any content to squidex
  initSquidex(): void {
    const squidexConfig: SquidexConfig =
      this.configService.getPageConfig<any>('global')?.contentManagementSystem
        ?.squidex;

    this.squidexDataService.initSquidex(squidexConfig);
  }
}
