import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  ToggleConfiguration,
  FormControlOptionConfiguration,
} from '@zipari/shared-ds-util-form';

@Component({
  selector: 'control-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ControlToggleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() config: ToggleConfiguration;
  @Input() control: UntypedFormControl;

  get isStrict() {
    if (this.config.strictAll) {
      return true;
    }

    if (this.config.strictIfRequired && this.config.validators) {
      return this.config.validators.some((val) => val === 'required');
    }

    return false;
  }

  value: any;
  controlSub: Subscription;

  ngOnChanges(changes) {
    if ('control' in changes) {
      this.controlSub = this.control.valueChanges.subscribe((val) => {
        this.value = val;
      });
    }
  }

  ngOnInit() {
    this.value = this.control.value;
  }

  onOptionClick(option: FormControlOptionConfiguration) {
    const keepValue =
      this.config.isDisabled ||
      this.control.disabled ||
      option.isDisabled ||
      (this.isStrict && this.value === option.value);

    if (!keepValue) {
      this.control.markAsTouched();
      this.control.markAsDirty();
      this.updateValue(option.value);
    }
  }

  getReadonlyStatus(config, option) {
    return config.readonly ||
      config.isDisabled ||
      option.isDisabled ||
      this.control.disabled
      ? config?.readonly || config.isDisabled || this.control.disabled
      : null;
  }

  ngOnDestroy() {
    if (this.controlSub) {
      this.controlSub.unsubscribe();
    }
  }

  updateValue(next) {
    this.value = this.value === next ? null : next;
    this.control.patchValue(this.value);
  }
}
