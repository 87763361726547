import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TableModule } from '@zipari/design-system';
import { DataTableComponent } from './data-table.component';

@NgModule({
  declarations: [DataTableComponent],
  exports: [DataTableComponent],
  imports: [CommonModule, TableModule],
})
export class DataTableModule {}
