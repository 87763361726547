import { Pipe, PipeTransform } from '@angular/core';
import { fullName } from '../utilities';

/*
    Pipe that will return try to find first and last name and return concatenated
    Entity could be e.g. member, provider, etc.
*/

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
  transform(entity: any, lastNameFirst = false): string {
    return fullName(entity, lastNameFirst);
  }
}
