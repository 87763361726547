/**
 * @deprecated use ButtonLevel union type instead
 */
export enum ButtonLevels {
  high = 'high',
  medium = 'medium',
  low = 'low',
  special = 'special',
  link = 'link',
  text = 'text',
  error = 'error',
  moderate = 'moderate',
  search = 'search',
}

export type ButtonLevel =
  | 'special'
  | 'high'
  | 'medium'
  | 'low'
  | 'link'
  | 'text'
  | 'error'
  | 'moderate'
  | 'search';

/**
 * @deprecated use ButtonSize union type instead
 */
export enum ButtonSizes {
  fit = 'fit',
  fullWidth = 'fullWidth',
}

export type ButtonSize = 'fit' | 'fullWidth';

export enum SubNavPositions {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

export type SubNavPosition = 'top' | 'bottom' | 'left' | 'right';

export class ButtonSubnavItem {
  label?: string;
  prop?: string;
  icon?: string;
}

export class ButtonSubnav {
  items?: ButtonSubnavItem[];

  constructor(options: ButtonSubnav = new ButtonSubnav({})) {
    Object.assign(this, options);
  }
}

export class ButtonConfig {
  action?: string;
  active?: boolean;
  content?: string;
  disabled?: boolean;
  icon?: string;
  iconSize?: string;
  id?: string;
  isIconRight?: boolean;
  isLink?: boolean;
  level?: ButtonLevels | ButtonLevel = 'text';
  openRouteInNewTab?: boolean;
  options?: object;
  route?: string;
  size?: ButtonSizes | ButtonSize = 'fit';
  hasSubnav?: boolean;
  subnav?: ButtonSubnav | null;
  menuPosition?: SubNavPositions | SubNavPosition = 'bottom';
  leftAlignedText?: boolean;
  isAriaDescriptionDisabled?: boolean;

  constructor(config: ButtonConfig = {}) {
    Object.assign(this, config);

    this.subnav = config.subnav ? new ButtonSubnav(config.subnav) : null;
  }
}
