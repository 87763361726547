import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModelAttributeModule } from '../../notes/model-attribute/model-attribute.module';
import { ModelAttributeGridComponent } from './model-attribute-grid.component';

@NgModule({
  declarations: [ModelAttributeGridComponent],
  imports: [CommonModule, ModelAttributeModule],
  exports: [ModelAttributeGridComponent],
})
export class ModelAttributeGridModule {}
