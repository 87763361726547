/**
 * Checks if a date is greater than another date
 * */
export function greaterThanDate(
  date1: string | number | null,
  date2: string | number | null
): boolean {
  const date1WithoutTimezone = getDateWithoutTimezoneOffset(date1);
  const date2WithoutTimezone = getDateWithoutTimezoneOffset(date2);

  // check condition
  return date1WithoutTimezone.getTime() > date2WithoutTimezone.getTime();
}

/**
 * Checks if a date is greater than another date or equal to that date
 * */
export function greaterThanOrEqualToDate(
  date1: string | number | null,
  date2: string | number | null
): boolean {
  const date1WithoutTimezone = getDateWithoutTimezoneOffset(date1);
  const date2WithoutTimezone = getDateWithoutTimezoneOffset(date2);

  // check condition
  return date1WithoutTimezone.getTime() >= date2WithoutTimezone.getTime();
}

/**
 * Checks if a date is less than another date
 * */
export function lessThanDate(
  date1: string | number | null,
  date2: string | number | null
): boolean {
  const date1WithoutTimezone = getDateWithoutTimezoneOffset(date1);
  const date2WithoutTimezone = getDateWithoutTimezoneOffset(date2);

  // check condition
  return date1WithoutTimezone.getTime() < date2WithoutTimezone.getTime();
}

/**
 * Checks if a date is less than another date or equal to that date
 * */
export function lessThanOrEqualToDate(
  date1: string | number | null,
  date2: string | null
): boolean {
  const date1WithoutTimezone = getDateWithoutTimezoneOffset(date1);
  const date2WithoutTimezone = getDateWithoutTimezoneOffset(date2);

  // check condition
  return date1WithoutTimezone.getTime() <= date2WithoutTimezone.getTime();
}

/**
 * get day of month
 * */
export function getDay(date: string | number | null): number {
  const dateWithoutTimezone = getDateWithoutTimezoneOffset(date);

  return dateWithoutTimezone.getDate();
}

/**
 * Retrieves the date after subtracting the offset that occurs from different timezones
 * If a date is not provided then it will retrieve the current date.
 * */
export function getDateWithoutTimezoneOffset(
  date: string | number | null = null
): Date {
  let newDate: Date;

  if (date) {
    newDate = new Date(`${date}T00:00:00`);
  } else {
    newDate = new Date();
  }

  return newDate;
}

/**
 * Returns a date formatted in YYYY-MM-DD
 * */
export function getFormattedDate(date: Date): string {
  const year: any = date.getFullYear();
  let month: any = date.getMonth() + 1;
  let day: any = date.getDate();

  if (month < 10) {
    month = `0${month}`;
  }

  if (day < 10) {
    day = `0${day}`;
  }

  return `${year}-${month}-${day}`;
}

/** Converts an ISO 8601 datetime to mm/dd/yyyy. */
export function mmDdYyyyFromIsoDatetime(isoDatetime: string): string {
  const TIME_DESIGNATOR = 'T';

  return isoDatetime.split(TIME_DESIGNATOR)[0].split('-').reverse().join('/');
}

/** determines how many days there are between two dates */
export function numDaysBetweenDates(
  d1: Date | string,
  d2: Date | string
): number {
  let date1;
  let date2;

  if (d1 instanceof Date) {
    date1 = d1;
  } else {
    date1 = new Date(d1);
  }

  if (d2 instanceof Date) {
    date2 = d2;
  } else {
    date2 = new Date(d2);
  }

  const diff = Math.abs(date1.getTime() - date2.getTime());

  return diff / (1000 * 60 * 60 * 24);
}

export function isValidDate(d: string): boolean {
  const monthChecks: Record<string, number> = {
    '01': 31,
    '02': 28,
    '03': 31,
    '04': 30,
    '05': 31,
    '06': 30,
    '07': 31,
    '08': 31,
    '09': 30,
    '10': 31,
    '11': 30,
    '12': 31,
  };

  const reg = new RegExp(
    /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/
  );

  const year = Number.parseInt(d.substr(0, 4), 10);
  const month = d.substr(5, 2);
  const day = d.substr(-2);

  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthChecks['02'] = 29;
  }

  return reg.test(d) && Number.parseInt(day, 10) <= monthChecks[month];
}

export function isValidMonthDate(d: string): boolean {
  const reg = new RegExp(/^(19|20)\d\d[- /.](0[1-9]|1[012])$/);

  return reg.test(d);
}

/**
 * Take a given date and increment it by X days
 * Returns date informatted string
 * @param input date to increment
 * @param inc how many days to increment
 * @param skipWeekend dont count weekends when incrementing
 */
export function incrementDate(
  input: string | number,
  inc: number,
  skipWeekend = false
): string {
  const date = getDateWithoutTimezoneOffset(input);

  if (!skipWeekend) {
    date.setDate(date.getDate() + inc);
  } else {
    let i = 0;

    while (i < inc) {
      date.setDate(date.getDate() + 1);

      const isWeekend = date.getDay() === 0 || date.getDay() === 6;

      if (!isWeekend) {
        ++i;
      }
    }
  }

  return getFormattedDate(date);
}

/**
 *  Take a given date and decrement it by X days
 * Returns date informatted string
 * @param input date to increment
 * @param inc how many days to increment
 * @param skipWeekend dont count weekends when incrementing
 */
export function decrementDate(
  input: string | number,
  inc: number,
  skipWeekend = false
): string {
  const date = getDateWithoutTimezoneOffset(input);

  if (!skipWeekend) {
    date.setDate(date.getDate() - inc);
  } else {
    let i = 0;

    while (i < inc) {
      date.setDate(date.getDate() - 1);

      const isWeekend = date.getDay() === 0 || date.getDay() === 6;

      if (!isWeekend) {
        ++i;
      }
    }
  }

  return getFormattedDate(date);
}

export const getDateOrNull = (date: string): string | null =>
  date && isValidDate(date) ? date : null;
