import { Pipe, PipeTransform } from '@angular/core';

/*
    Pipe that will format phone number string to inculde dashes
    e.g. 555-555-5555
 */

@Pipe({
  name: 'phoneNumber',
})
export class PhoneNumberPipe implements PipeTransform {
  transform(val: string): string {
    let phone: string;

    const areaCode = val.slice(0, 3);

    phone = val.slice(3);
    phone = `${phone.slice(0, 3)}-${phone.slice(3)}`;

    return `${areaCode}-${phone}`;
  }
}
