<div
  class="form-control__wrapper"
  [ngClass]="{
    'form-control__wrapper--inline': direction === 'row',
    'form-control__wrapper--flat': direction === 'filter'
  }"
>
  <label class="form-control__label">
    <p class="t-data t-bold">{{ config.label }}</p>
  </label>

  <div
    class="office-hours__group-controls"
    [ngClass]="{ 'u-fillRemaining': direction === 'row' }"
  >
    <ng-container *ngFor="let checkboxConfig of officeHourConfigs">
      <form-control
        direction="filter"
        [config]="checkboxConfig"
        [group]="daysOfWeekGroup"
        [control]="daysOfWeekGroup.controls[checkboxConfig.prop]"
      >
      </form-control>

      <ng-container *ngIf="currentDaysVal[checkboxConfig.prop]">
        <div class="office-hours__time-picker">
          <form-control
            [group]="group"
            [config]="timeConfig"
            direction="filter"
            [control]="
              group.controls[config.prop].controls[checkboxConfig.prop].controls
                .from
            "
          >
          </form-control>

          <p class="t-data">to</p>

          <form-control
            [group]="group"
            [config]="timeConfig"
            direction="filter"
            [control]="
              group.controls[config.prop].controls[checkboxConfig.prop].controls
                .to
            "
          >
          </form-control>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
