import { NgModule } from '@angular/core';
import { ManagePractitionerAddModule } from './practitioners/add-new/manage-practitioner-add.module';
import { ManagePractitionerDetailModule } from './practitioners/details/manage-practitioner-detail.module';
import { ManagePractitionerListModule } from './practitioners/list/manage-practitioner-list.module';
import { SiteLocationAttestationModule } from './site-locations-attestation/site-location-attestation.module';
import { ManageSiteLocationAddModule } from './site-locations/add-new/manage-site-location-add.module';
import { ManageSiteLocationDetailModule } from './site-locations/details/manage-site-location-detail.module';
import { ManageSiteLocationListModule } from './site-locations/list/manage-site-location-list.module';
@NgModule({
  imports: [
    ManageSiteLocationListModule,
    ManageSiteLocationDetailModule,
    ManageSiteLocationAddModule,
    ManagePractitionerListModule,
    ManagePractitionerDetailModule,
    ManagePractitionerAddModule,
    SiteLocationAttestationModule,
  ],
})
export class ProviderUpdateToolModule {}
