<ng-container
  *ngIf="
    providerDetail && config?.providerInformation?.showProviderDetail;
    else providerButton
  "
>
  <div class="Container">
    <div class="Remove__Link">
      <div
        class="Remove__Text"
        (click)="remove()"
        [innerHTML]="removeCard"
      ></div>
    </div>
    <div class="Card__Details">
      <div class="Card__Left--ProviderName">
        <label class="Card__Label">
          <p
            class="t-data t-bold"
            [innerHTML]="
              providerDetail.providerId
                ? providerDetail?.name?.fullName
                : providerDetail?.name
            "
          ></p>
        </label>

        <p
          *ngIf="showOutOfNetworkStatus"
          class="OutOfNetwork__StatusPill OutOfNetwork__StatusPill--Pending"
          [innerHTML]="outOfNetwork?.outOfNetworkLabel"
        ></p>
      </div>

      <div class="Card__Right--ProviderInformation">
        <ng-container *ngFor="let field of displayFieldsData">
          <label class="Card__Label" *ngIf="field?.label !== 'Name'">
            <p
              class="Label__Spacing t-data t-bold"
              [innerHTML]="field?.label + ':'"
            ></p>
            <p
              *ngIf="field.label !== 'Address'"
              class="t-data"
              [innerHTML]="field?.value"
            ></p>

            <ng-container
              *ngIf="field?.label === 'Address' && field.value; let address"
            >
              <p class="t-data" *ngIf="address?.id; else noAddressTemplate">
                {{ address?.streetName1 }}<br />
                {{ address?.streetName2 }}<br />
                {{ address?.cityName }},
                {{ address?.state }}
                {{ address?.zipCode }}
              </p>
            </ng-container>
            <ng-template #noAddressTemplate>
              <p class="t-data">n/a</p>
            </ng-template>
          </label>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #providerButton>
  <zip-button
    *ngIf="config?.providerInformation?.providerButton"
    [config]="config.providerInformation.providerButton"
    (zipButtonClicked)="addProviderOrLocation()"
  ></zip-button>
</ng-template>

<provider-selection-popup
  [config]="config"
  [group]="group"
  [shouldCloseProviderSelectionPopup]="shouldCloseProviderSelectionPopup"
  [providerSelectionModalConfig]="providerSelectionModalConfig"
  [providerMultiLocationModal]="providerMultiLocationModal"
  [outOfNetwork]="outOfNetwork"
  (closeProviderSelectionPopup)="closeSelectionPopup()"
  (selectedProvider)="getSelectedProvider($event)"
  (selectedAddress)="getSelectedAddress($event)"
  (isOutOfNetwork)="getOutOfNetwork()"
></provider-selection-popup>
