export const OutNetworkStatus = 'out';

export const claimDetailsMockConfig = {
  backButton: null,
  claimLineTab: null,
  financialOverviewTab: null,
  tabs: null,
  overviewTab: null,
  existingAppealsModal: null,
  actionButton: null,

  tabConfigs: null,
  sidebar: null,
  badges: null,
  outOfNetwork: {
    isOutOfNetworkEnabled: false,
    outOfNetworkLabel: 'out of network',
  },
};

export const CLAIM_APPEAL = {
  RECONSIDERATION_ROUTE: 'claim-reconsideration',
  REDETERMINATION_ROUTE: 'claim-redetermination',
  RECONSIDERATION: 'Reconsideration',
  REDETERMINATION: 'Redetermination',
};

export enum ClaimAppealType {
  reconsideration = 'Reconsideration',
  redetermination = 'Redetermination',
}

export const claimDetailConfigKey = 'claim_detail';

export const locationsUrl =
  '/api/provider-portal/update-tool/facility-locations/';
