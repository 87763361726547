import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { concat, of } from 'rxjs';

@Component({
  selector: 'dropdown-other',
  templateUrl: './dropdown-other.component.html',
  styleUrls: ['./dropdown-other.component.scss'],
})
export class DropdownOtherComponent implements OnInit, OnDestroy {
  @Input() config;
  @Input() group: UntypedFormGroup;
  @Input() direction: string;

  dropdownConfig;
  otherTextConfig;

  constructor() {}

  ngOnInit() {
    this.dropdownConfig = {
      ...this.config,
      type: 'dropdown',
    };

    this.otherTextConfig = {
      type: 'text',
      maxLength: 100,
      placeholder: this.config.label,
      prop: `${this.config.prop}_other`,
      validators: ['required'],
    };

    concat(
      of(this.group.get(this.config.prop).value),
      this.group.get(this.config.prop).valueChanges
    ).subscribe((value) => {
      if (value === 'other' || value === this.config.otherValue) {
        let otherValue = '';
        if (this.otherControl) {
          otherValue = this.otherControl.value;
        }
        this.group.addControl(
          this.otherTextConfig.prop,
          new UntypedFormControl(otherValue)
        );
      } else {
        if (this.otherControl) {
          this.group.removeControl(this.otherTextConfig.prop);
        }
      }
    });
  }

  public get otherControl(): UntypedFormControl {
    return <UntypedFormControl>this.group.get(this.otherTextConfig.prop);
  }

  ngOnDestroy() {}
}
