<modal
  *ngIf="
    (shouldOpenProviderFlyout || shouldOpenFacilityFlyout) && providerConfig;
    let modalConfig
  "
  [config]="providerConfig"
  [condition]="modalConfig"
  (cancel)="closeProviderFlyout()"
>
  <div body>
    <div class="Modal__SubHeader">
      <p [innerHTML]="providerConfig?.subheader"></p>
    </div>
    <zip-table
      *ngIf="loaded"
      (selectedRadioRow)="getSelectedProvider($event)"
      [options]="providerConfig?.table"
    >
    </zip-table>
  </div>
  <div footer>
    <zip-button
      level="high"
      [disabled]="!selectedProviderItem"
      [content]="providerConfig?.submitButton"
      (zipButtonClicked)="addSelectedProvider()"
    ></zip-button>
  </div>
</modal>

<modal
  *ngIf="showMultiLocationPopup && providerMultiLocationModal"
  [config]="providerMultiLocationModal"
  [condition]="showMultiLocationPopup"
  class="OutOfNetworkModal"
>
  <div body>
    <div class="Modal__SubHeader">
      <p [innerHTML]="providerMultiLocationModal?.subheader"></p>
    </div>
    <div>
      <form-control
        class="t-bold"
        [group]="group"
        [config]="{
          prop: 'multi_location',
          type: 'radio',
          options: locationOptions
        }"
        [control]="group.get('multi_location')"
      ></form-control>
    </div>
  </div>
  <div footer>
    <zip-button
      [disabled]="!selectedAddress"
      level="high"
      [content]="providerMultiLocationModal?.submitButton"
      (zipButtonClicked)="selectAddress()"
    ></zip-button>
  </div>
</modal>

<modal
  *ngIf="outOfNetwork?.isOutOfNetworkEnabled && showOutOfNetworkModal"
  [config]="outOfNetwork?.outOfNetworkModal?.outOfNetworkModalBody"
  [condition]="outOfNetwork?.isOutOfNetworkEnabled && showOutOfNetworkModal"
  (cancel)="closeOutOfNetworkModal()"
  class="OutOfNetworkModal"
>
  <div body>
    <div class="Modal__SubHeader">
      <p>
        {{ outOfNetwork.outOfNetworkModal?.outOfNetworkModalBody?.description }}
      </p>
    </div>
  </div>
  <div footer class="OutOfNetworkModal__Footer">
    <zip-button
      [config]="outOfNetwork.outOfNetworkModal?.footer?.cancelButton"
      (click)="closeOutOfNetworkModal()"
    ></zip-button>
    <zip-button
      [config]="outOfNetwork.outOfNetworkModal?.footer?.confirmButton"
      (click)="confirm()"
    ></zip-button>
  </div>
</modal>
