import { Directive, HostBinding, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[libGridCell]',
})
export class GridCellDirective {
  private _grid: any;
  @Input('libGridCell')
  private set grid(val: any) {
    this._grid = val || { col: '', row: '', justify: '' };
  }

  constructor(private sanitizer: DomSanitizer) {}

  @HostBinding('style.grid-row')
  public get gridRow() {
    return this.sanitizer.bypassSecurityTrustStyle(this._grid.row);
  }
  @HostBinding('style.grid-column')
  public get gridColumn() {
    return this.sanitizer.bypassSecurityTrustStyle(this._grid.col);
  }
  @HostBinding('style.justify-self')
  public get justifySelf() {
    return this.sanitizer.bypassSecurityTrustStyle(this._grid.justify);
  }
}
