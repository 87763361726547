import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BadgeConfig } from '@zipari/design-system';
import { Subscription } from 'rxjs';
import Claim from '../../../shared/models/shared/Claim.model';
import ClaimLine from '../../../shared/models/shared/ClaimLine.model';
import {
  APIService,
  ConfigService,
  FormattingService,
} from '../../../shared/services';
import { ApiListResponse } from '../../../shared/models/shared/ApiListResponse.model';

@Component({
  selector: 'claim-line',
  templateUrl: './claim-line-detail.component.html',
  styleUrls: ['./claim-line-detail.component.scss'],
})
export class ClaimLineDetailComponent implements OnInit {
  config;
  claim: Claim;
  claimLines: ClaimLine[];
  activeClaimLine: any;
  busy: Subscription;
  page: number = 1;
  claimLineReady: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private api: APIService,
    private router: Router,
    private configService: ConfigService,
    private formatService: FormattingService
  ) {}

  // /** Init Lifecycle get claim data **/
  ngOnInit(): void {
    this.config = this.configService.getPageConfig<any>('claim_line');
    this.getClaimData();
  }

  getClaimData(): void {
    this.route.params.subscribe((params: Params) => {
      this.api
        .get(`/api/provider-portal/claims/${params.claim_number}`)
        .subscribe((claim: Claim) => {
          this.claim = claim;
          this.getClaimLines(claim);
        });
    });
  }

  getClaimLines(claim: Claim): void {
    this.claimLineReady = false;
    this.busy = this.api
      .get(
        `/api/provider-portal/claims/${claim?.claim_number}/lines/?page=${this.page}`
      )
      .subscribe((claimLines: ApiListResponse<ClaimLine>) => {
        this.claimLines = claimLines.results;
        this.populateClaimLineData();
      });
  }

  routeBack(): void {
    let targetRoute: string;
    if (this.route.snapshot.params.id) {
      targetRoute = `provider-portal/patients/${this.route.snapshot.params.id}/claims/${this.route.snapshot.params?.claim_number}`;
    } else {
      targetRoute = `provider-portal/claims/${this.route.snapshot.params?.claim_number}`;
    }

    this.router.navigate([targetRoute]);
  }

  formatConfigValues(): void {
    if (this.config.badges) {
      this.config.badges = this.config.badges.map((badge: BadgeConfig) =>
        this.formatService.formatConfigValueAsTemplate(
          badge,
          this.activeClaimLine
        )
      );
    }
  }

  /** Populate Active Claim Object from Claim Object **/
  populateClaimLineData(): void {
    this.activeClaimLine = this.claimLines?.find(
      (line: ClaimLine) =>
        line.line_number?.toString() == this.route.snapshot.params.claim_line
    );

    if (!this.activeClaimLine) {
      this.page += 1;
      this.getClaimLines(this.claim);
    } else {
      this.activeClaimLine[
        'patient_name'
      ] = `${this.claim?.patient?.name?.first_name} ${this.claim?.patient?.name?.last_name}`;
      this.activeClaimLine['claim_number'] = this.claim?.claim_number;
      this.formatConfigValues();
      this.claimLineReady = true;
    }
  }
}
