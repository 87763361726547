<div class="u-flex u-alignCenter">
  <i *ngIf="!!column.icon" class="icon icon--medium">
    {{ column.icon }}
  </i>

  <ng-container [ngSwitch]="column.format">
    <a
      *ngSwitchCase="cellFormats.SUCCESS_ERROR"
      [ngClass]="
        _cellValue === true || _cellValue === column?.successText
          ? 'chip--success'
          : 'chip--error'
      "
      class="t-data chip"
      tabindex="0"
    >
      {{ _cellValue }}
    </a>

    <a
      *ngSwitchCase="cellFormats.LINK"
      class="t-data t-link"
      tabindex="0"
      role="link"
      (click)="cellClicked.emit(_context)"
      [attr.aria-label]="
        column.accessibilityMessage ||
        'Click to complete action for ' + _cellValue + 'cell'
      "
    >
      {{ _cellValue }}
    </a>

    <a
      *ngSwitchCase="cellFormats.EMAIL"
      class="t-data t-link"
      tabindex="0"
      [href]="'mailto: ' + _cellValue"
      target="_blank"
      [attr.aria-label]="
        column.accessibilityMessage || 'Click to email ' + _cellValue
      "
    >
      {{ _cellValue }}
    </a>

    <a
      *ngSwitchCase="cellFormats.PHONE"
      class="t-data t-link"
      tabindex="0"
      [href]="'tel: ' + _cellValue"
      target="_blank"
      [attr.aria-label]="
        column.accessibilityMessage || 'Click to call ' + _cellValue
      "
    >
      {{ _cellValue }}
    </a>

    <ul *ngSwitchCase="cellFormats.LIST" tabindex="0">
      <li
        *ngFor="let val of _cellValue"
        [attr.aria-label]="
          column.accessibilityMessage || 'Display of ' + val + ' value'
        "
      >
        <p class="t-data">{{ val }}</p>
      </li>
    </ul>

    <div
      *ngSwitchCase="cellFormats.ACTIONS"
      class="table__cell__actions"
      tabindex="0"
    >
      <i
        class="icon icon--medium icon--link"
        *ngFor="let action of column.actions"
        (click)="cellClicked.emit({ context: _context, action: action })"
      >
        {{ action }}
      </i>
    </div>

    <div *ngSwitchDefault>
      <ng-container *ngIf="column?.formatOptions?.link; else plainText">
        <a
          class="t-data t-link"
          tabindex="0"
          role="link"
          (click)="cellClicked.emit(_context)"
          [attr.aria-label]="
            column.accessibilityMessage ||
            'Click to complete action for ' + _cellValue + 'cell'
          "
        >
          {{ _cellValue }}
        </a>
      </ng-container>
      <ng-template #plainText>
        <p class="t-data" tabindex="0">
          {{ _cellValue }}
        </p>
      </ng-template>
    </div>
  </ng-container>
</div>
