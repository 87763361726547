import { Component, Input } from '@angular/core';
import { PreloaderSizes } from './preloader.model';

@Component({
  selector: 'preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
})
export class PreloaderComponent {
  @Input() isFullPage = false;
  @Input() flexCenter = true;
  @Input() positionRelative: boolean;
  @Input() size: PreloaderSizes;
}
