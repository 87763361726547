import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ButtonModule,
  CardModule,
  ErrorEmptyModule,
  FormControlModule,
  FormGroupModule,
  PaginatorModule,
} from '@zipari/design-system';
import { ZipBusyModule } from '../../../../../shared/modules/zip-busy/zip-busy.module';
import { RowItemModule } from '../row-item/row-item.module';
import { ViewerProvisioningComponent } from './viewer-provisioning.component';

@NgModule({
  declarations: [ViewerProvisioningComponent],
  exports: [ViewerProvisioningComponent],
  imports: [
    CommonModule,
    RouterModule,
    CardModule,
    FormControlModule,
    FormGroupModule,
    ButtonModule,
    RowItemModule,
    ZipBusyModule,
    PaginatorModule,
    ErrorEmptyModule,
  ],
})
export class ViewerProvisioningModule {}
