import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { APIService } from '../../services';

export interface ListServiceInterface {
  getList: (endpoint: string, params?: any) => Observable<any>;
  addToList: (endpoint: string, item: any) => Observable<any>;
}

export const LIST_SERVICE = new InjectionToken<ListServiceInterface>(
  'LIST_SERVICE'
);

@Injectable()
export class ListService {
  busy: Subscription;

  constructor(private api: APIService, private http: HttpClient) {}

  getList(endpoint: string, params = {}): Observable<any> {
    return this.http.get(endpoint, { params });
  }

  addToList(endpoint: string, item: any): Observable<any> {
    return this.http.post(endpoint, item);
  }

  public handleCheckingApplicationForUnfinishedWorkflow(
    configService,
    router: Router,
    route: ActivatedRoute,
    event,
    statuses?
  ): Promise<any> {
    const nonApplicationSubmitStatus = statuses || [
      'submitted',
      'completed',
      'complete',
    ];

    if (
      nonApplicationSubmitStatus.filter(
        (status) => event.context.row.status === status
      ).length === 0 &&
      event.context.row.application_source === configService.app
    ) {
      return router.navigate([
        configService.appRoute,
        configService.getMarketSegment(route?.snapshot),
        'enrollment',
        event.context.row.workflow_id,
      ]);
    }
  }

  // the code is not in use, hence kept in commented
  // public handleDeleteSubmitByKey(event: any) {
  //     // TODO: this needs to be alot more generic
  //     switch (event.key) {
  //         case 'delete_practitioners':
  //             // TODO: backend integration
  //             return this.api.get(`api/provider-portal/update-tool/providers/?page=1&page_size=20`);
  //         default:
  //             break;
  //     }
  // }
}
