import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'buildQuery',
})
export class BuildQueryPipe implements PipeTransform {
  // '/api' | buildQuery: [{a: "b"}, {c: "d"}]
  transform(url: string, args: object[] | Record<string, unknown>): string {
    let newUrl = `${url}?`;

    if (Array.isArray(args)) {
      newUrl += args
        .reduce((acc, arg) => `${acc + this.objectToQueryString(arg)}&`, '')
        .slice(0, -1);
    } else {
      newUrl += this.objectToQueryString(args);
    }

    return newUrl;
  }

  private objectToQueryString(arg: any): string {
    return Object.keys(arg)
      .map((key) => `${key}=${arg[key]}`)
      .join('&');
  }
}
