import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControlModule,
  TabsModule,
  ZipBusyModule,
} from '@zipari/design-system';
import { DetailTabsModule } from '../../shared/modules/detail-tabs/detail-tabs.module';
import { DocumentCenterComponent } from './document-center.component';
import { DocumentTableModule } from './document-table/document-table.module';
import { ResourcesModule } from './resources/resources.module';
import { DocumentCenterRoutingModule } from './document-center-routing.module';

@NgModule({
  declarations: [DocumentCenterComponent],
  imports: [
    CommonModule,
    DocumentCenterRoutingModule,
    DocumentTableModule,
    TabsModule,
    ZipBusyModule,
    DetailTabsModule,
    ResourcesModule,
    FormControlModule,
  ],
  exports: [DocumentCenterComponent],
})
export class DocumentCenterModule {}
