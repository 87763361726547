import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AccordionModule,
  ButtonModule,
  FormControlModule,
  ModelAttributeModule,
  ProgressBarModule,
  TableModule,
  DatagridModule,
} from '@zipari/design-system';
import { PregnancyFormListModule } from '../../../templates/pregnancy-risk-assesment/controls/pregnancy-form-list/pregnancy-form-list.module';
import { ViewerProvisioningModule } from '../../../templates/user-provisioning/view/viewer/viewer-provisioning.module';

import { AddNewModalModule } from '../../../../shared/modules/add-row-modal/add-new-modal.module';

import { SharedModule } from '../../../../shared/shared.module';
import { NgSwitchPreInitDirective } from '../../directives/ng-switch-preInit.directive';
import { IdCardsModule } from '../../../templates/id-cards/id-cards.module';
import { PipeModule } from '../../../../shared/pipes/pipe.module';
import { ClinicalReviewListModule } from '../clinical-review-list/clinical-review-list.module';
import { TabViewComponent } from './tab-view.component';

@NgModule({
  declarations: [TabViewComponent, NgSwitchPreInitDirective],
  imports: [
    CommonModule,
    ModelAttributeModule,
    TableModule,
    ProgressBarModule,
    FormControlModule,
    ButtonModule,
    AddNewModalModule,
    AccordionModule,
    ViewerProvisioningModule,
    SharedModule,
    PregnancyFormListModule,
    IdCardsModule,
    PipeModule,
    ClinicalReviewListModule,
    DatagridModule,
  ],
  exports: [TabViewComponent],
})
export class TabViewModule {}
