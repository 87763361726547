import { Component, Input } from '@angular/core';
import { PortalFooterConfiguration } from '@zipari/shared-ds-util-footer';

@Component({
  selector: 'smc-main',
  styleUrls: ['./main.component.scss'],
  templateUrl: './main.component.html',
})
export class MainComponent {
  @Input() footerConfig: PortalFooterConfiguration;
  @Input() hasBackgroundChanged?: boolean;
}
