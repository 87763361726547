import { Injectable } from '@angular/core';
import { getFormattedDate } from '@zipari/web-utils';
import { UntypedFormGroup } from '@angular/forms';
import { camelCase } from '@zipari/shared-util-common';
import { AuthService } from '../../../../shared/services';
import {
  AppealContactInformation,
  ClaimAppealPayload,
  QuestionResponse,
} from '../claim-appeal.model';
import {
  ProviderInformation,
  claimCardConfig,
} from '../claim-redetermination/claim-redetermination.model';
import { updateProviderInformation } from '../claim-appeal.helper';
import { AppealConstant } from '../claim-appeal.constants';
import Claim from '../../../../shared/models/shared/Claim.model';
import { DocumentUploadService } from './document-upload.service';

@Injectable({
  providedIn: 'root',
})
export class SubmitClaimAppealService {
  claimDetail: Claim;
  requestingProviderInformation: ProviderInformation;
  providerInformation: ProviderInformation | null;

  constructor(
    private authService: AuthService,
    private documentUploadService: DocumentUploadService
  ) {}

  setSubmitAppealPayload(
    appealFormValue: UntypedFormGroup,
    appealType: string,
    claimId: string,
    requestCardConfig?: claimCardConfig
  ): ClaimAppealPayload {
    const loggedInUser =
      this.authService.loggedInUser?.app_user_data?.user_name;
    const {
      disputeType,
      dateOfInitialDetermination,
      isPriorLevelFiled,
      dateOfAppeal,
      disputedAmount,
      priority,
      reasonOfRedetermination,
      priorLevelFiledReason,
      service,
      disputeTypeOther,
    } = appealFormValue.getRawValue();

    const payload = {
      source: 'Zipari',
      submitted_by_username: loggedInUser ? loggedInUser : '',
      submission_date: getFormattedDate(new Date()),
      requested_date: dateOfAppeal,
      claim_id: claimId,
      claim_line_number: service?.lineNumber,
      procedure_code: service?.procedures[0]?.code,
      original_decision_date: dateOfInitialDetermination,
      dispute_type: disputeType?.value,
      dispute_level: appealType,
      dispute_reason: reasonOfRedetermination?.value
        ? reasonOfRedetermination.value
        : reasonOfRedetermination,
      is_prior_level_filed: isPriorLevelFiled,
      prior_level_filed_reason: priorLevelFiledReason,
      dispute_amount: disputedAmount,
      dispute_priority: priority?.value,
      document_ids: this.getDocumentIds(),
      contact_information: this.getContactInformation(appealFormValue),
      requestor_information: this.getRequestorInformation(appealFormValue),
      dispute_type_other: disputeTypeOther,
    };

    if (requestCardConfig?.form?.controls) {
      payload['questions_responses'] = this.getQuestionsResponses(
        requestCardConfig,
        appealFormValue
      );
    }
    if (!this.providerInformation) {
      this.providerInformation = updateProviderInformation(
        camelCase(this.claimDetail)
      );
    }

    if (this.providerInformation?.facilityId) {
      this.requestingProviderInformation = this.providerInformation
        ?.facilityName
        ? this.providerInformation
        : updateProviderInformation(this.providerInformation);
      payload[AppealConstant.billingFacilityId] = this.providerInformation.id;
    } else {
      payload[AppealConstant.billingProviderId] = this.providerInformation?.id;
    }

    return payload;
  }

  getDocumentIds(): string[] {
    const documentIds: string[] = [];

    this.documentUploadService.uploadedFiles.forEach((document) => {
      documentIds.push(document.id.toString());
    });

    return documentIds;
  }

  getRequestorInformation(
    appealFormValue: UntypedFormGroup
  ): AppealContactInformation {
    const {
      requestorName,
      requestorPhoneNumber,
      requestorEmail,
      requestorRelationship,
      streetAddress1,
      streetAddress2,
      city,
      zipCode,
      state,
    } = appealFormValue.getRawValue();

    return {
      name: {
        full_name: requestorName,
      },
      phone_numbers: [
        {
          phone_number: requestorPhoneNumber,
        },
      ],
      email_address: requestorEmail,
      contact_relationship: requestorRelationship?.value,
      addresses: [
        {
          street_name_1: streetAddress1 ? streetAddress1 : '',
          street_name_2: streetAddress2 ? streetAddress2 : '',
          city_name: city ? city : '',
          zip_code: zipCode ? zipCode : '',
          state: state ? state : '',
        },
      ],
    };
  }

  getContactInformation(
    appealFormValue: UntypedFormGroup
  ): AppealContactInformation {
    const { contactName, contactPhoneNumber, contactFaxNumber, contactEmail } =
      appealFormValue.getRawValue();

    return {
      name: {
        full_name: contactName,
      },
      phone_numbers: [
        {
          phone_number: contactPhoneNumber,
        },
      ],
      fax: contactFaxNumber ? contactFaxNumber : '',
      email_address: contactEmail,
      addresses: [],
    };
  }

  getQuestionsResponses(
    requestCardConfig: claimCardConfig,
    appealFormValue: UntypedFormGroup
  ): QuestionResponse[] {
    const questionResponses: QuestionResponse[] = [];
    const controls = requestCardConfig?.form?.controls;
    const claimAppealDetails = appealFormValue.getRawValue();

    controls?.forEach((control) => {
      if (
        control.prop.lastIndexOf('Question_', 0) === 0 &&
        claimAppealDetails.hasOwnProperty(control.prop)
      ) {
        questionResponses.push({
          question_id: control.prop,
          response: claimAppealDetails[control.prop],
        });
      }
    });

    return questionResponses;
  }
}
