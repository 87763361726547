import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { PasswordConfiguration } from '@zipari/shared-ds-util-form';

@Component({
  selector: 'control-password',
  templateUrl: './control-password.component.html',
})
export class ControlPasswordComponent implements OnInit {
  @Input() autocomplete: any;
  @Input() canToggle = true;
  @Input() config: PasswordConfiguration;
  @Input() control: any;
  @Input() customInputTemplate: TemplateRef<any>;

  @Output() inputChanged = new EventEmitter<any>();
  @Output() focusEvent = new EventEmitter<any>();
  @Output() focusOutEvent = new EventEmitter<any>();

  @ViewChild('input') input;

  cantSeePassword: boolean;

  ngOnInit() {
    this.cantSeePassword = true;

    if (typeof this.canToggle === 'undefined') {
      this.canToggle = true;
    }

    if (this.config.hasOwnProperty('canToggle')) {
      this.canToggle = this.config.canToggle;
    }
  }

  onInputChange() {
    this.inputChanged.next(this.control.value);
  }

  /** Toggle whether the password that is typed in can be seen or is obscured by dots. */
  onEyeIconClick() {
    this.cantSeePassword = !this.cantSeePassword;
    this.canToggle = !this.canToggle;
  }
}
