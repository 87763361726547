<ng-container *ngIf="getSupportingDocumentContent">
  <simple-card class="SupportingDocumentationReview__SimpleCard">
    <div
      title
      class="SupportingDocumentationReview__Title"
      data-test-id="SupportingDocumentationReviewTitle"
    >
      <h6 class="t-bold">{{ documentationConfig.title }}</h6>
      <icon
        name="edit"
        [config]="{ type: 'link' }"
        *ngIf="isEditable"
        data-test-id="SupportingDocumentationReviewIcon"
        (click)="editIconClicked()"
      ></icon>
    </div>
    <ng-container
      *ngFor="let documentInfo of supportingDocumentationUploadedFiles"
    >
      <div class="SupportingDocumentationReview__Document">
        <icon
          name="pdf"
          [config]="{ type: 'link' }"
          class="SupportingDocumentationReview__DocumentIcon"
        >
        </icon>
        <a
          class="t-data t-link"
          (click)="downloadDocument(documentInfo.id, documentInfo.file_name)"
          target="_blank"
          >{{ documentInfo.file_name }}</a
        >
      </div>
    </ng-container>

    <ng-container *ngFor="let documentInfo of summaryPageDocumentUploadedFiles">
      <div class="SupportingDocumentationReview__Document">
        <icon
          name="pdf"
          [config]="{ type: 'link' }"
          class="SupportingDocumentationReview__DocumentIcon"
        >
        </icon>
        <a
          class="t-data t-link"
          (click)="downloadDocument(documentInfo.documentId, documentInfo.name)"
          target="_blank"
          >{{ documentInfo.name }}</a
        >
      </div>
    </ng-container>
  </simple-card>
</ng-container>
