import { DatePipe } from '@angular/common';

export function getQuarterFirstDay(): Date {
  const now = new Date();
  const quarter = Math.floor(now.getMonth() / 3);

  return new Date(now.getFullYear(), quarter * 3, 1);
}

export function getCurrentDate(): string {
  return new Date().toISOString().slice(0, 10);
}

export function getCurrentTime(): string {
  const time = new Date();

  return time
    .toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
    .toString();
}

export function getFormattedTime(
  time: string | null | undefined,
  format: string
) {
  const date = new Date(`${new Date().toDateString()} ${time}`);

  return new DatePipe('en-US').transform(date, format);
}

export const getDaysDifference = (selectedDate) =>
  Math.floor((new Date().getTime() - selectedDate) / 1000 / 60 / 60 / 24);
