import { Pipe, PipeTransform } from '@angular/core';

import { MASKS } from '../utilities/masks';

@Pipe({
  name: 'mask',
})
export class MaskPipe implements PipeTransform {
  transform(value: string, maskName: string): string {
    if (maskName && (<any>MASKS)[maskName]) {
      return (<any>MASKS)[maskName].mask(value);
    }

    return value;
  }
}
