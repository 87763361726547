import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IconModule } from '../../icon/icon.module';

import { ControlToggleComponent } from './toggle.component';

@NgModule({
  declarations: [ControlToggleComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IconModule],
  exports: [ControlToggleComponent],
})
export class ControlToggleModule {}
