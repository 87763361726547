import Benefit from '../../../shared/models/shared/Benefit.model';
import { IdCard } from '../../../shared/models/shared/IdCard.model';
import Member from '../../../shared/models/shared/Member.model';
import PlanBenefit from '../../../shared/models/shared/PlanBenefit.model';

export class PatientDetailContext {
  benefits: Benefit[];
  claims: any;
  coverage: any;
  eligibilityHistory: any;
  member?: Member;
  pcp: any;
  idCards: IdCard[];
  planBenefits?: PlanBenefit[];
  policy_status?: string;
  user: any;
}

export const TabsProp = {
  claims: 'claims',
  authorizations: 'authorizations',
  referrals: 'referrals',
  accumulators: 'accumulators',
  benefits: 'benefits',
  idCards: 'id-cards',
  eligibilityHistory: 'eligibility-history',
};

export const patientDetailConstants = {
  activeStatus: 'active',
  pregnancyForm: 'pregnancyForm',
  memberId: 'member_id',
  globalConfig: 'global',
};

export const noDataFoundMessage = 'No data found';
