<div id="test2"></div>
<div class="ConfirmAttestation" *ngIf="config">
  <header>
    <h4
      role="heading"
      class="t-bold ConfirmAttestation__Header Center"
      [innerHTML]="config.header"
    ></h4>
  </header>
  <p
    class="ConfirmAttestation__Description Center"
    [innerHTML]="config.description"
  ></p>
  <div class="ConfirmAttestation__printButton Center">
    <zip-button
      id="printAttestation"
      [config]="config.printButton"
      (zipButtonClicked)="printAttestation()"
    >
    </zip-button>
  </div>
  <div class="ConfirmAttestation__InfoPanel">
    <h6 class="t-bold" [innerHTML]="config.userInformationTitle"></h6>
    <hr class="ConfirmAttestation__Separator" />
    <div
      class="ConfirmAttestation__InfoPanel--Card"
      *ngFor="let attribute of config.userInformation.attributes"
    >
      <model-attribute
        [context]="userInformation"
        [config]="attribute"
      ></model-attribute>
    </div>
  </div>
  <div class="ConfirmAttestation__LocationPanel" *ngIf="showLocationsNameCard">
    <h6 class="t-bold" [innerHTML]="config.selectedLocationsTitle"></h6>
    <hr class="ConfirmAttestation__Separator" />
    <div
      class="ConfirmAttestation__LocationPanel--Card"
      *ngFor="let location of updatedLocations.name"
    >
      <p [innerHTML]="location"></p>
    </div>
  </div>
</div>
