import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { WindowService } from '@zipari/web-services';
import { cloneObject } from '@zipari/web-utils';
import { ModelAttributeConfig } from '@zipari/shared-ds-util-configuration';
import { toCamel } from '@zipari/shared-util-common';
import { FormattingService } from '@zipari/design-system';
import { DocumentUploadService } from '../../services/document-upload.service';
import {
  ClaimAppealStepsConfig,
  ClaimAppealSubmissionAPIResponse,
} from '../../claim-redetermination/claim-redetermination.model';
import {
  AppealConstant,
  AppealFormCurrentStep,
  ClaimAppealProps,
} from '../../claim-appeal.constants';
import { AttachmentMetaData } from '../../../../../shared/models/shared/Document.model';
import { updateBillingProviderContext } from '../../claim-appeal.helper';
import { SubmitClaimAppealService } from '../../services/submit-claim-appeal.service';
import { camelCase } from '../../../../../shared/utilities/case-conversion.utils';
import ClaimAppealEventService from '../../services/claim-appeal-event.service';

@Component({
  selector: 'review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit, OnChanges {
  @Input() appealForm: UntypedFormGroup | ClaimAppealSubmissionAPIResponse;
  @Input() config: ClaimAppealStepsConfig;
  @Input() currentStep: string;
  @Output() editDetail: EventEmitter<string> = new EventEmitter();
  reviewClaimConfig: ClaimAppealStepsConfig;
  public CONFIRMATION_STEP = AppealFormCurrentStep.CONFIRMATION;

  constructor(
    public documentUploadService: DocumentUploadService,
    public submitClaimAppealService: SubmitClaimAppealService,
    private windowService: WindowService,
    private claimAppealEventService: ClaimAppealEventService,
    private formattingService: FormattingService
  ) {}

  ngOnInit(): void {
    this.emitDynamicButtonConfig();
    this.updateAttributeConfig();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config?.previousValue !== changes.config?.currentValue) {
      this.config = changes.config.currentValue;
      this.emitDynamicButtonConfig();

      if (this.currentStep === AppealFormCurrentStep.CONFIRMATION) {
        this.getSubmitDocument();

        this.appealForm[AppealConstant.submissionDate] =
          this.formattingService.restructureValueBasedOnFormat(
            this.appealForm[AppealConstant.submissionDate],
            AppealConstant.dateFormat
          );

        this.appealForm[AppealConstant.billingProvider] = this.appealForm[
          AppealConstant.billingFacilityId
        ]
          ? this.submitClaimAppealService.requestingProviderInformation
          : updateBillingProviderContext(this.appealForm);
        this.updateAttributeConfig();
      }
    }
  }

  getSubmitDocument(): void {
    this.documentUploadService.uploadedFiles =
      this.documentUploadService.uploadedFiles?.filter(
        (file: AttachmentMetaData) =>
          this.appealForm[AppealConstant.documentIds]?.includes(file.id)
      );

    if (this.documentUploadService.uploadedFiles.length) {
      this.appealForm['documentType'] =
        this.documentUploadService.uploadedFiles[0].document_category;
    }
  }

  public downloadDocument(documentId: string): void {
    this.windowService.nativeWindow.open(
      `/api/documents/${documentId}/?download=true`,
      '_self'
    );
  }

  emitDynamicButtonConfig(): void {
    if (this.config) {
      this.claimAppealEventService.emitDynamicButtonConfig(
        this.config.nextButton
      );
    }
  }

  updateAttributeConfig(): void {
    this.reviewClaimConfig = cloneObject(this.config);
    const billingProviderConfig =
      this.reviewClaimConfig?.cards?.billingProviderInformation;
    const requestInfoConfig = this.reviewClaimConfig?.cards?.requestInformation;
    const { disputeTypeProp, disputeTypeOther } = ClaimAppealProps;

    if (this.appealForm) {
      if (billingProviderConfig?.attributes) {
        billingProviderConfig.attributes = this.updateAttributes(
          billingProviderConfig.attributes
        );
      }

      if (
        !Object.keys(this.appealForm).includes(
          ClaimAppealProps.reasonOfLateFiling
        )
      ) {
        requestInfoConfig.attributes = requestInfoConfig.attributes.filter(
          (attribute: ModelAttributeConfig) =>
            attribute.prop != ClaimAppealProps.reasonOfLateFiling
        );
      }

      const appealDetail = camelCase({ ...this.appealForm });

      requestInfoConfig.attributes = requestInfoConfig?.attributes.filter(
        (attribute: ModelAttributeConfig) => {
          const comparisonProp = toCamel(attribute.prop);

          return (
            comparisonProp !==
            (appealDetail[disputeTypeOther]
              ? disputeTypeProp
              : disputeTypeOther)
          );
        }
      );
    }
  }

  updateAttributes(attributes: ModelAttributeConfig[]): ModelAttributeConfig[] {
    if (this.currentStep === AppealFormCurrentStep.REVIEW) {
      return attributes.filter((attribute: ModelAttributeConfig) =>
        Object.keys(this.appealForm).includes(attribute.prop)
      );
    } else {
      return this.updateAttributesOnConfirmationPage(
        this.appealForm,
        attributes
      );
    }
  }

  updateAttributesOnConfirmationPage(
    appealForm: UntypedFormGroup | ClaimAppealSubmissionAPIResponse,
    attributes: ModelAttributeConfig[]
  ): ModelAttributeConfig[] {
    return appealForm[AppealConstant.billingProviderId]
      ? attributes.filter(
          (attribute: ModelAttributeConfig) =>
            !attribute.prop?.includes(ClaimAppealProps.facilityName)
        )
      : attributes.filter((attribute: ModelAttributeConfig) =>
          Object.keys(this.appealForm[AppealConstant.billingProvider]).includes(
            attribute?.prop?.split('.')[1]
          )
        );
  }
}
