import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { getPaginatorConfig } from '@zipari/design-system';
import { WindowService } from '@zipari/web-services';
import { ProviderPortalService } from '../../../provider-portal.service';
import { SquidexConfig } from '../../../../shared/modules/cms/squidex/model';
import { ConfigService } from '../../../../shared/services';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { DocumentCenterService } from '../document-center.service';
import {
  DocumentTableTabConfig,
  SearchFilterProp,
} from '../documentsConfig.model';
import { TableCellClickEvent } from '../../../../shared/models/shared/CellClickEvent.model';

@Component({
  selector: 'document-table',
  templateUrl: './document-table.component.html',
  styleUrls: ['./document-table.component.scss'],
})
export class DocumentTableComponent implements OnInit {
  config: DocumentTableTabConfig;
  pageSize: number;
  paginator;
  squidexConfig: SquidexConfig;
  searchString: string;
  data = [];
  squidexEnabled: boolean;
  currentPage = 1;
  skipItems = 0;
  filtersForm: UntypedFormGroup = new UntypedFormGroup({});
  loading = false;

  constructor(
    private documentCenterService: DocumentCenterService,
    private router: Router,
    public analyticsService: AnalyticsService,
    private configService: ConfigService,
    private providerPortalService: ProviderPortalService,
    private window: WindowService
  ) {}

  ngOnInit(): void {
    this.config = this.documentCenterService.tabsConfig;
    this.squidexConfig =
      this.configService.getPageConfig<any>(
        'global'
      )?.contentManagementSystem?.squidex;

    if (
      this.squidexConfig?.enabled &&
      this.squidexConfig?.enableForModule?.documents
    ) {
      this.squidexEnabled = true;
      this.updateConfig();
    }
  }

  navigateToDetail(target: TableCellClickEvent): void {
    let targetRoute: string;
    let url: string = '';
    const cxDictionaryObj = {
      document_category: target.context?.row?.document_category,
      document_name: target.context?.row?.file_name,
      file_type: target.context?.row?.file_type,
    };
    const cx_event = target.context?.col?.cell_click_cx_event_key;

    if (this.config?.targetRouteObj?.externalUrl) {
      url = target.context?.row?.[this.config.targetRouteObj.externalUrl] ?? '';

      if (url) {
        this.window.nativeWindow.open(url, '_blank');
      }

      if (cx_event) {
        this.analyticsService.sendEvent(cx_event, cxDictionaryObj);
      }
    }
    if (this.config?.targetRouteObj?.customUrl) {
      const pattern: any = /[^${}]*(?=\})/g;
      const toReplace: string[] =
        this.config.targetRouteObj.customUrl.match(pattern);
      toReplace.forEach((item: any) => {
        if (item.length > 0) {
          url = this.config.targetRouteObj.customUrl.replace(
            new RegExp(`\\\${${item}}`, 'gi'),
            target.context.row[item]
          );
        }
      });
      if (cx_event) {
        this.analyticsService.sendEvent(cx_event, cxDictionaryObj);
      }
      if (this.squidexEnabled) {
        this.window.nativeWindow.open(target.context?.row?.url, '_blank');
      } else {
        this.window.nativeWindow.open(url, '_blank');
      }
    } else if (this.config?.targetRouteObj?.targetUrl) {
      // Get target id from data
      const id = target.context?.row?.[this.config?.targetRouteObj?.targetId];
      targetRoute = `${this.window.nativeWindow.location.pathname}/${id}`;
      if (cx_event) {
        this.analyticsService.sendEvent(cx_event, cxDictionaryObj);
      }
      this.router.navigate([targetRoute]);
    }
  }

  updateConfig(): void {
    this.config.table.endpoint = null;

    if (this.config.table?.filters) {
      this.config.filters = [...this.config.table.filters];
    }
    this.config.table.paging = { ...this.config.table?.paging, enabled: false };
    this.config.table.filters = null;
    this.filtersForm.valueChanges.subscribe((val: string) => {
      if (val[SearchFilterProp] != null) {
        this.currentPage = 1;
        this.skipItems = 0;
        this.getSquidexDocuments();

        const cx_event = this.config.filters[0]?.cx_event_key;
        if (cx_event) {
          this.analyticsService.sendEvent(cx_event);
        }
      }
    });
    this.getSquidexDocuments();
  }

  getSquidexDocuments(): void {
    const documentType = this.documentCenterService.activeTabObj?.prop;
    this.pageSize = this.config?.table?.paging?.pageSize;
    this.loading = true;
    this.providerPortalService
      .getSquidexDocuments(
        documentType,
        this.filtersForm.value[SearchFilterProp],
        this.pageSize,
        this.skipItems
      )
      .subscribe((documents: any) => {
        const data = documents?.items?.map((item: any) => ({
          id: item.id,
          file_type: item.mimeType,
          file_name: this.getAssetName(item.fileName),
          file_size: item.fileSize,
          name: this.getAssetName(item.fileName),
          year: item.metadata?.year,
          url: this.getAssetUrl(item.id),
          effective_date: item.metadata?.effective_date,
          document_date: item.created,
          document_category: documentType,
        }));
        this.data = data ? [...data] : [];
        this.loading = false;
        this.paginator = getPaginatorConfig(
          {},
          this.pageSize,
          this.currentPage,
          documents.total
        );
      });
  }

  getAssetUrl(id: string): string {
    return `${this.squidexConfig.url}/api/assets/${this.squidexConfig.appName}/${id}`;
  }

  getAssetName(name: string): string {
    return name?.split('.').slice(0, -1).join('.');
  }

  setCurrentPage(page: number): void {
    this.currentPage = page;
    this.skipItems = (page - 1) * this.pageSize;
    this.getSquidexDocuments();
  }
}
