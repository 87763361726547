import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  BadgeModule,
  ButtonModule,
  ModalModule,
  ModelAttributeModule,
  ZipBusyModule,
} from '@zipari/design-system';
import { LinkListModule } from '@zipari/design-system';
import { SharedModule } from '../../../shared/shared.module';
import { DetailTabsModule } from '../../shared/modules/detail-tabs/detail-tabs.module';
import { TabViewModule } from '../../shared/modules/tab-view/tab-view.module';

import { TransferOfCareDetailComponent } from './transfer-of-care-detail.component';

@NgModule({
  declarations: [TransferOfCareDetailComponent],
  imports: [
    CommonModule,
    ModelAttributeModule,
    DetailTabsModule,
    ButtonModule,
    DetailTabsModule,
    TabViewModule,
    LinkListModule,
    BadgeModule,
    ZipBusyModule,
    SharedModule,
    ModalModule,
    DetailTabsModule,
  ],
  exports: [TransferOfCareDetailComponent],
})
export class TransferOfCareDetailModule {}
