import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlTimeComponent } from './time.component';

@NgModule({
  declarations: [ControlTimeComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [ControlTimeComponent],
})
export class ControlTimeModule {}
