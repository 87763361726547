<article
  *ngIf="config && claimDetailService.claimId; else loading"
  class="ClaimAppeal"
>
  <div class="ClaimAppeal__SidePanel">
    <side-bar
      [config]="config"
      [claimId]="claimDetailService.claimId"
    ></side-bar>
  </div>

  <div class="ClaimAppeal__MainContainer">
    <ng-container *ngIf="shouldShowReviewPage && context; else entryForm">
      <review
        [config]="currentStepConfig"
        [appealForm]="context"
        [currentStep]="currentStep"
        (editDetail)="editCard($event)"
      ></review>
    </ng-container>
    <ng-template #entryForm>
      <entry-form
        [config]="config?.entry"
        [appealForm]="appealForm"
        [claimId]="claimDetailService.claimId"
        [hasReturnedFromReviewPage]="hasReturnedFromReviewPage"
      ></entry-form>
    </ng-template>
  </div>

  <div class="ClaimAppeal__Footer">
    <zip-button
      class="ClaimAppeal__FooterButton"
      [config]="config.footer.cancelButton"
      *ngIf="config?.footer?.cancelButton && currentStep !== 'confirmation'"
      (zipButtonClicked)="navigateToPreviousPage()"
    >
    </zip-button>
    <zip-button
      class="ClaimAppeal__FooterButton ClaimAppeal__FooterButton--right"
      (zipButtonClicked)="handleNextButtonClick()"
      [disabled]="!appealForm.valid || !isDisputeTypeAllowed()"
      [config]="claimAppealEventService.dynamicButtonConfig$ | async"
    ></zip-button>
  </div>
</article>

<ng-template #loading>
  <spinner size="medium" flexCenter="true"></spinner>
</ng-template>

<api-error-message-modal
  *ngIf="config?.review?.submitErrorModal as errorModalConfig"
  [modalConfig]="errorModalConfig.modalConfig"
  [buttonConfig]="errorModalConfig.submitButton"
  [showModal]="showErrorModal"
  [errorMessage]="errorMessage"
  (submitButtonClicked)="errorModalClicked()"
></api-error-message-modal>
