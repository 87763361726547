import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ControlTextModule } from '../text/text.module';
import { ControlTimeRangeComponent } from './time-range.component';

@NgModule({
  declarations: [ControlTimeRangeComponent],
  imports: [CommonModule, ControlTextModule],
  exports: [ControlTimeRangeComponent],
})
export class ControlTimeRangeModule {}
