import { AdditionalDetailsFormValue } from './site-locations-attestation.model';

export const setAdditionalDetailPayload = (
  payload: object,
  additionalDetailsFormValue: AdditionalDetailsFormValue
): object => {
  const {
    legalEntityName = null,
    legalEntityEffectiveDate = null,
    legalEntityContactEmail = null,
    legalEntityContactName = null,
    legalEntityContactPhone = null,
    changeInOwnership = false,
  } = additionalDetailsFormValue;

  if (!changeInOwnership) return payload;

  return {
    ...payload,
    legal_entity_name: legalEntityName,
    legal_effective_date: legalEntityEffectiveDate,
    legal_entity_contact: {
      email_address: legalEntityContactEmail,
      name: { full_name: legalEntityContactName },
      phone_numbers: [{ phone_number: legalEntityContactPhone }],
    },
  };
};
