import { Component, OnDestroy } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

/*
    Base ControlValueAccessor class implementation to be extended by form elements with complex values requiring own FormGroup
    e.g. the `AddressComponent` can be given an object value when instantiating a new FormControl(addressValueHere)
*/
@Component({
  selector: 'base-cva',
  template: '<div></div>',
})
export class BaseCVAComponent implements ControlValueAccessor, OnDestroy {
  public formGroup: UntypedFormGroup;
  public destroy = new Subject();
  public onTouched = () => ({});

  public ngOnDestroy() {
    this.destroy.next(void 0);
    this.destroy.complete();
  }

  public writeValue(value: any) {
    if (value) {
      this.formGroup.patchValue(value, { emitEvent: false });
    }
  }

  public registerOnChange(fn: any) {
    this.formGroup.valueChanges
      .pipe(debounceTime(0), takeUntil(this.destroy))
      .subscribe(fn);
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public setDisabledState(disabled: boolean) {
    disabled ? this.formGroup.disable() : this.formGroup.enable();
  }
}
