import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConditionalPipeModule } from '../../shared/modules/conditional-pipe/conditional-pipe.module';
import { DigitalDataModule } from '../../shared/modules/digital-data/digital-data.module';
import { FormattingService } from '../../shared/services/formatting.service';
import { ButtonModule } from '../button/button.module';
import { ExpandingListModule } from '../expanding-list/expanding-list.module';
import { IconModule } from '../icon/icon.module';
import { PreloaderModule } from '../preloader/preloader.module';
import { StatusModule } from '../status/status.module';
import { TooltipModule } from '../tooltip/tooltip.module';
import { DatagridComponent } from './datagrid.component';
import { DatagridSortingStatePipe } from './pipes/sortingState.pipe';
import { SortToggleComponent } from './sort-toggle/sort-toggle.component';
import { NotesTextWithIconComponent } from './text-with-icon/notes-text-with-icon.component';

@NgModule({
  declarations: [
    DatagridComponent,
    SortToggleComponent,
    DatagridSortingStatePipe,
    NotesTextWithIconComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    IconModule,
    PreloaderModule,
    StatusModule,
    TooltipModule,
    ConditionalPipeModule,
    DigitalDataModule,
    ExpandingListModule,
  ],
  providers: [CurrencyPipe, DatePipe, FormattingService],
  exports: [
    DatagridComponent,
    SortToggleComponent,
    DatagridSortingStatePipe,
    NotesTextWithIconComponent,
  ],
})
export class DatagridModule {}
