<div class="ProgressBar__Header">
  <h4 class="ProgressBar__HeaderTitle" [innerHTML]="data.label"></h4>
  <div
    *ngIf="data.labelTopRight && data.total"
    class="ProgressBar__HeaderTotal"
  >
    <p class="ProgressBar__HeaderCaption">{{ data.labelTopRight }}</p>
    <p>{{ data.total }}</p>
  </div>
</div>

<div class="ProgressBar">
  <div class="ProgressBar__Total">
    <div
      class="ProgressBar__Partial"
      [ngStyle]="{ width: data.value + '%' }"
    ></div>
  </div>
</div>

<footer class="ProgressBar__Footer">
  <div class="ProgressBar__FooterSpent">
    <p class="ProgressBar__FooterCaption">{{ data.labelLeft }}</p>
    <p>{{ data.used }}</p>
  </div>
  <div class="ProgressBar__FooterRemaining">
    <p class="ProgressBar__FooterCaption">{{ data.labelRight }}</p>
    <p>{{ data.remaining }}</p>
  </div>
  <p class="ProgressBar__Note" *ngIf="data.note && data.showNote">
    {{ data.note }}
  </p>
</footer>
