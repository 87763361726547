import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ButtonModule,
  FormControlModule,
  FormGroupModule,
  ModalModule,
} from '@zipari/design-system';
import { ZipBusyModule } from '../../../../shared/modules/zip-busy/zip-busy.module';
import { EditUserProvisioningComponent } from '../..';
import { ProvisionerModule } from '../provisioner/provisioner.module';
import { ProvisionerService } from '../provisioner/provisioner.service';
import { ViewUserProvisioningModule } from '../view/view-user-provisioning.module';

@NgModule({
  declarations: [EditUserProvisioningComponent],
  exports: [EditUserProvisioningComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormControlModule,
    FormGroupModule,
    ButtonModule,
    ProvisionerModule,
    ZipBusyModule,
    ModalModule,
    ViewUserProvisioningModule,
  ],
  providers: [ProvisionerService],
})
export class EditUserProvisioningModule {}
