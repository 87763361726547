/* eslint-disable no-underscore-dangle */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { APIService } from '../../../../../shared/services';
import { ProviderUpdateToolService } from '../../provider-update-tool.service';

@Component({
  selector: 'dropdown-array',
  templateUrl: './dropdown-array.component.html',
  styleUrls: ['./dropdown-array.component.scss'],
})
export class DropdownArrayComponent implements OnInit {
  @Input() config: any;
  @Input() key: string;
  @Output() formCreated = new EventEmitter<any>(true);

  busy: Subscription;
  options: any[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private api: APIService,
    public mp: ProviderUpdateToolService
  ) {}

  ngOnInit() {
    if (this.config.options) {
      this.options = this.config.options;
      this.defaultValueSelection(this.options);
    }
    if (this.config.apiEndpoint) {
      this.busy = this.api
        .get(this.config.apiEndpoint)
        .subscribe((resp: any) => {
          this.populateDropdown(resp.results, this.config.dropdownDisplayProp);
          this.defaultValueSelection(resp);
          for (const [index, control] of this.formArray.controls.entries()) {
            if (control instanceof UntypedFormGroup) {
              // console.log(this.formArray.value[index].selection)
              let value: any;
              if (this.formArray.value[index].selection) {
                value = this._defaultValueSelection(
                  this.formArray.value[index].selection
                );
                const formGroup: any = this.formArray.at(
                  index
                ) as UntypedFormGroup;
                if (formGroup) formGroup.controls['selection'].setValue(value);
              }
            }
          }
          this.handleFormCreated();
        });
    }
  }

  handleFormCreated() {
    this.formCreated.emit();
  }

  defaultValueSelection(resp: any) {
    if (!this.getInitState) {
      // if no state, add values
      this.setInitState('opened');
      if (Array.isArray(this.contextData)) {
        this.contextData.forEach((element, index) => {
          const value: any = this._defaultValueSelection(element);
          if (index === 0) {
            const formGroup: any = this.formArray.at(0) as UntypedFormGroup;
            if (formGroup) formGroup.controls['selection'].setValue(value);
          } else {
            const val: any = this.fb.group({ selection: '' });
            val.controls['selection'].setValue(value);
            this.formArray.push(val);
          }
        });
      }
    } // if opened but not edited, set values
    else if (this.getInitState === 'opened') {
      if (Array.isArray(this.contextData)) {
        this.contextData.forEach((element, index) => {
          const value: any = this._defaultValueSelection(element);
          const formGroup: any = this.formArray.at(index) as UntypedFormGroup;
          if (formGroup) formGroup.controls['selection'].setValue(value);
        });
      }
    }
  }

  _defaultValueSelection(element: any) {
    const searchField: any = this.config.idMatch
      ? 'id'
      : this.config.dropdownDisplayProp;
    let searchedField: any = element[searchField];
    if (!searchedField) searchedField = element;
    const option: any = this.options.find(
      (item: any) => item.value[searchField] === searchedField
    );
    let value: any;
    if (option) value = option.value;

    return value;
  }

  populateDropdown(results: any[], prop: string) {
    for (const item of results) {
      this.options.push({
        label: this.getObjectProperty(item, prop),
        value: item,
      });
    }
  }

  getObjectProperty(obj, keys) {
    return keys.split('.').reduce(function (result, key) {
      return result[key];
    }, obj);
  }

  get formArray() {
    return this.mp
      .getFormGroup(this.key)
      .get(this.config.prop) as UntypedFormArray;
  }

  get contextData(): any[] {
    return this.mp.getContextData(this.key)[this.config.prop];
  }

  get getInitState() {
    if (!this.mp.getInitState(this.key)) return true;

    return this.mp.getInitState(this.key)[this.config.prop];
  }

  setInitState(state: string) {
    this.mp.getInitState(this.key)[this.config.prop] = state;
  }

  addArrayItem() {
    const validators: any[] = [];
    if (this.config.required) {
      validators.push(Validators.required);
    }
    const val: any = this.fb.group({ selection: ['', validators] });
    this.formArray.push(val);
  }

  deleteArrayItem(index) {
    this.formArray.removeAt(index);
  }

  getMaxSize() {
    return this.formArray.length;
  }

  public getJSONValue() {
    return JSON.stringify(this.mp.getFormGroup(this.key).value);
  }
}
