import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { SwitchConfiguration } from '@zipari/shared-ds-util-form';

@Component({
  selector: 'control-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class ControlSwitchComponent implements ControlValueAccessor, OnInit {
  @Input() config: SwitchConfiguration;
  @Input() control: any;

  value: boolean;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  propagateChange = (_: any) => {
    return;
  };

  onTouched = () => {
    return;
  };

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  ngOnInit() {
    this.value = this.control.value;
  }

  toggleSwitch() {
    this.control.patchValue(!this.value);
    this.value = this.control.value;
  }
}
