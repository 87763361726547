import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../../shared/services';
import { TableCellClickEvent } from '../../../shared/models/shared/CellClickEvent.model';

// replace 'manage-access-requests' with your component name that matches component folder
@Component({
  selector: 'manage-access-requests',
  templateUrl: './manage-access-requests.component.html',
  styleUrls: ['./manage-access-requests.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

// rename component name to component folder name Camelcased with `Component` at the end
export class ManageAccessRequestsComponent implements OnInit {
  config;
  constructor(
    public route: ActivatedRoute,
    public configService: ConfigService,
    public router: Router
  ) {}

  ngOnInit() {
    this.config = this.configService?.getPageConfig('manage-access-requests');
  }

  cellClick(event: TableCellClickEvent) {
    this.router?.navigate([
      `provider-portal/payer-admin/manage-access-request-detail/${event.context?.row?.id}/`,
    ]);
  }
}
