import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@zipari/design-system';
import { SiteLocationAttestationModule } from '../provider-update-tool/site-locations-attestation/site-location-attestation.module';
import { DashboardComponent } from './dashboard.component';

@NgModule({
  declarations: [DashboardComponent],
  imports: [CommonModule, SiteLocationAttestationModule, IconModule],
})
export class DashboardModule {}
