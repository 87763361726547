import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { randomString } from '@zipari/shared-util-common';
import { accordion, fade } from '../../shared/animations';
import { BREAKPOINTS } from '../../shared/constants';
import { Notification } from './notification.model';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: [accordion, fade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent {
  @Input() notification: Notification;

  @Output() readNotification: EventEmitter<any> = new EventEmitter();
  @Output() deleteNotification: EventEmitter<number> = new EventEmitter();
  @Output() showMobileNotification: EventEmitter<any> = new EventEmitter();
  @Output() hideMobileNotification: EventEmitter<any> = new EventEmitter();
  @Input() isNotificationOpen: boolean;
  @Output() isNotificationOpenChange: EventEmitter<boolean> =
    new EventEmitter();

  breakpoint = BREAKPOINTS.SMALL;
  notificationId = randomString();

  toggleNotification(notification: Notification) {
    if (!notification.is_read) {
      this.readNotification.emit();
    }

    this.isNotificationOpen = !this.isNotificationOpen;
    this.isNotificationOpenChange.emit(this.isNotificationOpen);
  }

  delete() {
    this.deleteNotification.emit();
  }
}
