<form [zipBusy]="busy" [formGroup]="group" *ngIf="group">
  <div class="form-control__label__wrapper">
    <div class="left">
      <label class="form-control__label t-bold">
        <p class="t-data t-bold" *ngIf="config?.label">{{ config?.label }}</p>
        <p
          *ngIf="config?.validators && config?.label"
          class="t-data t-bold spaced-star"
        >
          {{ '*' }}
        </p>
      </label>
      <label class="form-control__label">
        <p [innerHTML]="config?.subheader" class="subheader"></p>
      </label>
    </div>
  </div>
  <div class="diagnosis-table" *ngIf="selectedRowItem?.length > 0">
    <table class="generic_procedure-diagosis-table">
      <tr
        class="generic_procedure-diagosis-table_row"
        *ngFor="let item of selectedRowItem; let indexOfelement = index"
      >
        <td>
          <b>{{ item?.row?.code }}</b>
        </td>
        <td>
          <div class="diagnosis-table-item-entry">
            <div>
              {{ item?.row?.short_description || item?.row?.description }}
            </div>
            <div class="isPrimaryPill" *ngIf="item?.row?.is_primary">
              Primary
            </div>
          </div>
        </td>
        <td
          *ngIf="!config?.isDisabled"
          class="isCursor generic_procedure-diagosis-table_remove_button"
          (click)="removeDiagnosisAtPropAndIndex(item?.row, indexOfelement)"
        >
          Remove
        </td>
      </tr>
    </table>
  </div>
  <zip-button
    *ngIf="state === 'edit' && !config?.isDisabled"
    class="centered-base_page__always_on_top__centered-button"
    [config]="config?.diagnosisButton"
    (zipButtonClicked)="toggleFlyout($event)"
  >
  </zip-button>

  <div
    *ngIf="
      selectedRowItem?.length == 0 ||
      (group?.get(config?.prop).invalid &&
        (group?.get(config?.prop).dirty || group?.get(config?.prop).touched))
    "
  >
    <div
      class="error"
      *ngIf="selectedRowItem?.length == 0 || group?.get(config?.prop).errors"
    >
      <icon name="warning" class="warn-icon"
        ><i class="icon icon--font ng-star-inserted icon--small warning">
          warning
        </i>
      </icon>
      <p class="t-caption t-error">
        {{ config?.errorMessage || 'Please enter a valid input.' }}
      </p>
    </div>
  </div>
</form>

<modal
  #flyout
  *ngIf="showModal"
  [config]="config?.flyout"
  [condition]="showModal"
  (cancel)="toggleFlyout()"
>
  <div body>
    <div class="header-associate">
      <p>
        {{ config?.flyout?.subheader }}
      </p>
    </div>
    <zip-table
      *ngIf="loaded"
      (selectedRadioRow)="selectedRadioRow($event)"
      (selectedRows)="handleSelectedRows($event)"
      [options]="config?.flyout?.table"
      [preSelectedData]="selectedRowItem"
      (customMethod)="callCxEvent($event)"
    >
    </zip-table>
  </div>

  <div footer>
    <zip-button
      [disabled]="isDisabled()"
      level="high"
      [content]="config?.flyout?.submitButton"
      (click)="add()"
    ></zip-button>
  </div>
</modal>
