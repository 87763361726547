<ng-container *ngIf="control && config">
  <ng-container *ngFor="let checkbox of config.options; let i = index">
    <label
      class="form-control--checkbox__wrapper"
      [ngClass]="{
        'form-control--checkbox__wrapper--inline': config.inline === true,
        'form-control--checkbox__wrapper--top-aligned':
          config.isTopAligned === true,
        'form-control--checkbox__wrapper--disabled': checkbox?.isDisabled
      }"
      [attr.for]="getCheckboxId(config, i)"
    >
      <input
        #box
        class="form-control form-control--checkbox"
        [attr.disabled]="checkbox?.isDisabled || null"
        [attr.readonly]="checkbox?.readonly || null"
        [attr.type]="config.type"
        [attr.name]="checkbox?.name"
        [checked]="control.controls[i]?.value === checkbox.value ? true : null"
        [attr.aria-checked]="control.controls[i]?.value === !!checkbox.value"
        [value]="checkbox?.value"
        (click)="toggle(checkbox, i)"
        [id]="getCheckboxId(config, i)"
        [attr.aria-describedby]="(config?.id || config?.prop) + '-label'"
        [attr.aria-errormessage]="
          'error_' + (config?.id || config?.prop) + '-input'
        "
        [attr.aria-invalid]="
          (control.dirty || control.touched) && !control.valid
        "
        ngDefaultControl
        role="checkbox"
        [formControl]="
          extraText[checkbox.label]
            ? extraText[checkbox.label]
            : control.controls[i]
        "
      />
      <ng-container *ngIf="checkbox?.label">
        <p
          class="t-data"
          [ngClass]="{
            'form-control--checkbox__option-title':
              checkbox?.multiLines?.length || config.isBold
          }"
          [innerHTML]="checkbox?.label"
        ></p>
      </ng-container>
    </label>
    <ng-container *ngIf="checkbox?.multiLines?.length">
      <ng-container *ngFor="let lineOfText of checkbox.multiLines">
        <p
          class="t-data form-control--checkbox__multi-lines"
          [innerHTML]="lineOfText.label"
        ></p>
      </ng-container>
    </ng-container>

    <div
      class="form-control--checkbox__other"
      *ngIf="
        checkbox.type === 'other' &&
        extraText[checkbox.label] &&
        extraText[checkbox.label].value
      "
    >
      <control-text [control]="control.controls[i]" [config]="otherConfig">
      </control-text>
    </div>
  </ng-container>
</ng-container>
