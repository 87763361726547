export const CREDIT_CARD_REGEX = {
  visa: {
    regex: /^4[0-9]{12}(?:[0-9]{3})?$/,
    label: 'Visa',
  },
  mastercard: {
    regex:
      /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/,
    label: 'Mastercard',
  },
  amex: {
    regex: /^3[47][0-9]{13}$/,
    label: 'American Express',
  },
  discover: {
    regex:
      /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
    label: 'Discover',
  },
  diners_club: {
    regex: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    label: 'Diners Club',
  },
  jcb: {
    regex: /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/,
    label: 'Japan Credit Bureau',
  },
};
