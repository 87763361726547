import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnConfig } from '../../../models/column.model';
import { TableSorts } from '../../../models/table.model';

export class SortByField {
  column: ColumnConfig;
  sortOrder: 'asc' | 'desc';
}

@Component({
  selector: 'table-header-cell',
  templateUrl: './header-cell.component.html',
  styleUrls: ['./header-cell.component.scss'],
})
export class HeaderCellComponent implements OnInit {
  @Input() column: ColumnConfig;
  @Input() sorts: TableSorts;
  @Input() stackedIcons: boolean;
  @Input() dataExists: boolean;

  @Output() headerButtonClicked: EventEmitter<Event> =
    new EventEmitter<Event>();
  @Output() onSortByField: EventEmitter<SortByField> =
    new EventEmitter<SortByField>();

  sortOrder: 'asc' | 'desc';

  constructor() {}

  ngOnInit(): void {
    this.sortOrder =
      this.sorts?.[this.column?.sortByProp] || this.sorts?.[this.column?.prop];
  }

  onClick(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.column.sortable) {
      if (this.sortOrder === 'asc') {
        this.sortOrder = 'desc';
      } else {
        this.sortOrder = 'asc';
      }

      this.onSortByField.emit({
        column: this.column,
        sortOrder: this.sortOrder,
      });
    }
  }

  iconSortActive(target: string): boolean {
    if (
      this.sorts &&
      (this.sorts[this.column.sortByProp] || this.sorts[this.column.prop])
    ) {
      return this.sortOrder === target;
    }
  }
}
