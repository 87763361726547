import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  LinkConfig,
  NavigationConfiguration,
  NavigationLink,
  NavigationStateData,
} from '@zipari/shared-ds-util-navigation';
import { cloneObject } from '@zipari/web-utils';
import { filter } from 'rxjs/operators';
import { NavigatorService } from '../../../../../lib/shared/services/navigator.service';
import { stopEvent } from '../../../../shared/utils/event';
import { NavigationService } from './../../navigation.service';
import { removeSpecialCharacters } from '@zipari/shared-util-common';
import { ExternalSiteModalConfig } from '../../../external-site-warning-modal/external-site-warning-modal.model';

@UntilDestroy()
@Component({
  selector: 'nav-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss'],
  // turned off to allow overwriting of styles in mobile menu
  encapsulation: ViewEncapsulation.None,
})
export class LinksComponent implements OnInit {
  @Input() config: NavigationConfiguration;
  @Input() data: NavigationStateData;
  @Input() externalSiteModalConfig: ExternalSiteModalConfig;
  @Input() isMobileMenu = false;
  @Output() navItemClicked = new EventEmitter<NavigationLink>();

  activeRoute: string;
  filteredConfig: NavigationConfiguration;

  constructor(
    public navigator: NavigatorService,
    private navigation: NavigationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.removeFeedbackLink();
    this.rightAlignLastLink();
    this.listenForActiveRoute();
  }

  private rightAlignLastLink() {
    const clonedConfig = cloneObject(this.config);
    const numberOfLinks = clonedConfig.links.length;

    const updatedLinks = clonedConfig.links.map((link, index) => ({
      ...link,
      menuRightAlign: index === numberOfLinks - 1,
    }));

    this.filteredConfig = { ...clonedConfig, links: updatedLinks };
  }

  private removeFeedbackLink() {
    const clonedConfig = cloneObject(this.config);

    if (!this.isMobileMenu) {
      clonedConfig.links = clonedConfig.links.filter(
        (link) => link.key !== 'mp_feedback'
      );
    }
    this.filteredConfig = clonedConfig;
  }

  private listenForActiveRoute() {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe((link: NavigationEnd) => {
        this.activeRoute = link.url;
      });
  }

  public getLinkConfig(link: NavigationLink): LinkConfig {
    return this.navigation.getLinkConfig(link);
  }

  public isMenuOpen(link: NavigationLink) {
    return !!(this.data.openMenuItem && link === this.data.openMenuItem);
  }

  public onLinkClick(linkData: { event: Event; link: NavigationLink }): void {
    if (!this.isMobileMenu) {
      stopEvent(linkData.event);
    }
    this.navItemClicked.emit(linkData.link);
  }

  public createDOMId(sourceString: string) {
    return removeSpecialCharacters(sourceString).toLowerCase();
  }
}
