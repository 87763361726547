<form
  [formGroup]="group"
  class="form-control__wrapper"
  [ngClass]="{ 'form-control__wrapper--flat': direction === 'filter' }"
>
  <label class="form-control__label">
    <p class="t-bold">{{ config.label }}</p>
  </label>

  <quill-editor
    [formControlName]="config.prop"
    (onSelectionChanged)="onSelectionChanged($event)"
    (onContentChanged)="onContentChanged($event)"
    [modules]="{
      toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold'],
        ['link'],
        [{ color: [] }],
        [{ list: 'bullet' }],
        [{ align: [] }],
        ['clean']
      ]
    }"
  >
  </quill-editor>
</form>
