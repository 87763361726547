import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TemplateRefDirective } from './template-ref.directive';

import { TabsComponent } from './tabs.component';

@NgModule({
  declarations: [TabsComponent, TemplateRefDirective],
  imports: [CommonModule],
  exports: [TabsComponent, TemplateRefDirective],
})
export class TabsModule {}
