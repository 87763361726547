import { OverlayModule } from '@angular/cdk/overlay';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import {
  AddressPipe,
  BuildQueryPipe,
  DoesListHaveValuesPipe,
  EqualityFilterPipe,
  FullNamePipe,
  GetValuePipe,
  MaskPipe,
  PhoneNumberPipe,
  PipesModule,
  ReadMorePipe,
  SafeNumberPipe,
  StringBuilderPipe,
} from '@zipari/web-utils';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProviderUpdateToolService } from './provider-portal/templates/provider-update-tool/provider-update-tool.service';
import { ConfirmationModalComponent } from './shared/components/confirmation-modal/confirmation-modal.component';
import { CoreModule } from './shared/core.module';
import { SquidexAuthInterceptor } from './shared/modules/cms';
import { TimeoutModalModule } from './shared/modules/timeout-modal/timeout-modal.module';
import { PipeModule } from './shared/pipes/pipe.module';
import { AuthService, ConfigService } from './shared/services';
import { AnalyticsService } from './shared/services/analytics.service';
import { SentryErrorHandlerService } from './shared/services/sentry.service';

@NgModule({
  declarations: [AppComponent, ConfirmationModalComponent],
  imports: [
    AppRoutingModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    OverlayModule,
    PipeModule,
    CoreModule.forRoot(),
    PipesModule,
    NgIdleKeepaliveModule.forRoot(),
    TimeoutModalModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useFactory: resolveConfigUserData,
      deps: [ConfigService, AuthService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SquidexAuthInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandlerService,
    },
    Title,
    AnalyticsService,
    StringBuilderPipe,
    AddressPipe,
    GetValuePipe,
    EqualityFilterPipe,
    BuildQueryPipe,
    ProviderUpdateToolService,
    DoesListHaveValuesPipe,
    FullNamePipe,
    PhoneNumberPipe,
    MaskPipe,
    ReadMorePipe,
    SafeNumberPipe,
    CurrencyPipe,
    DatePipe,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function resolveConfigUserData(
  configService: ConfigService,
  authService: AuthService
) {
  let configPromise;

  const useMergedConfig = environment['configs']?.['useMergedConfig'];
  let useLocalConfig = environment['configs']?.['useLocalConfig'];

  if (typeof script_useLocalConfig !== 'undefined') {
    // gets called if local development using command line configuration for useLocalConfig
    useLocalConfig = script_useLocalConfig;
  }

  if (useLocalConfig) {
    configPromise = configService.initializeLocalConfig(environment['app']);
  } else if (useMergedConfig) {
    configPromise = configService.initializeAllConfig(environment['app']);
  } else if (!useLocalConfig && overrideDbFromLocalConfig) {
    configPromise = configService.initializeDBConfigWithLocalOverride(
      environment['app'],
      {
        setConfig: true,
      }
    );
  } else {
    configPromise = configService.initializeConfig();
  }
  const userPromise = environment['configs']?.['ignoreAuth']
    ? Promise.resolve({})
    : authService.getUser(environment['configs']);

  return () =>
    // Ensures logged in user set only after config and user data fetched
    Promise.all([configPromise, userPromise]).then(([config, user]) => {
      authService.setAppConfig(config);
      if (!environment['configs']?.['ignoreAuth']) {
        authService.setLoggedInUser(user, environment['configs']);
      }
    });
}
