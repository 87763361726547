<div
  class="FormGroupArrayFooter"
  [ngClass]="{
    FormGroupArrayFooter__Below: config?.isFooterBelowContent,
    'u-justifyCenter': config?.showFormWithAccordion,
    'u-justifyEnd': !config?.showFormWithAccordion
  }"
>
  <ng-container *ngIf="!config.hideActions">
    <zip-button
      *ngIf="hideAddButton()"
      [config]="config.addButton"
      (zipButtonClicked)="addFormGroup()"
    >
    </zip-button>
  </ng-container>
</div>
