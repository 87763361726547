export enum SelectionTypes {
  deselected = 'deselected',
  selected = 'selected',
}

export interface SetFilterValues {
  values: {
    [id: string]: string;
  };
  clearAll: boolean;
}

export const max_column_width_default = 150;
