import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FormControlOptionsParams } from '../../shared/models';

import { cloneObject, isInEnum } from '../../shared/utils/object';
import {
  AllControlsConfiguration,
  controlTypes,
  formControlDirections,
  nonLabelPositions,
} from '@zipari/shared-ds-util-form';
import { FileUploadInputs } from './../../chords/file-upload/file-uploader.constants';
import { FormControlService } from './form-control.service';
import { FormExclusionStore } from './form-exclusion-store.service';
import {
  FormControlValidatorsService,
  ZipValidator,
} from './shared/validators/validators.service';
import { CustomEndpoint } from './typeahead/control-typeahead.model';

@Component({
  selector: 'form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormControlComponent implements OnInit, AfterViewInit {
  @Output() inputChanged = new EventEmitter<any>();
  @Output() selected = new EventEmitter<any>();
  @Output() linkClick = new EventEmitter<any>();
  @Output() iconClick = new EventEmitter<any>();
  @Output() fileUploaded = new EventEmitter<any>();
  @Output() fileRemoved = new EventEmitter<any>();
  @Output() pendingAttachments = new EventEmitter<any>();
  @Output() typeaheadInputCleared = new EventEmitter<any>();
  @Output() iconRightClicked = new EventEmitter<any>();

  @Input() group: UntypedFormGroup;
  @Input() control: UntypedFormControl;
  @Input() config: AllControlsConfiguration;
  @Input() customInputTemplate: TemplateRef<any>;
  @Input() canToggle: boolean;
  @Input() disableEndpointOptions: boolean;
  @Input() selectedIndex: number;
  @Input() customEndpoint: CustomEndpoint;
  @Input() formControlOptionsParams: FormControlOptionsParams[];
  @Input() fileUploadInputs: FileUploadInputs;
  public hasControlFocusEvent = false;
  public hasFocusedOut = false;
  errors: ZipValidator[] = [];
  types = controlTypes;
  nonLabelPositions = nonLabelPositions;
  init: boolean;
  showFocus: boolean;
  isRequired: boolean;
  showControl;
  directionVal;

  constructor(
    private formExclusion: FormExclusionStore,
    public formControlValidatorsService: FormControlValidatorsService,
    public formControlService: FormControlService,
    public el: ElementRef
  ) {}

  get type(): controlTypes {
    return this.config.type;
  }

  get invalid() {
    return this.displayedError && (this.control.dirty || this.control.touched);
  }

  get displayedError() {
    const subControls = this.control['controls'];

    if (subControls) {
      const errorMessages: string[] = Object.keys(subControls).map((ctrlName) =>
        this.formControlService.determineErrorMessageFromErrorObject(
          subControls[ctrlName],
          this.errors,
          this.config
        )
      );

      return errorMessages.filter((err) => !!err).join(', ');
    } else {
      return this.formControlService.determineErrorMessageFromErrorObject(
        this.control,
        this.errors,
        this.config
      );
    }
  }

  get direction() {
    return this.directionVal;
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('direction')
  set direction(direction: string) {
    if (isInEnum(formControlDirections, direction)) {
      this.directionVal = direction;
    }
  }

  public get label(): string {
    if (this.requiredLabel) {
      return `${this.config.label} ${this.requiredLabel}`;
    }

    return this.config.label;
  }

  public get isRequiredLabelString(): boolean {
    return typeof this.config.requiredLabel === 'string';
  }

  public get requiredLabel(): string {
    if (!this.isRequired || !this.config.hasOwnProperty('requiredLabel')) {
      return null;
    }

    let label: string;
    if (this.isRequiredLabelString) {
      label = this.config.requiredLabel;
    } else {
      label = '';
    }

    return label;
  }

  // // Lifecycles
  ngOnInit() {
    this.mergeGlobalConfig();
    this.setErrors();
    this.setEndpointOptions(this.disableEndpointOptions);
    this.setLabel();
    this.setRequired();
    this.setExclusion();
    this.showControl = true;
  }

  ngAfterViewInit() {
    this.init = true;
  }

  // // onInit Functions
  /** merge global config for a control type into @Input config  */
  mergeGlobalConfig() {
    this.formControlService.retrieveConfigOverride(this.config);
  }

  /** Get a structure to show the error messages properly */
  setErrors() {
    const allValidators = {
      ...this.formControlValidatorsService.validators,
      ...this.formControlValidatorsService.groupValidators,
    };
    this.errors = Object.keys(allValidators)
      .filter((validator) => allValidators[validator].message)
      .map((validator) => ({
        message: allValidators[validator].message,
        key: validator,
      }));
  }

  checkForHtmlEntities(data: string) {
    return JSON.parse(JSON.stringify(data).replace(/\&ndash;/g, '-'));
  }

  /** Retrieves options based on endpoint given */
  setEndpointOptions(disableEndpointOptions?) {
    if (
      this.config['endpoint'] &&
      this.type !== controlTypes.typeahead &&
      !disableEndpointOptions
    ) {
      this.formControlService
        .getFormControlOptions(
          this.config['endpoint'],
          this.config['endpointTemplate'],
          this.formControlOptionsParams
        )
        .subscribe((options) => {
          this.config = cloneObject(this.config);

          if (this.config['placeholder']) {
            if (this.config['putPlaceholderDefaultAsLastItem']) {
              options.push({
                label: this.config['placeholder'],
                value: null,
              });
            } else {
              options.unshift({
                label: this.config['placeholder'],
                value: null,
              });
            }
          }
          this.config['options'] = this.checkForHtmlEntities(options);

          /**
           * This is meant to handle automatically setting a dropdown option retrieved from the API as the default/initial option
           */
          if (this.config.defaultValue) {
            if (this.config.defaultValue === 'true') {
              this.control.setValue(options[0].value);
            } else {
              const option = options.find(
                (opt) => opt.value === this.config.defaultValue
              );
              if (Array.isArray(options) && option) {
                this.control.setValue(this.config.defaultValue);
              }
            }
          }
        });
    }
  }

  setLabel() {
    if (!this.config['ariaLabel'] && Object.isExtensible(this.config)) {
      this.config['ariaLabel'] =
        this.config.label || this.config['placeholder'] || null;
    }
  }

  /** Check control against excluded values in store (disable controls across many form instances) */
  setExclusion() {
    this.config['excludeOn'] &&
      this.formExclusion.exclusions$.subscribe((excArr) => {
        if (
          excArr.some((exclusion) => exclusion === this.config['excludeOn'])
        ) {
          this.control.setValue(null);
          this.control.disable();
        } else {
          this.control.enable();
        }
      });
  }

  setRequired() {
    this.isRequired =
      this.config &&
      this.config.validators &&
      this.config.validators.find(
        (validator) =>
          validator === 'required' ||
          (validator.name && validator.name === 'required')
      );
  }

  handleIconRighClicked() {
    this.iconRightClicked.emit();
  }

  handleFocusOut() {
    this.showFocus = false;
    this.hasFocusedOut = true;
    const invalidControl = this.el.nativeElement.querySelector('.ng-invalid');
    const invalidMessage = this.el.nativeElement.querySelector('.is-invalid');

    if (invalidControl) {
      const scrollOptions: ScrollOptions = this.config?.scrollOptions || {
        behavior: 'smooth',
      };

      invalidControl.scrollIntoView(scrollOptions);
    }

    if (invalidMessage) {
      invalidMessage.focus();
    }
  }

  handleFocus() {
    this.hasControlFocusEvent = true;
    if (this.init && this.config['focus']) {
      this.showFocus = true;
    }
  }

  get labelFor(): string {
    const hasFor =
      this.config &&
      [controlTypes.dropdown, controlTypes.radio].some(
        (t) => t === this.config.type
      );

    return hasFor ? this.config['prop'] || this.config['name'] : null;
  }
}
