import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ClaimRedeterminationComponent } from './claim-redetermination.component';
@NgModule({
  declarations: [ClaimRedeterminationComponent],
  exports: [ClaimRedeterminationComponent],
  imports: [CommonModule, SharedModule],
})
export class ClaimDeterminationModule {}
