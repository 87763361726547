<modal
  *ngIf="config"
  [config]="config.modal"
  [condition]="showAdditionalDetailsModal"
  (cancel)="closeModal.emit()"
>
  <div body>
    <p [innerHTML]="config.modal.description"></p>
    <div class="AdditionalDetailsForm__FormContainer">
      <form-group
        [configs]="config.additionalDetailsForm.controls"
        [form]="additionalDetailsForm"
      ></form-group>
      <p
        *ngIf="additionalDetailsForm.value?.changeInOwnership"
        [innerHTML]="config.modal.effectiveDateNote"
      ></p>
    </div>
  </div>

  <div class="AdditionalDetailsForm__Footer" footer>
    <zip-button
      [config]="config.footer.cancelButton"
      (zipButtonClicked)="closeModal.emit()"
    ></zip-button>
    <zip-button
      [disabled]="!additionalDetailsForm.valid"
      [config]="config.footer.submitButton"
      (zipButtonClicked)="submitClicked.emit()"
    ></zip-button>
  </div>
</modal>
