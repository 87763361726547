import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'readmore' })
export class ReadMorePipe implements PipeTransform {
  transform(
    text: string,
    options = {
      maxCharacters: 60,
      showAll: false,
    }
  ): string {
    return !options.showAll
      ? text.substring(0, options.maxCharacters)
      : `${text}&nbsp;`;
  }
}
