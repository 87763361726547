/* eslint no-magic-numbers: "off", array-callback-return: "off" */
/* eslint-disablebak @zipari/no-key-as-generic-prop */
/* eslint-disablebak @zipari/no-assigning-over-temp-variables-in-list-operators */
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { ModalConfig } from '@zipari/shared-ds-util-modal';
import { max_column_width_default } from './../components/zip-table.constants';
import { ColumnConfig } from './column.model';

export enum SortTypes {
  asc = 'asc',
  desc = 'desc',
}

export enum ZipFilterViewOptions {
  rows = 'rows',
  columns = 'columns',
}

export enum ZipSidebarOrHeader {
  header = 'header',
  sidebar = 'sidebar',
}

/** la
 * INTERNAL USE: normal map in structure --> {sort prop: sort direction}
 */
export class TableSorts {}

export class TableFilters {
  type: string;
  prop: string;
  isSearchCaseInSensitive?: boolean;

  constructor(options) {
    Object.assign(this, options);
  }
}

export class TableDetailConfig {
  minColumns = 2;
  enabled = true;
  multi = true;
  template = null;
  init: DetailInitModel;

  constructor(options) {
    Object.assign(this, options);
  }
}

export class DetailInitModel {
  startOpen: boolean | number;
}

export class TableMessages {
  title?: string = '';
  noData = 'No Data Found';
  tooltip?: string;

  constructor(options) {
    Object.assign(this, options);
  }
}

export class TablePagingConfig {
  /** INTERNAL USE: the total count of rows for the table */
  count?: number = 0;

  enabled = true;
  pageIndex = 0;
  pageSize = 10;
  pageName: '';

  constructor(options) {
    if (options.enabled === false) {
      this.enabled = false;
      this.pageIndex = undefined;
      this.pageSize = undefined;
    } else {
      Object.assign(this, options || {}, { enabled: true });
    }
  }
}

export class TableSelectionConfig {
  enabled = false;
  border = true;
  arrow = false;

  init: any;

  multiple = false;
  persist = false;
  limitSelectAllToPage = false;
  editHeader = false;
  editHeaderConfig: any;

  constructor(options) {
    let enabled = this.enabled;

    if (options.hasOwnProperty('enabled')) {
      enabled = options.enabled;
    } else if (
      options.border ||
      options.arrow ||
      options.multiple ||
      options.editHeader
    ) {
      enabled = true;
    }

    if (options.editHeader) {
      options.multiple = true;
    }

    Object.assign(this, options, { enabled });
  }
}

export class TableGroupPropConfig {
  name: string;
}

export class TableGroupConfig {
  prop: string = null;
  enabled: boolean = null;
  rollup?: any = {};
  showInDetail?: boolean = true;
  groupPropConfig?: TableGroupPropConfig;
  sort?: SortTypes = SortTypes.asc;

  constructor(options) {
    Object.assign(this, options, {
      enabled:
        options.prop && !options.hasOwnProperty('enabled')
          ? true
          : options.enabled,
    });
  }
}
export interface OptionsModal {
  message: string | string[];
  icon?: string;
  text?: string;
  enabled?: boolean;
}

export class ExportConfiguration {
  icon = 'download';
  text = '';
  enabled = false;

  exportAsyncLimit: any;
  /** INTERNAL USE: the link to be used for doing an export of a table */
  exportLink?: string;
  restMethod?: string;
  queryParams?: string;
  requestBody?: any;
  exportId?: any;
  useLocalCsvDownload = false;
  formatResponse?: boolean;
  options?: any;
  constructor(
    options: OptionsModal = {
      message: '',
      icon: '',
      text: '',
      enabled: false,
    }
  ) {
    let enabled = this.enabled;
    if (!options.hasOwnProperty('enabled') && (options.icon || options.text)) {
      enabled = true;
    } else if (options.hasOwnProperty('enabled')) {
      enabled = options.enabled;
    }
    Object.assign(this, options, { enabled });
  }
}

export class TableFooter {
  onlyShowIfPaginated: boolean;
  doNotShowCountUnlessPaginated = false;
  areNumberedButtonsPresent = true;
  isLabelPresent = true;
  fill?: string;

  /** Choose which buttons and display in pagination to show */
  showFirstLastPageDoubleArrows = true;
  showSinglePagePaginationButtons = true;
  showCurrentPageNumberOnly = false;

  /** For ease of use this class constructor is utilized in order to handle the defaults
   * @param options - the raw options provided by the user
   * */
  constructor(options) {
    Object.assign(this, options);
  }
}

export class TablePage {
  pageSize = 10;
  count = 0;
  totalPages = 0;
  pageNumber = 0;
  offset = 0;
  next?: string;
  previous?: string;

  constructor(count, page?) {
    Object.assign(this, page || {});
    this.count = count;
    this.totalPages = this.count / this.pageSize;
  }
}

export class TablePagedData {
  data: any[] = [];
  page: TablePage = new TablePage(0);

  constructor(data, page?) {
    Object.assign(this, { page: new TablePage(data.count, page) });
    Object.assign(this, { data: data.results });
  }
}

export class TableMaxHeight {
  enabled?: boolean = false;
  height: number;

  constructor(options) {
    Object.assign(this, options);

    if (options.height) {
      this.enabled = true;
    }
  }
}

export class TableVisuals {
  theme: string;
  maxHeight: TableMaxHeight;
  filtersOwnRow: boolean;
  tooltip?: any;

  constructor(options) {
    Object.assign(this, options);
  }
}

export class TableSorting {
  init: any;
  stackedIcons?: boolean;
}

export interface Prompt {
  type: 'success' | 'error' | 'warning';
  key: string;
  message: string;
  icon?: string;
  customCSSClass?: string;
  action?: {
    [key: string]: any;
  };
}
export class TableConfig {
  showSpinner: boolean;
  showInnerSpinner?: boolean;
  sorting: TableSorting;
  visuals?: TableVisuals;
  messages?: TableMessages;

  /** INTERNAL USE: non query filters */
  nonQueryFilters = [];

  /** INTERNAL USE: only query filters */
  queryFilters = [];
  filters?: TableFilters[];
  filterOptions?: FilterOptionsConfig;
  exportConfig: ExportConfiguration;
  button?: ButtonConfig;
  selection: TableSelectionConfig;
  grouping: TableGroupConfig;
  detail: TableDetailConfig;

  paging: TablePagingConfig;
  footer?: TableFooter;
  columns: ColumnConfig[];
  endpoint: string;
  delaySearch?: boolean;
  clearDataOnError?: boolean;
  hasCustomRowClass: boolean;
  customRowClassIdentifier: string;
  restMethod?: string;
  requestBody?: any;
  showApiError?: boolean;

  /** if set to true, then the data will be formatted as per the column config and the excel
   * doc will be generated entirely in the FE instead of using the print endpoint */
  exportWithFormatting?: boolean;

  /** whether or not the zip-table should try to stop recalling the same endpoint.
   * Before making an http call if this is enabled it will make sure that a
   * change to the endpoint has been made before making another call. */
  endpointCache = true;

  /** INTERNAL USE: keeps track of what props are being sorted on */
  sorts?: TableSorts;

  /** Resets table page index to first page, then performs sort. This is an option to fix Okta pagination issues */
  setToFirstPageOnSort?: boolean = false;

  /** INTERNAL USE: keeps track of what props are being filtered on and what their values are */
  filterValues: any;
  replaceValues: any;

  /** INTERNAL USE: object to help keep track of the weights of the columns */
  columnPriorities?: any = {};

  title: string;
  subtitle: string;

  prompts: Prompt[];

  paginatedData: boolean;

  maxColumnWidth = max_column_width_default;

  /** For ease of use this class constructor is utilized in order to handle the defaults for the parts of the table
   * handles the set configurations and then assigns whatever the rest of the configuration for the table is
   * @param options - the raw options provided by the user
   * */
  constructor(options) {
    let filters;
    if (options.filters) {
      filters = options.filters.map((filter) => {
        const formattedFilter = new TableFilters(filter);
        if (formattedFilter.type === 'query') {
          this.queryFilters.push(filter);
        } else {
          this.nonQueryFilters.push(filter);
        }

        return;
      });
    }

    const exportConfig = new ExportConfiguration(options.exportConfig || {});
    const paging = new TablePagingConfig(options.paging || {});
    const selection = new TableSelectionConfig(options.selection || {});
    const grouping = new TableGroupConfig(options.grouping || {});
    const detail = new TableDetailConfig(options.detail || {});
    const newFooter = new TableFooter(options.footer || {});
    const messages = new TableMessages(options.messages || {});
    const button = new ButtonConfig(options.button);
    const newColumns = options.columns.map((col, ind) => {
      const builtCol = new ColumnConfig(col, ind);

      // set the group prop config to be passed around
      if (grouping.enabled && col.prop === grouping.prop) {
        grouping.groupPropConfig = col;
      }

      // make sure that you store the column priorities within the full table
      this.columnPriorities[builtCol.prop] = builtCol.priority;

      return builtCol;
    });

    Object.assign(this, options, {
      filters: filters,
      button: button,
      export: options.export,
      pagination: options.pagination,
      exportConfig: exportConfig,
      footer: newFooter,
      detail: detail,
      grouping: grouping,
      selection: selection,
      paging: paging,
      columns: newColumns,
      messages: messages,
    });

    if (options.hasOwnProperty('showSpinner')) {
      this.showSpinner = options.showSpinner;
    } else {
      this.showSpinner = false;
    }
  }
}

export class FilterOptionsConfig {
  buttons?: ButtonConfig[];
  searchOnSubmit?: boolean;
  formType?: string;
  flyout?: FilterFlyoutConfig;
}

export class FilterFlyoutConfig {
  mobileOnly?: boolean;
  trigger?: ButtonConfig;
  modalConfig?: ModalConfig;
  footerActions?: ButtonConfig[];
}

export class DiagnosisConfig {
  ind: number;
  row: DiagnosisRowConfig;
}
export class DiagnosisRowConfig {
  code?: string;
  description?: string;
  id?: string;
  index?: number;
  is_primary?: boolean;
  type?: string;
}
