export const claimSubmissionConstants = {
  place_of_service: 'place_of_service',
  code: 'code',
  unit_type: 'unit_type',
  priority: 'priority',
  service_unit_quantity: 'service_unit_quantity',
  procedure_id: 'procedure_id',
  description: 'description',
  documentUploadType: 'document_type',
  claimDocument: 'claim-document',
  controlDocument: 'control-document',
};

export const BillingFlyoutType = {
  billingProvider: 'flyoutOnBillingProvider',
  billingFacility: 'flyoutOnBillingFacility',
};

export const ProvidersKeys = [
  'billing_provider_location',
  'servicing_facility_location_id',
  'rendering_provider_id',
];
