<form-group
  [ngClass]="
    config?.showFormWithAccordion
      ? 'FormGroupArrayAccordionContentControls'
      : 'FormGroupArraycontrols'
  "
  [style.grid-template-columns]="
    config.columns ? setGridColumns(config.columns) : ''
  "
  [style.grid-column-gap]="config.columns ? '1.5rem' : ''"
  [configs]="config.controls"
  [form]="arrayGroup"
  [context]="context"
  [direction]="config.direction"
>
</form-group>
