<div
  class="u-flex u-alignCenter"
  (click)="onClick($event)"
  (keydown.space)="onClick($event)"
  (keydown.enter)="onClick($event)"
  [attr.aria-label]="'Column ' + column.name"
  tabindex="0"
>
  <p class="t-data t-bold">{{ column.name || column.prop }}</p>

  <i
    *ngIf="
      column.sortable &&
      sorts &&
      (sorts[column.prop] || sorts[column.sortByProp])
    "
    class="icon icon--medium icon--pointer"
    [attr.aria-label]="
      'Sort order: ' + sortOrder === 'asc' ? 'ascending' : 'descending'
    "
    tabindex="0"
    (keydown.space)="onClick($event)"
    (keydown.enter)="onClick($event)"
  >
    {{ sortOrder === 'asc' ? 'chevron_up' : 'chevron_down' }}
  </i>

  <ng-content></ng-content>
</div>
