<article *ngIf="config" class="Accordion" [class.Accordion_Card]="config.card">
  <div
    class="Accordion__Header u-flex u-column"
    (click)="toggleContent()"
    [attr.aria-controls]="config.id"
    [attr.aria-expanded]="!!isOpen"
  >
    <div
      class="Accordion__MainHeader u-flex u-row u-alignCenter"
      [ngClass]="{
        Accordion__MainHeader_IsOpen: isOpen,
        Accordion__MainHeader_IgnoreMargin: config?.ignoreMargin,
        Accordion__MainHeader_HeaderReverse: config.isHeaderReversed,
        Accordion__MainHeader_Subheader: subHeader
      }"
    >
      <icon
        *ngIf="config.icon; else defaultToggle"
        [name]="config.icon"
        [config]="{ fill: 'accordion-icon-color' }"
        role="button"
      >
      </icon>

      <ng-template #defaultToggle>
        <icon
          class="Accordion__Toggle"
          name="chevron_down"
          [config]="{
            fill: 'accordion-toggle-color',
            transform: isOpen ? 'rotate180' : null
          }"
          role="button"
        >
        </icon>
      </ng-template>
      <zip-button
        *ngIf="!config.customHeader"
        class="Accordion__Title"
        [class.Accordion__Title_Bold]="config.isHeaderBold"
        [config]="{ level: 'text', content: config.header }"
      >
      </zip-button>

      <h6 *ngIf="config.customHeader">
        <p data-test-id="customHeader" class="t-ellipsis t-bold">
          {{ config.header }}
        </p>
        <p
          *ngIf="config.subHeader"
          data-test-id="customSubHeader"
          class="t-ellipsis t-alt"
        >
          {{ config.subHeader }}
        </p>
      </h6>

      <div
        *ngIf="headerTemplateTitle"
        class="Accordion__Title"
        [class.Accordion__Title_Bold]="config.isHeaderBold"
      >
        <ng-container *ngTemplateOutlet="headerTemplateTitle"></ng-container>
      </div>

      <div
        *ngIf="headerAction"
        class="Accordion__Action u-flex u-alignCenter u-justifyEnd u-fillRemaining"
        (click)="onActionClick($event)"
      >
        <!-- Can pass in template or project content -->
        <ng-container *ngTemplateOutlet="headerAction"></ng-container>

        <ng-content *ngIf="isOpen" select="[header-action]"> </ng-content>
      </div>
    </div>
    <div
      *ngIf="subHeader"
      class="Accordion__Subheader u-flex"
      [class.Accordion__Subheader_Reverse]="config.isHeaderReversed"
    >
      <ng-container *ngTemplateOutlet="subHeader"></ng-container>
    </div>
  </div>

  <section
    class="Accordion__Content"
    *ngIf="isOpen || config.hideWhenCollapsed"
    [ngClass]="{ 'u-hidden': config.hideWhenCollapsed && !isOpen }"
    [attr.id]="config.id"
    @accordion
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
    <ng-content></ng-content>
  </section>
</article>
