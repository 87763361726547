import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { ModelAttributeConfig } from '@zipari/shared-ds-util-configuration';
import { SidePanelConfig, SidePanelErrors } from './side-panel.constants';
import { SidePanel } from './side-panel.model';

@Component({
  selector: 'mp-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent implements OnInit, OnChanges {
  @Input() config: SidePanelConfig;

  @Input() context: any; // for attribute lists...normally "Member"
  @Input() errors: SidePanelErrors;
  @Input() labelsList: string[];
  @Input() notes: string;
  @Output() addNewButtonClicked: EventEmitter<string> = new EventEmitter();
  @Output() editButtonClicked: EventEmitter<string> = new EventEmitter();
  @Output() resourceItemClicked: EventEmitter<ModelAttributeConfig> =
    new EventEmitter();
  @Output() customItemClicked: EventEmitter<string> = new EventEmitter();

  sidePanel: SidePanel;

  handleResourceClicked(evt: ModelAttributeConfig) {
    this.resourceItemClicked.emit(evt);
  }

  handleCustomAction(evt: string) {
    this.customItemClicked.emit(evt);
  }

  ngOnInit(): void {
    this.sidePanel = new SidePanel(this.config, this.labelsList, this.notes);
  }

  ngOnChanges(): void {
    this.sidePanel = new SidePanel(this.config, this.labelsList, this.notes);
  }
}
