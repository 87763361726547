import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { AddressService } from './services/address.service';
import { AuthService } from './services/auth.service';
import { ConfigService } from './services/config.service';
import { DocumentsService } from './services/documents.service';
import { SessionService } from './services/session.service';
import { WINDOW_PROVIDERS } from './services/window.service';

@NgModule({
  imports: [HttpClientModule],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ConfigService,
        AuthService,
        SessionService,
        AddressService,
        DocumentsService,
        WINDOW_PROVIDERS,
      ],
    };
  }
}
