import { SafeResourceUrl } from '@angular/platform-browser';

export const applyTheme = (theme, sanitizer) => {
  if (!theme) return;

  const root = document.documentElement;
  let typeface: SafeResourceUrl;

  Object.keys(theme).forEach((component: string) => {
    Object.keys(theme[component]).forEach((group: string) => {
      Object.keys(theme[component][group]).forEach((variable: string) => {
        const value = theme[component][group][variable];

        root.style.setProperty(variable, value);

        if (variable === '--typeface-1-url') {
          typeface = sanitizer.bypassSecurityTrustResourceUrl(value);
          document.body.style.fontFamily =
            theme[component][group]['--typeface-1-name'];
        }

        if (
          variable === '--icon-source-system' ||
          variable === '--icon-source-product'
        ) {
          const styleTag = document.createElement('style');

          styleTag.appendChild(
            document.createTextNode(`
                            @font-face {
                                font-family: '${
                                  variable === '--icon-source-system'
                                    ? 'System'
                                    : 'Product'
                                } Icons';
                                font-weight: var(--regular);
                                font-style: normal;
                                src: url('${value}') format('woff');
                            }`)
          );
          document.head.appendChild(styleTag);
        }
      });
    });
  });

  return typeface;
};
