<modal
  *ngIf="shouldCloseProviderSelectionPopup && providerSelectionModalConfig"
  [config]="providerSelectionModalConfig"
  [condition]="
    shouldCloseProviderSelectionPopup && providerSelectionModalConfig
  "
  (cancel)="closeSelectionPopup()"
>
  <div body class="popup-container">
    <div>
      <zip-button
        [config]="providerSelectionModalConfig?.providerButton"
        (zipButtonClicked)="openProviderFlyout()"
      >
      </zip-button>
    </div>
    <div>
      <zip-button
        [config]="providerSelectionModalConfig?.locationButton"
        (zipButtonClicked)="openFacilityFlyout()"
      >
      </zip-button>
    </div>
  </div>
</modal>

<provider-flyout
  [shouldOpenProviderFlyout]="shouldOpenProviderFlyout"
  [shouldOpenFacilityFlyout]="shouldOpenFacilityFlyout"
  [providerMultiLocationModal]="providerMultiLocationModal"
  [outOfNetwork]="outOfNetwork"
  [config]="config"
  [group]="group"
  (shouldCloseProviderFlyout)="closeProviderFlyout()"
  (selectedProvider)="getSelectedProvider($event)"
  (selectedAddress)="getSelectedAddress($event)"
  (isOutOfNetwork)="getOutOfNetwork()"
></provider-flyout>
