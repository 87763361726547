import { Injectable } from '@angular/core';

import { BREAKPOINTS } from '../constants';

function getWindow(): any {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  get nativeWindow(): any {
    return getWindow();
  }

  get isSmallWindow(): boolean {
    const width =
      this.nativeWindow.innerWidth ||
      this.nativeWindow.document.documentElement.clientWidth ||
      this.nativeWindow.document.body.clientWidth;

    return width < BREAKPOINTS.SMALL;
  }
}
