import { Injectable } from '@angular/core';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { ApiListResponse } from '../../../../../shared/models/shared/ApiListResponse.model';
import { APIService } from '../../../../../shared/services';
import {
  AffiliatedHospitalFacility,
  SiteLocation,
  SiteLocationsProfileConfig,
} from '../../site-locations-attestation/site-locations-attestation.model';

@Injectable({
  providedIn: 'root',
})
export class SiteLocationsProfileHttpService {
  constructor(private api: APIService) {}

  getSiteLocationsDetailsById(
    config: SiteLocationsProfileConfig,
    id: string
  ): Observable<SiteLocation> {
    return this.api.get(
      this.setParameter(config?.endpoints.facilityLocationDetails, 'id', id)
    );
  }

  setParameter(url: string, field: string, value: string): string {
    return url.replace(`$\{${field}}`, value);
  }

  getMultipleAffiliatedHospitalFacility(
    config: SiteLocationsProfileConfig,
    payloads: string[]
  ): Observable<AffiliatedHospitalFacility[]> {
    const callObservables = payloads.map((payload: string) =>
      this.getAffiliatedHospitalFacility(config, payload)
        .pipe(map((payloadItem: AffiliatedHospitalFacility) => payloadItem))
        .pipe(catchError((error: object) => of()))
    );

    return forkJoin(callObservables);
  }

  getAffiliatedHospitalFacility(
    config: SiteLocationsProfileConfig,
    payload: string
  ): Observable<AffiliatedHospitalFacility> {
    return this.api
      .get(
        this.setParameter(
          config?.endpoints.facilityDetails,
          'facility_id',
          payload
        )
      )
      .pipe(map((res: AffiliatedHospitalFacility) => res));
  }

  getControlOptions(apiEndpoint: string): Observable<ApiListResponse<unknown>> {
    return this.api.get(apiEndpoint);
  }

  updateSiteLocationEditDetails(
    config: SiteLocationsProfileConfig,
    id: string,
    payload: string
  ): Observable<SiteLocation> {
    return this.api.patch(
      this.setParameter(config.endpoints.facilityLocationDetails, 'id', id),
      payload
    );
  }
}
