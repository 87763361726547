import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConditionalPipe } from '../../pipes/conditional.pipe';

@NgModule({
  declarations: [ConditionalPipe],
  imports: [CommonModule],
  exports: [ConditionalPipe],
})
export class ConditionalPipeModule {}
