export type ObjectArray = Record<string, unknown>[];

export function objectToArray(object: any): any[] {
  return Object.keys(object)
    .filter((key) => !!object[key])
    .map((key) => object[key]);
}

export const arrayToObject = (array: ObjectArray, propertyForKey = 'key') =>
  array.reduce(
    (newObject, arrayItem) => ({
      ...newObject,
      [arrayItem[propertyForKey] as string]: arrayItem,
    }),
    {}
  );

export function isInEnum(
  type: { [s: string]: unknown } | ArrayLike<unknown>,
  value: unknown
): boolean {
  return Object.values(type).includes(value);
}
