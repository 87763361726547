import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { FormControlService } from '../../../notes/form-control/form-control.service';
import { fade } from '../../../shared/animations/fade';
import { BaseCVAComponent } from '../custom-form-element/base-cva.component';

/** @property controls  Not an array of Angular `FormControl` */
export interface ConditionalToggleConfig {
  controls: {
    prop: string;
  }[];
  invert: boolean;
  isGrid: boolean;
  prop: string;
  type: 'toggle';
}

@Component({
  animations: [fade],
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => ConditionalToggleComponent),
    },
    {
      multi: true,
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => ConditionalToggleComponent),
    },
  ],
  selector: 'conditional-toggle',
  styleUrls: ['./conditional-toggle.component.scss'],
  templateUrl: './conditional-toggle.component.html',
})
export class ConditionalToggleComponent
  extends BaseCVAComponent
  implements OnInit, OnDestroy
{
  @Input() config: ConditionalToggleConfig;
  @Input() direction;
  @Input() parentForm: UntypedFormGroup;
  showDependents = false;
  toggleConfig: ConditionalToggleConfig;

  constructor(private formControlService: FormControlService) {
    super();
  }

  ngOnInit() {
    // We need to add the toggle type when we pass this config to the form control component.
    this.toggleConfig = { ...this.config, type: 'toggle' };
    this.initFormGroup();

    // Watch for conditional toggle changes.
    this.formGroup.get(this.config.prop).valueChanges.subscribe((value) => {
      this.config.invert
        ? (this.showDependents = value === false)
        : (this.showDependents = value);
      this.addOrRemoveControls({ [this.config.prop]: this.showDependents });
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  initFormGroup(): void {
    this.formGroup = new UntypedFormGroup({});
    this.formGroup.setParent(this.parentForm);
    this.formControlService.addControlToFormGroup(this.formGroup, this.config);
  }

  addOrRemoveControls(values: any) {
    this.config.controls.forEach((c: any) => {
      this.configPropInValues(values)
        ? this.formControlService.addControlToFormGroup(this.formGroup, c, {
            [c.prop]: values[c.prop] || null,
          })
        : this.formGroup.removeControl(c.prop);
    });
  }
  configPropInValues = (values: any[]): boolean =>
    values[this.config.prop] ||
    (this.config.invert && values[this.config.prop] === false);

  /** Patch value of the toggle, then show and add nested control if the toggle is truthy. */
  writeValue(values: any) {
    if (!this.undefinedConfigPropValue(values)) return;
    this.formGroup.patchValue(
      { [this.config.prop]: values[this.config.prop] },
      { emitEvent: false }
    );
    this.showDependents = this.config.invert
      ? values[this.config.prop] === false
      : values[this.config.prop];
    this.addOrRemoveControls(values);
  }
  undefinedConfigPropValue = (values: any[]): boolean =>
    values && values[this.config.prop] !== undefined;

  validate(_: AbstractControl): ValidationErrors | null {
    return this.formGroup.invalid ? { custom: true } : null;
  }
}
