import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { AllControlsConfiguration } from '@zipari/shared-ds-util-form';
import { AddMultipleGroupConfig } from '../managePortalUsersCreateUser.model';

@Component({
  selector: 'add-multiple-groups',
  templateUrl: './add-multiple-groups.component.html',
  styleUrls: ['./add-multiple-groups.component.scss'],
})
export class AddMultipleGroupsComponent {
  @Input() control: AllControlsConfiguration;
  @Input() group: UntypedFormGroup;
  @Input() config: AddMultipleGroupConfig;

  showFlyout: boolean;
  defaultErrorMessage = 'Please enter a valid input.';

  get groupControl() {
    return this.group?.get(this.control?.prop);
  }

  get groupValue() {
    return this.groupControl?.value;
  }

  openFlyout(): void {
    this.showFlyout = true;
  }

  closeFlyout(): void {
    this.showFlyout = false;
  }

  getSelectedOptionList(options: object[]): void {
    this.groupControl?.setValue(options);
  }

  removeGroup(id: string): void {
    this.groupValue.forEach((value: object, rowIndex: number) => {
      if (value['row'].id == id) this.groupValue.splice(rowIndex, 1);
    });
  }
}
