export const ProvisionAccessType = {
  fullAccess: 'Full Access',
  managedAccess: 'Managed Access',
};

export const ProvisionType = {
  facility: 'Facility',
  groupNPI: 'Group NPI',
  location: 'Location',
};

export const ProvisionEventAttributeKey = {
  groupNpiFullAccess: 'group_npi_full_access',
  groupNpiManagedAccess: 'group_npi_managed_access',
  taxidFullAccess: 'taxid_full_access',
  taxIdManagedAccess: 'taxid_managed_access',
  locationFullAccess: 'location_full_access',
};
