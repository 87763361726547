import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { Subject } from 'rxjs';
import { FileRestrictions } from './file-uploader.constants';
import {
  checkFileSizeRestriction,
  checkFileTypeRestriction,
} from './utilities/file';

@Injectable({
  providedIn: 'root',
})
export class FileUploaderService {
  restrictions: FileRestrictions;
  disabled: boolean;
  fileAttachment: UntypedFormArray;

  // Subject of uploaded file
  newFile: Subject<File> = new Subject();
  uploadError: Subject<string> = new Subject();

  handleFileRestrictions(file: File, restrictions: FileRestrictions) {
    if (!checkFileSizeRestriction(file, restrictions.fileSizeLimit)) {
      this.uploadError.next(restrictions.messages.fileSizeLimit);

      return;
    } else if (!checkFileTypeRestriction(file, restrictions.fileTypes)) {
      this.uploadError.next(restrictions.messages.fileTypes);

      return;
    } else {
      this.newFile.next(file);
    }
  }

  handleFileInput(files: FileList) {
    // reset the error message on starting to upload a new file
    this.uploadError.next('');
    if (files.length > 0 && !this.disabled) {
      const file = files[0];

      this.handleFileRestrictions(file, this.restrictions);
    }
  }
}
