import { Injectable } from '@angular/core';
import { camelCase } from '@zipari/shared-util-common';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { APIService } from '../../../../shared/services';
import {
  CheckListItem,
  userGroupsDetail,
  userGroupsTaxIdDetail,
  userTaxIdDetails,
} from '../manage-portal-users-details.model';
import SuspendUserService from './suspend-user.service';

@Injectable({
  providedIn: 'root',
})
export class SuspendUserDataService {
  groupsTaxIdsDetail$: Observable<userGroupsTaxIdDetail>;
  suspendUser$ = new BehaviorSubject<boolean>(false);

  constructor(
    private apiService: APIService,
    private suspendUserService: SuspendUserService
  ) {}

  getGroupsList(endPoint: string): void {
    this.groupsTaxIdsDetail$ = this.apiService.get(endPoint).pipe(
      tap((userGroupTaxIdDetail: userGroupsTaxIdDetail) => {
        this.suspendUserService.groups = this.updateUserDetailsResponse(
          userGroupTaxIdDetail.groups
        );
        this.suspendUserService.taxIds = this.updateUserDetailsResponse(
          userGroupTaxIdDetail.tax_ids
        );
        this.suspendUser$.next(true);
      })
    );
  }

  updateUserDetailsResponse = (
    details: userGroupsDetail[] | userTaxIdDetails[]
  ): CheckListItem[] =>
    camelCase(details)?.map((detail: userGroupsDetail | userTaxIdDetails) => ({
      value: detail,
      label: detail.name,
    }));

  suspendUser(endpoint: string, userDetails): Observable<any> {
    return this.apiService.post(endpoint, userDetails);
  }
}
