import {
  CxCallbacks,
  CxCallBody,
  CxCallerConfig,
  CxCallerInitData,
  CxContext,
} from './constants';

export class CXCaller {
  private configInternal: CxCallerConfig;
  private dataInternal: CxCallerInitData = {};

  constructor(config: CxCallerConfig, initData: CxCallerInitData = {}) {
    this.configInternal = new CxCallerConfig(config);

    if (initData) {
      this.internalData = initData;
    }
  }

  private get internalData() {
    return this.dataInternal;
  }
  private set internalData(initData: CxCallerInitData) {
    this.dataInternal = new CxCallerInitData(this.config, initData);
  }

  private get config(): CxCallerConfig {
    return this.configInternal;
  }
  private set config(config: CxCallerConfig) {
    this.configInternal = new CxCallerConfig(config);
  }

  private get context(): CxContext | undefined {
    return this.dataInternal.context;
  }
  private set context(context: CxContext | undefined) {
    this.dataInternal.context = context;
  }

  public dispatchCXEvent(
    event: string,
    body: CxCallBody,
    callbacks: CxCallbacks
  ): void {
    const validBody = new CxCallBody(this.config, body, this.internalData);

    // handle different context provided in the call than originally set up
    if (validBody.context) {
      validBody.context = Object.assign(
        {},
        this.context,
        new CxContext(this.config, validBody.context)
      );
    } else {
      validBody.context = this.context;
    }

    const validCallbacks = new CxCallbacks(callbacks);

    // Note: this requires a minimum node version of node 18 or higher or run in a browser
    fetch(this.config.endpoint, {
      body: JSON.stringify(validBody),
      method: 'POST',
    })
      .then((response) => response.json())
      .then((response) => {
        this.runCallbackIfExists(validCallbacks.success, response);
      })
      .catch((error) => {
        this.runCallbackIfExists(validCallbacks.failure, error);
      });
  }

  private runCallbackIfExists(
    func: (...args: unknown[]) => void,
    body: unknown
  ): void {
    if (func) {
      func(body);
    }
  }
}
