<div [zipBusy]="api_call" class="cell">
  <header-cell
    [title]="config?.title"
    [state]="setHeaderState"
    [searchBar]="config?.search_form"
    (filterEvent)="filterResults($event)"
  >
  </header-cell>
</div>
<div class="items">
  <div
    #noData
    class="line-item"
    *ngIf="noData || !displayingCurrently?.length || error"
  >
    {{ noResultsMessage }}
  </div>
  <div *ngFor="let result of displayingCurrently; let i = index" class="cell">
    <item-cell
      #cells
      [state]="setItemState"
      [data]="result"
      [row]="i"
      [column]="column"
      [onlyLoadWhen]="onlyLoadWhen"
      [clicked]="result.clicked"
      [parentId]="bootStrapId"
      [selected]="result.selected"
      (click)="clickedCell(i)"
      (selectedCell)="selectedCell($event)"
      [config]="card"
    ></item-cell>
  </div>
</div>
