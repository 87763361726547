export const industryConfigs = {
  industryTitle: {
    label: 'Nature of Business',
    messages: {
      error: 'No Matching SIC Code Found',
      loading: 'Fetching SIC Codes',
    },
    options: [],
    prop: 'industry_title',
    selectionRequired: true,
    type: 'typeahead',
    validators: [],
  },
  sicCode: {
    key: 'sic_code',
    label: 'SIC Code',
    messages: {
      error: 'No Matching SIC Code Found',
      loading: 'Fetching SIC Codes',
    },
    options: [],
    prop: 'sic_code',
    selectionRequired: true,
    type: 'typeahead',
    validators: [],
  },
};
