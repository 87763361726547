<div class="DatagridSort">
  <div
    role="button"
    aria-label="Sort Ascending"
    class="DatagridSort__Toggle DatagridSort__Toggle--asc"
    [ngClass]="{ 'is-active': isAscending }"
  ></div>

  <div
    role="button"
    aria-label="Sort Descending"
    class="DatagridSort__Toggle DatagridSort__Toggle--desc"
    [ngClass]="{ 'is-active': isDescending }"
  ></div>
</div>
