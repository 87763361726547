import { setCSSVariableValue } from '../../shared/utils/css-variables';

import { loadIconFonts } from './icon.utils';

export enum IconFormats {
  svg = 'svg',
  font = 'font',
}

export enum IconSizes {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum IconTypes {
  link = 'link',
  pointer = 'pointer',
  move = 'move',
}

export enum IconSets {
  system = 'system',
  product = 'product',
}

export enum IconTransforms {
  rotate90 = 'rotate90',
  rotate180 = 'rotate180',
  flip = 'flip',
}

/**
 * @deprecated use `fill` instead
 */
export enum IconColors {
  alt = 'alt',
  primary = 'primary',
  link = 'link',
  success = 'success',
  warn = 'warn',
  error = 'error',
  white = 'white',
}
export interface IconConfig {
  size?: IconSizes;
  type?: IconTypes;
  transform?: IconTransforms;
  format?: IconFormats;
  set?: IconSets;
  fill?: string;
}
export class IconConfiguration {
  size?: IconSizes;
  type?: IconTypes;
  transform?: IconTransforms;
  format?: IconFormats;
  set?: IconSets;
  fill?: string;
  /**
   * @deprecated use `fill` instead
   */
  color?: IconColors;

  constructor(config) {
    this.type = config?.type ? config.type : null;
    this.transform = config?.transform ? config.transform : null;
    this.size = config?.size ? config.size : IconSizes.medium;
    this.set = config?.set ? config.set : IconSets.system;
    this.format = this.setFormat(config?.format);
    this.fill = this.setFill(config?.fill, config?.color);

    this.setCSSVariables();
  }

  /**
   * sets locally-scoped CSS variables for font-family & sizing
   */
  setCSSVariables(): void {
    // font-family
    if (this.format === IconFormats.font) {
      const font =
        this.set === IconSets.product ? 'Product Icons' : 'System Icons';

      setCSSVariableValue('--icon-font', font);
    }

    // sizing
    const sizes = ['small', 'medium', 'large'];

    sizes.forEach((size) => {
      setCSSVariableValue(`--icon-${size}`, `var(--icon-${this.set}-${size})`);
    });
  }

  /**
   * returns the appropriate CSS variable to supply the fill of the SVG or the color of the font icon
   *
   * @param fill - any CSS variable that evaluates to a color,
   *               without the `var()` method or prepending `--`
   * @param color - [deprecated] value from the IconColors enum
   *
   * @default - 'var(--icon-color)'
   */
  setFill(fill?: string, color?: IconColors): string {
    const name = fill
      ? fill // use `fill` if available
      : color // backward compatibility check for `color`
      ? color === IconColors.alt
        ? 'text--2' // "alt" is not a valid CSS color variable name
        : color
      : this.type === IconTypes.link
      ? 'link' // use `link` color if type is provided w/o `fill` or `color`
      : 'icon-color'; // if neither `fill` nor `color` is provided, use default

    return `var(--${name})`;
  }

  /**
   * returns the format of the given icon
   *
   * @param format - 'svg' | 'font'
   *
   * @default - 'svg'
   */
  setFormat(format?: IconFormats): IconFormats {
    const areSVGsAppended = document.getElementById('svgDefs');
    const areFontsLoaded = document.fonts?.check('1rem System Icons');

    // default is 'svg'
    if (!format) {
      format = IconFormats.svg;
    }

    // fallback to 'font' if SVGs are not attached
    if (format === IconFormats.svg && !areSVGsAppended) {
      format = IconFormats.font;
    }

    // if no font file is loaded, use theme variables to retrieve it
    if (format === IconFormats.font && !areFontsLoaded) {
      loadIconFonts();
    }

    return format;
  }
}
