import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PortalFooterModule } from '../../../chords/portal-footer/portal-footer.module';

import { MainComponent } from './main.component';

@NgModule({
  imports: [CommonModule, PortalFooterModule],
  declarations: [MainComponent],
  exports: [MainComponent],
})
export class MainModule {}
