import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatorConfiguration } from '@zipari/design-system';
import {
  SquidexConfig,
  SquidexContentFlatResponse,
  SquidexDataService,
} from '@zipari/shared-data-access-squidex';
import { Observable } from 'rxjs';
import { DocumentCenterTab, UserDetails } from '../util/document-center.model';
import {
  getFiltersQuery,
  getUserFiltersQuery,
} from './document-center-squidex-data-filters-helper';

@Injectable({
  providedIn: 'root',
})
export class DocumentCenterSquidexDataService {
  constructor(
    private http: HttpClient,
    private squidexDataService: SquidexDataService
  ) {}

  getSquidexContent = (
    activeTab: DocumentCenterTab,
    squidexConfig?: SquidexConfig,
    paginator?: PaginatorConfiguration,
    filtersFormValue?: Record<string, unknown> | null,
    userDetails?: UserDetails | null,
    sortKey?: string | null
  ): Observable<SquidexContentFlatResponse> => {
    const endPoint =
      activeTab.squidexDocumentsEndPoint || activeTab.squidexContentEndPoint;
    const url = this.squidexDataService.buildUrl(
      `/api/content/{app}/${endPoint}`
    );

    return this.http.get<SquidexContentFlatResponse>(url, {
      params: this.getSquidexApiParams(
        activeTab,
        squidexConfig,
        paginator,
        filtersFormValue,
        userDetails,
        sortKey
      ),
    });
  };

  getSquidexApiParams(
    activeTab: DocumentCenterTab,
    squidexConfig?: SquidexConfig,
    paginator?: PaginatorConfiguration,
    filtersFormValue?: Record<string, unknown> | null,
    userDetails?: UserDetails | null,
    sortKey?: string | null
  ): HttpParams {
    let params = new HttpParams();

    if (paginator?.rowsPerPage) {
      const skip = (paginator.currentPage - 1) * paginator.rowsPerPage;
      params = params.append('$skip', skip);
      params = params.append('$top', paginator.rowsPerPage);
    }

    if (filtersFormValue || (squidexConfig && userDetails)) {
      const filters = [];
      if (filtersFormValue) {
        filters.push(getFiltersQuery(activeTab, filtersFormValue));
      }
      if (squidexConfig && userDetails) {
        filters.push(getUserFiltersQuery(squidexConfig, userDetails));
      }

      const filterQuery = filters.filter(Boolean).join(' and ');
      if (filterQuery) {
        params = params.append('$filter', filterQuery);
      }
    }

    if (sortKey) {
      const sorting = `data/${this.getSortingKey(
        sortKey
      )}/iv ${this.getSortingOrder(sortKey)}`;
      params = params.append('$orderby', sorting);
    }

    return params;
  }

  getSortingOrder(key: string): string {
    return key.startsWith('-') ? 'desc' : 'asc';
  }

  getSortingKey(key: string): string {
    return key.startsWith('-') ? key.replace('-', '') : key;
  }
}
