import { Component, Input } from '@angular/core';
import { TreeNode } from '../../provisioner/provisioner.service';

@Component({
  styleUrls: ['row-item.component.scss'],
  selector: 'row-item',
  templateUrl: './row-item.component.html',
})
export class RowItemComponent {
  @Input() key: string;
  @Input() children: TreeNode[];
  @Input() numberOfColumns: number;
  @Input() mode: boolean;
  @Input() form_fields_label_map: Map<any, any>;
  @Input() display_field_prop_list: any[];

  get width() {
    if (this.mode) {
      return '55.15';
    }

    return '79.5';
  }
}
