import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import {
  FormControlConfiguration,
  controlTypes,
} from '@zipari/shared-ds-util-form';
import { FormControlValidatorsService } from '@zipari/design-system';
import * as __ from 'lodash';
import { stateOptions } from '../../../../../shared/constants/state.constants';
import {
  deaGroupProp,
  deaNumberProp,
  stateProp,
} from '../../practitioners/add-new/manage-practitioner-add.constant';
import { ProviderUpdateToolService } from '../../provider-update-tool.service';

@Component({
  selector: 'controls-group-array',
  templateUrl: './controls-group-array.component.html',
  styleUrls: ['./controls-group-array.component.scss'],
})
export class ControlsGroupArrayComponent implements OnInit {
  @Input() config;
  @Input() key: string;
  @Input() stateDeaInfoModal;

  stateDeaFormArrayGroup = [];
  showMsg: boolean;
  isEditState: boolean;

  constructor(
    public providerUpdateToolService: ProviderUpdateToolService,
    private formControlValidatorsService: FormControlValidatorsService
  ) {}

  get formArray(): UntypedFormArray {
    return this.providerUpdateToolService
      .getFormGroup(this.key)
      ?.get(this.config.prop) as UntypedFormArray;
  }

  get contextData(): any[] {
    const contextData = this.providerUpdateToolService.getContextData(this.key);

    return contextData && contextData[this.config.prop]
      ? contextData[this.config.prop]
      : [];
  }

  get getInitState(): string | boolean {
    if (!this.providerUpdateToolService.getInitState(this.key)) return true;

    return this.providerUpdateToolService.getInitState(this.key)[
      this.config.prop
    ];
  }

  ngOnInit() {
    this.updateStateOptions();
    const clonedArray = __.cloneDeep(this.config.controls);
    this.stateDeaFormArrayGroup.push({
      previousStateValue: null,
      controls: clonedArray,
    });
    this.updateDEAOnStateChanges();
    this.populateFormToEdit();
  }

  setInitState(state: string): void {
    const initState = this.providerUpdateToolService.getInitState(this.key);
    if (initState) {
      initState[this.config.prop] = state;
    }
  }

  addArrayItem(): void {
    const stateDeaMaxCount = this.stateDeaInfoModal?.stateDeaMaxCount;
    const formArrayLength = this.formArray.length;
    this.showMsg = formArrayLength >= stateDeaMaxCount;
    if (!this.showMsg) {
      this.formArray.push(new UntypedFormGroup({}));
      this.stateDeaFormArrayGroup.push({
        previousStateValue: null,
        controls: [...this.config.controls],
      });
    }
  }

  deleteArrayItem(index: number): void {
    this.formArray.removeAt(index);
    this.contextData.splice(index, 1);
  }

  getMaxSize(): number {
    return this.formArray.length;
  }

  formCreated(): void {
    this.formArray.controls.forEach((control: UntypedFormGroup) => {
      const validators =
        this.formControlValidatorsService.getFormControlValidators({
          prop: '',
          type: controlTypes.text,
          validators: ['dea'],
        });

      control.get(deaGroupProp)?.get(deaNumberProp)?.setValidators(validators);
      if (control.get(stateProp)?.value) {
        control
          .get(deaGroupProp)
          ?.get(deaNumberProp)
          ?.enable({ emitEvent: false });
      }
    });
  }

  updateStateOptions(): void {
    this.config.controls.map((control: FormControlConfiguration) => {
      if (control.prop === stateProp) {
        control.options = stateOptions;
      }
    });
  }

  updateDEAConfig(controlValue: object, index: number): void {
    const disable = controlValue[stateProp] ? false : true;

    if (
      !controlValue[stateProp] &&
      controlValue[deaGroupProp] &&
      controlValue[deaGroupProp]['number']
    ) {
      const stateValidator =
        this.formControlValidatorsService.getFormControlValidators({
          prop: '',
          type: controlTypes.dropdown,
          validators: ['required'],
        });
      this.formArray.controls[index]
        ?.get(stateProp)
        .setValidators(stateValidator);
    } else {
      this.formArray.controls[index]?.get(stateProp)?.clearValidators();
    }
    if (
      this.stateDeaFormArrayGroup[index]?.previousStateValue !==
        controlValue[stateProp] &&
      this.stateDeaFormArrayGroup[index]
    ) {
      const groupControls = JSON.parse(
        JSON.stringify(this.stateDeaFormArrayGroup[index].controls)
      );

      const updatedControls = groupControls.map((control: any) => {
        if (control.prop === deaGroupProp) {
          control.controls[0] = { ...control.controls[0], isDisabled: disable };
          this.formArray.controls[index]
            ?.get(deaGroupProp)
            ?.get(deaNumberProp)
            ?.enable({ emitEvent: false });
        }

        return control;
      });

      this.stateDeaFormArrayGroup[index] = {
        previousStateValue: controlValue[stateProp],
        controls: [...updatedControls],
      };
    }
  }

  updateDEAOnStateChanges(): void {
    this.providerUpdateToolService
      .getFormGroup(this.key)
      ?.get('StateDeaFormGroupArray')
      ?.valueChanges.subscribe((value: object[]) => {
        value.forEach((element: object, index: number) => {
          this.updateDEAConfig(element, index);
        });
      });
  }

  onCloseClick(): void {
    this.showMsg = false;
  }

  updateFormGroup(array: any[], addFormControl: boolean): void {
    array.forEach((_, index) => {
      if (!(index === array.length - 1)) {
        if (addFormControl) {
          this.addArrayItem();
        } else {
          const clonedArray = __.cloneDeep(this.config.controls);
          this.stateDeaFormArrayGroup.push({
            previousStateValue: null,
            controls: clonedArray,
          });
        }
      }
    });
  }

  populateFormToEdit(): void {
    if (!this.getInitState) {
      this.setInitState('opened');
      this.isEditState = true;
      this.updateFormGroup(this.contextData, true);
    } else if (this.getInitState === 'opened') {
      this.isEditState = true;
      this.updateFormGroup(this.formArray.controls, false);
    }
  }
}
