import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormArray,
  Validators,
} from '@angular/forms';

import { ProviderUpdateToolService } from '../../../provider-update-tool/provider-update-tool.service';

@Component({
  selector: 'phone-array',
  templateUrl: './phone-array.component.html',
  styleUrls: ['./phone-array.component.scss'],
})
export class PhoneArrayComponent implements OnInit {
  @Input() config: any = {};
  @Input() group: UntypedFormGroup;
  options: any[] = [];
  limit: number = 3;

  constructor(
    private fb: UntypedFormBuilder,
    public mp: ProviderUpdateToolService
  ) {}

  ngOnInit() {
    if (this.config.options) {
      this.options = this.config.options;
    }
    if (this.config.limit) {
      this.limit = this.config.limit;
    }
    const control_key: string = this.config.prop;
  }

  get formArray() {
    return this.group?.get(this.config.prop) as UntypedFormArray;
  }

  addArrayItem() {
    const phoneValidators: any[] = this.mp.getValidators('phone');
    const phoneTypeValidators: any[] = [
      this.mp.phoneConditionallyRequiredValidator,
    ];
    if (this.config.required) {
      phoneValidators.push(Validators.required);
      phoneTypeValidators.push(Validators.required);
    }
    const formGroup: UntypedFormGroup = this.fb.group({
      phone_number: ['', phoneValidators],
      type: ['', phoneTypeValidators],
    });
    formGroup?.get('phone_number')?.valueChanges.subscribe(() => {
      formGroup.get('type')?.updateValueAndValidity();
    });
    this.formArray?.push(formGroup);
    this.formArray?.markAllAsTouched();
  }

  deleteArrayItem(index) {
    this.formArray?.removeAt(index);
    this.formArray?.markAllAsTouched();
  }

  getMaxSize() {
    return this.formArray?.length;
  }
}
