import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { TooltipService } from './tooltip.service';

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective implements AfterViewInit {
  constructor(private el: ElementRef, private tooltipService: TooltipService) {}

  ngAfterViewInit() {
    this.tooltipService.setParentContainerBoundingClientRect(
      this.el.nativeElement.getBoundingClientRect()
    );
  }
}
