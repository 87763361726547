import { TableConfig } from '@zipari/design-system';

export class DocumentsConfig {
  tabs: DocumentsTabConfig[];
  tabConfigs: any;
}

export class DocumentsTabConfig {
  label: string;
  priority: number;
  prop: string;
  targetConfigKey: string;
  url: string;
  cx_event_key: string;
}

export interface DocumentTableTabConfig {
  targetRouteObj: any;
  table?: TableConfig;
  tableDataKey?: string;
  tableTitle?: string;
  endpoint?: string;
  description: string;
  filters?: any;
}

export interface ResourceTabConfig {
  accordions: Accordion[];
}

export interface Accordion {
  header: string;
  accordionFields: AccordionField[];
}

export interface AccordionField {
  title: string;
  icon: string;
  description: string;
  url: string;
}

export const DOCUMENTS_URLS = {
  FORMS: 'forms',
  TRAINING: 'training',
  POLICY_GUIDELINES: 'policy-guidelines',
  RESOURCES: 'resources',
  FINACIAL_DOCUMENTS: 'finacial-documents',
};

export const SearchFilterProp = 'search_string';
