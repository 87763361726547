import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { APIService } from '../../../../../shared/services';
import { ProviderUpdateToolService } from '../../../provider-update-tool/provider-update-tool.service';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';
@Component({
  selector: 'data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
})
export class DataGridComponent implements OnInit {
  @Input() config: any = {};
  @Input() key: string;
  busy: Subscription;
  error: boolean = false;
  noSpace: string = 'n/a';
  responseData: any;

  constructor(
    private fb: UntypedFormBuilder,
    private api: APIService,
    public mp: ProviderUpdateToolService,
    public router: Router,
    public analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.endPointData(this.config);
  }

  endPointData(config) {
    if (config?.table?.endpoint) {
      this.busy = this.api
        .get(config?.table?.endpoint)
        .subscribe((resp: any) => {
          this.responseData = resp['results'];
        });
    }
  }

  setActiveRow(row) {
    if (row.uniqueCellProp) {
      const routeToNavigateTo = row.uniqueCellProp;
      const baseRoute = routeToNavigateTo.slice(
        0,
        routeToNavigateTo.indexOf('$')
      );
      const propToReplace = routeToNavigateTo.match(/\{([^}]+)\}/);
      const uniqueIdentifierKey = this.getObjectProperty(row, propToReplace[1]);
      this.router.navigate([baseRoute, uniqueIdentifierKey]);
    } else {
      this.router.navigate([row]);
    }
    this.cxCapture(row);
  }

  cxCapture(row): void {
    let obj: any;
    if (row.uniqueCellProp) {
      obj = this.config?.table?.columns?.find(
        (url: any) => url.routeToProp === row.uniqueCellProp
      );
    } else {
      obj =
        this.config?.footer?.right?.route === row
          ? this.config?.footer?.right
          : null;
    }
    obj && obj.cx_event_key
      ? this.analyticsService.sendEvent(obj.cx_event_key)
      : '';
  }

  getObjectProperty(obj, key) {
    return key.split('.').reduce(function (result, _key) {
      return result[_key];
    }, obj);
  }
}
