<card *ngIf="!idp" [config]="{ hideSubHeader: true }">
  <div *ngIf="!activeCard?.confirmationView" class="title--section" title>
    <div class="error--section" *ngIf="!success && errorMessage">
      <p
        class="t-bold t-error u-flex u-justifyCenter"
        *ngFor="let error of errorMessage"
      >
        {{ error }}
      </p>
    </div>

    <div class="titles--section">
      <h4
        class="t-bold"
        role="heading"
        aria-level="1"
        [innerHTML]="
          success && config?.successTitle
            ? config?.successTitle
            : activeCard?.title || config?.title
        "
      ></h4>

      <p
        class="t-data"
        [innerHTML]="
          success && config?.successSubtitle
            ? config?.successSubtitle
            : activeCard.subtitle || config?.subtitle
        "
      ></p>
      <p
        *ngIf="activeCard?.form?.subheader"
        class="t-data"
        [innerHTML]="activeCard?.form?.subheader"
      ></p>
    </div>
  </div>

  <div *ngIf="!idp" class="body--section" body>
    <div>
      <div class="auth-card__body body--content">
        <div
          *ngIf="activeCard.type === 'text'"
          class="auth-card__content--terms-conditions"
        >
          <div class="terms-conditions__container">
            <p [innerHTML]="activeCard.textContent.content"></p>
          </div>
        </div>
        <form-group
          *ngIf="
            activeCard?.form &&
            !(config.page === 'request-access' && activeCard.index === 0)
          "
          class="form_group_test_class"
          (linkClicked)="linkClicked.emit($event.config)"
          [configs]="activeCard.form?.controls"
          [form]="formGroup"
          (formCreated)="formCreated()"
        ></form-group>
        <div
          *ngIf="
            activeCard?.form &&
            config.page === 'request-access' &&
            activeCard.index === 0
          "
        >
          <div class="grid">
            <ng-container *ngFor="let control of activeCard.form?.controls">
              <div class="grid__item" *ngIf="control?.prop">
                <form-control
                  [group]="formGroup"
                  [config]="getFormConfigByProp(control.prop)"
                  [control]="formGroup.controls[control.prop]"
                >
                </form-control>
              </div>
            </ng-container>
          </div>
        </div>

        <div *ngIf="activeCard?.form?.button" class="auth-card__body--button">
          <zip-button
            (click)="
              formButtonClicked(
                activeCard.form.controls[0],
                formGroup.controls['tax_id']
              )
            "
            [content]="activeCard?.form?.button?.content"
            [level]="activeCard?.form?.button?.level"
            [disabled]="!formGroup.controls['tax_id'].value"
          ></zip-button>
        </div>
      </div>
      <ng-container *ngIf="showError">
        <pp-error-message
          [errorMessage]="errorMessageForTaxId"
        ></pp-error-message>
      </ng-container>
      <div
        class="auth-card__content"
        *ngIf="activeCard?.form?.multiData?.active"
      >
        <h6 *ngIf="multiDataArry.length" class="auth-card__content--title">
          {{ activeCard.form.multiData.title }}
        </h6>
        <div class="multiData-items">
          <div
            *ngFor="let item of multiDataArry; let i = index"
            class="multiData-item"
          >
            <p class="multiData-item__content">
              {{ item }}
            </p>
            <i
              class="multiData-item__content--icon icon"
              (click)="removeMultiDataItem(i)"
              >close
            </i>
          </div>
        </div>
      </div>

      <div
        class="auth-card__request-access-confirmation"
        *ngIf="
          config.page === 'request-access' &&
          activeCard.index === this.config?.cardViews.length - 1
        "
      >
        <div
          class="auth-card__request-access-confirmation__header-success-icon-container"
        >
          <h4 class="t-bold request-access-confirmation__container-align">
            {{
              config['cardViews'][this.config?.cardViews.length - 1]
                .confirmationView.header
            }}
          </h4>
          <i
            class="icon icon--medium t-bold request-access-confirmation__container-align"
            >check_circle</i
          >
        </div>
        <p
          class="t-data auth-card__request-access-confirmation__descriptive-text"
          [innerText]="
            config['cardViews'][this.config?.cardViews.length - 1]
              .confirmationView.descriptiveText
          "
        ></p>
      </div>

      <div class="body--footer" *ngIf="activeCard.footer">
        <p
          *ngIf="activeCard.footer?.link"
          class="t-data t-link"
          (click)="linkClicked.emit(activeCard.footer?.link)"
          [innerHTML]="activeCard.footer?.link?.label"
        ></p>
        <zip-button
          (click)="handleButtonClicked()"
          [disabled]="isButtonDisabled()"
          [content]="activeCard.footer?.button?.content"
          [icon]="activeCard.footer?.button?.icon"
          [level]="activeCard.footer?.button?.level"
        >
        </zip-button>
      </div>
    </div>
  </div>
</card>

<div class="body--section">
  <div id="widget-container"></div>
</div>
