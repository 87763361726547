import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { formControlDirections } from '@zipari/shared-ds-util-form';
import { FormControlService } from '../../../notes/form-control/form-control.service';
import { formatGridArea } from '../../../shared/utils/css-grid';
import {
  CustomComponent,
  customFormElements,
} from './custom-form-element.constants';

@Component({
  selector: 'custom-form-element',
  templateUrl: './custom-form-element.component.html',
  styleUrls: ['./custom-form-element.component.scss'],
})
export class CustomFormElementComponent implements OnInit {
  /**
   * NEW FORM ELEMENTS MUST BE ADDED TO THIS COMPONENT
   * Make sure to add a unique type in the 'customFormElements' enum
   * During configuration... provide that custom type and it will display your custom form element.
   */
  @ViewChild('registeredComponentContainer', { read: ViewContainerRef })
  registeredComponentContainer: ViewContainerRef;

  /** allows users of the form group system to register custom components */
  @Input() registeredComponents;

  @Input() direction: formControlDirections;
  @Input() config: any;
  @Input() form = new UntypedFormGroup({});

  @Output() formCreated = new EventEmitter();
  @Output() addressValidationChange = new EventEmitter();

  customFormElements = customFormElements;
  componentRef: ComponentRef<CustomComponent>;

  constructor(
    public formControlService: FormControlService,
    public componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    if (
      !Object.values(customFormElements).includes(this.config.type) &&
      !this.registeredComponents[this.config.type]
    ) {
      console.warn(`Your provided type ${this.config.type} is not valid`);
    }

    /** merge global config for a control type into @Input config  */
    this.config = this.formControlService.retrieveConfigOverride(this.config);

    if (this.isRegisteredCustomComponent) {
      this.registerCustomComponent(this.registeredComponents[this.config.type]);
    }
  }

  registerCustomComponent(component): void {
    // create the component factory
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(component);

    // add the component to the view
    this.componentRef =
      this.registeredComponentContainer.createComponent(componentFactory);

    // add the interface pieces to the component
    this.componentRef.instance.config = this.config;
    this.componentRef.instance.form = this.form;
    this.componentRef.instance.formCreated = this.formCreated;
  }

  public setGridPosition(grid: string | { row: string; col: string }): string {
    return formatGridArea(grid);
  }

  public get isRegisteredCustomComponent() {
    return (
      this.registeredComponents && this.registeredComponents[this.config.type]
    );
  }

  public updateAddressForm(formGroup: UntypedFormGroup): void {
    this.addressValidationChange.emit(formGroup);
  }
}
