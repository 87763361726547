import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WindowService } from '@zipari/web-services';
import { CX_CALLS } from '../../../../../shared/constants/cx-calls.constant';
import { ConfigService } from '../../../../../shared/services';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';
import {
  BUTTON_TYPE,
  BUTTON_TYPE_NAME,
} from '../site-location-attestation.constant';
import SiteLocationAttestationEventService from '../site-location-attestation.event.service';
import {
  AttestedSiteLocationDetail,
  SiteLocationAttestationConfig,
  SiteLocationAttestationConfirmConfig,
  UserInformation,
} from '../site-locations-attestation.model';

@Component({
  selector: 'confirm-site-location-attestation',
  templateUrl: './confirm-site-location-attestation.component.html',
  styleUrls: ['./confirm-site-location-attestation.component.scss'],
})
export class ConfirmSiteLocationAttestationComponent
  implements OnInit, OnDestroy
{
  public userInformation: UserInformation = new UserInformation();
  public updatedLocations: AttestedSiteLocationDetail;
  public config: SiteLocationAttestationConfirmConfig;
  public showLocationsNameCard: boolean;

  constructor(
    public router: Router,
    private configService: ConfigService,
    private siteLocationAttestationEventService: SiteLocationAttestationEventService,
    private windowService: WindowService,
    public analyticsService: AnalyticsService
  ) {
    this.setSiteLocationAttestedDetails();
  }

  ngOnInit(): void {
    this.getConfig();
    if (!this.updatedLocations && !this.userInformation) {
      this.router.navigate([
        '/provider-portal/provider-admin/attestation/locations',
      ]);
    } else {
      this.emitDynamicButtonConfig();
    }
  }

  ngOnDestroy(): void {
    this.updatedLocations = {};
    this.siteLocationAttestationEventService.emitSelectedSiteLocations([]);
    this.siteLocationAttestationEventService.emitDynamicButtonConfig({});
    this.siteLocationAttestationEventService.emitDynamicButtonClickEvent('');
  }

  public emitDynamicButtonConfig(): void {
    if (this.config) {
      this.config.returnToDashBoardButton[BUTTON_TYPE] =
        BUTTON_TYPE_NAME.CONFIRM;
      this.siteLocationAttestationEventService.emitDynamicButtonConfig(
        this.config.returnToDashBoardButton
      );
    }
  }

  printAttestation(): void {
    this.windowService.nativeWindow.print();
  }

  public getConfig(): void {
    const siteLocationAttestationConfig: SiteLocationAttestationConfig =
      this.configService.getPageConfig('site-locations-attestation');
    if (siteLocationAttestationConfig) {
      this.config =
        siteLocationAttestationConfig.siteLocationAttestationConfirm;
    }
  }

  public setSiteLocationAttestedDetails(): void {
    const siteAttestedDetails: {
      locations: AttestedSiteLocationDetail;
      user: UserInformation;
    } = this.router.getCurrentNavigation()?.extras.state as {
      locations: AttestedSiteLocationDetail;
      user: UserInformation;
    };
    if (siteAttestedDetails?.locations && siteAttestedDetails?.user) {
      this.updatedLocations = siteAttestedDetails?.locations;
      this.userInformation = siteAttestedDetails?.user;
      this.showLocationsNameCard = this.shouldShowLocations();

      this.analyticsService.sendEvent(
        CX_CALLS.pp_site_locations_attested.event_key,
        this.getDictionaryAttributesOfSiteLocationConfirmation(
          siteAttestedDetails
        )
      );
    }
  }

  shouldShowLocations(): boolean {
    return this.updatedLocations.name?.filter(Boolean).length > 0;
  }

  public getDictionaryAttributesOfSiteLocationConfirmation(siteAttestedDetails: {
    locations: AttestedSiteLocationDetail;
    user: UserInformation;
  }): unknown {
    return {
      user_email: siteAttestedDetails.user.emailAddress,
      user_firstname: siteAttestedDetails.user.firstName,
      user_lastname: siteAttestedDetails.user.lastName,
      'site-location-attestation_date': siteAttestedDetails.user.dateSubmitted,
      'site-location-attestation_time': siteAttestedDetails.user.timeSubmitted,
      tax_id: siteAttestedDetails.user.taxId,
      'site-location_name': siteAttestedDetails.locations.name,
      'site-location_address1': siteAttestedDetails.locations.address1,
      'site-location_address2': siteAttestedDetails.locations.address2,
      'site-location_city': siteAttestedDetails.locations.city,
      'site-location_state': siteAttestedDetails.locations.state,
      'site-location_zipcode': siteAttestedDetails.locations.zipCode,
      npi: siteAttestedDetails.locations.npi,
    };
  }
}
