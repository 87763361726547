import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlService } from '../../../../notes/form-control/form-control.service';
import { AddressService } from '../../../../shared/services/address.service';

import { getValue } from '../../../../shared/utils/get-value';
import { BaseAddressDropdownComponent } from '../base-address-dropdown.component';

@Component({
  selector: 'cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => CitiesComponent),
      multi: true,
    },
  ],
})
export class CitiesComponent
  extends BaseAddressDropdownComponent
  implements OnInit, OnDestroy
{
  @Input() group;
  @Input() citiesConfig;

  constructor(
    formControlService: FormControlService,
    addressService: AddressService
  ) {
    super(addressService, formControlService);
  }

  ngOnInit() {
    this.initCityOption();
    this.baseInit(this.group, this.citiesConfig, 'city_name');
  }

  /*
        If persisted value - create dropdown option for display
    */
  initCityOption() {
    const value = getValue(this.group.value, 'city_name');
    if (value) {
      this.citiesConfig.options = [
        {
          label: value,
          value,
        },
      ];
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
