import {
  HttpHandler,
  HttpHeaders,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SquidexConfig } from './squidex.model';

import * as squidexToken from './token';

@Injectable({
  providedIn: 'root',
})
export class SquidexDataService {
  url = '';
  appName = '';
  clientId = '';
  clientSecret = '';
  shouldFlatten = true;
  language = '';

  public initSquidex(squidexConfig: SquidexConfig): void {
    if (!squidexConfig) return;

    this.url = squidexConfig.url;
    this.appName = squidexConfig.appName;
    this.clientId = squidexConfig.clientId;
    this.clientSecret = squidexConfig.clientSecret;
    this.shouldFlatten = squidexConfig.shouldFlatten;
    this.language = squidexConfig.language;
  }

  public buildUrl(url: string) {
    if (url.length > 0 && url.startsWith('/')) {
      url = url.substring(1);
    }
    return `${this.url}/${url}`.replace('{app}', this.appName);
  }

  public getToken(next: HttpHandler) {
    // Check if we have already a bearer token in local store.
    const cachedToken = squidexToken.getBearerToken();

    if (cachedToken) {
      return of(cachedToken);
    }

    // tslint:disable-next-line: max-line-length
    const body = `grant_type=client_credentials&scope=squidex-api&client_id=${this.clientId}&client_secret=${this.clientSecret}`;

    const tokenRequest = new HttpRequest(
      'POST',
      this.buildUrl('identity-server/connect/token'),
      body,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
        }),
      }
    );

    return next.handle(tokenRequest).pipe(
      filter((x: any) => x instanceof HttpResponse),
      map((response: HttpResponse<any>) => {
        const token = response.body.access_token;
        // Cache the bearer token in the local store.
        squidexToken.setBearerToken(token);
        return token;
      })
    );
  }
}
