import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormControlService } from '../../../../notes/form-control/form-control.service';
import { cloneObject } from '../../../../shared/utils/object';
import { officeHourConfigs, timeConfig, times } from './office-hours.constants';

@Component({
  selector: 'office-hours',
  templateUrl: './office-hours.component.html',
  styleUrls: ['./office-hours.component.scss'],
})
export class OfficeHoursComponent implements OnInit {
  @Input() group;
  @Input() config;
  @Input() direction;

  currentDaysVal = {};
  daysOfWeekGroup = new UntypedFormGroup({});

  officeHourConfigs = officeHourConfigs;
  timeConfig = timeConfig;
  times = times;

  constructor(public formControlService: FormControlService) {}

  ngOnInit() {
    this.formControlService.addFormGroupToFormGroup(this.group, this.config);

    this.officeHourConfigs.forEach((config) => {
      this.formControlService.addFormGroupToFormGroup(
        this.group.controls[this.config.prop],
        config
      );

      this.times.forEach((timeProp) => {
        const clonedTimeConfig = cloneObject(this.timeConfig);
        clonedTimeConfig.prop = timeProp;
        this.formControlService.addControlToFormGroup(
          this.group.controls[this.config.prop].controls[config.prop],
          clonedTimeConfig
        );
      });

      this.formControlService.addControlToFormGroup(
        this.daysOfWeekGroup,
        config
      );
    });

    this.daysOfWeekGroup.valueChanges.subscribe((val) => {
      Object.keys(val)
        .filter((key) => {
          const checkForFilter = val[key][0] ? key : null;

          if (!checkForFilter) {
            delete this.currentDaysVal[key];
            this.group.controls[this.config.prop].controls[
              key
            ].controls.from.patchValue(null);
            this.group.controls[this.config.prop].controls[
              key
            ].controls.to.patchValue(null);
          }

          return checkForFilter;
        })
        .forEach((key) => {
          if (!this.currentDaysVal[key]) {
            this.currentDaysVal[key] = {
              prop: val[key][0],
            };
          }
        });
    });
  }
}
