import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { ModalConfig } from '@zipari/shared-ds-util-modal';

@Component({
  selector: 'api-error-message-modal',
  templateUrl: './api-error-message-modal.component.html',
  styleUrls: ['./api-error-message-modal.component.scss'],
})
export class ApiErrorMessageModalComponent {
  @Input() modalConfig: ModalConfig;
  @Input() buttonConfig: ButtonConfig;
  @Input() errorMessage: string;
  @Input() showModal: boolean;
  @Output() submitButtonClicked = new EventEmitter();

  defaultErrorMessage = 'Something Wrong!!';

  closeErrorModal(): void {
    this.submitButtonClicked.emit();
  }
}
