import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ButtonModule,
  FormControlModule,
  ProgressBarModule,
} from '@zipari/design-system';
import { PipeModule } from '../../../../shared/pipes/pipe.module';
import { AccumulatorsComponent } from './accumulators.component';

@NgModule({
  declarations: [AccumulatorsComponent],
  exports: [AccumulatorsComponent],
  imports: [
    CommonModule,
    ProgressBarModule,
    ButtonModule,
    FormControlModule,
    PipeModule,
  ],
})
export class AccumulatorsModule {}
