<ng-container *ngIf="control">
  <div class="u-relative">
    <!--
            Custom input option is exposed to allow higher-level components access to the native input element
            while preserving reusability of form-control components label/error/etc. templating.
            Examples include custom form components that implement ControlValueAccessor that need the native input
        -->
    <ng-container *ngIf="customInputTemplate; else default">
      <ng-container *ngTemplateOutlet="customInputTemplate"></ng-container>
    </ng-container>

    <ng-template #default>
      <input
        #input
        tabindex="0"
        (focusout)="focusOutEvent.emit($event)"
        (focus)="focusEvent.emit($event)"
        class="form-control"
        ngDefaultControl
        [readOnly]="config.readonly"
        [attr.readonly]="config?.isDisabled ? config?.isDisabled : null"
        [attr.aria-label]="config.ariaLabel"
        [attr.aria-invalid]="
          (control.dirty || control.touched) && !control.valid
        "
        [attr.aria-required]="
          config?.validators && config?.validators.includes('required')
            ? true
            : false
        "
        [attr.autocomplete]="autocomplete !== null ? autocomplete : true"
        [attr.placeholder]="config.placeholder ? config.placeholder : null"
        [attr.type]="config.type"
        [formControl]="control"
        [id]="config.id || config.prop"
        [max]="config.max || null"
        [maxlength]="config.maxLength || ''"
        [name]="config.prop || config.name"
        [type]="cantSeePassword ? 'password' : 'text'"
        (keydown)="
          (config.type === 'number'
            ? $event.key !== 'e' && $event.key !== 'E'
            : true)
        "
        (input)="onInputChange()"
      />
    </ng-template>

    <div
      class="form-control__icon form-control__icon--right"
      *ngIf="config.canToggle"
    >
      <ng-container *ngIf="canToggle; else eyeSlash">
        <icon
          name="eye_active_hide"
          (click)="onEyeIconClick()"
          [config]="{ type: 'pointer' }"
        >
        </icon>
      </ng-container>
      <ng-template #eyeSlash>
        <icon
          name="eye_active"
          (click)="onEyeIconClick()"
          [config]="{ type: 'pointer' }"
        >
        </icon>
      </ng-template>
    </div>
  </div>
</ng-container>
