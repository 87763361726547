import { DatagridInitSort } from '@zipari/shared-ds-util-datagrid';

/**
 * @description Determine a new sort order based on the current sort order and the newly requested sort order
 * @param currentSort The existing sorting state e.g. '-service_date'
 * @param proposedSort The sort field that is being proposed. Will never have '-'
 */
export const determineSortOrder = (
  currentSort: string,
  proposedSort: string
): string => {
  // if there is no current sort, simply return the proposed Sort
  if (!currentSort) {
    return proposedSort;
  }
  const absoluteSortOrder = currentSort.endsWith('-')
    ? currentSort.slice(-(currentSort.length - 1))
    : currentSort;

  // if ascending, set to descending. If descending, set to null to remove sort
  return proposedSort === currentSort
    ? `-${proposedSort}`
    : proposedSort === absoluteSortOrder
    ? ''
    : proposedSort;
};

export const getEOBGridSortOrder = (
  sortBy: string,
  sortOrder: string
): string => (sortOrder === 'desc' ? `-${sortBy}` : sortBy);

export const fromDatagridInitSort = (sortObject: DatagridInitSort): string => {
  if (sortObject) {
    return sortObject.sortDirection?.toUpperCase().includes('ASC')
      ? sortObject.columnProp
      : `-${sortObject.columnProp}`;
  }

  return '';
};

export const changeSort = (event: string): string =>
  event.startsWith('-') ? event.replace('-', '') : `-${event}`;

export const sortByFullName = (
  a: any | { name: { fullName: number } },
  b: any | { name: { fullName: number } }
) => (a.name.fullName < b.name.fullName ? -1 : 1);

export const sortByFirstName = (
  a: any | { name: { first_name: number } },
  b: any | { name: { first_name: number } }
) => (a.name.first_name < b.name.first_name ? -1 : 1);

export const sortByDisplayName = (
  a: any | { label: number },
  b: any | { label: number }
) => (a.label < b.label ? -1 : 1);

export const sortByPosition = (
  a: any | { position: number },
  b: any | { position: number }
) => (a.position < b.position ? -1 : 1);

export const sortLabelInReverseOrder = (
  a: any | { label: number },
  b: any | { label: number }
) => (a.label < b.label ? 1 : -1);
// Sort object array at client side
export class SortObjectArray {
  private keyMap: Map<string, boolean> = new Map();

  public sort(
    objectArray: Record<string, any>[],
    propertyName: string,
    isDateType?: boolean,
    isAscending?: boolean
  ): Record<string, any>[] {
    const newObjectArray: Record<string, any>[] = [...objectArray];
    const stringType = 'string';
    let value: boolean | undefined = this.keyMap.get(propertyName);

    if (value === undefined) {
      value = true;
      this.keyMap.set(propertyName, value);
    }

    return newObjectArray.sort((a, b) => {
      const valueA = a[propertyName];
      const valueB = b[propertyName];

      if (!valueA || !valueB) {
        // property doesn't exist on either object
        return 0;
      }
      let varA;
      let varB;

      if (isDateType) {
        varA = new Date(valueA);
        varB = new Date(valueB);
      } else {
        varA = typeof valueA === stringType ? valueA.toUpperCase() : valueA;
        varB = typeof valueB === stringType ? valueB.toUpperCase() : valueB;
      }

      let comparison = 0;

      if (varA > varB || isAscending) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      this.keyMap.set(propertyName, !value);

      return value ? comparison * -1 : comparison;
    });
  }
}
