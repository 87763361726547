import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ControlRadioComponent } from './radio.component';

@NgModule({
  declarations: [ControlRadioComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [ControlRadioComponent],
})
export class ControlRadioModule {}
