<p class="t-data">
  <span [innerHTML]="text | readmore : pipeOptions"></span>
  <span *ngIf="!clicked && showButton">...</span>

  <span
    *ngIf="showButton"
    class="t-data t-link"
    (click)="readClicked()"
    (keydown.enter)="readClicked()"
    tabindex="0"
    >{{ clicked ? 'Read Less' : 'Read More' }}</span
  >
</p>
