import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import {
  BannerTypes,
  MessageBannerConfig,
} from '@zipari/shared-ds-util-messages';

import { Subscription } from 'rxjs';
import { SupportingDocumentation } from '../../songs/claim-submission/claim-submission.model';
import {
  defaultResponseErrorMessage,
  FileRestrictions,
} from './file-uploader.constants';
import { FileUploaderService } from './file-uploader.service';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit, OnChanges, OnDestroy {
  @Input() restrictions: FileRestrictions;
  @Input() filesAttached = 0;
  @Input() disabled: boolean;
  @Input() cancel: boolean;
  @Input() progress: number | string;
  @Input() fileAttachment: UntypedFormArray;
  @Input() showCloseIcon: boolean;
  @Input() config: SupportingDocumentation;
  @Input() errorMessageConfig: MessageBannerConfig = {
    icon: 'warning',
    message: defaultResponseErrorMessage,
    type: BannerTypes.error,
  };

  @Input() set uploadErrorMessage(error: boolean) {
    this.innerUploadError = error;
    if (error) {
      const indexOfLastAttachedFile =
        this.fileUploadService.fileAttachment?.length - 1;
      this.removeFile(indexOfLastAttachedFile);
    }
  }

  @Output() fileUploaded = new EventEmitter();
  @Output() fileRemoved = new EventEmitter();
  @Output() pendingAttachments = new EventEmitter();

  errorMessage: string;
  file: File;
  loaded = 0;
  total = 0;

  newFileSub: Subscription;
  restrictionErrorSub: Subscription;
  clearFileTimeout;
  clearErrMsg;
  disableAddAttachmentButton = true;

  private innerUploadError = false;

  constructor(public fileUploadService: FileUploaderService) {}

  ngOnInit() {
    this.buildForm();

    // this is a subject
    this.newFileSub = this.fileUploadService.newFile?.subscribe(
      (newFile: File) => {
        this.onNewFile(newFile);
      }
    );

    this.restrictionErrorSub = this.fileUploadService.uploadError.subscribe(
      (errorMessage: string) => {
        this.uploadErrorMessage = false;
        this.errorMessage = errorMessage;
      }
    );

    if (this.cancel || !this.fileAttachment) {
      this.fileUploadService.fileAttachment.clear();
    } else {
      this.fileUploadService.fileAttachment = this.fileAttachment;
      this.applyUploadLimit();
    }
  }

  get uploadErrorMessage(): boolean {
    return this.innerUploadError;
  }

  get totallyAttached(): number {
    return this.filesAttached + this.fileUploadService.fileAttachment?.length;
  }

  buildForm() {
    if (!this.fileUploadService.fileAttachment)
      this.fileUploadService.fileAttachment = new UntypedFormArray([]);
  }

  onNewFile(file: File) {
    this.file = file;
    this.loaded = 0;
    this.total = 0;
    this.cancel = false;

    this.fileUploadService.fileAttachment.push(new UntypedFormControl(file));

    if (this.config?.addDocumentationButton) {
      this.disabled = true;
      this.disableAddAttachmentButton = false;
    }

    this.applyUploadLimit();

    this.fileUploaded.emit(this.fileUploadService.fileAttachment.value);
  }

  removeFile(index) {
    this.fileUploadService.fileAttachment.removeAt(index);

    if (this.totallyAttached < this.restrictions.fileAttachmentLimit) {
      this.disabled = false;
    }
  }

  clearFile(index) {
    this.removeFile(index);
    this.fileRemoved.emit(index);
  }

  ngOnChanges() {
    this.fileUploadService.restrictions = new FileRestrictions(
      this.restrictions
    );

    if (this.cancel) {
      if (this.fileUploadService.fileAttachment) {
        this.fileUploadService.fileAttachment.clear();
        while (this.fileUploadService.fileAttachment?.length !== 0) {
          this.fileUploadService.fileAttachment.removeAt(0);
        }
      }
      this.disabled = false;
    }
  }

  public applyUploadLimit(): void {
    if (this.totallyAttached === this.restrictions.fileAttachmentLimit) {
      this.disabled = true;
      this.disableAddAttachmentButton = true;
    }
  }

  public addDocumentationClicked(): void {
    this.disabled = false;
    this.disableAddAttachmentButton = true;
  }

  ngOnDestroy() {
    this.restrictionErrorSub.unsubscribe();
    this.newFileSub.unsubscribe();
  }
}
