import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../button/button.module';

import { IconModule } from '../icon/icon.module';

import { AccordionComponent } from './accordion.component';

@NgModule({
  declarations: [AccordionComponent],
  imports: [CommonModule, IconModule, ButtonModule],
  exports: [AccordionComponent],
})
export class AccordionModule {}
