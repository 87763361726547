import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';

@Component({
  selector: 'file-uploader-progress',
  templateUrl: './file-uploader-progress.component.html',
  styleUrls: ['./file-uploader-progress.component.scss'],
})
export class FileUploaderProgressComponent {
  @Input() file: File;
  @Input() loaded: number;
  @Input() total = 0;
  @Input() error: boolean;
  @Input() formArray: UntypedFormArray;
  /**
   * Hide the close icon during the upload process (when the POST call is not done), since clicking it does not stop the upload process.
   * If a user is uploading a big file (5 MB), and if the close icon is clicked during the process;
   * the corresponding file tile would be removed from the file uploader list,
   * however the document upload POST call is still processing.
   * After the POST call is completed, the file_id will still be added to the form control.
   * It cannot be seen from the file uploader UI, but the request payload will include the unwanted file_id once the form is submitted.
   * This issue can be fixed if the parent component unsubscribes the HTTP POST call once the closeClicked event is fired.
   *
   * Also, removing the 2nd or 3rd attachment during the upload process will overwrite the previous attachment’s progress bar number.
   * JWANG TODO: modify file uploader so each file should have its own progress bar number,
   * no more progress conflicts/overwriting.
   * */
  @Input() showCloseIcon = true;

  @Output() closeClicked = new EventEmitter();

  get size() {
    const kB = 1000;

    return `${Math.floor(this.file.size / kB)}KB`;
  }

  get progressWidth(): any {
    return { width: `${this.total}%` };
  }
}
