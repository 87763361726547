<div class="Checklist" *ngIf="config">
  <div class="Checklist__Header">
    <h4 class="t-bold" [innerHTML]="config.header"></h4>
  </div>
  <div class="Checklist__SubHeader">
    <p class="t-data" [innerHTML]="config.subheader"></p>
  </div>
  <message-banner
    *ngIf="config.messageBanner"
    [config]="config.messageBanner"
  ></message-banner>
  <card class="Checklist__Card" [config]="{ hideSubHeader: true }">
    <div title>
      <h4 [innerHTML]="config?.checkListSelectionForm?.title"></h4>
    </div>
    <div body class="Checklist__CardBody">
      <checkbox-list
        [config]="config.checkListSelectionForm"
        [checkboxOptions]="data"
        [showSelectAll]="false"
        (selectedOptionList)="handleListSelection($event)"
      >
      </checkbox-list>
    </div>
  </card>
</div>
