<div class="benefits">
  <div
    class="benefits__description"
    *ngIf="config?.description"
    [innerHTML]="config.description"
  ></div>
  <div class="benefits__table" *ngIf="config?.table">
    <zip-table
      [options]="config.table"
      [data]="planBenefits"
      (customMethod)="callCxEvent($event)"
    ></zip-table>
  </div>
</div>
