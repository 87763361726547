import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsComponent } from './notifications.component';
import { NotificationsAllowPagingComponent } from './notifications-allow-paging/notifications-allow-paging.component';
import { AllNotificationsComponent } from './all-notifications/all-notifications.component';

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationsAllowPagingComponent,
    AllNotificationsComponent,
  ],
  imports: [CommonModule],
  exports: [NotificationsComponent],
})
export class NotificationsModule {}
