export class PortalUser {
  accepted_terms?: string[];
  agency_id?: number;
  agency_name?: string;
  broker_id?: number;
  cohort_keys?: string[];
  create_timestamp?: string;
  cx_id?: number;
  data?: {
    title?: string;
    send_email_verification?: boolean;
    deactivation_date?: string;
    send_deactivation_email?: boolean;
    permissions?: string[];
  };
  email_address?: string;
  email_verified?: boolean;
  first_name?: string;
  group_name?: string;
  groups?: Group[];
  id?: string | number;
  idp_id?: string | number;
  identities?: Identities;
  is_active?: boolean;
  last_login?: string;
  last_name?: string;
  license_effective_date?: string;
  license_number?: number;
  member_id?: number;
  okta_id?: string | number;
  organization?: string;
  phone_number?: string;
  prospect_id?: number;
  role?: string;
  roles?: Role[];
  status?: string;
  tax_ids?: (string | number)[];
  tenant_name?: string;
  update_timestamp?: string;
  user_name?: string;
  zip_code?: string;

  constructor() {
    this.data = {};
  }
}

class Group {
  id?: number;
  name?: string;
  category?: string;
}

class Role {
  name?: string;
  display_name?: string;
  active?: boolean;
  id?: number;
  group?: string;
  category?: string;
}

class Identities {
  is_admin?: boolean;
  members?: any[];
  brokers?: any[];
  employers?: any[];
  providers?: any[];
}
