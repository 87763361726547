import { Directive } from '@angular/core';
import { Host, Optional, Self } from '@angular/core';
import {
  ButtonComponent,
  LinkListComponent,
  TableComponent,
} from '@zipari/design-system';
import { DetailTabsComponent } from '../../provider-portal/shared/modules/detail-tabs/detail-tabs.component';
import { AnalyticsService } from '../services/analytics.service';

@Directive({
  selector: 'zip-table, zip-button, detail-tabs, link-list',
})
export class CxCaptureDirective {
  /*
        Using @Host @Self and @Optional, we can access the components themselves that are
        accessed via the directive. The directive can attach directly onto a component via the selector(s)
        once we have access to this component, we can call methods, rewrite methods, access attributes etc and
        perform conditional actions here within the @Directive

        NOTE: All we need to do, to be able to access the functionality of this directive, is to make sure that
        the components `.module.ts` has imported `app/src/shared/shared.module` after that, directive can just use
        defined selectors to perform operations.
    */

  constructor(
    public analyticsService: AnalyticsService,
    @Host() @Self() @Optional() public hostButtonComponent: ButtonComponent,
    @Host() @Self() @Optional() public hostTableComponent: TableComponent,
    @Host()
    @Self()
    @Optional()
    public hostDetailTabsComponent: DetailTabsComponent,
    @Host() @Self() @Optional() public hostLinkListComponent: LinkListComponent
  ) {
    if (this.hostButtonComponent) {
      this.hostButtonComponent.customEventMethod = ({
        event: event,
        context: context,
      }) => {
        if (context?.config?.cx_event_key) {
          this.analyticsService.sendEvent(context.config.cx_event_key);
        }
        // For selections on action buttons with a subnav. cx_event_key must be placed in
        // the `props` object of subnav as a key/value pair to trigger this event
        if (context?.config?.subnav?.items?.length && event?.cx_event_key) {
          this.analyticsService.sendEvent(event.cx_event_key);
        }
      };
    }

    if (this.hostTableComponent) {
      this.hostTableComponent.cellClickedHandler = (event: any) => {
        /**
                        Check event column row and see if there is a specific cx handler for cell clicks: `cell_click_cx_event_key`)
                        We cannot just use a `cx_event_key` on the column itself as that is used for the sorting cx event
                        column config should include: `cell_click_cx_event_key` key, and the cx event key value.
                    **/

        /*
         * If CX event requires additional dictionary attributes, send them as an array
         * as the second parameter in the cx event call
         *
         * TODO: config is not final on this as event key doc is not fully completed yet
         */
        if (event?.col?.cell_click_cx_event_key) {
          this.analyticsService.sendEvent(
            event.col.cell_click_cx_event_key,
            event.col.dictionaryAttrs
          );
        }

        // ----- Original cell click functionality in DS -----
        const clickEvent = event?.col?.clickEvent;

        if (clickEvent && clickEvent.type === 'filter') {
          this.hostTableComponent.handleFilterClickEvent(event);
        }

        this.hostTableComponent.cellClicked.emit(event);
        // ---------------------------------------------------
      };

      this.hostTableComponent.customEventMethod = ({
        event: event,
        context: context,
      }) => {
        // In QAT componant name is not coming up. So changed the logic here.
        // For filter event would be an array and for sorting it would be an object
        Array.isArray(event)
          ? this.sendTableFilterEvents(event)
          : this.sendTableSortEvent(event);
      };
    }

    if (this.hostDetailTabsComponent) {
      this.hostDetailTabsComponent.customEventMethod = ({
        event: tabIndexSelected,
        context: context,
      }) => {
        const tabConfig = context.tabConfig;
        if (tabConfig?.tabConfigs?.[tabIndexSelected]?.cx_event_key) {
          this.analyticsService.sendEvent(
            tabConfig.tabConfigs[tabIndexSelected].cx_event_key
          );
        }
      };
    }

    if (this.hostLinkListComponent) {
      this.hostLinkListComponent.customMethod.subscribe((data: any) => {
        if (data.context && data.event?.cx_event_key) {
          this.analyticsService.sendEvent(data.event?.cx_event_key);
        }
      });
    }
  }

  // Iterate through all items in filter change array, if cx_event_key exists in config, send cx event
  sendTableFilterEvents(filterChanges): void {
    if (filterChanges?.length) {
      filterChanges.forEach((filter: any) => {
        if (filter?.cx_event_key) {
          const attribute = filter?.cx_event_dictionary_key
            ? {
                [filter?.cx_event_dictionary_key]: filter.value,
              }
            : {};
          this.analyticsService.sendEvent(filter.cx_event_key, attribute);
        }
      });
    }
  }

  // Check column being sorted, if it has cx_event_key in config, send cx event
  sendTableSortEvent(columnBeingSorted): void {
    if (columnBeingSorted?.cx_event_key) {
      this.analyticsService.sendEvent(columnBeingSorted.cx_event_key);
    }
  }
}
