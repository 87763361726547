import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TableModule } from '@zipari/design-system';
import { SharedModule } from '../../../../shared/shared.module';
import { BenefitsComponent } from './benefits.component';

@NgModule({
  declarations: [BenefitsComponent],
  exports: [BenefitsComponent],
  imports: [CommonModule, TableModule, SharedModule],
})
export class BenefitsModule {}
