import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridCellDirective } from '../../directives/grid-cell.directive';

@NgModule({
  declarations: [GridCellDirective],
  imports: [CommonModule],
  exports: [GridCellDirective],
})
export class FormGroupDirectivesModule {}
