export interface BrandColorSet {
  label: string;
  colors: BrandColor[];
}

export interface BrandColor {
  label: string;
  var: string;
  value: string;
}

export enum BrandColorLabels {
  TRANSPARENT = 'Transparent',
  WHITE = 'White',
  BLACK = 'Black',
}

export const BRAND_COLORS: BrandColorSet[] = [
  {
    label: 'Base',
    colors: [
      {
        label: BrandColorLabels.TRANSPARENT,
        var: '--transparent',
        value: 'var(--transparent)',
      },
      {
        label: BrandColorLabels.WHITE,
        var: '--white',
        value: 'var(--white)',
      },
      {
        label: BrandColorLabels.BLACK,
        var: '--black',
        value: 'var(--black)',
      },
    ],
  },
  {
    label: 'Primary Colors',
    colors: [
      {
        label: 'Primary 1',
        var: '--primary',
        value: 'var(--primary)',
      },
      {
        label: 'Primary 2',
        var: '--primary--highlight',
        value: 'var(--primary--highlight)',
      },
    ],
  },
  {
    label: 'Text',
    colors: [
      {
        label: 'Primary Text',
        var: '--text--1',
        value: 'var(--text--1)',
      },
      {
        label: 'Secondary Text',
        var: '--text--2',
        value: 'var(--text--2)',
      },
      {
        label: 'Link',
        var: '--link',
        value: 'var(--link)',
      },
    ],
  },
  {
    label: 'Grayscale',
    colors: [
      {
        label: 'Very Light Gray',
        var: '--gray--artboard',
        value: 'var(--gray--artboard)',
      },
      {
        label: 'Light Gray',
        var: '--gray--inactive',
        value: 'var(--gray--inactive)',
      },
      {
        label: 'Medium Gray',
        var: '--gray--border',
        value: 'var(--gray--border)',
      },
      {
        label: 'Dark Gray',
        var: '--gray--shadow',
        value: 'var(--gray--shadow)',
      },
      {
        label: 'Very Dark Gray',
        var: '--gray--jet',
        value: 'var(--gray--jet)',
      },
    ],
  },
  {
    label: 'Secondary Colors',
    colors: [
      {
        label: 'Secondary 1',
        var: '--secondary--1',
        value: 'var(--secondary--1)',
      },
      {
        label: 'Secondary 2',
        var: '--secondary--5',
        value: 'var(--secondary--5)',
      },
      {
        label: 'Secondary 3',
        var: '--secondary--6',
        value: 'var(--secondary--6)',
      },
    ],
  },
  {
    label: 'Notification Colors',
    colors: [
      {
        label: 'Notification 1',
        var: '--secondary--2',
        value: 'var(--secondary--2)',
      },
      {
        label: 'Notification 2',
        var: '--secondary--3',
        value: 'var(--secondary--3)',
      },
      {
        label: 'Notification 3',
        var: '--secondary--4',
        value: 'var(--secondary--4)',
      },
    ],
  },
  {
    label: 'Alerts',
    colors: [
      {
        label: 'Success',
        var: '--success',
        value: 'var(--success)',
      },
      {
        label: 'Warn',
        var: '--warn',
        value: 'var(--warn)',
      },
      {
        label: 'Error',
        var: '--error',
        value: 'var(--error)',
      },
    ],
  },
  {
    label: 'Tints',
    colors: [
      {
        label: 'Primary',
        var: '--primary--tint',
        value: 'var(--primary--tint)',
      },
      {
        label: 'Success',
        var: '--success--tint',
        value: 'var(--success--tint)',
      },
      {
        label: 'Warn',
        var: '--warn--tint',
        value: 'var(--warn--tint)',
      },
      {
        label: 'Error',
        var: '--error--tint',
        value: 'var(--error--tint)',
      },
      {
        label: 'Notification 1',
        var: '--notification--1--tint',
        value: 'var(--notification--1--tint)',
      },
      {
        label: 'Notification 2',
        var: '--notification--2--tint',
        value: 'var(--notification--2--tint)',
      },
    ],
  },
  {
    label: 'Mixes',
    colors: [
      {
        label: 'Text Mix',
        var: '--text--mix',
        value: 'var(--text--mix)',
      },
      {
        label: 'Text Mix 2',
        var: '--text--mix--2',
        value: 'var(--text--mix--2)',
      },
    ],
  },
];
