import { Injectable } from '@angular/core';
import {
  LinkConfig,
  NavigationLink,
  ToolbarDropdownOption,
} from '@zipari/shared-ds-util-navigation';
import { ReplaySubject } from 'rxjs';

/** Primarily handles emission of deep events, from components that are highly nestled, so we don't need to propagate the event through
 * many layer of component templates, but can simply trigger the ultimate event at the top level navigation component from anywhere below.
 *
 * It doesn't matter what the next value is because we are not using the value, but waiting for the observable to update to signal
 * that a logout event should be emitted from the topmost navigation component.
 *
 * Replay instead of behavior because we don't want an initial value. */
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  dropdownOptionSelected$ = new ReplaySubject<object>(1);
  loginClicked$ = new ReplaySubject<void>(1);
  logoutClicked$ = new ReplaySubject<object>(1);
  mobileMenuToggled$ = new ReplaySubject<boolean>(1);
  navItemClicked$ = new ReplaySubject<NavigationLink>(1);
  navMenuToggled$ = new ReplaySubject<NavigationLink>(1);

  getLinkConfig(link: NavigationLink): LinkConfig {
    return {
      label: link.label,
      externalLink: link.isExternalRoute ? link.route : null,
      route: !link.isExternalRoute ? link.route : null,
      useExternalSiteModal: link.useExternalSiteModal,
      target: link.target,
      removeLinkStyles: link.removeLinkStyles,
    };
  }

  propagateDropdownOptionSelected$(tdo: ToolbarDropdownOption): void {
    this.dropdownOptionSelected$.next(tdo);
  }

  propagateLoginClicked$(): void {
    this.loginClicked$.next(null);
  }

  propagateLogoutClicked$(): void {
    this.logoutClicked$.next(null);
  }

  propagateMobileMenuToggled$(bool: boolean): void {
    this.mobileMenuToggled$.next(bool);
  }

  propagateNavItemClicked$(navigationLink: NavigationLink): void {
    this.navItemClicked$.next(navigationLink);
  }

  propagateNavMenuToggled$(navigationLink: NavigationLink): void {
    this.navMenuToggled$.next(navigationLink);
  }
}
