<ng-container *ngIf="!isLoading && config; else loading">
  <article class="Centered__Base--Page" *ngIf="config">
    <div class="Gray">
      <div class="Header__Row">
        <section>
          <h3
            class="t-bold"
            role="heading"
            aria-level="1"
            [innerHTML]="context?.name"
          ></h3>
          <ng-container *ngIf="siteLocationMessage?.length > 0">
            <site-location-message
              [successHeader]="config.siteLocationDetails?.successHeader"
              [siteLocationMessage]="siteLocationMessage"
            ></site-location-message>
          </ng-container>
        </section>
      </div>
    </div>

    <div class="Tab__Body">
      <div
        *ngFor="
          let section of config?.siteLocationDetails?.sections;
          let i = index
        "
      >
        <header *ngIf="section.header" class="Tab__View--Header">
          <div *ngIf="section.headerIcon">
            <i
              class="Header__Icon--Position icon icon--product icon--medium"
              [innerHTML]="section.headerIcon.name"
            >
            </i>
          </div>
          <h6
            class="Tab__ViewHeader--Text t-bold"
            [innerHTML]="section.header"
          ></h6>
          <div *ngIf="section.isHeaderEditIconRequired">
            <i
              class="Header__EditIcon--Position icon icon--medium"
              (click)="openEditModal(i)"
              >edit
            </i>
          </div>
        </header>
        <div class="Tab__View__Three__Column">
          <div *ngFor="let item of section.fields.attributes">
            <model-attribute
              [context]="context"
              [config]="item"
            ></model-attribute>
          </div>
        </div>
      </div>
    </div>
  </article>
</ng-container>
<ng-template #loading>
  <spinner size="medium" flexCenter="true"></spinner>
</ng-template>

<ng-container
  *ngIf="config?.siteLocationDetails?.editFlyout; let editFlyoutConfig"
>
  <modal
    #editFlyout
    *ngIf="editModal"
    [config]="editFlyoutConfig"
    [condition]="editModal"
    (cancel)="closeEditModal()"
  >
    <div body>
      <div *ngIf="editFlyoutConfig?.subheader" class="Edit__Flyout--Subheader">
        <p [innerHTML]="editFlyoutConfig?.subheader"></p>
      </div>
      <div
        *ngFor="
          let group of editFlyoutConfig.formFields;
          let accordionIndex = index
        "
      >
        <header>
          <accordion
            [isOpen]="
              accordionIndex === openAccordionIndex || openAccordionIndex == -1
            "
            [header]="group.header"
            [content]="contentTemplate"
          ></accordion>
        </header>
        <ng-template #contentTemplate>
          <div *ngIf="group.type != 'locationHours'">
            <div (editButtonClicked)="setDropdownValueOnEditClicked()">
              <form-group
                [configs]="group.controls"
                [form]="editFields"
                [context]="context"
                (formCreated)="onFormCreated()"
              >
              </form-group>
            </div>
            <div *ngFor="let control of group.array_controls">
              <div [ngSwitch]="control.type">
                <input-array
                  [key]="'editSiteDetails'"
                  [config]="control"
                  *ngSwitchCase="'input'"
                  (inputArrayCreated)="onDropdowArrayCreated()"
                ></input-array>
                <dropdown-array
                  [key]="'editSiteDetails'"
                  [config]="control"
                  *ngSwitchCase="'dropdown'"
                  (formCreated)="onDropdowArrayCreated()"
                ></dropdown-array>
              </div>
            </div>
          </div>
          <div *ngIf="group.type == 'locationHours'">
            <location-hours
              #locationHours
              [config]="group.controls"
              [location_hours]="locationHoursForm"
              [context]="locationOperatingHours"
            ></location-hours>
          </div>
        </ng-template>
      </div>
    </div>
    <div footer>
      <zip-button
        [config]="editFlyoutConfig.submitButton"
        [disabled]="isDisable()"
        (zipButtonClicked)="editSubmit(additionalDetailsModalConfig)"
      ></zip-button>
    </div>
  </modal>

  <pp-additional-detail-modal
    *ngIf="showAdditionalDetailsModal"
    [config]="additionalDetailsModalConfig"
    [showAdditionalDetailsModal]="showAdditionalDetailsModal"
    [additionalDetailsForm]="additionalDetailsForm"
    (closeModal)="cancelEdit()"
    (submitClicked)="submit($event)"
  ></pp-additional-detail-modal>
</ng-container>
