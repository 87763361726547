<tabs
  [tabLabels]="tabConfig?.labels"
  [noTabBorder]="tabConfig?.noTabBorder"
  [selectedIndex]="activeTab"
  (tabSelected)="tabSelected($event)"
></tabs>

<tab-view
  [selectedTab]="activeTab"
  [customInitSelectTab]="activeTab"
  [config]="tabConfig"
  [data]="tabData"
  (selectedTab)="(tabSelected)"
  (save)="onSave($event)"
  [outOfNetwork]="outOfNetwork"
></tab-view>
