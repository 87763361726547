import { Injectable, isDevMode } from '@angular/core';
import posthog, { Properties } from 'posthog-js';

export interface PosthogConfiguration {
  posthogApiKey: string;
  apiHost: string;
  debugEnabled: boolean;
  capturePageview?: boolean;
  autocaptureOn?: boolean;
  mtmEnabled: boolean;
  advancedDisableDecide?: boolean;
  disableSessionRecording?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PostHogService {
  initialize(config: PosthogConfiguration): void {
    if (isDevMode()) {
      return;
    }

    const {
      apiHost: api_host,
      posthogApiKey,
      autocaptureOn,
      capturePageview,
      advancedDisableDecide,
      disableSessionRecording,
    } = config;

    // Autocapture is off as too much of the data we need to capture is not already on the html which
    // is where autocapture pulls from
    /* eslint-disable @typescript-eslint/no-unnecessary-boolean-literal-compare */
    posthog.init(posthogApiKey, {
      api_host,
      autocapture: !!autocaptureOn,
      capture_pageview: !!capturePageview,
      cross_subdomain_cookie: false,
      advanced_disable_decide: advancedDisableDecide === false, // must be explicitly set to false
      disable_session_recording: disableSessionRecording === false, // must be explicitly set to false
    });

    const { debugEnabled } = config;

    if (debugEnabled) this.debug();
  }

  identifyUser(identifier: string, eventData: Properties | undefined): void {
    !isDevMode() && posthog.identify(identifier, eventData);
  }

  captureEvent(key: string, attributes?: object): void {
    !isDevMode() && posthog.capture(key, attributes);
  }

  debug(): void {
    !isDevMode() && posthog.debug();
  }

  reset(): void {
    !isDevMode() && posthog.reset(true);
  }
}
