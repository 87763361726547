import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { camelCase } from '../../shared/utils/case-conversion.util';
import { acceptHeaderKey } from './claim-submission.constant';
import { createClaimPayload } from './claim-submission.helper';
import {
  ClaimSubmissionDocumentData,
  SubmittedClaimDetails,
} from './submittedClaim';

@Injectable({
  providedIn: 'root',
})
export class ClaimSubmissionService {
  uploadedFiles = [];
  uploadStarted$: Subject<boolean> = new Subject<boolean>();
  uploadProgress$: Subject<number> = new Subject<number>();
  uploadCompleted$: Subject<boolean> = new Subject<boolean>();
  uploadError: Subject<boolean> = new Subject<boolean>();
  documentIds: number[] = [];
  apiResponse: SubmittedClaimDetails;
  public claimSubmitFormData: UntypedFormGroup;
  hasReturnedFromReviewPage = false;
  selectedPatient: string[];
  reviewSectionsId: string;
  claimId: string | number;
  claimNumber: string;
  filesList: ClaimSubmissionDocumentData[] = [];

  constructor(private http: HttpClient) {}

  uploadFile(fileList, documentUploadUrl) {
    this.uploadAttachments(fileList, documentUploadUrl).subscribe(
      (response) => {
        this.getActionFromHttpEvent(response);
        this.uploadError.next(false);
      },
      (error) => {
        this.uploadError.next(true);

        return error;
      }
    );
  }

  uploadAttachments(fileList, documentUploadUrl): Observable<HttpEvent<{}>> {
    const lastUploadedFile = [fileList[fileList.length - 1]];

    const formData = new FormData();
    formData.append('file', lastUploadedFile[0]);

    const options = {
      reportProgress: true,
    };

    return this.http.request(
      new HttpRequest('POST', `${documentUploadUrl}`, formData, options)
    );
  }

  getActionFromHttpEvent(event: HttpEvent<any>) {
    const successStatus = 200;
    const percentageConverter = 100;

    switch (event.type) {
      case HttpEventType.Sent: {
        this.uploadStarted$.next(true);

        return;
      }
      /* falls through */

      case HttpEventType.UploadProgress: {
        this.uploadProgress$.next(
          Math.round((percentageConverter * event.loaded) / event.total)
        );

        return;
      }
      /* falls through */

      case HttpEventType.ResponseHeader:
      case HttpEventType.Response: {
        if (event.status === successStatus) {
          if (event['body']) {
            this.uploadedFiles.push(event['body']);
            this.uploadError.next(false);
            this.uploadCompleted$.next(true);

            return;
          }
        } else {
          this.uploadError.next(true);

          return;
        }
      }
      /* falls through */

      default: {
        this.uploadCompleted$.next(true);

        return;
      }
    }
  }

  removeFile(fileId: number): void {
    this.uploadedFiles.splice(fileId, 1);
    this.filesList.splice(fileId, 1);
  }

  getDocumentIds(): void {
    this.documentIds = [];
    this.uploadedFiles.forEach((file) => {
      this.documentIds.push(file.id);
    });
  }

  claimSubmissionPostCall(
    formData: UntypedFormGroup,
    claimType: string,
    claimSubmissionUrl: string,
    submissionCategory: string,
    policyId: string
  ): Observable<object | HttpErrorResponse> {
    this.getDocumentIds();
    const payload = createClaimPayload(
      formData,
      submissionCategory,
      claimType,
      this.documentIds,
      this.selectedPatient,
      policyId
    );

    if (this.claimId) {
      const claimSubmissionPatchUrl = `${claimSubmissionUrl}${this.claimId}/`;

      return this.http.patch<object | HttpErrorResponse>(
        claimSubmissionPatchUrl,
        payload
      );
    } else {
      return this.http.post<object | HttpErrorResponse>(
        claimSubmissionUrl,
        payload
      );
    }
  }

  getClaimSubmissionDetailById(
    endPoint: string
  ): Observable<SubmittedClaimDetails | HttpErrorResponse> {
    return this.http.get(endPoint).pipe(map(camelCase));
  }

  public downloadDocument(id: string, documentUploadUrl: string) {
    const endpoint = `${documentUploadUrl}${id}/`;

    return this.http.get(endpoint, {
      headers: new HttpHeaders({ [acceptHeaderKey]: '*/*' }),
      responseType: 'blob',
    });
  }
}
