import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { Idle, AutoResume } from '@ng-idle/core';
import { stringBuilder } from '@zipari/web-utils';
import posthog from 'posthog-js';
import { IdleConfig, IdleStates } from '../../app.constants';
import { ConfigService } from './config.service';
import { WINDOW } from './window.service';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  [x: string]: any;
  public enabled: boolean;

  idling: boolean;
  idleState: string;
  timeoutCountdown: number;
  config: IdleConfig;
  service: any;

  constructor(
    @Inject(WINDOW) public window: Window,
    public idle: Idle,
    private http: HttpClient,
    public configService: ConfigService
  ) {}

  /** documentation can be found @ https://hackedbychinese.github.io/ng2-idle/ */
  setupIdle(
    idleConfig: IdleConfig = {
      enabled: false,
      timeToIdle: 0,
      timeoutAfterIdle: 0,
      interrupts: [],
    }
  ): void {
    this.config = idleConfig;
    this.enabled = idleConfig.enabled;

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = IdleStates.Not_Idle;
    });

    this.idle.onTimeout.subscribe(() => {
      this.idleState = IdleStates.Timeout;
      this.handleLogout();
      /** 02/25/2021 sprint 80: session-expired page is not needed at the moment */
      // this.handleTimeout();
    });

    this.idle.onIdleStart.subscribe(() => {
      this.idleState = IdleStates.Started;
    });

    this.idle.onTimeoutWarning.subscribe((countdown: number) => {
      this.timeoutCountdown = countdown;
      this.idleState = stringBuilder(IdleStates.TimeoutWarning, { countdown });
    });

    this.idle.setIdle(idleConfig.timeToIdle);

    this.idle.setTimeout(idleConfig.timeoutAfterIdle);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(idleConfig.interrupts);

    this.idle.setAutoResume(AutoResume.notIdle);
  }

  public get isIdling() {
    return this.idle.isIdling();
  }

  public stopTimeout() {
    this.idle.stop();
  }

  public resetTimeout() {
    this.idle.watch();
    this.idleState = 'Started.';
  }

  public handleLogout() {
    posthog.reset(true);
    if (this.config.redirectAfterLogoutUrl) {
      this.http
        .get('/logout', {})
        .subscribe()
        .add(() => {
          this.window?.location.assign(
            this.config.redirectAfterLogoutUrl || ''
          );
        });
    } else {
      this.window?.location.assign(
        `/logout?next=${this.window?.location.origin}/${this.configService.appRoute}/login`
      );
    }
  }

  public handleTimeout() {
    this.window?.location.assign(
      `/logout?next=${this.window?.location.origin}/${this.configService.appRoute}/session-expired`
    );
  }
}
