import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  FormControlModule,
  FormGroupModule,
  ModelAttributeModule,
} from '@zipari/design-system';
import { ItemCellComponent } from './item-cell.component';

@NgModule({
  declarations: [ItemCellComponent],
  exports: [ItemCellComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormControlModule,
    FormGroupModule,
    ModelAttributeModule,
  ],
})
export class ItemCellModule {}
