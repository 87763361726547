import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { Prompt } from './../../models/table.model';

const defaultWaitTimeBeforeMessageDisappears = 60000;
@Component({
  selector: 'table-prompt',
  templateUrl: './table-prompt.component.html',
  styleUrls: ['./table-prompt.component.scss'],
})
export class TablePromptComponent implements OnChanges {
  @Input() config: {
    prompts: Prompt[];
    waitTimeBeforeMessageDisappears?: number;
  };
  @Input() prompt: Prompt;
  @Output() promptActionClicked: EventEmitter<any> = new EventEmitter();

  activatedPrompt: Prompt;
  waitTimeBeforeMessageDisappears: number;
  asyncClosePrompt: number;
  showPrompt = false;

  ngOnChanges() {
    this.activatedPrompt =
      this.config?.prompts?.find(
        (prompt: Prompt) => prompt.key === this.prompt?.key
      ) || this.prompt;
    this.waitTimeBeforeMessageDisappears =
      this.config?.waitTimeBeforeMessageDisappears ||
      defaultWaitTimeBeforeMessageDisappears;
    if (this.activatedPrompt) this.displayPrompt();
  }

  setCustomClasses() {
    return `${
      this.activatedPrompt?.customCSSClass
        ? this.activatedPrompt.customCSSClass
        : this.activatedPrompt.type
    }`;
  }

  displayPrompt(): void {
    clearTimeout(this.asyncClosePrompt);
    this.showPrompt = true;
    this.asyncClosePrompt = window.setTimeout(() => {
      this.closePrompt();
    }, this.waitTimeBeforeMessageDisappears);
  }

  triggerPromptAction(event?: any) {
    if (event) {
      this.promptActionClicked.emit(event);
    }
  }

  closePrompt(): void {
    this.showPrompt = false;
    this.activatedPrompt = null;
  }
}
