import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControlService } from '../form-control.service';

import { ControlTextModule } from '../text/text.module';

import { ControlCheckboxComponent } from './checkbox.component';

@NgModule({
  declarations: [ControlCheckboxComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ControlTextModule],
  providers: [FormControlService],
  exports: [ControlCheckboxComponent],
})
export class ControlCheckboxModule {}
