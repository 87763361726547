<ng-container
  *ngIf="!config.hasOwnProperty('showGrid') || context[config.showGrid]"
>
  <div class="checkbox-matrix--error" *ngIf="dataError">
    {{ config.errorMessage || 'Data Error, try again at another time.' }}
  </div>

  <div class="checkbox-matrix" *ngIf="!dataError">
    <div
      [formGroup]="radioFormGroup"
      *ngIf="config.hasOwnProperty('radioConfig')"
      class="checkbox-matrix__radio-wrapper"
    >
      <div *ngFor="let option of config.radioConfig.options">
        <label
          [attr.aria-label]="config.radioConfig.prop"
          class="form-control--radio__wrapper"
        >
          <input
            type="radio"
            class="form-control form-control--radio"
            [value]="option.value"
            [formControlName]="config.radioConfig.prop"
          />
          <p class="t-data" [innerHtml]="option.label"></p>
        </label>
      </div>
    </div>

    <div class="checkbox-grid">
      <div class="checkbox-grid__header-wrapper">
        <div class="checkbox-grid__title-wrapper">
          <p class="t-bold">{{ config.title }}</p>
        </div>

        <div class="checkbox-grid__titles-wrapper">
          <div class="checkbox-grid__titles--container">
            <form [formGroup]="headerFormGroup">
              <div class="checkbox-grid__titles">
                <div
                  *ngFor="let column of gridData[config.columns]"
                  class="checkbox-grid__title"
                  [style.width.%]="100 / gridData[config.columns].length"
                >
                  <label
                    [for]="column[config.columnIdProp]"
                    attr.aria-label="Toggle {{ column.short_name }}"
                    class="form-control--checkbox__wrapper"
                  >
                    <input
                      class="form-control form-control--checkbox"
                      *ngIf="
                        !config?.hasOwnProperty('readOnly') &&
                        !config?.isHidden &&
                        hasCheckboxes(column[config?.columnIdProp]) &&
                        (config?.hasOwnProperty('radioConfig')
                          ? radioFormGroup.controls[config?.radioConfig?.prop]
                              .value
                          : true)
                      "
                      type="checkbox"
                      [id]="column[config.columnIdProp]"
                      [formControlName]="column[config.columnIdProp]"
                      (change)="
                        toggleColumn(
                          column[config.columnIdProp],
                          headerFormGroup.controls[column[config.columnIdProp]]
                            .value
                        )
                      "
                    />
                    <p class="t-bold" [innerHtml]="column.short_name"></p>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="checkbox-grid__rows-wrapper">
        <div class="checkbox-grid__grid-wrapper">
          <div
            *ngFor="
              let group of objectToArray(checkboxFormGroup.controls);
              let groupIndex = index
            "
            class="checkbox-grid__row"
          >
            <form [formGroup]="group" class="checkbox-grid__grid">
              <div class="checkbox-grid__rowLabels-wrapper">
                <div class="checkbox-grid__rowLabel">
                  <p
                    class="t-bold"
                    [innerHtml]="gridData[config.rows][groupIndex].short_name"
                  ></p>
                </div>
                <p
                  class="t-caption"
                  [innerHtml]="gridData[config.rows][groupIndex].detail_text"
                ></p>
              </div>
              <div class="checkbox-grid__cells-wrapper">
                <div
                  class="u-flex u-fillRemaining"
                  *ngFor="
                    let column of gridData[config.columns];
                    let columnIndex = index
                  "
                >
                  <ng-container
                    *ngIf="
                      group.controls[column[config.columnIdProp]];
                      let control
                    "
                  >
                    <span
                      *ngIf="
                        config.isHidden ||
                          (config.hasOwnProperty('radioConfig') &&
                            !radioFormGroup.get(config.radioConfig.prop).value);
                        else show
                      "
                    >
                    </span>

                    <ng-template #show>
                      <span
                        *ngIf="config.hasOwnProperty('readOnly'); else standard"
                      >
                        <span
                          *ngIf="
                            group.controls[column[config.columnIdProp]].value
                          "
                        >
                          <span
                            [innerHTML]="config.readOnly.character"
                            *ngIf="config.readOnly.character; else defaultChar"
                          >
                          </span>

                          <ng-template #defaultChar>
                            <i class="icon icon--medium icon--pointer">check</i>
                          </ng-template>
                        </span>
                      </span>
                      <ng-template #standard>
                        <label
                          [attr.aria-label]="
                            column[config.columnIdProp].heading
                          "
                          class="form-control--checkbox__wrapper"
                        >
                          <input
                            (change)="
                              matrixItemChange(column[config.columnIdProp])
                            "
                            class="form-control form-control--checkbox"
                            type="checkbox"
                            [formControlName]="column[config.columnIdProp]"
                          />
                        </label>
                      </ng-template>
                    </ng-template>
                  </ng-container>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
