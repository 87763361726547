import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../../notes/button/button.module';
import { CardModule } from '../../notes/card/card.module';
import { IconModule } from '../../notes/icon/icon.module';
import { ModalModule } from '../../notes/modal/modal.module';
import { ModelAttributeModule } from '../../notes/model-attribute/model-attribute.module';
import { PreloaderModule } from '../../notes/preloader/preloader.module';
import { SidebarModule } from '../../notes/sidebar-main-container/sidebar/sidebar.module';
import { StatusModule } from '../../notes/status/status.module';
import { DigitalDataModule } from '../../shared/modules/digital-data/digital-data.module';
import { ModelAttributeGridModule } from '../model-attribute-grid/model-attribute-grid.module';
import { AddLabelsNotesModalComponent } from './components/add-labels-notes-modal/add-labels-notes-modal.component';
import { InfoModalComponent } from './components/info-modal/info-modal.component';
import { MobileComponent } from './components/mobile/mobile.component';
import { OverviewModalComponent } from './components/overview-modal/overview-modal.component';
import { OverviewComponent } from './components/overview/overview.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { SidePanelCustomComponent } from './components/side-panel-custom/side-panel-custom.component';
import { SidePanelDownloadsComponent } from './components/side-panel-downloads/side-panel-downloads.component';
import { SidePanelHeaderComponent } from './components/side-panel-header/side-panel-header.component';
import { SidePanelLabelsAndNotesComponent } from './components/side-panel-labels-and-notes/side-panel-labels-and-notes.component';
import { SidePanelComponent } from './side-panel.component';

@NgModule({
  declarations: [
    SidePanelComponent,
    ResourcesComponent,
    OverviewComponent,
    SidePanelHeaderComponent,
    SidePanelLabelsAndNotesComponent,
    SidePanelDownloadsComponent,
    MobileComponent,
    OverviewModalComponent,
    InfoModalComponent,
    AddLabelsNotesModalComponent,
    SidePanelCustomComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    CardModule,
    DigitalDataModule,
    IconModule,
    ModalModule,
    ModelAttributeModule,
    ModelAttributeGridModule,
    SidebarModule,
    PreloaderModule,
    StatusModule,
  ],
  exports: [
    AddLabelsNotesModalComponent,
    OverviewComponent,
    ResourcesComponent,
    SidePanelComponent,
    SidePanelHeaderComponent,
    SidePanelLabelsAndNotesComponent,
    SidePanelDownloadsComponent,
    MobileComponent,
  ],
})
export class SidePanelModule {}
