import { Component, isDevMode, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { controlTypes, SwitchConfiguration } from '@zipari/shared-ds-util-form';

import { PHONE_REGEXP } from '@zipari/web-utils';
import { mmDdYyyyFromIsoDatetime } from '@zipari/web-utils';
import { fade, slideRight } from '../../../../shared/animations';
import { PortalUser } from '../../../../shared/models/shared/PortalUser.model';
import { PortalUsersService } from '../portal-users.service';

@Component({
  animations: [fade, slideRight],
  selector: 'portal-user-detail',
  styleUrls: ['./portal-user-detail.component.scss'],
  templateUrl: './portal-user-detail.component.html',
})
export class PortalUserDetailComponent implements OnInit {
  deactivationForm: UntypedFormGroup;
  formattedLastLogin: string;
  id: number;
  modalIsVisible = false;
  portalUser: PortalUser;
  sendEmailConfig: SwitchConfiguration;
  userDetailsForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private portalUserService: PortalUsersService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    // Build static forms.
    this.deactivationForm = this.buildDeactivationForm();
    this.sendEmailConfig = this.buildSendEmailConfig();

    // Get the portal user for this route and build the view and form for them.
    const username = this.route.snapshot.params.username;
    this.portalUser = new PortalUser();
    this.portalUserService.getPortalUser(username)?.subscribe(
      (portalUser: PortalUser) => {
        this.portalUser = portalUser;
        this.formattedLastLogin = mmDdYyyyFromIsoDatetime(
          this.portalUser.last_login
        );
        this.deactivationForm = this.buildDeactivationForm();
        this.userDetailsForm = this.buildUserDetailsForm(portalUser);
      },
      (err: any) => {
        console.error(err);
      }
    );
    this.sendEmailConfig = this.buildSendEmailConfig();
  }

  buildSendEmailConfig(): SwitchConfiguration {
    const sendEmailConfig = {
      prop: 'sendEmail',
      label: 'Send Email',
      type: controlTypes.switch,
      validators: [],
    };

    return sendEmailConfig;
  }

  buildDeactivationForm(): UntypedFormGroup {
    return this.fb.group({
      date: ['', Validators.required],
      sendEmail: [false],
    });
  }

  buildUserDetailsForm(portalUser: PortalUser): UntypedFormGroup {
    const userDetailsForm = this.fb.group({
      profile: this.fb.group({
        firstName: [portalUser.first_name, Validators.required],
        lastName: [portalUser.last_name, Validators.required],
        jobTitle: [portalUser.data.title, Validators.required],
      }),
      contact: this.fb.group({
        emailAddress: [
          portalUser.email_address,
          [Validators.required, Validators.email],
        ],
        phoneNumber: [
          portalUser.phone_number,
          [Validators.required, Validators.pattern(PHONE_REGEXP)],
        ],
      }),
      security: this.fb.group({
        username: [portalUser.user_name, Validators.required],
        role: [portalUser.roles[0].name],
        permissions: [{ value: portalUser.data.permissions, disabled: true }],
      }),
    });

    return userDetailsForm;
  }

  toPortalUser(userDetailsForm: UntypedFormGroup): PortalUser {
    return Object.assign(this.portalUser, userDetailsForm?.value);
  }

  onConfirmDeactivation() {
    if (this.deactivationForm.invalid) {
      return;
    }

    // Put the deactivation date and whether to send an email to the backend.
    const date = this.deactivationForm.controls.date.value;
    const sendEmail = this.deactivationForm.controls.sendEmail.value;
    this.portalUserService
      .deactivateUser(this.portalUser, date, sendEmail)
      .subscribe(
        (res: PortalUser) => res,
        (err: any) => this.handleError(err)
      );

    this.hideModal(); // Close the modal to indicate the deactivation was successful.
  }

  onSaveChanges() {
    this.portalUserService
      .editPortalUser(this.toPortalUser(this.userDetailsForm))
      .subscribe(
        (res: PortalUser) => res,
        (err: any) => this.handleError(err)
      );
  }

  hideModal = () => (this.modalIsVisible = false);
  showModal = () => (this.modalIsVisible = true);
  handleError = (err: any) => {
    if (isDevMode()) {
      console.error(err);
    }
  };
}
