import { Pipe, PipeTransform } from '@angular/core';
import {
  DatagridColumn,
  DatagridSortDirection,
} from '@zipari/shared-ds-util-datagrid';

@Pipe({ name: 'datagridSortingState' })
export class DatagridSortingStatePipe implements PipeTransform {
  transform(column: DatagridColumn, sortOrder: string): string {
    if (column?.sortable && sortOrder) {
      if (!sortOrder) {
        return null;
      }
      const propToSortBy = column.sortByProp || column.prop;
      const ascendingProp = sortOrder.startsWith('-')
        ? sortOrder.slice(-(sortOrder.length - 1))
        : sortOrder;
      const isSortedColumn = ascendingProp && ascendingProp === propToSortBy;
      // No sorting if this is not the sorting column
      if (!isSortedColumn) {
        return null;
      }
      const sortDirectionDescending = sortOrder.startsWith('-');

      return sortDirectionDescending
        ? DatagridSortDirection.DESCENDING
        : DatagridSortDirection.ASCENDING;
    }

    return null;
  }
}
