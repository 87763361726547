export enum validMasks {
  splitSsn = 'splitSsn',
  phone = 'phone',
  ssn = 'ssn',
  currency = 'currency',
  number = 'number',
  taxId = 'taxId',
  month = 'month',
  date = 'date',
}

const ssn = 11;
const last4 = -4;

export const MASKS = {
  // only allow numbers, replace all with X on blur
  splitSsn: {
    mask: (value: string) => value.replace(/[^\d]/g, ''),
    unmask: (value: string) => value.replace(/[^\d]/g, ''),
    blur: (value: string) => value.replace(/./g, 'X'),
  },
  phone: {
    mask: (value: string) =>
      value &&
      value
        .replace(/[^\d]/g, '')
        .replace(/([\d]{3})/, '$1-')
        .replace(/(-[\d]{3})/, '$1-'),
    unmask: (value: string) => value && value.replace(/[^\d]/g, ''),
  },
  ssn: {
    mask: (value: string) =>
      value &&
      value
        .replace(/[^\d]/g, '')
        .replace(/([\d]{3})/, '$1-')
        .replace(/(-[\d]{2})/, '$1-'),
    unmask: (value: string) => value && value.replace(/[^\d]/g, ''),
    blur: (value: string) => {
      if (value.length === ssn) {
        return `XXX-XX-${value.substr(last4)}`;
      }

      return value;
    },
  },
  currency: {
    // mask regex source: https://coderwall.com/p/uccfpq/formatting-currency-via-regular-expression
    mask: (value: string) => {
      const numberWithCommas = value
        .replace(/[^\d]/g, '')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

      return `$${numberWithCommas}`;
    },
    unmask: (value: string) => value && value.replace(/[^\d]/g, ''),
  },
  // Use this for html maxlength but with numbers (because maxlength doesn't work on input type=number)
  number: {
    mask: (value: string) => value.replace(/[^\d]/g, ''),
    unmask: (value: string) => value.replace(/[^\d]/g, ''),
  },
  alpha: {
    mask: (value: string) => value.replace(/[\d]/g, ''),
    unmask: (value: string) => value.replace(/[\d]/g, ''),
  },
  taxId: {
    mask: (value: string) =>
      value && value.replace(/[^\d]/g, '').replace(/([\d]{2})/, '$1-'),
    unmask: (value: string) => value && value.replace(/[^\d]/g, ''),
  },
  monthDay: {
    mask: (value: string) =>
      value && value.replace(/[^\d]/g, '').replace(/([\d]{2})/, '$1/'),
    unmask: (value: string) =>
      value && value.replace(/[^\d]/g, '').replace(/([\d]{2})/, '$1/'),
  },
  month: {
    mask: (value: string) =>
      value && value.replace(/[^\d]/g, '').replace(/([\d]{4})/, '$1-'),
    // .replace(/[^\0-9]{6,7}/),
    unmask: (value: string) =>
      value && value.replace(/[^\d]/g, '').replace(/([\d]{4})/, '$1-'),
    // .replace(/[^\0-9]{6,7}/),
  },
  date: {
    mask: (value: string) =>
      value &&
      value
        .replace(/[^\d]/g, '')
        .replace(/([\d]{4})/, '$1-')
        .replace(/(-[\d]{2})/, '$1-'),
    unmask: (value: string) =>
      value &&
      value
        .replace(/[^\d]/g, '')
        .replace(/([\d]{4})/, '$1-')
        .replace(/(-[\d]{2})/, '$1-'),
  },
};
