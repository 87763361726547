<ng-container *ngIf="config.options">
  <p
    [ngStyle]="{ 'text-align': config.alignStaticText }"
    *ngIf="
      config.options.length === 1 && config.ifOneOptionShowStaticText;
      else select
    "
    class="dropdown__static-text"
    [ngStyle]="{ 'text-align': config.alignStaticText }"
    tabindex="0"
  >
    {{ config.options[0].label }}
  </p>
</ng-container>

<ng-template #select>
  <div
    *ngIf="!config.hideIfOnlyOneOption || hideOrShowOptions()"
    class="form-control form-control--dropdown"
    [ngClass]="{ 'form-control--readonly': config.readonly }"
    [attr.disabled]="isDisabled || null"
    [attr.readonly]="config.readonly || null"
  >
    <select
      ngDefaultControl
      role="listbox"
      [attr.disabled]="isDisabled || null"
      [attr.readonly]="config.readonly || null"
      [formControl]="control"
      [id]="config.id || config.prop"
      [attr.aria-describedby]="(config?.id || config?.prop) + '-label'"
      [attr.aria-errormessage]="
        'error_' + (config?.id || config?.prop) + '-input'
      "
      [attr.aria-invalid]="(control.dirty || control.touched) && !control.valid"
      [name]="config.prop"
      [attr.data-test-id]="'Dropdown_' + (config.id || config.prop)"
      [attr.autocomplete]="config?.autocomplete || config.prop"
      (change)="onOptionSelected($event, config)"
    >
      <ng-container *ngFor="let option of config.options; let i = index">
        <option
          tabindex="0"
          [ngValue]="option.value"
          [attr.disabled]="
            option.isDisabled ||
            (option.value === null && config.validators?.includes('required'))
              ? true
              : null
          "
          [attr.selected]="i === selectedIndex ? 'selected' : null"
          [innerHTML]="option.label"
        ></option>
      </ng-container>
    </select>

    <div class="u-flex u-alignCenter u-justifyEnd u-fillRemaining">
      <icon
        [config]="{
          format: 'svg',
          size: config.iconSize || 'small'
        }"
        [name]="config.icon || 'carat_down'"
        class="dropdown__chevron"
      ></icon>
    </div>
  </div>
</ng-template>
