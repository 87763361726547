<div class="ExpandingList" data-test-id="expandinglist" @accordion>
  <ng-container
    *ngFor="let listItem of list | slice : 0 : limit; index as index"
  >
    <div
      class="ExpandingList__Item"
      [innerHTML]="listItem"
      *ngIf="(index < lineCount && !isExpanded) || isExpanded"
    ></div>
  </ng-container>
  <zip-button
    *ngIf="listLength > lineCount"
    class="ExpandingList__Button"
    [config]="{
      level: 'text',
      content: buttonText,
      icon: buttonIcon
    }"
    (click)="showHide()"
  ></zip-button>
</div>
