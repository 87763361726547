import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ModelAttributeConfig } from '@zipari/shared-ds-util-configuration';
import { Subject, takeUntil } from 'rxjs';
import { ConfigService } from '../../../../../shared/services';
import { GenericServices } from '../../../../shared/services/generic.service';
import { ActivateUserConstants } from '../../manage-portal-users-details.constant';
import SuspendUserEventService from '../../services/suspend-user-event.service';
import ActivateUserService from '../../services/activate-user.service';
import {
  ActivateUserConfig,
  CheckListItem,
} from '../../manage-portal-users-details.model';
import { PortalUser } from '../../../../../shared/models/shared/UserPayloadModels/PortalUser.model';

@Component({
  selector: 'activate-user',
  templateUrl: './activate-user.component.html',
  styleUrls: ['./activate-user.component.scss'],
})
export class ActivateUserComponent implements OnInit, OnDestroy {
  config: ActivateUserConfig;
  state: string;
  activeStepIndex: number = 0;
  role: string;
  accessId: string;
  idpId: string;
  showErrorModal: boolean;
  errorMessage: string;
  private destroy$ = new Subject();

  constructor(
    private configService: ConfigService,
    private suspendUserEventService: SuspendUserEventService,
    public genericServices: GenericServices,
    private location: Location,
    private activateUserService: ActivateUserService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.config = this.configService.getPageConfig(
      ActivateUserConstants.managePortalUserDetail
    )?.[ActivateUserConstants.activateUser];
    this.role = this.route.snapshot.data?.role;
    this.accessId = this.route.snapshot.data?.access_id;
    this.idpId = this.route.snapshot.params?.idp_id;

    const initialStepProp = this.config?.activateUserSteps?.attributes[0]?.prop;
    if (initialStepProp) {
      this.state = initialStepProp;
      this.suspendUserEventService.emitDynamicButtonConfig(
        this.config[this.state].nextButton
      );
      this.activateUserService.getGroupsList(this.config.selectGroups.endpoint);
    }
    this.genericServices.showFooter$.next(false);
  }

  handleNextStep(): void {
    const activateSteps = this.config?.activateUserSteps?.attributes;
    if (this.state == ActivateUserConstants.review) {
      this.activateUser();
    } else {
      this.moveToNextStep(activateSteps);
    }
  }

  activateUser(): void {
    const provisioningUrl = `provider-portal/${this.role}/user-provisioning/edit/`;
    const groups = this.activateUserService.selectedGroups.map(
      (group: CheckListItem) => ({ id: group?.value['id'] })
    );
    const payload = {
      groups: groups,
      status: 'Active',
    };
    const endpoint = this.setIdpId(this.config?.endpoint);

    this.activateUserService
      .activateUser(endpoint, payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (user: PortalUser) => {
          this.router.navigate([`${provisioningUrl}${user.idpId}`], {
            state: { id: this.accessId },
          });
        },
        (error) => {
          this.displayModalAndErrorMessage(error.error?.errors[0]?.user_error);
        }
      );
  }

  displayModalAndErrorMessage(errorMessage: string): void {
    this.showErrorModal = true;
    this.errorMessage = errorMessage;
  }

  moveToNextStep(activateSteps: ModelAttributeConfig[]): void {
    if (this.activeStepIndex < activateSteps.length - 1) {
      this.activeStepIndex += 1;
      const stepProp = activateSteps[this.activeStepIndex]?.prop;
      if (stepProp) this.state = stepProp;
    }
  }

  errorModalSubmitClicked(): void {
    this.showErrorModal = false;
  }

  editStep(state: string): void {
    this.state = state;
    this.activeStepIndex -= 1;
  }

  navigateToPreviousPage(): void {
    this.location.back();
  }

  getSelectedGroups(groups: CheckListItem[]): void {
    this.activateUserService.selectedGroups = groups;
  }

  setIdpId(endpoint: string): string {
    return endpoint.replace('${idp_id}', this.idpId);
  }

  ngOnDestroy(): void {
    this.activateUserService.selectedGroups = [];
    this.destroy$.next(true);
  }
}
