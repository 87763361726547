import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, map } from 'rxjs';
import {
  ClaimAppealDetailConfig,
  ServiceInfo,
  ServiceInformation,
  ServicingProvider,
} from '../../../../shared/models/shared/ClaimAppealDetail.model';
import { ConfigService } from '../../../../shared/services';
import { ClaimAppealDetailDataService } from '../claim-appeal-detail.data.service';

@Component({
  selector: 'service-response',
  templateUrl: './service-info.component.html',
  styleUrls: ['./service-info.component.scss'],
})
export class ServiceInfoComponent implements OnInit, OnDestroy {
  config: ClaimAppealDetailConfig;
  serviceInfo$: Observable<ServiceInfo>;
  serviceInformation$: Observable<ServiceInformation>;
  servicingProvider$: Observable<ServicingProvider>;
  claimAppealDetailsLoaded$: Subscription;

  constructor(
    private claimAppealDetailDataService: ClaimAppealDetailDataService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.config = this.configService.getPageConfig('claim-appeal-detail');
    this.serviceInfo$ = this.claimAppealDetailDataService.getService();
    this.claimAppealDetailsLoaded$ =
      this.claimAppealDetailDataService.claimAppealDetailsLoaded$.subscribe(
        (loaded: boolean) => {
          if (loaded) {
            this.serviceInformation$ = this.claimAppealDetailDataService
              .getService()
              .pipe(
                map(
                  (serviceInfo: ServiceInfo) => serviceInfo.serviceInformation
                )
              );
            this.servicingProvider$ = this.claimAppealDetailDataService
              .getService()
              .pipe(
                map((serviceInfo: ServiceInfo) => serviceInfo.servicingProvider)
              );
          }
        }
      );
  }

  ngOnDestroy(): void {
    this.claimAppealDetailsLoaded$?.unsubscribe();
  }
}
