export function toTitleCase(s: string): string {
  return s.replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase());
}

/** Converts provided snakeCase string into titleCase
 * @return converted titleCase string
 */
export function snakeToTitleCase(s: string): string {
  return s
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function toTitleCaseFromSentenceCase(stringValue: string): string {
  /** Converts provided sentenct case string into titleCase
   * @return converted titleCase string
   */
  if (typeof stringValue === 'string') {
    return (
      stringValue.charAt(0).toUpperCase() + stringValue.slice(1).toLowerCase()
    );
  } else {
    return stringValue;
  }
}
