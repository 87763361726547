import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CheckListItem,
  SelectGroupsTaxIdConfig,
} from '../../manage-portal-users-details.model';
import SuspendUserEventService from '../../services/suspend-user-event.service';
@Component({
  selector: 'check-list-card',
  templateUrl: './check-list-card.component.html',
  styleUrls: ['./check-list-card.component.scss'],
})
export class CheckListCardComponent implements OnInit {
  @Input() config: SelectGroupsTaxIdConfig;
  @Input() data: CheckListItem[];
  @Output() selectedItems: EventEmitter<CheckListItem[]> = new EventEmitter();

  constructor(private suspendUserEventService: SuspendUserEventService) {}

  ngOnInit(): void {
    this.emitDynamicButtonConfig();
  }

  handleListSelection(items: CheckListItem[]): void {
    this.selectedItems.emit(items);
  }

  public emitDynamicButtonConfig(): void {
    if (this.config) {
      this.suspendUserEventService.emitDynamicButtonConfig(
        this.config.nextButton
      );
    }
  }
}
