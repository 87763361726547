import { Component, Input } from '@angular/core';

import { TextWithIconModel } from './text-with-icon.model';

@Component({
  selector: 'lib-notes-text-with-icon',
  templateUrl: './notes-text-with-icon.component.html',
  styleUrls: ['./notes-text-with-icon.component.scss'],
})
export class NotesTextWithIconComponent {
  @Input() data: TextWithIconModel;
  @Input() isTextBold = false;
}
