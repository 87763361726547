import { Pipe, PipeTransform } from '@angular/core';

/* Loop through an array and filter for string values */

/* Currently this isn't being used anywhere */

@Pipe({
  name: 'doesListHaveValues',
})
export class DoesListHaveValuesPipe implements PipeTransform {
  transform(value: any): any {
    if (Array.isArray(value)) {
      return (
        value.length > 0 &&
        value.every((item) => item !== '') &&
        value.some((item) => typeof item === 'string')
      );
    }

    return false;
  }
}
