import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getValue } from '@zipari/web-utils';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { VALID_ROLES } from '../../app.constants';

import { ForgotPasswordPayload } from '../modules/authentication/authentication.component';
export class UserRoles {
  id: number;
  category: string;
  group: string;
  active: boolean;
  name: string;
  display_name: string;
}

export class AppUserData {
  accepted_terms?: any;
  agency_id?: string;
  agency_name?: null;
  broker_id?: string;

  /** generated on FE */
  broker_url?: string;

  cohort_keys?: [];
  create_timestamp?: string;
  cx_id?: string | number;
  data?: any;
  email_address?: string;
  email_verified?: boolean;
  first_name?: string;
  group_name?: string;
  groups?: { category: string; id: string | number; name: string }[];
  id?: number;
  identities?: any;
  is_active?: boolean;
  last_login?: string;
  last_name?: string;
  license_effective_date?: string;
  license_number?: string;
  member_id?: string | number;
  permissions?: any;
  phone_number?: string | number;
  prospect_id?: string | number;
  roles?: UserRoles[];
  tenant_name?: string;
  update_timestamp?: string;
  user_name?: string;
}

export class User {
  app_user_data: AppUserData;
  roles?: UserRoles[];
}

@Injectable()
export class AuthService {
  public replayPath: string;
  public authServiceOptions;
  public appConfig: any;
  // public userRole = 'Broker';
  public user: any;
  public actualUser: any;
  public impersonatedUser: any;
  private idp_id: any;
  /*
   * ERHO: TODO: forgotPasswordUrl will change with new endpoint
   */
  private readonly forgotPasswordUrl: string = 'api/user/forgot_password/';
  private readonly resetPasswordUrl: string = '/api/user/reset_password/';
  private readonly changePasswordUrl: string = 'user/change-password/';

  constructor(private http: HttpClient) {}

  public _appUserData = new BehaviorSubject(null);

  public appUserData(): Observable<any> {
    return this._appUserData.asObservable();
  }

  public get loggedInUser(): User {
    return this.impersonatedUser ? this.impersonatedUser : this.actualUser;
  }

  public get userRole() {
    if (
      !this.loggedInUser ||
      (this.loggedInUser &&
        this.loggedInUser.roles &&
        this.loggedInUser.roles.length === 0)
    ) {
      // TODO let's change this to be a constant, not a string
      return 'Anonymous';
    }
    // TODO This has gotten over complicated.. cant this just be the this.user?
    if (
      this.loggedInUser.app_user_data &&
      this.loggedInUser.app_user_data.data &&
      this.loggedInUser.app_user_data.data.active_role
    ) {
      return this.loggedInUser.app_user_data.data.active_role;
    }
    const validRoles = this.loggedInUser?.roles?.filter((role: any) =>
      VALID_ROLES.includes(role?.name)
    );

    if (validRoles.length < 1) {
      console.warn('No Valid Roles for Logged in User');
    }

    return validRoles[0]?.name;
  }

  public get dualRolesFunctionality() {
    if (
      !this.authServiceOptions ||
      Object.keys(this.authServiceOptions).length === 0
    ) {
      return null;
    }

    return !this.authServiceOptions.disable_multi_roles;
  }

  public get roleCount() {
    return this.loggedInUser
      ? this.loggedInUser.roles.filter((role: any) =>
          VALID_ROLES.includes(role.name)
        ).length
      : 0;
  }

  public get isBroker() {
    return !!getValue(this.user, 'broker_id');
  }

  login(payload: { username: string; password: string }): Observable<any> {
    const loginEndpoint: string =
      getValue(this.appConfig, 'login.loginEndpoint') || '/login/';

    return this.getDetails(loginEndpoint, payload);
  }

  public getDetails(
    loginEndpoint: string,
    payload: { username: string; password: string }
  ): Observable<any> {
    return this.http.post<any>(loginEndpoint, payload).pipe(
      tap((response: any) => {
        this._appUserData.next(response);
      })
    );
  }

  logout() {
    const endpoint = 'api/user/logout/';

    return this.http.post<any>(endpoint, {});
  }

  register(payload: any): Observable<any> {
    const endpoint = '/api/user/create-password/';

    return this.http.post(endpoint, payload);
  }

  getUser(dataSource): Promise<any> {
    if (dataSource?.userDataSource === 'init_data') {
      return this.getUserFromInitData();
    } else {
      return this.getUserFromApi();
    }
  }

  getUserFromApi() {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get<any>('api/user/')
        .toPromise()
        .then((data: any) => {
          this._appUserData.next(data);
          resolve(data);
        })
        .catch((data: any) => {
          this.actualUser = null;
          this.user = {};
          if (data.status === 403 || data.status === 401) {
            resolve();

            return;
          }
          reject(data);
        });
    });
  }

  setAppConfig(config) {
    this.appConfig = config;
  }

  setLoggedInUser(data, config?) {
    const global_options = this.appConfig['global'];

    this.authServiceOptions = global_options
      ? global_options['auth_options']
      : {};
    if (!config || (config && config.userDataSource !== 'init_data')) {
      // Anon hacked together need to rework it to make it cleaner
      if (data) {
        this.actualUser = {
          app_user_data: data,
          roles: data.roles,
        };
      }
      this.user = data;
      this.idp_id = data?.idp_id;
    }
  }

  getIdp_id() {
    return this.idp_id;
  }

  getUserFromInitData() {
    const promise = this.http.get<any>('init_data').toPromise();
    const enrollmentUserPromise = this.http.get<any>('/api/user/').toPromise();

    enrollmentUserPromise.then((enrollUserData: any) => {
      this.user = enrollUserData.impersonated_user
        ? enrollUserData.impersonated_user
        : enrollUserData;
    });
    promise.then((data: any) => {
      this._appUserData.next(data);
      this.actualUser = data.USER_INFO;
      this.impersonatedUser = data.USER_INFO.impersonated_user;
    });

    return Promise.all([enrollmentUserPromise, promise]).then();
  }

  setUserRole(role) {
    const data = { data: { active_role: role } };

    return new Promise<void>((resolve, reject) => {
      this.http.put(`api/user/`, data).subscribe(() => {
        this.getUserFromApi().then(() => {
          resolve();
        });
      });
    });
  }

  /**
   * set user data
   * @param data user.data json-blob
   */
  setUserData(data: any) {
    return this.http.put(`api/user/`, { data }).subscribe((userData: any) => {
      this._appUserData.next(userData);
    });
  }

  sendForgotPasswordEmail(payload: ForgotPasswordPayload): Observable<any> {
    return this.http.post<string>(this.forgotPasswordUrl, payload);
    // TODO: want to pass `next` URL in JSON, but backend refusing ATM
    // return this.http.post<string>(this.forgotPasswordUrl, payload);
  }

  resetPassword(payload: any): Observable<any> {
    return this.http.post<string>(this.resetPasswordUrl, payload);
  }

  changePassword(payload: {
    current_password: string;
    password: string;
    confirm_password: string;
  }): Observable<any> {
    const formattedPayload = {
      success_url: window.location.href,
      current_password: payload.current_password,
      new_password: payload.password,
      confirm_new_password: payload.confirm_password,
    };

    return this.http.post<string>(this.changePasswordUrl, formattedPayload);
  }

  updateUserData(data: any): Observable<any> {
    return this.http.put('api/user/', { data }).pipe(
      tap((userData: any) => {
        this.setLoggedInUser(userData);
      })
    );
  }
}
