import { createAction, props } from '@ngrx/store';

import {
  NavigationConfiguration,
  NavigationLink,
  NavigationUnreadCount,
  NavigationUnreadResponse,
  ToolbarConfiguration,
  ToolbarDropdownOption,
} from '@zipari/shared-ds-util-navigation';

export const getNavbarConfiguration = createAction(
  '[Navbar Init] Get Navbar Configuration',
  props<{ config: NavigationConfiguration }>()
);

export const toggleMobileMenu = createAction(
  '[Mobile Nav Hamburger] Toggle Mobile Menu',
  props<{ isOpen: boolean }>()
);

export const toggleNavbarMenu = createAction(
  '[Expandable Nav Link] Toggle Navbar Menu',
  props<{ link: NavigationLink }>()
);

export const setActiveNavLink = createAction(
  '[Nav Link] Set Active Navbar Link',
  props<{ link: NavigationLink }>()
);

export const getToolbarConfiguration = createAction(
  '[Nav Toolbar] Get Toolbar Configuration',
  props<{ payload: { config: ToolbarConfiguration } }>()
);

export const setActiveToolbarOption = createAction(
  '[Nav Toolbar Dropdown Option] Set Active Toolbar Dropdown Option',
  props<{ payload: { option: ToolbarDropdownOption } }>()
);

export const getUnreadCountInit = createAction(
  '[Navbar Icons] Get Unread Count',
  props<{ payload: NavigationUnreadCount }>()
);

export const getUnreadCountSuccess = createAction(
  '[Navbar Icons] Get Unread Count SUCCESS',
  props<{ payload: { response: NavigationUnreadResponse; index: number } }>()
);

export const getUnreadCountError = createAction(
  '[Navbar Icons] Get Unread Count ERROR',
  props<{ error: object }>()
);

export const logout = createAction('[Navbar Logout] Log Out');
