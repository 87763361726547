import { Pipe, PipeTransform } from '@angular/core';
import { formatGridArea } from '../../shared/utils/css-grid';

@Pipe({
  name: 'setGridPosition',
})
export class SetGridPositionPipe implements PipeTransform {
  transform(gridConfig) {
    return formatGridArea(gridConfig);
  }
}
