<ng-container *ngIf="showTooltip && tooltipText">
  <div
    class="u-relative tooltip option__tooltip"
    role="tooltip"
    [attr.aria-label]="tooltipText"
    [attr.data-tooltip]="tooltipText"
  ></div>
</ng-container>
<ul
  *ngIf="options.length > 0"
  class="form-control__menu"
  tabindex="-1"
  role="menu"
>
  <accessible-option
    *ngFor="let option of options"
    (mouseover)="determineTooltip(option)"
    (mouseout)="showTooltip = false"
    [option]="option"
    (selected)="select($event)"
  >
  </accessible-option>
</ul>
