import { Injectable } from '@angular/core';
import { PhoneNumber } from '../../../shared/models/shared/Locations.model';
import Member from '../../../shared/models/shared/Member.model';
import ProductCoverage from '../../../shared/models/shared/ProductCoverage.model';
import { AuthService, FormattingService } from '../../../shared/services';
import {
  ClinicalReviewConfig,
  DiagnosisCode,
  DocumentableDiagnosisRequestType,
  DocumentableProcedureRequestType,
  InpatientRequestedLevelOfCare,
  LengthOfStayCard,
  MCGAuthParameters,
  OutpatientRequestedLevelOfCare,
  PhoneType,
  ProcedureCodingSystem,
  RequestLevelOfCare,
} from './authorization-submission.constants';

@Injectable({
  providedIn: 'root',
})
export class McgClinicalReviewService {
  public lengthOfStay: boolean;
  constructor(
    private formattingService: FormattingService,
    public authService: AuthService
  ) {}
  getMCGAuthData(
    createAuthData: {
      services: any[];
      formValue: any;
      diagnosis: DiagnosisCode[];
    },
    memberCoverageDetails: { member: Member; coverage?: ProductCoverage },
    clinicalReviewConfig: ClinicalReviewConfig
  ) {
    const serviceDetails = this.getServiceDetails(createAuthData.services);
    const serviceProvider = serviceDetails?.servicing_provider;
    const loggedInUser = this.authService.loggedInUser;
    const mcgAuthRequiredData = {
      txtInterfaceLoginUrl: clinicalReviewConfig?.mcgIframeUrl,
      documentingUser: loggedInUser?.app_user_data?.user_name,
      documentingUserFirstname: loggedInUser?.app_user_data?.first_name,
      documentingUserLastname: loggedInUser?.app_user_data?.last_name,
      requestType: MCGAuthParameters.requestType,
      ddlHashFunction: clinicalReviewConfig?.ddlHashFunction,
      patientFirstname: memberCoverageDetails?.member?.name?.first_name,
      patientLastname: memberCoverageDetails?.member?.name?.last_name,
      patientDateOfBirth: this.formatDate(
        memberCoverageDetails?.member?.birth_date
      ),
      patientGender: memberCoverageDetails?.member?.gender || '',
      patientID: memberCoverageDetails?.member?.id || '',
      patientBenefitPlanName:
        memberCoverageDetails?.coverage?.external_plan_name || '',
      facilityName: serviceDetails?.servicing_facility_id?.name,
      facilityNPI: serviceDetails?.servicing_facility_id?.npi,
      facilityID: serviceDetails?.servicing_facility_id?.facility_id || '',
      attendingProviderFirstName: serviceProvider?.name?.first_name,
      attendingProviderLastName: serviceProvider?.name?.last_name,
      attendingProviderNPI: serviceProvider?.npi,
      attendingProviderID: serviceProvider?.id,
      requestingProviderFirstname: createAuthData.formValue?.requesting_provider
        ?.name
        ? createAuthData.formValue?.requesting_provider?.name?.firstName ||
          createAuthData.formValue?.requesting_provider?.name
        : createAuthData.formValue?.requesting_provider_id?.name?.first_name,
      requestingProviderLastname: createAuthData.formValue?.requesting_provider
        ?.name
        ? createAuthData.formValue?.requesting_provider?.name?.lastName ||
          createAuthData.formValue?.requesting_provider?.name
        : createAuthData.formValue?.requesting_provider_id?.name?.last_name,
      requestingProviderID:
        createAuthData.formValue?.requesting_provider_id?.id ||
        createAuthData.formValue?.requesting_provider?.providerId ||
        createAuthData.formValue?.requesting_provider?.facilityId ||
        '',
      requestingProviderNPI:
        createAuthData.formValue?.requesting_provider_id?.npi ||
        createAuthData.formValue?.requesting_provider?.npi ||
        '',
      authorizationCodeList: this.setAuthCodeList(createAuthData),
      authorizationRequestedLevelOfCare: this.getLevelOfCare(
        createAuthData.formValue.authorization_type
      ),
      authorizationRequestType: createAuthData.formValue.authorization_type,
      authorizationUrgent: createAuthData.formValue?.priority || '',
      authorizationRequestedUnits:
        serviceDetails?.length_of_stay_requested_days ||
        serviceDetails?.requested_units ||
        0,
      entryPoint: MCGAuthParameters.entryPoint,
      requestedDateOfService: this.formatDate(
        serviceDetails?.length_of_stay_admit_date
          ? serviceDetails.length_of_stay_admit_date
          : serviceDetails?.effective_date
      ),
      returnURL: window.location.href,
      'MCG-Partner-ID': clinicalReviewConfig.mcgPartnerId,
      'MCG-Solution-ID': clinicalReviewConfig.mcgSolutionId, // This is the name of the solution*
      'MCG-Solution-Version': clinicalReviewConfig.mcgSolutionVersion, // version of the solution
    };

    const optionalParameters = this.getMCGOptionalParameters(
      createAuthData,
      memberCoverageDetails,
      clinicalReviewConfig
    );

    return { ...mcgAuthRequiredData, ...optionalParameters };
  }

  getLevelOfCare(authRequestType: string): string {
    if (InpatientRequestedLevelOfCare.includes(authRequestType)) {
      return RequestLevelOfCare.Inpatient;
    } else if (OutpatientRequestedLevelOfCare.includes(authRequestType)) {
      return RequestLevelOfCare.Outpatient;
    } else {
      return RequestLevelOfCare.Observation;
    }
  }

  formatDate(date: string): string {
    return this.formattingService.restructureValueBasedOnFormat(date, {
      format: 'DATE',
    });
  }

  setAuthCodeList(createAuthData: {
    services: any[];
    formValue: any;
    diagnosis: DiagnosisCode[];
  }): string {
    const authCodeList: string[] = [];
    this.lengthOfStay = false;
    createAuthData.diagnosis.forEach((diagnosis: DiagnosisCode) => {
      authCodeList.push(
        `${diagnosis.code},${diagnosis.type}D,${
          diagnosis.is_primary
        },${this.isDiagnosisCodeDocumentable(
          createAuthData.formValue.authorization_type
        )},${diagnosis.description}`
      );
    });

    createAuthData.services.forEach((service: any) => {
      const procedureCode = service?.procedure_id;
      const procedureCodeType = this.getProcedureCodeType(
        procedureCode?.coding_system
      );
      if (procedureCode) {
        authCodeList.push(
          `${procedureCode.code},${procedureCodeType},${!!this.isPrimaryService(
            service
          )},${this.isProcedureCodeDocumentable(
            createAuthData.formValue.authorization_type
          )}, ${
            service?.length_of_stay_requested_days ||
            service?.requested_units ||
            0
          },${procedureCode.description?.replace(/,/, '\\,')}`
        );
      }
    });

    return authCodeList.join('|');
  }

  isPrimaryService(service: any): boolean {
    if (service?.id === 'length_of_stay') {
      return (this.lengthOfStay = true);
    } else if (!this.lengthOfStay && service?.is_primary) {
      return service.is_primary;
    } else {
      return false;
    }
  }

  removeEscapeCharacter(notes: string): string {
    return notes ? notes.replace(/\n/g, '') : '';
  }

  getServiceDetails(services: any[]): any {
    if (services?.length === 1) {
      return { ...services[0], is_primary: true };
    } else {
      const lengthOfStayDetails = services?.find(
        (service: any) => service.id === LengthOfStayCard
      );

      if (lengthOfStayDetails) {
        return { ...lengthOfStayDetails, is_primary: true };
      } else {
        const primaryServiceDetails = services?.find(
          (service: any) => service.is_primary
        );

        return primaryServiceDetails ? primaryServiceDetails : services[0];
      }
    }
  }

  getMCGOptionalParameters(
    createAuthData: {
      services: any[];
      formValue: any;
      diagnosis: DiagnosisCode[];
    },
    memberCoverageDetails: { member: Member; coverage?: ProductCoverage },
    clinicalReviewConfig: ClinicalReviewConfig
  ): { [key: string]: string | number } {
    const serviceDetails = this.getServiceDetails(createAuthData.services);
    const serviceProvider = serviceDetails?.servicing_provider;
    const serviceProviderSpecialities = serviceProvider?.specialties;
    const loggedInUser = this.authService.loggedInUser;
    const optionalParameters = {
      attendingProviderMI: serviceProvider?.name?.middle_name || '',
      attendingProviderSpecialty1: serviceProviderSpecialities?.length
        ? serviceProvider.specialties[0].name
        : '',
      attendingProviderSpecialty2:
        serviceProviderSpecialities?.length >= 2
          ? serviceProvider.specialties[1].name
          : '',
      attendingProviderSpecialty3:
        serviceProviderSpecialities?.length >= 3
          ? serviceProvider.specialties[2].name
          : '',
      authorizationNote: this.removeEscapeCharacter(
        serviceDetails?.length_of_stay_notes || serviceDetails?.notes
      ),
      documentingUserEmail: loggedInUser?.app_user_data?.email_address || '',
      facilityAddress1:
        serviceDetails?.servicing_facility_id?.address?.street_name_1 || '',
      facilityAddress2:
        serviceDetails?.servicing_facility_id?.address?.street_name_2 || '',
      facilityCity:
        serviceDetails?.servicing_facility_id?.address?.city_name || '',
      facilityBusinessPhone: this.setFacilityBusinessPhone(
        serviceDetails?.servicing_facility_id?.phone_numbers
      ),
      patientBenefitProduct:
        memberCoverageDetails?.coverage?.product_coverage_type || '',
      patientBenefitGroupID: memberCoverageDetails?.coverage?.group_id || '',
      patientBenefitMemberID:
        memberCoverageDetails?.coverage?.member_coverages[0]?.member?.id || '',
      patientBenefitEligibilityDate:
        memberCoverageDetails?.coverage?.effective_date || '',
      patientBenefitDisenrollmentDate:
        memberCoverageDetails?.coverage?.termination_date || '',
      requestingProviderMI:
        createAuthData.formValue?.requesting_provider_id?.name?.middle_name ||
        '',
    };

    const filteredOptionalParameters = Object.keys(optionalParameters)
      .filter(
        (key: string) =>
          clinicalReviewConfig?.prePopulateOptionalParameters[key]
      )
      .reduce(
        (
          filteredParameters: { [key: string]: string | number },
          key: string
        ) => {
          filteredParameters[key] = optionalParameters[key];

          return filteredParameters;
        },
        {}
      );

    return filteredOptionalParameters;
  }

  setFacilityBusinessPhone(phoneNumbers: PhoneNumber[]): string {
    const businessPhoneNumber = phoneNumbers?.find(
      (number: PhoneNumber) => number.type === PhoneType.WORK
    );

    return businessPhoneNumber ? businessPhoneNumber.phone_number : '';
  }

  getProcedureCodeType(codingSystem: string) {
    if (codingSystem === ProcedureCodingSystem.ICD10) {
      const updatedCodeType = codingSystem.replace(/-/g, '');

      return `${updatedCodeType}P`;
    } else {
      return codingSystem;
    }
  }

  isProcedureCodeDocumentable(authRequestType: string): boolean {
    return DocumentableProcedureRequestType.includes(authRequestType);
  }
  isDiagnosisCodeDocumentable(authRequestType: string): boolean {
    return DocumentableDiagnosisRequestType.includes(authRequestType);
  }
}
