<div class="table-prompt">
  <div *ngIf="activatedPrompt && showPrompt" [ngClass]="setCustomClasses()">
    <span class="table-prompt__text">
      <span class="table-prompt__animation">
        <i
          *ngIf="activatedPrompt.type === 'success'"
          class="icon icon--medium"
          >{{ activatedPrompt.icon || 'check_circle_outline' }}</i
        >
        <i *ngIf="activatedPrompt.type === 'error'" class="icon icon--medium">{{
          activatedPrompt.icon || 'error_outline'
        }}</i>
        {{ activatedPrompt.message }}
      </span>
    </span>

    <span class="table-prompt__text close-prompt">
      <a
        *ngIf="activatedPrompt?.action"
        class="icon--medium t-bold action-prompt"
        (click)="triggerPromptAction(activatedPrompt.action)"
        (keydown.enter)="triggerPromptAction(activatedPrompt.action)"
        tabindex="0"
        >{{ activatedPrompt?.action?.label }}</a
      >
      <i
        class="icon icon--medium"
        (click)="closePrompt()"
        (keydown.enter)="closePrompt()"
        tabindex="0"
        >close</i
      >
    </span>
  </div>
</div>
