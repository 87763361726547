import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormGroupModule } from '../../chords/form-group/form-group.module';
import { PortalFooterModule } from '../../chords/portal-footer/portal-footer.module';
import { ButtonModule } from '../../notes/button/button.module';
import { IconModule } from '../../notes/icon/icon.module';
import { MessageBannerModule } from '../../notes/message-banner/message-banner.module';
import { DSAuthComponent } from './auth.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ForgotUsernameComponent } from './components/forgot-username/forgot-username.component';
import { LoginComponent } from './components/login/login.component';
import { OktaComponent } from './components/okta/okta.component';
import { RegistrationComponent } from './components/registration/registration.component';

@NgModule({
  declarations: [
    DSAuthComponent,
    ForgotPasswordComponent,
    ForgotUsernameComponent,
    LoginComponent,
    OktaComponent,
    RegistrationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    FormGroupModule,
    IconModule,
    MessageBannerModule,
    PortalFooterModule,
  ],
  exports: [DSAuthComponent],
})
export class DSAuthModule {}
