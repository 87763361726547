import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProviderPortalModule } from './provider-portal/provider-portal.module';
import { PROVIDER_PORTAL_URLS } from './app-urls.constant';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => ProviderPortalModule,
    data: {
      pageName: PROVIDER_PORTAL_URLS.APP,
      indexRoute: PROVIDER_PORTAL_URLS.BASE,
    },
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
