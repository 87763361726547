import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormControlModule, FormGroupModule } from '@zipari/design-system';
import { HeaderCellComponent } from './header-cell.component';

@NgModule({
  declarations: [HeaderCellComponent],
  exports: [HeaderCellComponent],
  imports: [CommonModule, RouterModule, FormControlModule, FormGroupModule],
})
export class HeaderCellModule {}
