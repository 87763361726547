<div class="financial-overview">
  <div
    class="financial-overview__attrs"
    *ngIf="config?.attributeSection1?.attributes; let financialAttributes"
  >
    <model-attribute
      class="attr"
      *ngFor="let attr of financialAttributes"
      [context]="data"
      [config]="attr"
    ></model-attribute>
  </div>

  <div>
    <header
      class="financial-overview__title__text"
      *ngIf="config?.attributeSection2Title"
    >
      <h6 class="header" [innerHTML]="config?.attributeSection2Title"></h6>
    </header>
  </div>

  <div
    class="financial-overview__attrs"
    *ngIf="config?.attributeSection2?.attributes; let financialAttributes"
  >
    <model-attribute
      class="attr"
      *ngFor="let attr of financialAttributes"
      [context]="data"
      [config]="attr"
    ></model-attribute>
  </div>
</div>
