import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { map } from 'rxjs/operators';
import { FormControlOptionsParams } from '../../shared/models';
import { FormattingService } from '../../shared/services/formatting.service';
import { getValue } from '../../shared/utils/get-value';
import { cloneObject } from '../../shared/utils/object';
import { stringBuilder } from '../../shared/utils/string-builder';
import { AllControlsConfiguration } from '@zipari/shared-ds-util-form';
import {
  FormControlValidatorsService,
  ZipValidator,
} from './shared/validators/validators.service';

export enum defaultPresets {
  CURRENT_DATE_UNFORMATTED = 'CURRENT_DATE_UNFORMATTED',
  CURRENT_DATE = 'CURRENT_DATE',
}

export enum UpdateOnOptions {
  blur = 'blur',
  submit = 'submit',
  change = 'change',
}

export class FormOptions {
  context?: any;
  updateOn?: UpdateOnOptions = UpdateOnOptions.change;

  constructor(options = {}) {
    // this is backwards compatibility due to the fact that context used to be provided here
    if (
      !options.hasOwnProperty('context') &&
      !options.hasOwnProperty('updateOn')
    ) {
      this.context = cloneObject(options);
    } else {
      Object.assign(this, options);
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class FormControlService {
  constructor(
    public formattingService: FormattingService,
    public http: HttpClient,
    private formControlValidatorsService: FormControlValidatorsService
  ) {}

  /**
   * Mark all controls of a form group as touched to trigger validation and show errors
   * Handle nested form froups recursively
   * @param formElement (FormGroup)
   */
  public touchFormGroup(formElement) {
    Object.keys(formElement.controls).forEach((ctrl) => {
      // group
      if (
        formElement.controls.hasOwnProperty(ctrl) &&
        formElement.controls[ctrl] instanceof UntypedFormGroup &&
        formElement.controls[ctrl].controls
      ) {
        // skipping testing because this is just calling recursively
        /* istanbul ignore next */
        this.touchFormGroup(formElement.controls[ctrl]);
      } else if (formElement.controls[ctrl] instanceof UntypedFormControl) {
        formElement.controls[ctrl].markAsTouched();
      }
    });
  }

  // any typing for options is due to backwards compatibility for when the context used to be that option
  addControlToFormGroup(
    formGroup,
    control,
    formOptions: FormOptions | any = new FormOptions({})
  ) {
    control = { ...control };

    const formattedOptions = new FormOptions(formOptions);

    const abstractControl: AbstractControl = formGroup;
    const validators =
      this.formControlValidatorsService.getFormControlValidators(
        control,
        formGroup
      );
    const groupValidators =
      this.formControlValidatorsService.getFormGroupValidators(control);
    const asyncValidators =
      this.formControlValidatorsService.getFormControlAsyncValidators(control);

    switch (control.type) {
      case 'formGroupArray':
        const form = abstractControl as UntypedFormGroup;
        const groupArr = new UntypedFormArray([]);

        if (formattedOptions.context[control.prop]) {
          const contextArr = formattedOptions.context[control.prop];
          contextArr.forEach((item, index) => {
            const itemGroup = new UntypedFormGroup({});

            control.controls.forEach((ctrl) => {
              this.addControlToFormGroup(itemGroup, ctrl, contextArr[index]);
            });
            groupArr.push(itemGroup);
          });
        }

        form.addControl(control.prop, groupArr);
        break;
      case 'formArray':
        const arr = new UntypedFormArray([]);
        control.controls.forEach((ctrl) => {
          this.addControlToFormGroup(arr, ctrl);
        });
        if (abstractControl instanceof UntypedFormGroup) {
          abstractControl.addControl(control.prop, arr);
        }
        if (abstractControl instanceof UntypedFormArray) {
          abstractControl.push(arr);
        }
        break;
      case 'checkbox':
        const newFormControls = control.options.map((_, index) => {
          const optionValue =
            formattedOptions.context[control.prop] &&
            (formattedOptions.context[control.prop][index] || null);
          const newControl: any = new UntypedFormControl(optionValue, {
            updateOn: formattedOptions.updateOn,
          });

          newControl['prop'] = _.value;

          return newControl;
        });

        const newFormArray = new UntypedFormArray(newFormControls, {
          updateOn: formattedOptions.updateOn,
          validators: validators,
        });

        control['reactive'] = newFormArray;

        if (abstractControl instanceof UntypedFormGroup) {
          abstractControl.addControl(control.prop, newFormArray);
        }
        if (abstractControl instanceof UntypedFormArray) {
          abstractControl.push(newFormArray);
        }
        break;
      /* istanbul ignore next */
      case 'basicAddress':
        const basicAddressFormGroup = new UntypedFormGroup({});

        if (abstractControl instanceof UntypedFormGroup) {
          // If configured prop exists, then address controls will be nested under own form group
          if (control.prop) {
            abstractControl.addControl(control.prop, basicAddressFormGroup);
          }
        }
        if (abstractControl instanceof UntypedFormArray) {
          abstractControl.push(basicAddressFormGroup);
        }
        break;
      case 'group':
        let context =
          formOptions?.context && formOptions.context[control.prop]
            ? formOptions?.context[control?.prop]
            : null;
        if (!context) {
          context =
            formOptions && formOptions[control.prop]
              ? formOptions[control?.prop]
              : null;
        }
        const group = this.createNestedFormGroups(control, context);
        formGroup.addControl(control.prop, group);
        break;
      default:
        let value = getValue(formattedOptions.context, control.prop);

        // prioritize null as a value over ''
        // getValue will return '' if the value is null or undefined so we want to make sure that we do this
        // properly
        if (value === '') {
          const contextValue = formattedOptions[control.prop];
          value = contextValue ? contextValue : null;
        }

        // Allow empty string value at least for shallow prop
        /* istanbul ignore next */
        if (
          formattedOptions.context &&
          formattedOptions.context[control.prop] === ''
        ) {
          value = '';
        }

        value = this.handleDefaultValue(control, value, formattedOptions);

        const abstractControlOptions: AbstractControlOptions = {
          updateOn: formattedOptions.updateOn,
          validators,
        };
        if (asyncValidators && asyncValidators.length > 0) {
          abstractControlOptions['asyncValidators'] = asyncValidators;
        }

        const newFormControl = new UntypedFormControl(
          value,
          abstractControlOptions
        );
        control['reactive'] = newFormControl;

        if (abstractControl instanceof UntypedFormGroup) {
          abstractControl.addControl(control.prop, newFormControl);
        }
        if (abstractControl instanceof UntypedFormArray) {
          abstractControl.push(newFormControl);
        }

        if (abstractControl && groupValidators) {
          abstractControl.setValidators(groupValidators);
        }

        // todo: remove this reference
        /* istanbul ignore next */
        if (control.type === 'dropdownOther') {
          const otherControlProp = `${control.prop}_other`;
          if (formattedOptions.context[otherControlProp]) {
            formGroup.addControl(
              otherControlProp,
              new UntypedFormControl(
                formattedOptions.context[otherControlProp],
                {
                  updateOn: formattedOptions.updateOn,
                }
              )
            );
          }
        }
        break;
    }
  }

  /**
   * Used in order to construct nested form groups from
   * nested configs
   */
  createNestedFormGroups(
    controlConfig,
    context?,
    formGroup?: UntypedFormGroup
  ): UntypedFormGroup {
    const nestedFormGroup = !formGroup ? new UntypedFormGroup({}) : formGroup;
    controlConfig.controls.forEach((control) => {
      const cxt = context && context[control.prop] ? context[control.prop] : '';
      if (control.type === 'group') {
        nestedFormGroup.addControl(control.prop, new UntypedFormGroup({}));
        this.createNestedFormGroups(
          control,
          cxt,
          nestedFormGroup.get(control.prop) as UntypedFormGroup
        );
      } else {
        nestedFormGroup.addControl(control.prop, new UntypedFormControl(cxt));
      }
    });

    return nestedFormGroup;
  }
  /** Default values can be set based on keywords maintained in the enum "defaultPresets"
   * EX. We want to default the form control's value to the current date at times so when passing the
   * "default" key into the config like below we will prefill this control to be the current date.
   *
   * {
   *     "default": "CURRENT_DATE",
   *     "label": "Current Date",
   *     "type": "description"
   * }
   *
   * Default values can also be interpolated values if the parent's form-group includes a context object.
   * (If context was provided then it will be in `options.context` below)
   * EX. We want to default a form control on payment step to a previously entered workflow value.
   *
   * <form-group [form]="paymentForm" [configs]="bankControls" [context]="workflow"></form-group>
   *
   * {
   *     "label": "First Name",
   *     "type": "text"
   *     "default": "${values.subscriber.first_name}"
   * }
   *
   * */
  handleDefaultValue(control, value, options) {
    // handle default if applicable
    // use hasOwnProperty so we can also handle setting something to false
    if (value === null && control.hasOwnProperty('default')) {
      if (defaultPresets[control.default]) {
        switch (control.default) {
          case defaultPresets.CURRENT_DATE:
            value = this.formattingService.restructureValueBasedOnFormat(
              new Date().toISOString(),
              {
                format: 'DATE',
                formatOptions: control.formatOptions,
              }
            );
            break;
          case defaultPresets.CURRENT_DATE_UNFORMATTED:
            const currDate = new Date();
            value = `${currDate.getFullYear()}-${currDate.getMonth()}-${currDate.getDate()}`;
            break;
        }
      } else {
        // check for `${a}` format
        const reg = new RegExp(/\$\{(.+)\}/, 'gm');

        if (reg.test(control.default)) {
          value =
            options && options.context
              ? stringBuilder(control.default, options.context)
              : null;
        } else {
          value = control.default;
        }
      }
    }

    return value;
  }

  // any typing for options is due to backwards compatibility for when the context used to be that option
  addFormGroupToFormGroup(
    formGroup,
    control,
    idx = null,
    formOptions: FormOptions | any = new FormOptions({})
  ) {
    control = { ...control };

    const formattedOptions = new FormOptions(formOptions);
    const abstractControl: AbstractControl = formGroup;
    const validators =
      this.formControlValidatorsService.getFormControlValidators(control);

    if (control.type === 'array') {
      const newArr = new UntypedFormArray([], validators);
      control['reactive'] = newArr;

      if (control.controlType === 'group') {
        control.controls.forEach((ctrl) => {
          this.addFormGroupToFormGroup(
            newArr,
            ctrl,
            newArr.controls.length,
            formattedOptions
          );
        });
      }

      if (abstractControl instanceof UntypedFormGroup) {
        (<UntypedFormGroup>abstractControl).addControl(control.prop, newArr);
      }
      if (abstractControl instanceof UntypedFormArray) {
        abstractControl.push(newArr);
      }
    } else {
      const newGroup = new UntypedFormGroup({}, validators);
      if (abstractControl instanceof UntypedFormGroup) {
        (<UntypedFormGroup>abstractControl).addControl(control.prop, newGroup);
      }
      if (abstractControl instanceof UntypedFormArray) {
        abstractControl.push(newGroup);
      }

      // loop through the individual controls and add form controls for each of them to the group we just created
      if (control.controls) {
        control.controls.forEach((innerControl) => {
          if (idx === null) {
            this.addControlToFormGroup(
              formGroup.controls[control.prop],
              innerControl,
              formattedOptions
            );
          } else {
            // skipping testing because it is result of recursive function
            /* istanbul ignore next */
            this.addControlToFormGroup(
              formGroup.controls[idx],
              innerControl,
              formattedOptions
            );
          }
        });
      }
    }
  }

  public getFormControlOptions(
    endpoint,
    template = null,
    params?: FormControlOptionsParams[]
  ) {
    function newHttpParams(inputParams) {
      let httpParams = {};
      inputParams.forEach((param) => {
        const obj = {};
        obj[param.name] = param.value;
        httpParams = Object.assign(httpParams, obj);
      });

      return httpParams;
    }

    const allParams = params ? newHttpParams(params) : {};

    return this.http.get(endpoint, { params: allParams }).pipe(
      map(this.transformResponseToResultList),
      map((res: any) =>
        res.results && Array.isArray(res.results)
          ? res.results.map(
              (result: string | { label: string; value: string }) =>
                this.handleResultFormatting(result, template)
            )
          : []
      )
    );
  }

  /**  if api response is not contained in results, return object with results []. */
  transformResponseToResultList(response): { results: [] } {
    if (response.hasOwnProperty('results')) return response;
    const keys = Object.keys(response);

    return {
      results: response[keys[0]],
    };
  }

  public removeAllControls(
    reactiveElement: UntypedFormGroup | UntypedFormArray,
    ignore: any = {}
  ) {
    if (reactiveElement instanceof UntypedFormGroup) {
      const keys = Object.keys(reactiveElement.controls);

      keys.forEach((key) => {
        if (!ignore[key]) {
          reactiveElement.removeControl(key);
        }
      });
    } else if (reactiveElement instanceof UntypedFormArray) {
      while (reactiveElement.length !== 0) {
        reactiveElement.removeAt(0);
      }
    }
  }

  /** disable all controls that are keyed by 'form' or 'controls' */
  public disableAnyControlsWithinConfig(config: any) {
    if (typeof config !== 'object') {
      return;
    }

    if (Array.isArray(config)) {
      config.forEach((configObj) => {
        this.disableAnyControlsWithinConfig(configObj);
      });
    } else {
      const configKeys = Object.keys(config);

      configKeys.forEach((configKey) => {
        if (configKey === 'form' || configKey === 'controls') {
          let formControlArray = config['form'] || config['controls'];

          formControlArray = formControlArray.map((controlConfig) => {
            controlConfig.isDisabled = true;

            return controlConfig;
          });
        } else {
          // skipping testing because this is just calling recursively
          /* istanbul ignore next */
          this.disableAnyControlsWithinConfig(config[configKey]);
        }
      });
    }
  }

  public parentIsTrueAndHasChild(
    config: any,
    formGroup: UntypedFormGroup,
    prop: string,
    type: string
  ) {
    const childConfig = config[type] ? config[type][prop] : null;
    if (childConfig) {
      const target = childConfig.hasOwnProperty('targetValue')
        ? childConfig.targetValue
        : true;
      const val = formGroup.get(prop) && formGroup.get(prop).value === target;

      return val;
    }

    return false;
  }

  public initializeSubform(
    config: any,
    formGroup: UntypedFormGroup,
    values: any,
    prop: string
  ) {
    const subform = config.subforms[prop];
    subform.controls.forEach((control) => {
      this.addControlToFormGroup(formGroup, control);
      const existingValue = this.getExistingValue(control.prop, values);
      if (existingValue !== null) {
        const formControl = formGroup.get(control.prop);
        formControl.patchValue(existingValue);
      }
    });
  }

  public removeSubform(
    config: any,
    formGroup: UntypedFormGroup,
    values: any,
    prop: string
  ) {
    // ensures we don't validate nonexistent fields
    const existingValue = this.getExistingValue(prop, values);
    const subform = config.subforms[prop];
    subform.controls.forEach((control) => {
      formGroup.removeControl(control.prop);
      if (existingValue !== null) {
        values[control.prop] = null;
      }
    });
  }

  public getExistingValue(prop, values) {
    const questionValues = values;
    if (questionValues) {
      const existingValue = questionValues[prop];
      if (existingValue !== undefined && existingValue !== null) {
        return existingValue;
      }
    }

    return null;
  }

  /* istanbul ignore next */
  retrieveConfigOverride(config: AllControlsConfiguration) {
    let formControlOverride;

    try {
      formControlOverride = JSON.parse(
        localStorage.getItem('formControlOverride')
      );
    } catch (err) {
      return;
    }

    // override for all types
    if (formControlOverride && formControlOverride['all']) {
      Object.assign(config, formControlOverride['all'], config);
    }

    // override for specific type
    if (formControlOverride && formControlOverride[config.type]) {
      Object.assign(config, formControlOverride[config.type], config);
    }

    return config;
  }

  /** handles getting config overrides from local storage */

  // // Functions
  public determineErrorMessageFromErrorObject(control, errors, config) {
    let finalErr = null;

    if (errors && control && control.errors) {
      const potentialErrors: ZipValidator[] = errors.filter(
        (errorObject) => control.errors[errorObject.key]
      );

      if (potentialErrors && potentialErrors.length > 0) {
        for (let i = 0; i < potentialErrors.length; i++) {
          if (potentialErrors[i].key === 'blacklistCharacters') {
            finalErr = potentialErrors[i].message(config, control);
          } else {
            finalErr = potentialErrors[i].message(config);
          }

          if (finalErr) {
            break;
          }
        }
      }
    }

    return finalErr;
  }

  private handleResultFormatting(result, template = null) {
    if (template && typeof template === 'string') {
      return stringBuilder(template, result);
    } else if (template && typeof template === 'object') {
      const formattedResult = {};

      Object.keys(template).forEach((templateKey) => {
        formattedResult[templateKey] = stringBuilder(
          template[templateKey],
          result
        );
      });

      return formattedResult;
    } else if (result.label && result.value) {
      return result;
    }

    return result;
  }
}
