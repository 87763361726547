<div class="u-flex u-alignCenter" *ngIf="!column.isHidden">
  <icon *ngIf="column.icon" [name]="column.icon"> </icon>

  <ng-container [ngSwitch]="column.format">
    <a
      *ngSwitchCase="cellFormats.SUCCESS_ERROR"
      [ngClass]="
        _cellValue === true || _cellValue === column?.successText
          ? 'chip--success'
          : 'chip--error'
      "
      class="t-data chip"
      tabindex="0"
      [innerHTML]="value"
    >
    </a>

    <a
      *ngSwitchCase="cellFormats.LINK"
      class="t-data"
      [ngClass]="{ 't-link': !column?.formatOptions?.noLink }"
      tabindex="0"
      role="link"
      (click)="cellClicked.emit({ context })"
      (keydown.enter)="cellClicked.emit({ context })"
      [attr.aria-label]="
        column.accessibilityMessage ||
        'Click to complete action for ' + _cellValue + 'cell'
      "
      [innerHTML]="value || column.noValueMessage"
    >
    </a>

    <a
      *ngSwitchCase="cellFormats.EMAIL"
      class="t-data"
      [ngClass]="{ 't-link': !column?.formatOptions?.noLink }"
      tabindex="0"
      [href]="'mailto: ' + _cellValue"
      target="_blank"
      [attr.aria-label]="
        column.accessibilityMessage || 'Click to email ' + _cellValue
      "
      [innerHTML]="value"
    >
    </a>

    <a
      *ngSwitchCase="cellFormats.PHONE"
      class="t-data"
      [ngClass]="{ 't-link': !column?.formatOptions?.noLink }"
      tabindex="0"
      [attr.href]="column?.formatOptions?.noLink ? null : 'tel: ' + _cellValue"
      target="_blank"
      [attr.aria-label]="
        column.accessibilityMessage ||
        (column?.formatOptions?.noLink
          ? 'Display of ' + _cellValue + ' value'
          : 'Click to call ' + _cellValue)
      "
      [innerHTML]="value"
    >
    </a>

    <ul *ngSwitchCase="cellFormats.LIST" tabindex="0">
      <li
        *ngFor="let val of _cellValue"
        [attr.aria-label]="
          column.accessibilityMessage || 'Display of ' + val + ' value'
        "
      >
        <p class="t-data">{{ val }}</p>
      </li>
    </ul>

    <div
      *ngSwitchCase="cellFormats.ACTIONS"
      class="table__cell__actions"
      tabindex="0"
    >
      <ng-container *ngFor="let action of column.actions">
        <icon
          *ngIf="ztService.showConditionalActions(action, context.row)"
          [name]="action.icon || action"
          [label]="action.displayText"
          [config]="{ type: 'link', size: 'medium' }"
          (click)="handleCellClicked({ context, action })"
          (keydown.enter)="handleCellClicked({ context, action })"
        >
        </icon>
      </ng-container>
    </div>

    <div
      *ngSwitchCase="cellFormats.FILTERED_ACTIONS"
      class="table__cell__actions"
      tabindex="0"
    >
      <icon
        *ngFor="let action of filteredActions"
        [name]="action"
        [config]="{ type: 'link' }"
        (click)="cellClicked.emit({ context: context, action: action })"
        (keydown.enter)="cellClicked.emit({ context: context, action: action })"
      >
      </icon>
    </div>

    <div
      *ngSwitchCase="cellFormats.RADIO"
      [ngClass]="getCSSClasses(column)"
      tabindex="0"
    >
      <input
        [checked]="radioChecked"
        class="form-control form-control--radio table--radio"
        type="radio"
        (change)="radio($event)"
      />
    </div>

    <div
      *ngSwitchCase="cellFormats.CONDITIONAL_ACTION"
      class="table__cell__actions"
      tabindex="0"
    >
      <ng-container *ngIf="column?.conditionalAction?.icon">
        <icon
          *ngIf="showConditionalValue"
          [name]="column.conditionalAction.icon"
          [label]="column.conditionalAction.displayText"
          [config]="{ type: 'link', size: 'medium' }"
          (click)="cellActionClicked.emit({ context })"
          (keydown.enter)="cellActionClicked.emit({ context })"
        >
        </icon>
      </ng-container>
      <ng-container *ngIf="!column?.conditionalAction?.icon">
        <ng-container
          *ngIf="showConditionalAction; then linkTemplate; else plainText"
        ></ng-container>
      </ng-container>
    </div>

    <status
      *ngSwitchCase="cellFormats.STATUS"
      [config]="{
        label: value['label'],
        value: value['value'],
        statuses: column['statuses']
      }"
      [status]="value['status']"
    ></status>

    <div *ngSwitchDefault>
      <ng-container *ngIf="column?.formatOptions?.link; else plainText">
        <ng-container *ngTemplateOutlet="linkTemplate"></ng-container>
      </ng-container>
    </div>

    <ng-template #plainText>
      <expanding-article
        [config]="expandingArticleConfig"
        [text]="value || column.noValueMessage"
      >
      </expanding-article>
    </ng-template>

    <ng-template #linkTemplate>
      <a
        class="t-data t-link"
        tabindex="0"
        role="link"
        (click)="cellClicked.emit({ context })"
        (keydown.enter)="cellClicked.emit({ context })"
        [attr.aria-label]="
          column.accessibilityMessage ||
          'Click to complete action for ' + _cellValue + 'cell'
        "
        [innerHTML]="value"
      >
      </a>
    </ng-template>
  </ng-container>
</div>
