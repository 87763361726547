import { Injectable } from '@angular/core';
import {
  PracticeInformation,
  ContactInformation,
  Address,
  RequestAccessPayload,
  ReplaceTaxId,
  AssociatedTaxIds,
} from '../model/request-access';

@Injectable({
  providedIn: 'root',
})
export class PayloadService {
  getRequestAccessPayload(
    requestAccessFrom: object,
    event?: AssociatedTaxIds,
    enableReplacementUserWithTaxId?: boolean,
    replaceTaxIdsPayload?: ReplaceTaxId[]
  ): RequestAccessPayload {
    const address = this.getAddress(requestAccessFrom[0]);
    const practiceInformation = this.getPracticeInformation(
      requestAccessFrom[2]
    );
    const contactInformation = this.getContactInformation(requestAccessFrom[1]);
    // mandatory field
    const requestAccess: RequestAccessPayload = {
      first_name: requestAccessFrom[0]?.first_name,
      last_name: requestAccessFrom[0]?.last_name,
      email_address: requestAccessFrom[0]?.email_address,
      phone_number: requestAccessFrom[0]?.phone_number,
      access_request_type: 'tax_ids',
      phone_extension: requestAccessFrom[0]?.phone_extension,
      access_requested_for: event?.tax_ids,
      access_requested: enableReplacementUserWithTaxId
        ? replaceTaxIdsPayload
        : [],
      claim_number: requestAccessFrom[3]?.claim_number
        ? requestAccessFrom[3]?.claim_number
        : '',
      npi: requestAccessFrom[3]?.npi ? requestAccessFrom[3]?.npi : '',
      organization: requestAccessFrom[0]?.organization,
      job_title: requestAccessFrom[0]?.job_title,
      practice_information: practiceInformation,
      contact_information: contactInformation,
      address: address,
    };

    return requestAccess;
  }
  getPracticeInformation(
    practiceForm: PracticeInformation
  ): PracticeInformation {
    // mandatory field
    const practiceInformation: PracticeInformation = {
      practice_name: practiceForm?.practice_name,
      practice_specialty: practiceForm?.practice_specialty,
      department: practiceForm?.department,
      email: practiceForm?.email,
    };

    return this.generatePayload(
      practiceForm,
      practiceInformation
    ) as PracticeInformation;
  }
  getContactInformation(contactForm: ContactInformation): ContactInformation {
    // mandatory field
    const contactInformation: ContactInformation = {
      first_name: contactForm?.first_name,
      last_name: contactForm?.last_name,
      email: contactForm?.email,
    };

    return this.generatePayload(
      contactForm,
      contactInformation
    ) as ContactInformation;
  }
  getAddress(addressForm: Address): Address {
    // mandatory field
    const address: Address = {
      street_name_1: addressForm?.street_name_1,
      street_name_2: addressForm?.street_name_2,
      city_name: addressForm?.city_name,
      zip_code: addressForm?.zip_code,
      state: addressForm?.state,
    };

    return address;
  }

  generatePayload(formValues: object, formObject: object): object {
    if (formValues && formObject) {
      Object.keys(formValues).forEach((key: any) => {
        if (formValues[key] && !formObject[key]) {
          formObject[key] = formValues[key];
        }
      });
    }

    return formObject;
  }
}
