import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationLink } from '@zipari/shared-ds-util-navigation';
import posthog from 'posthog-js';
import { WindowService } from '@zipari/web-services';
import { AuthConfig, GlobalConfig } from '../../../../app.constants';
import { ConfigService } from '../../../services';

import { NotificationModel } from '../../../templates/notifications/notifications.constant';
import { NotificationsService } from '../../../templates/notifications/notifications.service';
import { AuthIdp } from '../../authentication/authentication.constants';
import { NavigationService } from '../navigation.service';
import { MessagesService } from '../../../templates/messages/messages.service';
import { memberId } from '../navigation.constants';
import { configKeys } from '../../../../provider-portal/provider-portal.constant';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Input() config;
  @Input() data;
  @Input() isLoggedIn;

  @Output() dropdownOptionSelected: EventEmitter<any> =
    new EventEmitter<object>();
  @Output() navItemClicked: EventEmitter<any> =
    new EventEmitter<NavigationLink>();
  @Output() navMenuToggled: EventEmitter<any> =
    new EventEmitter<NavigationLink>();

  isDropdownOpen: boolean;
  showNotificationsIndicator: boolean;
  showMessagesIndicator: boolean;
  authConfig: AuthConfig;
  constructor(
    public navigationService: NavigationService,
    public router: Router,
    public notificationService: NotificationsService,
    private messagesService: MessagesService,
    public configService: ConfigService,
    private window: WindowService
  ) {}

  ngOnInit(): void {
    this.authConfig = this.configService.getPageConfig('auth');
    this.assignShowNotificationsIndicator();
    this.assignShowMessagesIndicator();
  }

  handleIconClick(icon): void {
    const timeout = 250;
    const { nativeWindow } = this.window;
    const windowLocation = nativeWindow.location;

    if (icon.route) this.router.navigate([icon.route]);
    //
    // /* This is hack to log user out before assigning new url. It does not log user out if setTimeout is not used */
    if (!!icon.logout && icon.link) {
      setTimeout(() => {
        windowLocation.assign(icon.link);
      }, timeout);
    }

    if (icon.items) icon.isOpen = !icon.isOpen;

    if (icon.extLink) nativeWindow.open(icon.extLink, '_blank');

    if (icon.link) windowLocation.assign(icon.link);

    if (icon.logout) {
      const globalConfig: GlobalConfig =
        this.configService.getPageConfig<GlobalConfig>(configKeys.global);

      if (globalConfig?.analytics?.postHog_capture?.capturePosthogEvents) {
        posthog.reset(true);
      }

      if (localStorage.getItem(memberId)) {
        localStorage.removeItem(memberId);
      }
      const logoutUrl =
        this.authConfig?.idp === AuthIdp && this.authConfig?.auth0.logoutNextUrl
          ? `${icon.logout}?next=${this.authConfig.auth0.logoutNextUrl}`
          : icon.logout;

      windowLocation.assign(logoutUrl);
    }
  }

  assignShowNotificationsIndicator(): void {
    this.notificationService.newNotifications.subscribe(
      (notifications: NotificationModel[]) => {
        const unreadNotifications: any[] = [];

        notifications.forEach((notification: any) => {
          if (!notification.is_read) {
            unreadNotifications.push('unread_notification');
          }
        });
        this.showNotificationsIndicator = unreadNotifications.length > 0;
      }
    );
  }

  assignShowMessagesIndicator(): void {
    this.messagesService.unreadCount$.subscribe((unreadCount: number) => {
      this.showMessagesIndicator = unreadCount > 0;
    });
  }
}
