import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CX_CALLS } from '../../../../../shared/constants/cx-calls.constant';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';
import { getDictionaryAttributesOfSubmitPractitioners } from '../../shared/services/site-locations.helper';
import { SubmitPractitionerEventAttributesConfig } from '../site-locations-attestation.model';

@Injectable({
  providedIn: 'root',
})
export class ReviewPractitionersService {
  constructor(
    public router: Router,
    public analyticsService: AnalyticsService
  ) {}

  public captureSubmitPractitionerEvent(
    submitPractitionerEventDetails: SubmitPractitionerEventAttributesConfig
  ) {
    if (
      submitPractitionerEventDetails?.practitioners &&
      submitPractitionerEventDetails?.user
    ) {
      this.analyticsService.sendEvent(
        CX_CALLS.pp_practitioners_submitted.event_key,
        getDictionaryAttributesOfSubmitPractitioners(
          submitPractitionerEventDetails
        )
      );
    }
    this.router.navigate([
      '/provider-portal/provider-admin/attestation/locations',
    ]);
  }
}
