import { getValue } from './get-value';

export function stringBuilder(template: string, context: any) {
  if (!template || !context) {
    return '';
  }
  let enhancedString = template;
  const templateMatcher = /(\${)([a-zA-Z0-9/._]+)(})/g;
  const matches = template.match(templateMatcher);
  const idx = 2;

  if (matches) {
    matches.forEach((match: string) => {
      const basePropRegex = /(^[a-zA-Z/_]+)(\.)(.+)/g;
      const [value, base, , prop] = match
        .split(templateMatcher)
        [idx].split(basePropRegex);
      const newValue = prop ? getValue(context[base], prop) : context[value];

      enhancedString = enhancedString.replace(
        match,
        newValue === undefined || newValue === null ? '' : newValue
      );
    });
  }

  return enhancedString;
}
