<div
  class="Tabs"
  role="tablist"
  [ngClass]="{
    'Tabs--Shadow': pageTabs,
    'Tabs--Breadcrumbs': showAsBreadcrumbs,
    'Tabs--Center': isCenterAligned,
    'Tabs--Absolute': hasTabPositionChanged,
    'Tabs--WithoutBorder': isBorderNotRequired,
    'Tabs--desktop': isChangeTabWithDevices
  }"
>
  <div
    class="Tab"
    *ngFor="let tabLabel of tabLabels; let i = index"
    [ngClass]="{ 'Tab--Active': i === selectedIndex }"
    role="tab"
    [attr.aria-selected]="i === selectedIndex"
    [attr.aria-controls]="'TabPanel_' + i"
  >
    <div
      class="Tab__Label"
      [ngClass]="{
        'Tab__Label--Centered': isCenterAligned,
        'Tab__Label--Block': isBlockDisplay,
        'Tab__Label--WithoutBorder': isBorderNotRequired
      }"
      (click)="onSelect(i)"
      (keydown.enter)="onSelect(i)"
      role="button"
      [attr.data-test-id]="'TabLabel_' + i"
      #tabLabelElement
    >
      <icon
        *ngIf="tabLabel?.icon"
        class="Tab__Icon"
        [ngClass]="{ Tab__LargeIcon: isLargeIcon }"
        [config]="{ size: isLargeIcon ? 'large' : 'medium' }"
        [name]="tabLabel?.icon"
      >
      </icon>
      <h4
        class="Tab__TitleText"
        *ngIf="tabLabel?.text || (tabLabel && !tabLabel?.icon)"
        [attr.data-test-id]="'TabLabelText_' + i"
        [innerHTML]="tabLabel.text || tabLabel"
      ></h4>
      <p
        class="Tab__TitlePill"
        *ngIf="tabLabel?.pill"
        [attr.data-test-id]="'TabLabelPill_' + i"
      >
        {{ tabLabel.pill }}
      </p>
    </div>
  </div>
</div>

<div
  *ngIf="isChangeTabWithDevices"
  role="tablist"
  class="RadioTabs"
  [ngClass]="{
    'RadioTabs--mobile': isChangeTabWithDevices
  }"
>
  <div
    class="RadioTab"
    *ngFor="let tabLabel of tabLabels; let i = index"
    [ngClass]="{ 'RadioTab--Active': i === selectedIndex }"
    role="tab"
    [attr.aria-selected]="i === selectedIndex"
    [attr.aria-controls]="'TabPanel_' + i"
  >
    <label
      class="RadioTab__Label"
      role="button"
      (click)="onSelect(i)"
      (keydown.enter)="onSelect(i)"
      [attr.data-test-id]="'RadioTabLabel_' + i"
      [attr.tabindex]="i === selectedIndex ? 0 : -1"
    >
      <input
        class="form-control form-control--radio"
        type="radio"
        name="radioTab"
        [attr.checked]="i === selectedIndex ? true : null"
      />
      <p
        class="RadioTab__TitleText"
        [innerHTML]="tabLabel.text || tabLabel"
      ></p>
    </label>
  </div>
</div>

<div
  class="Tab__Content"
  data-test-id="TabConent"
  role="tabpanel"
  tabindex="0"
  [attr.aria-labelledby]="'TabPanel_' + selectedIndex"
>
  <ng-content select="[aboveContent]"></ng-content>

  <ng-container *ngIf="tabTemplates.length">
    <ng-container
      *ngTemplateOutlet="tabTemplates[selectedIndex]"
    ></ng-container>
  </ng-container>

  <ng-container *ngIf="!tabTemplates.length">
    <ng-container
      [ngTemplateOutlet]="projectedTemplatesList[selectedIndex]?.template"
    ></ng-container>
  </ng-container>

  <ng-content select="[belowContent]"></ng-content>
</div>
