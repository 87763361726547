export const coverSpouseConfig = {
  label: 'Does the subscriber want to cover a spouse / partner',
  prop: 'spouse_covered',
  type: 'toggle',
  options: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
  validators: ['required'],
};

export const coverDepConfig = {
  label: 'Does the subscriber want to cover any dependents',
  prop: 'deps_covered',
  type: 'toggle',
  options: [
    {
      label: 'Yes',
      value: true,
    },
    {
      label: 'No',
      value: false,
    },
  ],
  validators: ['required'],
};

export const subscriberConfig = {
  text: {
    subscriber: 'Subscriber',
  },
  controls: [
    {
      type: 'date',
      label: 'Date of Birth',
      max: '9999-01-01',
      prop: 'date_of_birth',
      placeholder: 'YYYY-MM-DD',
      validators: [
        'required',
        'reasonableDate',
        'dateInPast',
        {
          name: 'maxAge',
          dependency: {
            age: 65,
            dateProp: 'parent.parent.parent.value.coverage_effective_date',
          },
        },
        {
          name: 'minAge',
          dependency: {
            age: 18,
            dateProp: 'parent.parent.parent.value.coverage_effective_date',
          },
        },
      ],
    },
    {
      type: 'toggle',
      label: 'Tobacco User',
      prop: 'tobacco_user',
      description:
        'Did you use tobacco four or more times per week on average for at least the last six months?',
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      validators: ['required'],
    },
  ],
};

export const spouseConfig = {
  text: {
    spouse: 'Spouse',
  },
  controls: [
    {
      type: 'date',
      label: 'Date of Birth',
      max: '9999-01-01',
      prop: 'date_of_birth',
      placeholder: 'YYYY-MM-DD',
      validators: [
        'required',
        'reasonableDate',
        'dateInPast',
        {
          name: 'maxAge',
          dependency: {
            age: 65,
            dateProp: 'parent.parent.parent.value.coverage_effective_date',
          },
        },
        {
          name: 'minAge',
          dependency: {
            age: 18,
            dateProp: 'parent.parent.parent.value.coverage_effective_date',
          },
        },
      ],
    },
    {
      type: 'toggle',
      label: 'Tobacco User',
      prop: 'tobacco_user',
      description:
        'Did you use tobacco four or more times per week on average for at least the last six months?',
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      validators: ['required'],
    },
  ],
};

export const depConfig = {
  text: {
    child: 'Child',
  },
  controls: [
    {
      label: 'Date of Birth',
      placeholder: 'YYYY-MM-DD',
      prop: 'date_of_birth',
      validators: [
        'required',
        'dateInPast',
        'reasonableDate',
        {
          name: 'maxAgeAndPropCheck',
          dependency: {
            dateProp:
              'parent.parent.parent.parent.controls.getStarted.controls.coverage_effective_date',
            otherPropValue: true,
            age: 26,
            otherProp: 'controls.is_disabled',
          },
        },
      ],
      type: 'date',
      errors: {
        maxAgeAndPropCheck:
          'Child dependent must be under the age 26 with the exception of having a disability.',
        required: 'Please enter a valid date of birth',
        dateInPast: 'Please enter a valid date of birth',
        reasonableDate: 'Child dependent must be under the age 26',
      },
    },
    {
      type: 'toggle',
      label: 'Tobacco User',
      prop: 'tobacco_user',
      description:
        'Did you use tobacco four or more times per week on average for at least the last six months?',
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      validators: ['required'],
    },
    {
      type: 'toggle',
      label: 'Disabled User',
      prop: 'is_disabled',
      description: 'Is the child disabled?',
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      validators: [
        'required',
        {
          name: 'updateControl',
          dependency: 'controls.date_of_birth',
        },
      ],
    },
  ],
};

export const childOnlyConfig = {
  text: {
    child: 'Child Only',
  },
  controls: [
    {
      type: 'date',
      prop: 'date_of_birth',
      label: 'Date of Birth',
      placeholder: 'YYYY-MM-DD',
      errors: {
        reasonableDate: 'Please enter a valid date of birth',
        required: 'Please enter a valid date of birth',
        maxAge: 'Child must be less than 18 years old for child only coverage.',
        dateInPast: 'Please enter a valid date of birth',
      },
      validators: [
        'required',
        'dateInPast',
        'reasonableDate',
        {
          name: 'maxAge',
          dependency: {
            dateProp:
              'parent.parent.parent.parent.controls.coverage_effective_date.value',
            age: 18,
          },
        },
      ],
    },
    {
      label: 'Tobacco User?',
      description:
        'Did you use tobacco four or more times per week on average for at least the last six months?',
      validators: ['required'],
      prop: 'tobacco_user',
      type: 'toggle',
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
    {
      label: 'Does the dependent have a disability?',
      prop: 'is_disabled',
      description:
        "A limit in a range of major life activities. This includes activities like seeing, hearing, walking and tasks like thinking and working. Please go <a class='t-data t-link' target='_blank' href='https://www.healthcare.gov/glossary/disability/'>here</a> to learn more.",
      validators: [
        'required',
        {
          name: 'updateControl',
          dependency: 'controls.date_of_birth',
        },
      ],
      type: 'toggle',
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    },
  ],
  validators: ['required'],
};

export const individualsCoveredConfig = {
  prop: 'whose_covered',
  type: 'dropdown',
  label: 'Please add all individuals who will be covered under this policy',
  options: [
    {
      label: 'Subscriber',
      value: 'subscriber',
    },
    {
      label: 'Subscriber + Spouse',
      value: 'spouse',
    },
    {
      label: 'Subscriber + Child(ren)',
      value: 'parent',
    },
    {
      label: 'Subscriber + Family',
      value: 'family',
    },
    {
      label: 'Child Only',
      value: 'child_only',
    },
  ],
  validators: ['required'],
};

export const buttonConfig = {
  icon: 'add',
  level: 'text',
  content: 'Add Another Dependent',
};
