<div
  class="form-control__wrapper"
  [ngClass]="{ 'form-control__wrapper--flat': direction === 'filter' }"
>
  <label class="form-control__label">
    <p class="t-bold">{{ config.label }}</p>
  </label>

  <div class="form-control" (click)="menuOpen = !menuOpen">
    <div class="u-flex u-alignCenter u-maxXY">
      <ng-container *ngIf="selectedColor; else placeholder">
        <div
          class="swatch"
          [ngClass]="{
            'swatch--transparent':
              selectedColor.label === brandColorLabels.TRANSPARENT
          }"
          [ngStyle]="{ 'background-color': selectedColor.value }"
        ></div>

        <p>{{ selectedColor.label }}</p>

        <div
          class="u-flex u-justifyEnd u-fillRemaining"
          *ngIf="selectedColor.label !== brandColorLabels.TRANSPARENT"
        >
          <code class="t-caps" [ngSwitch]="selectedColor.label">
            <span *ngSwitchCase="brandColorLabels.WHITE">#ffffff</span>
            <span *ngSwitchCase="brandColorLabels.BLACK">#000000</span>
            <span *ngSwitchDefault>{{ getHex(selectedColor.var) }}</span>
          </code>
        </div>
      </ng-container>

      <ng-template #placeholder>
        <p>Select a brand color</p>
      </ng-template>
    </div>
  </div>

  <div class="form-control__menu" *ngIf="menuOpen">
    <ng-container *ngFor="let set of palette">
      <div class="form-control__menu__header">
        <p class="t-alt t-caption">{{ set.label }}</p>
      </div>

      <div
        class="form-control__menu__option"
        [ngClass]="{ 'is-active': color === selectedColor }"
        *ngFor="let color of set.colors; let i = index"
        (click)="selectColor(color)"
      >
        <div class="u-flex u-alignCenter u-maxX">
          <div
            class="swatch"
            [ngClass]="{
              'swatch--transparent':
                color.label === brandColorLabels.TRANSPARENT
            }"
            [ngStyle]="{ 'background-color': color.value }"
          ></div>

          <p>{{ color.label }}</p>

          <div
            class="u-flex u-justifyEnd u-fillRemaining"
            *ngIf="color.label !== brandColorLabels.TRANSPARENT"
          >
            <code class="t-caps" [ngSwitch]="color.label">
              <span *ngSwitchCase="brandColorLabels.WHITE">#ffffff</span>
              <span *ngSwitchCase="brandColorLabels.BLACK">#000000</span>
              <span *ngSwitchDefault>{{ getHex(color.var) }}</span>
            </code>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="modal__mask" *ngIf="menuOpen" (click)="menuOpen = false"></div>
