import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MaskDirective } from '../../../shared/directives/mask.directive';
import { IconModule } from '../../icon/icon.module';
import { ControlTextComponent } from './text.component';
import { DatePickerModule } from '../date-picker/date-picker.module';

@NgModule({
  declarations: [ControlTextComponent, MaskDirective],
  imports: [
    CommonModule,
    FormsModule,
    IconModule,
    ReactiveFormsModule,
    DatePickerModule,
  ],
  providers: [DeviceDetectorService],
  exports: [ControlTextComponent, MaskDirective],
})
export class ControlTextModule {}
