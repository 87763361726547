<div
  *ngIf="!config.hideActions"
  [ngClass]="{
    FormGroupArrayAccordionContent__AccordionAction:
      config?.showFormWithAccordion
  }"
>
  <icon
    *ngIf="
      (formArray?.length > 1 && !(index === 0 && config.protectMain)) ||
      config.allowEmpty
    "
    [name]="config.actionIcon || 'close'"
    [config]="{ type: 'pointer', fill: 'none' }"
    (click)="removeGroup(index)"
    tabindex="0"
    (keydown.enter)="removeGroup(index)"
  >
  </icon>
</div>
