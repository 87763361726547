import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
} from '@angular/core';
import linkifyStr from 'linkifyjs/string';
import { Subscription } from 'rxjs';
import { APIService } from '../../../../../shared/services';
import {
  AddressLocationConfig,
  OutOfNetworkConfig,
} from '../../../authorization-submission/authorization-submission.model';
import { ProviderUpdateToolService } from '../../../provider-update-tool/provider-update-tool.service';
import { BillingFlyoutType } from '../../claim-submission.constant';
@Component({
  selector: 'flyout-popup',
  templateUrl: './flyout-popup.component.html',
  styleUrls: ['./flyout-popup.component.scss'],
})
export class FlyoutPopupComponent implements AfterViewInit {
  @Input() config: any;
  @Input() group: any;
  @Input() state: string;
  @Input() control: any = {};
  @Input() outOfNetwork: OutOfNetworkConfig;

  @Output() getFlyoutValue: EventEmitter<any> = new EventEmitter<any>();

  showModal: boolean = false;
  selectedItem: any = null;
  busy: Subscription;
  loaded: boolean = false;
  noSpace: string = 'n/a';
  showProviderBillingPopup: boolean = false;
  getButtonValue: string;
  selectedAddress: any = null;
  addressLocation: AddressLocationConfig[] = [];
  showMultiLocationPopup: boolean = false;
  locationOptions = [];
  showOutOfNetworkModal: boolean;
  constructor(public mp: ProviderUpdateToolService, private api: APIService) {}

  ngAfterViewInit() {
    // On selecting address from multiple addresses
    this.group
      ?.get('multi_location')
      ?.valueChanges.subscribe((selectAdd: any) => {
        this.selectedAddress = selectAdd;
      });
  }

  populateDropdown(resp: any, labelProp: string, valueProp: string) {
    const options = [];
    resp.results.forEach(function (record, index) {
      if (valueProp) {
        options.push({
          label: record[labelProp],
          value: record[valueProp],
        });
      } else {
        options.push({
          label: record[labelProp],
          value: record,
        });
      }
    });

    return options;
  }

  toggleFlyout() {
    this.showModal = !this.showModal;
    this.selectedItem = null;
    this.selectedAddress = null;
  }

  remove() {
    this.group.get(this.config.prop).reset();
    this.group.get('multi_location').reset();
  }

  selectedRadioRow(event: any) {
    this.selectedItem = event;
  }

  addSelectedProvider(): void {
    if (this.selectedItem) {
      if (this.getButtonValue === BillingFlyoutType.billingProvider) {
        this.addBillingProvider();
      } else if (this.getButtonValue === BillingFlyoutType.billingFacility) {
        const shouldShowOutOfNetworkModal =
          this.outOfNetwork?.isOutOfNetworkEnabled &&
          this.selectedItem?.member_network?.is_in_network === false;
        if (shouldShowOutOfNetworkModal) {
          this.showOutOfNetworkModal = true;
        } else {
          this.group.get(this.config.prop).setValue(this.selectedItem);
          this.toggleFlyout();
        }
      }
    }
  }

  addBillingProvider(): void {
    if (
      this.selectedItem &&
      this.selectedItem?.provider_locations &&
      this.selectedItem.provider_locations.length > 0
    ) {
      const facilityLocation =
        this.selectedItem.provider_locations[0].facility_location?.facility
          ?.facility_locations;
      const isMultiLocation = facilityLocation?.filter(
        (locations: any) => locations.address
      ).length;

      // open popup to show multiple address
      if (isMultiLocation > 1) {
        this.group.get(this.config.prop).setValue(this.selectedItem);
        this.toggleMultiLocationPopup();
        this.locationOptions = [];
        this.addressLocation = this.group
          .get(this.config.prop)
          ?.value[
            this.config[this.getButtonValue].displayFields.address
          ][0].facility_location.facility.facility_locations.filter(
            (locations: any) => locations.address
          );
        this.updateLocationOptions();
      } else {
        this.addressLocation.push(facilityLocation[0]);
        this.group
          .get('multi_location')
          .patchValue(facilityLocation[0]?.address?.id);
        this.handleOutOfNetworkProvider();
      }
    } else {
      this.handleOutOfNetworkProvider();
    }
  }

  updateLocationOptions() {
    this.selectedItem.provider_locations[0].facility_location.facility.facility_locations
      .filter((locations: any) => locations.address)
      .map((locations: any) => {
        this.locationOptions.push({
          // eslint-disable-next-line max-len
          label: `${locations.address.street_name_1} ${locations.address.street_name_2} ${locations.address.city_name} ${locations.address.state} ${locations.address.zip_code}`,
          value: locations.address.id,
        });
      });
  }

  SelectAddress() {
    this.showMultiLocationPopup = !this.showMultiLocationPopup;
    this.handleOutOfNetworkProvider();
  }

  toggleProviderBillingPopup() {
    this.showProviderBillingPopup = !this.showProviderBillingPopup;
  }

  toggleMultiLocationPopup() {
    this.showMultiLocationPopup = !this.showMultiLocationPopup;
  }

  toggleProviderFlyout(id) {
    this.getButtonValue = id;
    this.toggleProviderBillingPopup();
    this.toggleFlyout();
    this.getFlyoutContent(id);
    this.getFlyoutValue.emit(this.getButtonValue);
  }

  // Get flyout content based on button(Billing provider/Location) clicked on popup model
  getFlyoutContent(flyout) {
    if (this.config?.subheader) {
      const options = {
        /* … */
      };
      this.config.subheader = linkifyStr(this.config.subheader, options);
    }
    if (this.control?.noSpace) this.noSpace = this.control?.noSpace;
    if (this.config[flyout]?.table?.filters) {
      const filters: any[] = this.config[flyout]['table']['filters'];
      let hasNoEndpoints: boolean = true;
      for (const filter of filters) {
        if (filter.type === 'select' && filter.apiEndpoint) {
          hasNoEndpoints = false;
          this.busy = this.api
            .get(filter.apiEndpoint)
            .subscribe((resp: any) => {
              filter.options = this.populateDropdown(
                resp,
                filter.displayProp,
                filter.jsonValue
              );
              this.loaded = true;
            });
        }
      }
      if (hasNoEndpoints) this.loaded = true;
    }
    const tableConfig = this.config[flyout]?.table;
    const shouldUpdateEndpoint =
      tableConfig &&
      this.outOfNetwork?.isOutOfNetworkEnabled &&
      tableConfig.outOfNetworkApiEndpoint;
    if (shouldUpdateEndpoint) {
      this.config[flyout].table = {
        ...this.config[flyout].table,
        endpoint: this.config[flyout].table.outOfNetworkApiEndpoint,
      };
    }
  }

  confirm(): void {
    this.group?.get(this.config.prop).setValue(this.selectedItem);
    this.showModal = false;
    this.showOutOfNetworkModal = false;
    this.selectedItem = null;
  }

  closeOutOfNetworkModal(): void {
    this.showOutOfNetworkModal = false;
    this.group?.get(this.config.prop).setValue(null);
    this.group?.get('multi_location').setValue(null);
    this.selectedAddress = null;
  }

  get showOutOfNetworkStatusPill(): boolean {
    const isInNetworkValue = this.group?.get(this.config?.prop).value
      ?.member_network?.is_in_network;

    return (
      this.outOfNetwork?.isOutOfNetworkEnabled && isInNetworkValue == false
    );
  }

  handleOutOfNetworkProvider(): void {
    const shouldShowOutOfNetworkModal =
      this.outOfNetwork?.isOutOfNetworkEnabled &&
      this.selectedItem?.member_network?.is_in_network === false;
    if (shouldShowOutOfNetworkModal) {
      this.showOutOfNetworkModal = true;
    } else {
      this.group.get(this.config.prop).setValue(this.selectedItem);
      this.showModal = false;
    }
  }
}
