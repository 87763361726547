export const ATTESTATION_PAGES = {
  siteLocations: 'site-locations',
  reviewSiteLocation: 'review-site-location',
  reviewPractitioner: 'review-practitioner',
  confirmation: 'confirmation',
};

export const SITELOCATION_CONSTANTS = {
  cardDynamicHeader: 'Rows Selected',
  defaultErrorMessage: 'Something went wrong',
  checkboxActiveIcon: 'checkbox_active',
  checkboxAllIcon: 'checkbox_all',
  checkboxIcon: 'checkbox',
  specialtiesProp: 'specialties',
  specialtyMappingKey: 'name',
  pageName: 'pageName',
  siteLocationsAttestation: 'site-locations-attestation',
  invalidDate: 'Invalid date',
  invalidName: 'Invalid Name',
  default: 'default',
  start: 'start',
  end: 'end',
  basicAddress: 'basicAddress',
  state: 'state',
  editSiteDetails: 'editSiteDetails',
  locationContacts: 'location_contacts',
  phoneNumbers: 'phone_numbers',
  administrationAttributes: 'administrationAttributes',
  contact: 'contact',
  administrationAttributesKey: 'administration_attributes',
  id: 'id',
  addresses: 'addresses',
  fax: 'fax',
  title: 'title',
  name: 'name',
  fullName: 'full_name',
  prefix: 'prefix',
  emailAddress: 'email_address',
  locationContact: 'locationContact',
  changeRequestEffectiveDate: 'change_request_effective_date',
  changeRequestNotes: 'change_request_notes',
  facilityList: 'facility_list',
  affiliatedHospitalFacilityIds: 'affiliated_hospital_facility_ids',
  deactivationDate: 'deactivation_date',
  day: 'day',
  closingTime: 'closing_time',
  openingTime: 'opening_time',
  hourTwelve: 'hh:mm a',
  hourTwentyFour: 'HH:mm',
  siteLocationsAlert: 'siteLocationsAlert',
  culturalCompetencyTrainingCompletionDate:
    'culturalCompetencyTrainingCompletionDate',
  formGroupArrayControl: 'formGroupArray',
  editPractitionerForm: 'editPractitionerForm',
  identifiers: 'identifiers',
  identifier: 'identifier',
  type: 'type',
  message: 'message',
};

export const Days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const PAGE_NAME = 'pageName';

export const SITE_LOCATIONS_ATTESTATION = 'site-locations-attestation';

export const INVALID_DATE = 'Invalid date';

export const INVALID_NAME = 'Invalid Name';

export enum BUTTON_TYPE_NAME {
  APPROVAL = 'APPROVAL',
  CONFIRM = 'CONFIRM',
  PRACTITIONER = 'PRACTITIONER',
  SUBMIT = 'SUBMIT',
}

export const BUTTON_TYPE = 'buttonType';
export const AdministrationAttributes = {
  acceptingNewPatients: 'accepting_new_patients',
  culturalCompetencyTrainingCompletionDate:
    'cultural_competency_training_completion_date',
  displayInDirectory: 'display_in_directory',
  hasMobilityImpairedAccessibleEquipment:
    'has_mobility_impaired_accessible_equipment',
  hasMobilityImpairedAccessibleExamRoom:
    'has_mobility_impaired_accessible_exam_room',
  isCulturalCompetencyTrainingComplete:
    'is_cultural_competency_training_complete',
  isMobilityImpairedAccessible: 'is_mobility_impaired_accessible',
  isPrimaryClinic: 'is_primary_clinic',
  isVisitingClinic: 'is_visiting_clinic',
};

export const SITE_LOCATION_PROFILE_FORMAT_OPTIONS = [
  {
    prop: 'specialties',
    key: 'name',
  },
  {
    prop: 'restrictions',
    key: 'code',
  },
  {
    prop: 'languages',
    key: 'name',
  },
  {
    prop: 'services',
    key: 'name',
  },
];

export const SITE_LOCATION_ALERT_ROUTES_CONSTANT = {
  internalRoute: {
    route: '/provider-portal/provider-admin/attestation/locations',
    target: '_self',
  },
  externalRoute: {
    target: '_blank',
  },
  route: '${route}',
  target: '${target}',
};

export const PROVIDER_IDENTIFIERS = {
  payerAssignedIdentifier: 'payer_assigned_identifier',
};
