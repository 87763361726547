<div
  class="tabs_pp"
  [ngClass]="{
    'tabs--shadow': !!pageTabs,
    'tabs__bottom-border': !noTabBorder
  }"
>
  <div
    class="tab"
    *ngFor="let tabLabel of tabLabels; let i = index"
    [ngClass]="{ 'is-active': i === selectedIndex }"
  >
    <h6
      tabindex="0"
      class="tab__title"
      (click)="onSelect(i)"
      (keydown.enter)="onSelect(i)"
    >
      <span class="tab__title-text">{{ tabLabel.text || tabLabel }}</span>
      <span class="tab__title-pill" *ngIf="tabLabel.pill">{{
        tabLabel.pill
      }}</span>
    </h6>
  </div>
</div>

<div class="tab__content">
  <ng-content select="[aboveContent]"></ng-content>

  <ng-container *ngIf="tabTemplates.length">
    <ng-container
      *ngTemplateOutlet="tabTemplates[selectedIndex]"
    ></ng-container>
  </ng-container>

  <ng-container *ngIf="!tabTemplates.length">
    <ng-container
      [ngTemplateOutlet]="projectedTemplatesList[selectedIndex]?.template"
    ></ng-container>
  </ng-container>

  <ng-content select="[belowContent]"></ng-content>
</div>
