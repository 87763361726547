import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  AccordionModule,
  ButtonModule,
  FormControlModule,
  FormGroupModule,
  ModalModule,
  ModelAttributeModule,
  PaginatorModule,
  TableModule,
  TabsModule,
} from '@zipari/design-system';
import { ZipBusyModule } from '../../../../../shared/modules/zip-busy/zip-busy.module';
import { PregnancyFormListComponent } from './pregnancy-form-list.component';

@NgModule({
  declarations: [PregnancyFormListComponent],
  exports: [PregnancyFormListComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormControlModule,
    FormGroupModule,
    ButtonModule,
    TableModule,
    ZipBusyModule,
    ModalModule,
    ModelAttributeModule,
    ZipBusyModule,
    AccordionModule,
    ReactiveFormsModule,
    TabsModule,
    PaginatorModule,
  ],
})
export class PregnancyFormListModule {}
