<div class="Container">
  <div *ngIf="alerts$ | async as alerts; else loading">
    <ng-container *ngFor="let alert of alerts">
      <message-banner
        [config]="alert"
        (actionClicked)="closeMessageBanner()"
      ></message-banner>
    </ng-container>
  </div>
  <ng-template #loading>
    <spinner
      *ngIf="isLoading"
      [asOverlay]="false"
      flexCenter="true"
      size="small"
    ></spinner>
  </ng-template>
</div>
