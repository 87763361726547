export const AdditionalDetailsModalMockConfig = {
  modal: {
    type: 'popup',
    clickOutside: false,
    backdrop: true,
    header: {
      title: 'Updates Have Been Made',
    },
    description:
      'Please enter the effective date of the changes and any note associated with the change.',
  },
  footer: {
    submitButton: { content: 'Submit', level: 'high' },
    cancelButton: { content: 'Cancel', level: 'text' },
  },
  additionalDetailsForm: {
    controls: [
      {
        label: 'Effective Date',
        type: 'date',
        validators: ['required'],
        prop: 'effective_date',
      },
      {
        label: 'Notes',
        type: 'textarea',
        prop: 'notes',
        resize: true,
      },
    ],
  },

  enableEffectiveDate: false,
  enableNotes: true,
  enableChangeInOwnership: false,
};

export const UpdatedDetailsModalMockConfig = {
  ...AdditionalDetailsModalMockConfig,
  enableEffectiveDate: true,
  enableNotes: true,
};

export const AdditionalDetailsFormControlProps = {
  effectiveDate: 'effective_date',
  notes: 'notes',
};

export const SiteLocationDetailsContextKeys = {
  changeRequestNotes: 'change_request_notes',
  changeRequestEffectiveDate: 'change_request_effective_date',
  facilityType: 'facility_type',
};
