export function getBearerToken() {
  return localStorage.getItem('token');
}

export function setBearerToken(token: string) {
  localStorage.setItem('token', token);
}

export function clearBearerToken() {
  localStorage.removeItem('token');
}
