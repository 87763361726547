<section class="ap__attachments">
  <div class="ap__attachment" *ngFor="let attachment of attachments">
    <a
      class="u-flex"
      (click)="openAttachment(attachment.id, $event)"
      [title]="attachment.file_name"
    >
      <i class="icon icon--medium icon--link">claims</i>
      <p class="ap__attachment-title">{{ attachment.file_name | fileTitle }}</p>
      <p *ngIf="attachment.file_size">
        ({{ attachment.file_size | fileSize }})
      </p>
    </a>
    <i
      *ngIf="showCloseIcon"
      class="icon icon--medium"
      (click)="removeAttachment.emit(attachment)"
      >close</i
    >
  </div>
</section>
