import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Option } from '@zipari/design-system';
import {
  AssociatedTaxIds,
  RequestAccessPayload,
} from '../model/request-access';
import {
  REQUEST_ACCESS_PAYLOAD,
  verifyTaxIdsEndpoint,
} from '../../../constants/app-urls.constants';

@Injectable({
  providedIn: 'root',
})
export class RequestAccessDataService {
  constructor(private httpClient: HttpClient) {}

  sendRequestAccess(
    requestAccessPayload: RequestAccessPayload
  ): Observable<any> {
    return this.httpClient.post(
      REQUEST_ACCESS_PAYLOAD.REQUEST_PAYLOAD,
      requestAccessPayload
    );
  }

  verifyTaxIds(taxIds: string[]): Observable<AssociatedTaxIds> {
    const payload = { tax_ids: taxIds };

    return this.httpClient.post(verifyTaxIdsEndpoint, payload).pipe(
      tap((associatedTaxIds: AssociatedTaxIds) => {
        associatedTaxIds.updatedTaxIds = this.updateVerifyTaxIdsResponse(
          associatedTaxIds.tax_ids
        );
      })
    );
  }

  updateVerifyTaxIdsResponse(taxIds: string[]): Option[] {
    return taxIds?.map((taxId: string) => ({
      value: taxId,
      label: taxId,
    }));
  }
}
