import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';

import { BadgeConfig, LinkListItem } from '@zipari/design-system';
import { Subject, mergeMap, of, takeUntil } from 'rxjs';

import { ConfigService, FormattingService } from '../../../shared/services';
import {
  ClaimAppealDetailConfig,
  ClaimAppealDetailTabsConfig,
} from '../../../shared/models/shared/ClaimAppealDetail.model';
import { GenericServices } from '../../shared/services/generic.service';
import Claim from '../../../shared/models/shared/Claim.model';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { CX_CALLS } from '../../../shared/constants/cx-calls.constant';
import { ClaimAppealConfig } from '../claim-appeal/claim-redetermination/claim-redetermination.model';
import { AttachmentMetaData } from '../../../shared/models/shared/Document.model';
import { ClaimAppealDetailDataService } from './claim-appeal-detail.data.service';
import {
  DocumentDefaultLabel,
  initialValueTabConfig,
} from './claim-appeal-detail.constants';

@Component({
  selector: 'claimAppealDetail',
  templateUrl: './claim-appeal-detail.component.html',
  styleUrls: ['./claim-appeal-detail.component.scss'],
})
export class ClaimAppealDetailComponent implements OnInit, OnDestroy {
  claim: Claim;
  config: ClaimAppealDetailConfig;
  claimRedeterminationConfig: ClaimAppealConfig;
  tabsLabels: string[] = [];
  tabsConfig: ClaimAppealDetailTabsConfig[] = [];
  badgesConfig: BadgeConfig[] = [];
  activeTabConfig: ClaimAppealDetailTabsConfig;
  resources: LinkListItem[];
  showClaimAppealWarningMessage: boolean;
  claimAppealWarningMessage: string;
  claimAppealTypeSelected: string;
  showPopupMessageForReconsideration: boolean = true;
  memberId: string | number;
  readonly claimNumber: string = this.route.snapshot?.params.claim_number;
  readonly claimAppealId: string = this.route.snapshot?.params.appeal_id;
  readonly claimAppealType: string = this.route.snapshot?.params.appeal_type;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private configService: ConfigService,
    public route: ActivatedRoute,
    private router: Router,
    private claimAppealDetailDataService: ClaimAppealDetailDataService,
    public genericServices: GenericServices,
    private formatService: FormattingService,
    public analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.genericServices.showFooter$.next(false);
    this.config = this.configService.getPageConfig('claim-appeal-detail');
    this.claimRedeterminationConfig = this.configService.getPageConfig(
      'claim_redetermination'
    );
    this.claimAppealDetailDataService.claimNumber = this.claimNumber;
    const requestInformationControls =
      this.claimRedeterminationConfig?.entry.cards.requestInformation?.form
        ?.controls;
    const disputeType = requestInformationControls?.find(
      (control) => (control.prop = 'disputeType')
    );
    if (disputeType) {
      this.claimAppealDetailDataService.disputeTypes = disputeType?.options;
    }

    this.addBadges();
    this.claimAppealDetailDataService.selectedClaimAppealType =
      this.claimAppealType;
    this.analyticsService.sendEvent(
      CX_CALLS['pp_claims-appeal_viewed'].event_key,
      { appeal_level: this.claimAppealType }
    );
  }

  routeBackClaimDetail(): void {
    this.router.navigate([
      `/provider-portal/claims/${this.claimNumber}/claim-appeals`,
    ]);
  }

  addBadges(): void {
    this.claimAppealDetailDataService
      .getClaim(this.claimNumber)
      .pipe(
        takeUntil(this.destroy$),
        mergeMap((claim: Claim) => {
          claim.id &&
            claim.patient?.id &&
            this.claimAppealDetailDataService.getClaimAppealDetail(
              claim.id,
              this.claimAppealId,
              this.config,
              claim.patient.id
            );

          return of(claim);
        })
      )
      .subscribe((claim: Claim) => {
        this.claim = claim;
        this.applyTabPriority();
        this.setActiveTab();
        this.createClaimAppealResource();

        this.memberId = this.claim.patient.id;
        this.badgesConfig = Object.entries(this.config.badges).map(
          ([badgeKey, badge]) =>
            this.formatService.formatConfigValueAsTemplate(badge, claim)
        );
      });
  }

  createClaimAppealResource(): void {
    this.claimAppealDetailDataService
      .getClaimAppealSidePanelInfo()
      ?.pipe(takeUntil(this.destroy$))
      .subscribe((data: AttachmentMetaData[]) => {
        this.resources = data.map((document: AttachmentMetaData) => ({
          label: document.file_name?.split('.')[0] || DocumentDefaultLabel,
          href: `${this.config.sidePanel.document.href}/${document.id}/`,
          icon: this.config.sidePanel.documentType || 'pdf',
          cx_event_key: '',
        }));
      });
  }

  setActiveTab(): void {
    let targetRoute: UrlSegment;
    if (this.route?.snapshot) {
      const segment = this.route?.snapshot['_urlSegment'].segments;
      targetRoute = segment[segment.length - 1];
    }

    this.activeTabConfig =
      this.tabsConfig.find(
        (tabConfig: ClaimAppealDetailTabsConfig) =>
          tabConfig.url === targetRoute.path
      ) || initialValueTabConfig;
  }

  applyTabPriority(): any {
    this.config &&
      Object.entries(this.config.tabs).forEach(([key, value]) => {
        this.tabsLabels[value.priority] = value.label;
        this.tabsConfig[value.priority] = value;
      });
  }

  selectTab(selectedTabIndex: number): void {
    this.router.navigate([this.tabsConfig[selectedTabIndex].url], {
      relativeTo: this.route,
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
