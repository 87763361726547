import { Injectable, NgModule } from '@angular/core';
import { RouterModule, ROUTES } from '@angular/router';
import { Resolve } from '@angular/router';
import { AuthService, ConfigService } from '../../../shared/services';
import { DocumentCenterWrapperComponent } from './document-center-wrapper.component';

@Injectable({ providedIn: 'root' })
export class DocumentsConfigResolver implements Resolve<any> {
  constructor(private configurationService: ConfigService) {}
  resolve() {
    return this.configurationService.getPageConfig('documents-center');
  }
}

@Injectable({ providedIn: 'root' })
export class SquidexConfigResolver implements Resolve<any> {
  constructor(private configurationService: ConfigService) {}
  resolve() {
    return this.configurationService.getPageConfig('global')[
      'contentManagementSystem'
    ]['squidex'];
  }
}

@Injectable({ providedIn: 'root' })
export class UserDetailsResolver implements Resolve<any> {
  constructor(private authService: AuthService) {}
  resolve() {
    return {
      cohortKeys: this.authService.loggedInUser?.app_user_data?.cohort_keys,
      role: this.authService.userRole,
    };
  }
}

function routesFactory(configurationService: ConfigService) {
  return [
    {
      path: '',
      component: DocumentCenterWrapperComponent,
      children: [
        {
          path: '',
          loadChildren: () => {
            const updatedConfig =
              configurationService.getPageConfig('documents-center');
            if (!!updatedConfig) {
              return import('@zipari/feat-document-center').then(
                (m: any) => m.DocumentCenterModule
              );
            } else {
              return import('../document-center/document-center.module').then(
                (m: any) => m.DocumentCenterModule
              );
            }
          },
          resolve: {
            documentCenterConfig: DocumentsConfigResolver,
            squidexConfig: SquidexConfigResolver,
            userDetails: UserDetailsResolver,
          },
        },
      ],
    },
  ];
}

@NgModule({
  imports: [RouterModule.forChild([])],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES,
      useFactory: routesFactory,
      multi: true,
      deps: [ConfigService],
    },
  ],
})
export class DocumentCenterWrapperRoutingModule {}
