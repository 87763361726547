<article [zipBusy]="busy" class="centered-base_page">
  <div class="centered-base_page__breadcrumbs">
    <div class="centered-base_page__breadcrumbs_crumb">
      <p [ngClass]="{ 't-bold': state === 'one' }" class="t-data">
        {{ config?.breadcrumbs?.one }}
      </p>
      <i class="centered-base_page__breadcrumbs_crumb__arrow icon icon--medium"
        >chevron_right</i
      >
    </div>
    <div class="centered-base_page__breadcrumbs_crumb">
      <p [ngClass]="{ 't-bold': state === 'two' }" class="t-data">
        {{ config?.breadcrumbs?.two }}
      </p>
      <i class="centered-base_page__breadcrumbs_crumb__arrow icon icon--medium"
        >chevron_right</i
      >
    </div>
    <div class="centered-base_page__breadcrumbs_crumb">
      <p [ngClass]="{ 't-bold': state === 'three' }" class="t-data">
        {{ config?.breadcrumbs?.three }}
      </p>
      <i class="centered-base_page__breadcrumbs_crumb__arrow icon icon--medium"
        >chevron_right</i
      >
    </div>
    <div class="centered-base_page__breadcrumbs_crumb">
      <p [ngClass]="{ 't-bold': state === 'four' }" class="t-data">
        {{ config?.breadcrumbs?.four }}
      </p>
    </div>
  </div>

  <div class="centered-base_page__content" [ngSwitch]="state">
    <div *ngSwitchDefault>
      <div class="provisioner">
        <div class="column-header" role="heading" aria-level="1">
          {{ (config?.createSteps)[state]?.header }}
        </div>
        <div class="column-subheader">
          {{ (config?.createSteps)[state]?.subheader }}
        </div>
        <div class="column-container">
          <div *ngFor="let section of (config?.createSteps)[state]?.sections">
            <card
              class="cardholder card item skinny-badge"
              (postInit)="postInit()"
            >
              <div title class="column-header">{{ section.header }}</div>
              <div body>
                <div *ngIf="section?.type !== 'location_hours'">
                  <form-group
                    [configs]="section?.controls"
                    [form]="getSectionForm(state)"
                  ></form-group>
                  <div *ngFor="let control of section?.array_controls">
                    <div [ngSwitch]="control.type">
                      <input-array
                        [key]="'addLocation'"
                        [config]="control"
                        *ngSwitchCase="'input'"
                      ></input-array>
                      <dropdown-array
                        [key]="'addLocation'"
                        [config]="control"
                        *ngSwitchCase="'dropdown'"
                      ></dropdown-array>
                    </div>
                  </div>
                </div>
                <div *ngIf="section?.type === 'location_hours'">
                  <location-hours
                    (exited)="onExit()"
                    [config]="section.controls"
                    [errorMessage]="section.errorMessage"
                    [location_hours]="location_hours_form"
                  ></location-hours>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
    <div class="provisioner" *ngSwitchCase="'four'">
      <card class="column-container card item skinny-badge">
        <div body class="provisioner">
          <div title class="column-header">
            <div *ngIf="config?.createSteps?.four?.successHeader">
              {{ config?.createSteps?.four?.successHeader }}
            </div>
            <div *ngIf="config?.createSteps?.four?.confirmationLocation">
              {{
                getObjectProperty(
                  createResponse,
                  config?.createSteps?.four?.confirmationLocation
                )
              }}
            </div>
          </div>
          <br />
          <div subheader class="column-subheader">
            {{ config?.createSteps?.four?.successMsg }}
          </div>
          <div>
            <zip-button
              level="high"
              [content]="config?.createSteps?.four?.submitButton"
              (click)="toggleModalInitiate()"
            ></zip-button>
          </div>
        </div>
      </card>
    </div>
    <div class="provisioner" *ngSwitchCase="'five'">
      <card class="column-container card item skinny-badge">
        <div body class="provisioner">
          <div title class="column-header">
            <div *ngIf="config?.createSteps?.five?.successHeader">
              {{ config?.createSteps?.five?.successHeader }}
            </div>
            <div *ngIf="config?.createSteps?.five?.confirmationLocation">
              {{
                getObjectProperty(
                  associateResponse,
                  config?.createSteps?.five?.confirmationLocation
                )
              }}
            </div>
          </div>
          <br />
          <div subheader class="column-subheader">
            {{ config?.createSteps?.five?.successMsg }}
          </div>
          <div>
            <zip-button
              level="high"
              [content]="config?.createSteps?.five?.returnButton"
              (click)="return($event)"
            ></zip-button>
          </div>
        </div>
      </card>
    </div>
  </div>
  <div class="centered-base_page__always_on_top">
    <zip-button
      *ngIf="state === 'one'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.buttons?.cancelButton"
      (zipButtonClicked)="cancel($event)"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'four'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.buttons?.returnButton"
      (zipButtonClicked)="return($event)"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'two'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.buttons?.backButton"
      (zipButtonClicked)="back($event)"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'three'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.buttons?.backButton"
      (zipButtonClicked)="back($event)"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'one' || state === 'two'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.buttons?.continueButton"
      (zipButtonClicked)="continue($event)"
    >
    </zip-button>
    <zip-button
      *ngIf="state === 'three'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.buttons?.submitButton"
      (zipButtonClicked)="submit($event)"
    >
    </zip-button>
  </div>
</article>

<modal
  #associateFlyout
  *ngIf="showModal"
  [config]="config?.associateFlyout"
  [condition]="showModal"
  (cancel)="cancelModal()"
>
  <div body>
    <div [ngSwitch]="addState">
      <div *ngIf="config?.associateFlyout?.subheader" class="header-associate">
        <p>
          {{ config?.associateFlyout?.subheader }}
        </p>
      </div>
      <div class="associate-table" *ngSwitchCase="'add'">
        <zip-table
          (selectedRows)="handleSelectedRows($event)"
          [options]="config?.associateFlyout.table"
        >
        </zip-table>
      </div>
      <div class="selected-row__review" *ngSwitchCase="'review'">
        <div *ngFor="let selection of selectedRows; let i = index">
          <!-- <h5 class="t-bold spaced-out">{{ getObjectProperty(selection, config?.associateFlyout?.displayField) }}
                        <span class="selected--row__header--required red">*</span>
                    </h5> -->
          <div class="row">
            <div class="column">
              <h6 class="t-bold">
                {{
                  getObjectProperty(
                    selection.row,
                    config?.associateFlyout?.fields.firstName
                  )
                }}
                {{
                  getObjectProperty(
                    selection.row,
                    config?.associateFlyout?.fields.lastName
                  )
                }}
              </h6>
            </div>
            <div class="column">
              <div
                *ngFor="
                  let _item of config?.associateFlyout?.fields.rightPanel;
                  let index = index
                "
              >
                <model-attribute
                  [context]="selection.row"
                  [config]="_item"
                ></model-attribute>
              </div>
            </div>
          </div>
          <form-group
            [configs]="config?.associateFlyout?.controls"
            [form]="getAssociatedFields(i)"
          ></form-group>
        </div>
      </div>
    </div>
  </div>
  <div footer>
    <zip-button
      [disabled]="!selectedRows.length"
      *ngIf="addState === 'add'"
      level="high"
      [content]="config?.associateFlyout?.nextButton"
      (click)="reviewAssociated($event)"
    ></zip-button>
    <zip-button
      [disabled]="isDisabled()"
      *ngIf="addState === 'review'"
      level="high"
      [content]="config?.associateFlyout?.submitButton"
      (click)="submitAddAssociated($event)"
    ></zip-button>
  </div>
</modal>

<modal
  *ngIf="error"
  [config]="config.errorModal"
  [condition]="error"
  (cancel)="toggleError()"
>
  <div body id="errorMessage">{{ config.errorModal.message }}</div>
</modal>
