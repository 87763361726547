import { HttpErrorResponse } from '@angular/common/http';
import { RESPONSE_CODES } from '../constants';
import { BannerTypes, ResponseCode } from '../enums';

export const FALLBACK_ERROR_MESSAGE =
  'There was a problem. Please try again later.';

/**
 * Returns an error message to display to the user
 * @param error response from the server
 * @param configMessage (optional) - provide error message via configuration
 */
export function getErrorMessage(
  error: HttpErrorResponse,
  configMessage?: string
): string {
  const err = (error?.error?.errors && error.error.errors[0]) || error?.message;

  function defaultMessage(): string {
    return configMessage ? configMessage : FALLBACK_ERROR_MESSAGE;
  }

  if (err?.user_error === 'None' || err?.user_error?.detail === 'None') {
    return defaultMessage();
  }
  if (typeof err?.user_error === 'string' && err?.user_error !== 'None') {
    return err.user_error;
  } else if (
    typeof err?.user_error === 'object' &&
    err.user_error?.detail &&
    err.user_error.detail !== 'None'
  ) {
    return err.user_error.detail;
  } else return defaultMessage();
}

/**
 * Returns a config object for displaying an HTTP error using MessageBanner component
 * @param error response from the server
 * @param config (optional) - provide error message via configuration
 */
export function httpErrorToMessageBanner(
  error: HttpErrorResponse,
  config?: { message: string }
) {
  return {
    type: BannerTypes.error,
    icon: 'warning',
    message: getErrorMessage(error, config?.message),
  };
}

/**
 * Returns a config object for displaying an HTTP error using ErrorEmpty component
 * @param error response from the server
 * @param config (optional) - manually provide display values from configuration
 */
export function httpErrorToErrorEmpty(
  error: HttpErrorResponse,
  config?: { title?: string; description?: string; image_url?: string }
) {
  return {
    title: config?.title
      ? config?.title
      : `${error.status} - ${error.statusText}`,
    description: getErrorMessage(error, config?.description),
    image_url: config?.image_url
      ? config?.image_url
      : 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/error-1.svg',
  };
}

/**
 * Returns a mock HttpErrorResponse for a given code + URL
 * @param code response code from the server (default: 500)
 * @param url the URL where the error occurs
 */
export function mockError(
  code: ResponseCode = ResponseCode.INTERNAL_SERVER_ERROR,
  url: string = 'https://members-democare.dev.zipari.net/'
) {
  return new HttpErrorResponse({
    error: null,
    status: code,
    statusText: RESPONSE_CODES.find((c) => c.value === code)?.label,
    url: url,
  });
}
