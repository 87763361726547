/* eslint-disable no-underscore-dangle */
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TableComponent } from '@zipari/design-system';

import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { GenericServices } from '../../../../shared/services/generic.service';
import { APIService, ConfigService } from '../../../../../shared/services';
import { CX_CALLS } from '../../../../../shared/constants/cx-calls.constant';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';
import { TableCellClickEvent } from '../../../../../shared/models/shared/CellClickEvent.model';

@Component({
  styleUrls: ['./manage-site-location-list.component.scss'],
  selector: 'manage-site-location-list',
  templateUrl: './manage-site-location-list.component.html',
})
export class ManageSiteLocationListComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @ViewChild(TableComponent) table: TableComponent;

  config: any;
  busy: Subscription;
  showModal: boolean = false;
  error: boolean = false;
  itemsToDelete: any[] = [];
  state: 'edit' | 'review';
  terminateFlyoutFields = new UntypedFormGroup({});
  deleteResponses: any[] = [];

  constructor(
    private api: APIService,
    private router: Router,
    public configService: ConfigService,
    private cdr: ChangeDetectorRef,
    public genericServices: GenericServices,
    public analyticsService: AnalyticsService
  ) {}

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.genericServices.showFooter$.next(false);
    });
    this.config = this.configService.getPageConfig('site-locations');
    this.config.delete_modal.controls.forEach((element: any) => {
      if (element.prop === 'deactivation_date') {
        element['default'] = moment(new Date()).format('YYYY-MM-DD');
      }
    });
  }

  handleModalCancel() {
    this.showModal = !this.showModal;
  }

  toggleError() {
    this.error = !this.error;
  }

  navigateToDetail(event: TableCellClickEvent): void {
    if (event.context?.row?.id)
      this.router.navigate([
        `/provider-portal/site-locations/detail/${event.context.row.id}`,
      ]);
  }

  deleteClicked(event: any[]): void {
    this.analyticsService.sendEvent(
      CX_CALLS.pp_add_site_location_terminated.event_key
    );
    this.terminateFlyoutFields = new UntypedFormGroup({});
    this.state = 'edit';
    if (this.config.delete_modal && event.length > 0) {
      this.itemsToDelete = event;
      this.showModal = !this.showModal;
    }
  }

  getObjectProperty(obj, keys) {
    if (!keys) return '';
    if (!obj) return '';

    return keys.split('.').reduce(function (result, key) {
      return result[key];
    }, obj);
  }

  remove(index: number) {
    if (index > -1) {
      this.itemsToDelete.splice(index, 1);
    }
  }

  submit() {
    if (this.isDisabled()) return true;
    if (this.terminateFlyoutFields.valid) {
      if (this.terminateFlyoutFields?.value) {
        const payload: any[] = [];
        this.itemsToDelete.forEach((item: any) => {
          const _item: any = {};
          _item['termination_date'] =
            this.terminateFlyoutFields?.value.deactivation_date;
          _item['notes'] = [
            { note_content: this.terminateFlyoutFields?.value.notes },
          ];
          _item['facility_location_id'] = item.row?.id;
          _item['id'] = item.row?.id;
          payload.push(_item);
        });
        this.busy = this.api
          .patch(this.config.table.endpoint, payload)
          .subscribe(
            (res: any) => {
              this.table?.unselectAllRows();
              this.table?.refresh();
              this.state = 'review';
              if (this.config?.delete_modal?.confirmationArrayLocation) {
                this.deleteResponses = this.getObjectProperty(
                  res,
                  this.config.delete_modal.confirmationArrayLocation
                );
              }
            },
            () => {
              this.handleModalCancel();
              this.toggleError();
            }
          );
      }
    } else {
      const controls = this.terminateFlyoutFields.controls;
      Object.keys(controls).forEach((c: any) => controls[c].markAsTouched());
    }
  }

  isDisabled() {
    let dontShow: boolean = false;
    dontShow = this.setDisabledValidations(
      this.terminateFlyoutFields,
      dontShow
    );
    // dontShow = this.setDisabledValidations(locationValues, dontShow);
    if (dontShow) return true;
    if (!this.itemsToDelete?.length) return true;

    return false; // otherwise, enable button;
  }

  setDisabledValidations(control: any, returnBool: boolean): boolean {
    if (!control.valid) {
      const controls = control.controls;
      if (controls) {
        Object.keys(controls).forEach((c: any) => {
          if (!controls[c].valid) {
            if (controls[c].controls) {
              Object.keys(controls[c].controls).forEach((cc: any) => {
                returnBool = this.setDisabledValidations(
                  controls[c].controls[cc],
                  returnBool
                );
              });
            } else {
              if (!controls[c].pristine) {
                controls[c].markAsTouched();
              }
              returnBool = true;
            }
          }
        });
      }
    }

    return returnBool;
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.genericServices.showFooter$.next(true);
    });
  }

  addLocation() {
    this.router.navigate([`/provider-portal/site-locations/create`]);
  }
}
