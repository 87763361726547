import { Component, Input, OnInit } from '@angular/core';
import { DropdownConfiguration } from '@zipari/shared-ds-util-form';
import { US_STATES } from './states.const';

@Component({
  selector: 'states',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.scss'],
})
export class StatesComponent implements OnInit {
  @Input() config: DropdownConfiguration;
  @Input() control: any;

  statesConfig: any;

  ngOnInit() {
    this.statesConfig = {
      ...this.config,
      options: US_STATES,
    };

    if (
      this.config.hasOwnProperty('placeholder') &&
      typeof this.config.placeholder === 'string'
    ) {
      this.statesConfig.options.unshift({
        label: this.config.placeholder,
        value: null,
      });
    }
  }
}
