<div
  class="form-control__wrapper form-array__wrapper"
  [ngClass]="{
    'form-control__wrapper--inline': direction === 'row',
    'form-control__wrapper--flat': direction === 'filter'
  }"
>
  <label class="form-control__label">
    <p class="t-data t-bold">{{ formArrayConfig.label }}</p>
  </label>

  <div class="form-array__controls u-fillRemaining">
    <div
      class="form-array-control u-flex"
      [ngClass]="{
        'u-alignBaseline': formArrayConfigs.length > 1
      }"
      *ngFor="let controlConfig of formArrayConfigs; let i = index"
    >
      <form-control
        direction="array"
        [group]="group.controls[formArrayConfig.prop]"
        [config]="controlConfig"
        [control]="group.controls[formArrayConfig.prop].controls[i]"
      >
      </form-control>

      <zip-button
        *ngIf="formArrayConfigs.length === 1; else multipleControls"
        (zipButtonClicked)="addItem()"
        level="medium"
        icon="add"
        content="Additional"
      >
      </zip-button>
      <ng-template #multipleControls>
        <div class="icon-container">
          <icon
            name="add"
            [config]="{
              size: 'small',
              type: 'pointer',
              fill: 'success'
            }"
          >
          </icon>

          <icon
            name="bullet"
            [config]="{
              size: 'small',
              type: 'pointer',
              fill: 'error'
            }"
          >
          </icon>
        </div>
      </ng-template>
    </div>
  </div>
</div>
