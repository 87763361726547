import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonModule,
  FormGroupModule,
  ModalModule,
} from '@zipari/design-system';
import { AdditionalDetailModalComponent } from './additional-detail-modal.component';

@NgModule({
  imports: [CommonModule, ModalModule, ButtonModule, FormGroupModule],
  declarations: [AdditionalDetailModalComponent],
  exports: [AdditionalDetailModalComponent],
})
export class UiAdditionalDetailModalModule {}
