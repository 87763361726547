export enum validMasks {
  currency = 'currency',
  date = 'date',
  americanDate = 'americanDate',
  mbi = 'mbi',
  month = 'month',
  number = 'number',
  phone = 'phone',
  splitSsn = 'splitSsn',
  ssn = 'ssn',
  taxId = 'taxId',
  name = 'name',
}

const ssn = 11;
const last4 = -4;

export const MASKS = {
  // only allow numbers, replace all with X on blur
  splitSsn: {
    mask: (value: any) => value.replace(/[^\d]/g, ''),
    unmask: (value: any) => value.replace(/[^\d]/g, ''),
    blur: (value: string) => value.replace(/./g, 'X'),
  },
  phone: {
    mask: (value: any) =>
      value &&
      value
        .replace(/[^\d]/g, '')
        .replace(/([\d]{3})/, '$1-')
        .replace(/(-[\d]{3})/, '$1-'),
    unmask: (value: any) => value && value.replace(/[^\d]/g, ''),
  },
  mbi: {
    mask: (value: any) =>
      value &&
      value.replace(/([\d\D]{4})/, '$1-').replace(/(-[\d\D]{3})/, '$1-'),
    unmask: (value: any) => value && value.replace(/[^\d]/g, ''),
  },
  ssn: {
    mask: (value: any) =>
      value &&
      value
        .replace(/[^\d]/g, '')
        .replace(/([\d]{3})/, '$1-')
        .replace(/(-[\d]{2})/, '$1-'),
    unmask: (value: any) => value && value.replace(/[^\d]/g, ''),
    blur: (value: string) => {
      if (value.length === ssn) {
        return `XXX-XX-${value.substr(last4)}`;
      }

      return value;
    },
  },
  currency: {
    // mask regex source: https://coderwall.com/p/uccfpq/formatting-currency-via-regular-expression
    mask: (value: any) => {
      const numDecimals = 2;

      if (value.indexOf('.') === value.length - 1) {
        return value;
      }
      const numbersOnlyValue = value.replace(/[^\d.]/g, '');
      const [number, dec] = numbersOnlyValue.split('.');
      let decimal = dec;

      decimal = decimal && (<string>decimal).substring(0, numDecimals);
      const numberWithCommas = Number(number).toLocaleString('en');
      const numbersWithCommasAndDecimal =
        numberWithCommas + (decimal ? `.${decimal}` : '');

      return `${numbersWithCommasAndDecimal}`;
    },
    unmask: (value: any) => value && value.replace(/[^\d.]/g, ''),
    blur: (value: any) => {
      const numDecimals = 2;
      const [bigBills, dec] = value.split('.');
      let cents = dec;

      cents = cents && (<string>cents).padEnd(numDecimals, '0');
      if (!cents) {
        cents = '00';
      }

      return `$${bigBills}${cents ? `.${cents}` : ''}`;
    },
  },
  // Use this for html maxlength but with numbers (because maxlength doesn't work on input type=number)
  number: {
    mask: (value: any) => String(value).replace(/[^\d]/g, ''),
    unmask: (value: any) =>
      // javascript precision get crazy on us when numbers are too large so we have to use strings
      value.length < `${Number.MAX_SAFE_INTEGER}.length`
        ? Number(value.replace(/[^\d]/g, ''))
        : String(value).replace(/[^\d]/g, ''),
  },
  alpha: {
    mask: (value: any) => value.replace(/[\d]/g, ''),
    unmask: (value: any) => value.replace(/[\d]/g, ''),
  },
  // Standard name mask allows letters, hyphens, apostrophes, and spaces
  name: {
    mask: (value: any) => value.replace(/[^a-zA-Z\-\s']/g, ''),
    unmask: (value: any) => value.replace(/[^a-zA-Z\-\s']/g, ''),
  },
  taxId: {
    mask: (value: any) =>
      value && value.replace(/[^\d]/g, '').replace(/([\d]{2})/, '$1-'),
    unmask: (value: any) => value && value.replace(/[^\d]/g, ''),
  },
  monthDay: {
    mask: (value: any) =>
      value && value.replace(/[^\d]/g, '').replace(/([\d]{2})/, '$1/'),
    unmask: (value: any) =>
      value && value.replace(/[^\d]/g, '').replace(/([\d]{2})/, '$1/'),
  },
  month: {
    mask: (value: any) =>
      value &&
      value
        .replace(/[^\d]/g, '')
        .replace(/([\d]{4})/, '$1-')
        .replace(/[^\0-9]{6,7}/),
    unmask: (value: any) =>
      value &&
      value
        .replace(/[^\d]/g, '')
        .replace(/([\d]{4})/, '$1-')
        .replace(/[^\0-9]{6,7}/),
  },
  date: {
    mask: (value: any) =>
      value &&
      value
        .replace(/[^\d]/g, '')
        .replace(/([\d]{4})/, '$1-')
        .replace(/(-[\d]{2})/, '$1-'),
    unmask: (value: any) =>
      value &&
      value
        .replace(/[^\d]/g, '')
        .replace(/([\d]{4})/, '$1-')
        .replace(/(-[\d]{2})/, '$1-'),
  },
  americanDate: {
    mask: (value: any) =>
      value &&
      value
        .replace(/[^\d]/g, '')
        .replace(/([\d]{2})/, '$1/')
        .replace(/(\/[\d]{2})/, '$1/'),
    unmask: (value: any) =>
      value &&
      value
        .replace(/[^\d]/g, '')
        .replace(/([\d]{2})/, '$1/')
        .replace(/(\/[\d]{2})/, '$1/'),
  },
};
