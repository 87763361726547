import { Component, Input } from '@angular/core';

@Component({
  selector: 'site-location-message',
  templateUrl: './site-location-message.component.html',
})
export class SiteLocationMessageComponent {
  @Input() successHeader: string;
  @Input() siteLocationMessage: string;
}
