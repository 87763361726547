import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule, FormControlModule } from '@zipari/design-system';
import { PipeModule } from '../../../../shared/pipes/pipe.module';
import { SharedModule } from '../../../../shared/shared.module';
import { ClinicalReviewListComponent } from './clinical-review-list.component';

@NgModule({
  declarations: [ClinicalReviewListComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormControlModule,
    ButtonModule,
    SharedModule,
    PipeModule,
  ],
  exports: [ClinicalReviewListComponent],
})
export class ClinicalReviewListModule {}
