import { Component, Input } from '@angular/core';
import { TextAreaConfiguration } from '@zipari/shared-ds-util-form';

@Component({
  selector: 'control-textarea',
  templateUrl: './textarea.component.html',
})
export class ControlTextAreaComponent {
  @Input() config: TextAreaConfiguration;
  @Input() control: any;

  get maximumCharactersNumberReached(): boolean {
    const maxLength = this.config.maxLength || 0;

    return maxLength - this.control.value.length <= 0;
  }
}
