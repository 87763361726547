<div class="table__row">
  <div class="table__row__expand">
    <i class="icon icon--medium icon--pointer" (click)="groupClicked.emit()">
      {{ expanded ? 'chevron_up' : 'chevron_down' }}
    </i>
  </div>

  <zip-table-generic-cell-2
    class="table__cell"
    *ngFor="let column of _columns"
    [column]="column"
    [data]="data.groupRow"
  >
  </zip-table-generic-cell-2>
</div>
