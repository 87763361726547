import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { stringBuilder } from '@zipari/web-utils';
import { WindowService } from '@zipari/web-services';
import { FormattingService } from '../../../../shared/services/formatting.service';
import {
  FieldConfig,
  SectionConfig,
  TabConfig,
  TabsConfig,
} from '../../../../shared/models/TabsConfig.model';
import { toCamel } from '../../../../shared/utilities/case-conversion.utils';
import { ProgressBarFormattingService } from '../../../../shared/services/progress-bar-formatting.service';
import {
  OutNetworkStatus,
  OutOfNetworkProps,
} from '../../../templates/transfer-of-care/transfer-of-care-details.constants';
import { OutOfNetworkConfig } from '../../../templates/authorization-submission/authorization-submission.model';
import { TableCellClickEvent } from '../../../../shared/models/shared/CellClickEvent.model';

@Component({
  selector: 'tab-view',
  templateUrl: './tab-view.component.html',
  styleUrls: ['./tab-view.component.scss'],
})
export class TabViewComponent implements OnInit, OnChanges {
  /** ERHO TODO: add types for all these */
  @Input() selectedTab: TabConfig;
  @Input() config: TabsConfig;
  @Input() data: any;
  @Input() customInitSelectTab: number;
  @Input() outOfNetwork: OutOfNetworkConfig;

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() zipTableDeleteButtonClicked: EventEmitter<any> = new EventEmitter();

  activeTab: TabConfig;
  tabFilters;
  formGroup: UntypedFormGroup;
  baseUrl = `provider-portal`;
  explicitData: any;
  targetTableLocation = {};
  showModal: boolean;
  openAccordionIdx: number;
  selectedTableData: any[];
  progressBarsformatted = false;
  selectedRows: any;
  openFlyouts = -1;
  deleteConfig: any;
  showDeleteModal = false;

  constructor(
    private formatService: FormattingService,
    private route: ActivatedRoute,
    private router: Router,
    private progressBarFormat: ProgressBarFormattingService,
    private windowService: WindowService
  ) {}

  ngOnInit(): void {
    this.formatTabSections();
    if (this.customInitSelectTab) {
      this.activeTab = this.config?.tabConfigs[this.customInitSelectTab];
    }
  }

  ngOnChanges(change: SimpleChanges): void {
    if (this.config) {
      if (change.selectedTab) {
        if (change.selectedTab.firstChange) {
          this.activeTab = this.customInitSelectTab
            ? this.config?.tabConfigs[this.customInitSelectTab]
            : this.config?.tabConfigs[0];
        } else {
          this.activeTab =
            this.config?.tabConfigs[change.selectedTab.currentValue];
          /** For explicitly referencing data passed from grandparent component **/
          if (this.activeTab?.explicitDataIdentifier) {
            this.explicitData =
              this.data[this.activeTab?.explicitDataIdentifier];
          }
        }
      }

      this.tabFilters = null;

      if (this.activeTab?.filters) this.setupFilters(this.activeTab.filters);
    }

    /** ERHO: TODO: change back once UCare Benefit API is functioning ***/
    if (this.data?.benefits && !this.progressBarsformatted) {
      this.formatProgressBar();
    }
  }

  preInit(config: any) {
    if (this.data?.member?.member_number) {
      config.apiEndpoint += `?member_id=${this.data.member.member_number}`;
    }
  }

  handleLinkClick(ev): void {
    this.windowService.nativeWindow.open(ev.url, '_blank');
  }

  openModalIfNeeded(event, headerEditIcon: boolean, target, idx: number): void {
    if (headerEditIcon) this.openModal(target, idx);
    else {
      event.stopPropagation();
    }
  }

  handleRowSelection(ev): void {
    this.data.selectedTableData = ev;
  }

  openModal(target?: any, idx?: number): void {
    this.showModal = true;
    this.openAccordionIdx = idx;
  }

  onCancelClick(): void {
    this.showModal = false;
    this.showDeleteModal = false;
  }

  formatFieldsData(fieldArr: FieldConfig[]): FieldConfig[] {
    return fieldArr
      .map((attr: FieldConfig) =>
        this.formatService.formatConfigValueAsTemplate(attr, this.data)
      )
      .map((config: FieldConfig) => {
        config.value = this.formatService.restructureValueBasedOnFormat(
          config.value,
          config
        );

        return config;
      });
  }

  formatTable(target: string): void {
    const locationArry: string[] = target.split('.');

    locationArry.forEach((elem: string) => {
      this.targetTableLocation[elem] = this.data[elem];
    });
  }

  /** navigate to detail page **/
  navigateToDetail(target: TableCellClickEvent): void {
    let targetRoute: string;
    let url: string;

    if (this.activeTab?.targetRouteObj?.isExternal) {
      url = target.context?.row?.[this.activeTab.targetRouteObj.innerTargetId];
      window.open(url, '_blank');
    }
    if (this.activeTab?.targetRouteObj?.customUrl) {
      const pattern: any = /[^${}]*(?=\})/g;
      const toReplace: string[] =
        this.activeTab.targetRouteObj.customUrl.match(pattern);

      toReplace.forEach((item: any) => {
        if (item.length > 0) {
          url = this.activeTab.targetRouteObj.customUrl.replace(
            new RegExp(`\\\${${item}}`, 'gi'),
            target.context.row[item]
          );
        }
      });
      window.open(url, '_blank');
    } else if (this.activeTab?.targetRouteObj?.targetUrl) {
      // this just just a sanity null check
      targetRoute = this.getDetailsUrl(target);
      this.router.navigate([targetRoute]);
    }
  }

  deleteClicked(event: any[]): void {
    if (this.activeTab.delete_panel && event.length > 0) {
      this.selectedRows = event;
      if (!this.deleteConfig) {
        this.deleteConfig = {};
        this.deleteConfig.workflow = this.activeTab.delete_panel;
      }
      this.showDeleteModal = true;
    }
  }

  setupFilters(filters): void {
    this.formGroup = new UntypedFormGroup({});
    this.tabFilters = filters.map(this.createFormControl.bind(this));
  }

  createFormControl(config): UntypedFormControl {
    const control: UntypedFormControl = new UntypedFormControl('', []);
    const newFormControl = Object.assign(config, {
      control: control,
    });

    this.formGroup.addControl(newFormControl.prop, control);

    if (config.value) {
      this.formGroup.get(newFormControl.prop).setValue(config.value);
    }

    return newFormControl;
  }

  /** Format URL for routing (for now, only back) */
  getDetailsUrl(target: TableCellClickEvent): string {
    let detailRoute: string;
    let targetVal: string;
    const targetUrl: string = this.activeTab.targetRouteObj.targetUrl;

    detailRoute = `${this.baseUrl}/${targetUrl}/`;
    targetVal = target.context?.val;

    if (this.activeTab.targetRouteObj.targetId) {
      detailRoute += `${this.data[this.activeTab.targetRouteObj.targetId]}/`;
    }
    if (this.activeTab.targetRouteObj.innerTarget) {
      detailRoute += `${this.activeTab.targetRouteObj.innerTarget}/`;
    }
    if (this.activeTab.targetRouteObj.innerTargetId) {
      targetVal =
        target.context.row[this.activeTab.targetRouteObj.innerTargetId];
    }

    /** ERHO: Refactor entire function to take identifier from config for routing **/
    /** Too much going on here **/
    if (this.activeTab.targetRouteObj.base) {
      detailRoute = this.buildUrlThatCanRouteBackToBase();
    }

    detailRoute += `${targetVal}`;

    return detailRoute;
  }

  buildUrlThatCanRouteBackToBase(): string {
    let detailRoute: string;

    detailRoute = this.activeTab.targetRouteObj.detailRoute;
    this.route.snapshot.url.map((url: object) => {
      detailRoute += `${url['path']}/`;
    });
    detailRoute += `${this.activeTab.targetRouteObj.deepTarget}/`;

    return detailRoute;
  }

  onButtonClick(config: any): void {
    switch (config.customAction) {
      case 'openModal':
        this.openModal(config);
        break;
      case 'openModalWorkflow':
        this.openModal(this.config?.form);
        break;
    }
  }

  /** ERHO:
   * TODO: have function return modified config rather than do all the looping
   * This is ugly.
   **/
  formatProgressBar(): void {
    this.activeTab.sections.map((section: SectionConfig) => {
      if (section.format === 'progressBar') {
        this.progressBarFormat.sortData(this.data[section.targetData]);

        section.progressBars = this.progressBarFormat.formatProgressBarConfig(
          section.progressBars,
          this.data[section.targetData],
          section.progressBarData,
          section.uniqueSectionKeyValue
        );
        this.progressBarsformatted = true;
      }
    });
  }

  setHeaderCSS(headerEditIcon: boolean) {
    return {
      ['isCursor']: headerEditIcon,
    };
  }

  onSaveNewUserClick(event: any) {
    this.onCancelClick();
    this.save.emit(event);
  }

  modifyHeadingContent(heading: string) {
    return heading
      .split('_')
      .map(
        (headingTextCollection: any) =>
          headingTextCollection[0].toUpperCase() +
          headingTextCollection.slice(1)
      )
      .join(' ');
  }

  showOutOfNetworkBadge(status: string, context: any) {
    const useServices: boolean =
      status === OutOfNetworkProps[toCamel(status)] &&
      context?.services?.length > 0;
    const badgeStatus = useServices
      ? context.services[0][status]
      : context[status];

    return (
      this.outOfNetwork?.isOutOfNetworkEnabled &&
      badgeStatus === OutNetworkStatus
    );
  }

  private getObjectProperty(result: any, config: FieldConfig): FieldConfig {
    const prop = config.value
      .match(/{([^}]*)}/)[1]
      .replace(/ /g, '')
      .split('||');

    return result[prop[0]]
      ? { ...config, value: `\${${prop[0]}}` }
      : { ...config, value: `\${${prop[1]}}` };
  }

  private formatFieldConfig(config: FieldConfig, context): FieldConfig {
    return config?.value.includes('||')
      ? this.getObjectProperty(context, config)
      : config;
  }

  private formatTabSections(): void {
    this.config?.tabConfigs.forEach((tab: TabConfig) => {
      tab.sections.forEach((section: SectionConfig) => {
        if (section.fields) {
          section.fields = this.formatFieldsData(section.fields);
        }

        if (section.table) {
          if (section.targetLocation) this.formatTable(section.targetLocation);

          // format endpoint
          if (section.table.endpoint) {
            section.table.endpoint = stringBuilder(
              section.table.endpoint,
              this.data
            );
          }
        }
        if (section.format === 'accordion') {
          if (section.accordion && !section.accordion.dataFromConfig) {
            const configToBeCopied: any = section['accordion'].accordionFields;

            this.data[section['accordion'].targetDataObj].forEach(
              (arryItem, idx) => {
                section.fields[idx] = configToBeCopied;
              }
            );
          }
        }
      });
    });
  }
}
