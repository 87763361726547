import { Location } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { GenericServices } from '../../../shared/services/generic.service';
import { APIService, ConfigService } from '../../../../shared/services';
import { PortalUser } from '../../../../shared/models/shared/PortalUser.model';
import { ProvisionerService } from '../provisioner/provisioner.service';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
@Component({
  styleUrls: ['./edit-user-provisioning.component.scss'],
  selector: 'edit-user-provisioning',
  templateUrl: './edit-user-provisioning.component.html',
})
export class EditUserProvisioningComponent implements OnInit, OnDestroy {
  id: any;
  config: any;
  state: 'manage' | 'review' | 'confirm' = 'manage';
  busy: Subscription;
  error: boolean = false;
  showModal: boolean = false;
  role: string;
  portalUser: PortalUser;
  accessRequestId: string;

  constructor(
    private route: ActivatedRoute,
    public configService: ConfigService,
    public genericServices: GenericServices,
    public router: Router,
    private api: APIService,
    private location: Location,
    private provisioner: ProvisionerService,
    public analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.provisioner.clearSelections();
    this.role = this.route.snapshot.data.role;
    setTimeout(() => {
      this.genericServices.showFooter$.next(false);
    });
    this.id = this.route.snapshot.params.id;
    // here if admin manually create user(from request access) then we get id from state object
    this.accessRequestId = window.history?.state?.id;
    this.provisioner.setUserId(this.id);
    this.config = this.configService.getPageConfig('edit-user-provisioning');
    if (this.config) {
      this.getPortalUser(this.setUserId(this.config.endpoints.user));
    }
  }

  public getPortalUser(url: string) {
    const portalUsers: Observable<PortalUser> = this.api.get(url);

    this.busy = portalUsers?.subscribe((portalUser: PortalUser) => {
      this.provisioner.setUserFirstName(portalUser.first_name);
      this.provisioner.setUserLastName(portalUser.last_name);
      this.portalUser = portalUser;
    });
  }

  cancelButtonClicked(event: any): void {
    if (this.state === 'confirm') {
      // here if admin manually create user(from request access) it should redirect to manage access request detail page
      const accessId = this.accessRequestId ? this.accessRequestId : this.id;
      if (this.accessRequestId) {
        this.router.navigate([
          `provider-portal/${this.role}/manage-access-request-detail/${accessId}`,
        ]);
      } else {
        this.router.navigate([
          `provider-portal/${this.role}/manage-portal-users/detail/${accessId}`,
        ]);
      }
    } else {
      if (this.genericServices.previousURL.includes('provider-portal')) {
        this.location.back();
      }
    }
  }

  previousButtonClicked(event: any): void {
    this.state = 'manage';
  }

  continueButtonClicked(event: any): void {
    // continue does validation checks on each column
    if (this.provisioner.getTreeNode().children.length) {
      // followed by swapping states
      this.state = 'review';
    } else {
      this.showModal = true;
    }
  }

  handleModalCancel(): void {
    this.showModal = false;
    this.error = false;
  }

  saveButtonClicked(event: any): void {
    const provisionSelections = this.provisioner.getFlatSelections();
    const payload: any = {
      updates: provisionSelections.flatSelections,
    };

    this.busy = this.api
      .patch(
        `/api/provider-portal/admin/users/${this.id}/provisions/edit/`,
        payload
      )
      .subscribe(
        (res: any) => {
          this.state = 'confirm';
          if (this.portalUser.role === 'Provider Office Staff') {
            this.analyticsService.sendEvent('pp_providerofficestaff_edited');
            this.analyticsService.sendEvent(
              'pp_providerofficestaff_provisioned',
              provisionSelections.eventAttributes
            );
          }
        },
        (err: any) => {
          this.error = true;
        }
      );
  }

  setUserId(target: string): string {
    return target.replace('${id}', this.provisioner.getUserId());
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.genericServices.showFooter$.next(true);
    });
    this.provisioner.clearSelections();
    this.provisioner.clearUserId();
  }
}
