import { ButtonConfig } from '@zipari/shared-ds-util-button';

export const authConstants = {
  controlType: 'service_type',
  cardType: 'dynamicCard',
};

export const RequestLevelOfCare = {
  Inpatient: 'Inpatient',
  Outpatient: 'Outpatient',
  Observation: 'Observation',
};

export interface DiagnosisCode {
  code: string;
  description: string;
  id: string;
  index: number;
  is_primary: boolean;
  type: string;
}

export const MCGAuthParameters = {
  requestType: 'AuthRequest',
  entryPoint: 'SubmitRequestStep',
};

export const AuthRequestType = {
  inpatient: 'inpatient',
  outpatient: 'outpatient',
};
export const DocumentableProcedureRequestType = [
  'inpatient',
  'inpatient_behavior_health',
  'skilled_nursing',
  'outpatient',
  'outpatient_oncology',
  'outpatient_behavior_health',
];
export const DocumentableDiagnosisRequestType = [
  'inpatient_oncology',
  'homehealth',
  'rehabilitation',
];

export const InpatientRequestedLevelOfCare = [
  'inpatient',
  'inpatient_behavior_health',
  'inpatient_oncology',
];
export const OutpatientRequestedLevelOfCare = [
  'outpatient',
  'outpatient_oncology',
  'outpatient_behavior_health',
  'homehealth',
];

export const ProvidersKeys = [
  'servicing_facility_id',
  'requesting_provider_id',
  'servicing_provider',
];

// eslint-disable-next-line no-shadow
export enum ProcedureCodingSystem {
  CPT = 'cpt',
  HCPCS = 'hcpcs',
  CDT = 'cdt',
  ICD10 = 'icd-10',
}

export interface ClinicalReviewConfig {
  enableClinicalReview: boolean;
  isClinicalReviewMandatory: boolean;
  clinicalReviewConfirmationModal: {
    confirmationModalConfig: {
      type: string;
      header: {
        title: string;
      };
      description: string;
    };
    footer: {
      continueButton: ButtonConfig;
      skipButton: ButtonConfig;
      cancelButton: ButtonConfig;
    };
  };
  vendorSignatureAPIEndPoint: string;
  ddlHashFunction: string;
  mcgIframeUrl: string;
  mcgPartnerId: string;
  mcgSolutionId: string;
  mcgSolutionVersion: string;
  prePopulateOptionalParameters?: any;
}

// eslint-disable-next-line no-shadow
export enum PhoneType {
  HOME = 'home',
  CELL = 'cell',
  WORK = 'work',
  OTHER = 'other',
}

export const LengthOfStayCard = 'length_of_stay';
export const IsPrimaryProp = 'is_primary';
export const ProcedureCodeCardKey = 'procedure_code_or_description';
export const RemoveLable = 'Remove';
export const NoValue = 'n/a';
export const Select = 'select';
export const isInNetwork = 'is_in_network';
export const ProviderType = {
  siteLocation: 'siteLocation',
  provider: 'provider',
};

export const CONTROL_KEYS = {
  site_location_and_practitioner_provider:
    'site-location-and-practitioner-provider',
};

export const ProviderDisplayFields = {
  name: 'Name',
  address: 'Address',
  groupNpi: 'Group NPI',
  idNumber: 'ID Number',
  primarySpecialty: 'Primary Specialty',
  phone: 'Phone Number',
  specialty: 'Primary Specialty',
};

export const uploadDocument = {
  documentType: 'document_type',
  documentIds: 'document_ids',
};
