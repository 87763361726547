import { Injectable } from '@angular/core';

declare const webkitSpeechRecognition: any;

@Injectable({
  providedIn: 'root',
})
export class SpeechRecognitionService {
  public recognition;
  public isListening = false;
  public text = '';
  public interimText: string;

  constructor() {
    // Only initializes Speech Recognition if API is supported
    if ('SpeechRecognition' in window || window['webkitSpeechRecognition']) {
      this.recognition = new webkitSpeechRecognition();
    }
  }

  init() {
    if (this.recognition) {
      this.recognition.interimResults = true;
      this.recognition.lang = 'en-US';

      this.recognition.addEventListener('result', (e) => {
        const transcript = Array.from(e.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join('');
        this.interimText = transcript;
      });
    }
  }

  start() {
    this.text = '';
    this.isListening = true;
    this.recognition.start();

    this.recognition.addEventListener('end', (condition) => {
      this.displayResults();
    });
  }

  stop() {
    this.isListening = false;
    this.displayResults();
    this.recognition.stop();
  }

  displayResults() {
    this.text = `${this.text} ${this.interimText}`.trim();
    this.interimText = '';
  }
}
