import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface PaginatorConfiguration {
  rowsPerPage?: number;
  showingStart: number;
  showingEnd: number;
  totalResults: number;
  totalPages: number;
  currentPage: number;
  labels?: {
    singular: string;
    plural: string;
  };
}

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent {
  @Input() config: PaginatorConfiguration;
  @Input() data: any[];
  @Output() pageSelected = new EventEmitter<any>();

  setCurrentPage(page: number) {
    this.pageSelected.emit(page);
  }
}
