import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalConfig, ModalTypes } from '@zipari/shared-ds-util-modal';
import { LinkConfig } from '@zipari/shared-ds-util-navigation';
import { toTitleCaseFromSentenceCase } from '@zipari/shared-util-common';
import { stringBuilder } from '@zipari/web-utils';
import { ExternalSiteModalConfig } from './external-site-warning-modal.model';

@Component({
  selector: 'external-site-warning-modal',
  templateUrl: './external-site-warning-modal.component.html',
})
export class ExternalSiteWarningModalComponent {
  @Input() linkConfig!: LinkConfig;
  @Input() externalSiteModalConfig!: ExternalSiteModalConfig;
  @Input() isModalOpen: boolean = false;
  @Output() closeModalClicked = new EventEmitter<null>();
  @Output() continueClicked = new EventEmitter<Event>();

  closeModal() {
    this.isModalOpen = false;
    this.closeModalClicked.emit();
  }

  continueConfirmed(event: Event) {
    this.isModalOpen = false;
    this.continueClicked.emit(event);
  }

  formatModalBody() {
    let formattedConfig = {};

    if (CONFIG) {
      formattedConfig = Object.keys(CONFIG).reduce(
        (newObj, key) => ({
          ...newObj,
          ...{ [key.toLowerCase()]: toTitleCaseFromSentenceCase(CONFIG[key]) },
        }),
        {}
      );
    }

    const expandedContext = { ...this.linkConfig, ...formattedConfig };

    return stringBuilder(
      this.externalSiteModalConfig?.description,
      expandedContext
    );
  }

  getModalConfig(externalModalConfig): ModalConfig {
    return {
      type: ModalTypes.popup,
      header: {
        title: externalModalConfig.title,
        icon: null,
        showClose: false,
      },
    };
  }
}
