import { Component, Input, OnInit } from '@angular/core';
import { FormControlService } from '../../../../notes/form-control/form-control.service';
import {
  cityStateZipConfig,
  streetAddressConfig,
} from './basic-address.constants';

@Component({
  selector: 'basic-address',
  templateUrl: './basic-address.component.html',
  styleUrls: ['./basic-address.component.scss'],
})
export class BasicAddressComponent implements OnInit {
  @Input() group;
  @Input() config;
  @Input() direction;

  addressLine1 = streetAddressConfig;
  addressLine2 = cityStateZipConfig;

  constructor(public formControlService: FormControlService) {}

  ngOnInit() {
    // Allow the ability to override internal controls from config
    if (this.config.streetAddressConfig)
      this.addressLine1 = this.config.streetAddressConfig;
    if (this.config.cityStateZipConfig)
      this.addressLine2 = this.config.cityStateZipConfig;

    this.formControlService.addFormGroupToFormGroup(this.group, this.config);

    this.addressLine1.forEach((config) => {
      this.formControlService.addControlToFormGroup(
        this.group.controls[this.config.prop],
        config
      );
    });

    this.addressLine2.forEach((config) => {
      this.formControlService.addControlToFormGroup(
        this.group.controls[this.config.prop],
        config
      );
    });
  }
}
