<form [formGroup]="mp.getFormGroup(key)">
  <div [formArrayName]="config.prop">
    <div class="form-control__label__wrapper">
      <label class="form-control__label t-bold">
        <p class="t-data t-bold">{{ config?.label }}</p>
        <p *ngIf="config.required" class="t-data t-bold spaced-star">
          {{ '*' }}
        </p>
      </label>
    </div>
    <div
      *ngFor="let item of formArray.controls; let index = index"
      [formGroupName]="index"
    >
      <div class="form-container">
        <div class="dropdown">
          <input
            class="form-control"
            formControlName="selection"
            [maxLength]="config.maxLength ? config.maxLength : 99"
          />
        </div>
        <span
          *ngIf="1 == getMaxSize()"
          class="material-icons mat-spaced-icon isHidden"
        >
          remove_circle_outline
        </span>
        <span
          *ngIf="getMaxSize() > 1"
          (click)="deleteArrayItem(index)"
          class="material-icons mat-spaced-icon"
        >
          remove_circle_outline
        </span>
        <span (click)="addArrayItem()" class="material-icons mat-spaced-icon">
          add_circle_outline
        </span>
      </div>
      <div
        *ngIf="
          item.get('selection').invalid &&
          (item.get('selection').dirty || item.get('selection').touched)
        "
      >
        <div class="error" *ngIf="item.get('selection').errors">
          <icon
            name="warning"
            style="height: 1.125rem; padding-left: 1rem; padding-right: 0.5rem"
            ><i
              class="icon icon--font ng-star-inserted icon--small"
              style="color: red"
            >
              warning
            </i>
          </icon>
          <p class="t-caption t-error">
            {{ config.errorMessage || 'Please enter a valid input.' }}
          </p>
        </div>
      </div>
    </div>
  </div>
</form>
