import { ButtonConfig, ButtonLevels } from '@zipari/shared-ds-util-button';
import {
  AllControlsConfiguration,
  ErrorEmptyConfig,
  formControlDirections,
} from '@zipari/shared-ds-util-form';

export class FormGroupArrayConfig {
  controls: AllControlsConfiguration[];
  prop: string;
  description?: string;
  title?: string;
  titleSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 't-data';
  maxLength?: number;
  protectMain?: boolean;
  hideTitleCount?: boolean;
  hideActions?: boolean;
  showDuplicateAction?: boolean;
  showFormWithAccordion?: boolean;
  actionIcon?: string;
  allowEmpty?: boolean;
  errorEmpty?: ErrorEmptyConfig;
  columns?: number;
  isFooterBelowContent?: boolean;
  direction?: formControlDirections;
  addButton: ButtonConfig = {
    level: ButtonLevels.medium,
  };
  addDuplicateButton?: ButtonConfig = {
    level: ButtonLevels.medium,
  };
  removeButton?: ButtonConfig = {
    level: ButtonLevels.high,
  };
  /**
   * @deprecated - use addButton instead.
   */
  addButtonText?: string;
  /**
   * @deprecated - use addButton instead.
   */
  addButtonIcon?: string;

  constructor(config) {
    Object.keys(config)
      .filter((key) => key !== 'addButtonText' && key !== 'addButtonIcon')
      .forEach((key) => (this[key] = config[key]));

    if (config.addButtonText) {
      this.addButton.content = config.addButtonText;
    }

    if (config.addButtonIcon) {
      this.addButton.icon = config.addButtonIcon;
    }
  }
}
