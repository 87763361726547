<div
  class="status"
  [ngClass]="[
    status | statusMap,
    config.hideBackground | statusBackground,
    config.fitContent | statusFitContent
  ]"
>
  <p
    *ngIf="!isMobileView"
    tabindex="0"
    class="status__text"
    [ngClass]="{
      'status__text--capitalized': config.capitalized,
      'StatusWhiteSpace--normal': config.whiteSpaceNormal
    }"
  >
    {{ config.label }}
  </p>
</div>
