import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  BadgeModule,
  ButtonModule,
  CardModule,
  FormControlModule,
  FormGroupModule,
  MessageBannerModule,
  ModalModule,
  ModelAttributeModule,
  PreloaderModule,
  FileUploaderModule,
} from '@zipari/design-system';
import { ZipBusyModule } from '../../../shared/modules/zip-busy/zip-busy.module';
import { FlyoutDiagnosisModule } from '../../shared/modules/flyout-diagnosis/flyout-diagnosis.module';
import { FlyoutDropdownModule } from '../../shared/modules/flyout-dropdown/flyout-dropdown.module';
import { ServiceDetailsModule } from '../../shared/modules/service-details/service-details.module';
import { ClinicalReviewListModule } from '../../shared/modules/clinical-review-list/clinical-review-list.module';
import { ClinicalReviewCardModule } from '../../shared/modules/clinical-review-card/clinical-review-card.module';
import { SiteLocationAndPractitionerModule } from '../../shared/modules/site-location-and-practitioner/site-location-and-practitioner.module';
import { AuthorizationSubmissionComponent } from './authorization-submission.component';

@NgModule({
  declarations: [AuthorizationSubmissionComponent],
  exports: [AuthorizationSubmissionComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormGroupModule,
    ButtonModule,
    ZipBusyModule,
    ModelAttributeModule,
    CardModule,
    ModalModule,
    FormControlModule,
    ServiceDetailsModule,
    FlyoutDropdownModule,
    FlyoutDiagnosisModule,
    FileUploaderModule,
    BadgeModule,
    PreloaderModule,
    MessageBannerModule,
    ClinicalReviewListModule,
    ClinicalReviewCardModule,
    SiteLocationAndPractitionerModule,
  ],
})
export class AuthorizationSubmissionModule {}
