import { Component, OnChanges, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { FormControlValidatorsService } from '../shared/validators/validators.service';

interface ValidatorConfig {
  date?: string;
  dependency?: string;
  message?: string;
  name: string;
}

interface DateRangeConfig {
  ariaLabel?: string;
  end_date_validators?: ValidatorConfig[];
  endProp?: string;
  iconRight?: string;
  label?: string;
  max?: string;
  min?: string;
  placeholder?: string;
  prop: string;
  start_date_validators?: ValidatorConfig[];
  startProp?: string;
  validators?: string[];
  fallbackMask?: string; // this is a mask for ie and safari which don't support the calendar.
}

export interface DateRangeValue {
  end: string;
  start: string;
}

@Component({
  selector: 'control-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class ControlDateRangeComponent implements OnChanges {
  @Input() config: DateRangeConfig;
  @Input() group: UntypedFormGroup;
  dateProps: string[];
  dateRangeConfig;
  formGroupName: string;

  constructor(private validatorService: FormControlValidatorsService) {}

  ngOnChanges() {
    const {
      prop,
      placeholder,
      iconRight,
      start_date_validators,
      end_date_validators,
      min,
      max,
      fallbackMask,
    } = this.config;
    const startPropName = this.config.startProp || 'start';
    const endPropName = this.config.endProp || 'end';
    const dateRangeFormGroup: UntypedFormGroup = new UntypedFormGroup({});

    this.dateProps = [startPropName, endPropName];
    this.formGroupName = prop;
    this.dateRangeConfig = {
      [startPropName]: {
        iconRight: iconRight,
        min,
        max,
        fallbackMask,
        prop: startPropName,
        type: 'date',
        validators: start_date_validators,
      },
      [endPropName]: {
        iconRight: iconRight,
        min,
        max,
        fallbackMask,
        prop: endPropName,
        type: 'date',
        validators: end_date_validators,
      },
    };
    if (placeholder) {
      // the placeholder is set in the text control
      this.dateRangeConfig[startPropName].placeholder = placeholder;
      this.dateRangeConfig[endPropName].placeholder = placeholder;
    }

    const startDateValidators =
      this.validatorService.getFormControlValidators(
        this.dateRangeConfig[startPropName]
      ) || [];
    const endDateValidators =
      this.validatorService.getFormControlValidators(
        this.dateRangeConfig[endPropName]
      ) || [];

    dateRangeFormGroup.addControl(
      endPropName,
      new UntypedFormControl('', {
        validators: endDateValidators,
        updateOn: 'blur',
      })
    );
    dateRangeFormGroup.addControl(
      startPropName,
      new UntypedFormControl('', {
        validators: [...startDateValidators],
        updateOn: 'blur',
      })
    );
    this.group.removeControl(prop);
    this.group.addControl(prop, dateRangeFormGroup);
  }
}
