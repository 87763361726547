<div [zipBusy]="busy">
  <div class="row header-row">
    <div *ngFor="let item of columns" class="row-item item">
      <p class="t-bold t-data header-text">
        {{ item?.label || '' }}
      </p>
    </div>
    <div *ngIf="hasPdf" class="pdf item">
      <p class="t-bold t-data header-text">
        {{ hasPdf?.label || 'PDF' }}
      </p>
    </div>
  </div>
  <div *ngFor="let result of results" class="row">
    <div *ngFor="let item of columns" class="row-item item">
      <p class="t-data header-text">
        {{
          getObjectProperty(result, item?.prop) || noSpace | date : 'MM/dd/yyyy'
        }}
      </p>
    </div>
    <div *ngIf="hasPdf" class="pdf item">
      <p
        *ngIf="getObjectProperty(result, hasPdf.prop)"
        class="t-data header-text"
      >
        <i
          class="icon icon--medium icon--pointer"
          (click)="getPDFLink(getObjectProperty(result, hasPdf.prop), $event)"
          >pdf</i
        >
      </p>
      <p
        *ngIf="!getObjectProperty(result, hasPdf.prop)"
        class="t-data header-text"
      >
        {{ noSpace }}
      </p>
    </div>
  </div>
  <div class="row" *ngIf="error">
    <div class="row-item item">
      <p class="t-alt header-text" id="errorMessage">
        {{ config.errorMessage || 'No Results.' }}
      </p>
    </div>
  </div>
  <div class="page">
    <paginator
      *ngIf="results?.length"
      [config]="paginatorConfig"
      [data]="results"
      (pageSelected)="goToPage($event)"
    >
    </paginator>
  </div>
</div>
