import { Component, Input } from '@angular/core';

@Component({
  selector: 'pp-pregnancy-form-group',
  templateUrl: './pregnancy-form-group.component.html',
  styleUrls: ['./pregnancy-form-group.component.scss'],
})
export class PregnancyFormGroupComponent {
  @Input() config;
  @Input() context;
  @Input() checkboxValues?;
}
