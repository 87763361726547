import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormControlService } from '@zipari/design-system';
import { Subscription } from 'rxjs';
import { FormattingService } from '../../../shared/services/formatting.service';
import { IdCard } from '../../../shared/models/shared/IdCard.model';
import { PatientDetailService } from '../patient-detail/patient-detail.service';
import { IdCardsTabConfig } from '../patient-detail/patientDetailConfig.model';
import { ApiListResponse } from '../../../shared/models/shared/ApiListResponse.model';
interface IdCardWithConfig {
  idCards: IdCard[];
  config: any;
}

@Component({
  selector: 'id-cards',
  templateUrl: './id-cards.component.html',
  styleUrls: ['./id-cards.component.scss'],
})
export class IdCardsComponent implements OnInit {
  error = false;
  dataLoaded = true;
  memberId: any;
  idCards: IdCard[];
  busy: Subscription;
  idCard: IdCard;
  config: IdCardsTabConfig;
  filteredIdCardswithConfig: IdCardWithConfig[] = [];
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  isCardSelected: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private patientDetailService: PatientDetailService,
    private formControlService: FormControlService,
    public formattingService: FormattingService
  ) {}

  ngOnInit(): void {
    this.config = this.patientDetailService.tabsConfig;
    this.memberId = this.patientDetailService.context?.member?.member_number;
    // ---ID card can not be used from cache as it creates problem to show correct ID card
    this.getIdCards();
  }

  setMemberId(target: any): any {
    const newEndpoint: string = target.replace('${member_id}', this.memberId);

    return newEndpoint;
  }

  getIdCards(): void {
    this.busy = this.patientDetailService
      .getIdCards(this.setMemberId(this.config.endpoint))
      .subscribe(
        (response: ApiListResponse<IdCard>) => {
          this.idCards = response.results;
          this.idCards.forEach((idCard: IdCard) => {
            idCard.effective_date = this.getFormattedDate(
              idCard.effective_date
            );
            idCard.termination_date = this.getFormattedDate(
              idCard.termination_date
            );
          });
          this.patientDetailService.context.idCards = this.idCards;
          this.buildDropdownFilter();
          this.buildfilteredIdCards();
          this.dataLoaded = true;
        },
        (error: any) => {
          this.idCards = [];
          this.filteredIdCardswithConfig = [];
          this.error = true;
          this.dataLoaded = true;
        }
      );
  }

  buildfilteredIdCards(): void {
    const dateNow: Date = new Date(new Date().setHours(0, 0, 0, 0));
    this.isCardSelected = false;

    // find most recent card which has product coverage type
    const recentIdCards = this.idCards.filter(
      (idcard: IdCard) =>
        idcard?.coverage_details?.product_coverage_type &&
        (!idcard.termination_date ||
          new Date(idcard.termination_date) > dateNow)
    );

    // Build Id card with config before selecting card
    this.config.idCards?.forEach((idCardConfig: any) => {
      this.filteredIdCardswithConfig.push({
        idCards: [],
        config: idCardConfig,
      });
    });
    // Get first most recent from to list to select
    const selectedIdCard =
      recentIdCards && recentIdCards.length > 0
        ? recentIdCards[0]?.id
        : undefined;
    if (selectedIdCard) {
      this.formGroup.patchValue({ [this.config.filters.prop]: selectedIdCard });
    }
  }

  getFormattedDate(dateValue: string) {
    return this.formattingService.restructureValueBasedOnFormat(
      dateValue,
      this.config?.filters
    );
  }

  private buildDropdownFilter() {
    const uniqueOptions = new Set();
    // find id cards with product type

    const options = this.idCards
      .filter(
        (card: IdCard) =>
          card.coverage_details && card.coverage_details.product_coverage_type
      )
      .map((idCard: IdCard) => ({
        value: idCard.id,
        label: `${idCard.effective_date} - ${idCard.termination_date}`,
      }));
    // Get  unique options
    this.config.filters.options = options.filter((option: any) => {
      const duplicate = uniqueOptions.has(option.label);
      uniqueOptions.add(option.label);

      return !duplicate;
    });
    const control = new UntypedFormControl('', []);
    const newFormControl = Object.assign(this.config.filters, {
      control: control,
    });
    this.formControlService.addControlToFormGroup(
      this.formGroup,
      newFormControl
    );
    //  When dropdown value changes update idCard associated with config for that coverage type
    this.formGroup
      .get(this.config.filters.prop)
      .valueChanges.subscribe((newValue: any) => {
        const selectedIdCard = this.idCards.find(
          (idcard: IdCard) => idcard.id === newValue
        );
        if (selectedIdCard) {
          const filteredIdCards = this.idCards.filter(
            (idCard: IdCard) =>
              idCard.effective_date === selectedIdCard.effective_date &&
              idCard.termination_date
          );
          if (filteredIdCards) {
            this.filteredIdCardswithConfig.forEach(
              (idCardWithConfig: IdCardWithConfig) => {
                idCardWithConfig.idCards = filteredIdCards.filter(
                  (idCard: IdCard) =>
                    idCard?.coverage_details?.product_coverage_type ===
                    idCardWithConfig.config?.title?.toLowerCase()
                );
              }
            );
          }
        } else {
          this.filteredIdCardswithConfig.forEach(
            (idCardWithConfig: IdCardWithConfig) => {
              idCardWithConfig.idCards = [];
            }
          );
        }
      });
  }
}
