<modal
  *ngIf="config"
  [condition]="showSuspensionTypeModal"
  [config]="config.modalConfig"
  (cancel)="closeUserSuspensionTypeModal()"
>
  <ng-container body>
    <form-group
      class="SuspendUserModal"
      [form]="suspensionTypeModalForm"
      [configs]="config.userSuspensionTypeForm?.controls"
      (formCreated)="onFormCreated()"
    ></form-group>
  </ng-container>
  <ng-container footer>
    <div class="u-flex u-alignCenter u-justifyBetween u-maxX">
      <zip-button
        (zipButtonClicked)="closeUserSuspensionTypeModal()"
        [config]="config.cancelButton"
      >
      </zip-button>
      <zip-button
        (zipButtonClicked)="suspensionTypeSelected()"
        [config]="
          suspensionTypeModalForm?.value?.suspensionType === 'entirely'
            ? config.submitButton
            : config.continueButton
        "
      >
      </zip-button>
    </div>
  </ng-container>
</modal>
