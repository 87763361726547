import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionModule, SpinnerModule } from '@zipari/design-system';
import { ResourcesComponent } from './resources.component';

@NgModule({
  declarations: [ResourcesComponent],
  exports: [ResourcesComponent],
  imports: [CommonModule, AccordionModule, SpinnerModule],
})
export class ResourcesModule {}
