export class CardConfig {
  hideHeader = false;
  hideSubHeader = false;
  hideFooter = false;
  stretch = false;
  static = false;
  ignorePadding = false;

  constructor(options = {}) {
    Object.assign(this, options);
  }
}
