/** Converts provided string into camelCase
 * @return converted camelCase string
 */
export function toCamel(s: string): string {
  return s
    .replace(/[\s|_|-](.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/[\s|_|-]/g, '')
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });
}

/** Checks the array and object keys to convert into
 * camelCase format
 * @return converted camelCase keys
 */
export function camelCase(o: any): any {
  if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
    const n = {};
    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = camelCase(o[k]);
    });

    return n;
  } else if (Array.isArray(o)) {
    return o.map((i) => camelCase(i));
  }

  return o;
}

/** Converts provided string into snakeCase
 * @return converted snakeCase string
 */
export function toSnakeCase(s: string): string {
  return s.replace(/[A-Z0-9]/g, (letter) => `_${letter.toLowerCase()}`);
}

/** Checks the array and object keys to convert into
 * snakeCase format
 * @return converted snakeCase keys
 */
export function camelToSnakeCase(o: any): any {
  if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
    const n = {};
    Object.keys(o).forEach((k) => {
      n[toSnakeCase(k)] = camelToSnakeCase(o[k]);
    });

    return n;
  } else if (Array.isArray(o)) {
    return o.map((i) => {
      const n = {};
      if (i === Object(i) && i !== null) {
        Object.keys(i).forEach((k) => {
          n[toSnakeCase(k)] = camelToSnakeCase(i[k]);
        });
      } else {
        return i;
      }

      return n;
    });
  }

  return o;
}
