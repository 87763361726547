import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from './../button/button.module';
import { ExpandingArticleComponent } from './expanding-article.component';

@NgModule({
  declarations: [ExpandingArticleComponent],
  imports: [CommonModule, ButtonModule],
  exports: [ExpandingArticleComponent],
})
export class ExpandingArticleModule {}
