import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  BadgeModule,
  ButtonModule,
  ModelAttributeModule,
  ZipBusyModule,
} from '@zipari/design-system';

import { DetailTabsModule } from '../../shared/modules/detail-tabs/detail-tabs.module';
import { TabViewModule } from '../../shared/modules/tab-view/tab-view.module';

import { ClaimLineDetailComponent } from './claim-line-detail.component';

@NgModule({
  declarations: [ClaimLineDetailComponent],
  imports: [
    CommonModule,
    ModelAttributeModule,
    ButtonModule,
    BadgeModule,
    DetailTabsModule,
    TabViewModule,
    ZipBusyModule,
  ],
  exports: [ClaimLineDetailComponent],
})
export class ClaimLineDetailModule {}
