import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ButtonModule,
  CardModule,
  ExpandingArticleModule,
  FormControlModule,
  FormGroupModule,
  ModalModule,
  ModelAttributeModule,
  TableModule,
} from '@zipari/design-system';
import { ZipBusyModule } from '../../../../../shared/modules/zip-busy/zip-busy.module';
import { ControlsGroupArrayModule } from '../../controls/controls-group-array/controls-group-array.module';
import { DropdownArrayModule } from '../../controls/dropdown-array/dropdown-array.module';
import { InputArrayModule } from '../../controls/input-array/input-array.module';
import { ManagePractitionerAddComponent } from './manage-practitioner-add.component';

@NgModule({
  declarations: [ManagePractitionerAddComponent],
  exports: [ManagePractitionerAddComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormControlModule,
    FormGroupModule,
    ButtonModule,
    ZipBusyModule,
    ModalModule,
    ExpandingArticleModule,
    CardModule,
    DropdownArrayModule,
    TableModule,
    ModelAttributeModule,
    InputArrayModule,
    ControlsGroupArrayModule,
  ],
  providers: [],
})
export class ManagePractitionerAddModule {}
