<modal [config]="modalConfig" [condition]="isIdling" (cancel)="handleCancel()">
  <div body [innerHTML]="modalBody"></div>

  <div footer class="timeout-modal__footer">
    <div class="timeout-modal__footer--button__group">
      <zip-button
        level="medium"
        content="Logout"
        (zipButtonClicked)="handleLogout()"
      >
      </zip-button>

      <zip-button
        level="high"
        content="Extend Session"
        (zipButtonClicked)="handleCancel()"
      >
      </zip-button>
    </div>
  </div>
</modal>
