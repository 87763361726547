import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ButtonModule,
  CardModule,
  ExpandingArticleModule,
  FormControlModule,
  FormGroupModule,
  ModalModule,
  ModelAttributeModule,
  TableModule,
} from '@zipari/design-system';
import { NgSwitchOnExitDirective } from '../../../../shared/directives/ng-switch-onExit.directive';
import { NgSwitchPostInitDirective } from '../../../../shared/directives/ng-switch-postInit.directive';
import { ZipBusyModule } from '../../../../../shared/modules/zip-busy/zip-busy.module';
import { DropdownArrayModule } from '../../controls/dropdown-array/dropdown-array.module';
import { InputArrayModule } from '../../controls/input-array/input-array.module';
import { LocationHoursModule } from '../../controls/location-hours/location-hours.module';
import { ManageSiteLocationAddComponent } from './manage-site-location-add.component';

@NgModule({
  declarations: [
    ManageSiteLocationAddComponent,
    NgSwitchPostInitDirective,
    NgSwitchOnExitDirective,
  ],
  exports: [ManageSiteLocationAddComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormControlModule,
    FormGroupModule,
    ButtonModule,
    ZipBusyModule,
    ModalModule,
    ModelAttributeModule,
    ExpandingArticleModule,
    CardModule,
    DropdownArrayModule,
    TableModule,
    LocationHoursModule,
    InputArrayModule,
  ],
  providers: [],
})
export class ManageSiteLocationAddModule {}
