<div class="productized-auth" [zipBusy]="busy">
  <!-- switch to change between layouts -->
  <ng-container [ngSwitch]="config?.layout">
    <auth-banner
      *ngSwitchCase="AuthenticationLayouts.banner"
      [layoutOptions]="config?.layoutOptions"
    >
      <ng-container authCard *ngTemplateOutlet="authCard"> </ng-container>
    </auth-banner>

    <auth-step
      *ngSwitchCase="AuthenticationLayouts.step"
      [layoutOptions]="config?.layoutOptions"
    >
      <ng-container authCard *ngTemplateOutlet="authCard"> </ng-container>
    </auth-step>
  </ng-container>

  <ng-template #authCard>
    <div
      [ngClass]="{
        'auth-card': !config?.layoutOptions?.isCustomAligned,
        'custom-auth-card': config?.layoutOptions?.isCustomAligned
      }"
      *ngIf="showAuthCard"
    >
      <auth-card
        (buttonClicked)="handleButtonClicked($event)"
        (linkClicked)="handleLinkClicked($event)"
        [disableButton]="disableButton"
        [success]="success"
        [errorMessage]="errorMessage"
        [formGroup]="formGroup.controls[activeCardIdx]"
        [mainForm]="formGroup"
        [config]="config?.authCardOptions"
        [idp]="config?.idp"
        [okta]="config?.okta"
        [activeCard]="activeCard"
        [authenticationType]="page"
      >
      </auth-card>
    </div>
  </ng-template>

  <replacement-users-modal
    [config]="activeCard.replacementUserModal"
    *ngIf="associatedTaxIds"
    [showModal]="showReplacementUsersModal"
    [associatedTaxIds]="associatedTaxIds"
    (closeModal)="closeModel()"
    (submitClicked)="submitRequestAccess()"
    (selectedItems)="handleReplacementTaxIds($event)"
  ></replacement-users-modal>
</div>
