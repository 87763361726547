import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ModelAttributeModule,
  ButtonModule,
  BadgeModule,
} from '@zipari/design-system';
import { DetailTabsModule } from '../../shared/modules/detail-tabs/detail-tabs.module';
import { TabViewModule } from '../../shared/modules/tab-view/tab-view.module';
import { BenefitLineComponent } from './benefit-line.component';

@NgModule({
  declarations: [BenefitLineComponent],
  imports: [
    CommonModule,
    ModelAttributeModule,
    ButtonModule,
    BadgeModule,
    DetailTabsModule,
    TabViewModule,
  ],
  exports: [BenefitLineComponent],
})
export class BenefitLineModule {}
