import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { SquidexDataService } from '@zipari/shared-data-access-squidex';
import * as token from './token';

@Injectable()
export class SquidexAuthInterceptor implements HttpInterceptor {
  squidexToken = token;
  squidexDomainUrl = 'squidex';

  constructor(private squidex: SquidexDataService) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes(this.squidexDomainUrl)) {
      return this.invokeInternal(req, next, true);
    } else {
      return next.handle(req);
    }
  }

  public invokeInternal(
    req: HttpRequest<any>,
    next: HttpHandler,
    retry: boolean
  ): Observable<HttpEvent<any>> {
    return this.squidex.getToken(next).pipe(
      switchMap((squidexToken: string) => {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${squidexToken}`,
            'X-Flatten': 'true',
            'X-Languages': 'en-us', // we assign "language" hard-coded here. As provider portal doesn't support multi-language. Once we have that, we pull language from the language service.
          },
        });

        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if ((error.status === 403 || error.status === 401) && retry) {
              this.squidexToken.clearBearerToken();

              return this.invokeInternal(req, next, false);
            } else {
              return throwError(error);
            }
          })
        );
      })
    );
  }
}
