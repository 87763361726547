import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ExpandingArticleModule,
  FormControlModule,
  FormGroupModule,
} from '@zipari/design-system';
import { ProvisionerColumnModule } from '../column/provisioner-column.module';
import { ProvisionerComponent } from './provisioner.component';
@NgModule({
  declarations: [ProvisionerComponent],
  exports: [ProvisionerComponent],
  imports: [
    CommonModule,
    RouterModule,
    ExpandingArticleModule,
    FormControlModule,
    FormGroupModule,
    ProvisionerColumnModule,
  ],
})
export class ProvisionerModule {}
