import { Pipe, PipeTransform } from '@angular/core';
import { FormControlConfiguration } from '@zipari/shared-ds-util-form';

@Pipe({
  name: 'showRequiredAsterisk',
})
export class ShowRequiredAsterisk implements PipeTransform {
  transform(
    config: FormControlConfiguration,
    isRequired: boolean,
    isRequiredLabelString: boolean
  ): boolean {
    return (
      isRequired &&
      !isRequiredLabelString &&
      !config.tooltip &&
      (typeof config.enableRequiredAsterisk === 'boolean'
        ? config.enableRequiredAsterisk
        : true)
    );
  }
}

/**
 * Added pipe to show Optional Label text
 */
@Pipe({
  name: 'showOptionalLabel',
})
export class ShowOptionalLabel implements PipeTransform {
  transform(config: FormControlConfiguration, isRequired: boolean): boolean {
    return (
      !isRequired &&
      (typeof config.enableOptionalLabel === 'boolean'
        ? config.enableOptionalLabel
        : false)
    );
  }
}
