import { Directive, HostListener, Input } from '@angular/core';
import { EventInfo } from '@zipari/shared-ds-util-digital-data';
import { LinkConfig } from '@zipari/shared-ds-util-navigation';
import { DigitalDataService } from '../../services/digital-data.service';

@Directive({
  selector: '[dataNavEvent]',
})
export class DigitalDataExternalNavEventDirective {
  @Input() dataNavEvent: Partial<LinkConfig> & Partial<EventInfo>;
  pageName: string;
  constructor(private dds: DigitalDataService) {}

  @HostListener('click')
  clickEvent() {
    if (this.dataNavEvent) {
      const navEventInfo: EventInfo = this.createNavEventInfo(
        this.dataNavEvent
      );
      this.dds.pushEventObject(navEventInfo);
      this.dataNavEvent = null;
    }
  }

  createNavEventInfo(
    linkConfig: Partial<LinkConfig> & Partial<EventInfo>
  ): EventInfo {
    return {
      eventName: linkConfig.eventName || 'External Nav Event',
      eventAction: 'Navigation',
      eventPoints: 200,
      type: 'navigation',
      timeStamp: new Date(),
      effect: `route to ${linkConfig.externalLink} `,
    };
  }
}
