import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule, ModelAttributeModule } from '@zipari/design-system';
import { ZipBusyModule } from '../../../shared/modules/zip-busy';
import { AddNewModalModule } from '../../../shared/modules/add-row-modal/add-new-modal.module';
import { TabViewModule } from '../../shared/modules/tab-view/tab-view.module';
import { DetailTabsModule } from '../../shared/modules/detail-tabs/detail-tabs.module';

import { ManageAccessRequestDetailComponent } from './manage-access-request-detail.component';

@NgModule({
  declarations: [ManageAccessRequestDetailComponent],
  imports: [
    CommonModule,
    ModelAttributeModule,
    DetailTabsModule,
    ButtonModule,
    AddNewModalModule,
    ZipBusyModule,
    TabViewModule,
  ],
  exports: [ManageAccessRequestDetailComponent],
})
export class ManageAccessRequestDetailModule {}
