import {
  AfterContentInit,
  AfterViewChecked,
  Component,
  ContentChildren,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { TemplateRefDirective } from '../../shared/directives/template-ref.directive';
import { TabLabel } from './tabs.constants';

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit, AfterViewChecked {
  @Input() tabLabels: (TabLabel | string)[] = [];
  @Input() tabTemplates: TemplateRef<any>[] = [];
  @Input() selectedIndex = 0;
  @Input() pageTabs: boolean;
  @Input() isCenterAligned: boolean;
  @Input() isBlockDisplay: boolean;
  @Input() isLargeIcon: boolean;
  @Input() isBorderNotRequired: boolean;
  @Input() isChangeTabWithDevices: boolean;
  @Input() hasTabPositionChanged: boolean;
  @Input() showAsBreadcrumbs: boolean;

  @Output() tabSelected: EventEmitter<number> = new EventEmitter<number>();

  @ViewChildren('tabLabelElement') tabLabelElements;

  @ContentChildren(TemplateRefDirective)
  projectedTemplates: QueryList<TemplateRefDirective>;
  projectedTemplatesList: TemplateRefDirective[];

  onSelect(index) {
    this.selectedIndex = index;
    this.resetTabIndices();
    this.tabSelected.emit(index);
  }

  resetTabIndices() {
    this.tabLabelElements.toArray().forEach((tabElement, index) => {
      tabElement.nativeElement.tabIndex = index === this.selectedIndex ? 0 : -1;
    });
  }

  /**
   * handle keyboard focus tabs
   */
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    const activeEle = document.activeElement.parentElement;

    if (event.key === 'ArrowLeft') {
      (activeEle.previousElementSibling?.firstChild as HTMLElement)?.focus();
    }
    if (event.key === 'ArrowRight') {
      (activeEle.nextElementSibling?.firstChild as HTMLElement)?.focus();
    }
  }

  ngAfterContentInit() {
    this.projectedTemplatesList = this.projectedTemplates.toArray();
  }

  ngAfterViewChecked(): void {
    this.resetTabIndices();
  }
}
