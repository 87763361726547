import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '../../notes/button/button.module';
import { IconModule } from '../../notes/icon/icon.module';
import { MessageBannerModule } from '../../notes/message-banner/message-banner.module';
import { FileUploaderButtonComponent } from './file-uploader-button/file-uploader-button.component';
import { FileUploaderDropZoneComponent } from './file-uploader-dropzone/file-upload-dropzone.component';
import { FileUploaderProgressComponent } from './file-uploader-progress/file-uploader-progress.component';
import { FileUploaderComponent } from './file-uploader.component';
import { FileSizePipe } from './pipes/fileSize.pipe';

@NgModule({
  declarations: [
    FileUploaderComponent,
    FileUploaderButtonComponent,
    FileUploaderDropZoneComponent,
    FileUploaderProgressComponent,
    FileSizePipe,
  ],
  imports: [CommonModule, ButtonModule, IconModule, MessageBannerModule],
  exports: [
    FileUploaderComponent,
    FileUploaderButtonComponent,
    FileUploaderDropZoneComponent,
  ],
})
export class FileUploaderModule {}
