import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ControlDropdownModule } from './../dropdown/dropdown.module';
import { StatesComponent } from './states.component';

@NgModule({
  declarations: [StatesComponent],
  imports: [CommonModule, ControlDropdownModule],
  exports: [StatesComponent],
})
export class StatesModule {}
