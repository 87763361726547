import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ModelAttributeModule,
  StatusModule,
  TableModule,
} from '@zipari/design-system';
import { PipesModule } from '@zipari/web-utils';

import { OverviewComponent } from './overview.component';

@NgModule({
  declarations: [OverviewComponent],
  exports: [OverviewComponent],
  imports: [
    CommonModule,
    ModelAttributeModule,
    TableModule,
    StatusModule,
    PipesModule,
  ],
})
export class OverviewModule {}
