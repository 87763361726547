import { Injectable } from '@angular/core';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root',
})
export class NavigatorService {
  constructor(private window: WindowService) {}
  get isIpad() {
    return navigator.userAgent.includes('iPad');
  }
  get isPortrait() {
    return (
      this.window.nativeWindow.innerHeight > this.window.nativeWindow.innerWidth
    );
  }
}
