import { Injectable } from '@angular/core';
import { CheckListItem } from '../manage-portal-users-details.model';
@Injectable({
  providedIn: 'root',
})
export default class SuspendUserService {
  public selectedGroups: CheckListItem[] = [];
  public selectedTaxIds: CheckListItem[] = [];
  public groups: CheckListItem[] = [];
  public taxIds: CheckListItem[] = [];
  public suspensionType: string;
}
