<form-control
  *ngIf="!onlyAutopay"
  [config]="saveMethodConfig"
  [control]="form.get(saveMethodConfig.prop)"
>
</form-control>

<form-control
  *ngIf="saveMethodValue || onlyAutopay"
  [config]="autopayConfig"
  [control]="form.get(autopayConfig.prop)"
>
</form-control>
