<article class="layout--detail" [zipBusy]="busy">
  <article class="layout--detail__content" *ngIf="!!dataLoaded">
    <aside class="layout--detail__aside">
      <ng-container *ngFor="let section of config.sidebar?.sections">
        <div [ngSwitch]="section.format">
          <header class="layout--detail__aside__header" *ngIf="section.label">
            <h6>{{ section.label }}</h6>
          </header>

          <!--                    ERHO: TODO: do this properly by using design system change-->
          <section
            class="patient-detail__aside__attrs"
            *ngSwitchCase="'attribute-list'"
          >
            <div
              class="patient-detail__aside__model-attributes"
              *ngFor="let attribute of section.attributes"
            >
              <model-attribute
                *ngIf="
                  (!attribute.dataAsLabel && !attribute.showIfTrue) ||
                  (attribute.showIfTrue &&
                    getValue(context, attribute).length > 0)
                "
                [context]="context"
                [config]="attribute"
                direction="column"
              ></model-attribute>
              <div *ngIf="attribute.dataAsLabel">
                <label>{{ getValue(context, attribute) }}</label>
              </div>
              <div *ngFor="let nestedAttr of attribute.additionalAttrs">
                <model-attribute
                  [context]="context"
                  [config]="nestedAttr"
                ></model-attribute>
              </div>
            </div>
          </section>
        </div>
      </ng-container>
    </aside>

    <main class="layout--detail__main">
      <header class="layout--detail__main__breadcrumb">
        <zip-button
          [config]="config.backButton"
          (zipButtonClicked)="routeBack()"
        ></zip-button>
      </header>
      <!-- ERHO: TODO ==> Fix this later to be from config -->
      <section
        class="patient-detail_patient-name layout--list__header"
        *ngIf="context.first_name"
      >
        <h3 class="layout--detail__main--header">
          {{ context.first_name }} {{ context.last_name }}
        </h3>
        <zip-button
          *ngIf="context.status == 'pending'"
          [config]="config.actionButton"
          (zipButtonClicked)="navigateSubNav($event)"
        ></zip-button>
      </section>
      <tab-view
        [config]="config.tabs"
        [data]="context"
        [selectedTab]="0"
      ></tab-view>
      <div
        class="manage-access-request-detail_tax-id-table-container"
        *ngIf="!replaceUserWithTaxId"
      >
        <div class="manage-access-request-detail_tax-id-table-header"></div>
        <div class="grid">
          <div
            *ngFor="let item of context.access_requested_for"
            class="grid__item"
          >
            {{ item.tax_id }}
          </div>
        </div>
      </div>
      <!-- ERHO: TODO ==>Will update to be from config. Just needs to get out for now -->
      <section
        class="patient-detail_patient-name layout--list__header"
        *ngIf="!context.first_name"
      >
        <h3 class="layout--detail__main--header">
          {{ context.first_name }} {{ context.last_name }}
        </h3>

        <div class="patient-detail__action-btn">
          <zip-button
            [config]="config.actionButton"
            (zipButtonClicked)="navigateSubNav($event)"
          ></zip-button>
        </div>
      </section>

      <section class="layout--detail__main__section"></section>
    </main>
    <add-or-edit-modal
      *ngIf="config && showModal"
      [config]="config"
      [data]="data"
      (cancel)="closeModal()"
      (save)="saveModal($event)"
    >
    </add-or-edit-modal>
  </article>
</article>
