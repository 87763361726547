<div
  *ngIf="state == 'active'"
  [ngClass]="{ clicked: clicked }"
  class="item-cell"
>
  <div
    class="text-fields"
    *ngIf="config?.display_fields"
    [ngClass]="{ selected: selected }"
  >
    <div class="text-field" *ngFor="let item of config?.display_fields">
      {{ data[item?.prop] }}
      <br />
    </div>
  </div>
  <br />
  <form-group
    *ngIf="config?.form_fields"
    [configs]="config?.form_fields"
    [form]="formGroup"
  >
  </form-group>
</div>
