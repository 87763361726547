import { Pipe, PipeTransform } from '@angular/core';
import { EventInfo } from '@zipari/shared-ds-util-digital-data';
import { LinkConfig } from '@zipari/shared-ds-util-navigation';

@Pipe({ name: 'getEventRoute' })
export class FooterEventPipe implements PipeTransform {
  transform(route): Partial<LinkConfig> & Partial<EventInfo> {
    return route ? { externalLink: route } : null;
  }
}
