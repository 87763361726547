import { Component, Input } from '@angular/core';
import { ProvisionerService } from '../provisioner/provisioner.service';

@Component({
  styleUrls: ['./view-user-provisioning.component.scss'],
  selector: 'view-provisioning',
  templateUrl: './view-user-provisioning.component.html',
})
export class ViewUserProvisioningComponent {
  @Input() title: string;
  @Input() state: any;
  @Input() config: any;

  constructor(public provisioner: ProvisionerService) {}

  print(event): void {
    window.print();
  }
}
