import { Injectable } from '@angular/core';
import { camelToSnakeCase } from '../../../shared/utilities/case-conversion.utils';
import {
  CreateUserConfig,
  contactInfo,
  practiceInfo,
} from './managePortalUsersCreateUser.model';
import { createUserConstants } from './manage-portal-users-create-user.constants';
import {
  getAuth0GroupPayload,
  getOktaGroupPayload,
} from './request-payload-format-service.helper';

@Injectable({
  providedIn: 'root',
})
export class RequestPayloadFormatService {
  getCreateUserPayload(
    createUserPayload: any,
    allowMultipleGroup: boolean,
    idp?: string
  ): CreateUserConfig {
    let createUserRequestPayload: CreateUserConfig = {
      firstName: createUserPayload?.first_name,
      lastName: createUserPayload?.last_name,
      role: createUserPayload?.role,
      organization: createUserPayload?.organization,
      jobTitle: createUserPayload?.job_title,
      emailAddress: createUserPayload?.email_address,
      phoneNumber: createUserPayload?.phone_number,
      phoneExtension: createUserPayload?.phone_extension,
      address1: createUserPayload?.address_1,
      address2: createUserPayload?.address_2,
      city: createUserPayload?.city,
      state: createUserPayload?.state,
      zipCode: createUserPayload?.zip_code,
      userName: createUserPayload?.email_address,
      accessRequestId: createUserPayload?.access_request_id,
      contactInformation: this.getCreateUserContactPayload(createUserPayload),
      practiceInformation: this.getCreateUserPracticePayload(createUserPayload),
    };

    const { okta, groups, groupIds } = createUserConstants;

    if (createUserPayload?.group_id || createUserPayload?.multiple_group) {
      const groupKey = idp === okta ? groups : groupIds;
      const groupValue = this.payloadMethods[idp]
        ? this.payloadMethods[idp](allowMultipleGroup, createUserPayload)
        : [];

      createUserRequestPayload = {
        ...createUserRequestPayload,
        [groupKey]: groupValue,
      };
    }

    return camelToSnakeCase(createUserRequestPayload);
  }

  private payloadMethods = {
    okta: getOktaGroupPayload,
    auth0: getAuth0GroupPayload,
  };

  getCreateUserContactPayload(createUserPayload: any): contactInfo {
    const createUserContactRequestPayload: contactInfo = {
      firstName: createUserPayload?.contact_first_name,
      lastName: createUserPayload?.contact_last_name,
      email: createUserPayload?.contact_email_address,
      phoneNumber: createUserPayload?.contact_phone,
      phoneExtension: createUserPayload?.contact_phone_extension,
      fax: createUserPayload?.contact_fax,
    };

    return createUserContactRequestPayload;
  }

  getCreateUserPracticePayload(createUserPayload: any): practiceInfo {
    const createUserPracticeRequestPayload: practiceInfo = {
      practiceName: createUserPayload?.practice_name,
      practiceSpecialty: createUserPayload?.practice_specialty,
      department: createUserPayload?.practice_department,
      address1: createUserPayload?.practice_address_1,
      address2: createUserPayload?.practice_address_2,
      city: createUserPayload?.practice_city,
      state: createUserPayload?.practice_state,
      zipCode: createUserPayload?.practice_zip_code,
      officeManager: createUserPayload?.practice_office_manager,
      email: createUserPayload?.practice_email,
      claimNumber: createUserPayload?.practice_claim_number,
      taxIds: createUserPayload?.practice_tax_ids?.split(','),
      phoneNumber: createUserPayload?.practice_phone_number,
      phoneExtension: createUserPayload?.practice_phone_extension,
      npi: createUserPayload?.practice_npi,
    };

    return createUserPracticeRequestPayload;
  }
}
