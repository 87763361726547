<div
  class="form-control__wrapper"
  [ngClass]="{ 'form-control__wrapper--flat': direction === 'filter' }"
>
  <label class="form-control__label">
    <p class="t-bold">{{ config.label }}</p>
  </label>

  <div class="u-relative" [formGroup]="group">
    <input
      class="form-control"
      [ngClass]="{ 'form-control--icon-right': speechRecognition.recognition }"
      [formControl]="group.get(config.prop)"
    />

    <div
      *ngIf="speechRecognition.recognition"
      class="form-control__icon form-control__icon--right"
    >
      <icon
        name="mic"
        [config]="{ fill: 'fc-icon-color', type: 'pointer' }"
        (click)="startSpeechRecognition()"
      >
      </icon>
    </div>
  </div>
</div>

<section @fade *ngIf="speechRecognition.isListening" class="SpeechRecognition">
  <div class="SpeechRecognition__ripple" (click)="stopSpeechRecognition()">
    <icon
      class="SpeechRecognition__icon"
      name="mic"
      [config]="{ size: 'large', type: 'pointer' }"
    >
    </icon>
  </div>

  <h1>
    <ng-container
      *ngIf="!speechRecognition.text && !speechRecognition.interimText"
      >{{ config.prompt || 'Say something' }}</ng-container
    >
    {{ speechRecognition.text }} {{ speechRecognition.interimText }}
  </h1>
</section>
