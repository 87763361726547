<div [zipBusy]="busy">
  <div *ngIf="!!dataLoaded">
    <div
      class="id-card__description"
      *ngIf="config.description"
      [innerHTML]="config.description"
    ></div>
    <div class="coverage__period" *ngIf="config.filters">
      <form-control
        class="filter"
        *ngIf="
          formGroup.get(config.filters.prop) &&
          config.filters?.options.length > 0
        "
        [group]="formGroup"
        [control]="formGroup.get(config.filters.prop)"
        [config]="config.filters"
      >
      </form-control>
    </div>
    <div
      [zipBusy]="busy"
      class="id-card__border"
      *ngFor="let filteredIdCard of filteredIdCardswithConfig"
    >
      <header class="id-card__header">
        <h6 class="header__text">
          {{ filteredIdCard.config?.title }}
        </h6>
      </header>
      <id-card-view
        [idCards]="filteredIdCard.idCards"
        [isError]="error"
        [section]="filteredIdCard.config"
        [documentApiEndpoint]="config.documentApiEndpoint"
      ></id-card-view>
    </div>
  </div>
</div>
