<div *ngFor="let accordion of accordionConfig?.accordions">
  <accordion
    [header]="accordion.header"
    [card]="false"
    [content]="accordionFieldsTemplate"
  ></accordion>

  <ng-template #accordionFieldsTemplate>
    <div
      class="AccordionContent"
      *ngFor="let field of accordion.accordionFields"
    >
      <div class="t-bold AccordionItem">
        <div class="AccordionItem__Title" (click)="documentViewed(field)">
          <icon
            *ngIf="field.icon"
            class="AccordionItem__Icon"
            [name]="field.icon"
          >
          </icon>

          <p class="t-bold t-link" [innerHTML]="field.title"></p>
        </div>

        <p [innerHTML]="field.description"></p>
      </div>
    </div>
  </ng-template>
</div>
