import { KeyValue } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { PaginatorConfiguration } from '@zipari/design-system';
import { SquidexConfig } from '@zipari/shared-data-access-squidex';
import { stringBuilder } from '@zipari/web-utils';
import { DocumentCenterTab } from '../util/document-center.model';

export interface DateFilter {
  end: string;
  start: string;
}
/**
 * For setFilters template method array reduce function
 * reducer
 */
export const formatFilters =
  (filters: Record<string, unknown>) =>
  (
    accumulator: KeyValue<string, unknown>[],
    currentValue: string
  ): KeyValue<string, unknown>[] => {
    if (filters[currentValue]) {
      const additionalFilters =
        currentValue === 'date_received'
          ? transformDateKey(filters[currentValue] as DateFilter)
          : [{ key: currentValue, value: filters[currentValue] }];

      accumulator = [...accumulator, ...additionalFilters];
    }

    return accumulator;
  };

const transformDateKey = (
  dateReceived: DateFilter
): KeyValue<string, string>[] => {
  // allow for single date searches
  // Eg. if only start, search provided start to the year 3000
  const { start = '1900-01-01', end = '3000-01-01' } = dateReceived;
  const dateFilters = [
    {
      key: 'date_received_after',
      value: start,
    },
    {
      key: 'date_received_before',
      value: end,
    },
  ];

  return dateFilters.filter(({ value }) => !!value);
};

export const getDocumentsApiParams = (
  activeTab: DocumentCenterTab,
  paginator: PaginatorConfiguration,
  filtersFormValue?: Record<string, unknown> | null,
  sortKey?: string | null
): HttpParams => {
  let params = new HttpParams()
    .set('document_category', activeTab.prop)
    .set('page', paginator.currentPage);

  if (paginator.rowsPerPage) {
    params = params.append('page_size', paginator.rowsPerPage);
  }

  if (sortKey) {
    params = params.append('ordering', sortKey);
  }

  if (filtersFormValue) {
    Object.keys(filtersFormValue)
      .reduce(formatFilters(filtersFormValue), [])
      .forEach((filter: KeyValue<string, unknown>) => {
        params = params.append(
          filter['key'],
          filter['value'] as string | number | boolean
        );
      });
  }

  return params;
};

export const downloadDocument = (
  squidexConfig: SquidexConfig,
  activeTab: DocumentCenterTab,
  document: Record<string, unknown>
): void => {
  let url: string;
  if (
    squidexConfig?.enabled &&
    squidexConfig?.enableForModule?.resourcesFormsDocuments
  ) {
    window.open(document['docRef'] as string, '_blank');
  } else if (activeTab.targetRouteObj) {
    const targetRoute = activeTab.targetRouteObj;

    if (targetRoute.externalUrl) {
      window.open(document[targetRoute.externalUrl] as string, '_blank');
    } else if (targetRoute.customUrl) {
      url = stringBuilder(targetRoute.customUrl, document);
      window.open(url, '_blank');
    }
  }
};
