<div class="modal-container"></div>
<section
  [zipBusy]="busy"
  class="modal custom--modal modal--panel"
  @slideRight
  *ngIf="formReady && activeWorkflow && dropdownReady"
  [ngClass]="{
    'modal__body--small': activeWorkflow.size === 'small',
    'modal__body--large': activeWorkflow.size === 'large',
    'modal__body--medium': activeWorkflow.size === 'medium'
  }"
>
  <ng-container [ngSwitch]="view">
    <!-- SUCCESS VIEW -->
    <ng-container *ngSwitchCase="'success'">
      <header class="modal__header">
        <p class="t-data t-bold">{{ mainConfig?.messages?.success?.header }}</p>
      </header>

      <section class="modal__body">
        <div class="modal__message">
          <h4 class="t-bold">{{ mainConfig?.messages?.success?.title }}</h4>
          <p class="t-data">{{ mainConfig?.messages?.success?.text }}</p>
        </div>

        <div class="model-attributes" *ngIf="mainConfig.attributes">
          <ng-container *ngFor="let attribute of attributes.attributes">
            <h6
              *ngIf="attribute?.title; else modelAttribute"
              class="add-row-attribute__title t-bold"
            >
              {{ attribute.title }}
            </h6>

            <ng-template #modelAttribute>
              <model-attribute
                [attr.direction]="
                  attribute?.direction ? attribute?.direction : null
                "
                [config]="attribute"
                [context]="successResponse"
              >
              </model-attribute>
            </ng-template>
          </ng-container>
        </div>
      </section>
    </ng-container>

    <!-- ERROR VIEW -->
    <ng-container *ngSwitchCase="'error'">
      <header class="modal__header">
        <p class="t-data t-bold">{{ config?.messages?.error }}</p>
      </header>

      <section class="modal__body">
        <div class="modal__message">
          <h4 class="t-bold">There was an error processing your request.</h4>
        </div>
      </section>
    </ng-container>

    <!-- FORM VIEW IS DEFAULT -->
    <ng-container *ngSwitchDefault>
      <header class="modal__header">
        <p class="t-data t-bold">{{ activeWorkflow.form.header }}</p>
      </header>

      <section class="modal__body">
        <div *ngIf="activeWorkflow?.form?.groups">
          <!--                        Test-->
          <div
            class="form-group"
            *ngFor="let group of activeWorkflow.form.groups; let idx = index"
          >
            <div *ngIf="group.type === 'accordion'">
              <header class="form-group__header">
                <accordion
                  [isOpen]="idx === openAccordionIdx || openAccordionIdx == -1"
                  [header]="group.header"
                  [content]="contentTemplate"
                ></accordion>
              </header>
            </div>

            <ng-template #contentTemplate>
              <div
                *ngFor="let section of group.sections"
                class="form-type__accordion"
              >
                <div>
                  <h6 class="t-bold title">{{ section.header }}</h6>
                </div>
                <form-group
                  [configs]="section.controls"
                  [form]="addRowForm"
                  (fileUploaded)="onFileUploaded($event)"
                  (fileRemoved)="onFileRemoved($event)"
                  (pendingAttachments)="onPendingAttachments($event)"
                >
                </form-group>
              </div>
            </ng-template>

            <!--- ERHO: TODO this is bad fix this later -->
            <div *ngIf="group.type === 'default' || group.type === 'workflow'">
              <header class="form-group__header">
                <h6 class="t-bold title">{{ group.header }}</h6>
                <p *ngIf="group.subheader" class="t-data">
                  {{ group.subheader }}
                </p>
              </header>
              <div *ngFor="let section of group.sections">
                <div [ngSwitch]="section.type">
                  <div *ngSwitchCase="'form'" class="form-type__form">
                    <h6 class="t-bold title">{{ section.header }}</h6>
                    <p
                      class="add-or-edit-modal_subheader"
                      *ngIf="section.subheader"
                      class="t-data"
                    >
                      {{ section.subheader }}
                    </p>
                    <div
                      *ngIf="section.subheader"
                      class="add-or-edit-modal_subheader-line-separator"
                    ></div>
                    <form-group
                      class="ModalForm"
                      [configs]="section.controls"
                      [form]="addRowForm"
                      (fileUploaded)="onFileUploaded($event)"
                      (fileRemoved)="onFileRemoved($event)"
                      (pendingAttachments)="onPendingAttachments($event)"
                    ></form-group>
                  </div>
                  <div *ngSwitchCase="'table'">
                    <div class="modal__body-table">
                      <message-banner
                        *ngIf="error"
                        (actionClicked)="bannerClicked($event)"
                        [config]="notification"
                      ></message-banner>
                      <zip-table
                        *ngIf="loaded"
                        [options]="section.table"
                        [data]="data[section.targetData]"
                        (selectedRows)="handleSelectedRows($event)"
                        (selectedRadioRow)="selectedRadioRow($event)"
                        (dataChanged)="dataChanged($event)"
                      ></zip-table>
                    </div>
                  </div>
                  <!-- ERHO: TODO: fix this crap -->
                  <div
                    class="selected-row__review"
                    *ngSwitchCase="'selectedRowReview'"
                  >
                    <div *ngFor="let selection of selectedRows">
                      <h5 class="t-bold">
                        {{ selection.row.name.first_name }}
                        {{ selection.row.name.last_name }}
                        <span class="selected--row__header--required">*</span>
                      </h5>
                      <form-group
                        [configs]="section.controls"
                        [form]="addRowForm"
                        (fileUploaded)="onFileUploaded($event)"
                        (fileRemoved)="onFileRemoved($event)"
                        (pendingAttachments)="onPendingAttachments($event)"
                      ></form-group>
                    </div>
                  </div>
                  <div
                    class="selected-row__review"
                    *ngSwitchCase="'deleteReview'"
                  >
                    <header class="form-group__header">
                      <accordion
                        [icon]="section.fieldsAccordianIcon"
                        [isOpen]="
                          idx === openAccordionIdx || openAccordionIdx == -1
                        "
                        [header]="section.fieldsAccordianHeader"
                        [content]="selectedList"
                      >
                      </accordion>
                    </header>
                    <ng-template #selectedList>
                      <div
                        *ngFor="
                          let selection of selectedRows;
                          let index = index
                        "
                      >
                        <div class="row">
                          <div class="column">
                            <h6 class="t-bold">
                              {{
                                getObjectProperty(
                                  selection.row,
                                  section.fields.firstName
                                )
                              }}
                              {{
                                getObjectProperty(
                                  selection.row,
                                  section.fields.lastName
                                )
                              }}
                            </h6>
                            <p
                              *ngIf="activeWorkflowIdx == 0"
                              class="t-bold isCursor t-link"
                              (click)="remove(index)"
                            >
                              Remove
                            </p>
                          </div>
                          <div class="column">
                            <div
                              *ngFor="
                                let item of section.fields.rightPanel;
                                let index = index
                              "
                            >
                              <p class="t-data t-bold">{{ item.label }}</p>
                              <p class="t-data">
                                {{
                                  getObjectProperty(selection.row, item.prop) ||
                                    'N/A'
                                }}
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    <header class="form-group__header">
                      <accordion
                        [icon]="section.deleteFormAccordianIcon"
                        [isOpen]="
                          idx === openAccordionIdx || openAccordionIdx == -1
                        "
                        [header]="section.deleteFormAccordianHeader"
                        [content]="TerminationForm"
                      ></accordion>
                    </header>
                    <ng-template #TerminationForm>
                      <div *ngIf="activeWorkflowIdx == 0">
                        <form-group
                          [configs]="section.controls"
                          [form]="addRowForm"
                        >
                        </form-group>
                      </div>
                      <div *ngIf="activeWorkflowIdx != 0">
                        <div *ngFor="let row of controls[0]">
                          <p
                            class="t-data t-bold"
                            ng-reflect-ng-class="[object Object]"
                          >
                            {{ row.label }}
                          </p>
                          <p
                            class="t-data"
                            ng-reflect-ng-class="[object Object]"
                          >
                            {{ addRowForm.controls[row.prop].value }}
                          </p>
                          <br />
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ng-container>
    <footer class="modal__footer" *ngIf="activeWorkflow?.form?.footerButtons">
      <div class="modal__footer--body">
        <div
          class="button__left"
          *ngIf="activeWorkflow?.form?.footerButtons?.buttonLeft"
        >
          <zip-button
            class="cancel__btn button__left"
            level="low"
            [content]="activeWorkflow.form.footerButtons.buttonLeft.content"
            (click)="
              handleBtnClick(activeWorkflow.form.footerButtons.buttonLeft)
            "
            (keydown.enter)="
              handleBtnClick(activeWorkflow.form.footerButtons.buttonLeft)
            "
          ></zip-button>
        </div>
        <div
          class="button__right"
          *ngIf="activeWorkflow?.form?.footerButtons?.buttonRight"
        >
          <zip-button
            class="submit__btn"
            level="high"
            [content]="activeWorkflow.form.footerButtons.buttonRight.content"
            (click)="
              handleBtnClickWithDisable(
                activeWorkflow.form.footerButtons.buttonRight,
                getButtonStatus()
              )
            "
            (keydown.enter)="
              handleBtnClickWithDisable(
                activeWorkflow.form.footerButtons.buttonRight,
                getButtonStatus()
              )
            "
            [disabled]="getButtonStatus()"
          >
          </zip-button>
        </div>
        <div
          class="button__right"
          *ngIf="activeWorkflow?.form?.footerButtons?.buttonMiddle"
        >
          <zip-button
            class="submit__btn"
            level="high"
            [content]="activeWorkflow.form.footerButtons.buttonMiddle.content"
            (click)="
              handleBtnClickWithDisable(
                activeWorkflow.form.footerButtons.buttonMiddle,
                getButtonStatus()
              )
            "
            (keydown.enter)="
              handleBtnClickWithDisable(
                activeWorkflow.form.footerButtons.buttonMiddle,
                getButtonStatus()
              )
            "
            [disabled]="getButtonStatus()"
          >
          </zip-button>
        </div>
      </div>
    </footer>
  </ng-container>
</section>
