import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AddressPipe } from './address.pipe';
import { BuildQueryPipe } from './build-query.pipe';
import { DoesListHaveValuesPipe } from './does-list-have-values.pipe';
import { EqualityFilterPipe } from './equality-filter.pipe';
import { FileSizePipe } from './fileSize.pipe';
import { FullNamePipe } from './full-name.pipe';
import { GetValuePipe } from './get-value.pipe';
import { IsNotEmptyPipe } from './is-not-empty.pipe';
import { JoinPipe } from './join.pipe';
import { MaskPipe } from './mask.pipe';
import { PhoneNumberPipe } from './phone-number.pipe';
import { ReadMorePipe } from './read-more.pipe';
import { SafeNumberPipe } from './safe-number.pipe';
import { StringBuilderPipe } from './string-builder.pipe';
import { UrlsToAnchorsPipe } from './urls-to-anchors.pipe';

@NgModule({
  declarations: [
    AddressPipe,
    BuildQueryPipe,
    DoesListHaveValuesPipe,
    EqualityFilterPipe,
    FullNamePipe,
    GetValuePipe,
    JoinPipe,
    MaskPipe,
    PhoneNumberPipe,
    ReadMorePipe,
    SafeNumberPipe,
    StringBuilderPipe,
    FileSizePipe,
    IsNotEmptyPipe,
    UrlsToAnchorsPipe,
  ],
  imports: [CommonModule],
  exports: [
    AddressPipe,
    BuildQueryPipe,
    DoesListHaveValuesPipe,
    EqualityFilterPipe,
    FullNamePipe,
    GetValuePipe,
    JoinPipe,
    MaskPipe,
    PhoneNumberPipe,
    ReadMorePipe,
    SafeNumberPipe,
    StringBuilderPipe,
    FileSizePipe,
    IsNotEmptyPipe,
    UrlsToAnchorsPipe,
  ],
})
export class PipesModule {}
