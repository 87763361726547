import { Injectable } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';
import {
  ConfirmationModalComponent,
  ConfirmationModalConfig,
} from '../components/confirmation-modal/confirmation-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private overlay: Overlay) {}

  cancelSubscription: Subscription;
  confirmSubscription: Subscription;

  public presentModal(
    config: ConfirmationModalConfig,
    context,
    confirmCallback?: Function,
    cancelCallback?: Function
  ) {
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: ['modal__mask-modal'],
    });
    const modalComponent = new ComponentPortal(ConfirmationModalComponent);
    const containerRef = overlayRef.attach(modalComponent);

    // Handle modal component inputs and outputs
    containerRef.instance.config = config;
    containerRef.instance.context = context;

    this.confirmSubscription = containerRef.instance.confirm.subscribe(() => {
      if (confirmCallback) {
        confirmCallback();
      }
      overlayRef.detach();
      this.confirmSubscription.unsubscribe();
    });

    this.cancelSubscription = containerRef.instance.cancel.subscribe(() => {
      if (cancelCallback) {
        cancelCallback();
      }
      overlayRef.detach();
      this.cancelSubscription.unsubscribe();
    });
  }
}
