<form [zipBusy]="busy" [formGroup]="group" *ngIf="group && config">
  <!-- <div class="forms_container" > -->
  <div class="form-control__label__wrapper">
    <div class="left">
      <label class="form-control__label t-bold label_flex">
        <p class="t-data t-bold">{{ config?.label }}</p>
        <p *ngIf="config.validators" class="t-data t-bold spaced-star">
          {{ '*' }}
        </p>
      </label>
      <label class="form-control__label">
        <p [innerHTML]="config?.subheader" class="subheader"></p>
      </label>
    </div>
    <div
      *ngIf="!group.get(config.prop)?.value"
      class="right isCursor"
      (click)="toggleFlyout()"
    >
      <i class="icon icon--medium">search</i>
    </div>
    <div
      *ngIf="group.get(config.prop)?.value"
      class="right isCursor"
      (click)="remove()"
    >
      <p class="remove">Remove</p>
    </div>
  </div>
  <div *ngIf="!group.get(config.prop)?.value" class="form-container">
    <div class="form-control form-control--dropdown">
      <select role="listbox" formControlName="{{ config.prop }}">
        <option value="default">Select One</option>
        <option *ngFor="let c of config.options" [ngValue]="c.value">
          {{ c.label }}
        </option>
      </select>
      <div class="u-flex u-alignCenter u-justifyEnd u-fillRemaining">
        <icon
          [config]="{ format: 'svg', size: 'small' }"
          [name]="config.icon || 'carat_down'"
          class="dropdown__chevron"
        ></icon>
      </div>
    </div>
  </div>
  <div *ngIf="group.get(config.prop)?.value">
    <div class="left">
      <label
        *ngIf="config.displayFields?.name && group.get(config.prop)?.value"
        class="form-control__label"
      >
        <p class="t-data t-bold">
          {{ group.get(config.prop)?.value[config.displayFields.name] }}
        </p>
      </label>
    </div>
    <!-- load this from config -->
    <div class="left">
      <label
        *ngIf="config.displayFields?.address && group.get(config.prop)?.value"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Address:</p>
        <p
          *ngIf="!group.get(config.prop)?.value[config.displayFields?.address]"
        >
          {{ noSpace }}
        </p>
        <div
          class="address"
          *ngIf="group.get(config.prop)?.value[config.displayFields?.address]"
        >
          {{
            group.get(config.prop)?.value[config.displayFields.address][
              'street_name_1'
            ]
          }}<br
            *ngIf="
              group.get(config.prop)?.value[config.displayFields?.address][
                'street_name_1'
              ]
            "
          />
          {{
            group.get(config.prop)?.value[config.displayFields.address][
              'street_name_2'
            ]
          }}<br
            *ngIf="
              group.get(config.prop)?.value[config.displayFields?.address][
                'street_name_2'
              ]
            "
          />
          {{
            group.get(config.prop)?.value[config.displayFields.address][
              'city_name'
            ]
          }},
          {{
            group.get(config.prop)?.value[config.displayFields.address]['state']
          }}
          {{
            group.get(config.prop)?.value[config.displayFields.address][
              'zip_code'
            ]
          }}
        </div>
      </label>
      <label
        *ngIf="config.displayFields?.group_npi && group.get(config.prop)?.value"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Group NPI:</p>
        <p>
          {{
            group.get(config.prop)?.value[config.displayFields.group_npi] ||
              noSpace
          }}
        </p>
      </label>
      <label
        *ngIf="config.displayFields?.npi && group.get(config.prop)?.value"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Group NPI:</p>
        <p>
          {{
            group.get(config.prop)?.value[config.displayFields.npi] || noSpace
          }}
        </p>
      </label>
      <label
        *ngIf="config.displayFields?.id_number && group.get(config.prop)?.value"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">ID Number:</p>
        <p>
          {{
            group.get(config.prop)?.value[config.displayFields.id_number] ||
              noSpace
          }}
        </p>
      </label>
      <label
        *ngIf="config.displayFields?.specialty && group.get(config.prop)?.value"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Primary Specialty:</p>
        <p
          *ngIf="
            group.get(config.prop)?.value[config.displayFields?.specialty]
              .length
          "
        >
          {{
            group.get(config.prop)?.value[config.displayFields.specialty][0][
              'name'
            ]
          }}
        </p>
        <p
          *ngIf="
            !group.get(config.prop)?.value[config.displayFields?.specialty]
              ?.length
          "
        >
          {{ noSpace }}
        </p>
      </label>
    </div>
  </div>
  <!-- </div> -->
  <div
    *ngIf="
      group.get(config.prop)?.invalid &&
      (group.get(config.prop).dirty || group.get(config.prop).touched)
    "
  >
    <div class="error" *ngIf="group.get(config.prop).errors">
      <icon
        name="warning"
        style="height: 1.125rem; padding-left: 1rem; padding-right: 0.5rem"
        ><i
          class="icon icon--font ng-star-inserted icon--small"
          style="color: red"
        >
          warning
        </i>
      </icon>
      <p class="t-caption t-error">
        {{ config.errorMessage || 'Please enter a valid input.' }}
      </p>
    </div>
  </div>
</form>

<modal
  #flyout
  *ngIf="showModal"
  [config]="config?.flyout"
  [condition]="showModal"
  (cancel)="toggleFlyout()"
>
  <div body>
    <div class="header-associate">
      <p>
        {{ config?.flyout?.subheader }}
      </p>
    </div>
    <zip-table
      *ngIf="loaded"
      (selectedRadioRow)="selectedRadioRow($event)"
      [options]="config?.flyout.table"
    >
    </zip-table>
  </div>
  <div footer>
    <zip-button
      [disabled]="!selectedItem"
      level="high"
      [content]="config?.flyout?.submitButton"
      (click)="add()"
    ></zip-button>
  </div>
</modal>
