import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false,
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], filterForm): any[] {
    if (!filterForm.control.value) return items;

    return items.filter(
      (item: any) =>
        item.data[filterForm.prop]?.toLowerCase() ===
        filterForm.control.value?.toLowerCase()
    );
  }
}
