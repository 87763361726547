export const stateProps = {
  practiceInformationState: 'practice_state',
  state: 'state',
};

export const preference_group = {
  ProviderCommunicationPreferences: 'ProviderCommunicationPreferences',
  ProviderLanguagePreference: 'ProviderLanguagePreference',
};

export const preferenceTypeShortName = {
  preferredContactMethod: 'Preferred Contact Method',
  bestTimeToContact: 'Best Time to Contact',
  languagesSpoken: 'Languages Spoken',
  preferredLanguage: 'Preferred Language',
};

export const SuspendedStatus = 'suspended';

export const preferencePropName = {
  preferredContactMethod: 'preferred_contact_method',
  bestContactTime: 'best_contact_time',
  languageSpoken: 'language_spoken',
  preferredLanguage: 'preferred_language',
};

export const SuspendUserConstants = {
  suspendTypeProp: 'suspensionType',
  managePortalUserDetail: 'manage-portal-users-detail',
  suspendUser: 'suspendUser',
  review: 'review',
  selectGroups: 'selectGroups',
};

export enum UserSuspensionType {
  ENTIRELY = 'entirely',
  PARTIALLY = 'partially',
}
export const ActivateUserConstants = {
  managePortalUserDetail: 'manage-portal-users-detail',
  activateUser: 'activateUser',
  review: 'review',
};

export const UserStatus = {
  activate: 'activate',
  suspend: 'suspend',
};

export const ManagePortalUsersDetailConstant = {
  userNaviagteTo: 'user_navigate_to',
  providerPortal: 'provider-portal',
  managePortalUsers: 'manage-portal-users',
  providerAdmins: 'provider-admins',
  manageAccessRequests: 'manage-access-requests',
};

export const userProfilePageName = 'user-profile';
