export const MARKET_SEGMENTS = {
  INDIVIDUAL: 'individual',
  MEDICARE: 'medicare',
  SMALL_GROUP: 'small-group',
  LARGE_GROUP: 'large-group',
};

export const GLOBAL_URLS = {
  AUTHORIZATIONS: 'authorizations',
  BENEFITS: 'benefits',
  CLAIMS: 'claims',
  CONFIG_MANAGER: 'config-manager',
  DASHBOARD: 'dashboard',
  OLD_DASHBOARD: 'old-dashboard',
  DOCUMENTS: 'documents',
  LOGIN: 'login',
  MESSAGES: 'messages',
  NOTIFICATIONS: 'notifications',
  REGISTER: 'register',
  PROFILE: 'profile',
  SETTINGS: 'settings',
  FORGOT_PASSWORD: 'forgot-password',
  TERMSANDCONDITIONS: 'terms-and-conditions',
  RESET_PASSWORD: 'reset-password',
  SESSION_EXPIRED: 'session-expired',
  SUPPORT: 'support',
  NEW_DASHBOARD: 'new-dashboard',
};

export const BROKER_PORTAL_URLS = {
  APP: 'broker-portal',
  BASE: 'broker-portal',
  URLS: {
    AGENCIES: 'agencies',
    APPLICATIONS: 'applications',
    BROKERS: 'brokers',
    CLIENTS: 'clients',
    COMMISSIONS: 'commissions',
    CONTACTS: 'contacts',
    ENROLLMENT: 'enrollment',
    GROUPS: 'groups',
    MEMBERS: 'members',
    POLICIES: 'policies',
    PROSPECTS: 'prospects',
    QUOTES: 'quotes',
    CREATE_QUOTE: 'create-quote',
    RENEWALS: 'renewals',
  },
};

export const EMPLOYER_PORTAL_URLS = {
  APP: 'employer-portal',
  BASE: 'employer-portal',
  URLS: {
    EMPLOYEES: 'employees',
    CLAIMS: 'claims',
    BILLING: 'billing',
    REPORTS: 'reports',
    DOCUMENTS: 'documents',
  },
};

export const MEMBER_PORTAL_URLS = {
  APP: 'member-portal',
  BASE: 'member-portal',
  URLS: {
    ACCOUNT: 'account',
    BENEFITS: 'benefits',
    GROUP_ADMIN: 'group-admin',
    ID_CARDS: 'id-cards',
    MEMBER_LINKING: 'member-linking',
    ONBOARDING: 'onboarding',
    PAYMENTS: 'payments',
    PLAN_DOCUMENTS: 'plan-documents',
    WHATS_COVERED: 'whats-covered',
  },
};

export const PROVIDER_PORTAL_URLS = {
  APP: 'provider-portal',
  BASE: 'provider-portal',
  URLS: {
    AUTH: 'authentication',
    REGISTER: 'registration',
    FORGOT_PASSWORD: 'forgot-password',
    RESET_PASSWORD: 'reset-password',
    OAUTH: 'oauth',
    REQUESTACCESS: 'request-access',
    TERMSANDCONDITIONS: 'terms-and-conditions',
    PATIENTS: 'patients',
    CLAIMS: 'claims',
    ELIGIBILITY: 'eligibility',
    MESSAGES: 'messages',
    PORTAL_USERS: 'portal-users',
    PRACTITIONERS: {
      BASE: 'practitioners',
      PROFILE: 'profile',
    },
    REFERRALS: 'referrals',
    SITE_LOCATIONS: {
      BASE: 'site-locations',
    },
    PROVISIONING: {
      BASE: 'user-provisioning',
      EDIT: 'edit',
      VIEW: 'view',
    },
    CREATE_REFERRAL: {
      BASE: 'create-referral',
    },
    CREATE_AUTHORIZATION: {
      BASE: 'create-authorization',
    },
    CREATE_CLAIM: {
      BASE: 'create-claim',
    },
    PREGNANCY_RISK_ASSESSMENT: {
      BASE: 'pregnancy-risk-assessment',
    },
    CLAIM_APPEAL: {
      BASE: 'claim-appeal',
    },
    CLAIM_RECONSIDERATION: {
      BASE: 'claim-reconsideration',
    },
    CLAIM_REDETERMINATION: {
      BASE: 'claim-redetermination',
    },
    PAYERADMIN: {
      BASE: 'payer-admin',
      DASHBOARD: 'dashboard',
      MANAGE_USERS: 'manage-users',
      MANAGE_ACCESS: 'manage-access-requests',
      MANAGE_ACCESS_DETAIL: 'manage-access-request-detail',
      PROVISIONING: {
        BASE: 'user-provisioning',
        EDIT: 'edit',
        VIEW: 'view',
      },
      MANAGEPORTALUSERS: 'manage-portal-users',
      SITE_LOCATIONS: {
        BASE: 'site-locations',
      },
      PRACTITIONERS: {
        BASE: 'practitioners',
        PROFILE: 'profile',
      },
      PROVIDER_GROUPS: 'provider-groups',
      PROVIDER_ADMINS: 'provider-admins',
    },
    PROVIDERADMIN: {
      BASE: 'provider-admin',
      PROVISIONING: {
        BASE: 'user-provisioning',
        EDIT: 'edit',
        VIEW: 'view',
      },
      SETTINGS: 'settings',
      DASHBOARD: 'dashboard',
      SITE_LOCATIONS: {
        BASE: 'site-locations',
        ATTESTATION: 'attestation',
      },
      PRACTITIONERS: {
        BASE: 'practitioners',
        PROFILE: 'profile',
      },
      ADDSITELOCATION: 'add-site-location',
      MANAGEPORTALUSERS: 'manage-portal-users',
    },
    SITEMAP: 'sitemap',
    SUSPEND_USER: 'suspend-user',
    ACTIVATE_USER: 'activate-user',
  },
};

export const SHOPPING_URLS = {
  APP: 'zshop',
  BASE: 'shopping',
  URLS: {
    APPLICATIONS: 'applications',
    RENEWAL: 'renewal',
  },
};
