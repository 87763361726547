<section *ngIf="activeTab | gridObjectsToArrays as tab">
  <message-banner
    *ngIf="errorMessage && showErrorMessage"
    data-testId="documentCenterMessageBanner"
    [config]="errorMessage"
  >
  </message-banner>
  <p
    *ngIf="!!tab.description"
    data-testId="documentCenterDescription"
    class="t-data"
    [innerHTML]="tab.description"
  ></p>

  <zipari-document-filters
    *ngIf="!!tab.filters"
    [documentCenterConfig]="documentCenterConfig"
    [tab]="tab"
    (filterFormValue)="filterDocuments($event)"
  ></zipari-document-filters>

  <section *ngIf="!documentCenterDataLoading; else documentsLoadingTemplate">
    <datagrid
      *ngIf="!!tab.datagrid"
      [config]="tab.datagrid"
      [data]="data"
      (actionClicked)="downloadAction($event)"
      (datagridRowSelected)="documentSelected($event)"
      (sortClicked)="sortDocuments($event)"
    >
    </datagrid>

    <grid-layout
      *ngIf="!!tab.gridlayout"
      [config]="tab.gridlayout"
    ></grid-layout>

    <zipari-accordion-layout
      *ngIf="!!tab.accordionLayout"
      [accordionConfig]="tab.accordionLayout"
    ></zipari-accordion-layout>
  </section>

  <paginator
    *ngIf="paginator?.totalPages > 1 && data?.length > 0"
    (pageSelected)="setCurrentPage($event)"
    [config]="paginator"
  >
  </paginator>
</section>

<ng-template #documentsLoadingTemplate>
  <div
    data-testId="documentCenterLoadingSpinner"
    class="u-flex u-justifyCenter u-maxX"
  >
    <preloader size="medium"></preloader>
  </div>
</ng-template>
