import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  ButtonModule,
  FormControlModule,
  FormGroupModule,
  CardModule,
  ModalModule,
  IconModule,
} from '@zipari/design-system';
import { TableFlyoutModule } from '../../shared/modules/table-flyout/table-flyout.module';
import { ApiErrorMessageModalModule } from '../../shared/modules/api-error-message-modal/api-error-message-modal.module';
import { ManagePortalUsersCreateUserComponent } from './manage-portal-users-create-user.component';
import { AddMultipleGroupsComponent } from './add-multiple-groups/add-multiple-groups.component';

@NgModule({
  declarations: [
    ManagePortalUsersCreateUserComponent,
    AddMultipleGroupsComponent,
  ],
  exports: [ManagePortalUsersCreateUserComponent, AddMultipleGroupsComponent],
  imports: [
    RouterModule,
    ButtonModule,
    CardModule,
    FormControlModule,
    FormGroupModule,
    CommonModule,
    ModalModule,
    IconModule,
    TableFlyoutModule,
    ApiErrorMessageModalModule,
  ],
})
export class ManagePortalUsersCreateUserModule {}
