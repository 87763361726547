/* eslint-disable no-shadow */
export class CXCaptureConfig {
  source_system_name: string = '';
  endpoint: string = '';
  tenant_name: string = '';
  constructor(options = {}) {
    Object.assign(this, options);
  }
}

export class GAConfig {
  mapping?: any;

  // use either gtag or ga based on the requested google analytics library... provide the id
  gtag?: string;
  ga?: string;
}

export interface PHConfig {
  apiKey: string;
  apiHost: string;
  capturePosthogEvents: boolean;
}

export enum validCXEvents {
  checkout = 'checkout',
  plan_details_viewed = 'plan-details_viewed',
  plans_list_viewed = 'plans-list_viewed',
  plan_added_to_cart = 'plan_added-to-cart',
  plan_removed_from_cart = 'plan_removed-from-cart',
  contact_us_viewed = 'contact-us_viewed',
  plans_compared = 'plans_compared',
  application_printed = 'application_printed',
  pcp_selection = 'pcp_selection',
  pcp_remove_selection = 'pcp_remove_selection',
  virtualPageView = 'virtualPageView',
  qle_selected = 'qle_selected',
}

export enum validPHEvents {
  checkout = 'checkout',
  plan_details_viewed = 'plan-details_viewed',
  plans_list_viewed = 'plans-list_viewed',
  plan_added_to_cart = 'plan_added-to-cart',
  plan_removed_from_cart = 'plan_removed-from-cart',
  contact_us_viewed = 'contact-us_viewed',
  plans_compared = 'plans_compared',
  application_printed = 'application_printed',
  pcp_selection = 'pcp_selection',
  pcp_remove_selection = 'pcp_remove_selection',
  virtualPageView = 'virtualPageView',
  qle_selected = 'qle_selected',
  memberDetailsBenefitsSearched = 'pp_member-details-benefits_searched',
  eligibilityMemberIdSearched = 'pp_eligibility-member-id_searched',
  eligibilityMemberNameSearched = 'pp_eligibility-member-name_searched',
  memberDetailsEligibilityHistoryViewed = 'pp_member-details-eligibility-history_viewed',
  memberDetailsCostViewed = 'pp_member-details-cost_viewed',
  memberDetailsClaimsListViewed = 'pp_member-details-claims-list_viewed',
  memberDetailsAuthorizationsListViewed = 'pp_member-details-authorizations-list_viewed',
  memberDetailsReferralsListViewed = 'pp_member-details-referrals-list_viewed',
  memberDetailsBenefitsViewed = 'pp_member-details-benefits_viewed',
  memberDetailsSearchListViewed = 'pp_member-details-search-list_viewed',
  memberDetailsClaimDetailsViewed = 'pp_member-details-claim-details_viewed',
  memberDetailsAuthorizationDetailsViewed = 'pp_member-details-authorization-details_viewed',
  memberDetailsReferralDetailsViewed = 'pp_member-details-referral-details_viewed',
  memberDetailsIDCardTabViewed = 'pp_member-details-ID-card_tab_viewed',
  memberDetailsIDCardPrinted = 'pp_member-details-ID-card_printed',
  memberDetailsIDCardDownloaded = 'pp_member-details-ID-card_downloaded',
  documentCenterListViewed = 'pp_document-center-list_viewed',
  documentSearched = 'pp_document_searched',
  documentPoliciesAndGuidelinesViewed = 'pp_document-policies-and-guidelines_viewed',
  documentFormsViewed = 'pp_document-forms_viewed',
  documentTrainingViewed = 'pp_document-training_viewed',
  documentResourcesViewed = 'pp_document-resources_viewed',
  documentFinancialViewed = 'pp_document-financial_viewed',
  messageInboxViewed = 'pp_message-inbox_viewed',
  messageSentViewed = 'pp_message-sent_viewed',
  messageArchivedViewed = 'pp_message-archived_viewed',
  messageArchived = 'pp_message_archived',
  messageDocumentAttached = 'pp_message-document_attached',
  messageViewed = 'pp_message_viewed',
  notificationCenterViewed = 'pp_notification-center_viewed',
  notificationCenterRead = 'pp_notificationcenter_read',
  practitionerAssociated = 'pp_add-practitioner_associated',
  siteLocationViewed = 'pp_add-site-location_viewed',
  siteLocationTerminated = 'pp_add-site-location_terminated',
  siteLocationPractitionerAssociationTerminated = 'pp_add-site-location-practitioner-association_terminated',
  siteLocationAssociated = 'pp_add-site-location_associated',
  siteLocationAdded = 'pp_add-site-location_added',
  siteLocationInitiated = 'pp_add-site-location_initiated',
  addPractitionerViewed = 'pp_add-practitioner_viewed',
  addPractitionerAdded = 'pp_add-practitioner_added',
  referralEntryEdited = 'pp_referral-entry_edited',
  referralEntryReviewed = 'pp_referral-entry_reviewed',
  referralAttachmentAdded = 'pp_referral-attachment_added',
  referralEntryCompleted = 'pp_referral-entry_completed',
  referralsServiceDatesFiltered = 'pp_referrals-service-dates_filtered',
  referralsMemberIdSearch = 'pp_referrals-member-id_search',
  referralsMemberNameSearch = 'pp_referrals-member-name_search',
  pregnancyAssessmentCreated = 'pp_pregnancy-assessment_created',
  pregnancyAssessmentReviewed = 'pp_pregnancy-assessment_reviewed',
  pregnancyFormEdited = 'pp_pregnancy-form_edited',
  memberDetailsPregnancyAssessmentInitiated = 'pp_member-details-actions-new-pregnancy-assessment_initiated',
  addPractitionerInitiated = 'pp_add-practitioner_initiated',
  authorizationEntryCompleted = 'pp_authorization-entry_completed',
  authorizationEntryEdited = 'pp_authorization-entry_edited',
  authorizationProviderspecialtySearched = 'pp_authorization-providerspecialty_searched',
  authorizationProvidernpiSearched = 'pp_authorization-providernpi_searched',
  authorizationFacilitynpiSearched = 'pp_authorization-facilitynpi_searched',
  authorizationFacilityspecialtySearched = 'pp_authorization-facilityspecialty_searched',
  authorizationDiagnosiscodeSearched = 'pp_authorization-diagnosiscode_searched',
  authorizationDiagnosisdescriptionSearched = 'pp_authorization-diagnosisdescription_searched',
  authorizationProcedurecodeSearched = 'pp_authorization-procedurecode_searched',
  authorizationProceduredescriptionSearched = 'pp_authorization-proceduredescription_searched',
  dashboardAuthDetailsViewed = 'pp_dashboard-auth-details_viewed',
  dashboardAuthSummaryViewed = 'pp_dashboard-auths-summary_viewed',
  dashboardClaimSummaryViewed = 'pp_dashboard-claim-summary_viewed',
  dashboardClaimDetailViewed = 'pp_dashboard-claim-detail_viewed',
  dashboardReferralSummaryListViewed = 'pp_dashboard-referral-summary-list_viewed',
  dashboardReferralDetailViewed = 'pp_dashboard-referral-detail_viewed',
}

export enum validGAEvents {
  checkout = 'checkout',
  plan_details_viewed = 'productClick',
  plans_list_viewed = 'productImpressions',
  plan_added_to_cart = 'addToCart',
  plan_removed_from_cart = 'removeFromCart',
  contact_us_viewed = 'contact-us_viewed',
  plans_compared = 'plans_compared',
  application_printed = 'application_printed',
  pcp_selection = 'pcp_selection',
  pcp_remove_selection = 'pcp_remove-selection',
  virtualPageView = 'virtualPageView',
  qle_selected = 'qle_selected',
}

export class AnalyticsContext {
  CXKey?: validCXEvents;
  GAKey?: validGAEvents;
  PHKey?: validPHEvents;
}

export const ProductPrefixes = {
  zshop: 'shp_',
  scout: 'ps_',
  provider_portal: 'pp_',
  member_portal: 'mp_',
  broker_portal: 'bp_',
};

export class workflowContext {
  workflow_id?: number;
  title: string;
  key: string;
  webuser_id?: number;
  web_session_id?: string;
}

export const ProviderPortal = 'Provider-Portal';

export const fetchGoogleAnalyticsScript = (id: any, noScript = false) =>
  !noScript
    ? // eslint-disable-next-line max-len
      `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${id}');`
    : // eslint-disable-next-line max-len
      `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
