import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TemplateRefDirective } from '../../shared/directives/template-ref.directive';

import { IconModule } from '../icon/icon.module';

import { TabsComponent } from './tabs.component';

@NgModule({
  declarations: [TabsComponent, TemplateRefDirective],
  imports: [CommonModule, IconModule],
  exports: [TabsComponent, TemplateRefDirective],
})
export class TabsModule {}
