import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-sort-toggle',
  templateUrl: './sort-toggle.component.html',
  styleUrls: ['./sort-toggle.component.scss'],
})
export class SortToggleComponent {
  @Input() isAscending: boolean;
  @Input() isDescending: boolean;
}
