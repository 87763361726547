import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GLOBAL_URLS } from '../../../app-urls.constant';
import { AuthIdp } from '../../../shared/modules/authentication/authentication.constants';

import { AuthService, ConfigService } from '../../../shared/services';
import { UserRoles } from '../../../shared/services/auth.service';
import { GenericServices } from '../../shared/services/generic.service';

@Component({
  selector: 'default-dashboard',
  template: '<ng-content></ng-content>',
})
export class DefaultDashboardComponent implements OnInit {
  config;
  authConfig;
  user: any;
  baseUrl: string;

  constructor(
    private configService: ConfigService,
    private router: Router,
    public genericServices: GenericServices,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.config = this.configService.getPageConfig('default-dashboard');
    this.authConfig = this.configService.getPageConfig('auth');
    this.user = this.authService.loggedInUser;
    this.getRole(this.user);
  }

  getDefaultDashboardConfig(config: object): string | undefined {
    for (const defaultConfig in config) {
      if (config[defaultConfig]) return defaultConfig;
    }
  }

  getRole(user) {
    user?.roles.map((target: UserRoles) => {
      if (target.name === 'Provider Admin') {
        this.baseUrl = 'provider-portal/provider-admin/';
      } else if (target.name === 'Payer Admin') {
        this.baseUrl = 'provider-portal/payer-admin/';
      } else {
        this.baseUrl = 'provider-portal/';
      }
    });

    if (
      !this.user?.app_user_data?.accepted_terms &&
      this.authConfig.idp === AuthIdp
    ) {
      this.router.navigate([`/${GLOBAL_URLS.TERMSANDCONDITIONS}`]);
    } else if (this.baseUrl) {
      this.router.navigate(
        [this.baseUrl + this.getDefaultDashboardConfig(this.config)],
        { skipLocationChange: true }
      );
    }
  }
}
