<form [formGroup]="group">
  <div formArrayName="{{ config.prop }}">
    <div
      *ngFor="let item of formArray?.controls; let pointIndex = index"
      [formGroupName]="pointIndex"
    >
      <div class="forms_container">
        <div class="right-space">
          <div *ngIf="pointIndex == 0" class="form-control__label__wrapper">
            <label class="form-control__label t-bold label_flex">
              <p class="t-data t-bold">{{ config?.label_phone }}</p>
              <p *ngIf="config.required" class="t-data t-bold spaced-star">
                {{ '*' }}
              </p>
            </label>
          </div>
          <div class="form-container">
            <div class="dropdown">
              <input
                class="form-control"
                maxLength="10"
                formControlName="phone_number"
              />
            </div>
          </div>
        </div>
        <div class="wide">
          <div *ngIf="pointIndex == 0" class="form-control__label__wrapper">
            <label class="form-control__label t-bold label_flex">
              <p class="t-data t-bold">{{ config?.label }}</p>
              <p *ngIf="config.required" class="t-data t-bold spaced-star">
                {{ '*' }}
              </p>
            </label>
          </div>
          <div class="form-container">
            <div class="form-control form-control--dropdown">
              <select role="listbox" formControlName="type">
                <option value="default">Select One</option>
                <option *ngFor="let c of options" [ngValue]="c.value">
                  {{ c.label }}
                </option>
              </select>
            </div>
            <span
              *ngIf="1 == getMaxSize()"
              class="material-icons mat-spaced-icon isHidden"
            >
              remove_circle_outline
            </span>
            <span
              *ngIf="getMaxSize() > 1"
              (click)="deleteArrayItem(pointIndex)"
              class="material-icons mat-spaced-icon"
            >
              remove_circle_outline
            </span>
            <span
              *ngIf="getMaxSize() < limit"
              (click)="addArrayItem()"
              class="material-icons mat-spaced-icon"
            >
              add_circle_outline
            </span>
            <span
              *ngIf="getMaxSize() >= limit"
              class="material-icons mat-spaced-icon isHidden"
            >
              add_circle_outline
            </span>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          (item.get('phone_number').invalid &&
            (item.get('phone_number').dirty ||
              item.get('phone_number').touched)) ||
          (item.get('type').invalid &&
            (item.get('type').dirty || item.get('type').touched))
        "
      >
        <div
          class="error"
          *ngIf="item.get('phone_number').errors || item.get('type').errors"
        >
          <icon
            name="warning"
            style="height: 1.125rem; padding-left: 1rem; padding-right: 0.5rem"
            ><i
              class="icon icon--font ng-star-inserted icon--small"
              style="color: red"
            >
              warning
            </i>
          </icon>
          <p class="t-caption t-error">
            {{ config.errorMessage || 'Please enter a valid input.' }}
          </p>
        </div>
      </div>
    </div>
  </div>
</form>
