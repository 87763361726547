<footer
  class="table__footer"
  *ngIf="
    (length > 0 || options?.footer?.onlyShowIfPaginated) &&
    options?.paging.enabled
  "
>
  <section
    *ngIf="
      options?.footer?.isLabelPresent ? options.footer?.isLabelPresent : true
    "
    class="table__footer__count"
  >
    <p
      *ngIf="length > options?.paging?.pageSize; else noPagination"
      class="t-data t-alt"
      tabindex="0"
    >
      Showing
      {{
        getRangeLabel(
          options?.paging?.pageIndex,
          options?.paging?.pageSize,
          length
        )
      }}
      of {{ length }}
    </p>

    <ng-template #noPagination>
      <p class="t-data t-alt" tabindex="0">
        Showing 1 - {{ length }} of {{ length }}
      </p>
    </ng-template>
  </section>

  <section class="table__pagination" *ngIf="length > options.paging.pageSize">
    <button
      class="table__pagination__button"
      type="button"
      [disabled]="!hasPreviousPage()"
      (click)="firstPage()"
      [attr.aria-label]="'Go to first page'"
      tabindex="0"
    >
      <i class="icon icon--medium">first_page</i>
    </button>

    <button
      class="table__pagination__button"
      [disabled]="!hasPreviousPage()"
      type="button"
      (click)="previousPage()"
      [attr.aria-label]="'Go to previous page'"
      tabindex="0"
    >
      <i class="icon icon--medium">chevron_left</i>
    </button>

    <button class="table__pagination__button" type="button" tabindex="0">
      <p class="t-data t-bold">{{ currentPage }} / {{ getNumberOfPages() }}</p>
    </button>

    <button
      class="table__pagination__button"
      type="button"
      [disabled]="!hasNextPage()"
      (click)="nextPage()"
      [attr.aria-label]="'Go to next page'"
      tabindex="0"
    >
      <i class="icon icon--medium">chevron_right</i>
    </button>

    <button
      class="table__pagination__button"
      type="button"
      [disabled]="!hasNextPage()"
      (click)="lastPage()"
      [attr.aria-label]="'Go to last page'"
      tabindex="0"
    >
      <i class="icon icon--medium">last_page</i>
    </button>
  </section>
</footer>
