<button
  type="button"
  class="button"
  (click)="onClick($event)"
  [disabled]="isDisabled"
  [ngClass]="{
    'button--search': config?.level && config?.level === ButtonLevels.search
  }"
  tabindex="0"
  dataClickEvent
  (keydown.enter)="onClick($event)"
  [attr.aria-label]="config?.content"
  [attr.aria-description]="ariaDescription"
>
  <div class="button__content">
    <icon
      class="button__icon"
      *ngIf="config?.icon && !config?.isIconRight"
      [name]="config?.icon"
      [config]="{ fill: iconFill, size: config?.iconSize || 'small' }"
    >
    </icon>

    <span
      class="button__content--text"
      *ngIf="config?.content"
      [innerHTML]="innerHtmlContent"
    >
    </span>

    <icon
      class="button__icon"
      *ngIf="config?.icon && config?.isIconRight"
      [name]="config?.icon"
      [config]="{ fill: iconFill, size: config?.iconSize || 'small' }"
    >
    </icon>
  </div>
</button>
