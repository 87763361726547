import { Component, Input } from '@angular/core';
import { TimeConfiguration } from '@zipari/shared-ds-util-form';

@Component({
  selector: 'control-time',
  templateUrl: './time.component.html',
})
export class ControlTimeComponent {
  @Input() config: TimeConfiguration;
  @Input() control: any;
}
