import { Directive, HostListener, Input } from '@angular/core';
import { EventInfo } from '@zipari/shared-ds-util-digital-data';
import { DigitalDataService } from '../../services/digital-data.service';

@Directive({
  selector: '[dataLoadEvent]',
})
export class DigitalDataLoadEventDirective {
  @Input() dataLoadEvent: EventInfo;
  constructor(private dds: DigitalDataService) {}

  @HostListener('click')
  clickEvent() {
    this.dds.pushEventObject(this.dataLoadEvent);
  }
}
