import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormGroupModule,
  PaginatorModule,
  SpinnerModule,
  TableModule,
} from '@zipari/design-system';
import { SharedModule } from '../../../../shared/shared.module';
import { DocumentTableComponent } from './document-table.component';

@NgModule({
  declarations: [DocumentTableComponent],
  exports: [DocumentTableComponent],
  imports: [
    CommonModule,
    TableModule,
    SharedModule,
    PaginatorModule,
    FormGroupModule,
    SpinnerModule,
  ],
})
export class DocumentTableModule {}
