import { Injectable } from '@angular/core';
import { LinkConfig } from '@zipari/shared-ds-util-navigation';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  private linkClickListener$: BehaviorSubject<LinkConfig> = new BehaviorSubject(
    null
  );

  setLinkClickListener(linkConfig: LinkConfig) {
    this.linkClickListener$.next(linkConfig);
  }

  getLinkClickListener() {
    return this.linkClickListener$.asObservable();
  }
}
