export function isObj(item: any): boolean {
  return !!(item && typeof item === 'object' && !Array.isArray(item));
}

export function isEmptyObj(obj: any): boolean {
  return Object.entries(obj).length === 0 && obj.constructor === Object;
}

/**
 * Handles getting a clone of an object that goes recursively down the structure and is faster than JSON.parse and
 * JSON.stringify also dynamically handles array objects as well.
 */
export function cloneObject(obj, justCopyProps = null) {
  let clone;
  if (Array.isArray(obj)) {
    clone = obj.map((individualObj) =>
      cloneObject(individualObj, justCopyProps)
    );
  } else {
    if (typeof obj === 'object') {
      clone = {};
      for (const i in obj) {
        if (justCopyProps && justCopyProps[i]) {
          clone[i] = obj[i];
        } else if (obj[i] && typeof obj[i] === 'object') {
          clone[i] = cloneObject(obj[i], justCopyProps);
        } else {
          clone[i] = obj[i];
        }
      }
    } else {
      return obj;
    }
  }

  return clone;
}

export function deepCompare(obj, obj2, ignoreProps?) {
  if (!obj || !obj2 || typeof obj !== 'object' || typeof obj2 !== 'object') {
    return false;
  }
  if (ignoreProps) {
    obj = cloneObject(obj);
    obj2 = cloneObject(obj2);
    for (const key in ignoreProps) {
      if (key) {
        delete obj[key];
        delete obj2[key];
      }
    }
  }

  return JSON.stringify(obj) === JSON.stringify(obj2);
}

/**
 * (zip-ui)
 * Deep merge two objects.
 * Arrays in target are overwritten by source
 * @param target
 * @param src
 */
export function deepMerge(target: object, src: object): object {
  if (!target) {
    return src;
  }
  if (!isObj(target)) {
    return src;
  }

  let output = { ...target };
  if (isObj(target) && isObj(src)) {
    Object.keys(src).forEach((key) => {
      if (!isObj(src[key]) || !(key in target)) {
        output = { ...output, [key]: src[key] };
      } else {
        output[key] = deepMerge(target[key], src[key]);
      }
    });
  }

  return output;
}

export function enumToArray(Enum): { id: string; name: string }[] {
  return Object.keys(Enum).map((key) => ({ id: Enum[key], name: key }));
}

export function objectToArray(object: any): any[] {
  return Object.keys(object).map((key) => object[key]);
}

export function isInEnum(Enum, value): boolean {
  return Object.values(Enum).includes(value);
}

export function cloneObjectIfNotExtensible(obj) {
  if (!Object.isExtensible(obj)) {
    return cloneObject(obj);
  } else {
    return obj;
  }
}
