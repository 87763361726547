<article
  @fadeToMaskOpacity
  (click)="handleMaskClick()"
  class="ModalMask"
  [ngClass]="{ no_backdrop: !config.backdrop }"
  *ngIf="condition && (config.backdrop || config.clickOutside)"
  data-test-id="ModalMask"
></article>

<article
  [@slide]="{
    value: ':enter',
    params: animationDirectionDefaults[config.direction]
  }"
  [ngClass]="{
    no_backdrop: !config.backdrop,
    'Modal--Right': config.direction === modalDirections.right,
    'Modal--Left': config.direction === modalDirections.left,
    'Modal--Center': config.direction === modalDirections.center,
    'Modal--Bottom': config.direction === modalDirections.bottom,
    'Modal--Popup': config.type === modalTypes.popup,
    'Modal--Flyout': config.type === modalTypes.flyout,
    'Modal--Small': config.size === modalSizes.small,
    'Modal--Large': config.size === modalSizes.large,
    'Modal--ExtraLarge': config.size === modalSizes.extraLarge,
    'Modal--Full': config.size === modalSizes.full,
    'Modal--Alt': config.altStyle,
    'Modal--BelowNav': config.isBelowNavigation,
    'Modal--FlyoutPartialWidth': config.partialWidth
  }"
  class="Modal"
  *ngIf="condition"
  data-test-id="ModalBackdrop"
  role="dialog"
  aria-modal="true"
  [attr.aria-labelledby]="
    config.header.title ? config.header.title : modalTitle
  "
  id="modalContainer"
>
  <div class="ModalHeader">
    <div
      class="ModalHeader__Icon"
      *ngIf="config.header.icon"
      data-test-id="ModalHeaderCloseDesktop"
    >
      <icon
        [name]="config.header.icon"
        [config]="{
          size: 'small',
          fill: iconFill
        }"
      >
      </icon>
    </div>

    <div
      class="ModalHeader__CloseMobile"
      *ngIf="
        config.header.showClose &&
        config.header.showBack &&
        config.type === modalTypes.flyout
      "
      data-test-id="ModalHeaderCloseMobile"
    >
      <icon
        name="arrow_left"
        role="button"
        aria-label="Cancel"
        [config]="{
          size: 'small',
          type: 'pointer',
          fill: iconFill
        }"
        (click)="handleCancel()"
      >
      </icon>
    </div>

    <h1
      class="ModalHeader__Title t-bold t-data"
      [style.textAlign]="
        config.header.alignTitle
          ? config.header.alignTitle
          : modalDirections.center
      "
      *ngIf="config.header.title"
      [innerHTML]="config.header.title"
      data-test-id="ModalHeaderTitle"
      aria-label="Modal Title"
    ></h1>

    <div class="ModalHeader--Right" data-test-id="ModalHeaderRightContent">
      <p
        class="t-data"
        *ngIf="config.header.description"
        [innerHTML]="config.header.description"
        data-test-id="ModalHeaderDescription"
      ></p>

      <icon
        *ngIf="config.header.showClose"
        name="close"
        [config]="{
          size: 'small',
          type: 'pointer',
          fill: iconFill
        }"
        (click)="handleCancel()"
        (keyup.enter)="handleCancel()"
        data-test-id="ModalHeaderCloseButton"
        tabindex="0"
        role="button"
        aria-label="Close"
      >
      </icon>
      <ng-content select="[headerRightContent]"></ng-content>
    </div>
  </div>

  <span role="document">
    <ng-container *ngIf="!config.isLoading; else loading">
      <section *ngIf="config.isConfirmed">
        <div
          class="Modal__Confirmation"
          data-test-id="ModalConfirmation"
          role="status"
        >
          <ng-content select="[confirmation]"></ng-content>
        </div>
      </section>

      <section
        class="ModalBody"
        *ngIf="config.isError"
        data-test-id="ModalBodyError"
        role="status"
      >
        <ng-content select="[error]"></ng-content>
      </section>

      <section
        #body
        tabindex="0"
        class="ModalBody"
        *ngIf="!config.isConfirmed && !config.isError"
        data-test-id="ModalBodyContent"
        role="dialog"
        aria-modal="true"
      >
        <ng-content select="[body]"></ng-content>
      </section>
    </ng-container>

    <ng-template #loading>
      <section
        class="ModalBody"
        data-test-id="ModalBodyLoading"
        role="status"
        aria-label="Loading Indicator"
      >
        <ng-content select="[loading]"></ng-content>
      </section>
    </ng-template>
  </span>
  <footer class="ModalFooter" data-test-id="ModalFooter">
    <ng-content select="[footer]"></ng-content>
  </footer>
</article>
