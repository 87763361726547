<article *ngIf="config" class="MainContainer">
  <sidebar-main-container [hasBackgroundChanged]="true">
    <div class="MainContainer__SideBar">
      <ng-container *ngIf="resources && claimNumber; else loading">
        <side-bar
          [config]="config.sidePanel"
          [memberId]="memberId"
          [claimId]="claimNumber"
        ></side-bar>
        <header class="MainContainer__SideBarHeader">
          <h6 [innerHTML]="config.sidePanel.document.label"></h6>
        </header>
        <link-list [dataList]="resources"></link-list>
      </ng-container>
      <ng-template #loading>
        <spinner [asOverlay]="false" size="small" flexCenter="true"></spinner>
      </ng-template>
    </div>
    <smc-main>
      <ng-container mainContent>
        <header class="MainContainer__Header">
          <zip-button
            [config]="config.backButton"
            (zipButtonClicked)="routeBackClaimDetail()"
          ></zip-button>
        </header>
        <section class="MainContainer__Badges">
          <div class="MainContainer__Badge" *ngFor="let badge of badgesConfig">
            <badge [config]="badge"></badge>
          </div>
        </section>
        <section class="MainContainer__Tabs">
          <tabs
            pageTabs="true"
            [tabLabels]="tabsLabels"
            (tabSelected)="selectTab($event)"
            [selectedIndex]="activeTabConfig?.priority"
          >
          </tabs>
        </section>
        <router-outlet></router-outlet>
      </ng-container>
    </smc-main>
  </sidebar-main-container>
</article>
