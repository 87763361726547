<div
  class="document-upload__dropzone"
  [ngClass]="{
    'is-disabled': disabled,
    horizontal__dropzone: fileUploadAlignment
  }"
>
  <p
    class="document-upload__dropzone__text"
    [ngClass]="{ 't-alt': disabled }"
    *ngIf="fileUploadText; else uploadText"
  >
    {{ fileUploadText }}
  </p>
  <ng-template #uploadText>Drag and drop files or</ng-template>
  <ng-content></ng-content>
</div>
