import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'control-time-range',
  templateUrl: './time-range.component.html',
})
export class ControlTimeRangeComponent implements OnInit {
  @Input() config;
  @Input() group: UntypedFormGroup;

  timeRangeForm: UntypedFormGroup;
  timeRangeConfig;

  constructor() {}

  ngOnInit() {
    this.timeRangeConfig = {
      start: {
        prop: 'start',
        type: 'time',
        iconRight: this.config.iconRight,
      },
      end: {
        prop: 'end',
        type: 'time',
        iconRight: this.config.iconRight,
      },
    };
    this.timeRangeForm = new UntypedFormGroup({
      start: new UntypedFormControl(this.config.start),
      end: new UntypedFormControl(this.config.end),
    });

    this.group.removeControl(this.config.prop);
    this.group.addControl(this.config.prop, this.timeRangeForm);
  }
}
