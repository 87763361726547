<section [zipBusy]="genericServices.subscriptions" class="provider-portal">
  <navigation></navigation>

  <div
    class="SectionToPrint"
    [ngClass]="{ hideToolbar: !showToolbar, showToolbar: showToolbar }"
  >
    <router-outlet (activate)="onActivate()"></router-outlet>
  </div>

  <portal-footer
    *ngIf="footer && showFooter$ | async"
    [config]="footer"
  ></portal-footer>
</section>
