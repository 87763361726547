<label
  class="form-control--checkbox__wrapper"
  [ngClass]="{
    'form-control--checkbox__wrapper--inline': config.inline === true,
    'form-control--checkbox__wrapper--top-aligned':
      config.isCheckboxTopAligned === true
  }"
>
  <input
    #box
    class="form-control form-control--checkbox"
    type="checkbox"
    ngDefaultControl
    role="checkbox"
    [attr.disabled]="config?.isDisabled ? config?.isDisabled : null"
    [attr.readonly]="config?.readonly ? config?.readonly : null"
    [attr.aria-required]="
      config?.validators && config?.validators.includes('required')
        ? true
        : false
    "
    [attr.name]="config.label"
    [id]="config.id || config.prop"
    [formControl]="control"
    [attr.aria-checked]="!!control.value"
    [attr.aria-invalid]="(control.dirty || control.touched) && !control.valid"
    [attr.aria-describedby]="(config?.id || config?.prop) + '-label'"
    [attr.aria-errormessage]="
      'error_' + (config?.id || config?.prop) + '-input'
    "
  />
  <p
    class="t-data"
    [ngClass]="{ 't-bold': config.boldText }"
    [innerHTML]="checkboxLabel"
  ></p>
</label>
