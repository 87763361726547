<section class="provider-groups-create">
  <div>
    <div>
      <h3 class="provider-groups-create__header__form-text t-bold">
        {{ activeStep?.stepHeader }}
      </h3>

      <div
        *ngIf="activeStep?.stepSubheader"
        class="provider-groups-create__header__descriptive-text"
      >
        {{ activeStep?.stepSubheader }}
      </div>
    </div>

    <div class="provider-groups-create__confirmation">
      <div *ngIf="activeStep?.badges" class="confirmation__badges-container">
        <div class="badge--card" *ngFor="let badge of activeStep?.badges">
          <card>
            <div class="badge-body" body>
              <div class="badge-body__icon center">
                <i
                  [ngClass]="badge.icon.classString"
                  class="icon icon--large"
                  >{{ badge.icon.name }}</i
                >
              </div>
              <div class="badge-body__value center">
                <h6 class="t-bold">{{ getVal(newGroupData, badge.value) }}</h6>
              </div>
              <div class="badge-body__label center">
                <p>{{ badge.label }}</p>
              </div>
            </div>
          </card>
        </div>
      </div>

      <div *ngIf="activeStep?.printButton" class="confirmation__print">
        <zip-button
          class="confirmation__print--button"
          [config]="activeStep?.printButton"
          (zipButtonClicked)="print()"
        ></zip-button>
      </div>
    </div>

    <div>
      <card
        class="provider-groups-create__card"
        *ngFor="let card of activeStep?.cards | keyvalue"
      >
        <div body>
          <div title class="provider-groups-create__header-container">
            <h5
              class="provider-groups-create__header --typeface--1 t-bold --h5-font-size --h5-font-height"
            >
              {{ card.value.cardHeader }}
            </h5>

            <icon
              *ngIf="card?.value?.icon"
              [name]="card?.value?.icon?.name"
              [config]="card?.value?.icon?.config"
              (click)="iconClicked()"
            ></icon>
          </div>
          <div *ngIf="card?.value?.formConfig">
            <ng-container
              *ngFor="let control of card.value.formConfig | keyvalue"
            >
              <form-control
                [group]="newGroupFormGroup"
                [config]="control.value"
                [control]="newGroupFormGroup.get(control.value.prop)"
              >
              </form-control>
            </ng-container>
          </div>

          <div class="card-data__container" *ngIf="card.value.dataConfig">
            <div
              class="card-data__data"
              *ngFor="let data of card.value.dataConfig"
            >
              <label class="data__label">{{ data.label }}</label>
              <p class="data__value">
                {{ getVal(newGroupFormGroup.value, data.value) }}
              </p>
            </div>
          </div>
        </div>
      </card>
      <div
        *ngIf="activeStep?.buttons"
        class="provider-groups-create__action-buttons-container"
      >
        <div class="provider-groups-create__action-buttons__left">
          <zip-button
            class="provider-groups-create__cancel-button"
            [config]="activeStep?.buttons?.secondaryButton"
            (zipButtonClicked)="
              buttonClicked(activeStep?.buttons?.secondaryButton)
            "
          >
          </zip-button>
        </div>
        <div class="provider-groups-create__action-buttons__right">
          <zip-button
            [disabled]="!newGroupFormGroup.valid"
            class="manage-portal-users__submit-button"
            [config]="activeStep?.buttons?.primaryButton"
            (zipButtonClicked)="
              buttonClicked(activeStep?.buttons?.primaryButton)
            "
          >
          </zip-button>
        </div>
      </div>
    </div>
  </div>
</section>

<modal
  #errorModal
  *ngIf="showErrorModal"
  [config]="config.errorModal"
  [condition]="showErrorModal"
  (cancel)="closeModal()"
>
  <div body class="provider-group__error-modal">
    <div
      class="provider-group__error-modal--error"
      *ngFor="let error of errors"
    >
      <div [innerHTML]="error"></div>
    </div>
  </div>

  <div footer class="provider-group__error-modal--footer">
    <div>
      <zip-button
        [config]="config?.errorModal?.footer?.confirm"
        (zipButtonClicked)="closeModal()"
      ></zip-button>
    </div>
  </div>
</modal>
