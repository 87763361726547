import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReadMorePipe } from '../../pipes/read-more.pipe';
import { ReadMoreComponent } from './read-more.component';

@NgModule({
  declarations: [ReadMoreComponent, ReadMorePipe],
  imports: [CommonModule],
  exports: [ReadMoreComponent],
})
export class ReadMoreModule {}
