import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CLAIM_DETAIL_URLS } from '../../../shared/constants/app-urls.constants';
import { ClaimLineDetailComponent } from '../claim-line-detail/claim-line-detail.component';
import { ClaimReconsiderationComponent } from '../claim-appeal/claim-reconsideration/claim-reconsideration.component';
import { ClaimRedeterminationComponent } from '../claim-appeal/claim-redetermination/claim-redetermination.component';
import { ClaimDetailComponent } from './claim-detail.component';
import { ClaimLinesComponent } from './claim-lines/claim-lines.component';
import { FinancialOverviewComponent } from './financial-overview/financial-overview.component';
import { OverviewComponent } from './overview/overview.component';
import { ClaimAppealsComponent } from './claim-appeals/claim-appeals.component';
import { ClaimAppealsGuard } from './claim-detail-routing.guard';

const routes: Routes = [
  {
    path: 'claim-lines/:claim_line',
    component: ClaimLineDetailComponent,
    pathMatch: 'full',
    data: {
      pageName: 'claim-line-detail',
    },
  },
  {
    path: `${CLAIM_DETAIL_URLS.CLAIM_APPEALS}/:appeal_id/:appeal_type`,
    loadChildren: () =>
      import('../claim-appeal-detail/claim-appeal-detail.module').then(
        (m: any) => m.ClaimAppealDetailModule
      ),
  },
  {
    path: 'claim-reconsideration',
    component: ClaimReconsiderationComponent,
    pathMatch: 'full',
    data: {
      pageName: 'claim_reconsideration',
    },
  },
  {
    path: 'claim-redetermination',
    component: ClaimRedeterminationComponent,
    pathMatch: 'full',
    data: {
      pageName: 'claim_redetermination',
    },
  },
  {
    path: '',
    component: ClaimDetailComponent,
    children: [
      {
        path: CLAIM_DETAIL_URLS.OVERVIEW,
        component: OverviewComponent,
        data: {
          activeTab: 'overview',
        },
      },
      {
        path: CLAIM_DETAIL_URLS.FINANCIAL_OVERVIEW,
        component: FinancialOverviewComponent,
        data: {
          activeTab: 'financial-overview',
        },
      },
      {
        path: CLAIM_DETAIL_URLS.CLAIM_LINES,
        component: ClaimLinesComponent,
        data: {
          activeTab: 'claim-lines',
        },
      },
      {
        path: CLAIM_DETAIL_URLS.CLAIM_APPEALS,
        component: ClaimAppealsComponent,
        canActivate: [ClaimAppealsGuard],
        data: {
          activeTab: CLAIM_DETAIL_URLS.CLAIM_APPEALS,
        },
      },
      {
        path: '**',
        redirectTo: CLAIM_DETAIL_URLS.OVERVIEW,
        pathMatch: 'prefix',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClaimDetailRoutingModule {}
