export function getCSSVariableValue(name: string): string {
  return getComputedStyle(document.documentElement)
    .getPropertyValue(name)
    .trim()
    .replace(/\"/g, '');
}

export function setCSSVariableValue(name: string, value: string): void {
  document.documentElement.style.setProperty(name, value);
}
