import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NavigationLink } from '@zipari/shared-ds-util-navigation';
import { NavigationService } from './../../navigation.service';

@Component({
  selector: 'lib-expandable-menu',
  templateUrl: './expandable-menu.component.html',
  styleUrls: ['./expandable-menu.component.scss'],
})
export class ExpandableMenuComponent implements AfterViewInit {
  @Input() link;
  @Input() data;
  @Input() externalSiteModalConfig;
  @Input() openMenuItem: NavigationLink;
  @Output() linkClicked: EventEmitter<any> = new EventEmitter();
  @ViewChild('exapandableContent') exapandableContent: ElementRef;

  scrollHeight: string;

  constructor(private navigation: NavigationService) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.scrollHeight = this.exapandableContent?.nativeElement.scrollHeight;
    }, 0);
  }

  getLinkConfig(link: NavigationLink) {
    return this.navigation.getLinkConfig(link);
  }

  onLinkClick(evt, item) {
    this.linkClicked.emit({ event: evt, link: item });
  }
}
