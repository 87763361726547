import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { FileUploaderService } from '../file-uploader.service';

@Component({
  selector: 'file-uploader-dropzone',
  templateUrl: './file-upload-dropzone.component.html',
  styleUrls: ['./file-upload-dropzone.component.scss'],
})
export class FileUploaderDropZoneComponent {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @Input() disabled: boolean;
  @Input() fileUploadText: string;
  @Input() fileUploadAlignment: string;
  onChange: Function;
  private file: File | null = null;

  constructor(
    public fileUploadService: FileUploaderService,
    private host: ElementRef<HTMLInputElement>
  ) {}

  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();

    // this starts it off
    if (!this.disabled) {
      this.fileUploadService.handleFileInput(event.dataTransfer?.files);
    }
  }
}
