<div *ngIf="config">
  <div class="ProviderAdminSearch">
    <form-group
      [form]="filterForm"
      [configs]="config.filters?.controls"
      (inputChanged)="handleInputChanged()"
      class="ProviderAdminSearch__Filters"
    ></form-group>
    <div class="ProviderAdminSearch__FiltersActions">
      <zip-button
        [config]="config.searchButton"
        [disabled]="disableSearch"
        (zipButtonClicked)="searchProviderAdmin()"
      >
      </zip-button>
    </div>
  </div>
  <div *ngIf="adminList$ | async as data; else loadingOrError">
    <datagrid
      [config]="config.datagrid"
      [data]="data.results"
      (sort)="sortProviderAdmins($event)"
      (datagridRowSelected)="navigateToDetail($event)"
    ></datagrid>
    <paginator
      *ngIf="paginator?.totalPages > 1"
      (pageSelected)="setCurrentPage($event)"
      [config]="paginator"
    >
    </paginator>
  </div>
  <ng-template #loadingOrError>
    <ng-container *ngIf="showError; else loading">
      <error-empty class="u-maxX" [config]="errorMessageConfig"> </error-empty>
    </ng-container>
    <ng-template #loading>
      <spinner [asOverlay]="false" flexCenter="true" size="medium"></spinner>
    </ng-template>
  </ng-template>
</div>
