<article class="modal__mask" *ngIf="modalIsVisible" @fade></article>

<article class="modal modal--panel" *ngIf="modalIsVisible" @slideRight>
  <header class="modal__header">
    <p class="t-data t-bold">Deactivate User</p>
  </header>

  <section class="modal__body">
    <h4 class="t-bold">
      {{ portalUser.first_name }} {{ portalUser.last_name }}
    </h4>

    <div class="portal-user__deactivation-instructions">
      <p class="t-data portal-user__top-instruction">
        Deactivating a user will block them from logging into the Provider
        Portal, effective on the date that you define below.
      </p>

      <p class="t-data">
        You can also opt to send an email to the user notifying them that their
        account will be deactivated.
      </p>
    </div>

    <form [formGroup]="deactivationForm">
      <div class="portal-user__deactivation-form">
        <label>
          <p class="t-data t-bold">Deactivation Date</p>
        </label>
        <input formControlName="date" class="form-control" type="date" />
      </div>

      <div class="portal-user__email">
        <form-control
          [group]="deactivationForm"
          [control]="deactivationForm.controls.sendEmail"
          [config]="sendEmailConfig"
        >
        </form-control>
      </div>
    </form>
  </section>

  <footer class="modal__footer">
    <zip-button level="low" content="Cancel" (click)="hideModal()">
    </zip-button>

    <zip-button
      level="high"
      content="Confirm Deactivation"
      (click)="onConfirmDeactivation()"
      [disabled]="deactivationForm.invalid"
    >
    </zip-button>
  </footer>
</article>

<article class="layout--detail">
  <article class="layout--detail__content">
    <aside class="layout--detail__aside">
      <header class="layout--detail__aside__header">
        <h6>Overview</h6>
      </header>

      <section class="layout--detail__aside__section">
        <div class="model-attributes">
          <div class="model-attribute">
            <label class="model-attribute__label">
              <p class="t-data t-bold">Name</p>
            </label>

            <div class="model-attribute__value">
              <p class="t-data">
                {{ portalUser.first_name }} {{ portalUser.last_name }}
              </p>
            </div>
          </div>

          <div class="model-attribute">
            <label class="model-attribute__label">
              <p class="t-data t-bold">Status</p>
            </label>

            <div class="model-attribute__value">
              <p class="t-data">
                {{ portalUser.is_active ? 'Active' : 'Inactive' }}
              </p>
            </div>
          </div>

          <div class="model-attribute">
            <label class="model-attribute__label">
              <p class="t-data t-bold">Last Login</p>
            </label>

            <div class="model-attribute__value">
              <p class="t-data">{{ formattedLastLogin }}</p>
            </div>
          </div>
        </div>
      </section>
    </aside>

    <main class="layout--detail__main">
      <header class="layout--detail__main__breadcrumb">
        <a
          class="u-flex u-alignCenter"
          [routerLink]="'/provider-portal/portal-users'"
        >
          <i class="icon icon--medium">chevron_left</i>
          <p class="t-data t-bold t-link">All Users</p>
        </a>
      </header>

      <header class="layout--detail__main__header">
        <div class="u-flex u-alignCenter u-justifyBetween u-maxX">
          <h4 class="t-bold">User Details</h4>

          <zip-button level="medium" content="Deactivate" (click)="showModal()">
          </zip-button>
        </div>
      </header>

      <section class="form-group">
        <header class="form-group__header">
          <div class="profile__logo"></div>
          <h6 class="t-bold">Profile</h6>
        </header>

        <form
          *ngIf="userDetailsForm"
          [formGroup]="userDetailsForm.controls.profile"
        >
          <div class="form-control__wrapper form-control__wrapper--inline">
            <label class="form-control__label">
              <p class="t-data t-bold">First Name</p>
            </label>

            <input class="form-control" formControlName="firstName" />
          </div>

          <div class="form-control__wrapper form-control__wrapper--inline">
            <label class="form-control__label">
              <p class="t-data t-bold">Last Name</p>
            </label>

            <input class="form-control" formControlName="lastName" />
          </div>

          <div class="form-control__wrapper form-control__wrapper--inline">
            <label class="form-control__label">
              <p class="t-data t-bold">Job Title</p>
            </label>

            <input class="form-control" formControlName="jobTitle" />
          </div>
        </form>
      </section>

      <section class="form-group">
        <header class="form-group__header">
          <div class="contact__logo"></div>
          <h6 class="t-bold">Contact</h6>
        </header>

        <form
          *ngIf="userDetailsForm"
          [formGroup]="userDetailsForm.controls.contact"
        >
          <div class="form-control__wrapper form-control__wrapper--inline">
            <label class="form-control__label">
              <p class="t-data t-bold">Email Address</p>
            </label>

            <input class="form-control" formControlName="emailAddress" />
          </div>

          <div class="form-control__wrapper form-control__wrapper--inline">
            <label class="form-control__label">
              <p class="t-data t-bold">Phone Number</p>
            </label>

            <input class="form-control" formControlName="phoneNumber" />
          </div>
        </form>
      </section>

      <section class="form-group">
        <header class="form-group__header">
          <div class="security__logo"></div>
          <h6 class="t-bold">Sign In & Security</h6>
        </header>

        <form
          *ngIf="userDetailsForm"
          [formGroup]="userDetailsForm.controls.security"
        >
          <div class="form-control__wrapper form-control__wrapper--inline">
            <label class="form-control__label">
              <p class="t-data t-bold">Username</p>
            </label>

            <input class="form-control" formControlName="username" />
          </div>

          <div class="form-control__wrapper form-control__wrapper--inline">
            <label class="form-control__label">
              <p class="t-data t-bold">Role</p>
            </label>

            <input class="form-control" formControlName="role" />
          </div>

          <div class="form-control__wrapper form-control__wrapper--inline">
            <label class="form-control__label">
              <p class="t-data t-bold">Permissions</p>
            </label>

            <input class="form-control" formControlName="permissions" />
          </div>
        </form>
      </section>
    </main>

    <footer class="layout--detail__footer">
      <div class="container">
        <div class="u-flex u-alignCenter u-justifyEnd u-maxX">
          <zip-button
            *ngIf="userDetailsForm"
            [disabled]="userDetailsForm.invalid"
            (click)="onSaveChanges()"
            content="Save Changes"
            level="high"
          >
          </zip-button>
        </div>
      </div>
    </footer>
  </article>
</article>
