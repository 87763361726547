import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
  AsyncValidatorFn,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { CREDIT_CARD_REGEX, MBI_REGEX } from '../../../../shared/constants';
import {
  decrementDate,
  getDateWithoutTimezoneOffset,
  getDay,
  getFormattedDate,
  greaterThanOrEqualToDate,
  incrementDate,
  isValidDate,
  lessThanOrEqualToDate,
  numDaysBetweenDates,
} from '../../../../shared/utils/dates';
import { getValue } from '../../../../shared/utils/get-value';
import { stringBuilder } from '../../../../shared/utils/string-builder';

const secondsInAMinute = 60;
const minutesInAnHour = 60;
const millisecondsInASecond = 1000;
const hoursInADay = 24;

function isEmptyInputValue(value: any): boolean {
  return value === null || value === undefined || value === '';
}

/**
 * @description returns true if the input param has value
 * @param input
 * @param includeZero 0 passes value check
 * @param includeBooleans  false passes value check
 */
function hasValue(input: any, includeZero = false, includeBooleans = false) {
  if (input) {
    return true;
  }

  const zeroPass = includeZero && input === 0;
  const boolPass = includeBooleans && input === false;

  return zeroPass || boolPass;
}

function setupErrorMessageObject(condition, name) {
  const errorMessage = {};
  errorMessage[name] = { name };

  return condition ? null : errorMessage;
}

const reasonableFutureDate = 3000;
const reasonablePastDate = 1900;
const reasonableMinimumYearForAge = 1900;

function validateOnlyAfterReasonableMinimumAgeIsEntered(date) {
  const controlDate = new Date(date);

  if (!controlDate || controlDate.getFullYear() < reasonableMinimumYearForAge) {
    return null;
  }

  return true;
}

function sameMonth(d1, d2) {
  const date1 = new Date(d1);
  const date2 = new Date(d2);

  return (
    date1.getUTCFullYear() === date2.getUTCFullYear() &&
    date1.getUTCMonth() === date2.getUTCMonth()
  );
}

function handleAsyncResult(control, condition, name) {
  if (!control.hasOwnProperty('asyncCache')) {
    control['asyncCache'] = {};
  }
  control.asyncCache[control.value] = setupErrorMessageObject(condition, name);
  control['previousValue'] = control.value;

  return control.asyncCache[control.value];
}

// @dynamic
export class FormControlValidators extends Validators {
  static future60Days(name): ValidatorFn {
    const daysToCheck = 60;

    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || !isValidDate(control.value)) {
        return null; // don't validate empty and invalid date values
      }

      // get last 60 days date
      const dateOffset =
        hoursInADay *
        minutesInAnHour *
        secondsInAMinute *
        millisecondsInASecond *
        daysToCheck;
      const dateValueForCheck = new Date();
      dateValueForCheck.setTime(dateValueForCheck.getTime() + dateOffset);

      // get input value as a date
      const inputValue = new Date(control.value);

      // check condition
      const condition =
        inputValue.getTime() <= dateValueForCheck.getTime() &&
        inputValue.getTime() >= new Date().getTime();

      const returnObj = {};
      returnObj[name] = { name: name };

      return condition ? null : returnObj;
    };
  }

  static last60Days(name): ValidatorFn {
    const daysToCheck = 60;

    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || !isValidDate(control.value)) {
        return null; // don't validate empty and invalid date values
      }

      // get last 60 days date
      const dateOffset =
        hoursInADay *
        minutesInAnHour *
        secondsInAMinute *
        millisecondsInASecond *
        daysToCheck; // 60 days
      const dateValueForCheck = new Date();
      dateValueForCheck.setTime(dateValueForCheck.getTime() - dateOffset);

      // get input value as a date
      const inputValue = new Date(control.value);

      // check condition
      const condition =
        inputValue.getTime() <= new Date().getTime() &&
        inputValue.getTime() >= dateValueForCheck.getTime();

      const returnObj = {};
      returnObj[name] = { name: name };

      return condition ? null : returnObj;
    };
  }

  static zipRequired(control: AbstractControl): ValidationErrors | null {
    const validator = Validators.required(control);

    return validator;
  }

  static zipRequiredTrue(control: AbstractControl): ValidationErrors | null {
    const validator = Validators.requiredTrue(control);

    return validator;
  }

  // card__type
  static cardBrand(name: string, nameOfBrandProp: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }
      const cardBrand = getValue(control.parent.value, nameOfBrandProp);

      if (!cardBrand) {
        return null;
      }

      const creditCardRegexObj = getValue(
        CREDIT_CARD_REGEX,
        cardBrand.toLowerCase()
      );

      if (!creditCardRegexObj) {
        return null;
      }

      const regex = getValue(creditCardRegexObj, 'regex');
      const validationResult = regex.test(control.value);

      return setupErrorMessageObject(validationResult, name);
    };
  }

  static creditCardNumber(
    name: string,
    network: string[] | string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const double = 2;
      const nine = 9; // I can't figure out why we use 9
      const decimalValue = 10;
      const creditCardRegex = CREDIT_CARD_REGEX;
      const value = control.value.replace(/\D/g, '');

      // Luhn algorithm
      let sum = 0;
      let shouldDouble = false;

      for (let i = value.length - 1; i >= 0; i--) {
        let digit = parseInt(value.charAt(i), 10);

        if (shouldDouble) {
          if ((digit *= double) > nine) digit -= nine;
        }

        sum += digit;
        shouldDouble = !shouldDouble;
      }

      const isValid = sum % decimalValue === 0;

      // check acceptability for provided networks via regex
      let isAccepted = false;
      const networkArr = Array.isArray(network) ? network : network.split(',');

      networkArr.forEach((networkItem) => {
        const regex = creditCardRegex[networkItem].regex;

        if (regex.test(value)) {
          isAccepted = true;
        }
      });

      return setupErrorMessageObject(isValid && isAccepted, name);
    };
  }

  static creditCardExpirationDate(name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const value = control.value.split('/').map((c) => parseFloat(c));
      const month = value[0];
      const year = value[1];

      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const numberOfDigitsInYear = 2;
      const currentYear = parseFloat(
        today.getFullYear().toString().substr(-numberOfDigitsInYear)
      );

      let condition;

      if (year < currentYear) {
        condition = false;
      } else if (year === currentYear) {
        condition = month >= currentMonth;
      } else {
        condition = true;
      }

      return setupErrorMessageObject(condition, name);
    };

    return validator;
  }

  static lettersNumbersAndSymbols(name: string, symbols = []): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow for optional controls
      }

      let pattern = '';

      switch (name) {
        case 'lettersAndSymbols':
          pattern = '^([a-zA-Z]';
          break;
        case 'numbersAndSymbols':
          pattern = '^([0-9]';
          break;
        case 'lettersAndNumbersAndSymbols':
          pattern = '^([a-zA-Z0-9]';
          break;
      }

      symbols.forEach((symbol) => {
        if (symbol === 'SPACE') {
          pattern += '|\\s';
        } else {
          pattern += `|\\${symbol}`;
        }
      });

      pattern += ')*$';

      const errorMessage = {};
      errorMessage[name] = {
        name: name,
      };

      const regex = new RegExp(pattern);

      const returnObj = {};
      returnObj[name] = { name: name };

      return regex.test(control.value) ? null : errorMessage;
    };

    return validator;
  }

  /**
   * Handles blacklisting characters
   * **/
  static blacklistCharacters(
    _: any,
    blacklist: string[] | string,
    name: string
  ): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const blacklistKey = {};
      const blacklistArr = Array.isArray(blacklist)
        ? blacklist
        : blacklist.split(',');
      blacklistArr.forEach(function (blacklistVal) {
        const charCode = blacklistVal.charCodeAt(0);
        blacklistKey[charCode] = true;
      });

      const controlValue = control.value || '';
      const largestControlCharacter = 31;
      const largestASCIIChar = 126;

      let valid = true;
      for (let i = 0; i < controlValue.length; i++) {
        const charCode = controlValue.charCodeAt(i);
        if (
          charCode <= largestControlCharacter ||
          charCode >= largestASCIIChar ||
          blacklistKey[charCode]
        ) {
          valid = false;
          break;
        }
      }

      return setupErrorMessageObject(valid, name);
    };

    return validator;
  }

  static sepQualifyingDate(name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || !isValidDate(control.value)) {
        return null; // don't validate empty and invalid date values
      }
      const daysToCheck = 60;
      const controlDateValue = getDateWithoutTimezoneOffset(control.value);
      const controlDate = new Date(controlDateValue);
      const currentDate = new Date();

      const daysBetween = numDaysBetweenDates(controlDate, currentDate);
      const condition =
        daysBetween <= daysToCheck && daysBetween >= -daysToCheck;

      // return error message
      return setupErrorMessageObject(condition, name);
    };

    return validator;
  }

  static reasonableDate(name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const controlDateValue = getDateWithoutTimezoneOffset(
        control.value
      ).getTime();

      if (Number.isNaN(controlDateValue)) {
        return null;
      }

      const controlDate = new Date(controlDateValue);
      const condition =
        controlDate.getFullYear() < reasonableFutureDate &&
        controlDate.getFullYear() > reasonablePastDate;

      // return error message
      return setupErrorMessageObject(condition, name);
    };

    return validator;
  }

  static validDate(name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }
      const condition = isValidDate(control.value);

      // return the error
      return setupErrorMessageObject(condition, name);
    };

    return validator;
  }

  static dateInFutureIncludingCurrentMonth(name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty date values
      }

      // control.value is always timed at 00:00.000
      // currentDate must also be time agonostic
      const currentDate = getDateWithoutTimezoneOffset().setHours(0, 0, 0, 0);
      const controlDateValue = getDateWithoutTimezoneOffset(
        control.value
      ).getTime();

      if (Number.isNaN(controlDateValue)) {
        return null; // don't validate invalid date values
      }

      const condition =
        controlDateValue > currentDate ||
        sameMonth(currentDate, controlDateValue);

      // return error message
      return setupErrorMessageObject(condition, name);
    };

    return validator;
  }

  static dateInFuture(name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || !isValidDate(control.value)) {
        return null; // don't validate empty and invalid date values
      }

      // control.value is always timed at 00:00.000
      // currentDate must also be time agonostic
      const currentDate = getDateWithoutTimezoneOffset().setHours(0, 0, 0, 0);
      const controlDateValue = getDateWithoutTimezoneOffset(
        control.value
      ).getTime();
      const condition = controlDateValue > currentDate;

      // return error message
      return setupErrorMessageObject(condition, name);
    };

    return validator;
  }

  static dateInPast(name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || !isValidDate(control.value)) {
        return null; // don't validate empty and invalid date values
      }

      // control.value is always timed at 00:00.000
      // currentDate must also be time agonostic
      const currentDate = getDateWithoutTimezoneOffset().setHours(0, 0, 0, 0);
      const controlDateValue = getDateWithoutTimezoneOffset(
        control.value
      ).getTime();
      const condition = controlDateValue < currentDate;

      // return error message
      return setupErrorMessageObject(condition, name);
    };

    return validator;
  }

  static checkPercentage(
    dependency: {
      maxNum: number;
      percentage: number;
    },
    name: string
  ): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const base_10_radix = 10;
      const one_hundred_percent = 100;

      const inputValue = Number.parseInt(control.value, base_10_radix);
      const perc = dependency.percentage / one_hundred_percent;

      // check condition
      const condition = dependency.maxNum * perc <= inputValue;

      // return error message
      return setupErrorMessageObject(condition, name);
    };

    return validator;
  }

  /**
   * Validate that user's date is no more than X days in the future
   * @param days
   * **/
  static dateWithinDaysFuture(days: number, name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || !isValidDate(control.value)) {
        return null; // don't validate empty and invalid date values
      }

      // get date that is x days in future
      const futureDate = new Date();
      const numberOfDays = typeof days === 'number' ? days : parseInt(days, 10);
      futureDate.setDate(futureDate.getDate() + numberOfDays);

      // check condition
      const condition = lessThanOrEqualToDate(
        control.value,
        getFormattedDate(futureDate)
      );

      // return error message
      return setupErrorMessageObject(condition, name);
    };

    return validator;
  }

  /**
   * Validate that user's date is no more than X days in the past
   * @param days
   * **/
  static dateWithinDaysPast(days: number, name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || !isValidDate(control.value)) {
        return null; // don't validate empty and invalid date values
      }

      // get date that is x days in future
      const pastDate = new Date();
      pastDate.setDate(pastDate.getDate() - days);

      // check condition
      const condition = greaterThanOrEqualToDate(
        control.value,
        getFormattedDate(pastDate)
      );

      return setupErrorMessageObject(condition, name);
    };

    return validator;
  }

  /**
   * Validate that user's date is no more than X days in the future
   * @param days
   * **/
  static dateInFutureWithinDays(days: number, name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || !isValidDate(control.value)) {
        return null; // don't validate empty and invalid date values
      }

      // get date that is x days in future
      const numberOfDays = typeof days === 'number' ? days : parseInt(days, 10);
      const futureDate = new Date();
      futureDate.setDate(futureDate.getDate() + numberOfDays);

      // check condition
      const condition = lessThanOrEqualToDate(
        control.value,
        getFormattedDate(futureDate)
      );

      // return error message
      return setupErrorMessageObject(condition, name);
    };

    return validator;
  }

  /**
   * Validate that user's date is in past and no more than X days in the past
   * @param days
   * **/
  static dateInPastWithinDays(days: number, name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || !isValidDate(control.value)) {
        return null; // don't validate empty and invalid date values
      }

      // get date that is x days in future
      const pastDate = new Date();
      pastDate.setDate(pastDate.getDate() - days);

      // check condition
      const condition =
        lessThanOrEqualToDate(control.value, null) &&
        greaterThanOrEqualToDate(control.value, getFormattedDate(pastDate));

      return setupErrorMessageObject(condition, name);
    };

    return validator;
  }

  /**
   * Does date fall within range by count
   * @param day
   * **/
  static dateInRangeCount(
    days: {
      past: { count: number; skipWeekend: boolean };
      future: { count: number; skipWeekend: boolean };
    },
    name: string
  ): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || !isValidDate(control.value)) {
        return null;
      }

      const minPastDate = decrementDate(
        '',
        days.past.count,
        days.past.skipWeekend
      );
      const maxFutureDate = incrementDate(
        '',
        days.future.count,
        days.future.skipWeekend
      );

      const isValid =
        greaterThanOrEqualToDate(control.value, minPastDate) &&
        lessThanOrEqualToDate(control.value, maxFutureDate);

      return setupErrorMessageObject(isValid, name);
    };

    return validator;
  }

  /**
   * Show error when user entes too few characters
   * @param count minimum characters allowed
   * **/
  static minCount(count: number, name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      const isValid = !control.value || control.value.length >= count;

      return setupErrorMessageObject(isValid, name);
    };

    return validator;
  }

  /**
   * Show error when user enters too many characters
   * @param count how many characters allowed
   * **/
  static maxCount(count: number, name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      const isValid = !control.value || control.value.length <= count;

      return setupErrorMessageObject(isValid, name);
    };

    return validator;
  }

  /**
   * confirm date is not blacklisted
   * @param date Array of blacklisted dates by day of month (1-31)
   * **/
  static dateNotBlacklisted(
    days: number[] | string,
    name: string
  ): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || !isValidDate(control.value)) {
        return null;
      }

      const daysArr = Array.isArray(days) ? days : days.split(',');
      const numDate = getDay(control.value);
      const isBlackListed = daysArr.some(function (day) {
        return day === numDate;
      });

      return setupErrorMessageObject(!isBlackListed, name);
    };

    return validator;
  }

  static birthDate(name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      if (control.value && !isValidDate(control.value)) {
        return setupErrorMessageObject(false, name); // Error need to set for non mat input field.
      }

      const dateToCheck = new Date(control.value);
      const lowDate = new Date('1900-01-01');
      const highDate = new Date();
      const condition = dateToCheck > lowDate && dateToCheck < highDate;

      return setupErrorMessageObject(condition, name);
    };

    return validator;
  }

  static equals(value: any, name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const currentValue = control.value;

      return setupErrorMessageObject(value === currentValue, name);
    };

    return validator;
  }

  /**
   * set error on control when the value is equal to the input value
   * @param value the value the user input can not be equal to
   * **/
  static notEqual(value: any, name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const currentValue = control.value;

      return setupErrorMessageObject(value !== currentValue, name);
    };

    return validator;
  }

  static not_match(
    form: UntypedFormGroup | UntypedFormArray,
    dependency: string,
    name: string
  ): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const targetControl = form.get(dependency);

      let targetValue;
      let currentValue;
      if (targetControl) {
        targetValue = targetControl.value;
        currentValue = control.value;
      }

      return setupErrorMessageObject(
        targetControl && targetValue !== currentValue,
        name
      );
    };

    return validator;
  }

  static match(
    form: UntypedFormGroup | UntypedFormArray,
    dependency: string,
    name: string
  ): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const targetControl = form.get(dependency);
      const targetValue = targetControl.value;
      const currentValue = control.value;

      return setupErrorMessageObject(targetValue === currentValue, name);
    };

    return validator;
  }

  static matchCaseInsensitive(
    form: UntypedFormGroup | UntypedFormArray,
    dependency: string,
    name: string
  ): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const targetControl = form.get(dependency);
      const currentValue = control.value;
      const targetValue = Boolean(
        targetControl.value.localeCompare(currentValue, undefined, {
          sensitivity: 'accent',
        })
      );

      return setupErrorMessageObject(!targetValue, name);
    };

    return validator;
  }

  static exactLength(number: number, name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const length: number = control.value ? control.value.length : 0;

      return setupErrorMessageObject(length === number, name);
    };

    return validator;
  }

  // TODO: combine date validators
  static minDate(date: Date, name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (
        isEmptyInputValue(control.value) ||
        !isValidDate(control.value) ||
        !validateOnlyAfterReasonableMinimumAgeIsEntered(control.value)
      ) {
        return null; // don't validate empty and invalid date values
      }

      return setupErrorMessageObject(date < new Date(control.value), name);
    };

    return validator;
  }

  static maxDate(date: Date, name: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (
        isEmptyInputValue(control.value) ||
        !isValidDate(control.value) ||
        !validateOnlyAfterReasonableMinimumAgeIsEntered(control.value)
      ) {
        return null; // don't validate empty and invalid date values
      }

      return setupErrorMessageObject(date > new Date(control.value), name);
    };

    return validator;
  }

  static endDateDoesNotPreceedStartDate(
    startPropName: string,
    name: string
  ): ValidatorFn {
    const validator = (endDate: AbstractControl): ValidationErrors | null => {
      const parentControl = endDate?.parent;
      const startDate = parentControl?.controls['start'];

      if (startDate?.value && !isValidDate(startDate.value)) {
        return null; // don't validate empty and invalid date values
      }

      if (!parentControl) return null;
      if (!parentControl.value[startPropName]) return null;

      const start = parentControl.value[startPropName];
      const end = endDate.value || null;

      return setupErrorMessageObject(
        !start || !end || new Date(start) <= new Date(end),
        name
      );
    };

    return validator;
  }

  /**
   * Validator to ensure date is the last day of the month
   * @returns validation errors
   */
  static endOfMonth(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const name = 'endOfMonth';
      const date = getDateWithoutTimezoneOffset(control.value);

      // Logic shamelessly stolen from: https://stackoverflow.com/a/6355083/6405192
      const testDate = new Date(date.getTime());
      testDate.setDate(testDate.getDate() + 1);
      const isLastDay = testDate.getDate() === 1;

      return setupErrorMessageObject(isLastDay, name);
    };
  }

  /**
   * Validator to ensure date is the last day of the year
   * @returns validation errors
   */
  static endOfYear(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const name = 'endOfYear';
      const dateString = control.value;
      const date = getDateWithoutTimezoneOffset(control.value);
      const isLastDayOfYear = dateString === `${date.getFullYear()}-12-31`;

      return setupErrorMessageObject(isLastDayOfYear, name);
    };
  }

  /**
   * Throw an error if control date comes before previously entered date
   * ALWAYS prefix date with getValue:
   * @param date path to find previous date (use path from controls raw parent object as default)
   * @param name dateDoesNotPreceedPreviousDate
   * @param useRoot when flagged use path from form root
   * @param canEqual vallidation pass if dates are equal
   */
  static dateDoesNotPreceedPreviousDate(
    date: Date | string,
    name: string,
    useRoot = false,
    canEqual = false
  ): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (
        isEmptyInputValue(control.value) ||
        !isValidDate(control.value) ||
        !validateOnlyAfterReasonableMinimumAgeIsEntered(control.value)
      ) {
        return null; // don't validate empty and invalid date values
      }

      const currentDate = control.value;
      let previousDate = date;

      if (typeof date === 'string' && date.indexOf('getValue:') >= 0) {
        if (useRoot) {
          const previousDateCtrl = control.root.get(date.split(':')[1].trim());
          previousDate = previousDateCtrl && previousDateCtrl.value;
        } else {
          previousDate = getValue(control.parent, date.split(':')[1].trim());
        }
      }

      if (previousDate) {
        return canEqual
          ? setupErrorMessageObject(
              new Date(previousDate) <= new Date(currentDate),
              name
            )
          : setupErrorMessageObject(
              new Date(previousDate) < new Date(currentDate),
              name
            );
      } else {
        return null;
      }
    };

    return validator;
  }

  /**
   * Throw an error if control date after before previously entered date
   * ALWAYS prefix date with getValue:
   * @param date path to find previous date (use path from controls raw parent object as default)
   * @param name dateDoesNotExceedPreviousDate
   * @param useRoot when flagged use path from form root
   * @param should vallidation pass if dates are equal
   */
  static dateDoesNotExceedPreviousDate(
    date: Date | string,
    name: string,
    useRoot = false,
    canEqual = false
  ): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value) || !isValidDate(control.value)) {
        return null; // don't validate empty and invalid date values
      }

      const currentDate = control.value;
      let previousDate = date;

      if (typeof date === 'string' && date.indexOf('getValue:') >= 0) {
        if (useRoot) {
          const previousDateCtrl = control.root.get(date.split(':')[1].trim());
          previousDate = previousDateCtrl && previousDateCtrl.value;
        } else {
          previousDate = getValue(control.parent, date.split(':')[1].trim());
        }
      }

      if (previousDate) {
        return canEqual
          ? setupErrorMessageObject(
              new Date(previousDate) >= new Date(currentDate),
              name
            )
          : setupErrorMessageObject(
              new Date(previousDate) > new Date(currentDate),
              name
            );
      } else {
        return null;
      }
    };

    return validator;
  }

  static minAge(name: string, age, dateProp): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (
        isEmptyInputValue(control.value) ||
        !isValidDate(control.value) ||
        !validateOnlyAfterReasonableMinimumAgeIsEntered(control.value)
      ) {
        return null; // don't validate empty and invalid date values
      }
      let dateToUseForCompare;
      let controlDate;
      const valueFromForm = dateProp
        ? getValue(control.parent, dateProp)
        : new Date().getTime();
      if (valueFromForm) {
        const dateFromForm = new Date(valueFromForm);
        dateToUseForCompare = new Date(
          dateFromForm.getFullYear() - age,
          dateFromForm.getMonth(),
          dateFromForm.getDate()
        );
        controlDate = new Date(control.value);
      }

      return setupErrorMessageObject(
        valueFromForm ? dateToUseForCompare > controlDate : null,
        name
      );
    };

    return validator;
  }

  // Check whether user is N years old on or by the last day of the month in dateProp
  static minAgeWithinMonth(name: string, age, dateProp): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (
        isEmptyInputValue(control.value) ||
        !isValidDate(control.value) ||
        !validateOnlyAfterReasonableMinimumAgeIsEntered(control.value)
      ) {
        return null; // don't validate empty and invalid date values
      }

      let compareDate;
      let controlDate;
      const valueFromForm = dateProp
        ? getValue(control.parent, dateProp)
        : new Date().toLocaleDateString();
      if (valueFromForm) {
        const safeValue = valueFromForm.replace('-', '/'); // avoid off-by-one errs
        const dateFromForm = new Date(safeValue);
        compareDate = new Date(
          dateFromForm.getFullYear() - age,
          dateFromForm.getMonth() + 1,
          0
        ); // get last day of month
        controlDate = new Date(control.value);
      }

      return setupErrorMessageObject(
        valueFromForm ? compareDate > controlDate : null,
        name
      );
    };

    return validator;
  }

  static maxAge(name: string, age, dateProp, dateValue): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (
        isEmptyInputValue(control.value) ||
        !isValidDate(control.value) ||
        !validateOnlyAfterReasonableMinimumAgeIsEntered(control.value)
      ) {
        return null; // don't validate empty and invalid date values
      }

      let dateToUseForCompare;
      let controlDate;
      let referenceDate;
      const valueFromForm = dateProp ? getValue(control.parent, dateProp) : '';
      if (valueFromForm) {
        referenceDate = valueFromForm;
      } else if (dateValue) {
        referenceDate = dateValue;
      } else {
        referenceDate = new Date().getTime();
      }

      if (referenceDate) {
        const safeValue =
          typeof referenceDate === 'number'
            ? new Date(referenceDate).toISOString()
            : referenceDate.toString().split('-').join('/');
        const dateFromForm = new Date(safeValue);
        dateToUseForCompare = new Date(
          dateFromForm.getFullYear() - age,
          dateFromForm.getMonth(),
          dateFromForm.getDate()
        );
        const controlSafeValue = control.value.split('-').join('/');
        controlDate = new Date(controlSafeValue);
      }

      return setupErrorMessageObject(
        referenceDate ? dateToUseForCompare < controlDate : null,
        name
      );
    };

    return validator;
  }

  static maxAgeAndPropCheck(
    name: string,
    age: number,
    dateProp: string,
    otherProp: string,
    intendedOtherPropValue: any
  ): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (
        isEmptyInputValue(control.value) ||
        !isValidDate(control.value) ||
        !validateOnlyAfterReasonableMinimumAgeIsEntered(control.value)
      ) {
        return null; // don't validate empty and invalid date values
      }

      let dateToUseForCompare;
      let controlDate;
      let otherPropCheck;
      const dateControl = getValue(control.parent, dateProp);
      const otherPropControl = getValue(control.parent, otherProp);
      const valueFromForm = dateControl.value;
      const otherValueFromForm = otherPropControl.value;

      if (valueFromForm) {
        const dateFromForm = new Date(valueFromForm);
        dateToUseForCompare = new Date(
          dateFromForm.getFullYear() - age,
          dateFromForm.getMonth(),
          dateFromForm.getDate()
        );
        controlDate = new Date(control.value);
      }

      otherPropCheck = true;

      if (typeof otherValueFromForm !== 'undefined') {
        otherPropCheck = otherValueFromForm === intendedOtherPropValue;
      }

      return setupErrorMessageObject(
        valueFromForm
          ? dateToUseForCompare < controlDate || otherPropCheck
          : null,
        name
      );
    };

    return validator;
  }

  static updateControl(targetProp: string): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      const targetControl = getValue(control.parent, targetProp);

      if (targetControl) {
        targetControl.updateValueAndValidity();
      }

      return null;
    };

    return validator;
  }

  /**
   * @description Compare a max value against the aggregate values of a given property for each form group in a form
   *     array
   * @param form parent form group to the control (array is the root)
   * @param max max number
   * @param prop property to compare between forms
   */
  static maxInArr(form: UntypedFormGroup, max: number, prop: string) {
    const validator = (): ValidationErrors | null => {
      const formArr = form.root;
      let totalCountInArr = 0;

      if (formArr instanceof UntypedFormArray) {
        totalCountInArr = formArr.controls.reduce(function (
          runningCount,
          temp_form
        ) {
          const count =
            temp_form.get(prop) &&
            !isNaN(Number(temp_form.get(prop).value)) &&
            Number(temp_form.get(prop).value);

          return !count ? runningCount : runningCount + count;
        },
        0);
      }

      return setupErrorMessageObject(totalCountInArr <= max, 'maxInArr');
    };

    return validator;
  }

  /**
   * @description Ensure the control value is less than that of another control in the same form
   * @param form parent form group to the control
   * @param compareTo property in form to compare against
   * @param useRoot get compare value from root of form
   */
  static lessThan(form: UntypedFormGroup, compareTo: string, useRoot = false) {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      const hasCompare = useRoot
        ? form.root.get(compareTo) && form.root.get(compareTo).value
        : form.get(compareTo) && form.get(compareTo).value;

      return !hasCompare || !control.value || isNaN(Number(control.value))
        ? null
        : setupErrorMessageObject(
            Number(control.value) <= hasCompare,
            'lessThan'
          );
    };

    return validator;
  }

  /**
   * @description Required only if available (has options, not disabled);
   * **/
  static requiredIfAvailable(config, name: string): ValidatorFn {
    const validator = (control): ValidationErrors | null => {
      const required =
        config.isDisabled || (config.options && !(config.options.length > 1));
      const empty = isEmptyInputValue(control.value);

      return required ? null : setupErrorMessageObject(!empty, name);
    };

    return validator;
  }

  /**
   * @description Date can be empty or have value - date can't be partial (01/01/yyyy)
   * @param dependency id of input
   * **/
  static dateEmptyOrComplete(dependency: string, name: string): ValidatorFn {
    const validator = (): ValidationErrors | null => {
      const input: HTMLInputElement = document.querySelector(`#${dependency}`);
      const badInput = getValue(input, 'validity.badInput');

      return setupErrorMessageObject(!badInput, name);
    };

    return validator;
  }

  /**
   * @description some element in array must have value (including bools & 0)
   * pass: [null, null, false];
   * fail: [null, undefined, NaN, ""]
   * **/
  static arrayHasValue(): ValidatorFn {
    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !Array.isArray(control.value)) {
        return null;
      }

      const someValue = control.value.some(function (value) {
        return hasValue(value, true, true);
      });

      return setupErrorMessageObject(someValue, 'arrayHasValue');
    };

    return validator;
  }

  /**
   * Extension of angular pattern validator
   * Allows the ability to provide a name that can be used as a representative of that pattern
   *
   *
   * ORIGINAL DESCRIPTION
   * @description
   * Validator that requires the control's value to match a regex pattern. This validator is also
   * provided
   * by default if you use the HTML5 `pattern` attribute.
   *
   * @usageNotes
   *
   * ### Validate that the field only contains letters or spaces
   *
   * ```typescript
   * const control = new FormControl('1', Validators.pattern('[a-zA-Z ]*'));
   *
   * console.log(control.errors); // {pattern: {requiredPattern: '^[a-zA-Z ]*$', actualValue: '1'}}
   * ```
   *
   * ```html
   * <input pattern="[a-zA-Z ]*">
   * ```
   *
   * @returns A validator function that returns an error map with the
   * `pattern` property if the validation check fails, otherwise `null`.
   */
  static patternWithName(pattern: string | RegExp, name: string): ValidatorFn {
    if (!pattern) {
      return Validators.nullValidator;
    }
    let regex: RegExp;
    let regexStr: string;
    if (typeof pattern === 'string') {
      regexStr = '';

      if (pattern.charAt(0) !== '^') {
        regexStr += '^';
      }

      regexStr += pattern;

      if (pattern.charAt(pattern.length - 1) !== '$') {
        regexStr += '$';
      }

      regex = new RegExp(regexStr);
    } else {
      regexStr = pattern.toString();
      regex = pattern;
    }

    const validator = (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }
      const value: string = control.value;

      const errorMessage = {};
      errorMessage[name] = {
        requiredPattern: regexStr,
        actualValue: value,
        name: name,
      };

      return regex.test(value) ? null : errorMessage;
    };

    return validator;
  }

  static asyncValidationCheck(
    dependency,
    name: string,
    prop: string,
    http: HttpClient
  ): AsyncValidatorFn {
    const validator = (control: AbstractControl) => {
      const previousValueCheck =
        !control.hasOwnProperty('previousValue') ||
        (control.hasOwnProperty('previousValue') &&
          control['previousValue'] !== control.value);
      if (previousValueCheck) {
        const apiEndpoint = stringBuilder(
          `${dependency.endpoint}`,
          Object.assign({}, { [prop]: control.value })
        );

        return http.get(apiEndpoint).pipe(
          map(() => handleAsyncResult(control, true, name)),
          catchError(() => of(handleAsyncResult(control, false, name)))
        );
      } else {
        return of(control['asyncCache'][control.value]);
      }
    };

    return validator;
  }

  static mbi(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      const validationResult = MBI_REGEX.test(control.value);

      return setupErrorMessageObject(validationResult, 'mbi');
    };
  }
}
