<span
  #content
  *ngIf="!linkConfig.button"
  class="link u-maxX"
  [ngClass]="{
    't-bold': linkConfig.bold,
    't-link': !linkConfig.removeLinkStyles
  }"
  role="link"
  tabindex="0"
  (click)="
    linkConfig.useExternalSiteModal
      ? openModal($event)
      : handleLinkClick($event, linkConfig)
  "
  [dataNavEvent]="getLinkEvent()"
  [attr.aria-label]="'Link to ' + linkConfig.label"
>
  <ng-content></ng-content>
</span>
<zip-button
  *ngIf="linkConfig.button"
  (click)="
    linkConfig.useExternalSiteModal
      ? openModal($event)
      : handleLinkClick($event, linkConfig)
  "
  [config]="linkConfig.button"
></zip-button>

<ng-container *ngIf="externalSiteModalConfig">
  <external-site-warning-modal
    [isModalOpen]="isModalOpen"
    [externalSiteModalConfig]="externalSiteModalConfig"
    [linkConfig]="linkConfig"
    (closeModalClicked)="closeModal()"
    (continueClicked)="handleLinkClick($event, linkConfig)"
  ></external-site-warning-modal>
</ng-container>
