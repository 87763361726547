<div
  class="Ripple"
  [ngClass]="{
    'Ripple__IconLoadingWrapper--full': isFullPage,
    'Ripple__IconLoadingWrapper--flex-center': flexCenter,
    'Ripple__IconLoadingWrapper--relative': positionRelative
  }"
>
  <svg
    [attr.width]="getSize()"
    [attr.height]="getSize()"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    style="
      shape-rendering: auto;
      animation-play-state: running;
      animation-delay: 0s;
      background: none;
    "
  >
    <circle
      cx="50"
      cy="50"
      r="25.051"
      fill="none"
      stroke="var(--ripple-color, var(--primary))"
      stroke-width="4"
      style="animation-play-state: running; animation-delay: 0s"
    >
      <animate
        attributeName="r"
        calcMode="spline"
        values="0;45"
        keyTimes="0;1"
        dur="1.5"
        keySplines="0 0.2 0.8 1"
        begin="-0.75s"
        repeatCount="indefinite"
        style="animation-play-state: running; animation-delay: 0s"
      ></animate>
      <animate
        attributeName="opacity"
        calcMode="spline"
        values="1;0"
        keyTimes="0;1"
        dur="1.5"
        keySplines="0.2 0 0.8 1"
        begin="-0.75s"
        repeatCount="indefinite"
        style="animation-play-state: running; animation-delay: 0s"
      ></animate>
    </circle>

    <circle
      cx="50"
      cy="50"
      r="43.6082"
      fill="none"
      stroke="var(--ripple-color, var(--primary))"
      stroke-width="4"
      style="animation-play-state: running; animation-delay: 0s"
    >
      <animate
        attributeName="r"
        calcMode="spline"
        values="0;45"
        keyTimes="0;1"
        dur="1.5"
        keySplines="0 0.2 0.8 1"
        begin="0s"
        repeatCount="indefinite"
        style="animation-play-state: running; animation-delay: 0s"
      ></animate>
      <animate
        attributeName="opacity"
        calcMode="spline"
        values="1;0"
        keyTimes="0;1"
        dur="1.5"
        keySplines="0.2 0 0.8 1"
        begin="0s"
        repeatCount="indefinite"
        style="animation-play-state: running; animation-delay: 0s"
      ></animate>
    </circle>
  </svg>
</div>
