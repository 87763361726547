import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ButtonModule, FormControlModule } from '@zipari/design-system';
import { EligibilityUsersService } from '../eligibility-users.service';

import { EligibilityUserDetailComponent } from './eligibility-user-detail.component';

@NgModule({
  declarations: [EligibilityUserDetailComponent],
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    FormControlModule,
  ],
  providers: [EligibilityUsersService],
})
export class EligibilityUserDetailModule {}
