/* eslint-disable @typescript-eslint/naming-convention */
export enum Country {
  UnitedStates = 'United States',
  Canada = 'Canada',
  Other = 'Other',
}

export enum CountryCode {
  UnitedStates = 'US',
  Canada = 'CA',
  Other = 'OT',
}
