import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  BadgeModule,
  ButtonModule,
  CardModule,
  FormControlModule,
  FormGroupModule,
  MessageBannerModule,
  ModalModule,
  ModelAttributeModule,
  FileUploaderModule,
} from '@zipari/design-system';
import { ZipBusyModule } from '../../../shared/modules/zip-busy/zip-busy.module';
import { FlyoutDiagnosisModule } from '../../shared/modules/flyout-diagnosis/flyout-diagnosis.module';
import { ClaimSubmissionComponent } from './claim-submission.component';
import { FlyoutDropdownModule } from './controls/flyout-dropdown/flyout-dropdown.module';
import { FlyoutPopupModule } from './controls/flyout-popup/flyout-popup.module';
import { ServiceDetailsModule } from './controls/service-details/service-details.module';

@NgModule({
  declarations: [ClaimSubmissionComponent],
  exports: [ClaimSubmissionComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormGroupModule,
    ButtonModule,
    ZipBusyModule,
    ModelAttributeModule,
    CardModule,
    ModalModule,
    FormControlModule,
    ServiceDetailsModule,
    FlyoutDropdownModule,
    FlyoutDiagnosisModule,
    FileUploaderModule,
    BadgeModule,
    FlyoutPopupModule,
    MessageBannerModule,
  ],
})
export class ClaimSubmissionModule {}
