import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StatusComponent } from './status.component';
import {
  GetStatusTypeFromValuePipe,
  StatusBackgroundPipe,
  StatusFitContentPipe,
  StatusMapPipe,
} from './status.pipes';

@NgModule({
  declarations: [
    StatusComponent,
    StatusMapPipe,
    StatusBackgroundPipe,
    StatusFitContentPipe,
    GetStatusTypeFromValuePipe,
  ],
  imports: [CommonModule],
  exports: [StatusComponent, GetStatusTypeFromValuePipe],
})
export class StatusModule {}
