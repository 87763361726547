export const ClaimAppealProps = {
  dateProp: 'dateOfInitialDetermination',
  disputeTypeProp: 'disputeType',
  documentType: 'documentType',
  reasonOfLateFiling: 'Question_reasonOfLateFiling',
  documentCategory: 'document_category',
  sameAsRequestor: 'sameAsRequestor',
  contactEmail: 'contactEmail',
  contactPhoneNumber: 'contactPhoneNumber',
  contactName: 'contactName',
  requestorName: 'requestorName',
  dateOfAppeal: 'dateOfAppeal',
  service: 'service',
  billingProviderInformation: 'billingProviderInformation',
  providerInformation: 'provider-information',
  requestingProvider: 'requestingProvider',
  providerState: 'providerState',
  facilityName: 'facilityName',
  providerFirstName: 'providerFirstName',
  providerLastName: 'providerLastName',
  facilityId: 'facilityId',
  billingFacilityLocationId: 'billing_facility_location_id',
  priority: 'priority',
  disputeTypeOther: 'disputeTypeOther',
};

export const AppealConstant = {
  typeOfServiceDescription: 'typeOfServiceDescription',
  redetermination: 'redetermination',
  reconsideration: 'reconsideration',
  label: 'label',
  valueProp: 'value',
  lineNumber: 'lineNumber',
  documentIds: 'document_ids',
  billingFacilityId: 'billing_facility_id',
  billingProviderId: 'billing_provider_id',
  billingProvider: 'billing_provider',
  eobDocumentId: 'eob_document_id',
  disputeTypeOther: 'other',
  submissionDate: 'submission_date',
  dateFormat: {
    format: 'DATE',
  },
  claimReconsideration: 'claim_reconsideration',
  claimRedetermination: 'claim_redetermination',
};

export enum AppealFormCurrentStep {
  ENTRY = 'entry',
  REVIEW = 'review',
  CONFIRMATION = 'confirmation',
}

export const DefaultProviderLocation = {
  address: {
    streetName1: '',
    streetName2: '',
    cityName: '',
    state: '',
    zipCode: '',
  },
  isInNetwork: false,
};

export const DefaultMemberNetwork = {
  isInNetwork: null,
};

export const ReadonlyFormControls = [
  'providerState',
  'facilityName',
  'providerFirstName',
  'providerLastName',
  'dateOfInitialDetermination',
  'providerStreetName1',
  'providerStreetName2',
  'providerCity',
  'providerZipCode',
  'providerNpi',
  'isNetworkProvider',
];

export const claimListApiEndpoint = '/provider-portal/claims/';
