import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentCenterState } from '../util/document-center.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentCenterStateService {
  private _state: BehaviorSubject<DocumentCenterState> = new BehaviorSubject(
    null as unknown as DocumentCenterState
  );
  private _state$ = this._state.asObservable();

  getState(): Observable<DocumentCenterState> {
    return this._state$;
  }

  setState(eventType: DocumentCenterState): void {
    this._state.next({ ...eventType });
  }
}
