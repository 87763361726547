import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ControlTextModule } from '../text/text.module';

import { OptionComponent } from '../shared/options/option.component';
import { OptionsDropdownComponent } from '../shared/options/options-dropdown.component';
import { IconModule } from '../../icon/icon.module';
import { PreloaderModule } from '../../preloader/preloader.module';
import { ControlTypeaheadComponent } from './control-typeahead.component';

@NgModule({
  declarations: [
    ControlTypeaheadComponent,
    OptionsDropdownComponent,
    OptionComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ControlTextModule,
    IconModule,
    PreloaderModule,
  ],
  exports: [
    ControlTypeaheadComponent,
    OptionsDropdownComponent,
    OptionComponent,
  ],
})
export class ControlTypeaheadModule {}
