import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocumentCenterComponent } from './document-center.component';
import { DocumentTableComponent } from './document-table/document-table.component';
import { DOCUMENTS_URLS } from './documentsConfig.model';
import { ResourcesComponent } from './resources/resources.component';

const routes: Routes = [
  {
    path: '',
    component: DocumentCenterComponent,
    children: [
      {
        path: DOCUMENTS_URLS.FORMS,
        component: DocumentTableComponent,
        data: {
          activeTab: DOCUMENTS_URLS.FORMS,
        },
      },
      {
        path: DOCUMENTS_URLS.TRAINING,
        component: DocumentTableComponent,
        data: {
          activeTab: DOCUMENTS_URLS.TRAINING,
        },
      },
      {
        path: DOCUMENTS_URLS.POLICY_GUIDELINES,
        component: DocumentTableComponent,
        data: {
          activeTab: DOCUMENTS_URLS.POLICY_GUIDELINES,
        },
      },
      {
        path: DOCUMENTS_URLS.RESOURCES,
        component: ResourcesComponent,
        data: {
          activeTab: DOCUMENTS_URLS.RESOURCES,
        },
      },
      {
        path: DOCUMENTS_URLS.FINACIAL_DOCUMENTS,
        component: DocumentTableComponent,
        data: {
          activeTab: DOCUMENTS_URLS.FINACIAL_DOCUMENTS,
        },
      },
      {
        path: '**',
        redirectTo: DOCUMENTS_URLS.FORMS,
        pathMatch: 'prefix',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DocumentCenterRoutingModule {}
