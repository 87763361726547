import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ButtonModule,
  CardModule,
  FormControlModule,
  FormGroupModule,
  IconModule,
  ModalModule,
  TableModule,
} from '@zipari/design-system';
import { PipeModule } from '../../../../shared/pipes/pipe.module';
import { SharedModule } from '../../../../shared/shared.module';
import { TableFlyoutComponent } from './table-flyout.component';

@NgModule({
  declarations: [TableFlyoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormControlModule,
    FormGroupModule,
    ButtonModule,
    SharedModule,
    PipeModule,
    CardModule,
    ModalModule,
    TableModule,
    IconModule,
  ],
  exports: [TableFlyoutComponent],
})
export class TableFlyoutModule {}
