import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EligibilityUser } from '../../../shared/models/shared/EligibilityUser.model';
import { ConfigService } from '../../../shared/services';

class EligibilityUserPageResponse {
  count: number;
  previous: number;
  next: number;
  results: EligibilityUser[];
}
@Injectable()
export class EligibilityUsersService {
  config: any;
  eligibilityUsersEndpoint: string;

  constructor(private configService: ConfigService, private http: HttpClient) {
    // this.updateEligibilityUser();
    this.loadConfig();
  }
  /** Load configuration from `tenant-configs` */
  loadConfig() {
    this.config = this.configService.getPageConfig('patients');
    this.eligibilityUsersEndpoint = this.config?.endpoint;
  }

  /** Request all eligibility users from the backend */
  getEligibilityUsersPage(
    page: number
  ): Observable<EligibilityUserPageResponse> {
    return this.http.get<EligibilityUserPageResponse>(this.pageUrl(page));
  }

  /** Composer URL for a particular page of the eligibility users endpoint. */
  pageUrl = (page: number) =>
    this.eligibilityUsersEndpoint?.concat(`?page=${page}`);

  /** Get a particular eligibility user from the backend */
  getEligibilityUser(name: string) {
    return this.http.get<EligibilityUser>(
      this.eligibilityUserUrl(name),
      this.applicationJson()
    );
  }

  createEligibilityUser(newUser: EligibilityUser): Observable<EligibilityUser> {
    return this.http.post<EligibilityUser>(
      this.eligibilityUsersEndpoint,
      newUser,
      this.applicationJson()
    );
  }

  /** Put edited eligibility user to the backend */
  editEligibilityUser(
    eligibilityUser: EligibilityUser
  ): Observable<EligibilityUser> {
    return this.http.put<EligibilityUser>(
      this.eligibilityUserUrl(eligibilityUser.user_name),
      eligibilityUser,
      this.applicationJson()
    );
  }

  /** Add deactivation data to eligibility user and PUT it to the backend  */
  deactivateUser(
    eligibilityUser: EligibilityUser,
    date: string,
    sendEmail: boolean
  ): Observable<EligibilityUser> {
    const deactivatedEligibilityUser = this.addDeactivationData(
      eligibilityUser,
      date,
      sendEmail
    );

    return this.editEligibilityUser(deactivatedEligibilityUser);
  }

  /** Add deactivation date and send deactivation email flag to a eligibility user's data */
  addDeactivationData(
    eligibilityUser: EligibilityUser,
    deactivationDate: string,
    sendEmail: boolean
  ): EligibilityUser {
    if (eligibilityUser.data) {
      eligibilityUser.data.deactivation_date = deactivationDate;
      eligibilityUser.data.send_deactivation_email = sendEmail;
    }

    return eligibilityUser;
  }

  applicationJson = () =>
    Object.assign(
      {},
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  eligibilityUserUrl = (name?: string) =>
    this.eligibilityUsersEndpoint?.concat(String(name));
}
