import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DigitalDataModule } from '../../shared/modules/digital-data/digital-data.module';

import { IconModule } from '../icon/icon.module';
import { RippleModule } from '../ripple/ripple.module';

import { ButtonComponent } from './button.component';

@NgModule({
  declarations: [ButtonComponent],
  imports: [CommonModule, IconModule, DigitalDataModule, RippleModule],
  exports: [ButtonComponent],
})
export class ButtonModule {}
