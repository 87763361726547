import { Location } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  AbstractControl,
} from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControlService } from '@zipari/design-system';
import { MessageBannerConfig } from '@zipari/shared-ds-util-messages';
import { getValue } from '@zipari/web-utils';
import * as _ from 'lodash';
import { isEmpty } from 'lodash';
import { forkJoin, Observable } from 'rxjs';

import ProductCoverage from '../../../shared/models/shared/ProductCoverage.model';
import { AnalyticsService } from '../../../shared/services/analytics.service';

import { GenericServices } from '../../shared/services/generic.service';
import { CX_CALLS } from '../../../shared/constants/cx-calls.constant';
import { ApiListResponse } from '../../../shared/models/shared/ApiListResponse.model';
import Member from '../../../shared/models/shared/Member.model';
import Policy from '../../../shared/models/shared/Policy.model';
import {
  APIService,
  ConfigService,
  FormattingService,
} from '../../../shared/services';
import { objectToArray } from '../../../shared/utilities/object';
import { defaultFileUploaderRestrictions } from '../../../shared/constants/file-uploader.constant';
import { UploadDocumentConfig } from '../../../shared/models/shared/FileUploader.model';
import {
  camelCase,
  toCamel,
} from '../../../shared/utilities/case-conversion.utils';
import { ProviderUpdateToolService } from '../provider-update-tool/provider-update-tool.service';
import { OutOfNetworkProps } from '../transfer-of-care/transfer-of-care-details.constants';
import {
  PractitionerDetails,
  SiteLocation,
} from '../provider-update-tool/site-locations-attestation/site-locations-attestation.model';
import {
  CONTROL_KEYS,
  uploadDocument,
  NoValue,
  ProcedureCodeCardKey,
  ProviderDisplayFields,
  ProvidersKeys,
  ProviderType,
} from './authorization-submission.constants';
import {
  authConstants,
  IsPrimaryProp,
} from './authorization-submission.constants';
import { McgClinicalReviewService } from './mcg-clinical-review.service';
import { AuthCardArray } from './authorization-submission.model';
import { DocumentUploadService } from '../claim-appeal/services/document-upload.service';
import { getDefaultFileUploaderConfig } from '../../../shared/helpers/file-uploader.helper';

@Component({
  styleUrls: ['./authorization-submission.component.scss'],
  selector: 'authorization-submission',
  templateUrl: './authorization-submission.component.html',
})
export class AuthorizationSubmissionComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  @ViewChild('mcgIframe') frame: ElementRef;
  @ViewChild('mcgAuthRequestForm', { static: false })
  mcgAuthRequestForm: ElementRef;
  id: any;
  config: any = {};
  context: { member: Member; coverage?: ProductCoverage } = { member: null };
  state: 'edit' | 'review' | 'confirm' = 'edit';
  formGroup = new UntypedFormGroup({});
  controls: Map<any, any> = new Map<any, any>();
  payload: any = {};
  risks: any[] = [];
  busy: any[] = [];
  response: any;
  error = false;
  nonDependentEndpoints: string[];
  nonDependentEndpointStatus: boolean[] = [];
  loaded = false;
  noSpace = 'n/a';
  showCard1 = false;
  showCard2 = false;
  showOnly = false;
  diagnosisDetails: any;
  reviewServiceType = false;
  errorInDocumentUpload = false;
  uploadedDocs = [];
  jsonSubmissionObject = {};
  currentDocumentType: string;
  fileTooBig = false;
  hasFile: any = null;
  documentsAreEditable = true;
  cardArray: AuthCardArray[] = [];
  showAlert = false;
  enableCopyButton = false;
  _: any = _;
  showClinicalReviewModal = false;
  showIframe = false;
  mcgFormInputs: { input: string; value: string }[] = [];
  iframeSrc: SafeResourceUrl;
  episodeId: string;
  showIframeLoader = false;
  mcgAuthData: any;
  isAuthorizationOptionEnabled = false;
  authorizationDetailsContext: any;
  coverageApi: Observable<ApiListResponse<Policy>>;
  memberApi: Observable<Member>;
  readonly CONTROL_KEYS = CONTROL_KEYS;
  displayFieldsData: { label: any; value: any }[];
  providerDetails: SiteLocation | PractitionerDetails;
  documentContext: UntypedFormArray;
  defaultFileUploaderRestrictions = defaultFileUploaderRestrictions;

  constructor(
    private route: ActivatedRoute,
    public configService: ConfigService,
    public genericServices: GenericServices,
    private formControlService: FormControlService,
    public router: Router,
    private api: APIService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private location: Location,
    private el: ElementRef,
    public mp: ProviderUpdateToolService,
    public analyticsService: AnalyticsService,
    private formattingService: FormattingService,
    private sanitizer: DomSanitizer,
    private mcgService: McgClinicalReviewService,
    public documentUploadService: DocumentUploadService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.genericServices.showFooter$.next(false);
    });
    this.id = this.route.snapshot.params.id;

    this.config = this.configService.getPageConfig('authorization-submission');

    // delete the practitioner object if requesting provider support both site location and practitioner.
    if (this.config.allowLocationAsRequestingProvider) {
      delete this.config.cards['requesting_provider_card'];
    } else {
      delete this.config.cards['site-location-and-practitioner-provider'];
    }

    if (this.config) {
      for (const cardKey of _.keys(this.config?.cards)) {
        for (const controlKey of _.keys(
          this.config?.cards[cardKey]?.controls
        )) {
          this.config.cards[cardKey].controls = Object.keys(
            this.config?.cards[cardKey]?.controls
          )
            .sort((a, b) =>
              this.config?.cards[cardKey]?.controls[a]['controlOrder'] >
              this.config?.cards[cardKey]?.controls[b]['controlOrder']
                ? 1
                : -1
            )
            .reduce((result, key) => {
              result[key] = this.config.cards[cardKey].controls[key];

              return result;
            }, {});
          for (const [key, value] of Object.entries(
            this.config?.cards[cardKey]?.controls
          )) {
            if (
              controlKey &&
              key.indexOf(controlKey) !== -1 &&
              key.indexOf('flyout') !== -1 &&
              controlKey.indexOf('flyout') === -1
            ) {
              Object.assign(this.config?.cards[cardKey]?.controls[controlKey], {
                flyout: value,
              });
              this.config.cards[cardKey].controls[
                controlKey
              ].flyout.table.columns = objectToArray(value['table'].columns);
              this.config.cards[cardKey].controls[
                controlKey
              ].flyout.table.filters = objectToArray(value['table'].filters);
              delete this.config?.cards[cardKey]?.controls[key];
            }
          }
        }
      }
      this.config.cards = Object.keys(this.config.cards)
        .sort((a, b) =>
          this.config?.cards[a]['cardOrder'] >
          this.config?.cards[b]['cardOrder']
            ? 1
            : -1
        )
        .reduce((result, key) => {
          result[key] = this.config.cards[key];

          return result;
        }, {});
    }
    this.memberApi = this.api.get(
      this.setMemberId(this.config.endpoints.member)
    );
    this.coverageApi = this.api.get(
      this.setMemberId(this.config.endpoints.coverage)
    );
    this.noSpace = this.config?.noSpace;
    this.buildPanel();
    this.buildForms();
    if (this.documentUploadService.fileAttachments?.length > 0) {
      this.setFilesFromAPI(this.documentUploadService.fileAttachments);
    }
  }

  shouldAddRequestingProviderSiteLocationAndPractitioner(key: string): boolean {
    if (
      key.toLowerCase() ===
      CONTROL_KEYS.site_location_and_practitioner_provider.toLowerCase()
    ) {
      return this.config.allowLocationAsRequestingProvider;
    }

    return true;
  }

  buildPanel(): void {
    this.busy.push(
      forkJoin([this.memberApi, this.coverageApi]).subscribe(
        ([member, coverage]) => {
          this.context.member = member;
          this.context.coverage = coverage.results[0]?.product_coverages[0];
          if (
            this.context?.member?.additional_fields
              ?.enrolled_medicare_savings_program
          ) {
            this.context.member.additional_fields.enrolled_medicare_savings_program =
              ' ';
          }
          if (this.context.coverage) {
            this.context.coverage.effective_date = this.formatDate(
              this.context.coverage.effective_date
            );
            this.context.coverage.termination_date = this.formatDate(
              this.context.coverage.termination_date
            );
          }

          this.loaded = true;
        }
      )
    );
  }

  showOutOfNetworkStatusPill(key: string, controlKey: string): boolean {
    return (
      this.config?.outOfNetwork?.isOutOfNetworkEnabled &&
      this.formGroup.get(this.config?.cards[key]?.controls[controlKey]?.prop)
        ?.value?.member_network?.is_in_network === false
    );
  }

  setMemberId(target: string): string {
    return target?.replace('${member_number}', this.id);
  }

  checkShowOnTrueValues(attr): boolean {
    let targetDataExists: boolean;
    let value: string | number;

    if (attr.showIfTrue) {
      value = this.getValue(this.context, attr);
      targetDataExists = value?.toString().length > 0;
    } else return false;

    return targetDataExists;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getValue(data: object, attr, val?: boolean): string | number {
    const value: string | number = val
      ? getValue(data, attr.value)
      : getValue(data, attr.prop);

    return value;
  }

  buildForms() {
    if (this.config) {
      for (const cardKey of _.keys(this.config?.cards)) {
        if (
          this.config?.cards[cardKey]?.type !== authConstants.cardType ||
          this.config?.cards[cardKey]?.type === undefined
        ) {
          for (const controlKey of _.keys(
            this.config?.cards[cardKey]?.controls
          )) {
            if (controlKey === authConstants.controlType) {
              this.formGroup
                .get(
                  this.config?.cards[cardKey]?.controls[controlKey]?.ifThisProp
                )
                ?.valueChanges.subscribe((val: any) => {
                  this.controls.set(
                    this.config?.cards[cardKey]?.controls[controlKey]?.prop,
                    this.config?.cards[cardKey]?.controls[controlKey]
                  );
                  const control: AbstractControl = new UntypedFormControl(
                    '',
                    []
                  );
                  const newFormControl = Object.assign(
                    this.config?.cards[cardKey]?.controls[controlKey],
                    {
                      control: control,
                    }
                  );

                  this.endPointData(
                    this.config.cards[cardKey].controls[controlKey],
                    `?authorization_type=${val}`
                  );
                  this.formControlService.addControlToFormGroup(
                    this.formGroup,
                    newFormControl
                  );
                });
            } else {
              this.controls.set(
                this.config?.cards[cardKey]?.controls[controlKey]?.prop,
                this.config?.cards[cardKey]?.controls[controlKey]
              );
              const control: AbstractControl = new UntypedFormControl('', []);
              const newFormControl = Object.assign(
                this.config?.cards[cardKey]?.controls[controlKey],
                {
                  control: control,
                }
              );

              this.formControlService.addControlToFormGroup(
                this.formGroup,
                newFormControl
              );
              this.endPointData(
                this.config?.cards[cardKey]?.controls[controlKey]
              );
            }
          }
        } else if (
          this.config?.cards[cardKey]?.type === authConstants.cardType
        ) {
          this.formGroup
            .get(this.config?.cards[cardKey]?.ifThisProp)
            ?.valueChanges.subscribe((val: any) => {
              if (this.config?.cards[cardKey]?.loadCardFor === val) {
                this.cardArray = [];
                const dynamicControls: Map<any, any> = new Map<any, any>();
                const dynamicFormGroup = new UntypedFormGroup({});

                for (const controlKey of _.keys(
                  this.config?.cards[cardKey]?.controls
                )) {
                  dynamicControls.set(
                    this.config?.cards[cardKey]?.controls[controlKey]?.prop,
                    this.config?.cards[cardKey]?.controls[controlKey]
                  );
                  const control: AbstractControl = new UntypedFormControl(
                    '',
                    []
                  );
                  const newFormControl = Object.assign(
                    this.config?.cards[cardKey]?.controls[controlKey],
                    {
                      control: control,
                    }
                  );

                  this.formControlService.addControlToFormGroup(
                    dynamicFormGroup,
                    newFormControl
                  );
                  if (this.config?.cards[cardKey]?.controls[controlKey]) {
                    this.endPointData(
                      this.config?.cards[cardKey]?.controls[controlKey]
                    );
                  }
                  if (
                    this.config?.cards[cardKey]?.controls[controlKey]?.prop ===
                    IsPrimaryProp
                  ) {
                    if (!this.checkForPrimaryCardAvailable()) {
                      dynamicFormGroup
                        .get(
                          this.config?.cards[cardKey]?.controls[controlKey]
                            ?.prop
                        )
                        .setValue(true);
                    }
                  }
                }
                this.cardArray.push({
                  card_to_load: this.config?.cards[cardKey],
                  group_to_load: dynamicFormGroup,
                  controls_to_load: dynamicControls,
                });
              }
            });
        }
      }
    }
  }

  switchTriggered(index) {
    const tempCardArr = [...this.cardArray];

    this.cardArray = tempCardArr.map((item: any, i: number) => {
      if (i !== index) {
        item?.group_to_load?.get('is_primary')?.setValue(false);
      }

      return { ...item };
    });
  }

  cardToLoadValues(card): boolean {
    let targetDataExists: boolean;

    if (card.loadCardFor === this.formGroup.get(card.ifThisProp).value) {
      targetDataExists = this.cardArray.length > 0;
    } else return false;

    return targetDataExists;
  }

  addCard(id: any, index: number, addKey: string) {
    if (this.config) {
      for (const cardKey of _.keys(this.config?.cards)) {
        if (this.config?.cards[cardKey]?.loadCardFor === id && !addKey) {
          const dynamicControls: Map<any, any> = new Map<any, any>();
          const dynamicFormGroup = new UntypedFormGroup({});

          for (const controlKey of _.keys(
            this.config?.cards[cardKey].controls
          )) {
            if (controlKey === ProcedureCodeCardKey) {
              this.config.cards[cardKey].controls[controlKey].validators =
                this.cardArray[0]?.card_to_load?.controls[
                  controlKey
                ]?.validators;
            }
            dynamicControls.set(
              this.config?.cards[cardKey]?.controls[controlKey]?.prop,
              this.config?.cards[cardKey]?.controls[controlKey]
            );
            const control: AbstractControl = new UntypedFormControl('', []);
            const newFormControl = Object.assign(
              this.config?.cards[cardKey]?.controls[controlKey],
              {
                control: control,
              }
            );

            this.formControlService.addControlToFormGroup(
              dynamicFormGroup,
              newFormControl
            );
            this.endPointData(
              this.config?.cards[cardKey]?.controls[controlKey]
            );

            if (
              this.config?.cards[cardKey]?.controls[controlKey]?.prop ===
              'is_primary'
            ) {
              if (!this.checkForPrimaryCardAvailable()) {
                dynamicFormGroup
                  .get(this.config?.cards[cardKey]?.controls[controlKey]?.prop)
                  .setValue(true);
              }
            }
          }
          this.cardArray.push({
            card_to_load: this.config?.cards[cardKey],
            group_to_load: dynamicFormGroup,
            controls_to_load: dynamicControls,
            service: 'new_service',
          });
        } else if (
          this.config?.cards[cardKey]?.duplicateCard === id &&
          addKey
        ) {
          const duplicateFormGroup = _.cloneDeep(
            this.cardArray[index].group_to_load
          );

          duplicateFormGroup.get('is_primary')?.setValue(false);
          const duplicateControls = this.cardArray[index].controls_to_load;

          for (const controlKey of _.keys(
            this.config?.cards[cardKey]?.controls
          )) {
            const control: AbstractControl = new UntypedFormControl('', []);
            const newFormControl = Object.assign(
              this.config?.cards[cardKey]?.controls[controlKey],
              {
                control: control,
              }
            );
          }
          this.cardArray.push({
            card_to_load: this.config?.cards[cardKey],
            group_to_load: duplicateFormGroup,
            controls_to_load: duplicateControls,
            service: 'copy_service',
          });
        }
      }
    }
  }

  /**
   * Traverse the cardArray and return true if any of the card is assigned is_primary = true, else return false
   */
  checkForPrimaryCardAvailable(): boolean {
    let isPrimary = false;

    this.cardArray.forEach((card: any) => {
      if (card?.group_to_load.value.is_primary) {
        isPrimary = true;
      }
    });

    return isPrimary ? true : false;
  }

  checkFormField(index) {
    const group = this.cardArray[index]?.group_to_load;

    if (
      Object.values(group?.value).find(
        (value: any) => value !== '' && value !== null
      )
    ) {
      this.enableCopyButton = true;
    } else {
      this.enableCopyButton = false;
    }

    return this.enableCopyButton;
  }

  checkLimit(limit): boolean {
    if (limit) {
      if (
        limit ===
        this.cardArray.reduce(
          (i, obj) => i + Number(obj.service === 'new_service'),
          0
        )
      ) {
        return false;
      } else return true;
    } else {
      return true;
    }
  }

  deleteCard(index) {
    if (this.cardArray[index]?.group_to_load?.value?.is_primary) {
      this.showAlert = true;
    } else {
      this.showAlert = false;
    }
    this.cardArray.splice(index, 1);

    const temArray = [...this.cardArray];

    this.cardArray = temArray.map((item: any, ind: number) => {
      if (index === ind) {
        item?.group_to_load?.controls?.is_primary?.patchValue(true);
      }

      return { ...item };
    });
  }

  getState(updateVal) {
    this.state = updateVal;
  }

  endPointData(control, queryParam?: string) {
    if (
      control &&
      control?.outOfNetworkApiEndpoint &&
      this.config?.outOfNetwork?.isOutOfNetworkEnabled
    ) {
      const apiEndPoint = this.buildEndPoint(
        control.outOfNetworkApiEndpoint,
        queryParam
      );

      this.setDropdownOptions(control, apiEndPoint);
    } else if (control && control?.apiEndpoint) {
      const apiEndPoint = this.buildEndPoint(control.apiEndpoint, queryParam);

      this.setDropdownOptions(control, apiEndPoint);
    }
  }

  setDropdownOptions(control, apiEndPoint: string) {
    this.busy.push(
      this.api.get(apiEndPoint).subscribe((response: any) => {
        if (control?.optionFormat) {
          let obj;
          const dropdownArray = response.results?.map(
            (item: any, idx: number) => {
              let thing = '';

              control.optionFormat.forEach((format: any) => {
                thing += `${getValue(item, format)}`;
              });

              obj = {
                label: thing,
                value: idx,
              };

              return obj;
            }
          );

          control.options = this.populateDropdown(
            new Array<any>(),
            dropdownArray,
            control?.dropdownDisplayProp
          );
        } else {
          control.options = this.populateDropdown(
            new Array<any>(),
            response?.results,
            control.dropdownDisplayProp
          );
          if (control.addCustomValueInDropDown) {
            const customOption = {
              label: control.addCustomValueInDropDown,
              value: {
                code: '',
                description: control.addCustomValueInDropDown,
                id: null,
                name: '',
              },
            };

            control.options?.push(customOption);
          }
        }
      })
    );
  }

  buildEndPoint(apiUrl: string, queryParam?: string): string {
    return queryParam ? `${apiUrl}${queryParam}` : apiUrl;
  }

  print(): void {
    window?.print();
  }

  cancel(event: any): void {
    if (this.genericServices.previousURL.includes('provider-portal')) {
      this.location.back();
    }
  }

  backToMemberDetails(): void {
    const targetRoute = `/provider-portal/patients/${this.route.snapshot.params['id']}/benefits`;

    if (this.genericServices.previousURL.includes('provider-portal')) {
      this.router.navigate([targetRoute]);
    }
  }

  selectedDiagnosisDetails(event) {
    this.diagnosisDetails = event;
  }

  scrollTo(event: any): void {
    this.back(null);
    const dictionary_attributes = {
      authorization_type: this.formGroup.value?.authorization_type,
      type_of_service: this.formGroup.value?.service_type_id,
      place_of_service: this.formGroup.value?.place_of_service?.id,
      level_of_care: this.formGroup.value?.level_of_care?.id,
      requested_units: this.getCXValue('requested_units'),
      unit_type: this.getCXValue('unit_type'),
      priority: this.getCXValue('priority'),
      diagnosis_code: this.diagnosisDetails?.map(
        (element: any) => element?.row?.code
      ),
      diagnosis_description: this.diagnosisDetails?.map(
        (element: any) => element?.row?.description
      ),
      procedure_code: this.getCXValue('procedure_id', 'code'),
      procedure_description: this.getCXValue('procedure_id', 'description'),
    };

    this.analyticsService.sendEvent(
      CX_CALLS.pp_authorization_entry_edited.event_key,
      dictionary_attributes
    );
    setTimeout(() => {
      if (document && event) {
        const itemToScrollTo = document.getElementById(event);
        // null check to ensure that the element actually exists

        if (itemToScrollTo) {
          itemToScrollTo.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
  }

  getCXValue(cxKey, objKey = null) {
    const cxArray = [];

    this.cardArray.map((key: any) =>
      objKey && key.group_to_load?.value[cxKey]
        ? cxArray.push(key.group_to_load.value[cxKey][objKey])
        : key.group_to_load.value[cxKey]
        ? cxArray.push(key.group_to_load.value[cxKey])
        : ''
    );

    return cxArray.length > 0 ? cxArray : '';
  }

  populateDropdown(options: any[], results: any[], prop: string) {
    if (results && results.length > 0) {
      for (const item of results) {
        options.push({
          label: this.getObjectProperty(item, prop),
          value: item,
        });
      }
    }

    return options;
  }

  getObjectProperty(obj: any, key: any) {
    return key?.split('.').reduce(function (result: any, prop: any) {
      return result[prop];
    }, obj);
  }

  getDropdownDisplayValue(prop: string): string {
    const options: any[] = this.controls.get(prop).options;
    const option: any = options.find(
      (item: any) => item.value === this.formGroup.get(prop).value
    );

    if (option) return option.label;

    return this.noSpace;
  }

  back(event: any): void {
    if (this.state === 'review') this.state = 'edit';
  }

  checkValidation(): boolean {
    let lengthFlag = false;

    if (this.cardArray) {
      for (let i = 0; i < this.cardArray.length; i++) {
        if (this.formGroup.valid && this.cardArray[i]['group_to_load'].valid) {
          if (i !== this.cardArray.length - 1) continue;
          lengthFlag = true;
        } else {
          this.formGroup.markAllAsTouched();
          this.cardArray[i]['group_to_load'].markAllAsTouched();
          lengthFlag = false;
          setTimeout(() => {
            this.genericServices.scrollToFirstInvalidControl(this.el);
          });
        }
      }
    }

    return lengthFlag;
  }

  review(event: any): void {
    if (this.formGroup.value?.authorization_type) {
      const authTypePropName = toCamel(
        this.formGroup.value?.authorization_type
      );

      this.isAuthorizationOptionEnabled =
        this.config?.clinicalReview?.AuthorizationTypeOptions[authTypePropName];
    }

    if (this.formGroup.value?.requesting_provider) {
      this.providerDetails = this.formGroup.value?.requesting_provider;
      this.updateDisplayFields(this.formGroup.value?.requesting_provider);
    }
    if (this.checkValidation()) {
      if (this.state === 'edit') this.state = 'review';
      window.scrollTo(0, 0);
      this.analyticsService.sendEvent(
        this.config.footer.buttons.buttonReview.cx_event_key
      );
    } else {
      this.formGroup.markAllAsTouched();
      setTimeout(() => {
        this.genericServices.scrollToFirstInvalidControl(this.el);
      });
    }
  }

  updateDisplayFields(providerDetail): void {
    let updatedDisplayFields: [string, string][] = [];
    const providerCardConfig =
      this.config.cards['site-location-and-practitioner-provider'];

    if (providerDetail) {
      providerDetail.providerId &&
        providerCardConfig &&
        (updatedDisplayFields = Object.entries(
          providerCardConfig.controls['provider-flyout'].displayFields
        ));
      providerDetail.facilityId &&
        providerCardConfig &&
        (updatedDisplayFields = Object.entries(
          providerCardConfig.controls['facility-flyout'].displayFields
        ));
    }

    if (updatedDisplayFields.length > 0) {
      this.displayFieldsData = updatedDisplayFields.map((key) => ({
        label: ProviderDisplayFields[`${camelCase(key[0])}`],
        value: getValue(providerDetail, `${camelCase(key[1])}`)
          ? getValue(providerDetail, `${camelCase(key[1])}`)
          : NoValue,
      }));
    }
  }

  setFieldIfExists(obj: any, field_name: string) {
    if (obj && (typeof obj === 'number' || !isEmpty(obj))) {
      this.payload[field_name] = obj;
    }
  }

  setField(obj: any, field_name: string) {
    this.payload[field_name] = obj;
  }

  setNestedFieldIfExists(obj: any, outter_field: string, inner_field: string) {
    if (obj && !isEmpty(obj)) {
      if (!this.payload[outter_field]) this.payload[outter_field] = {};
      this.payload[outter_field][inner_field] = obj;
    }
  }

  setDiagnosis() {
    const diagnosis: any[] = [];
    let is_primary = false;

    this.diagnosisDetails?.forEach((element: any) => {
      is_primary = element.row.is_primary ? element.row.is_primary : false;
      diagnosis.push({ diagnosis_id: element.row.id, is_primary: is_primary });
      this.mcgAuthData?.diagnosis?.push({
        ...element.row,
        is_primary: is_primary,
      });
    });

    return diagnosis;
  }

  buildPayload() {
    const name = {
      first_name: this.formGroup.value?.submitted_by_name,
      last_name: '',
      middle_name: '',
      prefix: '',
      suffix: '',
      full_name: this.formGroup.value?.submitted_by_name,
    };
    const phone = {
      phone_number: parseInt(this.formGroup.value?.submitted_by_phone, 10),
    };

    const serviceArray: any[] = [];

    this.mcgAuthData = {
      services: [],
      formValue: this.formGroup.value,
      diagnosis: [],
    };
    if (this.cardArray.length > 0) {
      for (let i = 0; i < this.cardArray.length; i++) {
        const groupToLoadValue = this.cardArray[i]?.group_to_load?.value;
        const obj = {
          length_of_stay_admit_date:
            groupToLoadValue?.length_of_stay_admit_date,
          length_of_stay_discharge_date:
            groupToLoadValue?.length_of_stay_discharge_date,
          length_of_stay_notes:
            this.cardArray[0]?.group_to_load.value?.length_of_stay_notes,
          length_of_stay_requested_days:
            this.cardArray[0]?.group_to_load.value
              ?.length_of_stay_requested_days,
          servicing_provider_id: groupToLoadValue?.servicing_provider?.id,
          servicing_facility_location_id:
            groupToLoadValue?.servicing_facility_id?.id,
          unit_type: groupToLoadValue?.unit_type,
          quantity: groupToLoadValue?.requested_units,
          procedure_id: groupToLoadValue?.procedure_id?.id,
          is_primary: groupToLoadValue?.is_primary
            ? groupToLoadValue.is_primary
            : false,
          cost: groupToLoadValue?.cost,
          frequency: groupToLoadValue?.frequency,
          effective_date: groupToLoadValue?.effective_date,
          termination_date: groupToLoadValue?.termination_date,
          status: '',
          status_reason: '',
          notes: groupToLoadValue?.notes,
          servicing_provider_network_status:
            groupToLoadValue?.servicing_provider?.member_network
              ?.network_status,
          servicing_facility_network_status:
            groupToLoadValue?.servicing_facility_id?.member_network
              ?.network_status,
        };

        this.mcgAuthData.services.push({
          ...groupToLoadValue,
          id: this.cardArray[i]?.card_to_load?.id,
        });
        serviceArray.push(obj);
      }
    }

    this.setFieldIfExists('', 'id');
    this.setFieldIfExists('', 'number');
    this.setFieldIfExists('', 'secondary_number');
    this.setFieldIfExists(this.id, 'member_id');
    this.setFieldIfExists(this.formGroup.value?.authorization_type, 'type');

    if (this.config?.allowLocationAsRequestingProvider) {
      if (
        this.formGroup.value.requesting_provider?.providerType ===
        ProviderType.provider
      ) {
        this.setFieldIfExists(
          this.formGroup.value?.requesting_provider.id,
          'requesting_provider_id'
        );
        this.setFieldIfExists(
          this.formGroup.value?.requesting_provider?.memberNetwork
            ?.networkStatus,
          'requesting_provider_network_status'
        );
      } else if (
        this.formGroup.value.requesting_provider?.providerType ===
        ProviderType.siteLocation
      ) {
        this.setFieldIfExists(
          this.formGroup.value?.requesting_provider.id,
          'requesting_facility_location_id'
        );
        this.setFieldIfExists(
          this.formGroup.value?.requesting_provider.name,
          'requesting_facility_location_name'
        );
        this.setFieldIfExists(
          this.formGroup.value?.requesting_provider?.memberNetwork
            ?.networkStatus,
          'requesting_facility_location_network_status'
        );
      }
    } else {
      this.setFieldIfExists(
        this.formGroup.value?.requesting_provider_id?.id,
        'requesting_provider_id'
      );
      this.setFieldIfExists(
        this.formGroup.value?.requesting_provider_id?.member_network
          ?.network_status,
        'requesting_provider_network_status'
      );
    }

    this.setFieldIfExists(this.setDiagnosis(), 'diagnoses');
    if (this.formGroup.value?.service_category) {
      this.setFieldIfExists(
        parseInt(this.formGroup.value?.service_category?.id, 10),
        'service_category_id'
      );
    }
    this.setFieldIfExists(
      parseInt(this.formGroup.value?.service_type_id?.id, 10),
      'service_type_id'
    );
    this.setFieldIfExists(
      this.formGroup.value?.level_of_care?.id,
      'level_of_care_id'
    );
    this.setFieldIfExists(
      this.formGroup.value?.place_of_service?.id,
      'place_of_service_id'
    );
    this.setFieldIfExists(this.formGroup.value?.priority, 'priority');
    this.setField(this.getDocumentIds(), uploadDocument.documentIds);
    this.setFieldIfExists(this.formGroup.value?.comment, 'notes');
    this.setFieldIfExists(name, 'submitted_by_name');
    this.setFieldIfExists('', 'submitted_by_email');
    this.setFieldIfExists(phone, 'submitted_by_phone');
    this.setFieldIfExists(
      this.formGroup.value?.submitted_by_fax,
      'submitted_by_email'
    );
    this.setField(serviceArray, 'services');
    this.setFieldIfExists('', 'status');
    this.setFieldIfExists('', 'status_reason');
    this.setFieldIfExists('', 'decision_date');
  }

  submit(): void {
    if (!this.episodeId) {
      this.buildPayload();
    }
    const payload = {
      authorization_request: this.payload,
      request_type: 'submit',
      mcg_episode_id: this.episodeId,
    };
    const dictionary_attributes = {
      authorization_type: this.formGroup.value?.authorization_type,
      type_of_service: this.formGroup.value?.service_type_id,
      place_of_service: this.formGroup.value?.place_of_service?.id,
      level_of_care: this.formGroup.value?.level_of_care?.id,
      requested_units: this.getCXValue('requested_units'),
      unit_type: this.getCXValue('unit_type'),
      priority: this.getCXValue('priority'),
      diagnosis_code: this.diagnosisDetails?.map(
        (element: any) => element?.row?.code
      ),
      diagnosis_description: this.diagnosisDetails?.map(
        (element: any) => element?.row?.description
      ),
      procedure_code: this.getCXValue('procedure_id', 'code'),
      procedure_description: this.getCXValue('procedure_id', 'description'),
    };

    this.busy.push(
      this.api.post(this.config.endpoints.submitApi, payload).subscribe(
        (resp: any) => {
          this.authorizationDetailsContext = resp;

          if (this.state === 'review') this.state = 'confirm';

          this.analyticsService.sendEvent(
            CX_CALLS.pp_authorization_entry_completed.event_key,
            dictionary_attributes
          );
        },
        (err: any) => {
          this.toggleError();
        }
      )
    );
  }

  getDefaultReviewBox(control: any) {
    if (control.type === 'date') {
      if (
        this.formGroup.get(control.prop) &&
        this.formGroup.get(control.prop).value
      ) {
        const value: any = this.formGroup.get(control.prop).value;
        const dateObj = new Date(`${value}T00:00:00`);

        return new Intl.DateTimeFormat('en-US').format(dateObj);
      } else {
        return this.noSpace;
      }
    }

    return this.formGroup.get(control.prop)
      ? this.formGroup.get(control.prop).value
        ? this.formGroup.get(control.prop).value
        : this.noSpace
      : this.noSpace;
  }

  toggleError() {
    this.error = !this.error;
  }

  onClick(event) {
    this.showAlert = !this.showAlert;
  }

  cancelReview(): void {
    this.showClinicalReviewModal = !this.showClinicalReviewModal;
  }

  continueReview(): void {
    this.showClinicalReviewModal = true;
  }

  skipReview(): void {
    this.showClinicalReviewModal = false;
    this.submit();
  }

  loadIframe(): void {
    this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.config.clinicalReview?.mcgIframeUrl
    );
    this.showClinicalReviewModal = false;
    this.getHashMessage();
  }

  getHashMessage(): void {
    const payload = this.getSignatureAPIParams();

    this.api
      .post(this.config.clinicalReview.vendorSignatureAPIEndPoint, payload)
      .subscribe(
        (response: any) => {
          if (response.hashed_msg) {
            this.showIframe = true;
            this.mcgFormInputs.push({
              input: 'messageHash',
              value: response.hashed_msg,
            });
            this.cdr.detectChanges();

            if (this.mcgAuthRequestForm) {
              this.mcgAuthRequestForm.nativeElement.submit();
              this.showIframeLoader = true;
            }
          }
        },
        (error: any) => {
          this.showIframeLoader = false;
          this.toggleError();
        }
      );
  }

  getSignatureAPIParams(): any {
    this.buildPayload();
    const mcgAuthParameters = _.pickBy(
      this.mcgService.getMCGAuthData(
        this.mcgAuthData,
        this.context,
        this.config.clinicalReview
      )
    );

    Object.keys(mcgAuthParameters).forEach((key: string) => {
      this.mcgFormInputs.push({ input: key, value: mcgAuthParameters[key] });
    });

    return mcgAuthParameters;
  }

  iframeLoaded(): void {
    this.showIframeLoader = false;

    if (this.frame?.nativeElement?.contentWindow?.location?.href) {
      const childframeUrl =
        this.frame.nativeElement.contentWindow.location?.href;
      const urlParams = new URLSearchParams(childframeUrl.split('?')[1]);

      this.episodeId = urlParams?.get('id');
      const childIframe = window.parent.document.getElementById('mcgIframe');

      childIframe?.parentNode.removeChild(childIframe);
      this.showIframe = false;
      this.submit();
    }
  }

  formatDate(date: string): string {
    return this.formattingService.restructureValueBasedOnFormat(date, {
      format: 'DATE',
    });
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.genericServices.showFooter$.next(true);
    });
    this.documentUploadService.uploadedFiles = [];
    this.documentUploadService.uploadAttachmentSubscription?.unsubscribe();
  }

  getStatusBannerConfig(): MessageBannerConfig {
    const messageBannerConfig = this.config.clinicalReview?.messageBanner;

    return messageBannerConfig &&
      messageBannerConfig[this.authorizationDetailsContext?.status]
      ? messageBannerConfig[this.authorizationDetailsContext.status]
      : {};
  }

  areProvidersOutOfNetwork(): boolean {
    const outOfNetworkProvider = ProvidersKeys.find((provider: string) => {
      if (provider === OutOfNetworkProps[toCamel(provider)]) {
        return this.cardArray.some(
          (cardItem: any) =>
            cardItem?.group_to_load?.value[provider]?.member_network
              ?.is_in_network === false
        );
      } else {
        return (
          this.formGroup.value[provider]?.member_network?.is_in_network ===
          false
        );
      }
    });

    return !!outOfNetworkProvider;
  }

  isProviderAddressAvailable(key: string, controlKey: string): boolean {
    const selectedProvider = this.formGroup.get(
      this.config?.cards[key]?.controls[controlKey]?.prop
    ).value['provider_locations'];

    return !!(
      selectedProvider.length &&
      selectedProvider[0] &&
      selectedProvider[0]['address']
    );
  }

  fileUploaded(fileList: File[]): void {
    const documentType = this.formGroup?.get(
      uploadDocument.documentType
    )?.value;
    const uploadDocumentConfig =
      this.config?.cards.supporting_documentation_card?.controls?.upload_doc;
    const documentUploadUrl =
      uploadDocumentConfig?.documentUploadUrl ||
      uploadDocumentConfig?.documentCreate;

    if (documentUploadUrl) {
      this.documentUploadService.uploadFile(
        fileList,
        documentUploadUrl,
        documentType?.value
      );
    }
  }

  removeFile(fileId: number): void {
    this.documentUploadService.removeFile(fileId);
    this.documentUploadService.uploadCompleted$.next(false);
  }

  public setFilesFromAPI(filesList: File[]): void {
    this.documentContext = new UntypedFormArray(
      filesList.map((file: File) => new UntypedFormControl(file))
    );
  }

  public getDocumentIds(): number[] {
    return (
      this.documentUploadService.uploadedFiles.map(
        (uploadDocument) => uploadDocument.id
      ) || []
    );
  }

  public getDefaultUploadDocumentConfig(
    uploadDocumentConfig: UploadDocumentConfig
  ): UploadDocumentConfig {
    return getDefaultFileUploaderConfig(uploadDocumentConfig);
  }
}
