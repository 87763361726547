<card class="cardholder card item skinny-badge">
  <div title class="card-header card-edit">
    <div class="left">
      {{ config.header }}
    </div>
    <div
      *ngIf="state == 'review'"
      class="right"
      (click)="scrollTo(config.id, index)"
    >
      <i class="icon icon--medium">edit</i>
    </div>
    <ng-content select=".right"></ng-content>
  </div>
  <div class="left" *ngIf="config?.subheader" subheader>
    {{ config?.subheader }}
  </div>
  <div body>
    <div class="title-border"></div>
    <div *ngFor="let controlKey of _.keys(config['controls'])">
      <div [ngSwitch]="config['controls'][controlKey].type">
        <div *ngIf="state === 'edit'">
          <div *ngSwitchCase="'flyout'">
            <flyout-dropdown
              [group]="group"
              [config]="config['controls'][controlKey]"
              [outOfNetwork]="outOfNetwork"
              [authorizationDetailsContext]="authorizationDetailsContext"
            ></flyout-dropdown>
          </div>
          <div *ngSwitchCase="'date'">
            <form-control
              [group]="group"
              [config]="config['controls'][controlKey]"
              [control]="group.get(config['controls'][controlKey].prop)"
            >
            </form-control>
          </div>
          <div *ngSwitchCase="'dropdown'">
            <form-control
              [group]="group"
              [config]="config['controls'][controlKey]"
              [control]="group.get(config['controls'][controlKey].prop)"
            >
            </form-control>
          </div>
          <div *ngSwitchCase="'textarea'">
            <form-control
              [group]="group"
              [config]="config['controls'][controlKey]"
              [control]="group.get(config['controls'][controlKey].prop)"
            >
            </form-control>
          </div>
          <div *ngSwitchCase="'switch'">
            <form-control
              [group]="group"
              [config]="config['controls'][controlKey]"
              [control]="group.get(config['controls'][controlKey].prop)"
              (click)="switchTriggered.emit(index)"
            >
            </form-control>
          </div>
          <!-- there is something wrong with ngSwitchDefault  -->
          <div *ngSwitchDefault>
            <form-control
              [group]="group"
              [config]="config['controls'][controlKey]"
              [control]="group.get(config['controls'][controlKey].prop)"
            >
            </form-control>
          </div>
          <ng-content select="[btn]"></ng-content>
        </div>
        <div class="review-box" *ngIf="state !== 'edit'">
          <p class="t-data t-bold">
            {{ config['controls'][controlKey]?.label }}
          </p>
          <div [ngSwitch]="config['controls'][controlKey].type">
            <div *ngSwitchCase="'dropdown'">
              {{
                getDropdownDisplayValue(
                  config['controls'][controlKey].prop,
                  group
                )
              }}
            </div>
            <div *ngSwitchCase="'flyout'" class="root">
              <div
                class="left-flyout"
                *ngIf="config['controls'][controlKey].prop === 'procedure_id'"
              >
                <label class="form-control__label">
                  <p
                    class="t-data t-bold"
                    [innerHTML]="
                      group.get(config['controls'][controlKey].prop)?.value
                        ?.code || noSpace
                    "
                  ></p>
                </label>
              </div>

              <div
                class="left-flyout"
                *ngIf="
                  config['controls'][controlKey].prop === 'servicing_provider'
                "
              >
                <label
                  *ngIf="
                    config['controls'][controlKey]?.displayFields?.name &&
                    group.get(config['controls'][controlKey].prop)?.value
                  "
                  class="form-control__label"
                >
                  <p class="t-data t-bold">
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'name'
                      ]['full_name'] || noSpace
                    }}
                  </p>
                </label>
                <p
                  *ngIf="showOutOfNetworkStatusPill(controlKey)"
                  class="OutOfNetwork__StatusPill Pending"
                >
                  {{ outOfNetwork?.outOfNetworkLabel }}
                </p>
              </div>
              <div
                class="left-flyout"
                *ngIf="
                  config['controls'][controlKey].prop ===
                  'servicing_facility_id'
                "
              >
                <label
                  *ngIf="
                    config['controls'][controlKey]?.displayFields?.name &&
                    group.get(config['controls'][controlKey].prop)?.value
                  "
                  class="form-control__label"
                >
                  <p class="t-data t-bold">
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'name'
                      ] || noSpace
                    }}
                  </p>
                </label>
                <p
                  *ngIf="showOutOfNetworkStatusPill(controlKey)"
                  class="OutOfNetwork__StatusPill Pending"
                >
                  {{ outOfNetwork?.outOfNetworkLabel }}
                </p>
              </div>
              <!-- load this from config -->
              <div
                class="right-flyout"
                *ngIf="config['controls'][controlKey].prop === 'procedure_id'"
              >
                <label class="form-control__label">
                  <p>
                    {{
                      group.get(config['controls'][controlKey].prop)?.value
                        ?.description
                    }}
                  </p>
                </label>
              </div>
              <div
                class="right-flyout"
                *ngIf="
                  config['controls'][controlKey].prop === 'servicing_provider'
                "
              >
                <label
                  *ngIf="config['controls'][controlKey]?.displayFields?.address"
                  class="form-control__label"
                >
                  <p class="spacing t-data t-bold">Address:</p>
                  <p
                    *ngIf="
                      !(group.get(config['controls'][controlKey].prop)?.value)[
                        'provider_locations'
                      ]?.length
                    "
                  >
                    {{ noSpace }}
                  </p>
                  <div
                    class="address"
                    *ngIf="
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'provider_locations'
                      ]?.length
                    "
                  >
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'provider_locations'
                      ][0]['address']['street_name_1']
                    }}<br />
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'provider_locations'
                      ][0]['address']['street_name_2']
                    }}<br />
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'provider_locations'
                      ][0]['address']['city_name']
                    }},
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'provider_locations'
                      ][0]['address']['state']
                    }}
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'provider_locations'
                      ][0]['address']['zip_code']
                    }}
                  </div>
                </label>
                <label
                  *ngIf="config['controls'][controlKey].displayFields.group_npi"
                  class="form-control__label"
                >
                  <p class="spacing t-data t-bold">Group NPI:</p>
                  <p>
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'npi'
                      ] || noSpace
                    }}
                  </p>
                </label>
                <label
                  *ngIf="
                    config['controls'][controlKey]?.displayFields.id_number
                  "
                  class="form-control__label"
                >
                  <p class="spacing t-data t-bold">ID Number:</p>
                  <p>
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'number'
                      ] || noSpace
                    }}
                  </p>
                </label>
                <label
                  *ngIf="
                    config['controls'][controlKey]?.displayFields.specialty
                  "
                  class="form-control__label"
                >
                  <p class="spacing t-data t-bold">Primary Specialty:</p>
                  <p
                    *ngIf="
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'primary_specialty'
                      ]
                    "
                  >
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'primary_specialty'
                      ]
                    }}
                  </p>
                  <p
                    *ngIf="
                      !(group.get(config['controls'][controlKey].prop)?.value)[
                        'primary_specialty'
                      ]
                    "
                  >
                    {{ noSpace }}
                  </p>
                </label>
              </div>
              <div
                class="right-flyout"
                *ngIf="
                  config['controls'][controlKey].prop ===
                  'servicing_facility_id'
                "
              >
                <label
                  *ngIf="config['controls'][controlKey]?.displayFields?.address"
                  class="form-control__label"
                >
                  <p class="spacing t-data t-bold">Address:</p>
                  <p
                    *ngIf="
                      !(group.get(config['controls'][controlKey].prop)?.value)[
                        'address'
                      ]
                    "
                  >
                    {{ noSpace }}
                  </p>
                  <div
                    class="address"
                    *ngIf="
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'address'
                      ]
                    "
                  >
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'address'
                      ]['street_name_1']
                    }}<br />
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'address'
                      ]['street_name_2']
                    }}<br />
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'address'
                      ]['city_name']
                    }},
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'address'
                      ]['state']
                    }}
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'address'
                      ]['zip_code']
                    }}
                  </div>
                </label>
                <label
                  *ngIf="
                    config['controls'][controlKey]?.displayFields?.group_npi
                  "
                  class="form-control__label"
                >
                  <p class="spacing t-data t-bold">Group NPI:</p>
                  <p>
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'group_npi'
                      ] || noSpace
                    }}
                  </p>
                </label>
                <label
                  *ngIf="
                    config['controls'][controlKey]?.displayFields?.id_number
                  "
                  class="form-control__label"
                >
                  <p class="spacing t-data t-bold">ID Number:</p>
                  <p>
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'number'
                      ] || noSpace
                    }}
                  </p>
                </label>
                <label
                  *ngIf="
                    config['controls'][controlKey]?.displayFields?.specialty
                  "
                  class="form-control__label"
                >
                  <p class="spacing t-data t-bold">Primary Specialty:</p>
                  <p
                    *ngIf="
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'specialties'
                      ]?.length
                    "
                  >
                    {{
                      (group.get(config['controls'][controlKey].prop)?.value)[
                        'specialties'
                      ][0]['name']
                    }}
                  </p>
                  <p
                    *ngIf="
                      !(group.get(config['controls'][controlKey].prop)?.value)[
                        'specialties'
                      ]?.length
                    "
                  >
                    {{ noSpace }}
                  </p>
                </label>
              </div>

              <!-- load this from config -->
              <div class="title-border"></div>
            </div>
            <div *ngSwitchCase="'toggle'">
              <p class="t-data t-bold">
                {{ config['controls'][controlKey].get(control.prop)?.label }}
              </p>
              {{
                getDropdownDisplayValue(
                  config['controls'][controlKey].prop,
                  group
                )
              }}
            </div>
            <div *ngSwitchDefault>
              {{
                getDefaultReviewBox(config['controls'][controlKey], group) ||
                  noSpace
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</card>
