import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[isEllipsisActive]',
})
export class IsEllipsisActiveDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  // When Ellipsis is active show content as tooltip
  ngAfterViewInit(): void {
    const element = this.elementRef.nativeElement;
    if (element.offsetWidth < element.scrollWidth) {
      element.title = element.innerHTML;
    }
  }
}
