<ng-container *ngIf="config">
  <section>
    <info-list
      *ngIf="
        config?.serviceInformationTab.servicingProvider;
        let servicingProvider
      "
      [header]="servicingProvider.label"
      [attributeList]="servicingProvider.attributes"
      [context$]="servicingProvider$"
    ></info-list>
  </section>

  <section>
    <info-list
      *ngIf="
        config?.serviceInformationTab.serviceInformation;
        let serviceInformation
      "
      [header]="serviceInformation.label"
      [attributeList]="serviceInformation.attributes"
      [context$]="serviceInformation$"
    ></info-list>
  </section>
  <section *ngIf="serviceInfo$ | async as serviceInfo">
    <ng-container
      *ngTemplateOutlet="
        infoTemplate;
        context: {
          info: serviceInfo.procedures,
          options: config?.serviceInformationTab.procedures.table,
          header: config?.serviceInformationTab.procedures.title
        }
      "
    ></ng-container>
  </section>
  <section *ngIf="serviceInfo$ | async as serviceInfo">
    <ng-container
      *ngTemplateOutlet="
        infoTemplate;
        context: {
          info: serviceInfo.diagnosesInformation,
          options: config?.serviceInformationTab.diagnosis.table,
          header: config?.serviceInformationTab.diagnosis.title
        }
      "
    ></ng-container>
  </section>
  <ng-template
    #infoTemplate
    let-info="info"
    let-options="options"
    let-header="header"
  >
    <header>
      <h5 role="heading" aria-level="1" [innerHTML]="header"></h5>
    </header>
    <hr class="ServiceInformation__Separator" />
    <zip-table [options]="options" [data]="info"></zip-table>
  </ng-template>
</ng-container>
