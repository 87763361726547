import { createAction, props } from '@ngrx/store';

export const getSidePanelConfig = createAction(
  '[Side Panel | Init] Get Side Panel Configuration',
  props<{ config: any }>()
);

export const downloadEOBInit = createAction(
  '[Claim Detail | Resource List] Download EOB - Initialized',
  props<{ payload: { eobDocumentId: string } }>()
);

export const downloadClaimDetailsInit = createAction(
  '[Claim Detail | Resource List] Download Claim Details - Initialized',
  props<{ payload: { claimDetailsDocumentId: string } }>()
);
