import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentEvent } from '../util/document-center.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentCenterEventService {
  private _eventType: BehaviorSubject<DocumentEvent> = new BehaviorSubject(
    null as unknown as DocumentEvent
  );
  private _eventType$ = this._eventType.asObservable();

  getEventType(): Observable<DocumentEvent> {
    return this._eventType$;
  }

  captureEvent(eventType: DocumentEvent): void {
    this._eventType.next(eventType);
  }
}
