import { Directive, Output, EventEmitter, OnDestroy } from '@angular/core';

@Directive({
  selector: '[exited]',
})
export class NgSwitchOnExitDirective implements OnDestroy {
  @Output() exited: EventEmitter<null> = new EventEmitter();

  ngOnDestroy() {
    this.exited.emit();
  }
}
