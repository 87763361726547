<div class="ReviewSuspendUser" *ngIf="config">
  <div class="ReviewSuspendUser__Header">
    <h4 class="t-bold" [innerHTML]="config.header"></h4>
  </div>
  <div class="ReviewSuspendUser__SubHeader">
    <p class="t-data" [innerHTML]="config.subheader"></p>
  </div>
  <ng-container *ngFor="let card of config.cards | keyvalue">
    <card class="ReviewSuspendUser__Card" [config]="{ hideSubHeader: true }">
      <div title>
        <h4 [innerHTML]="card.value.title"></h4>
      </div>
      <div header-action>
        <icon
          *ngIf="card.value.showEditIcon"
          (click)="editDetail.emit(card.value.stepKey)"
          [name]="'edit'"
        ></icon>
      </div>
      <div body class="ReviewSuspendUser__CardBody">
        <p
          class="t-data t-bold"
          *ngFor="let group of getDisplayItems(card.value.stepKey)"
          [innerHtml]="'- ' + group.label"
        ></p>
      </div>
    </card>
  </ng-container>
</div>
