import {
  animate,
  animation,
  AnimationTriggerMetadata,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';

const popOut = animation([
  style({ transform: 'scale(.3)', transformOrigin: '{{transformOrigin}}' }),

  animate('150ms ease', style({ transform: 'scale(1.1)' })),
  animate('150ms ease', style({ transform: 'scale(1)' })),
]);

const popIn = animation([
  style({ transform: 'scale(1)', transformOrigin: '{{transformOrigin}}' }),

  animate('150ms ease-in', style({ transform: 'scale(0)' })),
]);

export const popup: AnimationTriggerMetadata = trigger('popup', [
  transition(':enter', [useAnimation(popOut)]),
  transition(':leave', [useAnimation(popIn)]),
]);
