import { Component, Input } from '@angular/core';
import { FormGroupArrayConfig } from '../../form-group-array.config';

@Component({
  selector: 'form-group-array-title',
  templateUrl: './form-group-array-title.component.html',
  styleUrls: ['./form-group-array-title.component.scss'],
})
export class FormGroupArrayTitleComponent {
  @Input() config: FormGroupArrayConfig;
  @Input() index: number;
  @Input() formArray: any;
}
