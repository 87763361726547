<div *ngIf="!error" class="col-container">
  <div
    class="col-header"
    [ngStyle]="{ width: 'calc(100%/' + config.numberOfColumns + ')' }"
    *ngFor="let col of config?.columns"
  >
    {{ col }}
  </div>
</div>

<div [zipBusy]="api_call" *ngIf="!error">
  <div
    #container
    class="card-container"
    *ngFor="let child of children; let i = index"
  >
    <div
      [ngStyle]="{
        'min-width': 'calc(100%/' + config.numberOfColumns + ')',
        'background-color': setBackgroundColor(i)
      }"
      class="leftCell"
    >
      <div *ngIf="child?.selectionEvent">
        <div
          class="prop-list"
          *ngFor="let prop of config.display_field_prop_list"
        >
          {{ (child?.selectionEvent?.data)[prop] }}
        </div>
        <div
          class="subheader"
          *ngFor="let field of child?.selectionEvent?.formData | keyvalue"
        >
          {{ config.form_fields_label_map[field.value] }}
        </div>
      </div>
      <div *ngIf="!child?.selectionEvent">
        <div
          class="prop-list"
          *ngFor="let prop of config.display_field_prop_list"
        >
          {{ child[prop] }}
        </div>
        <div class="subheader" *ngIf="child.full_access">
          {{ config.form_fields_label_map['full_access'] }}
        </div>
        <div class="subheader" *ngIf="!child.full_access">
          {{ config.form_fields_label_map['managed'] }}
        </div>
      </div>
    </div>
    <row-item
      class="rightCell"
      [mode]="config?.apiEndpoint && !viewMode ? true : false"
      [numberOfColumns]="config.numberOfColumns"
      [form_fields_label_map]="config.form_fields_label_map"
      [display_field_prop_list]="config.display_field_prop_list"
      [children]="child.children"
      [key]="'children'"
    ></row-item>
  </div>
</div>

<div *ngIf="!error && config?.apiEndpoint && !ignoreAPI">
  <paginator
    [config]="paginatorConfig"
    [data]="children"
    (pageSelected)="goToPage($event)"
  >
  </paginator>
</div>

<div #container *ngIf="error">
  <error-empty [config]="config.error"></error-empty>
</div>

<zip-button
  *ngIf="
    config?.apiEndpoint &&
    !viewMode &&
    !config.disableEdit &&
    !(!totalResultsCount || error)
  "
  class="edit-button"
  [config]="{
    content: 'Edit User Provisions',
    level: 'high'
  }"
  (zipButtonClicked)="provision()"
>
</zip-button>
<zip-button
  *ngIf="
    config?.apiEndpoint &&
    !viewMode &&
    !config.disableEdit &&
    (!totalResultsCount || error)
  "
  class="edit-button"
  [config]="{
    content: 'Add Provisions',
    level: 'high'
  }"
  (zipButtonClicked)="provision()"
>
</zip-button>
