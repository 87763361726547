<footer class="Footer" data-test-id="portalFooter">
  <div class="container">
    <section
      class="Footer__Row"
      data-test-id="portalFooterRow"
      [ngStyle]="{ 'margin-bottom': row.marginBottom }"
      *ngFor="let row of rows"
    >
      <div
        class="Footer__Column"
        data-test-id="portalFooterColumn"
        *ngFor="let column of row.columns"
      >
        <ng-container *ngFor="let line of column">
          <ng-container [ngSwitch]="line?.type">
            <ng-container *ngSwitchCase="types.address">
              <div
                class="Footer__Line--Address"
                data-test-id="portalFooterAddress"
              >
                <p tabindex="0" class="Footer__Link">
                  {{ line.line1 }}
                </p>
                <p tabindex="0" class="Footer__Link">
                  {{ line.line2 }}
                </p>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="types.title">
              <div class="Footer__Line" data-test-id="portalFooterText">
                <p
                  tabindex="0"
                  class="Footer__Title"
                  [innerHTML]="line.label"
                ></p>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="types.header">
              <div
                class="Footer__Line--Header"
                data-test-id="portalFooterHeader"
              >
                <h5
                  tabindex="0"
                  class="Footer__Header"
                  [ngClass]="{ 'Footer__Header--Center': line.textAlignCenter }"
                >
                  {{ line.label }}
                </h5>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="types.icon">
              <icon
                data-test-id="portalFooterIcon"
                [name]="line.icon"
                [config]="{
                  size: 'large',
                  type: 'link',
                  fill: 'pf-icon'
                }"
              >
              </icon>
            </ng-container>

            <ng-container *ngSwitchCase="types.image">
              <a
                tabindex="0"
                class="Footer__Line--Image"
                *ngIf="line.route; else noRoute"
                [attr.href]="line.route"
                [target]="line.target ? line.target : '_blank'"
                data-test-id="portalFooterImage"
              >
                <img
                  [attr.title]="line.title"
                  [alt]="line.title"
                  [src]="line.source"
                  [ngStyle]="{
                    width: line.width,
                    height: line.height
                  }"
                />
              </a>

              <ng-template #noRoute>
                <img
                  class="Footer__Line--Image"
                  [attr.title]="line.title"
                  [alt]="line.title"
                  [src]="line.source"
                  [ngStyle]="{
                    width: line.width,
                    height: line.height
                  }"
                />
              </ng-template>
            </ng-container>

            <ng-container *ngSwitchCase="types.link">
              <div class="Footer__Line" data-test-id="portalFooterLink">
                <ds-link
                  [linkConfig]="getLinkConfig(line)"
                  [externalSiteModalConfig]="externalSiteModalConfig"
                  [dataNavEvent]="line.route | getEventRoute"
                >
                  <div class="Footer__Link">
                    <p class="Footer__Link--Label">{{ line.label }}</p>
                    <icon
                      *ngIf="line.isExternalRoute && line.showIcon"
                      [name]="line.icon || 'open_in_new'"
                    ></icon>
                  </div>
                </ds-link>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="types.longText">
              <div
                class="Footer__Line--Paragraphs"
                data-test-id="portalFooterLongText"
              >
                <p
                  tabindex="0"
                  class="Footer__Text"
                  [ngClass]="{ 'Footer__Header--Center': line.textAlignCenter }"
                  *ngFor="let paragraph of line.paragraphs"
                >
                  {{ paragraph }}
                </p>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="types.modalLink">
              <ng-container *ngIf="line.modal">
                <div class="Footer__Line" data-test-id="portalFooterModalLink">
                  <a
                    [dataNavEvent]="line.route | getEventRoute"
                    tabindex="0"
                    class="Footer__Link"
                    (click)="line.modal.isOpen = true"
                    (keydown.enter)="line.modal.isOpen = true"
                  >
                    {{ line.label }}
                  </a>
                </div>

                <modal
                  [condition]="line.modal.isOpen"
                  [config]="line.modal"
                  (cancel)="line.modal.isOpen = false"
                  (keydown.enter)="line.modal.isOpen = false"
                >
                  <section body class="Footer__Modal--Body">
                    <div tabindex="0" [innerHtml]="line.modal.body"></div>
                  </section>
                  <ng-container footer *ngIf="line.modal?.footer?.link">
                    <div class="u-flex u-justifyBetween u-maxX">
                      <zip-button
                        [config]="{
                          level: 'low',
                          content: line.modal.footer.cancel || 'Cancel'
                        }"
                        (zipButtonClicked)="line.modal.isOpen = false"
                      >
                      </zip-button>

                      <zip-button
                        [config]="{
                          level: 'high',
                          content: line.modal.footer.proceed || 'Proceed'
                        }"
                        (zipButtonClicked)="goTo(line.modal.footer?.link)"
                      >
                      </zip-button>
                    </div>
                  </ng-container>
                </modal>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="types.phone">
              <div class="Footer__Line" data-test-id="portalFooterPhone">
                <p
                  tabindex="0"
                  class="Footer__Text"
                  [ngClass]="{ 'Footer__Header--Center': line.textAlignCenter }"
                >
                  {{ line.label }}
                  <a
                    [dataNavEvent]="line.phone | getEventRoute"
                    class="Footer__Link"
                    [attr.href]="'tel:' + line.phone"
                    >{{ line.phone }}</a
                  >
                </p>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="types.text">
              <div class="Footer__Line" data-test-id="portalFooterText">
                <p
                  tabindex="0"
                  class="Footer__Text"
                  [ngClass]="{ 'Footer__Header--Center': line.textAlignCenter }"
                  [innerHTML]="line.label"
                ></p>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </section>
  </div>
</footer>
