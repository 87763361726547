<section
  class="DocumentCenter__Filters"
  *ngIf="!!tab?.filters"
  aria-label="filters"
>
  <form-group
    [configs]="tab?.filters?.controls"
    [form]="filtersForm"
    class="DocumentCenter__FiltersForm"
    direction="filter"
    (formCreated)="filterFormCreated()"
  >
  </form-group>

  <div
    class="DocumentCenter__FiltersActions"
    *ngIf="
      documentCenterConfig?.page?.buttonClearFilters ||
      documentCenterConfig?.page?.buttonApplyFilters
    "
  >
    <zip-button
      *ngIf="documentCenterConfig?.page?.buttonClearFilters"
      data-testId="DocumentCenterClearFilters"
      (zipButtonClicked)="clearFilters()"
      [config]="documentCenterConfig.page.buttonClearFilters"
    ></zip-button>

    <zip-button
      *ngIf="documentCenterConfig?.page?.buttonApplyFilters"
      data-testId="DocumentCenterApplyFilters"
      (zipButtonClicked)="applyFilters()"
      class="DocumentCenter__ApplyFilterButton"
      [config]="documentCenterConfig.page.buttonApplyFilters"
    >
    </zip-button>
  </div>
</section>
<section class="DocumentCenter__Filters--Mobile">
  <zip-button
    data-testId="DocumentCenterShowMobileFilterButton"
    (zipButtonClicked)="isMobileFiltersOpen = true"
    [config]="{
      level: 'text',
      icon: 'tune',
      content: getMobileFilterLabel(tab?.label)
    }"
  >
  </zip-button>
</section>

<zipari-mobile-filters
  [documentCenterConfig]="documentCenterConfig"
  [isMobileFiltersOpen]="isMobileFiltersOpen"
  [filtersForm]="filtersForm"
  [tab]="tab"
  (applyFilters)="applyFilters()"
  (clearFilters)="clearFilters()"
></zipari-mobile-filters>
