import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ClaimAppealConfig,
  ClaimAppealStepsConfig,
  ClaimAppealSubmissionAPIResponse,
} from '../../claim-redetermination/claim-redetermination.model';
import {
  AppealConstant,
  AppealFormCurrentStep,
  ClaimAppealProps,
  claimListApiEndpoint,
} from '../../claim-appeal.constants';
import {
  daysLessThanMinDays,
  updateClaimAppealResponse,
} from '../../claim-appeal.helper';
import { SubmitClaimAppealService } from '../../services/submit-claim-appeal.service';
import { APIService } from '../../../../../shared/services';
import { CX_CALLS } from '../../../../../shared/constants/cx-calls.constant';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';
import ClaimLine from '../../../../../shared/models/shared/ClaimLine.model';
import { DocumentUploadService } from '../../services/document-upload.service';
import { GenericServices } from '../../../../shared/services/generic.service';
import { ClaimDetailService } from '../../../claim-detail/claim-detail.service';
import ClaimAppealDataService from '../../services/claim-appeal-data.service';
import ClaimAppealEventService from '../../services/claim-appeal-event.service';

@Component({
  selector: 'claim-appeal-wrapper',
  templateUrl: './claim-appeal-wrapper.component.html',
  styleUrls: ['./claim-appeal-wrapper.component.scss'],
})
export class ClaimAppealWrapperComponent implements OnInit, OnDestroy {
  @Input() config: ClaimAppealConfig;
  @Input() claimNumber: string;
  @Input() appealType: string;

  public appealForm: UntypedFormGroup = new UntypedFormGroup({});
  public currentStep: string = AppealFormCurrentStep.ENTRY;
  public currentStepConfig: ClaimAppealStepsConfig;
  public hasReturnedFromReviewPage = false;
  public claimAppealResponse: ClaimAppealSubmissionAPIResponse;
  public showErrorModal: boolean;
  public errorMessage: string;
  public shouldShowReviewPage: boolean = false;
  public context: object;

  private createAppealSubscription!: Subscription;

  constructor(
    private location: Location,
    private router: Router,
    public submitClaimAppealService: SubmitClaimAppealService,
    private apiService: APIService,
    private analyticsService: AnalyticsService,
    public claimAppealDataService: ClaimAppealDataService,
    public documentUploadService: DocumentUploadService,
    public genericServices: GenericServices,
    public claimDetailService: ClaimDetailService,
    public claimAppealEventService: ClaimAppealEventService
  ) {}

  ngOnInit(): void {
    if (!this.claimDetailService.claimId) {
      this.claimDetailService.getClaim(this.claimNumber).subscribe();
    }

    this.genericServices.showFooter$.next(false);
  }

  showReviewPage(): void {
    this.shouldShowReviewPage = [
      AppealFormCurrentStep.REVIEW,
      AppealFormCurrentStep.CONFIRMATION,
    ].includes(this.currentStep as AppealFormCurrentStep);
  }

  getContext(): void {
    this.context =
      this.currentStep !== AppealFormCurrentStep.CONFIRMATION
        ? this.appealForm.getRawValue()
        : this.claimAppealResponse;
  }

  navigateToPreviousPage(): void {
    this.location.back();
  }

  handleNextButtonClick(): void {
    if (this.currentStep === AppealFormCurrentStep.ENTRY) {
      this.currentStep = AppealFormCurrentStep.REVIEW;
      this.currentStepConfig = this.config?.review;
      this.showReviewPage();
      this.getContext();
      window.scrollTo(0, 0);
    } else if (this.currentStep === AppealFormCurrentStep.REVIEW) {
      this.showReviewPage();
      this.submitClaimAppeal();
    } else if (this.currentStep === AppealFormCurrentStep.CONFIRMATION) {
      this.router.navigate([`${claimListApiEndpoint}${this.claimNumber}`]);
    }
  }

  isDisputeTypeAllowed(): boolean {
    const date = this.appealForm.get(ClaimAppealProps.dateProp)?.value;
    const requestInfoConfig = this.config.entry.cards.requestInformation;

    if (requestInfoConfig?.minDaysAllowed && date) {
      return !daysLessThanMinDays(date, requestInfoConfig.minDaysAllowed);
    }

    return false;
  }

  editCard(key: string): void {
    this.currentStep = AppealFormCurrentStep.ENTRY;
    this.hasReturnedFromReviewPage = true;
    this.showReviewPage();
    this.getContext();
    setTimeout(() => {
      const element = document.getElementById(key);

      if (element) element.scrollIntoView({ behavior: 'smooth' });
    });
  }

  submitClaimAppeal(): void {
    const payload = this.submitClaimAppealService.setSubmitAppealPayload(
      this.appealForm,
      this.appealType,
      this.claimDetailService.claimId,
      this.config.entry.cards.requestInformation
    );

    this.createAppealSubscription = this.apiService
      .post(
        this.config.endpoints.submitAppeal.replace(
          '${claim_id}',
          this.claimDetailService.claimId
        ),
        payload
      )
      .subscribe({
        next: this.handleClaimSubmission.bind(this),
        error: this.handleError.bind(this),
      });
  }

  handleClaimSubmission(response: ClaimAppealSubmissionAPIResponse): void {
    this.currentStepConfig = this.config?.confirmation;
    this.claimAppealResponse = {
      ...response,
      appealed_service: this.getSelectedClaimLine(response),
    };
    updateClaimAppealResponse(
      this.claimAppealResponse,
      this.config.entry.cards
    );
    this.currentStep = AppealFormCurrentStep.CONFIRMATION;
    this.getContext();
    window.scrollTo(0, 0);
    this.analyticsService.sendEvent(
      CX_CALLS.pp_claims_appeal_confirmation_viewed.event_key
    );
  }

  handleError(error: HttpErrorResponse): void {
    const userErrorMessage = error.error?.errors[0]?.user_error;
    const errorMessage =
      typeof userErrorMessage === 'string' ? userErrorMessage : '';
    this.errorMessage = errorMessage;
    this.showErrorModal = true;
  }

  getSelectedClaimLine(
    claimAppealResponse: ClaimAppealSubmissionAPIResponse
  ): string | undefined {
    return this.claimAppealDataService.claimLinesList?.find(
      (claimLine: ClaimLine) =>
        claimLine[AppealConstant.lineNumber]?.toString() ==
        claimAppealResponse?.claim_line_number?.toString()
    )?.[AppealConstant.typeOfServiceDescription];
  }

  errorModalClicked(): void {
    this.showErrorModal = false;
  }

  ngOnDestroy(): void {
    this.createAppealSubscription?.unsubscribe();
    this.documentUploadService.fileAttachments = [];
    this.documentUploadService.uploadedFiles = [];
    this.submitClaimAppealService.providerInformation = null;
  }
}
