import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormControlService } from '../../../../notes/form-control/form-control.service';
import {
  cityStateZipConfig,
  streetAddressConfig,
} from '../basic-address/basic-address.constants';
import { MultiAddressConfig } from './multi-address.model';

@Component({
  selector: 'multi-address',
  templateUrl: './multi-address.component.html',
  styleUrls: [
    './multi-address.component.scss',
    '../basic-address/basic-address.component.scss',
  ],
})
export class MultiAddressComponent implements OnInit {
  @Input() config: MultiAddressConfig;
  @Input() context;
  @Input() formGroup;

  constructor(private formControlService: FormControlService) {}
  addressLine1 = {};
  addressLine2 = {};
  addressesFormGroup;
  typeProps: string[];

  ngOnInit() {
    this.addressesFormGroup = new UntypedFormGroup({});
    const addresses = this.context[this.config.prop];
    this.typeProps = this.config.controls.map((config) => config.typeProp);
    const cityProps = this.config.controls.map((config) => config.cityProp);
    const stateProps = this.config.controls.map((config) => config.stateProp);

    this.typeProps.forEach((typeProp, index) => {
      this.addressesFormGroup.addControl(typeProp, new UntypedFormGroup({}));
      streetAddressConfig.forEach((config) => {
        const newConfig = { ...config, id: typeProp + config.prop };
        this.formControlService.addControlToFormGroup(
          this.addressesFormGroup.controls[typeProp],
          newConfig,
          {
            context: addresses[index],
          }
        );
        if (!this.addressLine1[typeProp]) {
          this.addressLine1[typeProp] = {};
        }
        this.addressLine1[typeProp][newConfig.prop] = newConfig;
      });

      cityStateZipConfig.forEach((config) => {
        let newConfig = { ...config, id: typeProp + config.prop };
        if (config.prop === 'city' && typeof cityProps[index] !== 'undefined') {
          newConfig = { ...newConfig, prop: cityProps[index] };
        }
        if (
          config.prop === 'state' &&
          typeof stateProps[index] !== 'undefined'
        ) {
          newConfig = { ...newConfig, prop: stateProps[index] };
        }
        this.formControlService.addControlToFormGroup(
          this.addressesFormGroup.controls[typeProp],
          newConfig,
          {
            context: addresses[index],
          }
        );
        if (!this.addressLine2[typeProp]) {
          this.addressLine2[typeProp] = {};
        }
        this.addressLine2[typeProp][newConfig.prop] = newConfig;
      });
    });
    this.formGroup.addControl(this.config.prop, this.addressesFormGroup);
  }
}
