import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CardModule } from '../../notes/card/card.module';
import { IconModule } from '../../notes/icon/icon.module';
import { LinkModule } from '../link/link.module';
import { ResourceGridComponent } from './resource-grid.component';

@NgModule({
  declarations: [ResourceGridComponent],
  imports: [CommonModule, CardModule, IconModule, LinkModule, RouterModule],
  exports: [ResourceGridComponent],
})
export class ResourceGridModule {}
