<form class="Container" *ngIf="config" [formGroup]="group">
  <div
    *ngIf="
      group?.get(this.config.providerInformation.prop)?.invalid &&
      (group?.get(this.config.providerInformation.prop)?.dirty ||
        group?.get(this.config.providerInformation.prop)?.touched)
    "
  >
    <div
      class="Error"
      *ngIf="group?.get(this.config.providerInformation.prop).errors"
    >
      <icon
        name="warning"
        style="height: 1.125rem; padding-left: 1rem; padding-right: 0.5rem"
        ><i
          class="icon icon--font ng-star-inserted icon--small"
          style="color: red"
        >
          warning
        </i>
      </icon>
      <p class="t-caption t-error">
        {{
          config.providerInformation.errorMessage ||
            'Please enter a valid input.'
        }}
      </p>
    </div>
  </div>
  <provider-selection-card
    [config]="config"
    [group]="group"
    [providerData]="providerData"
    [providerSelectionModalConfig]="providerSelectionModalConfig"
    [providerMultiLocationModal]="providerMultiLocationModal"
    [outOfNetwork]="outOfNetwork"
    [showOutOfNetworkStatus]="showOutOfNetworkStatus"
    (selectedProvider)="getSelectedProvider($event)"
  ></provider-selection-card>
</form>
