export const stateProp = 'state';
export const deaGroupProp = 'deaGroup';
export const deaNumberProp = 'number';
export const deaProp = 'dea';
export const StateDeaFormGroupArrayProp = 'StateDeaFormGroupArray';

export interface StateDeaContext {
  state: string;
  deaGroup: {
    number: string;
  };
}

export interface StateDeaPayload {
  id: string;
  state_code: string;
  state_id: string;
  state_id_type: string;
  number: string;
}
