import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getValue } from '@zipari/web-utils';
import { forkJoin, Subscription } from 'rxjs';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import {
  APIService,
  ConfigService,
  FormattingService,
} from '../../../shared/services';
import { LoggingService } from '../../../shared/services/logging.service';
import { ProvisionerService } from '../user-provisioning/provisioner/provisioner.service';
import { CX_CALLS } from '../../../shared/constants/cx-calls.constant';
import { GlobalConfig } from '../../../app.constants';
import { SectionConfig } from '../../../shared/models/TabsConfig.model';
import { manageAccessRequestDetailConstant } from './manage-access-request-detail.constant';
import {
  AccessRequest,
  ProviderAdmin,
} from './manage-access-request-detail.model';
@Component({
  selector: 'manage-access-request-detail',
  templateUrl: './manage-access-request-detail.component.html',
  styleUrls: ['./manage-access-request-detail.component.scss'],
})
export class ManageAccessRequestDetailComponent implements OnInit, OnDestroy {
  config: any;
  context: any;
  busy: Subscription;
  dataLoaded: boolean;
  role: string;
  data: any;
  showModal: boolean;
  access_id: string;
  globalConfig: GlobalConfig;
  replaceUserWithTaxId: boolean;

  constructor(
    private configService: ConfigService,
    private route: ActivatedRoute,
    private api: APIService,
    private router: Router,
    private http: HttpClient,
    private logger: LoggingService,
    private formatService: FormattingService,
    private provisioner: ProvisionerService,
    public analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.config = this.configService.getPageConfig(
      'manage-access-request-detail'
    );
    this.globalConfig = this.configService.getPageConfig(
      manageAccessRequestDetailConstant.global
    );
    this.access_id = this.route.snapshot.params.id;
    this.role = this.route.snapshot.data.role;
    this.context = {};
    this.replaceUserWithTaxId =
      this.globalConfig.toggleFeatures?.enableReplacementUserWithTaxId;
    this.showHideDatagridConfig();
    this.provisioner.setUserId(this.access_id);
    this.getUserDetails();
    this.data = {};
    this.showModal = false;
  }

  showHideDatagridConfig(): void {
    if (!this.replaceUserWithTaxId) {
      const sectionsConfig = this.config.tabs.tabConfigs[0].sections;
      const sectionConfig = this.getSectionByFormat(
        sectionsConfig,
        manageAccessRequestDetailConstant.datagrid
      );
      if (sectionConfig) {
        delete sectionConfig[manageAccessRequestDetailConstant.datagrid];
      }
    }
  }

  getSectionByFormat(
    sectionsConfig: SectionConfig[],
    format: string
  ): SectionConfig {
    return sectionsConfig.find(
      (section: SectionConfig) => section.format === format
    );
  }

  saveModal(event: any) {
    const userPatchUpdate = {
      status: 'denied',
    };
    if (event.formGroup.send_email[0] === 'true') {
      userPatchUpdate['send_denied_notification_email'] = true;
    }
    this.analyticsService.sendEvent(
      CX_CALLS.pp_manageaccess_provideruser_denied.event_key
    );
    this.api
      .patch(this.setUserId(this.config.endpoints.user), userPatchUpdate)
      .subscribe(
        (resp: any) => {
          this.routeBack();
        },
        (err: any) => {
          // TODO: add the error block
        }
      );
  }

  getUserDetails(): void | any {
    const portalUserApi = this.api.get(
      this.setUserId(this.config?.endpoints?.user)
    );

    this.busy = forkJoin([portalUserApi]).subscribe(([portalUser]) => {
      this.dataLoaded = true;
      this.context = portalUser;
      this.context['table_content'] = {};
      this.context.access_requested_for = this.context.access_requested_for.map(
        (value: any) => ({ tax_id: value })
      );
      this.context.table_content.access_requested_for =
        this.context.access_requested_for;
      this.config = this.formatService.formatConfigValueAsTemplate(
        this.config,
        this.context
      );
      this.config.workflow[0].form.groups[0].sections[0].header = `${portalUser.first_name} ${portalUser.last_name}`;
      this.provisioner.setUserFirstName(this.context.first_name);
      this.provisioner.setUserLastName(this.context.last_name);
      this.replaceUserWithTaxId &&
        (this.context.access_requested = this.updateUserAccessRequests(
          this.context.access_requested
        ));
    });
  }

  updateUserAccessRequests(
    userAccessRequests: AccessRequest[]
  ): AccessRequest[] {
    userAccessRequests?.forEach((replaceTaxId: AccessRequest) => {
      replaceTaxId.providerAdminName = replaceTaxId.provider_admins
        .map(
          (providerName: ProviderAdmin) =>
            `${providerName.first_name} ${providerName.last_name}`
        )
        .toString();
    });

    return userAccessRequests;
  }

  setUserId(target: string): string {
    return target.replace('${id}', this.access_id);
  }

  formatDate(date) {
    // LOOK AWAY-- ugly formatter -- will change in DS properly after urgent release
    // formats string date of 2020-12-31 format to 12/31/2020 (MM/DD/YYY)
    const dateSplit = date.split('-');

    return `${dateSplit[1]}/${dateSplit[2]}/${dateSplit[0]}`;
  }

  routeBack(): void {
    this.router.navigate([
      `provider-portal/payer-admin/manage-access-requests`,
    ]);
  }

  navigateSubNav(event): void {
    if (event.route) {
      if (event.route.includes('create-user')) {
        localStorage.setItem('user_created_From', 'access-request');
        event.route = `${event.route}/${this.access_id}`;
      }
      if (event.relative) {
        this.router.navigate([event.route], { relativeTo: this.route });
      } else {
        this.router.navigate([event.route]);
      }
    }
    if (event === 'deny') {
      this.openModal();
    }
  }

  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  getValue(data: object, attr): string | number {
    const value: string | number = getValue(data, attr.prop);

    return value;
  }

  ngOnDestroy(): void {
    this.provisioner.clearUserId();
  }
}
