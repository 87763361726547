<div class="dataTable">
  <div
    class="dataTable__description"
    *ngIf="config?.description"
    [innerHTML]="config.description"
  ></div>
  <div class="dataTable__table" *ngIf="config?.table">
    <zip-table
      [options]="config.table"
      (cellClicked)="navigateToDetail($event)"
    ></zip-table>
  </div>
</div>
