import { Component, Input, OnInit } from '@angular/core';

import { Observable, map, tap } from 'rxjs';
import { objectToArray } from '@zipari/shared-util-common';
import { APIService } from '../../../../shared/services';
import Claim from '../../../../shared/models/shared/Claim.model';
import { CX_CALLS } from '../../../../shared/constants/cx-calls.constant';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { AppealConstant } from '../../../templates/claim-appeal/claim-appeal.constants';
import { LinkListItemConfig } from '../../../templates/claim-appeal/claim-appeal.model';
import {
  SideBar,
  SideBarSectionConfig,
} from '../../../../shared/models/shared/side-bar.model';

@Component({
  selector: 'side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  @Input() claimId: string;
  @Input() config: SideBar;

  sidePanelInfo$: Observable<Claim>;
  sideBarSections: SideBarSectionConfig[];
  claim: Claim;

  constructor(
    private apiService: APIService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    const claimDetail$ = this.apiService.get(
      this.config.endpoints.claim.replace('${claim_id}', this.claimId)
    );
    this.sidePanelInfo$ = claimDetail$.pipe(
      map((claim: Claim) => (this.claim = claim)),
      tap(() => this.updateSidePanelLinks())
    );
    this.sideBarSections = objectToArray(this.config.sidebar);
  }

  updateSidePanelLinks(): void {
    this.sideBarSections.forEach((sideBarSection: SideBarSectionConfig) => {
      sideBarSection.links = this.getFilterLinks(sideBarSection);
      this.updateLinkListItems(sideBarSection);
    });
  }

  showSidePanelSection(sideBarSection: SideBarSectionConfig): boolean {
    return (
      !sideBarSection.conditions ||
      (sideBarSection.conditions?.dataDependency &&
        this.claim[sideBarSection.conditions.dataDependency])
    );
  }

  getFilterLinks(
    sideBarSection: SideBarSectionConfig
  ): LinkListItemConfig[] | undefined {
    return sideBarSection?.links?.filter(
      (link: LinkListItemConfig) =>
        link.target !== AppealConstant.eobDocumentId ||
        (link?.endpoint && this.claim?.[link.target])
    );
  }

  updateLinkListItems(sideBarSection: SideBarSectionConfig): void {
    sideBarSection.links = sideBarSection.links?.map(
      (link: LinkListItemConfig) => {
        link.endpoint &&
          (link.href = link.endpoint.replace(
            link.target,
            this.claim?.[link.target]
          ));

        return link;
      }
    );
  }

  linkClicked(event): void {
    if (CX_CALLS[event.cx_event_key])
      this.analyticsService.sendEvent(CX_CALLS[event.cx_event_key].event_key);
  }
}
