import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Tooltip } from '@zipari/shared-ds-util-tooltip';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { popup } from '../../shared/animations';
import { IconConfiguration } from '../icon/icon.model';
import { TooltipService } from './tooltip.service';

@UntilDestroy()
@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  animations: [popup],
})
export class TooltipComponent implements OnInit {
  @Input() config: Tooltip & IconConfiguration;
  @Output() open = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();
  @ViewChild('tooltipEl') tooltipEl: ElementRef<HTMLDivElement>;

  trackById: number;
  transformOrigin = '5% -0.625rem';
  boundingClientRect: any;
  tooltipMarginLeft: number;
  private readonly defaultIconName = 'info';
  private readonly defaultActiveIconName = 'info_active';

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    @Inject(DOCUMENT) readonly document: any,
    public tooltipService: TooltipService
  ) {
    this.tooltipService.updateInstanceCount();
    this.trackById = this.tooltipService.instanceCount - 1;
  }

  ngOnInit() {
    this.tooltipService.parentContainerBoundingClientRect$
      .pipe(take(1))
      .subscribe((parentBoundingClientRect) => {
        this.boundingClientRect = parentBoundingClientRect;
      });

    this.tooltipService.instances$
      .pipe(untilDestroyed(this))
      .pipe(
        map((tracker: boolean[]) => tracker[this.trackById]),
        distinctUntilChanged()
      )
      .subscribe((isOpened) => {
        if (isOpened) {
          this.open.emit();
        } else {
          this.close.emit();
        }
      });
  }

  toggleTooltip(event) {
    const tabletScreenWidth = 768;
    const mobileMarginValue = -10;
    const desktopMarginValue = 40;
    this.tooltipMarginLeft =
      window.innerWidth >= tabletScreenWidth
        ? mobileMarginValue
        : desktopMarginValue - event.srcElement.getBoundingClientRect().left;
    this.calculateDescriptionPosition(
      event.srcElement.getBoundingClientRect().right,
      this.boundingClientRect?.right
    );
    this.tooltipService.setTooltipToOpenAndClosePrevious(this.trackById);
  }

  public getIconName(isActive: boolean): string {
    if (isActive) {
      if (this.config.icon) {
        return this.config.activeIcon || this.config.icon;
      } else {
        return this.defaultActiveIconName;
      }
    } else {
      return this.config.icon || this.defaultIconName;
    }
  }

  /**
   * Determine how far to the right a tooltip is positioned based on the boundingClientRect.right property of the container
   */
  calculateDescriptionPosition(iconPosition: number, containerBcrRight?: any) {
    if (!containerBcrRight) {
      containerBcrRight = this.document.body.offsetWidth;
    }
    // percentage value relative to left side of container
    const rightSideOfContainer = 90;
    const oneHundredPercent = 100;
    const positionOfTooltipPercent = Math.ceil(
      (iconPosition / containerBcrRight) * oneHundredPercent
    );
    if (positionOfTooltipPercent >= rightSideOfContainer) {
      this.renderer.addClass(
        this.tooltipEl.nativeElement,
        'TooltipWrapper--right'
      );
      this.transformOrigin = '95% -0.625rem';
    }
  }

  @HostListener('document:click', ['$event'])
  clickoutAndCloseTooltip(event) {
    if (!this.elRef.nativeElement.contains(event.target))
      this.tooltipService.close(this.trackById);
  }
}
