import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableConfig } from '../../../templates/edit-authorization/edit-authorization.model';
import { FlyoutModalConfig } from './table-flyout.model';

@Component({
  selector: 'table-flyout',
  templateUrl: './table-flyout.component.html',
  styleUrls: ['./table-flyout.component.scss'],
})
export class TableFlyoutComponent {
  @Input() modalConfig: FlyoutModalConfig;
  @Input() tableConfig: TableConfig;
  @Input() showFlyout: boolean;
  @Output() closeFlyout = new EventEmitter<boolean>();
  @Output() selectedOptionList = new EventEmitter<object[]>();

  selectedRows: object[];
  isRowSelected: boolean = false;
  _preSelectedRows: object[];

  @Input() set preSelectedRows(selectedRows: object[]) {
    this._preSelectedRows = selectedRows;
  }

  getSelectedRows(event: object[]): void {
    this.selectedRows = event;
    this.isRowSelected = !!(this.selectedRows.length > 0);
  }

  addSelectedRows(): void {
    this.selectedOptionList.emit(this.selectedRows);
    this.closeFlyoutModal();
  }

  closeFlyoutModal() {
    this.closeFlyout.emit();
  }
}
