import { KeyValue } from '@angular/common';
import {
  SquidexConfig,
  SquidexUserFilter,
} from '@zipari/shared-data-access-squidex';
import { DocumentCenterTab, UserDetails } from '../util/document-center.model';
import { formatFilters } from './document-center.helper';

export const getFiltersQuery = (
  activeTab: DocumentCenterTab,
  filtersFormValue: Record<string, unknown>
): string => {
  const filtersArray: string[] = [];

  Object.keys(filtersFormValue)
    .reduce(formatFilters(filtersFormValue), [])
    .forEach((filter: KeyValue<string, unknown>) => {
      if (filter.key == 'search_string') {
        const searchPropsArray: string[] = [];
        activeTab.datagrid?.columns.forEach((column) => {
          searchPropsArray.push(
            `contains( data/${column.prop}/iv,'${filter.value}')`
          );
        });
        const filterByString = searchPropsArray.filter(Boolean).join(' or ');

        filtersArray.push(
          filterByString.length ? `(${filterByString})` : filterByString
        );
      } else if (
        filter.key === 'date_received_after' ||
        filter.key === 'date_received_before'
      ) {
        filtersArray.push(getDateRangeFilter(filter));
      }
    });

  return filtersArray.filter(Boolean).join(' and ');
};

export const getUserFiltersQuery = (
  squidexConfig: SquidexConfig,
  userDetails: UserDetails
): string => {
  if (squidexConfig.filters) {
    const enabledUserFilterKeys = Object.keys(squidexConfig.filters).filter(
      (filter: string) =>
        !!(
          squidexConfig.filters &&
          squidexConfig.filters[filter as keyof SquidexUserFilter]
        )
    );

    const userFilters: string[] = [];

    enabledUserFilterKeys.forEach((filter: string) => {
      userFilters.push(setUserFilterQuery(filter, userDetails));
    });

    return userFilters.filter(Boolean).join(' and ');
  }

  return '';
};

const setUserFilterQuery = (key: string, userDetails: UserDetails): string => {
  let filterQuery = '';
  switch (key) {
    case 'role': {
      filterQuery = `(contains(data/role/iv,'${userDetails.role}') or empty(data/role/iv))`;
      break;
    }
    case 'cohortKeys': {
      filterQuery = getCohortFilters(userDetails);
      break;
    }
    case 'policyId': {
      filterQuery = `(contains(data/policyIds/iv,'${userDetails.policyId}') or empty(data/policyIds/iv))`;
      break;
    }
  }

  return filterQuery;
};

const getCohortFilters = (userDetails: UserDetails): string => {
  const cohortFilters: string[] = [];
  userDetails.cohortKeys.forEach((cohortKey: string) => {
    cohortFilters.push(`contains(data/cohortIds/iv,'${cohortKey}')`);
  });

  const cohortFiltersQuery = cohortFilters.filter(Boolean).join(' or ');

  return `(${cohortFiltersQuery} or empty(data/cohortIds/iv))`;
};

const getDateRangeFilter = (filter: KeyValue<string, unknown>): string => {
  const dateRangeFilterArray: string[] = [];
  const datePath = 'data/date_received/iv';
  dateRangeFilterArray.push(
    `${datePath} ${filter.key === 'date_received_after' ? 'gt' : 'lt'} ${
      filter.value
    }`
  );

  return dateRangeFilterArray.filter(Boolean).join(' and ');
};
