import { Injectable } from '@angular/core';
import Referral from '../../../shared/models/shared/Referral.model';

@Injectable({
  providedIn: 'root',
})
export class DummyDataService {
  diagnosis = [
    {
      id: '221',
      code: 'E0.123',
      short_description: 'short description of diagnosis type 1',
      long_description: 'long description of diagnosis type 1 extra text here',
    },
    {
      id: '13',
      code: 'E1.2345',
      short_description: 'short description of diagnosis type 2',
      long_description: 'long description of diagnosis type 2 extra text here',
    },
    {
      id: '64',
      code: 'B0.123',
      short_description: 'short description of diagnosis type 3',
      long_description: 'long description of diagnosis type 3 extra text here',
    },
    {
      id: '52',
      code: 'B1.938',
      short_description: 'short description of diagnosis type 4',
      long_description: 'long description of diagnosis type 4 extra text here',
    },
  ];

  documentTypes: unknown = [
    {
      id: '221',
      code: '123',
      description: 'short description of category type 1',
      type: 'Additional Clinical Information',
    },
    {
      id: '13',
      code: '2345',
      description: 'short description of category type 2',
      type: 'Additional Client Information',
    },
    {
      id: '64',
      code: '123',
      description: 'short description of category type 3',
      type: 'Insurance Documents',
    },
    {
      id: '52',
      code: '938',
      description: 'short description of category type 4',
      type: 'ID Card',
    },
  ];

  serviceTypes: unknown = [
    {
      id: '22',
      code: '20091',
      short_description: 'short description of service type 1',
      long_description: 'long description of service type 1 extra text here',
    },
    {
      id: '13',
      code: '808791',
      short_description: 'short description of service type 2',
      long_description: 'long description of service type 2 extra text here',
    },
    {
      id: '64',
      code: '23491',
      short_description: 'short description of service type 3',
      long_description: 'long description of service type 3 extra text here',
    },
    {
      id: '52',
      code: '098121',
      short_description: 'short description of service type 4',
      long_description: 'long description of service type 4 extra text here',
    },
  ];

  procedures: unknown = [
    {
      id: '221',
      code: '123',
      short_description: 'short description of procedure type 1',
      long_description: 'long description of procedure type 1 extra text here',
    },
    {
      id: '13',
      code: '2345',
      short_description: 'short description of procedure type 2',
      long_description: 'long description of procedure type 2 extra text here',
    },
    {
      id: '64',
      code: '123',
      short_description: 'short description of procedure type 3',
      long_description: 'long description of procedure type 3 extra text here',
    },
    {
      id: '52',
      code: '938',
      short_description: 'short description of procedure type 4',
      long_description: 'long description of procedure type 4 extra text here',
    },
  ];

  serviceCategories: unknown = [
    {
      id: '221',
      code: '123',
      description: 'short description of category type 1',
      name: 'Endocrinology',
    },
    {
      id: '13',
      code: '2345',
      description: 'short description of category type 2',
      name: 'Neurology',
    },
    {
      id: '64',
      code: '123',
      description: 'short description of category type 3',
      name: 'Dermatology',
    },
    {
      id: '52',
      code: '938',
      description: 'short description of category type 4',
      name: 'Cardiology',
    },
  ];

  practitioners: unknown = [
    {
      id: '11',
      accepting_new_pateints: true,
      birth_date: '1987-12-05',
      dea: '17484',
      degrees: ['MD'],
      display_in_directory: true,
      gender: 'Female',
      languages: ['English', 'Spanish'],
      license_number: '11381138',
      name: {
        first_name: 'Leia',
        last_name: 'Organa',
        middle_name: '',
        prefix: '',
      },
      npi: '113441241',
      practicing_as: 'General Practitioner',
      specialties: ['General Medicine'],
      ssn: 'XXX-XX-0000',
      status: 'active',
      taxonomy_code: '1828984',
      title: 'Chief Physician',
    },
    {
      id: '21',
      accepting_new_pateints: false,
      birth_date: '1989-08-17',
      dea: '3423424',
      degrees: ['MD'],
      display_in_directory: true,
      gender: 'Male',
      languages: ['English', 'French'],
      license_number: '908979875',
      name: {
        first_name: 'Lando',
        last_name: 'Calrissian',
        middle_name: '',
        prefix: '',
      },
      npi: '83893986',
      practicing_as: 'Radiologist',
      specialties: ['Radiology'],
      ssn: 'XXX-XX-8383',
      status: 'active',
      taxonomy_code: '283947928',
      title: 'Chielf Radiologist',
    },
    {
      id: '30',
      accepting_new_pateints: true,
      birth_date: '1977-05-25',
      dea: '9287349',
      degrees: ['MD'],
      display_in_directory: true,
      gender: 'Female',
      languages: ['English', 'Portuguese'],
      license_number: '234141515',
      name: {
        first_name: 'Wedge',
        last_name: 'Antilles',
        middle_name: '',
        prefix: '',
      },
      npi: '092735986',
      practicing_as: 'GP',
      specialties: ['General Practitioner'],
      ssn: 'XXX-XX-9898',
      status: 'active',
      taxonomy_code: '2983749',
      title: 'General Practitioner',
    },
    {
      id: '41',
      accepting_new_pateints: true,
      birth_date: '1980-05-26',
      dea: '2235',
      degrees: ['MD'],
      display_in_directory: true,
      gender: 'Female',
      languages: ['English'],
      license_number: '23423423',
      name: {
        first_name: 'Gial',
        last_name: 'Ackbar',
        middle_name: '',
        prefix: '',
      },
      npi: '98279857298',
      practicing_as: 'General Pratitioner',
      specialties: ['General Practitioner'],
      ssn: 'XXX-XX-2342',
      status: 'active',
      taxonomy_code: '234234',
      title: 'Doctor',
    },
    {
      id: '52',
      accepting_new_pateints: true,
      birth_date: '1983-04-12',
      dea: '23482',
      degrees: ['MD'],
      display_in_directory: true,
      gender: 'Male',
      languages: ['English', 'German'],
      license_number: '1312415',
      name: {
        first_name: 'Allanisette',
        last_name: 'Morrisett',
        middle_name: '',
        prefix: '',
      },
      npi: '234298',
      practicing_as: 'Neurology',
      specialties: ['Neurology'],
      ssn: 'XXX-XX-1111',
      status: 'active',
      taxonomy_code: '987',
      title: 'Lead Neurologist',
    },
    {
      id: '61',
      accepting_new_pateints: true,
      birth_date: '1960-09-01',
      dea: '19827',
      degrees: ['MD'],
      display_in_directory: true,
      gender: 'Female',
      languages: ['English'],
      license_number: '23498',
      name: {
        first_name: 'Amanda',
        last_name: 'Kissenheug',
        middle_name: '',
        prefix: '',
      },
      npi: '9807987',
      practicing_as: 'Oncology',
      specialties: ['Oncology'],
      ssn: 'XXX-XX-2222',
      status: 'active',
      taxonomy_code: '7867867',
      title: 'Oncologist',
    },
  ];

  siteLocations: unknown = [
    {
      facility_name: 'California Hospital Centre',
      id: 1,
      care_system: 'California Care System',
      locations: [
        {
          street_name_1: '42 Emory Ramp',
          street_name_2: null,
          city: 'Gardena',
          state: 'CA',
          zipcode: '90248',
          main_location: true,
        },
      ],
      taxonomy_code: '1234',
      tax_id: '123456789',
      facility_type: 'care_system',
      phone_number: '4155555890',
      fax_number: '4155555891',
      npi: '0000000',
      specialties: ['Chiropractic Medicine'],
      medicare: 'A6101123',
      site_url: 'www.thecaliforniahospitalcentre.org',
      contact: {
        contact_name: 'John Doe',
        addresses: [
          {
            street_name_1: '42 Emory Ramp',
            street_name_2: null,
            city: 'Gardena',
            state: 'CA',
            zipcode: '90248',
            main_location: true,
          },
        ],
        phone_number: '4155555555',
        fax_number: '4155555556',
        title: 'Doctor',
        email: 'JohnDoe@email.com',
      },
      accepting_new_patients: false,
      cultural_training_complete: false,
      affiliations: ['affiliation_one', 'affiliation_two'],
      cultural_training_date: '2020-06-04',
      restrictions: ['Some Restriction', 'Some Other Restriction'],
      display_in_directory: true,
      available_for_disability: true,
      languages: ['English', 'Spanish'],
      visiting_clinic: true,
      exam_room_available_for_disability: true,
      is_primary_care: true,
      special_services: ['service_1', 'service_2'],
      location_hours: [
        {
          day: 'Monday',
          open: true,
          open_hour: '10:00',
          close_hour: '17:00',
        },
        {
          day: 'Tuesday',
          open: false,
          open_hour: '10:00',
          close_hour: '17:00',
        },
        {
          day: 'Wednesday',
          open: true,
          open_hour: '10:00',
          close_hour: '17:00',
        },
        {
          day: 'Thursday',
          open: false,
          open_hour: '10:00',
          close_hour: '17:00',
        },
        {
          day: 'Friday',
          open: true,
          open_hour: '10:00',
          close_hour: '17:00',
        },
        {
          day: 'Saturday',
          open: false,
          open_hour: '10:00',
          close_hour: '17:00',
        },
        {
          day: 'Sunday',
          open: true,
          open_hour: '10:00',
          close_hour: '17:00',
        },
      ],
      third_party: {
        npi: '223456789',
        tax_id: '223456789',
        name: 'Billing Partner LLC',
        locations: [
          {
            street_name_1: '22 Street Blvd',
            street_name_2: 'Suite 206',
            city: 'Morris_Plains',
            state: 'NJ',
            zipcode: '07950',
            main_location: true,
          },
        ],
        phone_number: '9735551234',
        fax_number: '9735551235',
        effective_date: '2020-01-01',
      },
      billing: {
        npi: '223456789',
        tax_id: '223456789',
        name: 'Billing Partner LLC',
        locations: [
          {
            street_name_1: '22 Street Blvd',
            street_name_2: 'Suite 206',
            city: 'Morris Plains',
            state: 'MO',
            zipcode: '07950',
            main_location: true,
          },
        ],
        phone_number: '9735551234',
        fax_number: '9735551235',
        effective_date: '2020-01-01',
      },
    },
    {
      facility_name: 'Site Location 2',
      care_system: 'California Care System',
      locations: [
        {
          street_name_1: '1234 Littleton Road',
          street_name_2: null,
          city: 'Morris Plains',
          state: 'NJ',
          zipcode: '07950',
          main_location: true,
        },
      ],
      taxonomy_code: '1232346',
      tax_id: '148757',
      facility_type: 'care_system',
      phone_number: '(973) 555 - 5555',
      fax_number: '(973) 555 - 5556',
      npi: '20375948',
      specialties: ['General Medicine'],
      medicare: 'FJ393',
      site_url: 'www.website.internet',
      contact: {
        contact_name: 'John Something',
        addresses: [
          {
            street_name_1: '1234 Littleton Road',
            street_name_2: null,
            city: 'Morris Plains',
            state: 'NJ',
            zipcode: '07950',
            main_location: true,
          },
        ],
        phone_number: '(934) 234 - 2344',
        fax_number: '(908) 234 1533',
        title: 'Title Here',
        email: 'email@internet.com',
      },
      accepting_new_patients: true,
      cultural_training_complete: true,
      affiliations: ['affiliation_one', 'affiliation_two'],
      cultural_training_date: '',
      restrictions: [''],
      display_in_directory: true,
      available_for_disability: true,
      languages: ['English'],
      visiting_clinic: true,
      exam_room_available_for_disability: true,
      is_primary_care: true,
      special_services: ['service_1', 'service_2'],
      location_hours: [
        {
          day: 'Monday',
          open: true,
          open_hour: '9:00AM',
          close_hour: '5:00PM',
        },
        {
          day: 'Tuesday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
        {
          day: 'Wednesday',
          open: true,
          open_hour: '9:00AM',
          close_hour: '5:00PM',
        },
        {
          day: 'Thursday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
        {
          day: 'Friday',
          open: true,
          open_hour: '9:00AM',
          close_hour: '5:00PM',
        },
        {
          day: 'Saturday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
        {
          day: 'Sunday',
          open: true,
          open_hour: '',
          close_hour: '',
        },
      ],
      third_party: {
        npi: '123456789',
        tax_id: '123456789',
        name: 'Billing Partner LLC',
        locations: [
          {
            street_name_1: '22 Street Blvd',
            street_name_2: 'Suite 206',
            city: 'Morris Plains',
            state: 'NJ',
            zipcode: '07950',
            main_location: true,
          },
        ],
        phone_number: '(973) 555 - 1234',
        fax_number: '(973) - 555 - 1235',
        effective_date: '2020-01-01',
      },
      billing: {
        npi: '123456789',
        tax_id: '123456789',
        name: '',
        locations: [
          {
            street_name_1: '22 Street Blvd',
            street_name_2: 'Suite 206',
            city: 'Morris Plains',
            state: 'NJ',
            zipcode: '07950',
            main_location: true,
          },
        ],
        phone_number: '',
        fax_number: '',
        effective_date: '',
      },
    },
    {
      facility_name: 'Super Health Facility',
      care_system: 'California Care System',
      locations: [
        {
          street_name_1: '123 Address Road',
          street_name_2: null,
          city: 'Cityville',
          state: 'NY',
          zipcode: '10006',
          main_location: true,
        },
      ],
      taxonomy_code: '8790987',
      tax_id: '8970558',
      facility_type: 'care_system',
      phone_number: '(939) 583 - 8389',
      fax_number: '(832) 583 - 1124',
      npi: '89747598',
      specialties: [''],
      medicare: 'JFO83838',
      site_url: 'www.hospital.com',
      contact: {
        contact_name: 'Another Name',
        addresses: [
          {
            street_name_1: '1828 Lane Street',
            street_name_2: null,
            city: 'Townstown',
            state: 'CA',
            zipcode: '90210',
            main_location: true,
          },
        ],
        phone_number: '(391) 235 - 1858',
        fax_number: '(373) 283 - 1848',
        title: 'Something',
        email: 'email@test.com',
      },
      accepting_new_patients: true,
      cultural_training_complete: true,
      affiliations: ['affiliation_one', 'affiliation_two'],
      cultural_training_date: '',
      restrictions: [''],
      display_in_directory: true,
      available_for_disability: true,
      languages: ['English'],
      visiting_clinic: true,
      exam_room_available_for_disability: true,
      is_primary_care: true,
      special_services: ['service_1', 'service_2'],
      location_hours: [
        {
          day: 'Monday',
          open: true,
          open_hour: '9:00AM',
          close_hour: '5:00PM',
        },
        {
          day: 'Tuesday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
        {
          day: 'Wednesday',
          open: true,
          open_hour: '9:00AM',
          close_hour: '5:00PM',
        },
        {
          day: 'Thursday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
        {
          day: 'Friday',
          open: true,
          open_hour: '9:00AM',
          close_hour: '5:00PM',
        },
        {
          day: 'Saturday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
        {
          day: 'Sunday',
          open: true,
          open_hour: '',
          close_hour: '',
        },
      ],
      third_party: {
        npi: '123456789',
        tax_id: '123456789',
        name: 'Billing Partner LLC',
        locations: [
          {
            street_name_1: '22 Street Blvd',
            street_name_2: 'Suite 206',
            city: 'Morris Plains',
            state: 'NJ',
            zipcode: '07950',
            main_location: true,
          },
        ],
        phone_number: '(973) 555 - 1234',
        fax_number: '(973) - 555 - 1235',
        effective_date: '2020-01-01',
      },
      billing: {
        npi: '123456789',
        tax_id: '123456789',
        name: '',
        locations: [
          {
            street_name_1: '22 Street Blvd',
            street_name_2: 'Suite 206',
            city: 'Morris Plains',
            state: 'NJ',
            zipcode: '07950',
            main_location: true,
          },
        ],
        phone_number: '',
        fax_number: '',
        effective_date: '',
      },
    },
    {
      facility_name: 'Super Health Facility',
      care_system: 'California Care System',
      locations: [
        {
          street_name_1: '123 Address Road',
          street_name_2: null,
          city: 'Cityville',
          state: 'NY',
          zipcode: '10006',
          main_location: true,
        },
      ],
      taxonomy_code: '8790987',
      tax_id: '8970558',
      facility_type: 'care_system',
      phone_number: '(939) 583 - 8389',
      fax_number: '(832) 583 - 1124',
      npi: '89747598',
      specialties: [''],
      medicare: 'JFO83838',
      site_url: 'www.hospital.com',
      contact: {
        contact_name: 'Another Name',
        addresses: [
          {
            street_name_1: '1828 Lane Street',
            street_name_2: null,
            city: 'Townstown',
            state: 'CA',
            zipcode: '90210',
            main_location: true,
          },
        ],
        phone_number: '(391) 235 - 1858',
        fax_number: '(373) 283 - 1848',
        title: 'Something',
        email: 'email@test.com',
      },
      accepting_new_patients: true,
      cultural_training_complete: true,
      affiliations: ['affiliation_one', 'affiliation_two'],
      cultural_training_date: '',
      restrictions: [''],
      display_in_directory: true,
      available_for_disability: true,
      languages: ['English'],
      visiting_clinic: true,
      exam_room_available_for_disability: true,
      is_primary_care: true,
      special_services: ['service_1', 'service_2'],
      location_hours: [
        {
          day: 'Monday',
          open: true,
          open_hour: '9:00AM',
          close_hour: '5:00PM',
        },
        {
          day: 'Tuesday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
        {
          day: 'Wednesday',
          open: true,
          open_hour: '9:00AM',
          close_hour: '5:00PM',
        },
        {
          day: 'Thursday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
        {
          day: 'Friday',
          open: true,
          open_hour: '9:00AM',
          close_hour: '5:00PM',
        },
        {
          day: 'Saturday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
        {
          day: 'Sunday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
      ],
      third_party: {
        npi: '123456789',
        tax_id: '123456789',
        name: 'Billing Partner LLC',
        locations: [
          {
            street_name_1: '22 Street Blvd',
            street_name_2: 'Suite 206',
            city: 'Morris Plains',
            state: 'NJ',
            zipcode: '07950',
            main_location: true,
          },
        ],
        phone_number: '(973) 555 - 1234',
        fax_number: '(973) - 555 - 1235',
        effective_date: '2020-01-01',
      },
      billing: {
        npi: '123456789',
        tax_id: '123456789',
        name: '',
        locations: [
          {
            street_name_1: '22 Street Blvd',
            street_name_2: 'Suite 206',
            city: 'Morris Plains',
            state: 'NJ',
            zipcode: '07950',
            main_location: true,
          },
        ],
        phone_number: '',
        fax_number: '',
        effective_date: '',
      },
    },
    {
      facility_name: 'Super Health Facility',
      care_system: 'California Care System',
      locations: [
        {
          street_name_1: '123 Address Road',
          street_name_2: null,
          city: 'Cityville',
          state: 'NY',
          zipcode: '10006',
          main_location: true,
        },
      ],
      taxonomy_code: '8790987',
      tax_id: '8970558',
      facility_type: 'care_system',
      phone_number: '(939) 583 - 8389',
      fax_number: '(832) 583 - 1124',
      npi: '89747598',
      specialties: [''],
      medicare: 'JFO83838',
      site_url: 'www.hospital.com',
      contact: {
        contact_name: 'Another Name',
        addresses: [
          {
            street_name_1: '1828 Lane Street',
            street_name_2: null,
            city: 'Townstown',
            state: 'CA',
            zipcode: '90210',
            main_location: true,
          },
        ],
        phone_number: '(391) 235 - 1858',
        fax_number: '(373) 283 - 1848',
        title: 'Something',
        email: 'email@test.com',
      },
      accepting_new_patients: true,
      cultural_training_complete: true,
      affiliations: ['affiliation_one', 'affiliation_two'],
      cultural_training_date: '',
      restrictions: [''],
      display_in_directory: true,
      available_for_disability: true,
      languages: ['English'],
      visiting_clinic: true,
      exam_room_available_for_disability: true,
      is_primary_care: true,
      special_services: ['service_1', 'service_2'],
      location_hours: [
        {
          day: 'Monday',
          open: true,
          open_hour: '9:00AM',
          close_hour: '5:00PM',
        },
        {
          day: 'Tuesday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
        {
          day: 'Wednesday',
          open: true,
          open_hour: '9:00AM',
          close_hour: '5:00PM',
        },
        {
          day: 'Thursday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
        {
          day: 'Friday',
          open: true,
          open_hour: '9:00AM',
          close_hour: '5:00PM',
        },
        {
          day: 'Saturday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
        {
          day: 'Sunday',
          open: true,
          open_hour: '',
          close_hour: '',
        },
      ],
      third_party: {
        npi: '123456789',
        tax_id: '123456789',
        name: 'Billing Partner LLC',
        locations: [
          {
            street_name_1: '22 Street Blvd',
            street_name_2: 'Suite 206',
            city: '4Morris Plains',
            state: 'NJ',
            zipcode: '07950',
            main_location: true,
          },
        ],
        phone_number: '(973) 555 - 1234',
        fax_number: '(973) - 555 - 1235',
        effective_date: '2020-01-01',
      },
      billing: {
        npi: '123456789',
        tax_id: '123456789',
        name: '',
        locations: [
          {
            street_name_1: '22 Street Blvd',
            street_name_2: 'Suite 206',
            city: '4Morris Plains',
            state: 'NJ',
            zipcode: '07950',
            main_location: true,
          },
        ],
        phone_number: '',
        fax_number: '',
        effective_date: '',
      },
    },
    {
      facility_name: 'Super Health Facility',
      care_system: 'California Care System',
      locations: [
        {
          street_name_1: '123 Address Road',
          street_name_2: null,
          city: 'Cityville',
          state: 'NY',
          zipcode: '10006',
          main_location: true,
        },
      ],
      taxonomy_code: '8790987',
      tax_id: '8970558',
      facility_type: 'care_system',
      phone_number: '(939) 583 - 8389',
      fax_number: '(832) 583 - 1124',
      npi: '89747598',
      specialties: [''],
      medicare: 'JFO83838',
      site_url: 'www.hospital.com',
      contact: {
        contact_name: 'Another Name',
        addresses: [
          {
            street_name_1: '1828 Lane Street',
            street_name_2: null,
            city: 'Townstown',
            state: 'CA',
            zipcode: '90210',
            main_location: true,
          },
        ],
        phone_number: '(391) 235 - 1858',
        fax_number: '(373) 283 - 1848',
        title: 'Something',
        email: 'email@test.com',
      },
      accepting_new_patients: true,
      cultural_training_complete: true,
      affiliations: ['affiliation_one', 'affiliation_two'],
      cultural_training_date: '',
      restrictions: [''],
      display_in_directory: true,
      available_for_disability: true,
      languages: ['English'],
      visiting_clinic: true,
      exam_room_available_for_disability: true,
      is_primary_care: true,
      special_services: ['service_1', 'service_2'],
      location_hours: [
        {
          day: 'Monday',
          open: true,
          open_hour: '9:00AM',
          close_hour: '5:00PM',
        },
        {
          day: 'Tuesday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
        {
          day: 'Wednesday',
          open: true,
          open_hour: '9:00AM',
          close_hour: '5:00PM',
        },
        {
          day: 'Thursday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
        {
          day: 'Friday',
          open: true,
          open_hour: '9:00AM',
          close_hour: '5:00PM',
        },
        {
          day: 'Saturday',
          open: false,
          open_hour: '',
          close_hour: '',
        },
        {
          day: 'Sunday',
          open: true,
          open_hour: '',
          close_hour: '',
        },
      ],
      third_party: {
        npi: '123456789',
        tax_id: '123456789',
        name: 'Billing Partner LLC',
        locations: [
          {
            street_name_1: '22 Street Blvd',
            street_name_2: 'Suite 206',
            city: '2Morris Plains',
            state: 'NJ',
            zipcode: '07950',
            main_location: true,
          },
        ],
        phone_number: '(973) 555 - 1234',
        fax_number: '(973) - 555 - 1235',
        effective_date: '2020-01-01',
      },
      billing: {
        npi: '123456789',
        tax_id: '123456789',
        name: '',
        locations: [
          {
            street_name_1: '22 Street Blvd',
            street_name_2: 'Suite 206',
            city: '3Morris Plains',
            state: 'NJ',
            zipcode: '07950',
            main_location: true,
          },
        ],
        phone_number: '',
        fax_number: '',
        effective_date: '',
      },
    },
  ];

  specialties: string[] = [
    'Anesthesiology',
    ' Emergency Medicine',
    'Family Medicine',
    'Internal Medicine',
    'Ophthalmology',
    'Orthopaedic Surgery',
    ' Pediatrics',
    'Plastic Surgery',
    'Neurology',
    'Diagnostic Radiology',
    'General Surgery',
    'Urology',
  ];

  referrals: Referral[] = [
    {
      id: 1,
      number: 'REF7283',
      secondary_number: 'TEMP1010',
      member_id: 'Mem909009',
      referring_provider_id: 'PRD123123',
      servicing_provider_id: 'PRD902992',
      service_category_id: 'SC1232',
      service_type_id: 'ST9876',
      diagnoses: [
        {
          diagnosis_id: 'Dia1234',
          is_primary: true,
        },
        {
          diagnosis_id: 'Dia5678',
          is_primary: false,
        },
      ],
      procedures: [
        {
          procedure_id: 'Pro1234',
          is_primary: true,
        },
        {
          procedure_id: 'Pro5678',
          is_primary: false,
        },
      ],
      service_unit_type: 'visits',
      service_unit_quantity: 5,
      priority: 'medium',
      status: 'pended',
      status_reason: 'Requires Medical Director Review',
      status_updated_date: '2020-10-16',
      effective_date: '2020-09-16',
      termination_date: '2020-12-16',
      decision_date: '2020-10-16',
      documents: ['Doc5678', 'Doc123'],
      notes: [
        {
          note_type: 'service_note',
          note_content: 'Service notes, or any comments the user can enter.',
        },
        {
          note_type: 'referral_note',
          note_content: 'Referral notes, or any comments the user can enter.',
        },
      ],
    },
  ];

  public getSet(key) {
    switch (key) {
      case 'practitioners':
        return this.practitioners;
      case 'service_types':
        return this.serviceTypes;
      case 'site_locations':
        return this.siteLocations;
      case 'diagnosis':
        return this.diagnosis;
      case 'specialties':
        return this.specialties;
      case 'service_categories':
        return this.serviceCategories;
      case 'documentTypes':
        return this.documentTypes;
      case 'procedures':
        return this.procedures;
    }
  }
}
