<div
  class="form-control__wrapper"
  [ngClass]="{
    'form-control__wrapper--inline': direction === 'row',
    'form-control__wrapper--filter': direction === 'filter'
  }"
>
  <label class="form-control__label">
    <p class="t-data t-bold">{{ config.label }}</p>
  </label>

  <div
    [formGroup]="formGroup"
    [ngClass]="{ 'address-group__controls': !!config.grid }"
  >
    <form-control
      [direction]="direction || 'default'"
      [zipGridCell]="countryConfig.grid"
      [config]="countryConfig"
      [control]="formGroup.get(countryConfig.prop)"
    >
    </form-control>

    <ng-container *ngIf="!config?.zipcodeCountyOnly">
      <form-control
        [direction]="direction || 'default'"
        [zipGridCell]="address1Config.grid"
        [config]="address1Config"
        [control]="formGroup.get(address1Config.prop)"
      >
      </form-control>

      <form-control
        *ngIf="!address2Config.hidden"
        [direction]="direction || 'default'"
        [zipGridCell]="address2Config.grid"
        [config]="address2Config"
        [control]="formGroup.get(address2Config.prop)"
      >
      </form-control>

      <ng-container *ngIf="!config.populateCity">
        <form-control
          [direction]="direction || 'default'"
          [zipGridCell]="cityConfig.grid"
          [config]="cityConfig"
          [control]="formGroup.get(cityConfig.prop)"
        >
        </form-control>
      </ng-container>

      <ng-container *ngIf="config.populateCity">
        <cities
          [zipGridCell]="cityDropdownConfig.grid"
          [formControlName]="cityDropdownConfig.prop"
          [citiesConfig]="cityDropdownConfig"
          [group]="formGroup"
        >
        </cities>
      </ng-container>
    </ng-container>

    <form-control
      [direction]="direction || 'default'"
      [zipGridCell]="zipCodeConfig.grid"
      [config]="zipCodeConfig"
      [control]="formGroup.get(zipCodeConfig.prop)"
    >
    </form-control>

    <counties
      *ngIf="
        !countyConfig.hidden &&
        !countyConfig.countyText &&
        !config?.zipcodeCountyOnly
      "
      [zipGridCell]="countyConfig.grid"
      [formControlName]="countyConfig.prop"
      [countyConfig]="countyCodeConfig"
      [group]="formGroup"
    >
    </counties>

    <form-control
      *ngIf="!isOtherCountrySelected"
      [direction]="direction || 'default'"
      [zipGridCell]="stateConfig.grid"
      [config]="stateConfig"
      [control]="formGroup.get(stateConfig.prop)"
    >
    </form-control>
    <form-control
      *ngIf="isOtherCountrySelected"
      [direction]="direction || 'default'"
      [zipGridCell]="stateTextboxConfig.grid"
      [config]="stateTextboxConfig"
      [control]="formGroup.get(stateTextboxConfig.prop)"
    >
    </form-control>

    <form-control
      *ngIf="!countyConfig.hidden && countyConfig.countyText"
      [direction]="direction || 'default'"
      [zipGridCell]="countyConfig.grid"
      [config]="countyConfig"
      [control]="formGroup.get(countyConfig.prop)"
    >
    </form-control>
  </div>
</div>
