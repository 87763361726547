import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IconModule } from '../../icon/icon.module';
import { ControlDropdownComponent } from './dropdown.component';

@NgModule({
  declarations: [ControlDropdownComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IconModule],
  exports: [ControlDropdownComponent],
})
export class ControlDropdownModule {}
