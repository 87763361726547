<section
  class="notification"
  [ngClass]="{
    'is-open': !!notification.open,
    'is-read': !!notification.is_read
  }"
  *ngFor="let notification of notifications"
>
  <div class="notification__datetime">
    <p class="t-data" [ngClass]="{ 't-bold': !notification.is_read }">
      {{ notification.effective_date | date : 'mediumDate' }}
    </p>
    <p class="t-caption t-caps t-alt" *ngIf="showTimeStamp">
      {{ notification.created_at | date : 'shortTime' }}
    </p>
  </div>

  <div class="notification__icon" *ngIf="notification.tags">
    <i
      class="icon icon--medium"
      [ngClass]="{
        'icon--product': productIconLigatures.includes(
          setNotificationIcon(notification)
        )
      }"
      >{{ setNotificationIcon(notification) }}</i
    >
  </div>

  <div class="notification__timeline"></div>

  <div class="notification__content">
    <div class="notification__pointer"></div>

    <ng-container *ngIf="notification.url; else notificationContent">
      <a (click)="linkClicked(notification)">
        <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
      </a>
    </ng-container>

    <ng-template #notificationContent>
      <header
        class="notification__content__header icon--pointer"
        (click)="toggle(notification)"
      >
        <p class="t-data" [ngClass]="{ 't-bold': !notification.is_read }">
          {{ notification.title }}
        </p>
        <i
          class="icon icon--medium icon--pointer"
          [ngClass]="{ 'icon--rotate180': notification.open }"
        >
          chevron_down
        </i>
      </header>
    </ng-template>

    <section
      class="notification__content__body"
      *ngIf="!!notification.open"
      @accordion
    >
      <p class="t-data" [innerHtml]="notification.body"></p>
    </section>
  </div>
</section>
