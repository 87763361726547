<ng-container *ngIf="control">
  <div class="u-relative">
    <div class="form-control__icon" *ngIf="config.icon && !config.isIconRight">
      <icon [name]="config.icon" [config]="{ fill: 'fc-icon-color' }"> </icon>
    </div>

    <!--
            Custom input option is exposed to allow higher-level components access to the native input element
            while preserving reusability of form-control components label/error/etc. templating.
            Examples include custom form components that implement ControlValueAccessor that need the native input
        -->
    <ng-container *ngIf="customInputTemplate; else default">
      <ng-container *ngTemplateOutlet="customInputTemplate"></ng-container>
    </ng-container>

    <ng-template #default>
      <ng-container *ngIf="!config.mask; else maskTemplate">
        <input
          class="form-control"
          [ngClass]="{
            'form-control--icon-right': config.isIconRight
          }"
          tabindex="0"
          (focusout)="focusOutEvent.emit($event)"
          (focus)="focusEvent.emit($event)"
          #input
          ngDefaultControl
          (keydown)="
            (config.type === 'number'
              ? $event.key !== 'e' && $event.key !== 'E'
              : true)
          "
          [attr.disabled]="isDisabled || null"
          [attr.readonly]="config.readonly || null"
          [attr.aria-label]="config.ariaLabel"
          [attr.aria-invalid]="
            (control.dirty || control.touched) && !control.valid
          "
          [attr.aria-describedby]="(config?.id || config?.prop) + '-label'"
          [attr.aria-errormessage]="
            'error_' + (config?.id || config?.prop) + '-input'
          "
          [attr.aria-required]="
            config?.validators && config?.validators.includes('required')
              ? true
              : false
          "
          [attr.autocomplete]="autocomplete !== null ? autocomplete : true"
          [attr.placeholder]="config.placeholder ? config.placeholder : null"
          [attr.type]="config.type"
          [attr.max]="
            config.type === 'date' ? config.max || '9999-01-01' : null
          "
          [attr.min]="config.type === 'date' ? config.min || '' : null"
          [formControl]="control"
          [id]="config.id || config.prop"
          [maxlength]="config.maxLength || ''"
          [name]="config.prop || config.name"
          (input)="onInputChange()"
          [attr.data-test-id]="
            'input_' + (config.id || config.prop || config.ariaLabel)
          "
        />
      </ng-container>
      <ng-template #maskTemplate>
        <ng-container *ngIf="config.type === 'datePicker'; else typeText">
          <zip-date-picker
            [config]="config"
            [control]="control"
          ></zip-date-picker>
        </ng-container>

        <ng-template #typeText>
          <input
            class="form-control"
            [ngClass]="{
              'form-control--icon-right': config.isIconRight
            }"
            tabindex="0"
            (focusout)="focusOutEvent.emit($event)"
            (focus)="focusEvent.emit($event)"
            #input
            [zipMask]="config.mask"
            [attr.disabled]="isDisabled || null"
            [attr.readonly]="config.readonly || null"
            [attr.aria-label]="config.ariaLabel"
            [attr.aria-invalid]="
              (control.dirty || control.touched) && !control.valid
            "
            [attr.aria-describedby]="(config?.id || config?.prop) + '-label'"
            [attr.aria-errormessage]="
              'error_' + (config?.id || config?.prop) + '-input'
            "
            [attr.aria-required]="
              config?.validators && config?.validators.includes('required')
                ? true
                : false
            "
            [attr.autocomplete]="
              autocomplete !== null ? autocomplete : config.prop || config.name
            "
            [attr.placeholder]="config.placeholder ? config.placeholder : null"
            [attr.max]="
              config.type === 'date' ? config.max || '9999-01-01' : null
            "
            [attr.type]="config.type"
            [formControl]="control"
            [id]="config.id || config.prop"
            [maxlength]="config.maxLength || ''"
            [name]="config.prop || config.name"
            (input)="onInputChange()"
            [attr.data-test-id]="
              'input_' + (config.id || config.prop || config.ariaLabel)
            "
          />
        </ng-template>
      </ng-template>
    </ng-template>

    <div
      class="form-control__icon form-control__icon--right"
      *ngIf="config.unit"
    >
      <p [innerHTML]="config.unit" class="t-data t-alt"></p>
    </div>

    <div
      class="form-control__icon form-control__icon--right"
      *ngIf="config.isIconRight"
    >
      <icon
        [name]="config.icon"
        [config]="{ fill: 'fc-icon-color' }"
        (click)="iconRightClicked.emit()"
      >
      </icon>
    </div>

    <span
      *ngIf="
        config.remainingCount && config.maxLength > 0 && control.value?.length
      "
      class="form-control-textarea__remaining-characters"
      [attr.data-test-id]="
        'input_' +
        (config.id || config.prop || config.ariaLabel) +
        '_max-len-warning'
      "
    >
      {{ config.maxLength - control.value.length }} characters remaining
    </span>
  </div>
</ng-container>
