import { PaginatorConfiguration } from './paginator.component';

/**
 * @deprecated
 * @param  { any[] } items items parameter will be removed in a future release
 */
export function getPaginatorConfig(
  _,
  pageSize: number,
  page: number,
  total: number
): PaginatorConfiguration {
  const totalResults = total;
  const currentPage = page ? page : 1;
  const rowsPerPage = pageSize;
  const showingStart = (page - 1) * pageSize + 1;
  const showingEnd =
    showingStart + pageSize - 1 < totalResults
      ? showingStart + pageSize - 1
      : totalResults;
  const totalPages = Math.ceil(totalResults / pageSize);

  return {
    rowsPerPage,
    currentPage,
    showingStart,
    showingEnd,
    totalResults,
    totalPages,
  };
}
