/** Converts provided string into camelCase
 * @return converted camelCase string
 */
export function toCamel(s: string): string {
  return s.replace(/([-_][a-z0-9])/gi, ($1) =>
    $1.toUpperCase().replace('-', '').replace('_', '')
  );
}

/** Checks the array and object keys to convert into
 * camelCase format
 * @return converted camelCase keys
 */
export function camelCase(o: any): any {
  if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
    const n: Record<string, any> = {};

    Object.keys(o).forEach((k) => {
      n[toCamel(k)] = camelCase(o[k]);
    });

    return n;
  } else if (Array.isArray(o)) {
    return o.map((i) => camelCase(i));
  }

  return o;
}

/** Converts provided string into snakeCase
 * @return converted snakeCase string
 */
export function toSnakeCase(s: string): string {
  return s.replace(/[A-Z0-9]/g, (letter) => `_${letter.toLowerCase()}`);
}

/** Checks the array and object keys to convert into
 * snakeCase format
 * @return converted snakeCase keys
 */
export function camelToSnakeCase(o: any): any {
  if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
    const n: Record<string, any> = {};

    Object.keys(o).forEach((k) => {
      n[toSnakeCase(k)] = o[k];
    });

    return n;
  } else if (Array.isArray(o)) {
    return o.map((i) => {
      const n: Record<string, any> = {};

      Object.keys(i).forEach((k) => {
        n[toSnakeCase(k)] = i[k];
      });

      return n;
    });
  }

  return o;
}
