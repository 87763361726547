import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormControlConfiguration } from '@zipari/shared-ds-util-form';
import { AdditionalDetailsModalConfig } from './additional-detail-modal.model';
import { additionalDetailsFormControlProps } from './additional-detail-modal.constant';

@Component({
  selector: 'pp-additional-detail-modal',
  templateUrl: './additional-detail-modal.component.html',
  styleUrls: ['./additional-detail-modal.component.scss'],
})
export class AdditionalDetailModalComponent implements OnInit {
  @Input() config!: AdditionalDetailsModalConfig;
  @Input() showAdditionalDetailsModal = false;
  @Input() additionalDetailsForm!: UntypedFormGroup;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitClicked: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    this.updateAdditionalDetailsFormControl();
  }

  updateAdditionalDetailsFormControl(): void {
    if (!this.config.enableNotes) {
      this.config.additionalDetailsForm &&
        (this.config.additionalDetailsForm.controls =
          this.filterAdditionalDetails(
            this.config.additionalDetailsForm.controls,
            additionalDetailsFormControlProps.notes
          ));
    }

    if (!this.config.enableEffectiveDate) {
      this.config.additionalDetailsForm &&
        (this.config.additionalDetailsForm.controls =
          this.filterAdditionalDetails(
            this.config.additionalDetailsForm.controls,
            additionalDetailsFormControlProps.effectiveDate
          ));
    }

    if (
      !this.config.enableChangeInOwnership &&
      this.config.additionalDetailsForm
    ) {
      this.config.additionalDetailsForm.controls = this.filterAdditionalDetails(
        this.config.additionalDetailsForm.controls,
        additionalDetailsFormControlProps.changeInOwnership
      );
    }
  }

  filterAdditionalDetails(
    additionalDetails: FormControlConfiguration[],
    prop: string
  ): FormControlConfiguration[] {
    return additionalDetails
      ? additionalDetails.filter(
          (control: FormControlConfiguration) => control.prop !== prop
        )
      : [];
  }
}
