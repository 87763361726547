<article class="layout--detail" [zipBusy]="busy">
  <article class="layout--detail__content" *ngIf="!!dataLoaded">
    <aside class="layout--detail__aside">
      <ng-container *ngFor="let section of config.sidebar?.sections">
        <div [ngSwitch]="section.format">
          <header class="layout--detail__aside__header" *ngIf="section?.label">
            <h6 role="heading" aria-level="1">{{ section?.label }}</h6>
          </header>

          <!--                    ERHO: TODO: do this properly by using design system change-->
          <section
            class="patient-detail__aside__attrs"
            *ngSwitchCase="'attribute-list'"
          >
            <div
              class="patient-detail__aside__model-attributes"
              *ngFor="let attribute of section.attributes"
            >
              <model-attribute
                *ngIf="
                  (!attribute.dataAsLabel && !attribute.showIfTrue) ||
                  (attribute.showIfTrue &&
                    getValue(userInformation, attribute).length > 0)
                "
                [context]="userInformation"
                [config]="attribute"
                direction="column"
              ></model-attribute>
              <div *ngIf="attribute.dataAsLabel">
                <label>{{ getValue(userInformation, attribute) }}</label>
              </div>
              <div *ngFor="let nestedAttr of attribute.additionalAttrs">
                <model-attribute
                  [context]="userInformation"
                  [config]="nestedAttr"
                ></model-attribute>
              </div>
            </div>
          </section>
        </div>
      </ng-container>
    </aside>

    <main class="layout--detail__main">
      <header class="layout--detail__main__breadcrumb">
        <zip-button
          [config]="backButtonConfig"
          (zipButtonClicked)="routeBack()"
        ></zip-button>
      </header>
      <!-- ERHO: TODO ==> Fix this later to be from config -->
      <section
        class="patient-detail_patient-name layout--list__header"
        *ngIf="userInformation.first_name"
      >
        <h3 class="t-bold layout--detail__main--header">
          {{ userInformation.first_name }} {{ userInformation.last_name }}
          <p
            class="portal-user--header__pill"
            [ngClass]="{
              active: userStatus === 'active',
              pending: userStatus === 'pending',
              'in-progress': userStatus === 'in progress',
              suspended: userStatus === 'suspended'
            }"
          >
            {{ userStatus | titlecase }}
          </p>
        </h3>

        <div class="patient-detail__action-btn" *ngIf="config.actionButton">
          <zip-button
            [config]="actionBtn"
            (zipButtonClicked)="navigateSubNav($event)"
          ></zip-button>
        </div>
      </section>
      <!-- ERHO: TODO ==>Will update to be from config. Just needs to get out for now -->
      <section
        class="patient-detail_patient-name layout--list__header"
        *ngIf="!userInformation.first_name"
      >
        <h3 class="layout--detail__main--header">
          {{ userInformation.first_name }} {{ userInformation.last_name }}
        </h3>

        <div class="patient-detail__action-btn" *ngIf="config.actionButton">
          <zip-button
            [config]="config.actionButton"
            (zipButtonClicked)="navigateSubNav($event)"
          ></zip-button>
        </div>
      </section>

      <section class="layout--detail__main__section">
        <tabs
          (tabSelected)="handleTabSelected($event)"
          [selectedIndex]="selectedTab"
          [tabLabels]="config?.tabs?.labels"
          [tabTemplates]="[userDetails, provision]"
        >
        </tabs>
      </section>
    </main>
  </article>
</article>
<ng-template #userDetails>
  <div *ngIf="dataLoaded" class="tab-body">
    <div
      *ngFor="
        let section of (config?.tabs?.tabConfigs)[0]?.sections;
        let i = index
      "
    >
      <header
        *ngIf="section.header"
        class="tab-view__header"
        (click)="
          openEditModalIfNeeded($event, section.showHeaderEditIcon, section, i)
        "
      >
        <div *ngIf="section.headerIcon">
          <i
            class="header-icon-position"
            [ngStyle]="section.headerIcon.styles"
            [ngClass]="section.headerIcon.classString"
            >{{ section.headerIcon.name }}
          </i>
        </div>
        <h6 class="tab-view__header__text t-bold">{{ section.header }}</h6>
        <div
          *ngIf="section.showHeaderEditIcon && isUserInfoEditable(i)"
          (click)="openEditModal(section, i)"
        >
          <icon [name]="'edit'" class="Header__EditIcon"></icon>
        </div>
      </header>
      <div class="tab-view__three-column">
        <div class="three-column__attrs" *ngFor="let item of section.fields">
          <model-attribute
            [context]="userInformation"
            [config]="item"
          ></model-attribute>
        </div>
      </div>
      <br />
      <br />
    </div>
  </div>
</ng-template>

<ng-template #provision>
  <div *ngIf="dataLoaded" class="tab-body">
    <div *ngFor="let section of config?.tabs?.tabConfigs; let i = index">
      <div
        class="three-column__attrs"
        *ngIf="section.sections[0].format === 'provisioning'"
      >
        <div class="viewer-container">
          <viewer-provisioning
            [config]="section.sections[0].viewProvisioner"
          ></viewer-provisioning>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<modal
  #editFlyout
  *ngIf="editModal"
  [config]="(config?.tabs?.tabConfigs)[currentTab]?.editFlyout"
  [condition]="editModal"
  (cancel)="toggleEdit()"
>
  <div body>
    <div
      *ngIf="(config?.tabs?.tabConfigs)[currentTab]?.editFlyout?.subheader"
      class="header-associate"
    >
      <p>
        {{ (config?.tabs?.tabConfigs)[currentTab]?.editFlyout?.subheader }}
      </p>
    </div>
    <div
      class="form-group"
      *ngFor="
        let group of (config?.tabs?.tabConfigs)[currentTab]?.editFlyout
          .formFields;
        let idx = index
      "
    >
      <header class="form-group__header">
        <accordion
          *ngIf="isUserInfoEditable(idx)"
          [isOpen]="idx === openAccordionIdx"
          [header]="group?.header"
          [content]="contentTemplate"
        ></accordion>
      </header>
      <ng-template #contentTemplate>
        <ng-container *ngFor="let control of group.controls">
          <ng-container
            *ngIf="
              control.type !== 'multiple_group_selection';
              else selectMultipleGroup
            "
          >
            <form-control
              [group]="editFields"
              [config]="control"
              [control]="editFields?.get(control?.prop)"
            >
            </form-control>
          </ng-container>
          <ng-template #selectMultipleGroup>
            <add-multiple-groups
              [control]="control"
              [group]="editFields"
              [config]="config?.addMultipleGroupConfig"
            ></add-multiple-groups>
          </ng-template>
        </ng-container>
      </ng-template>
    </div>
  </div>
  <div footer>
    <zip-button
      [disabled]="!hasControlChange && !this.editFields.valid"
      level="high"
      [content]="
        (config?.tabs?.tabConfigs)[currentTab]?.editFlyout.submitButton
      "
      (click)="saveEditModal()"
    ></zip-button>
  </div>
</modal>

<modal
  #confirmModal
  *ngIf="(showActivateUserModal || showSuspendUserModal) && activeModal"
  [config]="activeModal"
  [condition]="showActivateUserModal || showSuspendUserModal"
  (cancel)="cancelConfirmModal()"
>
  <div body>
    <div [innerHtml]="activeModal?.message"></div>
  </div>
  <div footer class="modal-footer">
    <div class="footer-container">
      <zip-button
        class="footer-container__cancel"
        [config]="activeModal?.footer?.cancel"
        (zipButtonClicked)="cancelConfirmModal()"
      ></zip-button>
      <zip-button
        class="footer-container__confirm"
        [config]="activeModal?.footer?.confirm"
        (zipButtonClicked)="submitModal()"
      ></zip-button>
    </div>
  </div>
</modal>

<modal
  #errorModal
  *ngIf="showEditError"
  [config]="activeModal"
  [condition]="showEditError"
>
  <div body>
    <div *ngFor="let error of editErrors">
      <p [innerHTML]="error.user_error"></p>
    </div>
  </div>

  <div footer class="modal-footer">
    <zip-button
      class="footer-container__confirm"
      [config]="activeModal?.footer?.confirm"
      (zipButtonClicked)="closeModal()"
    ></zip-button>
  </div>
</modal>

<suspend-user-modal
  [config]="config?.suspendUserAccountModal"
  [showSuspensionTypeModal]="showSuspensionTypeModal"
  [suspensionTypeModalForm]="suspensionTypeModalForm"
  (submitButtonClicked)="suspensionTypeSelected()"
  (closeModal)="closeSuspensionTypeModal()"
></suspend-user-modal>
