import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentCenterWrapperComponent } from './document-center-wrapper.component';
import { DocumentCenterWrapperRoutingModule } from './document-center-wrapper-routing.module';

@NgModule({
  declarations: [DocumentCenterWrapperComponent],
  imports: [CommonModule, DocumentCenterWrapperRoutingModule],
  exports: [],
})
export class DocumentCenterWrapperModule {}
