<div *ngIf="idCards && idCards.length > 0; else idCarErrorTemplate">
  <div *ngFor="let idCard of idCards">
    <div class="id-card__multi-column">
      <div
        class="id-card__two-column__attrs"
        *ngFor="let item of section.fields.attributes"
      >
        <model-attribute [context]="idCard" [config]="item"></model-attribute>
      </div>
      <div class="button-container" *ngIf="section.printButton">
        <zip-button
          [config]="section.printButton"
          (zipButtonClicked)="print(idCard)"
        ></zip-button>
      </div>
      <div class="button-container" *ngIf="section.downloadButton">
        <zip-button
          [config]="section.downloadButton"
          (zipButtonClicked)="downloadHref(idCard)"
        ></zip-button>
      </div>
      <div class="button-container" *ngIf="section.enlarge">
        <zip-button
          [config]="section.enlarge"
          (zipButtonClicked)="enlarge(idCard)"
        ></zip-button>
      </div>
    </div>
    <div class="id-cards__id__image">
      <div *ngIf="!isError; else errorTemplate" class="id-card__two-column">
        <div class="id-cards__id__front">
          <img
            [src]="
              documentApiEndpoint +
              (idCard.front_doc_id ? idCard.front_doc_id : idCard.full_doc_id) +
              '/'
            "
            (error)="error = !error"
            alt="This ID card is not available at this time. Please check back later."
          />
        </div>
        <div class="id-cards__id__back">
          <img
            *ngIf="idCard.back_doc_id"
            [src]="documentApiEndpoint + idCard.back_doc_id + '/'"
            (error)="error = !error"
            alt="This ID card is not available at this time. Please check back later."
          />
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #idCarErrorTemplate>
  <div class="id-card-view__no_id u-flex">
    <p>
      Id card detail not available for this coverage period or coverage period
      is not selected!!!
    </p>
  </div>
</ng-template>

<ng-template #errorTemplate>
  <div class="id-card-view__no_id u-flex">
    <p>Sorry your ID could not be found</p>
  </div>
</ng-template>

<modal *ngIf="showModal" class="modal-overlay" [condition]="showModal">
  <div class="modal-container">
    <header>
      <div class="button__right">
        <zip-button
          [content]="'Close'"
          [level]="'high'"
          (click)="handleModalClose()"
          (keydown.enter)="handleModalClose()"
        >
        </zip-button>
      </div>
    </header>
    <div body>
      <div class="id-cards__id__front__enlarge">
        <img
          [src]="
            documentApiEndpoint +
            (currentIdCard.front_doc_id
              ? currentIdCard.front_doc_id
              : currentIdCard.full_doc_id) +
            '/'
          "
          (error)="error = !error"
          alt="This ID card is not available at this time. Please check back later."
        />
      </div>
      <div class="id-cards__id__back__enlarge">
        <img
          *ngIf="currentIdCard.back_doc_id"
          [src]="documentApiEndpoint + currentIdCard.back_doc_id + '/'"
          (error)="error = !error"
          alt="This ID card is not available at this time. Please check back later."
        />
      </div>
    </div>
  </div>
</modal>
