export const getIconParentId = (clickEvent: Event): string => {
  let clickedElement: HTMLElement = clickEvent.target as HTMLElement;
  let acceptibleParentNodes = ['icon', 'body']; // body listed to prevent code lock-up

  while (
    !acceptibleParentNodes.includes(clickedElement.nodeName.toLowerCase())
  ) {
    clickedElement = clickedElement.parentElement;
  }

  return clickedElement.id || '';
};
