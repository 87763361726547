<div
  class="form-control__wrapper"
  [ngClass]="{ 'form-control__wrapper--flat': direction === 'filter' }"
  *ngIf="config"
>
  <label class="form-control__label">
    <p class="t-data t-bold">{{ config.label }}</p>
  </label>

  <div class="form-control" (click)="menuOpen = !menuOpen">
    <div class="u-flex u-alignCenter" *ngIf="selectedIcon; else placeholder">
      <icon
        class="si__icon"
        [name]="selectedIcon.value"
        [config]="{
          format: 'svg',
          size: 'medium'
        }"
      >
      </icon>
      <p class="t-data">{{ selectedIcon.label }}</p>
    </div>

    <ng-template #placeholder>
      <div class="u-flex u-alignCenter">
        <div class="si__swatch"></div>
        <p>No Icon</p>
      </div>
    </ng-template>
  </div>

  <div class="form-control__menu" *ngIf="menuOpen">
    <div
      class="form-control__menu__option"
      [ngClass]="{ 'is-active': !selectedIcon }"
      (click)="selectIcon(null)"
    >
      <ng-template [ngTemplateOutlet]="placeholder"></ng-template>
    </div>

    <div
      class="form-control__menu__option"
      [ngClass]="{ 'is-active': icon === selectedIcon }"
      *ngFor="let icon of icons; let i = index"
      (click)="selectIcon(icon)"
    >
      <div class="u-flex u-alignCenter">
        <icon
          class="si__icon"
          [name]="icon.value"
          [config]="{
            format: 'svg',
            size: 'medium'
          }"
        >
        </icon>
        <p class="t-data">{{ icon.label }}</p>
      </div>
    </div>
  </div>
</div>

<div class="modal__mask" *ngIf="menuOpen" (click)="menuOpen = false"></div>
