import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { GLOBAL_URLS } from '../../../app-urls.constant';
import { AuthIdp } from '../../modules/authentication/authentication.constants';
import { AuthService, ConfigService } from '../../services';
import { UserRoles } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProviderAdminGuard implements CanActivate {
  private user;
  constructor(
    private authService: AuthService,
    private router: Router,
    private configService: ConfigService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    this.user = this.authService.loggedInUser;
    const authConfig: any = this.configService.getPageConfig('auth');

    return new Promise((resolve: Function) => {
      this.user.roles.map((target: UserRoles) => {
        if (target.name === 'Provider Admin') {
          if (
            state.url.includes('provider-admin') ||
            (state.url.includes(GLOBAL_URLS.TERMSANDCONDITIONS) &&
              !this.user?.app_user_data?.accepted_terms &&
              authConfig.idp === AuthIdp)
          ) {
            resolve(true);
          } else {
            this.router.navigate(['provider-portal/provider-admin/dashboard']);
            resolve(false);
          }
        } else {
          resolve(false);
        }
      });
      // resolve(false);
    });
  }
}
