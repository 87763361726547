export function hasOwnNestedProperty(obj, propertyPath) {
  if (!propertyPath) {
    return false;
  }

  const properties = propertyPath.split('.');
  for (let i = 0; i < properties.length; i++) {
    const prop = properties[i];

    if (!obj || !obj.hasOwnProperty(prop)) {
      return false;
    } else {
      obj = obj[prop];
    }
  }

  return true;
}
