<fieldset
  tabindex="0"
  class="form-control__wrapper--date-range"
  *ngIf="dateRangeConfig"
  [attr.name]="config.prop"
  [attr.aria-labelledby]="config.ariaLabel || config.label"
>
  <zip-date-picker
    class="DateRange__DatePicker"
    *ngFor="let date of dateProps"
    [config]="dateRangeConfig[date]"
    [control]="group.get(formGroupName).get(date)"
    [dateRangeProp]="config.prop"
  ></zip-date-picker>
</fieldset>
