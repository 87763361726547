<article [zipBusy]="busy" class="centered-base_page">
  <div class="centered-base_page__breadcrumbs">
    <div class="centered-base_page__breadcrumbs_crumb">
      <p [ngClass]="{ 't-bold': state == 'one' }" class="t-data">
        {{ config?.breadcrumbs?.one }}
      </p>
      <i class="centered-base_page__breadcrumbs_crumb__arrow icon icon--medium"
        >chevron_right</i
      >
    </div>
    <div class="centered-base_page__breadcrumbs_crumb">
      <p [ngClass]="{ 't-bold': state == 'two' }" class="t-data">
        {{ config?.breadcrumbs?.two }}
      </p>
      <i class="centered-base_page__breadcrumbs_crumb__arrow icon icon--medium"
        >chevron_right</i
      >
    </div>
    <div class="centered-base_page__breadcrumbs_crumb">
      <p [ngClass]="{ 't-bold': state == 'three' }" class="t-data">
        {{ config?.breadcrumbs?.three }}
      </p>
    </div>
  </div>

  <div class="centered-base_page__content" [ngSwitch]="state">
    <div *ngSwitchCase="'one'">
      <div class="provisioner">
        <div class="column-header" role="heading" aria-level="1">
          {{ config?.createSteps?.one?.header }}
        </div>
        <div class="column-subheader">
          {{ config?.createSteps?.one?.subheader }}
        </div>
        <div class="column-container">
          <card class="card-containercard item skinny-badge">
            <div body>
              <form-group
                [configs]="config?.createSteps?.one?.controls"
                [form]="submitCheckFields"
              ></form-group>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'two'">
      <div class="provisioner">
        <div class="column-header">
          {{ config?.createSteps?.two?.header }}
        </div>
        <div class="column-subheader">
          {{ config?.createSteps?.two?.subheader }}
        </div>
        <div class="column-container">
          <div *ngFor="let section of config?.createSteps?.two?.sections">
            <card class="cardholder card item skinny-badge">
              <div title class="column-header">{{ section.header }}</div>
              <div body>
                <form-group
                  [configs]="section?.details?.controls"
                  [form]="profileCheckFields"
                ></form-group>
                <div
                  *ngFor="let control of section?.additionalDetails?.controls"
                >
                  <div [ngSwitch]="control.type">
                    <input-array
                      [key]="'addPractitioner'"
                      [config]="control"
                      *ngSwitchCase="'input'"
                    ></input-array>
                    <dropdown-array
                      [key]="'addPractitioner'"
                      [config]="control"
                      *ngSwitchCase="'dropdown'"
                    ></dropdown-array>
                    <controls-group-array
                      [key]="'addPractitioner'"
                      [config]="control"
                      *ngSwitchCase="'formGroupArray'"
                      [stateDeaInfoModal]="section.stateDeaInfoModal"
                    ></controls-group-array>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
    <div class="provisioner" *ngSwitchCase="'three'">
      <card class="column-container card item skinny-badge">
        <div body class="provisioner">
          <div title class="column-header">
            <div *ngIf="config?.createSteps?.three?.successHeader">
              {{ config?.createSteps?.three?.successHeader }}
            </div>
            <div *ngIf="config?.createSteps?.three?.confirmationLocation">
              {{
                getObjectProperty(
                  createResponse,
                  config?.createSteps?.three?.confirmationLocation
                )
              }}
            </div>
          </div>
          <br />
          <div subheader class="column-subheader">
            {{ config?.createSteps?.three?.successMsg }}
          </div>
          <div>
            <zip-button
              level="high"
              [content]="config?.createSteps?.three?.submitButton"
              (click)="toggleModalInitiate()"
            ></zip-button>
          </div>
        </div>
      </card>
    </div>
    <div class="provisioner" *ngSwitchCase="'four'">
      <card class="column-container card item skinny-badge">
        <div body class="provisioner">
          <div title class="column-header">
            <div *ngIf="config?.createSteps?.four?.successHeader">
              {{ config?.createSteps?.four?.successHeader }}
            </div>
            <div *ngIf="config?.createSteps?.four?.confirmationLocation">
              {{
                getObjectProperty(
                  associateResponse,
                  config?.createSteps?.four?.confirmationLocation
                )
              }}
            </div>
          </div>
          <br />
          <div subheader class="column-subheader">
            {{ config?.createSteps?.four?.successMsg }}
          </div>
          <div>
            <zip-button
              level="high"
              [content]="config?.createSteps?.four?.returnButton"
              (click)="back($event)"
            ></zip-button>
          </div>
        </div>
      </card>
    </div>
  </div>
  <div class="centered-base_page__always_on_top">
    <zip-button
      *ngIf="state == 'one' || 'two' || 'three'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.buttons?.cancelButton"
      (zipButtonClicked)="back()"
    >
    </zip-button>
    <zip-button
      *ngIf="state == 'one'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.buttons?.submitButton"
      (zipButtonClicked)="submitCheck()"
    >
    </zip-button>
    <zip-button
      *ngIf="state == 'two'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.buttons?.savebutton"
      (zipButtonClicked)="submit()"
    >
    </zip-button>
  </div>
</article>

<modal
  #associateFlyout
  *ngIf="showModal"
  [config]="config?.associateFlyout"
  [condition]="showModal"
  (cancel)="toggleModal()"
>
  <div body>
    <div [ngSwitch]="addState">
      <div *ngIf="config?.associateFlyout?.subheader" class="header-associate">
        <p>
          {{ config?.associateFlyout?.subheader }}
        </p>
      </div>
      <div class="associate-table" *ngSwitchCase="'add'">
        <zip-table
          (selectedRows)="handleSelectedRows($event)"
          [options]="config?.associateFlyout.table"
        >
        </zip-table>
      </div>
      <div class="selected-row__review" *ngSwitchCase="'review'">
        <div *ngFor="let selection of selectedRows; let i = index">
          <!-- <h5 class="t-bold spaced-out">{{ getObjectProperty(selection, config?.associateFlyout?.displayField) }}
                        <span class="selected--row__header--required red">*</span>
                    </h5> -->
          <div class="row">
            <div class="column">
              <h6 class="t-bold">
                {{
                  getObjectProperty(
                    selection.row,
                    config?.associateFlyout?.fields.name
                  )
                }}
              </h6>
            </div>
            <div class="column">
              <div
                *ngFor="
                  let _item of config?.associateFlyout?.fields.rightPanel;
                  let index = index
                "
              >
                <model-attribute
                  [context]="selection.row"
                  [config]="_item"
                ></model-attribute>
              </div>
            </div>
          </div>
          <form-group
            [configs]="config?.associateFlyout?.controls"
            [form]="getAssociatedFields(i)"
          ></form-group>
        </div>
      </div>
    </div>
  </div>
  <div footer>
    <zip-button
      [disabled]="!selectedRows.length"
      *ngIf="addState == 'add'"
      level="high"
      [content]="config?.associateFlyout?.nextButton"
      (click)="reviewAssociated()"
    ></zip-button>
    <zip-button
      [disabled]="isDisabled()"
      *ngIf="addState == 'review'"
      level="high"
      [content]="config?.associateFlyout?.submitButton"
      (click)="submitAddAssociated($event)"
    ></zip-button>
  </div>
</modal>

<modal
  *ngIf="providerExists"
  [config]="config.profileExistsModal"
  [condition]="providerExists"
  (cancel)="toggleBack()"
>
  <div body>{{ config.profileExistsModal.message }}</div>

  <div footer>
    <zip-button
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.profileExistsModal?.returnButton"
      (zipButtonClicked)="back()"
    >
    </zip-button>
  </div>
</modal>

<modal
  *ngIf="error"
  [config]="config.errorModal"
  [condition]="error"
  (cancel)="toggleError()"
>
  <div body id="errorMessage">{{ config.errorModal.message }}</div>
</modal>
