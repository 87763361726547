import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

/*
    Group Validators are form validations that apply to two different fields.
    Leverage Angular's FormGroup-level validators to ensure validity is updated as either of two controls value changes

    Examples:
        - Password and Confirm Password fields must match
        - Effective Date field must be before Termination Date field

    The form group gets an error automatically, while we also manually set errors on a control
    This is done in order to display the errors properly under a given control.

*/

// Helper function to add an error to a control while preserving any existing errors
export function addControlError(control: UntypedFormControl, errorKey: string) {
  const existingErrors = control.errors || {};
  control.setErrors({
    ...existingErrors,
    [errorKey]: true,
  });
}

// Helper function to remove an error from a control while preserving any existing errors
export function removeControlError(
  control: UntypedFormControl,
  errorKey: string
) {
  let errors = control.errors || {};
  if (errors[errorKey]) {
    delete errors[errorKey];
  }
  if (Object.keys(errors).length === 0) {
    errors = null;
  }
  control.setErrors(errors);
}

/*
    Group validator to ensure two controls' values match
    Example Config:
        "groupValidators": [
            {
                "name": "groupMatch",
                "prop1": "password",
                "prop2": "confirm_password"
            }
        ]
*/
export function validateFormGroupMatch(config: {
  prop1: string;
  prop2: string;
}) {
  return (group: any): { [key: string]: any } => {
    const formGroup = group as UntypedFormGroup;

    const matchErrorKey = 'groupMatch';
    const control1 = <UntypedFormControl>formGroup.get(config.prop1);
    const control2 = <UntypedFormControl>formGroup.get(config.prop2);
    if (control1.value !== control2.value) {
      addControlError(control2, matchErrorKey);

      return {
        groupMatch: true,
      };
    }
    removeControlError(control2, matchErrorKey);

    return null;
  };
}
