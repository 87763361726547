<fieldset
  class="toggle"
  [ngClass]="{ 'toggle--full': config.isFullWidth }"
  [attr.prop]="config.prop"
  role="group"
  [attr.aria-label]="config.label"
>
  <div
    class="toggle__option"
    [ngClass]="{ 'is-active': option.value === value }"
    *ngFor="let option of config.options"
    [attr.readonly]="getReadonlyStatus(config, option)"
    (click)="onOptionClick(option)"
    (keydown.enter)="onOptionClick(option)"
    [attr.role]="'switch'"
    [attr.aria-label]="option.label"
    [attr.aria-checked]="option.value === value"
    tabindex="0"
    [attr.aria-describedby]="(config?.id || config?.prop) + '-label'"
    [attr.aria-errormessage]="
      'error_' + (config?.id || config?.prop) + '-input'
    "
    [attr.aria-invalid]="(control.dirty || control.touched) && !control.valid"
  >
    <icon class="toggle__icon" *ngIf="option.icon" [name]="option.icon"></icon>
    <p class="toggle__label" *ngIf="option.label">{{ option.label }}</p>
  </div>
</fieldset>
