import {
  animate,
  animation,
  AnimationTriggerMetadata,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';

const fadeIn = animation([
  style({ opacity: 0 }),
  animate('500ms ease', style({ opacity: 1 })),
]);

const fadeOut = animation([animate('500ms ease', style({ opacity: 0 }))]);

export const fade: AnimationTriggerMetadata = trigger('fade', [
  transition(':enter', [useAnimation(fadeIn)]),
  transition(':leave', [useAnimation(fadeOut)]),
]);

export const fadeToMaskOpacity: AnimationTriggerMetadata = trigger(
  'fadeToMaskOpacity',
  [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('500ms ease', style({ opacity: 0.8 })),
    ]),
    transition(':leave', [animate('500ms ease', style({ opacity: 0 }))]),
  ]
);
