import { Component, Input } from '@angular/core';

/**
 * a wrapper component that displays a sidebar, a main content section, and an optional footer
 *
 * usage:
 *       <sidebar-main-container>
 *           <smc-sidebar>
 *               <ng-container sidebarLabel>
 *                   <h6>Sidebar Label</h6>
 *               </ng-container>
 *
 *               <ng-container sidebarBody>
 *                   <model-attribute
 *                      *ngFor="..."
 *                      [config]="..."
 *                      [context]="...">
 *                   </model-attribute>
 *               </ng-container>
 *           </smc-sidebar>
 *
 *           <smc-main [footerConfig]="[ configuration object ]">
 *                [ Main Content ]
 *           </smc-main>
 *       </sidebar-main-container>
 */
@Component({
  selector: 'sidebar-main-container',
  styleUrls: ['./sidebar-main-container.component.scss'],
  templateUrl: './sidebar-main-container.component.html',
})
export class SidebarMainContainerComponent {
  @Input() hasSizeChanged?: boolean;
  @Input() hasBackgroundChanged?: boolean;
}
