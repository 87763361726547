<form-control
  [config]="toggleConfig"
  [control]="formGroup.get(config.prop)"
  [group]="formGroup"
>
</form-control>

<div @fade *ngIf="showDependents">
  <form-group
    [configs]="config.controls"
    [form]="formGroup"
    [ngClass]="{ 'conditional-grid': config.isGrid }"
  >
  </form-group>
</div>
