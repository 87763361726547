// We might not even want to name these and just let them decide from the component how to handle this
// But it would be good to have a standard sort set of things that can be passed in.
/* eslint-disable no-magic-numbers */
/* eslint-disable no-underscore-dangle */
import { getValue } from './get-value';
import { isObj } from './object';

export enum ZipRuleAction {
  SHOW = 'SHOW',
  DISABLE = 'DISABLE',
  THEME = 'THEME',
  HIDE = 'HIDE',
  COPY = 'COPY',
  ASSIGN = 'ASSIGN',
  RESET = 'RESET',
  READONLY = 'READONLY',
  INVALIDATE_FORM = 'INVALIDATE_FORM',
  ELEMENT_MESSAGE = 'ELEMENT_MESSAGE',
  ROUTE = 'ROUTE',
}

// Maybe we want to do something even cooler than this but this will work for now;
export enum ZipRuleComparator {
  CHANGES_TO = 'CHANGES_TO',
  CURRENTDATECOMPARE = 'CURRENTDATECOMPARE',
  COMPAREYEAR = 'COMPAREYEAR',
  EXISTS = 'EXISTS',
  NOT_EXISTS = 'NOT_EXISTS',
  INCLUDES = 'INCLUDES',
  GREATER_THAN = 'GREATER_THAN',
  VIEWED = 'VIEWED',
  LESS_THAN = 'LESS_THAN',
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
  GREATER_THAN_OR_EQUAL_TO = 'GREATER_THAN_OR_EQUAL_TO',
  LESS_THAN_OR_EQUAL_TO = 'LESS_THAN_OR_EQUAL_TO',
  ARRAY_ITEM_INCLUDES = 'ARRAY_ITEM_INCLUDES',
}

export enum Connectives {
  OR = 'OR',
  AND = 'AND',
}

const allowedZipRuleActions = {
  ELEMENT_MESSAGE: 'ELEMENT_MESSAGE',
  SHOW: 'SHOW',
  DISABLE: 'DISABLE',
  THEME: 'THEME',
  HIDE: 'HIDE',
  COPY: 'COPY',
  ASSIGN: 'ASSIGN',
  RESET: 'RESET',
  READONLY: 'READONLY',
  SET_KEY: 'SET_KEY',
  INVALIDATE_FORM: 'INVALIDATE_FORM',
  ROUTE: 'ROUTE',
};

export class ZipRulesConfig {
  // Map of callbacks to be called if it passes relationship tests
  actionMap: { [key: string]: Function };
}

export class WhenRelation {
  prop?: string;
  comparator?: string;
  value?: any;
  extra?: any;
  // Recursive to allow for nested when relations
  connective?: Connectives;
  when?: WhenRelation[];
}

export class Relation {
  action: ZipRuleAction;
  connective?: Connectives;
  when: WhenRelation[];
  returnArgs: any[];
}

export class Conditions {
  /** Representative of a condition that has conditional operations under it */
  any?: Conditions;
  all?: Conditions;

  /** An individual condition */
  name?: string;
  operation?: string;
  value?: any;
  extra?: any;
}

export class ZipRules {
  context: any;
  prevContext: any;

  constructor(context, prevContext?) {
    this.context = context;
    this.prevContext = prevContext;
  }

  public getRuleResult(conditions: Conditions) {
    // todo: rewrite this function to handle this
    let _connectiveValue = false;
    const anyOrAll = ['all', 'any'].find(
      (anyOrAllKey) => conditions[anyOrAllKey]
    );

    for (let i = 0; i < conditions[anyOrAll].length; i++) {
      const condition = conditions[anyOrAll][i];

      const anyOrAllInner = ['all', 'any'].find(
        (anyOrAllKey) => condition[anyOrAllKey]
      );

      if (anyOrAllInner) {
        return this.getRuleResult(condition);
      } else {
        const resultOfCompare = this.executeIndividualZipRule(
          this.context,
          condition
        );

        if (anyOrAll === 'any') {
          if (resultOfCompare) {
            _connectiveValue = true;
            break;
          }
        } else {
          if (!resultOfCompare) {
            _connectiveValue = false;
            break;
          }
          _connectiveValue = true;
        }
      }
    }

    // for (const condition of) {
    //     if (condition) {
    //         return this.getRuleResult(when.when, when.connective);
    //     } else
    //         }

    return _connectiveValue;
  }

  public executeIndividualZipRule(data, condition: Conditions) {
    // TODO Add in the rest of the comparators
    switch (condition.operation) {
      case ZipRuleComparator.CHANGES_TO:
        let CHANGES_TO_RESULT = null;
        const currValue = getValue(this.context || {}, condition.name);
        const previousValue = getValue(this.prevContext || {}, condition.name);

        // here we know that the two values are different
        if (currValue !== previousValue) {
          // check to see if we need to check what the value came from
          let fromCheck = true;
          if (condition['from']) {
            fromCheck = previousValue === condition['from'];
          }

          // if this also is true then we know that the value has just changed to this value
          if (currValue === condition.value && fromCheck) {
            CHANGES_TO_RESULT = true;
          }
        }

        return CHANGES_TO_RESULT;
      case ZipRuleComparator.CURRENTDATECOMPARE:
        const milliseconds_to_formats = {
          days: 86400000,
          month: 2592000000,
          year: 31556952000000,
        };

        // dteermine the milliseconds since epoch time for eaach date
        const valFromData = getValue(data, condition.name);
        const dateToCompare = new Date(valFromData).getTime();
        const currentDate = new Date().getTime();

        // determine the name of the format to use for the math
        // this switch case is here for ease of use
        let format;
        switch (condition.extra.format) {
          case 'DAY':
          case 'DAYS':
          case 'day':
          case 'd':
          case 'days':
            format = 'days';
            break;
          case 'YEAR':
          case 'YEARS':
          case 'year':
          case 'y':
          case 'years':
            format = 'year';
            break;
          case 'MONTH':
          case 'MONTHS':
          case 'month':
          case 'm':
          case 'months':
            format = 'month';
            break;
          default:
            return null;
        }

        // incorporate any padding involved in the compare
        const currentDateIncludingPadding =
          currentDate +
          (condition.extra.currDatePadding || 0) *
            milliseconds_to_formats[format];
        const dateToCompareIncludingPadding =
          dateToCompare +
          (condition.extra.propDatePadding || 0) *
            milliseconds_to_formats[format];

        // determine the actual amount of the specified firnat between the two dates
        const result =
          (currentDateIncludingPadding - dateToCompareIncludingPadding) /
          milliseconds_to_formats[format];

        // send back whether or not the user wanted to know if it was greater or less than the current date
        // we'll know this by whether or not its a positive number
        if (condition.value === 'GREATER') {
          return result > 0;
        } else if (condition.value === 'LESS') {
          return result < 0;
        } else {
          console.warn('CURRENTDATECOMPARE comparison not set up correctly');
        }
        break;
      // NOTE: The final logic for viewed file is to be decided.
      // Either we can check all files or just the first item. Most of time
      // there is only one file available.
      case ZipRuleComparator.VIEWED:
        if (getValue(data, condition.name)) {
          return getValue(data, condition.name)[0].viewed === condition.value;
        } else {
          return false;
        }
      case ZipRuleComparator.COMPAREYEAR:
        const itemToFind = condition.name.split('.')[0];
        if (!data[itemToFind]) {
          return null;
        }
        const currentValue = getValue(data, condition.name);
        // make sure that the value exists first
        // if it does then do the comparison otherwise just send up true because we may not need it to be
        // required right now
        if (currentValue) {
          const currentYear = parseInt(currentValue.substring(0, 4), 10);
          // check the value given to know what we should do for the compare function...
          // only options right now are less than or greater than
          if (currentYear > 1850 && condition.value[0] === '>') {
            return (
              new Date().getFullYear() - currentYear >
              parseInt(condition.value.substring(1, condition.value.length), 10)
            );
          } else if (currentYear > 1850 && condition.value[0] === '<') {
            return (
              new Date().getFullYear() - currentYear <
              parseInt(condition.value.substring(1, condition.value.length), 10)
            );
          } else {
            return null;
          }
        } else {
          return null;
        }
      case ZipRuleComparator.INCLUDES:
        const val = getValue(data, condition.name);

        return val.includes(condition.value);
      case ZipRuleComparator.NOT_EXISTS:
        const _value2 = getValue(data, condition.name);
        if (Array.isArray(_value2)) {
          const checkArr = _value2.filter(
            (item) => item === undefined || item === null || item === ''
          );

          return checkArr.length === _value2.length;
        } else {
          return _value2 === undefined || _value2 === null || _value2 === '';
        }
      case ZipRuleComparator.EXISTS:
        const _value = getValue(data, condition.name);
        if (Array.isArray(_value)) {
          const checkArr = _value.filter(
            (item) => !(item === undefined || item === null || item === '')
          );

          return checkArr.length > 0;
        } else {
          return !(_value === undefined || _value === null || _value === '');
        }
      case ZipRuleComparator.EQUALS:
        return getValue(data, condition.name) === condition.value;
      case ZipRuleComparator.NOT_EQUALS:
        return getValue(data, condition.name) !== condition.value;
      case ZipRuleComparator.GREATER_THAN:
        return parseInt(getValue(data, condition.name), 10) > condition.value;
      case ZipRuleComparator.GREATER_THAN_OR_EQUAL_TO:
        return parseInt(getValue(data, condition.name), 10) >= condition.value;
      case ZipRuleComparator.LESS_THAN:
        return parseInt(getValue(data, condition.name), 10) < condition.value;
      case ZipRuleComparator.LESS_THAN_OR_EQUAL_TO:
        return parseInt(getValue(data, condition.name), 10) <= condition.value;
      case ZipRuleComparator.ARRAY_ITEM_INCLUDES:
        // In this case condition.name is the name of an array, and condition.value is a string value.
        // We check to see if condition.value is a substring of any value of an item in an array, or
        // if condition.value is a substring of any property's value of an item an an array
        const arr = getValue(data, condition.name);
        if (Array.isArray(arr)) {
          return arr.some((item) => {
            if (isObj(item)) {
              return Object.keys(item).some(
                (prop) => item[prop] && item[prop].includes(condition.value)
              );
            } else {
              return item && item.includes(condition.value);
            }
          });
        }

        return false;
      default:
        console.warn('comparator not supported');

        return null;
    }
  }
}
