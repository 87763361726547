/** Converts provided string into camelCase
 * @return converted camelCase string
 */

export function getObjectProperty(obj, key) {
  if (key === undefined || key === null) return;

  return key.split('.').reduce(function (result, inner_key) {
    if (!result) return;
    if (result[inner_key] !== null) return result[inner_key];
    else {
      result[inner_key] = null;

      return null;
    }
  }, obj);
}
