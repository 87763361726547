import { TableConfig } from '@zipari/design-system';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { ModelAttributeConfig } from '@zipari/shared-ds-util-configuration';
import {
  AllControlsConfiguration,
  FormControlConfiguration,
} from '@zipari/shared-ds-util-form';
import { MessageBannerConfig } from '@zipari/shared-ds-util-messages';
import Address from '../../../../shared/models/shared/Address.model';
import { Billing } from '../../../../shared/models/shared/Billing.model';
import {
  FacilityLocation,
  Location,
} from '../../../../shared/models/shared/Locations.model';
import { Name } from '../../../../shared/models/shared/Name.model';
import Plan from '../../../../shared/models/shared/Plan.model';
import Specialty from '../../../../shared/models/shared/Specialty.model';
import { StateDeaPayload } from '../practitioners/add-new/manage-practitioner-add.constant';

export interface SiteLocationAttestationConfig {
  siteLocationsList: SiteLocationsListConfig;
  siteLocationsReview: SiteLocationsProfileConfig;
  reviewPractitioners: ReviewPractitionersConfig;
  siteLocationsAlert: SiteLocationsAlertConfig;
  buttons: {
    approveButton: ButtonConfig;
    cancelButton: ButtonConfig;
  };
  siteLocationAttestationConfirm: SiteLocationAttestationConfirmConfig;
}

export interface SiteLocationsListConfig {
  title: string;
  subTitle: string;
  endpoint: string;
  filters: {
    submit: ButtonConfig;
    controls: AllControlsConfiguration[];
  };
  selectAllCardsHeader: string;
  locationCard: {
    reviewLabel: string;
    details: {
      label?: string;
      attributes: AttributeConfig[];
    };
  };
  pageSize: number;
  noDataMessage: string;
  approveButton: ButtonConfig;
}
export interface SiteLocationsListResponse {
  count: number;
  previous: number;
  next: number;
  results: SiteLocation[];
}

export interface SiteLocationsAlertConfig {
  alertTypeMapping: {
    information: string;
    warning: string;
    error: string;
    success: string;
  };
  alertConfig: {
    infoBanner: MessageBannerConfig;
    warningBanner: MessageBannerConfig;
    errorBanner: MessageBannerConfig;
    successBanner: MessageBannerConfig;
  };
  endpoint: string;
}

export interface Language {
  id: string;
  code: string;
  name: string;
}

export interface Restriction {
  code: string;
  description: string;
  id: string;
}
export interface Service {
  code: string;
  description: string;
  id: number;
  name: string;
}

export interface SiteLocationsProfileConfig {
  siteLocationDetails: {
    sections: SectionItems[];
    editFlyout: SiteLocationsProfileEditConfig;
    additionalDetailsModal: AdditionalDetailsModalConfig;
    confirmationLocationProp?: string;
  };
  endpoints: {
    facilityDetails: string;
    facilityLocationDetails: string;
  };
  reviewPractitionersButton: ButtonConfig;
}

export interface AdditionalDetailsModalConfig {
  modal: ModalConfig;
  footer: {
    submitButton: ButtonConfig;
    cancelButton: ButtonConfig;
  };
  additionalDetailsForm: {
    controls: AllControlsConfiguration[];
  };
  enableEffectiveDate: boolean;
  enableNotes: boolean;
  enableChangeInOwnership?: boolean;
}

export interface ModalConfig {
  type: string;
  clickOutside: boolean;
  backdrop: boolean;
  header: {
    title: string;
  };
  description: string;
}

export interface SiteLocationsProfileEditConfig {
  type: string;
  header?: {
    title: string;
  };
  formFields: SiteLocationEditFormFileds[];
  submitButton?: {
    content?: string;
    level: string;
  };
}

export interface SiteLocationEditFormFileds {
  header?: string;
  array_controls?: SiteLocationsEditFormConfiguration[];
  controls?: SiteLocationsEditFormConfiguration[];
}
export interface SiteLocationsEditFormConfiguration
  extends FormControlConfiguration {
  defaultProp?: string;
  dropdownDisplayProp: string;
  apiEndpoint?: string;
  enableOnTrue: string;
  defaultStartProp?: string;
  defaultEndProp?: string;
  defaultValueMatchField: string;
  validatorType: string;
  streetAddressConfig?: SiteLocationsEditFormConfiguration[];
  cityStateZipConfig?: SiteLocationsEditFormConfiguration[];
}
export interface SiteLocationAttestationConfirmConfig {
  header: string;
  description: string;
  userInformationTitle: string;
  selectedLocationsTitle: string;
  returnToDashBoardButton: ButtonConfig;
  printButton: ButtonConfig;
  userInformation: { attributes: ModelAttributeConfig[] };
}

export interface SectionItems {
  fields: {
    attributes: ModelAttributeConfig[];
  };
  header: string;
  headerIcon: { name: string };
  isHeaderEditIconRequired: boolean;
}

export interface SiteLocation {
  id: number;
  name: string;
  number: string;
  type: { id: string; name: string; code: string };
  location: Location;
  specialties: Specialties[];
  phoneNumbers: PhoneNumber[];
  billingInformation: Billing[];
  faxNumbers: string;
  emails: string[];
  npi: string;
  effectiveDate: string;
  terminationDate: string;
  address?: Address;
  taxonomyCodes: string[];
  locationContacts: LocationContact[];
  administrationAttributes?: AdministrationAttributes;
  restrictions: Restriction[];
  isPrimary: boolean;
  providerType?: string;
  websiteUrl: string;
  facilityId: string;
  languages: Language[];
  services: Service[];
  providerLocations: any;
  operatingHours: OperatingHour[];
  taxId: string;
  medicareId: string;
  affiliatedHospitalFacilityIds: string[];
  affiliatedHospitalFacilityLocationIds: string[];
  isHospital: boolean;
  changeRequestDisplayText: string;
  status: string;
  groupNpi: string;
  changeRequestEffectiveDate: string;
  changeRequestNotes: string;
  changeRequestSubmittedBy: string;
  lastAttestationDate: string;
  groupTaxId: string;
  locationContact?: LocationContact;
  memberNetwork?: MemberNetworkConfig;
}

export interface MemberNetworkConfig {
  memberId: string;
  policyId: string;
  networkId: string;
  networkStatus: string;
  isInNetwork: boolean;
}
export interface Service {
  code: string;
  description: string;
  id: number;
  name: string;
}
export interface Specialties {
  code: string;
  id: string;
  name: string;
  specialtyDescription: string;
}

export interface LocationContact {
  addresses?: Address[];
  contactRelationship?: string;
  contactType?: string;
  emailAddress?: string;
  fax?: string;
  gender?: string;
  id?: number;
  isPrimary?: boolean;
  name?: Name;
  phoneNumbers?: PhoneNumber[];
  title?: null;
}

export interface Restriction {
  code: string;
  description: string;
  id: string;
}

export interface AdministrationAttributes {
  id?: number;
  acceptingNewPatients?: boolean;
  isMobilityImpairedAccessible?: boolean;
  hasMobilityImpairedAccessibleExamRoom?: boolean;
  hasMobilityImpairedAccessibleEquipment?: boolean;
  isVisitingClinic?: boolean;
  isPrimaryClinic?: boolean;
  displayInDirectory?: boolean;
  isCulturalCompetencyTrainingComplete?: boolean;
  culturalCompetencyTrainingCompletionDate?: string;
}

export interface OperatingHour {
  closingTime?: string | null;
  day?: string;
  facilityLocation?: number;
  openingTime?: string | null;
}

export interface OperatingHourDays {
  sunday?: string;
  monday?: string;
  tueday?: string;
  wednesday?: string;
  thursday?: string;
  friday?: string;
  saturday?: string;
}

export interface AffiliatedHospitalFacility {
  name?: string;
  affiliatedFacilityIds?: string[];
  id: string;
  medicareId?: string;
  networkCode?: string;
  networkSpecificId?: string;
  npi?: string;
  number?: string;
  preferredPharmacyNetwork?: boolean;
  taxId?: string;
  twentyFourHourPharmacy?: boolean;
}

export class PhoneNumber {
  extention?: string;
  id: number;
  isPrimary?: boolean;
  phoneNumber: string;
  type?: string;
}

export interface ControlOptions {
  label: string;
  value: {
    id?: string;
    code?: string;
    description?: string;
    name?: string;
  };
}

export interface AttributeConfig
  extends Omit<ModelAttributeConfig, 'formatOptions'> {
  formatOptions: {
    mapping: string;
  };
}
export interface ReviewPractitionersConfig {
  table: TableConfig;
  title: string;
  subTitle: string;
  submitButton: ButtonConfig;
  terminatePractitioner: TerminatePractitionerConfig;
  errorModal: {
    header: {
      title: string;
    };
    type: string;
    message: string;
    clickOutside: boolean;
    backdrop: boolean;
  };
  editPractitioner: {
    modal: modalConfig;
    footer: {
      submitButton: ButtonConfig;
    };
    sections: {
      demographics: {
        header: string;
        details: { controls: SiteLocationsEditFormConfiguration[] };
        additionalDetails: { controls: SiteLocationsEditFormConfiguration[] };
      };
      specialties: {
        header: string;
        details: { controls: SiteLocationsEditFormConfiguration[] };
        additionalDetails: { controls: SiteLocationsEditFormConfiguration[] };
      };
    };
    endpoint: string;
    additionalDetailsModal: AdditionalDetailsModalConfig;
  };
  confirmationLocationProp?: string;
}
export interface SiteLocationAttestationParams {
  facility_location_id: number;
  id: number;
  last_attestation_date: string;
}
export class UserInformation {
  firstName: string;
  lastName: string;
  userName: string;
  emailAddress: string;
  dateSubmitted: string;
  timeSubmitted: string;
  taxId?: string[] = [];
}
export interface TerminatePractitionerConfig {
  modalConfig: modalConfig;
  footer: {
    submitButton: ButtonConfig;
    backButton: ButtonConfig;
  };
  terminationDetails: {
    accordionConfig: {
      header: string;
      icon: string;
      card: boolean;
    };
    form: {
      controls: AllControlsConfiguration[];
    };
  };
  selectedPractitioners: {
    accordionConfig: { header: string; icon: string; card: boolean };
    details: {
      attributes: AttributeConfig[];
    };
  };
  endpoint: string;
}

export interface modalConfig {
  header: {
    title: string;
    showClose?: boolean;
  };
  type: string;
  description?: string;
  clickOutside: boolean;
  backdrop: boolean;
}

export interface Practitioner {
  id: number;
  facility_locations: FacilityLocation;
  facility_location: {
    facility: {
      id: string;
      facility_type: { id: string; name: string; code: string };
      name: string;
      network_code: string;
      network_specific_id: string;
      is_pcp: boolean;
      twenty_four_hour_pharmacy: boolean;
      preferred_pharmacy_network: boolean;
      tax_id: string;
      facility_locations: FacilityLocation[];
      profiles: {
        id: number;
        first_name: string;
        last_name: string;
        network_code: string;
        network_specific_id: string;
      }[];
      languages: Language[];
      facility_descriptions: {
        site_hospital_affiliation: string;
        site_special_services: string;
      }[];
      parent: { id: number; name: string };
      children: {
        id: number;
        name: string;
      }[];
      services: {
        id: string;
        name: string;
        description: string;
        code: string;
      }[];
      npi: string;
      medicare_id: string;
      healthcare_system: { id: number; name: string; tax_id: string };
      affiliated_facility_ids: string[];
      is_credentialed: boolean;
      number: string;
      is_hospital: boolean;
    };
    facility_id: string;
    facility_name: string;
  };
  specialties: Specialties[];
  phone_numbers: PhoneNumber[];
  emails: string[];
  fax_numbers: string[];
  address: Address;
  is_primary: boolean;
  accepting_new_patients: boolean;
  provider_id: string;
  facility_location_id: string;
  is_active: boolean;
  last_updated_notes: string;
  effective_date: string;
  termination_date: string;
  location_contacts: LocationContact[];
  change_request_display_text: string;
  status: null;
  additional_specialties: null;
  can_display_in_directory: boolean;
  provider_first_name: string;
  provider_last_name: string;
  provider_full_name: string;
  provider_npi: string;
  provider_status: string;
  provider_change_request_display_text: string;
  provider_primary_speciality: string;
}

export interface CxEngagementResponse {
  id: number;
  state: string;
  priority_score: string;
  engagement: {
    name: string;
    engagement_app: {
      key: string;
      name: string;
      config: object;
    };
    template: {
      key: string;
      name: string;
    };
    outcomes: [
      {
        state: string;
        event: {
          key: string;
          name: string;
          dictionary_attribute_schema: {
            type: string;
            required: string[];
            properties: object;
          };
        };
      }
    ];
    tags: string[];
    data: {
      notification_title?: string;
    };
  };
  goal: {
    key: string;
    name: string;
    description: string;
    category: string;
    department: string;
  };
  consumer_id: string;
}

export interface PractitionerDetails {
  id: number;
  providerId: string;
  number: string;
  gender: string;
  isPcp: boolean;
  taxId: string;
  languages: Language[];
  primarySpecialty: string;
  providerLocations?: ProviderFacilityLocation[];
  providerPlans?: {
    plan?: Plan;
    effectiveDate: string;
    terminationDate: string;
  }[];
  boardCertifications?: {
    id?: number;
    boardCertification: string;
  }[];
  providerServiceRestrictions?: {
    data?: string;
    providerServiceRestriction?: string;
  }[];
  medicalGroups?: {
    id?: number;
    name: string;
    taxId?: string;
  }[];
  networkCode?: string;
  networkSpecificId?: string;
  distance?: string;
  degrees?: {
    id?: number;
    degreeName: string;
    schoolName: string;
    graduationYear?: number;
  }[];
  npi: string;
  taxIdUpdatedDate: string;
  licenseNumber: string;
  deaNumber: string;
  providerType?: string;
  specialties: Specialties[];
  imageUrl: string;
  ssn: string;
  isPreApproved: boolean;
  isParticipatingInPcmh: boolean;
  birthDate: string;
  medicareId: string;
  taxonomyCodes: string[];
  memberNetwork?: MemberNetworkConfig;
  practicingInStates: ProviderNumber[];
  isCredentialed: boolean;
  changeRequestDisplayText: string;
  effectiveDate: string;
  terminationDate: string;
  status: string;
  notes: { id: number; noteContent: string; noteType: string }[];
  additionalProviderId: string;
  providerNumbers: ProviderNumber[];
  changeRequestEffectiveDate: string;
  changeRequestNotes: string;
  changeRequestSubmittedBy: string;
  name: {
    firstName: string;
    lastName: string;
    middleName: string;
    fullName: string;
    prefix: string;
    suffix: string;
  };
}

export interface ProviderNumber {
  id: string;
  number: string;
  stateCode: string;
  stateId: string;
  stateIdType: string;
}

export interface ProviderFacilityLocation {
  address: Address;
  billing_information: Billing[];
  effective_date: string;
  emails: string[];
  facility_id: string;
  fax_numbers: string[];
  id: string;
  is_primary: boolean;
  location_contacts: LocationContact[];
  name: string;
  npi: string;
  number: string;
  operating_hours: OperatingHour[];
  type: {
    code: string;
    id: string;
    name: string;
  };
  location: Location;
  phone_numbers: PhoneNumber[];
  specialties: Specialties[];
  taxonomy_codes: string[];
  termination_date: string;
  website_url: string;
}

export interface EditPractitionerPayload {
  id?: string;
  name?: {
    middle_name: string;
    last_name: string;
    first_name: string;
    prefix: string;
  };
  ssn?: string;
  birth_date?: string;
  gender?: string;
  languages?: Language[];
  npi?: string;
  degrees?: {
    id?: number;
    degree_name: string;
    school_name: string;
    graduation_year?: number;
  }[];
  specialties?: Specialty[];
  taxonomy_codes?: string[];
  dea_number?: string;
  effective_date?: string;
  license_number?: string;
  number?: string;
  change_request_effective_date?: string;
  change_request_notes?: string;
  provider_numbers?: StateDeaPayload[];
}

export interface SubmitPractitionerEventAttributesConfig {
  practitioners: SubmitEventPractitionerDetails[];
  user: UserInformation;
}

export interface SubmitEventPractitionerDetails {
  practitioner: string;
  npi: string;
}

export interface ProviderIdentifierPayload {
  identifier: string;
  type: string;
}

export interface AttestedSiteLocationDetail {
  name?: string[];
  npi?: string[];
  taxId?: string[];
  address1?: string[];
  address2?: string[];
  city?: string[];
  state?: string[];
  zipCode?: string[];
}

export interface AdditionalDetailsFormValue {
  changeInOwnership: boolean;
  legalEntityName?: string;
  legalEntityEffectiveDate?: string;
  legalEntityContactEmail?: string;
  legalEntityContactName?: string;
  legalEntityContactPhone?: string;
}

export interface ConfirmationResponse {
  confirmationType: string;
  displayMessage: string;
  displayNumber: string;
  id: string;
  transactionId: string;
}
