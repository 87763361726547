import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { Column2 } from './column.model';

export enum sortTypes {
  asc = 'asc',
  desc = 'desc',
}

export enum ZipFilterViewOptions {
  rows = 'rows',
  columns = 'columns',
}

export enum ZipSidebarOrHeader {
  header = 'header',
  sidebar = 'sidebar',
}

/** la
 * INTERNAL USE: normal map in structure --> {sort prop: sort direction}
 */
export class ZipTableSorts2 {}

export class ZipTableFilters2 {
  type: string;
  prop: string;

  constructor(options) {
    Object.assign(this, options);
  }
}

export class DetailInitModel {
  startOpen: boolean | number;
}

export class ZipTableDetailConfig2 {
  minColumns: number = 2;
  enabled: boolean = true;
  multi: boolean = true;
  template = null;
  init: DetailInitModel;

  constructor(options) {
    Object.assign(this, options);
  }
}

export class ZipTable2Messages {
  title?: string = '';
  noData: string = 'No Data Found';
  tooltip?: string;

  constructor(options) {
    Object.assign(this, options);
  }
}

export class ZipTablePagingConfig2 {
  /** INTERNAL USE: the total count of rows for the table */
  count?: number = 0;
  enabled: boolean = true;
  pageIndex: number = 0;
  pageSize: number = 10;

  constructor(options) {
    if (options.enabled === false) {
      this.enabled = false;
      this.pageIndex = undefined;
      this.pageSize = undefined;
    } else {
      Object.assign(this, options || {}, { enabled: true });
    }
  }
}

export class ZipTableSelectionConfig2 {
  enabled: boolean = false;
  border: boolean = true;
  arrow: boolean = false;
  init: any;
  multiple: boolean = false;
  persist: boolean = false;
  editHeader: boolean = false;

  constructor(options) {
    let enabled = this.enabled;

    if (options.hasOwnProperty('enabled')) {
      enabled = options.enabled;
    } else if (
      options.border ||
      options.arrow ||
      options.multiple ||
      options.editHeader
    ) {
      enabled = true;
    }

    if (options.editHeader) {
      options.multiple = true;
    }

    Object.assign(this, options, { enabled });
  }
}

export class ZipTableGroupPropConfig2 {
  name: string;
}

export class ZipTableGroupConfig2 {
  prop: string = null;
  enabled: boolean = null;
  rollup?: any = {};
  showInDetail?: boolean = true;
  groupPropConfig?: ZipTableGroupPropConfig2;
  sort?: sortTypes = sortTypes.asc;

  constructor(options) {
    Object.assign(this, options, {
      enabled:
        options.prop && !options.hasOwnProperty('enabled')
          ? true
          : options.enabled,
    });
  }
}

export class ExportConfiguration {
  icon: string = 'download';
  text: string = '';
  enabled: boolean = false;
  exportAsyncLimit: any;
  /** INTERNAL USE: the link to be used for doing an export of a table */
  exportLink?: string;

  constructor(options: any = {}) {
    let enabled = this.enabled;
    if (!options.hasOwnProperty('enabled') && (options.icon || options.text)) {
      enabled = true;
    } else if (options.hasOwnProperty('enabled')) {
      enabled = options.enabled;
    }
    Object.assign(this, options, { enabled });
  }
}

export class ZipTableFooter2 {
  onlyShowIfPaginated: boolean;
  doNotShowCountUnlessPaginated: boolean = false;
  areNumberedButtonsPresent: boolean = true;
  isLabelPresent: boolean = true;

  /** For ease of use this class constructor is utilized in order to handle the defaults
   * @param options - the raw options provided by the user
   * */
  constructor(options) {
    Object.assign(this, options);
  }
}

export class ZipTablePage2 {
  pageSize: number = 10;
  count: number = 0;
  totalPages: number = 0;
  pageNumber: number = 0;
  offset: number = 0;
  next?: string;
  previous?: string;

  constructor(count, page?) {
    Object.assign(this, page || {});
    this.count = count;
    this.totalPages = this.count / this.pageSize;
  }
}

export class ZipTablePagedData2 {
  data: any[] = [];
  page: ZipTablePage2 = new ZipTablePage2(0);

  constructor(data, page?) {
    Object.assign(this, { page: new ZipTablePage2(data.count, page) });
    Object.assign(this, { data: data.results });
  }
}

export class ZipTable2MaxHeight {
  enabled?: boolean = false;
  height: number;

  constructor(options) {
    Object.assign(this, options);

    if (options.height) {
      this.enabled = true;
    }
  }
}

export class ZipTable2Visuals {
  theme: string;
  maxHeight: ZipTable2MaxHeight;
  filtersOwnRow: boolean;

  constructor(options) {
    Object.assign(this, options);
  }
}

export class ZipTable2Sorting {
  init: any;
}

export class ZipTable2 {
  showSpinner: boolean;
  sorting: ZipTable2Sorting;

  visuals?: ZipTable2Visuals;
  messages?: ZipTable2Messages;

  /** INTERNAL USE: non query filters */
  nonQueryFilters = [];

  /** INTERNAL USE: only query filters */
  queryFilters = [];
  filters?: ZipTableFilters2[];

  exportConfig: ExportConfiguration;
  button?: any;
  filterOptions?: any;
  selection: ZipTableSelectionConfig2;
  grouping: ZipTableGroupConfig2;
  detail: ZipTableDetailConfig2;
  paging: ZipTablePagingConfig2;
  footer?: ZipTableFooter2;
  columns: Column2[];
  endpoint: string;
  endpointCache: boolean = true;

  /** INTERNAL USE: keeps track of what props are being sorted on */
  sorts?: ZipTableSorts2;

  /** INTERNAL USE: keeps track of what props are being filtered on and what their values are */
  filterValues: any;

  /** INTERNAL USE: object to help keep track of the weights of the columns */
  columnPriorities?: any = {};

  title: string;

  /** For ease of use this class constructor is utilized in order to handle the defaults for the parts of the table
   * handles the set configurations and then assigns whatever the rest of the configuration for the table is
   * @param options - the raw options provided by the user
   * */
  constructor(options) {
    let filters;
    if (options.filters) {
      filters = options.filters.map((filter) => {
        const formattedFilter = new ZipTableFilters2(filter);
        if (formattedFilter.type === 'query') {
          this.queryFilters.push(filter);
        } else {
          this.nonQueryFilters.push(filter);
        }

        return;
      });
    }

    const exportConfig = new ExportConfiguration(options.exportConfig || {});
    const paging = new ZipTablePagingConfig2(options.paging || {});
    const selection = new ZipTableSelectionConfig2(options.selection || {});
    const grouping = new ZipTableGroupConfig2(options.grouping || {});
    const detail = new ZipTableDetailConfig2(options.detail || {});
    const newFooter = new ZipTableFooter2(options.footer || {});
    const messages = new ZipTable2Messages(options.messages || {});
    const button = new ButtonConfig(options.button);
    const filterOptions = options.filterOptions;
    const newColumns = options.columns.map((col, ind) => {
      const builtCol = new Column2(col, ind);

      // set the group prop config to be passed around
      if (grouping.enabled && col.prop === grouping.prop) {
        grouping.groupPropConfig = col;
      }

      // make sure that you store the column priorities within the full table
      this.columnPriorities[builtCol.prop] = builtCol.priority;

      return builtCol;
    });

    Object.assign(this, options, {
      filters: filters,
      button: button,
      export: options.export,
      pagination: options.pagination,
      exportConfig: exportConfig,
      footer: newFooter,
      detail: detail,
      grouping: grouping,
      selection: selection,
      paging: paging,
      columns: newColumns,
      messages: messages,
      filterOptions: filterOptions,
    });

    if (options.hasOwnProperty('showSpinner')) {
      this.showSpinner = options.showSpinner;
    } else {
      this.showSpinner = false;
    }
  }
}
