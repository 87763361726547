<article [zipBusy]="busy" class="centered-base_page">
  <div class="centered-base_page__content">
    <aside class="panel" *ngIf="config.sidebar?.sections">
      <section *ngIf="loaded">
        <ng-container *ngFor="let section of config.sidebar?.sections">
          <div class="section" [ngSwitch]="section.format">
            <header class="sidebar_header">
              <h6 role="heading" aria-level="1">{{ section.label }}</h6>
            </header>
            <section
              class="patient-detail__aside__attrs"
              *ngSwitchCase="'attribute-list'"
            >
              <div *ngFor="let attribute of section.attributes">
                <model-attribute
                  *ngIf="
                    (!attribute.dataAsLabel && !attribute.showIfTrue) ||
                    (attribute.showIfTrue && checkShowOnTrueValues(attribute))
                  "
                  [context]="context"
                  [config]="attribute"
                  direction="column"
                ></model-attribute>
                <div *ngIf="attribute.dataAsLabel">
                  <label>{{ getValue(context, attribute) }}</label>
                </div>
                <div *ngFor="let nestedAttr of attribute.additionalAttrs">
                  <model-attribute
                    [context]="context"
                    [config]="nestedAttr"
                  ></model-attribute>
                </div>
              </div>
            </section>
          </div>
        </ng-container>
      </section>
    </aside>
    <div class="main-container">
      <div class="provisioner">
        <div
          *ngIf="config?.header && state === 'edit'"
          class="column-header"
          role="heading"
          aria-level="1"
        >
          {{ config?.header }}
        </div>
        <div
          *ngIf="config?.reviewHeader && state === 'review'"
          class="column-header"
          role="heading"
          aria-level="1"
        >
          {{ config?.reviewHeader }}
        </div>
        <div
          *ngIf="config?.confirmationHeader && state === 'confirm'"
          class="column-header"
          role="heading"
          aria-level="1"
        >
          {{ config?.confirmationHeader }}
        </div>
        <div
          *ngIf="config?.subheader && state === 'edit'"
          class="column-subheader"
        >
          {{ config?.subheader }}
        </div>
        <div
          *ngIf="config?.reviewSubheader && state === 'review'"
          class="column-subheader"
        >
          {{ config?.reviewSubheader }}
        </div>
        <div
          *ngIf="config?.confirmationMessage && state === 'confirm'"
          class="column-subheader"
        >
          {{ config?.confirmationMessage }}
        </div>
        <div
          *ngIf="
            (state === 'confirm' || state === 'review') &&
            config?.outOfNetwork?.messageBanner &&
            config?.outOfNetwork?.isOutOfNetworkEnabled &&
            areProvidersOutOfNetwork()
          "
        >
          <message-banner
            [config]="config?.outOfNetwork?.messageBanner"
          ></message-banner>
        </div>
        <div class="badge-container" *ngIf="state == 'confirm'">
          <div class="badge-spacer">
            <badge [config]="config.badge">
              <div class="badge-body">
                <div class="badge-icon badge-text__center">
                  <i class="icon icon--product icon--large">care</i>
                </div>
                <div class="badge-header">
                  <p class="t-bold card-header badge-text__center">
                    {{
                      response?.display_number || response?.number || noSpace
                    }}
                  </p>
                </div>
                <div class="badge-subheader badge-text__center">
                  <p class="card-subheader">
                    {{ config.confirmationBadgeText }}
                  </p>
                </div>
              </div>
            </badge>
          </div>
          <div class="badge-spacer">
            <badge [config]="config.badge">
              <div class="badge-body">
                <div class="badge-icon badge-text__center">
                  <i class="icon icon--product icon--large">clipboard</i>
                </div>
                <div class="badge-header">
                  <p class="t-bold card-header badge-text__center">
                    {{ response?.status || 'Completed' }}
                  </p>
                </div>
                <div class="badge-subheader badge-text__center">
                  <p class="card-subheader">Transaction Status</p>
                </div>
              </div>
            </badge>
          </div>
          <div class="badge-spacer">
            <badge [config]="config.badge">
              <div class="badge-body">
                <div class="badge-icon badge-text__center">
                  <i class="icon icon--product icon--large">profile_product</i>
                </div>
                <div class="badge-header">
                  <p class="t-bold card-header badge-text__center">
                    {{ context?.member?.name?.full_name || noSpace }}
                  </p>
                </div>
                <div class="badge-subheader badge-text__center">
                  <p class="card-subheader">Patient</p>
                </div>
              </div>
            </badge>
          </div>
        </div>
        <div *ngIf="state == 'confirm'" class="button-container">
          <zip-button
            [config]="config.printButton"
            (zipButtonClicked)="print($event)"
          ></zip-button>
        </div>
        <div class="column-container">
          <div *ngFor="let key of _.keys(config?.cards)">
            <card
              id="{{ (config?.cards)[key]?.id }}"
              class="cardholder card item skinny-badge"
              *ngIf="
                (config?.cards)[key].type !== 'dynamicCard' &&
                (config?.cards)[key].type !== 'clinicalReviewCard' &&
                shouldAddRequestingProviderSiteLocationAndPractitioner(key)
              "
            >
              <div title class="card-header card-edit">
                <div class="left">
                  {{ (config?.cards)[key]?.header }}
                </div>

                <div
                  *ngIf="state == 'review'"
                  class="right"
                  (click)="
                    scrollTo(
                      (config?.cards)[key]?.id,
                      (config?.cards)[key]?.cx_event_key
                    )
                  "
                >
                  <i class="icon icon--medium">edit</i>
                </div>
              </div>
              <div subheader>
                <div class="t-data address">
                  {{ (config?.cards)[key]?.subheader }}
                </div>
              </div>
              <div body>
                <div class="title-border"></div>
                <ng-container
                  *ngFor="
                    let controlKey of _.keys((config?.cards)[key]?.controls)
                  "
                >
                  <div
                    [style.width]="
                      controls.get(
                        ((config?.cards)[key]?.controls)[controlKey]?.prop
                      )?.width
                        ? controls.get(
                            ((config?.cards)[key]?.controls)[controlKey]?.prop
                          ).width
                        : '100%'
                    "
                  >
                    <div [hidden]="state != 'edit'">
                      <ng-container
                        *ngIf="
                          controlKey == 'provider-information' &&
                          this.config?.allowLocationAsRequestingProvider
                        "
                      >
                        <site-location-and-practitioner
                          [group]="formGroup"
                          [config]="(config?.cards)[key]?.controls"
                          [providerSelectionModalConfig]="
                            config.providerSelectionModal
                          "
                          [providerMultiLocationModal]="
                            config.providerMultiLocationModal
                          "
                          [outOfNetwork]="config?.outOfNetwork"
                          [providerData]="
                            rowData?.requesting_provider ||
                            rowData?.requesting_facility_location
                          "
                          [showOutOfNetworkStatus]="showOutOfNetworkStatus()"
                        ></site-location-and-practitioner>
                      </ng-container>
                      <ng-container
                        *ngIf="
                          key !==
                          CONTROL_KEYS.site_location_and_practitioner_provider.toLocaleLowerCase()
                        "
                      >
                        <ng-container
                          [ngSwitch]="
                            ((config?.cards)[key]?.controls)[controlKey]?.type
                          "
                        >
                          <flyout-dropdown
                            *ngSwitchCase="'flyout'"
                            [group]="formGroup"
                            [config]="
                              ((config?.cards)[key]?.controls)[controlKey]
                            "
                            [outOfNetwork]="config?.outOfNetwork"
                            [authorizationDetailsContext]="rowData"
                          ></flyout-dropdown>
                          <flyout-diagnosis
                            *ngSwitchCase="'flyout-diagnosis'"
                            [group]="formGroup"
                            [config]="
                              ((config?.cards)[key]?.controls)[controlKey]
                            "
                            [state]="state"
                            (diagnosisDetails)="
                              selectedDiagnosisDetails($event)
                            "
                            [diagnosesData]="diagnosisDetails"
                          ></flyout-diagnosis>
                          <ng-container *ngSwitchCase="'dropdown'">
                            <form-control
                              *ngIf="
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.apiEndpoint
                              "
                              [group]="formGroup"
                              [config]="
                                ((config?.cards)[key]?.controls)[controlKey]
                              "
                              [control]="
                                formGroup.get(
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.prop
                                )
                              "
                            ></form-control>
                            <form-control
                              *ngIf="
                                !((config?.cards)[key]?.controls)[controlKey]
                                  ?.apiEndpoint
                              "
                              [group]="formGroup"
                              [config]="
                                ((config?.cards)[key]?.controls)[controlKey]
                              "
                              [control]="
                                formGroup.get(
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.prop
                                )
                              "
                            ></form-control>
                          </ng-container>
                          <form-control
                            *ngSwitchDefault
                            [group]="formGroup"
                            [config]="
                              ((config?.cards)[key]?.controls)[controlKey]
                            "
                            [control]="
                              formGroup.get(
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.prop
                              )
                            "
                          ></form-control>
                          <div *ngSwitchCase="'referral-documents'">
                            <file-uploader
                              class="EntryFormCard__FileUploader"
                              *ngIf="state !== 'confirm'"
                              [restrictions]="
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.restriction ||
                                defaultFileUploaderRestrictions
                              "
                              [showCloseIcon]="
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.showCloseIcon ?? true
                              "
                              [disabled]="
                                !formGroup.get('document_type')?.value
                              "
                              [errorMessageConfig]="
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.errorMessage
                              "
                              [fileAttachment]="documentContext"
                              [config]="
                                getDefaultUploadDocumentConfig(
                                  ((config?.cards)[key]?.controls)[controlKey]
                                )
                              "
                              [progress]="
                                documentUploadService.uploadProgress$ | async
                              "
                              [uploadErrorMessage]="
                                documentUploadService.uploadError$ | async
                              "
                              (fileUploaded)="fileUploaded($event)"
                              (fileRemoved)="removeFile($event)"
                            >
                            </file-uploader>
                          </div>
                        </ng-container>
                      </ng-container>
                    </div>
                    <div class="review-box" *ngIf="state != 'edit'">
                      <ng-container
                        *ngIf="
                          key !==
                          CONTROL_KEYS.site_location_and_practitioner_provider.toLocaleLowerCase()
                        "
                      >
                        <div
                          [ngSwitch]="
                            ((config?.cards)[key]?.controls)[controlKey]?.type
                          "
                        >
                          <div *ngSwitchCase="'dropdown'">
                            <p class="t-data t-bold">
                              {{
                                controls.get(
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.prop
                                )?.label
                              }}
                            </p>
                            {{
                              getDropdownDisplayValue(
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.prop
                              )
                            }}
                          </div>
                          <div *ngSwitchCase="'toggle'">
                            <p class="t-data t-bold">
                              {{
                                controls.get(
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.prop
                                )?.label
                              }}
                            </p>
                            {{
                              getDropdownDisplayValue(
                                ((config?.cards)[key]?.controls)[controlKey]
                                  ?.prop
                              )
                            }}
                          </div>
                          <div *ngSwitchCase="'flyout-diagnosis'">
                            <p class="t-data t-bold">
                              {{
                                controls.get(
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.prop
                                )?.label
                              }}
                            </p>
                            <div class="diagnosis-table">
                              <table class="generic_procedure-diagosis-table">
                                <tr
                                  class="generic_procedure-diagosis-table_row"
                                  *ngFor="
                                    let item of diagnosisDetails;
                                    let indexOfelement = index
                                  "
                                >
                                  <td>
                                    <b>{{ item.row.code }}</b>
                                  </td>
                                  <td>
                                    <div class="diagnosis-table-item-entry">
                                      <div>
                                        {{
                                          item.row.short_description ||
                                            item.row.description
                                        }}
                                      </div>
                                      <div
                                        class="isPrimaryPill"
                                        *ngIf="item.row.is_primary === true"
                                      >
                                        Primary
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                          <div *ngSwitchCase="'flyout'" class="root">
                            <p class="t-data t-bold">
                              {{
                                controls.get(
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.prop
                                )?.label
                              }}
                            </p>
                            <div class="left-flyout">
                              <label
                                *ngIf="
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.displayFields.name &&
                                  formGroup.get(
                                    ((config?.cards)[key]?.controls)[controlKey]
                                      ?.prop
                                  )?.value
                                "
                                class="form-control__label"
                              >
                                <p class="t-data t-bold">
                                  {{
                                    (formGroup.get(
                                      ((config?.cards)[key]?.controls)[
                                        controlKey
                                      ]?.prop
                                    )?.value)['name']['full_name'] || noSpace
                                  }}
                                </p>
                              </label>

                              <p
                                *ngIf="
                                  showOutOfNetworkStatusPill(key, controlKey)
                                "
                                class="OutOfNetwork__StatusPill Pending"
                              >
                                {{ config?.outOfNetwork?.outOfNetworkLabel }}
                              </p>
                            </div>
                            <!-- load this from config -->
                            <div class="right-flyout">
                              <label
                                *ngIf="
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.displayFields.address
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">Address:</p>
                                <p
                                  *ngIf="
                                    !formGroup.get(
                                      ((config?.cards)[key]?.controls)[
                                        controlKey
                                      ]?.prop
                                    ).value['provider_locations']?.length
                                  "
                                >
                                  {{ noSpace }}
                                </p>
                                <div
                                  class="address"
                                  *ngIf="
                                    formGroup.get(
                                      ((config?.cards)[key]?.controls)[
                                        controlKey
                                      ]?.prop
                                    ).value['provider_locations']?.length
                                  "
                                >
                                  {{
                                    formGroup.get(
                                      ((config?.cards)[key]?.controls)[
                                        controlKey
                                      ]?.prop
                                    ).value['provider_locations'][0]['address'][
                                      'street_name_1'
                                    ]
                                  }}<br />
                                  {{
                                    formGroup.get(
                                      ((config?.cards)[key]?.controls)[
                                        controlKey
                                      ]?.prop
                                    ).value['provider_locations'][0]['address'][
                                      'street_name_2'
                                    ]
                                  }}<br />
                                  {{
                                    formGroup.get(
                                      ((config?.cards)[key]?.controls)[
                                        controlKey
                                      ]?.prop
                                    ).value['provider_locations'][0]['address'][
                                      'city_name'
                                    ]
                                  }},
                                  {{
                                    formGroup.get(
                                      ((config?.cards)[key]?.controls)[
                                        controlKey
                                      ]?.prop
                                    ).value['provider_locations'][0]['address'][
                                      'state'
                                    ]
                                  }}
                                  {{
                                    formGroup.get(
                                      ((config?.cards)[key]?.controls)[
                                        controlKey
                                      ]?.prop
                                    ).value['provider_locations'][0]['address'][
                                      'zip_code'
                                    ]
                                  }}
                                </div>
                              </label>
                              <label
                                *ngIf="
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.displayFields.group_npi
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">Group NPI:</p>
                                <p>
                                  {{
                                    formGroup.get(
                                      ((config?.cards)[key]?.controls)[
                                        controlKey
                                      ]?.prop
                                    ).value['npi'] || noSpace
                                  }}
                                </p>
                              </label>
                              <label
                                *ngIf="
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.displayFields.id_number
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">ID Number:</p>
                                <p>
                                  {{
                                    formGroup.get(
                                      ((config?.cards)[key]?.controls)[
                                        controlKey
                                      ]?.prop
                                    ).value['number'] || noSpace
                                  }}
                                </p>
                              </label>
                              <label
                                *ngIf="
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.displayFields.specialty
                                "
                                class="form-control__label"
                              >
                                <p class="spacing t-data t-bold">
                                  Primary Specialty:
                                </p>
                                <p
                                  *ngIf="
                                    formGroup.get(
                                      ((config?.cards)[key]?.controls)[
                                        controlKey
                                      ]?.prop
                                    ).value['primary_specialty']
                                  "
                                >
                                  {{
                                    formGroup.get(
                                      ((config?.cards)[key]?.controls)[
                                        controlKey
                                      ]?.prop
                                    ).value['primary_specialty']
                                  }}
                                </p>
                                <p
                                  *ngIf="
                                    !formGroup.get(
                                      ((config?.cards)[key]?.controls)[
                                        controlKey
                                      ]?.prop
                                    ).value['primary_specialty']
                                  "
                                >
                                  {{ noSpace }}
                                </p>
                              </label>
                            </div>
                          </div>
                          <div *ngSwitchCase="'referral-documents'">
                            <!-- This is for document upload -->
                            <div
                              class="generic-card-summary_one-line-value"
                              *ngIf="state != 'confirm'"
                            >
                              <!-- <b>{{currentDocumentType}} </b> -->
                            </div>
                            <div
                              *ngFor="
                                let document of documentUploadService.uploadedFiles;
                                let idx = index
                              "
                              class="documents"
                            >
                              <div class="document">
                                <div class="document--icon">
                                  <i class="icon icon--medium">pdf</i>
                                </div>
                                <div class="document--name">
                                  <p class="t-bold document--name__main">
                                    {{ document.file_name }}
                                  </p>
                                  <p class="document--name__file">
                                    {{ document.file_name }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div *ngSwitchDefault>
                            <p class="t-data t-bold">
                              {{
                                controls.get(
                                  ((config?.cards)[key]?.controls)[controlKey]
                                    ?.prop
                                )?.label
                              }}
                            </p>
                            {{
                              getDefaultReviewBox(
                                ((config?.cards)[key]?.controls)[controlKey]
                              ) || noSpace
                            }}
                          </div>
                        </div>
                      </ng-container>
                      <ng-container
                        *ngIf="
                          controlKey == 'provider-information' &&
                          this.config?.allowLocationAsRequestingProvider &&
                          state != 'edit'
                        "
                      >
                        <div class="left-flyout">
                          <label
                            *ngIf="
                              ((config?.cards)[key]?.controls)[
                                'provider-information'
                              ]?.displayFields.name &&
                              formGroup.value.requesting_provider
                            "
                            class="form-control__label"
                          >
                            <p
                              class="t-data t-bold"
                              *ngIf="
                                formGroup.value.requesting_provider?.providerId
                              "
                            >
                              {{
                                formGroup.value.requesting_provider?.name
                                  ?.fullName || noSpace
                              }}
                            </p>

                            <p
                              class="t-data t-bold"
                              *ngIf="
                                formGroup.value.requesting_provider?.facilityId
                              "
                            >
                              {{
                                formGroup.value.requesting_provider?.name ||
                                  noSpace
                              }}
                            </p>
                          </label>
                          <p
                            *ngIf="
                              config.outOfNetwork?.isOutOfNetworkEnabled &&
                              formGroup.value.requesting_provider.memberNetwork
                                ?.isInNetwork === false
                            "
                            class="OutOfNetwork__StatusPill Pending"
                          >
                            {{ config?.outOfNetwork?.outOfNetworkLabel }}
                          </p>
                        </div>

                        <div class="right-flyout">
                          <ng-container *ngFor="let field of displayFieldsData">
                            <label
                              *ngIf="
                                field?.label !== 'Name' &&
                                field.label !== 'Address'
                              "
                              class="form-control__label"
                            >
                              <p
                                class="spacing t-data t-bold"
                                [innerHTML]="field?.label + ':'"
                              ></p>
                              <p class="t-data">{{ field?.value }}</p>
                            </label>

                            <label
                              *ngIf="field?.label === 'Address'"
                              class="form-control__label"
                            >
                              <p
                                class="spacing t-data t-bold"
                                [innerHTML]="field?.label + ':'"
                              ></p>

                              <ng-container
                                *ngIf="field.value?.id; else noAddressTemplate"
                              >
                                <p class="t-data">
                                  {{ field?.value?.streetName1 }}<br />
                                  {{ field?.value?.streetName2 }}<br />
                                  {{ field?.value?.cityName }},
                                  {{ field?.value?.state }}
                                  {{ field?.value?.zipCode }}
                                </p>
                              </ng-container>
                              <ng-template #noAddressTemplate>
                                <p class="t-data">{{ 'n/a' }}</p>
                              </ng-template>
                            </label>
                          </ng-container>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>
            </card>
            <ng-container
              *ngIf="
                (config?.cards)[key]?.type === 'dynamicCard' &&
                cardToLoadValues((config?.cards)[key])
              "
            >
              <div *ngFor="let data of cardArray; let pointIndex = index">
                <service-details
                  [config]="data.card_to_load"
                  [group]="data.group_to_load"
                  [controls]="data.controls_to_load"
                  [state]="state"
                  (updatedState)="getState($event)"
                  (switchTriggered)="switchTriggered($event)"
                  (callCxEvent)="callEvent($event)"
                  [index]="pointIndex"
                  [outOfNetwork]="config?.outOfNetwork"
                  [authorizationDetailsContext]="rowData"
                >
                  <div
                    *ngIf="state == 'edit' && pointIndex !== 0"
                    class="right"
                    (click)="deleteCard(pointIndex)"
                  >
                    <i class="icon icon--medium">close</i>
                  </div>
                  <div btn class="btn-class">
                    <zip-button
                      *ngIf="
                        state == 'edit' &&
                        (config?.cards)[key]['addButton']['isButtonEnabled'] &&
                        checkLimit(data.card_to_load.addButton.limit)
                      "
                      class="centered-base_page__always_on_top__centered-button"
                      [config]="data.card_to_load?.addButton"
                      (zipButtonClicked)="
                        addCard(
                          data.card_to_load.addButton.addCardFor,
                          pointIndex,
                          ''
                        )
                      "
                    >
                    </zip-button>
                    <zip-button
                      *ngIf="state == 'edit'"
                      [disabled]="!checkFormField(pointIndex)"
                      class="centered-base_page__always_on_top__centered-button"
                      [config]="data.card_to_load?.duplicateButton"
                      (zipButtonClicked)="
                        addCard(
                          data.card_to_load.duplicateButton.addDuplicateCard,
                          pointIndex,
                          data.card_to_load.duplicateButton.action
                        )
                      "
                    >
                    </zip-button>
                  </div>
                </service-details>
              </div>
            </ng-container>
            <ng-container
              *ngIf="(config?.cards)[key]?.type === 'clinicalReviewCard'"
            >
              <clinical-review-card
                [config]="(config?.cards)[key]"
                [authorizationDetailsContext]="rowData"
              ></clinical-review-card>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="state != 'confirm'" class="centered-base_page__always_on_top">
    <zip-button
      *ngIf="state == 'edit'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonCancel"
      (zipButtonClicked)="cancel($event)"
    >
    </zip-button>
    <zip-button
      *ngIf="state == 'edit'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonReview"
      (zipButtonClicked)="review($event)"
    >
    </zip-button>
    <zip-button
      *ngIf="state == 'review'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonBack"
      (zipButtonClicked)="back($event)"
    >
    </zip-button>
    <zip-button
      *ngIf="state == 'review'"
      class="centered-base_page__always_on_top__centered-button"
      [config]="config?.footer?.buttons?.buttonSubmit"
      (zipButtonClicked)="submit($event)"
    >
    </zip-button>
  </div>
</article>

<modal
  #errorFlyout
  *ngIf="showAlert"
  [config]="config?.errorModal"
  [condition]="showAlert"
  (cancel)="onClick($event)"
>
  <div body>{{ config?.warnModal.message }}</div>
</modal>

<modal
  #errorFlyout
  *ngIf="error"
  [config]="config?.errorModal"
  [condition]="error"
  (cancel)="toggleError()"
>
  <div body>
    <p
      class="t-bold t-error u-flex u-justifyCenter"
      *ngFor="let error of errorMessage"
    >
      {{ error }}
    </p>
  </div>
</modal>
