import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { distinctUntilChanged } from 'rxjs/operators';
import { accordion } from '../../../shared/animations/accordion';
import { fade } from '../../../shared/animations/fade';
import { getValue } from '../../../shared/utils/get-value';
import { deepCompare } from '../../../shared/utils/object';
import { DiagnosisConfig, TableConfig } from '../models/table.model';
import { TableService } from '../services/table.service';
import { TableFilterComponent } from './filters/table-filter.component';
import { SelectionTypes, SetFilterValues } from './zip-table.constants';
import { CellClickEvent, ColumnClickEvent, ConditionalAction } from '../models';

@Component({
  selector: 'zip-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [TableService],
  animations: [accordion, fade],
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit {
  deepCompareFn: Function;

  showMobileActionMenu: boolean;

  /** Reference to the table container */
  @ViewChild(TableFilterComponent) tableFilterComponent: TableFilterComponent;
  @ViewChild('tableWrapper') tableWrapper;
  @ViewChild('tableBody') tableBody;

  /** If we dont have an endpoint this data will be used to provide data to this component */
  @Input() data: any;
  @Input() isTableInsideCard: boolean;

  /** Raw configuration options... (will get defaults if certain properties were not provided) */
  @Input() config: TableConfig;

  @Input() set promptsInfo(data: any) {
    if (data) {
      this.ztService.showPrompt = data;
      this.ztService.prompt = this.ztService.zipTableOptions?.prompts.find(
        (prompt) => prompt.key === data
      );
    }
  }

  /** To prefetch data in flyout */
  @Input() preSelectedData: DiagnosisConfig[];

  /** @deprecated */
  @Input() options: TableConfig;

  @Input() fullScreen: boolean;

  /** Emitter so that outside components know that pagination has occurred */
  @Output() pagination = new EventEmitter<any>();

  /** Emitter so that outside components know that pagination has occurred */
  @Output() rowClicked = new EventEmitter<any>();

  /** Emitter so that outside components know that pagination has occurred */
  @Output() rowDeselected = new EventEmitter<any>();

  /** Emitter so that outside components know that pagination has occurred */
  @Output() rowSelected = new EventEmitter<any>();

  // Emit current selected rows
  @Output() selectedRows = new EventEmitter<any[]>();

  // Emit the Row the  Radio Column was selected for
  @Output() selectedRadioRow = new EventEmitter<any>();

  /** Emitter for when an individual cell gets clicked */
  @Output() cellClicked: EventEmitter<any> = new EventEmitter<any>();

  /** Emitter for when the button gets clicked */
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output() editClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output() deleteClicked: EventEmitter<any> = new EventEmitter<any>();

  @Output() headerButtonClicked: EventEmitter<any> = new EventEmitter<any>();

  /** Emitter for when the data gets changed */
  @Output() dataChanged: EventEmitter<any> = new EventEmitter<any>();

  /** Emitter for when the query params gets changed */
  @Output() queryChanged: EventEmitter<any> = new EventEmitter<any>();

  /** Emitter for when the filters get changed */
  @Output() filtersChanged: EventEmitter<object[]> = new EventEmitter<
    object[]
  >();

  @Output() customMethod: EventEmitter<any> = new EventEmitter();

  /** Emitter prompt action clicked action */
  @Output() promptClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(public ztService: TableService) {
    this.deepCompareFn = deepCompare;
  }

  @HostListener('window:resize', [])
  onResize() {
    // this is used to make sure the allowed number of columns gets reset
    // any other thoughts on how to do this without do a change detection would be appreciated
    // tslint:disable-next-line: no-unused-expression
    this.ztService.allowedColumns;
  }

  public get showMobileActionMenuIcon(): boolean {
    // We should only show the action menu icon if we have menu options.
    return !!this.ztService.showButton || !!this.ztService.showExport;
  }

  ngOnInit() {
    this.initializeTable();
  }

  ngAfterViewInit(): void {
    this.ztService.tableWrapper = this.tableWrapper;
  }

  ngOnChanges(changes) {
    if (
      'data' in changes &&
      changes.data.currentValue &&
      changes.options &&
      changes.options.currentValue
    ) {
      this.ztService.setupOptions(changes.options.currentValue);
      this.ztService.retrieveData(
        changes.data.firstChange && !this.ztService.tableInit,
        changes.data.currentValue
      );
    } else if ('data' in changes && changes.data.currentValue) {
      this.ztService.retrieveData(
        changes.data.firstChange && !this.ztService.tableInit,
        changes.data.currentValue
      );
      if (!getValue(this.config || this.options, 'selection.persist')) {
        this.ztService.unselectAllRows();
      }
    }

    if ('options' in changes && changes.options.currentValue) {
      this.ztService.setupOptions(changes.options.currentValue);
    }
  }

  public unselectAllRows() {
    this.ztService.unselectAllRows();
  }

  clickEvent(row, ind) {
    this.rowClicked.emit(row);

    const result = this.ztService.handleRowSelection(row, ind);
    result === SelectionTypes.selected
      ? this.rowSelected.emit(row)
      : this.rowDeselected.emit(row);
  }

  initializeTable() {
    this.ztService.tableInit = false;
    this.ztService.setupOptions(this.config || this.options);
    if (!this.ztService.zipTableOptions.delaySearch) {
      this.ztService.retrieveData(!this.ztService.tableInit, this.data);
    }
    this.ztService.rowSelected.subscribe((selected: any) => {
      if (selected) {
        this.clickEvent(selected.row, selected.ind);
      }
    });

    this.ztService.dataChanged.subscribe((data) => {
      this.dataChanged.emit(data);
    });

    this.ztService.filtersChanged.subscribe((data) => {
      this.filtersChanged.emit(data);
    });

    this.ztService.queryChanged
      .pipe(distinctUntilChanged((prev, curr) => deepCompare(prev, curr)))
      .subscribe((query) => {
        this.queryChanged.emit(query);
      });

    this.ztService.selectedRowSubject.subscribe(() => {
      this.selectedRows.emit(this.ztService.selectedRow);
    });

    this.ztService.selectedRadioRowSubject.subscribe((index: any) => {
      if (index) this.selectedRadioRow.emit(index);
    });

    this.ztService.markRowsAsPreSelected(this.preSelectedData);
  }

  /** allows outside entities to refresh filters and data within table */
  public refresh(resetFilters = null) {
    if (resetFilters) {
      this.tableFilterComponent.resetFilters();
    }

    this.ztService.refreshExternalData();
  }

  customEventMethod({ event: event, context: context }) {
    // if a `customMethod` has been set on the component, emit event on that method.
    if (this.customMethod.observers.length) {
      // passing `event`and component `context` so can access component data in customMethod.
      this.customMethod.emit({ event: event, context: context });
    }
  }

  getCSSClasses(row) {
    return {
      ['tooltip']:
        this.ztService.zipTableOptions &&
        this.ztService.zipTableOptions.visuals &&
        this.ztService.zipTableOptions.visuals.tooltip,
      ['table__row__wrapper--hover']:
        this.ztService.zipTableOptions &&
        this.ztService.zipTableOptions.selection &&
        this.ztService.zipTableOptions.selection.enabled,
      ['table__row__wrapper--nested']: this.ztService.groupingEnabled,
      ['is-expanded']:
        this.ztService.detailEnabled &&
        row.expanded &&
        this.ztService.detailColumns.length > 0,
      ['isRead']:
        this.options &&
        this.options.hasCustomRowClass &&
        row[this.options.customRowClassIdentifier],
      ['isUnread']:
        this.options &&
        this.options.hasCustomRowClass &&
        row[this.options.customRowClassIdentifier] === false,
    };
  }

  cellClickedHandler($event: CellClickEvent) {
    const clickEvent: ColumnClickEvent = $event?.context?.col?.clickEvent;

    if (clickEvent?.type === 'filter') {
      this.handleFilterClickEvent($event);
    }

    this.cellClicked.emit($event);
  }

  /** Sets the value of a filter to the value in the cell that was clicked */
  handleFilterClickEvent($event: CellClickEvent) {
    const clickEvent: ColumnClickEvent = $event?.context?.col?.clickEvent;
    const filterId: string = clickEvent?.options?.id;
    const filterVal: string = $event?.context?.val;
    const setFilterEvent: SetFilterValues = {
      values: { [filterId]: filterVal },
      clearAll: clickEvent?.options?.clearAll,
    };

    if (filterId && filterVal) {
      this.ztService.setFilters(setFilterEvent);
    }
  }

  public handleCellClicked(cellClickEvent: CellClickEvent): void {
    const { context, action } = cellClickEvent;

    this.cellClicked.emit({
      context: context,
      action: action.icon || action,
    });
  }
}
