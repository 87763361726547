<div
  *ngIf="link === openMenuItem"
  class="NavLink__Menu"
  [ngStyle]="{ height: scrollHeight + 'px' }"
  [ngClass]="{
    'NavLink__Menu--Right': link.menuRightAlign
  }"
  #exapandableContent
>
  <ds-link
    class="NavLink__Option"
    [ngClass]="{
      'NavLink__Option--Active':
        data.activeNavItem && item === data.activeNavItem
    }"
    tabindex="0"
    [linkConfig]="getLinkConfig(item)"
    [externalSiteModalConfig]="externalSiteModalConfig"
    (linkClicked)="onLinkClick($event, item)"
    (openExternalLinkModal)="$event.stopPropagation()"
    *ngFor="let item of openMenuItem.items"
  >
    <div class="u-flex u-alignCenter">
      <h2 class="NavLink__Name t-bold" [innerHTML]="item.label"></h2>
      <icon
        *ngIf="item.isExternalRoute && link.showIcon"
        [name]="item.icon ? item.icon : 'open_in_new'"
        [config]="{
          type: 'link',
          fill: 'nav-link-icon-color'
        }"
      ></icon>
    </div>
    <p class="NavLink__Description" [innerHTML]="item.description"></p>
  </ds-link>
</div>
