<button
  type="button"
  class="button"
  [id]="config?.id || null"
  (click)="onClick($event)"
  [disabled]="isDisabled"
  [ngClass]="{
    'button--fit': config?.size === ButtonSizes.fit,
    'button--fullWidth': config?.size === ButtonSizes.fullWidth,
    'button--special': config?.level && config?.level === ButtonLevels.special,
    'button--high': config?.level && config?.level === ButtonLevels.high,
    'button--medium': config?.level && config?.level === ButtonLevels.medium,
    'button--moderate':
      config?.level && config?.level === ButtonLevels.moderate,
    'button--link': config?.level && config?.level === ButtonLevels.link,
    'button--low': config?.level && config?.level === ButtonLevels.low,
    'button--text': config?.level && config?.level === ButtonLevels.text,
    'button--error': config?.level && config?.level === ButtonLevels.error,
    'button--icon': config?.icon && !config?.content,
    'is-active': config?.active,
    'button--leftAligned': config?.leftAlignedText
  }"
  tabindex="0"
  dataClickEvent
  (keydown.enter)="onClick($event)"
  role="button"
  [attr.data-analytics-name]="innerHtmlContent || ariaDescription"
  [attr.aria-label]="ariaDescription"
>
  <div class="button__content">
    <icon
      class="button__icon"
      *ngIf="config?.icon && !config?.isIconRight"
      [name]="config?.icon"
      [config]="{ fill: iconFill, size: config?.iconSize || 'small' }"
    >
    </icon>

    <ripple
      *ngIf="loading; else buttonText"
      class="button__icon"
      size="small"
      [flexCenter]="true"
      [isFullPage]="false"
    ></ripple>
    <ng-template #buttonText>
      <span
        class="button__content--text"
        *ngIf="config?.content"
        [innerHTML]="innerHtmlContent"
      >
      </span>
    </ng-template>

    <icon
      class="button__icon"
      *ngIf="config?.icon && config?.isIconRight"
      [name]="config?.icon"
      [config]="{
        fill: iconFill,
        format: config.iconFormat ? config.iconFormat : 'svg',
        size: config.iconSize ? config.iconSize : 'small'
      }"
    >
    </icon>
  </div>

  <div
    class="button__subnav"
    [ngClass]="{
      'button__subnav--right': config?.menuPosition === SubNavPositions.right,
      'button__subnav--top': config?.menuPosition === SubNavPositions.top
    }"
    *ngIf="config?.subnav && buttonSubnavShows"
  >
    <button
      role="button"
      tabindex="0"
      [attr.aria-label]="subnavItem.label"
      class="button__subnav__row"
      *ngFor="let subnavItem of config?.subnav?.items"
      (click)="onClick(subnavItem.prop, 'subnav')"
      (keydown.enter)="onClick(subnavItem.prop, 'subnav')"
    >
      <icon *ngIf="subnavItem.icon" [name]="subnavItem.icon"> </icon>
      <p [innerHTML]="subnavItem.label"></p>
    </button>
  </div>
</button>
