<form-control
  [direction]="direction"
  [control]="control"
  [customInputTemplate]="customInputTemplate"
  [config]="config"
>
</form-control>

<ng-template #customInputTemplate>
  <input
    (blur)="triggerVal()"
    [formControl]="control"
    class="form-control"
    type="date"
    [id]="config.prop"
    [name]="config.prop || config.name"
    [attr.aria-invalid]="(control.dirty || control.touched) && !control.valid"
    attr.aria-required="false"
    attr.autocomplete="false"
    attr.placeholder="null"
    attr.readonly="null"
    attr.type="date"
  />
</ng-template>
