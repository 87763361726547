import { Pipe, PipeTransform } from '@angular/core';
import { getValue } from '../utilities';

/* See getValue utility for details */

@Pipe({
  name: 'getValue',
})
export class GetValuePipe implements PipeTransform {
  transform(context: any, valueGetter: string): string {
    if (context && valueGetter) {
      return getValue(context, valueGetter);
    }

    return context;
  }
}
