<button-search
  class="TableFiltersModal--mobile"
  *ngIf="filterOptions?.flyout?.trigger"
  [config]="filterOptions?.flyout?.trigger"
  (click)="modalToggle()"
>
</button-search>

<div class="TableFiltersModal--desktop">
  <div *ngIf="!filterOptions?.flyout; else filtersMain"></div>
</div>

<modal
  [condition]="modalOpen"
  *ngIf="filterOptions?.flyout; else filtersMain"
  [config]="filterOptions?.flyout?.modalConfig"
  (cancel)="modalToggle()"
>
  <div body>
    <div *ngIf="!filterOptions?.flyout; else filtersMain"></div>
  </div>
  <div
    footer
    class="TableFiltersFooter"
    *ngIf="filterOptions?.flyout?.footerActions"
  >
    <zip-button
      *ngFor="let button of filterOptions?.flyout?.footerActions"
      [config]="button"
      (zipButtonClicked)="onButtonClick(button); modalToggle()"
    ></zip-button>
  </div>
</modal>

<ng-template #filtersMain>
  <div class="TableFiltersBody">
    <ng-container *ngFor="let filter of tableFilters; let i = index">
      <div
        class="TableFiltersInput"
        *ngIf="filter.prop === 'search'; else nonSearchFilter"
      >
        <form-control
          [group]="formGroup"
          [config]="filter"
          [control]="formGroup.get(filter.controlProp)"
          direction="search"
        >
        </form-control>
      </div>

      <ng-template #nonSearchFilter>
        <div
          class="TableFiltersInput"
          [ngClass]="getCSSClasses(filter)"
          *ngIf="!filter.hide && filter.type !== 'query'"
        >
          <form-control
            [group]="formGroup"
            [config]="filter"
            [control]="formGroup.get(filter.controlProp)"
            direction="filter"
          >
          </form-control>
        </div>
      </ng-template>
    </ng-container>
    <div class="TableFiltersInput" *ngIf="filterOptions?.buttons">
      <div
        class="table__filter--button"
        *ngFor="let button of filterOptions.buttons"
      >
        <zip-button
          [content]="button?.content"
          [level]="button?.level"
          [disabled]="!formGroup.valid"
          (zipButtonClicked)="onButtonClick(button)"
        ></zip-button>
      </div>
    </div>
  </div>
</ng-template>
