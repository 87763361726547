<article class="layout--detail" [zipBusy]="busy">
  <article class="layout--detail__content" *ngIf="!!claim && !!dataLoaded">
    <aside class="layout--detail__aside">
      <ng-container *ngFor="let section of config.sidebar?.sections">
        <div
          *ngIf="
            !section.conditions ||
            (section.conditions && claim[section.conditions.dataDependency])
          "
        >
          <div [ngSwitch]="section.format">
            <header class="layout--detail__aside__header" *ngIf="section.label">
              <h6 role="heading" aria-level="1">{{ section.label }}</h6>
            </header>

            <section
              class="claim-detail__aside__attrs"
              *ngSwitchCase="'attribute-list'"
            >
              <model-attribute
                *ngFor="let attribute of section.attributes"
                [context]="claim"
                [config]="attribute"
                direction="column"
              >
              </model-attribute>
            </section>

            <div *ngSwitchCase="'link-list'">
              <link-list
                [dataList]="section.links"
                (clicked)="linkClicked($event)"
              ></link-list>
            </div>
          </div>
        </div>
      </ng-container>
    </aside>

    <main class="layout--detail__main">
      <header class="layout--detail__main__breadcrumb">
        <zip-button
          [config]="buttonConfig"
          (zipButtonClicked)="routeBack()"
        ></zip-button>

        <div
          class="patient-detail__action-btn"
          *ngIf="config.actionButton && !errorHandled"
        >
          <zip-button
            [config]="config.actionButton"
            (zipButtonClicked)="navigateClaimForm($event)"
          ></zip-button>
        </div>
      </header>

      <section class="claim-detail__main__badges" *ngIf="config.badges">
        <div
          class="claim-detail__badge"
          *ngFor="let badge of modifiedBadgeConfig"
        >
          <badge class="u-fillRemaining" [config]="badge"></badge>
        </div>
      </section>

      <section class="layout--detail__main__section">
        <tabs
          pageTabs="true"
          [tabLabels]="tabLabels"
          (tabSelected)="tabSelected($event)"
          [selectedIndex]="activeTabObj.priority"
        >
        </tabs>
      </section>

      <div class="container">
        <div class="claim-detail__main-container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </main>
  </article>
</article>
<modal
  *ngIf="config?.existingAppealsModal"
  [config]="config.existingAppealsModal"
  [condition]="showClaimAppealWarningMessage"
  (cancel)="showClaimAppealWarningMessage = !showClaimAppealWarningMessage"
>
  <div body [innerHTML]="claimAppealWarningMessage"></div>
  <div footer class="claim-detail_Modal-Button">
    <zip-button
      [config]="config.existingAppealsModal.cancelButton"
      (zipButtonClicked)="
        showClaimAppealWarningMessage = !showClaimAppealWarningMessage
      "
    >
    </zip-button>
    <zip-button
      [config]="config.existingAppealsModal.confirmButton"
      (zipButtonClicked)="navigateToClaimAppealsForm()"
    >
    </zip-button>
  </div>
</modal>
