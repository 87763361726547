<div class="documentTable">
  <div
    class="documentTable__description"
    *ngIf="config?.description"
    [innerHTML]="config.description"
  ></div>
  <div class="documentTable__table" *ngIf="config?.table">
    <div *ngIf="squidexEnabled && config.filters" class="documentFilter">
      <form-group [form]="filtersForm" [configs]="config.filters"></form-group>
    </div>
    <div *ngIf="!squidexEnabled || (squidexEnabled && data)">
      <zip-table
        [options]="config.table"
        [data]="data"
        (cellClicked)="navigateToDetail($event)"
        (customMethod)="callCxEvent($event)"
      ></zip-table>
    </div>
    <div *ngIf="paginator?.totalPages > 1 && data?.length > 0">
      <paginator (pageSelected)="setCurrentPage($event)" [config]="paginator">
      </paginator>
    </div>
  </div>
  <spinner
    *ngIf="squidexEnabled && loading"
    [asOverlay]="true"
    size="medium"
  ></spinner>
</div>
