import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { objectToArray } from '../../../../shared/utils/object';
import { DefaultMatrixConfiguration } from './checkbox-matrix.constants';

@Component({
  selector: 'checkbox-matrix',
  templateUrl: './checkbox-matrix.component.html',
  styleUrls: [
    './checkbox-matrix.component.scss',
    '../../../../notes/form-control/form-control.component.scss',
  ],
})
export class CheckboxMatrixComponent implements OnInit {
  @Input() config: DefaultMatrixConfiguration;
  @Input() formGroup: UntypedFormGroup;
  @Input() context: any;

  public headerFormGroup: UntypedFormGroup;
  public checkboxFormGroup: UntypedFormGroup;
  public radioFormGroup: UntypedFormGroup;
  public gridData;
  public objectToArray = objectToArray;
  public getKeys = Object.keys;
  public dataError: boolean;

  createHeaderCheckboxes = (headers: any[]) =>
    headers.reduce((acc, column) => {
      //* If all checkboxes in column are checked, header should be checked as well.
      const value = this.gridData[this.config.rows].reduce(
        (acc, row) =>
          row.user_selected_option_ids.includes(
            column[this.config.columnIdProp]
          )
            ? [...acc, true]
            : [...acc, false],
        []
      );
      const valueBool = value.every((r) => r);
      Object.assign(acc, {
        [column[this.config.columnIdProp]]: new UntypedFormControl({
          value: valueBool,
          disabled: this.config.hasOwnProperty('readOnly'),
        }),
      });

      return acc;
    }, {});

  createRowCheckboxes = (row, columns) =>
    [row].reduce((acc, curr) => {
      columns.map((column) =>
        curr.available_option_ids.includes(column[this.config.columnIdProp])
          ? Object.assign(acc, {
              [column[this.config.columnIdProp]]: new UntypedFormControl(
                row.user_selected_option_ids.includes(
                  column[this.config.columnIdProp]
                )
              ),
            })
          : null
      );

      return acc;
    }, {});

  toggleColumn = (id, value) =>
    objectToArray(this.checkboxFormGroup.controls).map((group) =>
      group.controls[id] ? group.controls[id].setValue(value) : null
    );

  //* Hide heading checkbox if no rows contain control for columnId
  hasCheckboxes = (columnId) =>
    objectToArray(this.checkboxFormGroup.controls).reduce(
      (acc, group, index) => {
        if (
          objectToArray(this.checkboxFormGroup.controls).length - 1 ===
          index
        ) {
          return acc.length > 0;
        }

        return Object.keys(group.controls).includes(columnId.toString())
          ? [...acc, null]
          : acc;
      },
      []
    );

  private getRowValuesForColumn = (columnId, formGroup) =>
    objectToArray(formGroup.controls).reduce(
      (acc, group) =>
        group.controls[columnId]
          ? [...acc, group.controls[columnId].value]
          : acc,
      []
    );

  //* Get values of all elements in column to set header value
  matrixItemChange = (columnId) => {
    this.headerFormGroup.controls[columnId].setValue(
      this.getRowValuesForColumn(columnId, this.checkboxFormGroup).every(
        (c) => c
      )
    );
  };

  createForm = (matrix, componentFormGroup) =>
    this.gridData[matrix.rows].map((row) =>
      componentFormGroup.addControl(
        row[this.config.rowIdProp],
        new UntypedFormGroup(
          this.createRowCheckboxes(row, this.gridData[matrix.columns])
        )
      )
    );

  initCheckboxForm() {
    const { config } = this;
    this.checkboxFormGroup = new UntypedFormGroup({});
    this.createForm(config, this.checkboxFormGroup);
    this.headerFormGroup = new UntypedFormGroup(
      this.createHeaderCheckboxes(this.gridData[config.columns])
    );
    if (this.config.hasOwnProperty('radioConfig')) {
      const { radioConfig } = config;
      this.radioFormGroup = new UntypedFormGroup({
        [radioConfig.prop]: new UntypedFormControl(
          this.context[radioConfig.prop]
        ),
      });
      this.formGroup.addControl(radioConfig.prop, this.radioFormGroup);
    }
  }

  setAllValues(value) {
    objectToArray(this.formGroup.controls[this.config.prop]['controls']).map(
      (control) => {
        objectToArray(control['controls']).map((gridControl) => {
          gridControl.setValue(value);
        });
      }
    );
    objectToArray(this.headerFormGroup.controls).forEach((headerControl) => {
      headerControl.setValue(value);
    });
  }

  ngOnInit() {
    this.gridData = this.context[this.config.prop];
    try {
      this.initCheckboxForm();
      if (!this.config.hasOwnProperty('readOnly')) {
        this.formGroup.addControl(this.config.prop, this.checkboxFormGroup);
        if (this.formGroup.controls[this.config.radioConfig.prop]) {
          this.formGroup.controls[
            this.config.radioConfig.prop
          ].valueChanges.subscribe((resp) => {
            this.setAllValues(resp[this.config.radioConfig.prop]);
          });
        }
      }
    } catch (e) {
      if (
        !this.gridData ||
        !this.gridData[this.config.rows] ||
        !this.gridData[this.config.columns]
      ) {
        this.dataError = true;
      }
    }
  }
  constructor() {}
}
