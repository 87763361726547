export const PROVIDER_LIST_CONSTANTS = {
  defaultErrorMessage: 'Something went wrong',
  defaultPageSize: 10,
  firstName: 'firstName',
};

export const InitialValueProviderAdminSearchParams = {
  taxId: '',
  emailAddress: '',
};
