<ng-template #showing>
  <p class="Paginator__Label" tabindex="0" [ngPlural]="config.totalResults">
    Showing {{ config.showingStart }}-{{
      config.showingEnd > config.totalResults
        ? config.totalResults
        : config.showingEnd
    }}
    of
    {{ config.totalResults }}
    <ng-template ngPluralCase="=1">{{
      config.labels?.singular || 'Result'
    }}</ng-template>
    <ng-template ngPluralCase="other">{{
      config.labels?.plural || 'Results'
    }}</ng-template>
  </p>
</ng-template>

<div class="Paginator__Wrapper">
  <div class="Paginator__Showing Paginator__Showing--Desktop">
    <ng-container [ngTemplateOutlet]="showing"></ng-container>
  </div>

  <div class="Paginator__Showing Paginator__Showing--Mobile">
    <ng-container [ngTemplateOutlet]="showing"></ng-container>
  </div>

  <section class="Paginator" *ngIf="config.totalPages > 1">
    <button
      class="Paginator__Button Paginator__Button--First"
      type="button"
      (click)="setCurrentPage(1)"
      [attr.aria-label]="'Go to first page'"
      [disabled]="config.currentPage === 1"
      tabindex="0"
      dataClickEvent
      ariadescription="Pagination: Go to first page"
    >
      <icon name="first_page"></icon>
    </button>

    <button
      class="Paginator__Button"
      type="button"
      (click)="setCurrentPage(config.currentPage - 1)"
      [attr.aria-label]="'Go to previous page'"
      [disabled]="config.currentPage === 1"
      tabindex="0"
      dataClickEvent
      ariadescription="Pagination: Go to previous page"
    >
      <icon name="chevron_left"></icon>
    </button>

    <button
      class="Paginator__Button Paginator__Button--Current"
      type="button"
      tabindex="0"
      aria-label="Button does nothing"
    >
      <p class="Paginator__Pages">
        {{ config.currentPage }} / {{ config.totalPages }}
      </p>
    </button>

    <button
      class="Paginator__Button"
      type="button"
      (click)="setCurrentPage(config.currentPage + 1)"
      [attr.aria-label]="'Go to next page'"
      [disabled]="config.currentPage === config.totalPages"
      tabindex="0"
      dataClickEvent
      ariadescription="Pagination: Go to next page"
    >
      <icon name="chevron_right"></icon>
    </button>

    <button
      class="Paginator__Button Paginator__Button--Last"
      type="button"
      (click)="setCurrentPage(config.totalPages)"
      [attr.aria-label]="'Go to last page'"
      [disabled]="config.currentPage === config.totalPages"
      tabindex="0"
      dataClickEvent
      ariadescription="Pagination: Go to last page"
    >
      <icon name="last_page"></icon>
    </button>
  </section>
</div>
