import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  AccordionModule,
  ButtonModule,
  FormControlModule,
  TabsModule,
  ModelAttributeModule,
} from '@zipari/design-system';

import { DetailTabsModule } from '../../shared/modules/detail-tabs/detail-tabs.module';
import { SettingsComponent } from './settings.component';

@NgModule({
  imports: [
    CommonModule,
    TabsModule,
    AccordionModule,
    DetailTabsModule,
    ButtonModule,
    FormControlModule,
    ModelAttributeModule,
  ],
  declarations: [SettingsComponent],
})
export class SettingsModule {}
