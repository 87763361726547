import { WindowService } from '../services/window.service';

/**
 * Returns a `grid-area` string for a given string or object configuration
 * @param {string | {row: string; col: string}} grid string/object to be formatted
 * @returns {string} formatted `grid-area` string
 */
export function formatGridArea(
  grid: string | { row: string; col: string }
): string {
  const isMobile = new WindowService().isSmallWindow;
  const fullWidth = 'auto / 1 / auto / -1';

  if (grid) {
    if (typeof grid === 'string') {
      return !isMobile ? grid : fullWidth;
    } else {
      const gridAuto = ['auto', 'auto'];
      const row = grid.row ? grid.row.split('/') : gridAuto;
      const column = grid.col ? grid.col.split('/') : gridAuto;

      const rowStart = row[0];
      const rowEnd = row[1] ? row[1] : 'auto';
      const columnStart = column[0];
      const columnEnd = column[1] ? column[1] : 'auto';

      return !isMobile
        ? `${rowStart} / ${columnStart} / ${rowEnd} / ${columnEnd}`
        : fullWidth;
    }
  } else {
    return 'auto / auto / auto / auto';
  }
}
/**
 * Returns a `grid-template-columns` string for n columns
 * @param {number} columns number of columns in the grid
 * @returns {string} formatted `grid-template-columns` string
 */
export function formatGridColumns(columns: number): string {
  const isMobile = new WindowService().isSmallWindow;

  return !isMobile ? `repeat(${columns}, 1fr)` : '1fr';
}
