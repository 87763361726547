import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GridCellDirective } from './grid-cell.directive';
import { NgLetDirective } from './nglet.directive';
import { OutsideClickDirective } from './outside-click.directive';
import { UIBackgroundImageDirective } from './ui-background-img.directive';
import { UIBoxModelDirective } from './ui-box-model.directive';
import { UIBoxShadowDirective } from './ui-box-shadow.directive';
import { UIFlexboxDirective } from './ui-flexbox.directive';
import { UIIconDirective } from './ui-icon.directive';
import { UITextDirective } from './ui-text.directive';

@NgModule({
  declarations: [
    GridCellDirective,
    NgLetDirective,
    OutsideClickDirective,
    UIBackgroundImageDirective,
    UIBoxModelDirective,
    UIBoxShadowDirective,
    UIFlexboxDirective,
    UIIconDirective,
    UITextDirective,
  ],
  imports: [CommonModule],
  exports: [
    GridCellDirective,
    NgLetDirective,
    OutsideClickDirective,
    UIBackgroundImageDirective,
    UIBoxModelDirective,
    UIBoxShadowDirective,
    UIFlexboxDirective,
    UIIconDirective,
    UITextDirective,
  ],
})
export class DirectivesModule {}
