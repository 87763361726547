import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { ColumnConfig, TableFilters } from '@zipari/design-system';
import { UntypedFormGroup } from '@angular/forms';
import { objectToArray } from '../../../../../shared/utilities/object';
import {
  ProviderFlyout,
  FacilityFlyout,
  ProviderSelectionConfig,
  ProviderMultiLocationModalConfig,
  AddressLocationConfig,
  DisplayFieldsContext,
  OutOfNetworkConfig,
} from '../../../../templates/authorization-submission/authorization-submission.model';
import { camelCase } from '../../../../../shared/utilities/case-conversion.utils';
import {
  PractitionerDetails,
  SiteLocation,
} from '../../../../templates/provider-update-tool/site-locations-attestation/site-locations-attestation.model';
import {
  isInNetwork,
  ProviderType,
  Select,
} from '../../../../templates/authorization-submission/authorization-submission.constants';
import { APIResponse, APIService } from '../../../../../shared/services';
import Specialty from '../../../../../shared/models/shared/Specialty.model';

@Component({
  selector: 'provider-flyout',
  templateUrl: './provider-flyout.component.html',
  styleUrls: ['./provider-flyout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProviderFlyoutComponent implements AfterViewInit {
  @Input() config: ProviderSelectionConfig;
  @Input() group: UntypedFormGroup;
  @Input() providerMultiLocationModal: ProviderMultiLocationModalConfig;
  @Input() outOfNetwork: OutOfNetworkConfig;
  @Output() shouldCloseProviderFlyout = new EventEmitter<boolean>();
  @Output() selectedProvider = new EventEmitter<
    SiteLocation | PractitionerDetails | null
  >();
  @Output() selectedAddress = new EventEmitter();
  @Output() isOutOfNetwork = new EventEmitter();

  providerConfig: ProviderFlyout | FacilityFlyout;
  selectedProviderItem: SiteLocation | PractitionerDetails | null;
  showMultiLocationPopup: boolean;
  locationOptions: DisplayFieldsContext[] = [];
  addressLocation: AddressLocationConfig[];
  showOutOfNetworkModal: boolean;
  loaded = false;

  private _shouldOpenProviderFlyout: boolean;
  private _shouldOpenFacilityFlyout: boolean;

  constructor(private api: APIService) {}

  @Input()
  get shouldOpenProviderFlyout(): boolean {
    return this._shouldOpenProviderFlyout;
  }

  @Input()
  get shouldOpenFacilityFlyout(): boolean {
    return this._shouldOpenFacilityFlyout;
  }

  set shouldOpenProviderFlyout(status) {
    this._shouldOpenProviderFlyout = status;
    if (this.config && status) {
      this.updateProviderConfig();
    }
  }

  set shouldOpenFacilityFlyout(status) {
    this._shouldOpenFacilityFlyout = status;
    if (this.config && status) {
      this.updateProviderConfig();
    }
  }

  ngAfterViewInit(): void {
    this.group
      ?.get('multi_location')
      ?.valueChanges.subscribe((addressId: number) => {
        if (this.addressLocation?.length > 0) {
          const selectedAddress = this.addressLocation.find(
            (location: AddressLocationConfig) =>
              location?.address?.id === addressId
          );
          this.selectedAddress.emit(selectedAddress?.address);
        }
      });
  }

  selectAddress(): void {
    this.showMultiLocationPopup = false;
    const shouldShowOutOfNetworkModal =
      this.outOfNetwork?.isOutOfNetworkEnabled &&
      this.selectedProviderItem?.memberNetwork &&
      !this.selectedProviderItem?.memberNetwork?.isInNetwork;
    if (shouldShowOutOfNetworkModal) {
      this.showOutOfNetworkModal = true;
    } else {
      this.selectedProvider.emit(this.selectedProviderItem);
      this.closeProviderFlyout();
    }
  }

  updateProviderConfig(): void {
    this.shouldOpenProviderFlyout &&
      (this.providerConfig = this.config.providerFlyout);
    this.shouldOpenFacilityFlyout &&
      (this.providerConfig = this.config.facilityFlyout);

    if (this.providerConfig?.table) {
      this.providerConfig.table.columns = this.convertObjectToArray(
        this.providerConfig.table?.columns
      );
      this.providerConfig.table.filters = this.convertObjectToArray(
        this.providerConfig.table?.filters
      );

      if (this.providerConfig.table.filters) {
        let hasNoEndpoints = true;
        for (const filter of this.providerConfig.table.filters) {
          if (filter.type === Select && filter.apiEndpoint) {
            hasNoEndpoints = false;
            this.api
              .get(filter.apiEndpoint)
              .subscribe((resp: APIResponse<Specialty>) => {
                this.loaded = true;
                filter.options = this.populateDropdown(
                  resp,
                  filter?.displayProp,
                  filter?.jsonValue
                );
              });
          }
        }
        if (hasNoEndpoints) this.loaded = true;
      }

      const shouldUpdateEndpoint =
        this.providerConfig.table &&
        this.outOfNetwork?.isOutOfNetworkEnabled &&
        this.providerConfig.table.outOfNetworkApiEndpoint;
      if (shouldUpdateEndpoint) {
        this.providerConfig.table = {
          ...this.providerConfig.table,
          endpoint: this.providerConfig.table.outOfNetworkApiEndpoint,
        };
      } else if (this.providerConfig.table) {
        Object.keys(this.providerConfig.table?.columns).forEach(
          (item: string) => {
            if (
              this.providerConfig.table.columns[item]?.prop?.includes(
                isInNetwork
              )
            ) {
              delete this.providerConfig.table.columns[`${item}`];
            }
          }
        );
      }
    }
  }

  populateDropdown(
    resp: APIResponse<Specialty>,
    labelProp: string,
    valueProp: string
  ) {
    const options: DisplayFieldsContext[] = [];
    resp.results.forEach((specialty: Specialty) => {
      if (valueProp) {
        options.push({
          label: specialty[labelProp],
          value: specialty[valueProp],
        });
      } else {
        options.push({
          label: specialty[labelProp],
          value: specialty,
        });
      }
    });

    return options;
  }

  convertObjectToArray(tableContent: TableFilters[] | ColumnConfig[] | object) {
    return tableContent
      ? typeof tableContent === 'object'
        ? objectToArray(tableContent)
        : tableContent
      : [];
  }

  getSelectedProvider(
    provider: SiteLocation | PractitionerDetails | null
  ): void {
    this.selectedProviderItem = camelCase(provider);
    this.selectedProviderItem &&
      (this.selectedProviderItem.providerType = this.shouldOpenProviderFlyout
        ? ProviderType.provider
        : ProviderType.siteLocation);
  }

  closeProviderFlyout(): void {
    this.shouldCloseProviderFlyout.emit();
    this.selectedProviderItem = null;
  }

  addSelectedProvider(): void {
    if (
      this.selectedProviderItem &&
      this.selectedProviderItem.providerLocations &&
      this.selectedProviderItem.providerLocations.length > 0
    ) {
      const facilityLocations =
        this.selectedProviderItem.providerLocations[0].facilityLocation.facility
          .facilityLocations;
      const isMultiLocation = facilityLocations.filter(
        (locations: AddressLocationConfig) => locations?.address
      ).length;

      if (isMultiLocation > 1) {
        this.selectedProvider.emit(this.selectedProviderItem);
        this.openMultiLocationPopup();
        this.locationOptions = [];
        this.addressLocation = facilityLocations.filter(
          (locations: AddressLocationConfig) => locations.address
        );
        this.updateLocationOptions();
      } else {
        this.selectedAddress.emit(facilityLocations[0]?.address);
        const shouldShowOutOfNetworkModal =
          this.outOfNetwork?.isOutOfNetworkEnabled &&
          this.selectedProviderItem?.memberNetwork &&
          !this.selectedProviderItem?.memberNetwork?.isInNetwork;

        if (shouldShowOutOfNetworkModal) {
          this.showOutOfNetworkModal = true;
        } else {
          this.selectedProvider.emit(this.selectedProviderItem);
          this.closeProviderFlyout();
        }
      }
    } else {
      const shouldShowOutOfNetworkModal =
        this.outOfNetwork?.isOutOfNetworkEnabled &&
        this.selectedProviderItem?.memberNetwork &&
        !this.selectedProviderItem?.memberNetwork?.isInNetwork;
      if (shouldShowOutOfNetworkModal) {
        this.showOutOfNetworkModal = true;
      } else {
        this.selectedProvider.emit(this.selectedProviderItem);
        this.closeProviderFlyout();
      }
    }
  }

  updateLocationOptions(): void {
    if (this.selectedProviderItem) {
      this.selectedProviderItem.providerLocations[0].facilityLocation.facility.facilityLocations
        .filter((locations: AddressLocationConfig) => locations.address)
        .map((locations: AddressLocationConfig) => {
          this.locationOptions.push({
            label: `${locations.address?.streetName1} ${locations.address?.streetName2} ${locations.address?.cityName} ${locations.address?.state} ${locations.address?.zipCode}`,
            value: locations.address?.id,
          });
        });
    }
  }

  openMultiLocationPopup(): void {
    this.showMultiLocationPopup = true;
  }

  confirm() {
    this.showOutOfNetworkModal = false;
    this.selectedProvider.emit(this.selectedProviderItem);
    this.isOutOfNetwork.emit();
    this.closeProviderFlyout();
  }

  closeOutOfNetworkModal() {
    this.showOutOfNetworkModal = false;
  }
}
