import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';

import { fade, slideRight } from '../../../../shared/animations';
import { PortalUser } from '../../../../shared/models/shared/PortalUser.model';
import { ConfigService } from '../../../../shared/services';
import {
  PortalUserPageResponse,
  PortalUsersService,
} from '../portal-users.service';

@Component({
  animations: [fade, slideRight],
  templateUrl: './portal-users-list.component.html',
  styleUrls: ['./_portal-users-list.scss'],
})
export class PortalUsersListComponent implements OnInit {
  form: UntypedFormGroup;
  modal: any;
  modalIsVisible = false;
  pageIndex = 1;
  portalUsers: PortalUser[];
  config: any;

  constructor(
    private configService: ConfigService,
    private formControlService: FormControlService,
    private portalUsersService: PortalUsersService
  ) {}

  ngOnInit() {
    this.config = this.configService.getPageConfig('portal-users');
    this.modal = this.config?.modal;
    this.form = this.addNewUserForm();
    this.portalUsersService.getPortalUsersPage(this.pageIndex)?.subscribe(
      (res: PortalUserPageResponse) => (this.portalUsers = res.results),
      (err: any) => console.error(err)
    );
  }

  addNewUserForm(): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    this.config?.modal?.formGroups.forEach((formGroup: UntypedFormGroup) =>
      this.formControlService.addFormGroupToFormGroup(form, formGroup)
    );

    return form;
  }

  fromForm(form: UntypedFormGroup): PortalUser {
    const newUser = new PortalUser();
    const userDetails = form.value.userDetails;
    const notifications = form.value.notifications;

    newUser.first_name = userDetails?.firstName;
    newUser.last_name = userDetails?.lastName;
    newUser.data.title = userDetails?.jobTitle;
    newUser.user_name = userDetails?.userName;
    newUser.phone_number = userDetails?.phoneNumber;
    newUser.email_address = userDetails?.email;
    newUser.data.send_email_verification = notifications?.sendEmailVerification;

    return newUser;
  }

  hideModal = () => (this.modalIsVisible = false);
  showModal = () => (this.modalIsVisible = true);
  onCancelClick = () => this.hideModal();
  onNewUserClick = () => this.showModal();
  onSaveClick = () => {
    if (this.form.invalid) {
      return;
    }
    this.portalUsersService.createPortalUser(this.fromForm(this.form));
    this.hideModal();
  };
}
