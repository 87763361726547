<article *ngIf="config" class="EntryForm">
  <h4 class="EntryForm__Header t-bold" [innerHTML]="config.header"></h4>

  <p
    class="EntryForm__SubHeader t-data"
    *ngIf="config.subHeader"
    [innerHTML]="config.subHeader"
  ></p>

  <entry-form-card
    class="EntryForm__CardContainer"
    *ngIf="appealForm"
    [config]="config"
    [appealForm]="appealForm"
    [claimId]="claimId"
    [facilityNameControl]="facilityNameControl"
    [providerNameControl]="providerNameControl"
    [providerSelectionModalConfig]="config.providerSelectionModal"
    [errorModal]="config?.errorModal"
    [hasReturnedFromReviewPage]="hasReturnedFromReviewPage"
  ></entry-form-card>
</article>
