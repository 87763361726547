import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ButtonModule, FormControlModule } from '@zipari/design-system';
import { PortalUsersService } from '../portal-users.service';

import { PortalUserDetailComponent } from './portal-user-detail.component';

@NgModule({
  declarations: [PortalUserDetailComponent],
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    FormControlModule,
  ],
  providers: [PortalUsersService],
})
export class PortalUserDetailModule {}
