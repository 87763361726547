import { Component, Input } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { StepOptions } from '../../authentication.constants';

@Component({
  selector: 'auth-step',
  templateUrl: './auth-step-layout.component.html',
  styleUrls: ['./auth-step-layout.component.scss'],
})
export class AuthStepLayoutComponent {
  @Input() layoutOptions: StepOptions;

  constructor(public route: ActivatedRoute) {}
}
