export class ExpandingArticleConfig {
  expandText?: string = '...read details';
  shrinkText?: string = '...hide details';
  lines?: number = 3;
  lineHeight?: string;

  constructor(options = {}) {
    Object.assign(this, options);
  }
}
