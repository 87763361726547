<div
  class="SmcMain"
  [ngClass]="{ 'SmcMain__BG--Reverse': hasBackgroundChanged }"
>
  <section class="SmcMain__Content">
    <ng-content select="[mainContent]"></ng-content>
  </section>

  <ng-content select="[footer]"></ng-content>
</div>
