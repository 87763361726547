import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AccordionModule,
  BannerModule,
  ButtonModule,
  CardModule,
  FormControlModule,
  FormGroupModule,
  ModalModule,
  ModelAttributeModule,
  PaginatorModule,
  ResourceGridModule,
  TableModule,
  TabsModule,
} from '@zipari/design-system';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ZipBusyModule } from '../../../shared/modules/zip-busy/zip-busy.module';
import { SiteLocationAttestationModule } from '../provider-update-tool/site-locations-attestation/site-location-attestation.module';
import { NewDashboardComponent } from './new-dashboard.component';
import { CustomCardModule } from './custom-component/custom-card/custom-card.module';
import { DataGridModule } from './custom-component/data-grid/data-grid.module';
import { NotificationModule } from './custom-component/notification/notification.module';

@NgModule({
  declarations: [NewDashboardComponent],
  imports: [
    CommonModule,
    CardModule,
    BannerModule,
    TableModule,
    RouterModule,
    FormControlModule,
    FormGroupModule,
    ButtonModule,
    ModalModule,
    ModelAttributeModule,
    ZipBusyModule,
    AccordionModule,
    ReactiveFormsModule,
    TabsModule,
    PaginatorModule,
    CustomCardModule,
    DataGridModule,
    NotificationModule,
    ResourceGridModule,
    SiteLocationAttestationModule,
  ],
})
export class NewDashboardModule {}
