import { ButtonConfig } from '@zipari/shared-ds-util-button';

export enum ColumnLineTypes {
  image = 'image',
  link = 'link',
  longText = 'long-text',
  text = 'text',
  paragraph = 'paragraph',
  header = 'header',
  button = 'button',
}

export interface GridLayoutRow {
  columns: Lines[];
  marginBottom: string;
}

export interface Lines {
  lines: (
    | ImageColumnLine
    | LinkColumnLine
    | TextColumnLine
    | ButtonColumnLine
  )[];
}

export interface ColumnLine {
  readonly type: ColumnLineTypes;
}

export interface ImageColumnLine extends ColumnLine {
  source: string;
  title: string;
  height?: string;
  width?: string;
}

export interface LinkColumnLine extends TextColumnLine {
  route: string;
}

export interface TextColumnLine extends ColumnLine {
  label: string;
}

export interface ParagraphColumnLine extends ColumnLine {
  header: string;
  text: string;
}

export interface ButtonColumnLine extends ColumnLine {
  config: ButtonConfig;
  route: string;
}
