import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IsEllipsisActiveDirective } from '../../shared/directives/is-ellipsis-active.directive';

import { IconModule } from '../icon/icon.module';
import { StatusModule } from '../status/status.module';

import { BadgeComponent } from './badge.component';

@NgModule({
  declarations: [BadgeComponent, IsEllipsisActiveDirective],
  imports: [CommonModule, IconModule, StatusModule],
  exports: [BadgeComponent],
})
export class BadgeModule {}
