<article class="pp-dashboard">
  <div class="pp-dashboard__section">
    <site-locations-alert></site-locations-alert>
    <section>
      <header>
        <div class="pp-dashboard__title">
          <h4 class="t-bold">Dashboard</h4>
        </div>
      </header>
    </section>
    <section *ngIf="dashboardHasLoaded" class="pp-dashboard__tiles">
      <div
        class="pp-dashboard__tile"
        *ngFor="let tile of config?.tiles"
        (click)="navigate(tile)"
      >
        <icon
          [config]="{ size: 'large', set: 'product' }"
          [name]="tile.icon"
          class="tileIcon"
        ></icon>

        <div class="pp-dashboard__tile__content">
          <div class="pp-dashboard__tile__label">
            <h6 class="t-bold">{{ tile.label }}</h6>
          </div>

          <p class="t-data">{{ tile.description }}</p>
        </div>
      </div>
    </section>
  </div>
</article>
