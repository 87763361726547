<footer
  class="Footer"
  *ngIf="
    (length > 0 || options?.footer?.onlyShowIfPaginated) &&
    options?.paging.enabled
  "
>
  <section
    *ngIf="
      options?.footer?.isLabelPresent ? options.footer?.isLabelPresent : true
    "
    class="Footer__count"
  >
    <p
      *ngIf="length > options?.paging?.pageSize; else noPagination"
      class="t-data t-alt"
      tabindex="0"
    >
      Showing
      {{
        getRangeLabel(
          options?.paging?.pageIndex,
          options?.paging?.pageSize,
          length
        )
      }}
      of {{ length }} {{ options?.paging?.pageName }}
    </p>

    <ng-template #noPagination>
      <p class="t-data t-alt" tabindex="0">
        Showing 1 - {{ length }} of {{ length }} {{ options?.paging?.pageName }}
      </p>
    </ng-template>
  </section>

  <section class="Footer__pagination" *ngIf="length > options.paging.pageSize">
    <div *ngIf="options.footer.showFirstLastPageDoubleArrows">
      <button
        class="Footer__pagination--button"
        type="button"
        [disabled]="!hasPreviousPage()"
        (click)="firstPage()"
        [attr.aria-label]="'Go to first page'"
        tabindex="0"
      >
        <icon name="first_page" [config]="options.footer"></icon>
      </button>
    </div>

    <div *ngIf="options.footer.showSinglePagePaginationButtons">
      <button
        class="Footer__pagination--button"
        [disabled]="!hasPreviousPage()"
        type="button"
        (click)="previousPage()"
        [attr.aria-label]="'Go to previous page'"
        tabindex="0"
      >
        <icon name="chevron_left" [config]="options.footer"></icon>
      </button>
    </div>

    <button
      class="Footer__pagination--button"
      type="button"
      tabindex="0"
      aria-label="currentPage"
    >
      <p
        *ngIf="!options.footer.showCurrentPageNumberOnly"
        class="t-data t-bold"
      >
        {{ currentPage }} / {{ getNumberOfPages() }}
      </p>
      <p *ngIf="options.footer.showCurrentPageNumberOnly" class="t-data t-bold">
        {{ currentPage }}
      </p>
    </button>

    <div *ngIf="options.footer.showSinglePagePaginationButtons">
      <button
        class="Footer__pagination--button"
        type="button"
        [disabled]="!hasNextPage()"
        (click)="nextPage()"
        [attr.aria-label]="'Go to next page'"
        tabindex="0"
      >
        <icon name="chevron_right" [config]="options.footer"></icon>
      </button>
    </div>

    <div *ngIf="options.footer.showFirstLastPageDoubleArrows">
      <button
        class="Footer__pagination--button"
        type="button"
        [disabled]="!hasNextPage()"
        (click)="lastPage()"
        [attr.aria-label]="'Go to last page'"
        tabindex="0"
      >
        <icon name="last_page" [config]="options.footer"></icon>
      </button>
    </div>
  </section>
</footer>
