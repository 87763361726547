<div
  #tooltipEl
  *ngIf="config"
  class="TooltipWrapper"
  [attr.aria-controls]="trackById"
  [attr.aria-expanded]="!!(tooltipService.instances$ | async)[trackById]"
>
  <zip-button
    *ngIf="config.description"
    [config]="{
      level: 'text',
      icon: getIconName((tooltipService.instances$ | async)[trackById])
    }"
    [iconSize]="config.size ? config.size : 'medium'"
    [iconFormat]="config.format ? config.format : 'svg'"
    (click)="toggleTooltip($event)"
    dataClickEvent
    aria-label="Toggle tooltip"
  >
  </zip-button>

  <div
    class="TooltipPointer"
    [ngClass]="{ TooltipDirectionTopPointer: config.directionTop }"
    *ngIf="(tooltipService.instances$ | async)[trackById]"
  ></div>

  <p
    [style.marginLeft.px]="tooltipMarginLeft"
    tabindex="0"
    class="Tooltip t-html"
    [ngClass]="{
      TooltipLeftFlow: config.leftAlign,
      TooltipDirectionTop: config.directionTop
    }"
    [attr.id]="trackById"
    *ngIf="(tooltipService.instances$ | async)[trackById] as show"
    [innerHTML]="config?.description"
    [@popup]="{ value: show, params: { transformOrigin: transformOrigin } }"
  ></p>
</div>
