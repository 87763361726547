<header
  class="table__header"
  [ngClass]="{
    'multiple-select': ztService.zipTableOptions.selection.multiple
  }"
>
  <div
    class="table__row__expand"
    *ngIf="((detailView && multiExpandedAllowed) || grouping) && weHaveData"
  >
    <i class="icon icon--medium icon--pointer" (click)="expandAllRows.emit()">
      {{ allExpanded ? 'unfold_less' : 'unfold_more' }}
    </i>
  </div>

  <ng-container *ngIf="ztService.zipTableOptions.selection.multiple">
    <input
      class="form-control form-control--checkbox table--checkbox header--checkbox"
      type="checkbox"
      [checked]="ztService.allRowsAreSelected"
      (change)="markAllRowsAsSelected($event)"
    />
  </ng-container>

  <ng-container
    *ngIf="
      ztService.zipTableOptions.selection.editHeader &&
        ztService.selectedRow.length > 0;
      else normalHeaderColumns
    "
  >
    <div class="table__cell table__cell--header u-relative">
      <p class="t-data t-bold">
        {{ ztService.selectedRow.length }} rows selected
      </p>

      <div class="button__group">
        <zip-button
          (zipButtonClicked)="editClicked.emit($event)"
          level="low"
          icon="edit"
          content="Edit"
        >
        </zip-button>
        <zip-button
          (zipButtonClicked)="deleteClicked.emit($event)"
          level="low"
          icon="trash"
          content="Delete"
        >
        </zip-button>
      </div>
    </div>
  </ng-container>

  <ng-template #normalHeaderColumns>
    <ng-container *ngFor="let column of columns; let i = index">
      <zip-table-header-cell-2
        [ngClass]="{ tooltip: column.tooltip }"
        [attr.title]="column.tooltip"
        class="table__cell table__cell--header u-relative"
        [sorts]="options?.sorts"
        [column]="column"
        (onSortByField)="fireSortEvent($event)"
      >
      </zip-table-header-cell-2>
    </ng-container>
  </ng-template>
</header>
