import { Injectable } from '@angular/core';
import { AllControlsConfiguration } from '@zipari/shared-ds-util-form';
import { getValue } from '../utils/get-value';
import { cloneObject, isInEnum } from '../utils/object';
import { stringBuilder } from '../utils/string-builder';
import { Conditions, WhenRelation, ZipRules } from '../utils/zip-rules';
import { LoggerService } from './logger.service';

export enum ValidActions {
  HIDE = 'HIDE',
  REQUIRE = 'REQUIRE',
  OVERRIDE_VALIDATORS = 'OVERRIDE_VALIDATORS',
}

export class ZipAction {
  name?: ValidActions | string;
}

export class ConditionalOptions {
  ignoreConditionalCallback?: Function;
}

export class RecursiveConditionalOptions extends ConditionalOptions {
  filterOutItemsInArraysThatAreHidden?: boolean;
}

export const handleArrayPropInConfig = (configs: any, context: any) => {
  if (!Array.isArray(configs)) {
    return configs;
  }

  const newConfig = [];

  configs.forEach((config) => {
    // Allow special logic for doing dynamic configurations based on an "array" type of value
    if (config.arrayProp) {
      const arrayContext = getValue(context, config.arrayProp);

      if (arrayContext && Array.isArray(arrayContext)) {
        arrayContext.forEach((indArrayContext, ind) => {
          const fullArrayContext = {
            ...indArrayContext,
            index: ind,
            displayIndex: ind + 1,
          };

          const clonedConfig = cloneObject(config);

          // Loop through the cloned config and try to replace the strings with references to indexes
          Object.keys(clonedConfig).forEach((clonedConfigKey) => {
            let clonedConfigValue = clonedConfig[clonedConfigKey];

            if (
              typeof clonedConfigValue === 'string' &&
              (clonedConfigValue.indexOf('${displayIndex}') >= 0 ||
                clonedConfigValue.indexOf('${index}') >= 0)
            ) {
              clonedConfigValue = stringBuilder(
                clonedConfigValue,
                fullArrayContext
              );

              clonedConfig[clonedConfigKey] = clonedConfigValue;
            }
          });

          newConfig.push(clonedConfig);
        });
      }
    } else {
      newConfig.push(config);
    }
  });

  return newConfig;
};

@Injectable({
  providedIn: 'root',
})
export class BusinessRulesService {
  constructor(public loggerService: LoggerService) {}

  public retrieveResultFromBusinessRule(conditions, values: any) {
    // todo: Create cohesion with structure of conditional from BE and FE

    // setup a new rule
    const rules = new ZipRules(values);

    // run the conditional
    return rules.getRuleResult(conditions);
  }

  /** Recursive function that allows us to pass in generic inputs for the conditional call and run business rules down an entire object
   * */
  public handleRecursiveConditional(
    config: any,
    context: any,
    options: RecursiveConditionalOptions = {}
  ) {
    // Handle deprecated conditional
    this.handleDeprecatedConfigWarning(config);

    // Make sure that whatever is passed into the function is an object
    if (typeof config === 'object') {
      if (Array.isArray(config)) {
        // Handle situation where there are configs that need to be dynamic
        // EX. dependent configs ... one for each dependent but dependents can be a unique number for different
        // households
        config = handleArrayPropInConfig(config, context);

        config = config
          .filter((arrayConfig) => typeof arrayConfig === 'object')
          .map((arrayConfig) => {
            arrayConfig = this.handleRecursiveConditional(
              arrayConfig,
              context,
              options
            );

            return arrayConfig;
          });

        // Removes those items that are "hidden" from the original config
        // This can be useful for display of items later without having to add conditions in the template for
        // every part of the object structure
        if (options.filterOutItemsInArraysThatAreHidden) {
          config = config.filter((arrayConfig) => !arrayConfig.hidden);
        }
      } else {
        // run base case of handle conditional
        config = this.handleConditional(config, context, options);

        // continue down the object checking for conditionals
        Object.keys(config).forEach((configKey: string) => {
          const newConfig = config[configKey];

          const exclusions = {
            conditions: true,
            conditional: true,
            actions: true,
          };
          if (!exclusions[configKey]) {
            config[configKey] = this.handleRecursiveConditional(
              newConfig,
              context,
              options
            );
          }
        });
      }
    }

    return config;
  }

  /** Generic way to handle conditional logic coming from the business rules.
   * Allows conditionals to be functions added in code or the same inputs as the "retrieveResultFromBusinessRule"
   * function. It also allows special checks for when to ignore the conditional through a Function callback and
   * allows the performance of an action as well (if an action is provided).
   *
   * EX.
   * {
   *     conditional: {
   *         when: ""
   *     }
   * }
   * */
  public handleConditional(
    config: AllControlsConfiguration | any,
    context: any,
    options: ConditionalOptions = {}
  ) {
    let result: boolean;

    if (options && options.ignoreConditionalCallback) {
      const callbackCheck: boolean = options.ignoreConditionalCallback(
        config,
        context
      );

      if (!callbackCheck) {
        return config;
      }
    }

    // handle old format of conditional backwards compatible
    if (config.conditional && !config.conditions) {
      this.handleDeprecatedConfigWarning(config);

      config.conditions = this.handleDeprecatingConditionalStructure(
        config.conditional
      );
    }

    if (config.conditions) {
      result = this.retrieveResultFromBusinessRule(config.conditions, context);

      // handle an action by mapping a particular key onto the original config
      config = this.performActionsOnConfig(config, result);
    }

    return config;
  }

  public performActionsOnConfig(config, result) {
    if (!config.actions) {
      config.actions = [{ name: ValidActions.HIDE }];
    }

    config.actions.forEach((action) => {
      config = this.performActionOnConfig(config, action, result);
    });

    return config;
  }

  /** Handles performing an action on a config object
   * All actions should either add / remove / update keys on a given config
   * These keys that are added / removed / updated should be given meaning outside of the context of this function
   * EX. The default action of HIDE change the "hidden" key on the given config when the conditional is not met. The
   *     FormGroup calls this function handles what it means when the "hidden" key is on a particular config object
   * */
  public performActionOnConfig(
    config: any,
    action: ZipAction,
    result: boolean
  ) {
    const currentAction: ValidActions | string = action.name;

    // Don't allow actions that aren't "registered" by adding the actions to the "ValidActions" enum
    if (!isInEnum(ValidActions, currentAction)) {
      throw new Error(`You have provided an invalid action ${currentAction}`);
    }

    // Many actions can be provided here and should be included in the "ValidActions" enum when relevant
    switch (currentAction) {
      case ValidActions.REQUIRE:
        config.required = result;
        config.notRequired = !result;
        break;
      case ValidActions.OVERRIDE_VALIDATORS:
        config.useOverrideValidators = result;
        break;
      case ValidActions.HIDE:
      default:
        config.hidden = !result;
        break;
    }

    return config;
  }

  /** "conditional" has been replaced with the key "conditions" in favor of a different business rules structure that aligns with  */
  public handleDeprecatedConfigWarning(config) {
    if (config && config.conditional) {
      this.loggerService.warn(
        '"conditional" and its associated structure has been deprecated and replaced with "conditions".' +
          'Please see https://github.com/zipari/business-rules#3-build-the-rules for more information.'
      );
    }
  }

  /** deprecate old conditional structure */
  public handleDeprecatingConditionalStructure(
    conditional: WhenRelation
  ): Conditions {
    const handleWhen = (
      conditionalOperation: WhenRelation,
      path = '',
      previousStructure = null
    ) => {
      const finalConditionalResponse = path ? previousStructure[path] : {};

      if (conditionalOperation.when) {
        const conditionalConnective: string = conditionalOperation.connective;
        const conditionType: string =
          conditionalConnective === 'OR' ? 'any' : 'all';

        const conditionalOperations = [];
        conditionalOperation.when.forEach((when: WhenRelation, ind) => {
          if (when.when) {
            conditionalOperations.push(handleWhen(when));
          } else {
            const operation = when.comparator;
            const name = when.prop;
            const value = when.value;

            conditionalOperations.push({
              operation,
              name,
              value,
            });
          }
        });

        finalConditionalResponse[conditionType] = conditionalOperations;
      }

      return finalConditionalResponse;
    };

    return handleWhen(conditional);
  }
}
