<article class="container message__detail" [zipBusy]="busy">
  <div class="message__detail__content">
    <div class="message__detail__breadcrumb">
      <zip-button
        [config]="config.backButton"
        (zipButtonClicked)="routeBack()"
      ></zip-button>
    </div>

    <div class="message__headers" *ngIf="message">
      <div class="message__header">
        <header class="message__header__subject">
          <h4 class="t-bold">{{ message.subject }}</h4>
          <h5 class="">{{ message.category }}</h5>
          <h6 class="" [innerHtml]="message.preview"></h6>
        </header>
      </div>

      <div class="message__body">
        <div
          class="message__body__thread"
          *ngFor="let reply of message.messages; let i = index"
          (click)="toggleView(reply, $event)"
          (keydown.enter)="toggleView(reply, $event)"
          [ngClass]="{ isOpen: reply.isOpen }"
          tabindex="0"
        >
          <div
            class="message__thread__message"
            [ngClass]="{ isOpen: reply.isOpen }"
          >
            <div class="message__thread__header">
              <div class="message__header__users">
                <p class="t-bold t-data">
                  {{ reply.author }} to {{ reply.recipient }}
                </p>
              </div>

              <div class="message__header__date">
                <p>
                  {{ reply.date | date : 'MM/dd/yyyy' }} at
                  {{ reply.date | date : 'hh:mm a' }}
                </p>
              </div>
              <ng-container *ngIf="reply.attachments?.length > 0">
                <i class="message__header__attachment icon icon--medium">
                  attachment
                </i>
              </ng-container>
            </div>
            <div
              class="message__thread__body"
              [ngClass]="{ isOpen: reply.isOpen }"
            >
              <div class="message__thread__content">
                <p
                  class="message__thread__text"
                  [innerHtml]="reply.content"
                ></p>
              </div>
              <attachment-pill
                *ngIf="reply.attachments?.length > 0 && reply.isOpen"
                [attachments]="reply.attachments"
              >
              </attachment-pill>
            </div>
          </div>
        </div>

        <!-- archived messages should not be allowed to write new replies -->
        <div class="message__body__footer" *ngIf="!message.is_archived">
          <div class="message__footer__btn" *ngIf="!showMessageField">
            <zip-button
              [config]="config?.reply?.replyButton"
              (zipButtonClicked)="openReply()"
            ></zip-button>
          </div>
          <div class="message__footer__area" *ngIf="showMessageField">
            <div class="new-message">
              <form-control
                [group]="formGroup"
                [config]="config?.reply?.replyForm?.content"
                [control]="formGroup.get('content')"
              ></form-control>

              <attachment-pill
                *ngIf="attachmentMetaData?.length > 0"
                [attachments]="attachmentMetaData"
                [showCloseIcon]="'true'"
                (removeAttachment)="onRemoveAttachment($event)"
              >
              </attachment-pill>

              <div
                class="character__count"
                *ngIf="config?.reply?.replyForm?.content?.maxLength"
              >
                {{
                  config?.reply?.replyForm?.content?.maxLength -
                    (formGroup.get('content').value
                      ? formGroup.get('content').value.length
                      : 0)
                }}
                characters remaining
              </div>
            </div>

            <div class="new-message__buttons">
              <div class="send-buttons__group">
                <zip-button
                  class="sendBtn"
                  [config]="config?.reply?.sendMessage"
                  [disabled]="formGroup.status === 'INVALID'"
                  (zipButtonClicked)="sendMessage()"
                >
                </zip-button>
                <zip-button
                  *ngIf="
                    config?.reply?.attachmentButton &&
                    config.reply.replyForm?.attachment_ids
                  "
                  class="attachmentBtn"
                  [config]="config.reply.attachmentButton"
                  (click)="openAttachmentModal()"
                >
                </zip-button>
              </div>

              <zip-button
                class="cancelBtn"
                [config]="config?.reply?.cancelMessage"
                (click)="cancelMessage()"
              ></zip-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section class="modal__mask" @fade *ngIf="showAttachmentModal"></section>
  <section class="modal__mask" @fade *ngIf="showConfirmationModal"></section>

  <add-or-edit-modal
    #attachmentModal
    class="attachment-modal"
    *ngIf="showAttachmentModal"
    [viewProfile]="true"
    [config]="config.reply.attachment"
    [mainConfig]="config.reply.attachment"
    [successResponse]="addSuccessResponse"
    [errorResponse]="addErrorResponse"
    [addRowForm]="formGroup"
    (cancel)="closeModal()"
    (save)="closeModal()"
    (fileMetaData)="onFileMetaData($event)"
  ></add-or-edit-modal>

  <add-or-edit-modal
    #confirmationModal
    class="attachment-modal"
    *ngIf="showConfirmationModal"
    [viewProfile]="true"
    [config]="config?.reply?.success"
    [mainConfig]="config.reply.success"
    [successResponse]="showConfirmationModal"
    [errorResponse]="addErrorResponse"
    [addRowForm]="formGroup"
    (save)="closeConfirmationModal()"
  ></add-or-edit-modal>
</article>
