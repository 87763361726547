import { Component, Input } from '@angular/core';
import { ErrorEmptyConfig } from '@zipari/shared-ds-util-form';

@Component({
  selector: 'error-empty',
  templateUrl: './error-empty.component.html',
  styleUrls: ['./error-empty.component.scss'],
})
export class ErrorEmptyComponent {
  @Input() config: ErrorEmptyConfig;
}
