export const CLAIM_DETAIL_URLS = {
  OVERVIEW: 'overview',
  FINANCIAL_OVERVIEW: 'financial-overview',
  CLAIM_LINES: 'claim-lines',
  CLAIM_APPEALS: 'claim-appeals',
  CLAIM_APPEAL_DETAIL: 'claim-appeal-detail',
  DISPUTE_RESPONSE: 'dispute-response',
  CLAIM_RECONSIDERATION: 'claim-reconsideration',
  CLAIM_REDETERMINATION: 'claim-reconsideration',
};

export const REQUEST_ACCESS_PAYLOAD = {
  REQUEST_PAYLOAD: 'api/common/access-requests/',
};

export const verifyTaxIdsEndpoint = 'api/provider-portal/admin/verify-tax-ids/';
