import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileTitle',
})
export class FileTitlePipe implements PipeTransform {
  transform(
    text: string,
    options: { maxLength: number; ellipsis: string } = {
      maxLength: 15,
      ellipsis: '...',
    }
  ): string {
    const maxLength = options.maxLength;
    const ellipsisLength = options.ellipsis.length;
    if (text?.length - maxLength - ellipsisLength <= ellipsisLength)
      return text;
    const halfMaxLength = Math.round(maxLength / 2);
    const firstHalf = text?.substr(0, halfMaxLength);
    const secondHalf = text?.substr(text.length - halfMaxLength);

    return `${firstHalf}${options.ellipsis}${secondHalf}`;
  }
}
