import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfigService } from '../../services';
import { AnalyticsService } from '../../services/analytics.service';
import { NotificationsService } from './notifications.service';
import {
  NotificationConfig,
  NotificationModel,
  notificationSubtypeToIconMapping,
} from './notifications.constant';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @Input() showHeader = true;
  @Input() allowPaging = false;
  @Input() latestNotifications: NotificationModel[];

  notifications: NotificationModel[] = [];
  notificationSubtypeToIconMapping = notificationSubtypeToIconMapping;
  notificationsConfig: NotificationConfig;

  constructor(
    public router: Router,
    public notificationsService: NotificationsService,
    public http: HttpClient,
    public configService: ConfigService,
    public analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.notificationsConfig =
      this.configService.getPageConfig('notifications');
    // add new mappings for overriding default icons
    if (this.notificationsConfig?.mapping) {
      Object.assign(
        this.notificationSubtypeToIconMapping,
        this.notificationsConfig.mapping
      );
    }

    if (this.latestNotifications) {
      this.notifications = this.latestNotifications;
    } else {
      this.notificationsService.newNotifications.subscribe(
        (notifications: any) => {
          this.notifications = notifications;
        }
      );

      this.notificationsService.retrieveNotifications();
    }
  }
}
