<ng-container>
  <div class="Information">
    <div *ngIf="header">
      <header>
        <h5 role="heading" aria-level="1" [innerHTML]="header"></h5>
        <hr class="Information__Separator" />
      </header>
    </div>
    <div class="Information__List">
      <ng-container *ngIf="context$ | async as list; else loading">
        <div *ngFor="let attribute of attributeList">
          <model-attribute
            [context]="list"
            [config]="attribute"
            direction="column"
          ></model-attribute>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template #loading>
    <spinner [asOverlay]="false" size="small" flexCenter="true"></spinner>
  </ng-template>
</ng-container>
