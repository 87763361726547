/** *
 * ERHO: Add event keys here
 * Top level keys are what is referenced in the config for whichever feature
 * and the nested "event_key" is the actual event key from the CX Capture spreadsheet for PP
 ***/

export const CX_CALLS = {
  pp_referral_entry_completed: {
    event_key: 'pp_referral-entry_completed',
  },
  pp_referral_entry_edited: {
    event_key: 'pp_referral-entry_edited',
  },
  pp_claimlist_viewed: {
    event_key: 'pp_claim-list_viewed',
  },
  pp_claimsid_searched: {
    event_key: 'pp_claims-id_searched',
  },
  pp_claimsmemberid_searched: {
    event_key: 'pp_claims-member-id_searched',
  },
  pp_claimdetails_viewed: {
    event_key: 'pp_claim-details_viewed',
  },
  pp_claimeop_viewed: {
    event_key: 'pp_claim-eop_viewed',
  },
  pp_claimsmembername_searched: {
    event_key: 'pp_claims-member-name_searched',
  },
  pp_message_center_viewed: {
    event_key: 'pp_messagecenter_viewed',
  },
  pp_message_inbox_viewed: {
    event_key: 'pp_message-inbox_viewed',
  },
  pp_message_sent_viewed: {
    event_key: 'pp_message-sent_viewed',
  },
  pp_message_archived_viewed: {
    event_key: 'pp_message-archived_viewed',
  },
  pp_new_message_created: {
    event_key: 'pp_new-message-created',
  },
  pp_message_reply_sent: {
    event_key: 'pp_message-reply_sent',
  },
  pp_message_archived: {
    event_key: 'pp_message_archived',
  },
  pp_message_unarchived: {
    event_key: 'pp_message-unarchived',
  },
  pp_message_document_attached: {
    event_key: 'pp_message-document_attached',
  },
  pp_message_viewed: {
    event_key: 'pp_message_viewed',
  },
  pp_notification_center_viewed: {
    event_key: 'pp_notification-center_viewed',
  },
  pp_notification_center_read: {
    event_key: 'pp_notificationcenter_read',
  },
  pp_claim_entry_edited: {
    event_key: 'pp_claim_entry_edited',
  },
  pp_claim_entry_reviewed: {
    event_key: 'pp_claim_entry_reviewed',
  },
  pp_claim_entry_completed: {
    event_key: 'pp_claim_entry_completed',
  },
  pp_memberdetails_IDcard_tab_viewed: {
    event_key: 'pp_member-details-ID-card_tab_viewed',
  },
  pp_memberdetails_IDcard_printed: {
    event_key: 'pp_member-details-ID-card_printed',
  },
  pp_memberdetails_IDcard_downloaded: {
    event_key: 'pp_member-details-ID-card_downloaded',
  },
  pp_alert_displayed: {
    event_key: 'pp_alert_displayed',
  },
  pp_alert_deleted: {
    event_key: 'pp_alert_deleted',
  },
  pp_alert_viewed: {
    event_key: 'pp_alert_viewed',
  },
  pp_pregnancy_form_edited: {
    event_key: 'pp_pregnancy-form_edited',
  },
  pp_add_site_location_terminated: {
    event_key: 'pp_add-site-location_terminated',
  },
  pp_add_site_location_practitioner_association_terminated: {
    event_key: 'pp_add-site-location-practitioner-association_terminated',
  },
  pp_add_site_location_viewed: {
    event_key: 'pp_add-site-location_viewed',
  },
  pp_authorization_entry_completed: {
    event_key: 'pp_authorization-entry_completed',
  },
  pp_authorization_entry_reviewed: {
    event_key: 'pp_authorization-entry_reviewed',
  },
  pp_authorization_entry_edited: {
    event_key: 'pp_authorization-entry_edited',
  },
  pp_authorization_review_edit_initiated: {
    event_key: 'pp_authorization-review-edit_initiated',
  },
  pp_documentcenter_viewed: {
    event_key: 'pp_document-center-list_viewed',
  },
  pp_referral_entry_reviewed: {
    event_key: 'pp_referral-entry_reviewed',
  },
  pp_member_details_benefits_searched: {
    event_key: 'pp_member-details-benefits_searched',
  },
  pp_authorization_edit_edited: {
    event_key: 'pp_authorization-edit_edited',
  },
  pp_manageaccess_provideruser_denied: {
    event_key: 'pp_manageaccess-provideruser_denied',
  },
  pp_provider_admin_created: {
    event_key: 'pp_provider-admin_created',
  },
  pp_providerofficestaff_created: {
    event_key: 'pp_providerofficestaff_created',
  },
  pp_site_location_attestation_viewed: {
    event_key: 'pp_site-location-attestation_viewed',
  },
  pp_practitioners_reviewed: {
    event_key: 'pp_practitioners_reviewed',
  },
  pp_site_locations_attested: {
    event_key: 'pp_site-locations_attested',
  },
  pp_providers_attested: {
    event_key: 'pp_providers_attested',
  },
  pp_attestation_alert_closed: {
    event_key: 'pp_attestation-alert_closed',
  },
  pp_site_location_reviewed: {
    event_key: 'pp_site-location_reviewed',
  },
  pp_practitioners_submitted: {
    event_key: 'pp_practitioners_submitted',
  },
  'pp_claims-appeal_viewed': {
    event_key: 'pp_claims-appeal_viewed',
  },
  pp_claims_appeal_confirmation_viewed: {
    event_key: 'pp_claims-appeal-confirmation_viewed',
  },
};
