<div
  class="SidebarMainContainer"
  [ngClass]="{ 'SidebarMainContainer__BG--Reverse': hasBackgroundChanged }"
>
  <div
    class="SidebarMainContainer__content"
    [ngClass]="{ 'SidebarMainContainer__content--Small': hasSizeChanged }"
  >
    <ng-content></ng-content>
  </div>
</div>
