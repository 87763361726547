import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { ModalConfig } from '@zipari/shared-ds-util-modal';
import { TableConfig } from '../edit-authorization/edit-authorization.model';
import { Group } from '../../../shared/models/shared/UserPayloadModels/UserEditModel.model';
import { AccessRequested } from '../../../shared/modules/authentication/model/request-access';

export class CreateUserConfig {
  firstName: string;
  lastName: string;
  role: string;
  organization: string;
  jobTitle: string;
  emailAddress: any;
  phoneNumber: number;
  phoneExtension: number;
  groupIds?: string[];
  groupId?: string;
  address1: any;
  address2: any;
  city: string;
  state: string;
  zipCode: number;
  userName: string;
  accessRequestId: number;
  contactInformation: contactInfo;
  practiceInformation: practiceInfo;
}
export class contactInfo {
  firstName: string;
  lastName: string;
  email: any;
  phoneNumber: number;
  phoneExtension: number;
  fax: any;
}
export class practiceInfo {
  practiceName: string;
  practiceSpecialty: string;
  department: string;
  address1: any;
  address2: any;
  city: string;
  state: string;
  zipCode: number;
  officeManager: string;
  email: string;
  claimNumber: number;
  taxIds: string[];
  phoneNumber: any;
  phoneExtension: number;
  npi: number;
}

export class ManageAccessCreateUser {
  id: number | string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phoneNumber: string;
  accessRequestedFor: [];
  accessRequested?: AccessRequested[];
  confirmationNumber: string;
  accessRequestType: any;
  status: string;
  createdAt: string;
  updatedAt: string;
  organization: string;
  jobTitle: string;
  claimNumber: string;
  groups: Group[];
  npi: string;
  phoneExtension: string;
  practiceInformation: practiceInformation;
  contactInformation: contactInformation;
  address: AddressInformation;
}

export class contactInformation {
  id: number;
  createdAt: string;
  updatedAt: string;
  data: {};
  tenantName: string;
  appName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phoneNumber: string;
  phoneExtension: number;
  fax: string;
  email: string;
}

export class practiceInformation {
  id: number;
  practiceName: string;
  practiceSpecialty: string;
  officeManager: string;
  department: string;
  phoneNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  email: string;
  phoneExtension: string;
  fax: number;
}

export class AddressInformation {
  id: number;
  state: string;
  countyName: string;
  countyCode: string;
  streetName1: string;
  streetName2: string;
  streetName3: string;
  effectiveDate: any;
  terminationDate: any;
  addressType: string;
  cityName: string;
  zipCode: string;
  title: string;
  attention: string;
  type: string;
  countryName: string;
  countryCode: string;
  tenant: string;
  isPrimary: any;
}

export interface AddMultipleGroupConfig {
  addButton?: ButtonConfig;
  modalConfig?: {
    flyout: ModalConfig;
    submitButton: ButtonConfig;
  };
  defaultGroupName: string;
  groupListTable?: TableConfig;
  iconConfig?: IconConfig;
}

export interface IconConfig {
  format?: string;
  size?: string;
  fill?: string;
  color?: string;
}

export class OktaGroupPayload {
  id: string | Group;
}
