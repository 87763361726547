import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ModalConfig, ModalTypes } from '@zipari/shared-ds-util-modal';
import {
  DocumentCenterConfig,
  DocumentCenterTab,
} from '../../util/document-center.model';

@Component({
  selector: 'zipari-mobile-filters',
  templateUrl: './mobile-filters.component.html',
  styleUrls: ['./mobile-filters.component.scss'],
})
export class MobileFiltersComponent implements OnInit {
  @Input() documentCenterConfig!: DocumentCenterConfig;
  @Input() tab!: DocumentCenterTab;
  @Input() isMobileFiltersOpen = false;
  @Input() filtersForm!: UntypedFormGroup;

  @Output() clearFilters: EventEmitter<void> = new EventEmitter<void>();
  @Output() applyFilters: EventEmitter<void> = new EventEmitter<void>();
  modalConfig!: ModalConfig;

  ngOnInit(): void {
    const modalHeader =
      this.documentCenterConfig?.mobileFiltersModal?.modalHeader;
    this.modalConfig = {
      header: modalHeader ? modalHeader : {},
      type: ModalTypes.flyout,
    };
  }
}
