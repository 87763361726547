import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ButtonModule,
  MessageBannerModule,
  ModelAttributeModule,
  SpinnerModule,
  StatusModule,
  TabsModule,
} from '@zipari/design-system';
import { RouterModule } from '@angular/router';
import { ZipBusyModule } from '../../../shared/modules/zip-busy';
import { SharedModule } from '../../../shared/shared.module';
import { IdCardsModule } from '../id-cards/id-cards.module';
import { PatientDetailComponent } from './patient-detail.component';
import { PatientDetailRoutingModule } from './patient-detail-routing.module';
import { BenefitsModule } from './benefits/benefits.module';
import { AccumulatorsModule } from './accumulators/accumulators.module';
import { DataTableModule } from './data-table/data-table.module';

@NgModule({
  declarations: [PatientDetailComponent],
  imports: [
    CommonModule,
    ModelAttributeModule,
    TabsModule,
    RouterModule,
    ButtonModule,
    ZipBusyModule,
    SharedModule,
    MessageBannerModule,
    PatientDetailRoutingModule,
    BenefitsModule,
    IdCardsModule,
    AccumulatorsModule,
    DataTableModule,
    StatusModule,
    SpinnerModule,
  ],
  exports: [PatientDetailComponent],
})
export class PatientDetailModule {}
