<section>
  <div class="container">
    <h2 class="t-bold manage-acces-request__header-text">
      {{ config?.headerText }}
    </h2>
    <section class="manage-access-request__top-filter-style">
      <zip-table
        [options]="(config?.sections)['0']['table']"
        (cellClicked)="cellClick($event)"
      >
      </zip-table>
    </section>
    <section class="manage-access-request__top-filter-style">
      <zip-table
        [options]="(config?.sections)['1']['table']"
        (cellClicked)="cellClick($event)"
      >
      </zip-table>
    </section>
  </div>
</section>
