import { Directive, HostListener } from '@angular/core';
import { EventInfo } from '@zipari/shared-ds-util-digital-data';
import { DigitalDataService } from '../../services/digital-data.service';

@Directive({
  selector: '[dataClickEvent]',
})
export class DigitalDataClickEventDirective {
  constructor(private dds: DigitalDataService) {}

  @HostListener('click', ['$event'])
  clickEvent(event) {
    const description = event.currentTarget.getAttribute('aria-description');
    const label = event.currentTarget.getAttribute('aria-label');
    const clickEventInfo: EventInfo = this.createClickEventInfo(
      description || label
    );
    if (description) {
      this.dds.pushEventObject(clickEventInfo);
    }
  }

  createClickEventInfo(description: string) {
    return {
      eventName: 'click event',
      eventAction: 'clickEvent',
      eventPoints: 200,
      timeStamp: new Date(),
      type: 'click',
      effect: description,
    };
  }
}
