<input
  aria-label="file-uploader"
  class="u-hidden"
  type="file"
  #fileInput
  (change)="onFileSelected($event)"
  data-test-id="fileUploaderInput"
/>

<zip-button
  class="document-upload__dropzone__button"
  [disabled]="disabled"
  (zipButtonClicked)="onUploadDocumentClick()"
  [config]="{
    level: fileUploadButton ? fileUploadButton.level : 'high',
    content: fileUploadButton ? fileUploadButton.content : 'Upload Document',
    icon: fileUploadButton ? fileUploadButton.icon : 'upload'
  }"
>
</zip-button>
