import { HttpErrorResponse } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { GridLayoutRow, Lines } from '@zipari/design-system';
import { Datagrid } from '@zipari/shared-ds-util-datagrid';
import { objectToArray } from '@zipari/shared-util-common';
import { httpErrorToMessageBanner } from '@zipari/web-services';
import { DocumentCenterTab } from '../util/document-center.model';

@Pipe({
  name: 'getErrorMessage',
})
export class GetErrorMessagePipe implements PipeTransform {
  transform(config: Datagrid, error: HttpErrorResponse): Datagrid {
    if (error) {
      const { message } = httpErrorToMessageBanner(error);

      config = {
        ...config,
        error: {
          ...config.error,
          message,
        },
      };
    }

    return config;
  }
}

@Pipe({
  name: 'gridObjectsToArrays',
})
export class FormatDocumentCenterTab implements PipeTransform {
  transform(tab: DocumentCenterTab) {
    if (!tab.gridlayout) return tab;

    const formattedTab = { ...tab };
    const gridlayout = { ...formattedTab.gridlayout };
    const rows = objectToArray(gridlayout.rows);

    const mappedRows = rows.map((row: GridLayoutRow) => {
      const columns = objectToArray(row.columns);
      const mappedColumns = columns.map((col: Lines[]) => ({
        ...col,
        lines: objectToArray(col),
      }));

      return { ...row, columns: mappedColumns };
    });

    gridlayout.rows = mappedRows;
    if (formattedTab.gridlayout) {
      formattedTab.gridlayout = gridlayout;
    }
    return formattedTab;
  }
}
