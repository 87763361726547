import { Injectable } from '@angular/core';
import {
  ValidationErrors,
  ValidatorFn,
  AsyncValidatorFn,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { LoggerService } from '../../../../shared/services/logger.service';

import {
  AllControlsConfiguration,
  zipcode4Length,
} from '@zipari/shared-ds-util-form';

import * as groupValidators from './group-validators';
import { FormControlValidators } from './validators';
import {
  DatePickerValidatorConfiguration,
  endValidDateKey,
  invalidDateErrorMessage,
  startValidDateKey,
  validDateKey,
} from './validators.constants';

export class ZipValidator {
  key: string;
  validator?: ValidatorFn | Function;
  message: Function;
}

function isEmptyInputValue(value: any): boolean {
  return value === null || value === undefined || value === '';
}

@Injectable({
  providedIn: 'root',
})
export class FormControlValidatorsService {
  /**
   * @description: This is the master key of all of the validators that can be used throughout our applications.
   *
   *  TO CREATE A NEW VALIDATOR:
   *      - write the validator function in the FormControlValidators... this file extends off of the reactive form
   *     validator class so if you're unsure how to do that look at the pattern they use for guidance
   *      - add an object here keyed by the relevant key.
   *          - provide a key that is the same as the key to the object
   *          - provide a validator which is a reference to the validator function that you used above... notice that
   *     sometimes you need to call the validator function in a callback to in some scenarios
   *          - provide a message that the user will eventually see. this must be a callback that will take the
   *     configuration for the control.... which allows us to create complex messages based on the given control
   *     config
   *
   *  TO USE THE VALIDATORS:
   *      - make sure that the reactive element that you are creating utilizes the relevant validators
   *          - you can use the `getFormControlValidators` function below to do this programmatically
   *      - make sure that the component properly checks for the relevant error message key
   *      - make sure that once an error is detected... the component properly calls the message function and
   *     providing the relevant configuration for the control
   *
   * */

  // tslint:disable: max-line-length
  public validators = {
    cardBrand: {
      key: 'cardBrand',
      validator: (dependency) =>
        FormControlValidators.cardBrand('cardBrand', dependency),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'cardBrand',
          'Please enter a valid credit card number'
        ),
    },
    creditCardNumber: {
      key: 'creditCardNumber',
      validator: (dependency) =>
        FormControlValidators.creditCardNumber('creditCardNumber', dependency),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'creditCardNumber',
          'Please enter a valid credit card number'
        ),
    },
    creditCardExpirationDate: {
      key: 'creditCardExpirationDate',
      validator: FormControlValidators.creditCardExpirationDate(
        'creditCardExpirationDate'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'creditCardExpirationDate',
          'Please enter a valid expiration date'
        ),
    },
    future60Days: {
      key: 'future60Days',
      validator: FormControlValidators.future60Days('future60Days'),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'future60Days',
          `${this.getFieldName(config)} must be a date in the next 60 days`
        ),
    },
    last60Days: {
      key: 'last60Days',
      validator: FormControlValidators.last60Days('last60Days'),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'last60Days',
          `${this.getFieldName(config)} must be a date in the last 60 days`
        ),
    },
    sepQualifyingDate: {
      key: 'sepQualifyingDate',
      validator: FormControlValidators.sepQualifyingDate('sepQualifyingDate'),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'sepQualifyingDate',
          'Qualifying life event date must be within 60 days of application submission date'
        ),
    },
    reasonableDate: {
      key: 'reasonableDate',
      validator: FormControlValidators.reasonableDate('reasonableDate'),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'reasonableDate',
          'Please input a valid date'
        ),
    },
    validDate: {
      key: validDateKey,
      validator: FormControlValidators.validDate(validDateKey),
      message: (config) => {
        return this.determineErrorMessage(
          config,
          validDateKey,
          invalidDateErrorMessage
        );
      },
    },
    startValidDate: {
      key: startValidDateKey,
      validator: FormControlValidators.validDate(startValidDateKey),
      message: (config) => {
        return this.getMessage(config, startValidDateKey);
      },
    },
    endValidDate: {
      key: endValidDateKey,
      validator: FormControlValidators.validDate(endValidDateKey),
      message: (config) => {
        return this.getMessage(config, endValidDateKey);
      },
    },
    customErrMessage: {
      key: 'customErrMessage',
      validator: () => true,
      message: (config) =>
        this.determineErrorMessage(config, 'customErrMessage', ''),
    },
    endDateBeforeStartDate: {
      key: 'endDateBeforeStartDate',
      validator: (endDateControl) =>
        FormControlValidators.endDateDoesNotPreceedStartDate(
          endDateControl,
          'endDateBeforeStartDate'
        ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'endDateBeforeStartDate',
          'End date must not preceed start date'
        ),
    },
    dateInFutureIncludingCurrentMonth: {
      key: 'dateInFutureIncludingCurrentMonth',
      validator: FormControlValidators.dateInFutureIncludingCurrentMonth(
        'dateInFutureIncludingCurrentMonth'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'dateInFutureIncludingCurrentMonth',
          `${this.getFieldName(
            config
          )} must be a date in the future or be the current month.`
        ),
    },
    dateInFuture: {
      key: 'dateInFuture',
      validator: FormControlValidators.dateInFuture('dateInFuture'),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'dateInFuture',
          `${this.getFieldName(config)} must be a date in the future.`
        ),
    },
    dateInPast: {
      key: 'dateInPast',
      validator: FormControlValidators.dateInPast('dateInPast'),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'dateInPast',
          `${this.getFieldName(config)} must be a date in the past.`
        ),
    },
    checkPercentage: {
      key: 'checkPercentage',
      validator: (dependency) =>
        FormControlValidators.checkPercentage(dependency, 'checkPercentage'),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'checkPercentage',
          `${this.getFieldName(config)} cannot exceed 100%`
        ),
    },
    min: {
      key: 'min',
      validator: (minNum) => FormControlValidators.min(minNum),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'min',
          `The minimum value is ${config.minNum}`
        ),
    },
    max: {
      key: 'max',
      validator: (maxNum) => FormControlValidators.max(maxNum),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'max',
          `The maximum value is ${config.maxNum}`
        ),
    },
    minDate: {
      key: 'minDate',
      validator: (date) => FormControlValidators.minDate(date, 'minDate'),
      message: (config) => {
        const dateStr = `${
          config.minDate.getMonth() + 1
        }/${config.minDate.getDate()}/${config.minDate.getFullYear()}`;

        return this.determineErrorMessage(
          config,
          'minDate',
          `${this.getFieldName(config)} must be after ${dateStr}.`
        );
      },
    },
    maxDate: {
      key: 'maxDate',
      validator: (date) => FormControlValidators.maxDate(date, 'maxDate'),
      message: (config) => {
        const dateStr = `${
          config.maxDate.getMonth() + 1
        }/${config.maxDate.getDate()}/${config.maxDate.getFullYear()}`;

        return this.determineErrorMessage(
          config,
          'maxDate',
          `${this.getFieldName(config)} must be before ${dateStr}.`
        );
      },
    },
    dateDoesNotPreceedPreviousDate: {
      key: 'dateDoesNotPreceedPreviousDate',
      validator: (date, useRoot, canEqual) =>
        FormControlValidators.dateDoesNotPreceedPreviousDate(
          date,
          'dateDoesNotPreceedPreviousDate',
          useRoot,
          canEqual
        ),
      message: (config) => {
        const maxDateInConfig = config.maxDate ? config.maxDate : null;
        let defaultErr = '';

        if (maxDateInConfig) {
          defaultErr = `${
            config.label
            // eslint-disable-next-line max-len
          } must be before ${config.dateDoesNotPreceedPreviousDate.getMonth()}/${config.dateDoesNotPreceedPreviousDate.getDate()}/${config.dateDoesNotPreceedPreviousDate.getFullYear()}.`;
        } else {
          defaultErr = `${this.getFieldName(
            config
          )} must be before previously entered date`;
        }

        return this.determineErrorMessage(
          config,
          'dateDoesNotPreceedPreviousDate',
          defaultErr
        );
      },
    },
    dateDoesNotExceedPreviousDate: {
      key: 'dateDoesNotExceedPreviousDate',
      validator: (date, useRoot, canEqual) =>
        FormControlValidators.dateDoesNotExceedPreviousDate(
          date,
          'dateDoesNotExceedPreviousDate',
          useRoot,
          canEqual
        ),
      message: (config) => {
        const maxDateInConfig = config.maxDate ? config.maxDate : null;
        let defaultErr = '';

        if (maxDateInConfig) {
          defaultErr = `${
            config.label
            // eslint-disable-next-line max-len
          } must be before ${config.dateDoesNotExceedPreviousDate.getMonth()}/${config.dateDoesNotExceedPreviousDate.getDate()}/${config.dateDoesNotExceedPreviousDate.getFullYear()}.`;
        } else {
          defaultErr = `${this.getFieldName(
            config
          )} must be before previously entered date`;
        }

        return this.determineErrorMessage(
          config,
          'dateDoesNotExceedPreviousDate',
          defaultErr
        );
      },
    },
    minAge: {
      key: 'minAge',
      validator: (dependency) =>
        FormControlValidators.minAge(
          'minAge',
          dependency.age,
          dependency.dateProp
        ),
      message: (config) => {
        const validatorConfig = this.getValidatorConfig(config, 'minAge');

        return this.determineErrorMessage(
          config,
          'minAge',
          `Must be at least ${
            validatorConfig.dependency ? validatorConfig.dependency.age : ''
          } years of age.`
        );
      },
    },
    minAgeWithinMonth: {
      key: 'minAgeWithinMonth',
      validator: (dependency) =>
        FormControlValidators.minAgeWithinMonth(
          'minAgeWithinMonth',
          dependency.age,
          dependency.dateProp
        ),
      message: (config) => {
        const validatorConfig = this.getValidatorConfig(
          config,
          'minAgeWithinMonth'
        );

        return this.determineErrorMessage(
          config,
          'minAgeWithinMonth',
          `Must be at least ${
            validatorConfig.dependency ? validatorConfig.dependency.age : ''
          } years of age.`
        );
      },
    },
    maxAge: {
      key: 'maxAge',
      validator: (dependency) =>
        FormControlValidators.maxAge(
          'maxAge',
          dependency.age,
          dependency.dateProp,
          dependency.dateValue
        ),
      message: (config) => {
        const validatorConfig = this.getValidatorConfig(config, 'maxAge');

        return this.determineErrorMessage(
          config,
          'maxAge',
          `Cannot be older than ${
            validatorConfig.dependency ? validatorConfig.dependency.age : ''
          } years of age.`
        );
      },
    },
    updateControl: {
      key: 'updateControl',
      validator: (dependency) =>
        FormControlValidators.updateControl(dependency),
      message: () => '',
    },
    maxAgeAndPropCheck: {
      key: 'maxAgeAndPropCheck',
      validator: (dependency) =>
        FormControlValidators.maxAgeAndPropCheck(
          'maxAgeAndPropCheck',
          dependency.age,
          dependency.dateProp,
          dependency.otherProp,
          dependency.otherPropValue
        ),
      message: (config) => {
        const validatorConfig = this.getValidatorConfig(
          config,
          'maxAgeAndPropCheck'
        );

        return this.determineErrorMessage(
          config,
          'maxAgeAndPropCheck',
          `Cannot be older than ${
            validatorConfig.dependency ? validatorConfig.dependency.age : ''
          } years of age and not meet other check.`
        );
      },
    },
    selectionRequired: {
      key: 'selectionRequired',
      validator: () => true,
      message: (config) =>
        this.determineErrorMessage(
          config,
          'selectionRequired',
          `Please select a ${config.label} from the dropdown`
        ),
    },
    dateWithinDaysPast: {
      key: 'dateWithinDaysPast',
      validator: (dependency) =>
        FormControlValidators.dateWithinDaysPast(
          dependency,
          'dateWithinDaysPast'
        ),
      message: (config) => {
        const validator = this.getValidatorConfig(config, 'dateWithinDaysPast');
        let errorMsg = `${this.getFieldName(
          config
        )} must be a date in the last ${validator.dependency} days`;
        if (validator?.showMessageWithDate) {
          const date = new Date();
          date.setDate(date.getDate() - validator.dependency);
          const numOfDigitsToDisplay = 2;
          const day = String(date.getDate()).padStart(
            numOfDigitsToDisplay,
            '0'
          );
          const month = String(date.getMonth() + 1).padStart(
            numOfDigitsToDisplay,
            '0'
          );
          const year = date.getFullYear();
          errorMsg = `${validator.showMessageWithDate} ${month}/${day}/${year}`;
        }

        return this.determineErrorMessage(
          config,
          'dateWithinDaysPast',
          errorMsg
        );
      },
    },
    dateWithinDaysFuture: {
      key: 'dateWithinDaysFuture',
      validator: (dependency) =>
        FormControlValidators.dateWithinDaysFuture(
          dependency,
          'dateWithinDaysFuture'
        ),
      message: (config) => {
        const validator = this.getValidatorConfig(
          config,
          'dateWithinDaysFuture'
        );

        return this.determineErrorMessage(
          config,
          'dateWithinDaysFuture',
          `${this.getFieldName(config)} must be a date in the next ${
            validator.dependency
          } days`
        );
      },
    },
    dateInPastWithinDays: {
      key: 'dateInPastWithinDays',
      validator: (dependency) =>
        FormControlValidators.dateInPastWithinDays(
          dependency,
          'dateInPastWithinDays'
        ),
      message: (config) => {
        const validator = this.getValidatorConfig(
          config,
          'dateInPastWithinDays'
        );

        return this.determineErrorMessage(
          config,
          'dateInPastWithinDays',
          `${this.getFieldName(config)} must be a date in the last ${
            validator.dependency
          } days`
        );
      },
    },
    dateInFutureWithinDays: {
      key: 'dateInFutureWithinDays',
      validator: (dependency) =>
        FormControlValidators.dateInFutureWithinDays(
          dependency,
          'dateInFutureWithinDays'
        ),
      message: (config) => {
        const validator = this.getValidatorConfig(
          config,
          'dateInFutureWithinDays'
        );

        return this.determineErrorMessage(
          config,
          'dateInFutureWithinDays',
          `${this.getFieldName(config)} must be a date in the next ${
            validator.dependency
          } days`
        );
      },
    },
    dateInRangeCount: {
      key: 'dateInRangeCount',
      validator: (dependency) =>
        FormControlValidators.dateInRangeCount(dependency, 'dateInRangeCount'),
      message: (config) => {
        const validator = this.getValidatorConfig(config, 'dateInRangeCount');

        return this.determineErrorMessage(
          config,
          'dateInRangeCount',
          `Date must be within past ${validator.dependency.past.count} ${
            validator.dependency.past.skipWeekend ? 'business' : 'calendar'
          } days
                    and ${validator.dependency.future.count} ${
            validator.dependency.future.skipWeekend ? 'business' : 'calendar'
          } days in the future`
        );
      },
    },
    dateNotBlacklisted: {
      key: 'dateNotBlacklisted',
      validator: (dependency) =>
        FormControlValidators.dateNotBlacklisted(
          dependency,
          'dateNotBlacklisted'
        ),
      message: (config) => {
        const validator = this.getValidatorConfig(config, 'dateNotBlacklisted');
        const dates = validator.dependency.toString().replace(/,/g, ', ');

        return this.determineErrorMessage(
          config,
          'dateNotBlacklisted',
          `Date must not include: ${dates}`
        );
      },
    },
    dateEmptyOrComplete: {
      key: 'dateEmptyOrComplete',
      validator: (dependency) =>
        FormControlValidators.dateEmptyOrComplete(
          dependency,
          'dateEmptyOrComplete'
        ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'dateEmptyOrComplete',
          'Date must either be empty or complete.'
        ),
    },
    birthDate: {
      key: 'birthDate',
      validator: FormControlValidators.birthDate('birthDate'),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'birthDate',
          `${this.getFieldName(config)} must be a realistic birth date.`
        ),
    },
    checkNumber: {
      key: 'checkNumber',
      validator: FormControlValidators.patternWithName(
        /^[0-9]{1,20}$/,
        'checkNumber'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'checkNumber',
          `${this.getFieldName(
            config
          )} must be in check number format (less than 20 digits).`
        ),
    },
    bankAccountNumber: {
      key: 'bankAccountNumber',
      validator: FormControlValidators.patternWithName(
        /^[0-9]{7,20}$/,
        'bankAccountNumber'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'bankAccountNumber',
          `${this.getFieldName(
            config
          )} must be in bank account number format (between 7 and 20 digits).`
        ),
    },
    routingNumber: {
      key: 'routingNumber',
      validator: FormControlValidators.patternWithName(
        /^[0-9]{9,9}$/,
        'routingNumber'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'routingNumber',
          `${this.getFieldName(
            config
          )} must be in routing number format (XXXXXXXXX).`
        ),
    },
    i94Number: {
      key: 'i94Number',
      validator: FormControlValidators.patternWithName(
        /^[0-9]{9,9}[\w]{2,2}$/,
        'i94Number'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'i94Number',
          `${this.getFieldName(
            config
          )} must be in i-94 number format (XXXXXXXXXXX).`
        ),
    },
    cardNumber: {
      key: 'cardNumber',
      validator: FormControlValidators.patternWithName(
        /^[0-9]{13,16}$/,
        'cardNumber'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'cardNumber',
          `${this.getFieldName(
            config
          )} must be in card number format (XXXXXXXXXXXXX).`
        ),
    },
    immigrationCardNumber: {
      key: 'immigrationCardNumber',
      validator: FormControlValidators.patternWithName(
        /^[a-zA-Z]{3}[0-9]{10}$/,
        'immigrationCardNumber'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'immigrationCardNumber',
          `${this.getFieldName(
            config
          )} must start with 3 letters, then 10 numbers (XXXXXXXXXXXXX).`
        ),
    },
    passportNumber: {
      key: 'passportNumber',
      validator: FormControlValidators.patternWithName(
        /^[a-zA-Z0-9]{6,12}$/,
        'passportNumber'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'passportNumber',
          `${this.getFieldName(config)} must be in passport number format.`
        ),
    },
    employeeIdNumber: {
      key: 'employeeIdNumber',
      validator: FormControlValidators.patternWithName(
        /^[0-9]{9,9}$/,
        'employeeIdNumber'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'employeeIdNumber',
          `${this.getFieldName(
            config
          )} must be in employee id number format (AXXXXXXXXX).`
        ),
    },
    alienNumber: {
      key: 'alienNumber',
      validator: FormControlValidators.patternWithName(
        /^[\d]{6,10}$/,
        'alienNumber'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'alienNumber',
          `${this.getFieldName(
            config
          )} must be numeric. (Anything provided less than 9 digits will have 0s prepended when stored)`
        ),
    },
    equals: {
      key: 'equals',
      validator: (dependency) =>
        FormControlValidators.equals(dependency, 'equals'),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'equals',
          `${this.getFieldName(config)} must match.`
        ),
    },
    notEqual: {
      key: 'notEqual',
      validator: (dependency) =>
        FormControlValidators.notEqual(dependency, 'notEqual'),
      message: (config) => {
        const validator = this.getValidatorConfig(config, 'notEqual');

        return this.determineErrorMessage(
          config,
          'notEqual',
          `${this.getFieldName(config)} cannot equal ${
            validator.dependency || ''
          }.`
        );
      },
    },
    match: {
      key: 'match',
      validator: (dependency, form) =>
        FormControlValidators.match(form, dependency, 'match'),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'match',
          `${this.getFieldName(config)} must match.`
        ),
    },
    matchCaseInsensitive: {
      key: 'matchCaseInsensitive',
      validator: (dependency, form) =>
        FormControlValidators.matchCaseInsensitive(
          form,
          dependency,
          'matchCaseInsensitive'
        ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'matchCaseInsensitive',
          `${this.getFieldName(config)} must match.`
        ),
    },
    not_match: {
      key: 'not_match',
      validator: (dependency, form) =>
        FormControlValidators.not_match(form, dependency, 'not_match'),
      message: (config) => {
        const relevantValidatorObject = this.getValidatorConfig(
          config,
          'not_match'
        );

        return this.determineErrorMessage(
          config,
          'not_match',
          `${this.getFieldName(config)} cannot match ${
            relevantValidatorObject.dependency || ''
          }.`
        );
      },
    },
    lessThan: {
      key: 'lessThan',
      validator: (form, compareTo, useRoot): ValidationErrors =>
        FormControlValidators.lessThan(form, compareTo, useRoot),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'lessThan',
          `${this.getFieldName(config)} value exceeds limit`
        ),
    },
    maxInArr: {
      key: 'maxInArr',
      validator: (form, max, prop) =>
        FormControlValidators.maxInArr(form, max, prop),
      message: (config) => {
        const validator = this.getValidatorConfig(config, 'maxInArr');

        return this.determineErrorMessage(
          config,
          'maxInArr',
          `Max combined count is ${validator.max}`
        );
      },
    },
    alpha: {
      key: 'alpha',
      validator: FormControlValidators.patternWithName(
        /^[a-zA-Z0-9_]*$/,
        'alpha'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'alpha',
          `${this.getFieldName(config)} must be alphabetic.`
        ),
    },
    numeric: {
      key: 'numeric',
      validator: FormControlValidators.patternWithName(/^[0-9]*$/, 'numeric'),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'numeric',
          `${this.getFieldName(config)} must be a number.`
        ),
    },
    minOneUpperCase: {
      key: 'minOneUpperCase',
      validator: FormControlValidators.patternWithName(
        /(?=.*[A-Z])/,
        'minOneUpperCase'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'minOneUpperCase',
          `${this.getFieldName(
            config
          )} must contain at least one upper case letter`
        ),
    },
    minOneLowerCase: {
      key: 'minOneLowerCase',
      validator: FormControlValidators.patternWithName(
        /(?=.*[a-z])/,
        'minOneLowerCase'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'minOneLowerCase',
          `${this.getFieldName(
            config
          )} must contain at least one lower case letter`
        ),
    },
    minOneNumber: {
      key: 'minOneNumber',
      validator: FormControlValidators.patternWithName(
        /(?=.*[1-9])/,
        'minOneNumber'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'minOneNumber',
          `${this.getFieldName(config)} must contain at least one number`
        ),
    },
    numericAllowNegative: {
      key: 'numericAllowNegative',
      validator: FormControlValidators.patternWithName(
        /^-?[0-9]*$/,
        'numericAllowNegative'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'numericAllowNegative',
          `${this.getFieldName(config)} must be a number.`
        ),
    },
    name: {
      key: 'name',
      validator: FormControlValidators.patternWithName(
        /^[a-zA-Z\-.' ]*$/,
        'name'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'name',
          `${this.getFieldName(config)} must be in name format.`
        ),
    },
    zipcode4: {
      key: 'zipcode4',
      // eslint-disable-next-line no-magic-numbers
      validator: FormControlValidators.exactLength(zipcode4Length, 'zipcode4'),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'zipcode4',
          `${this.getFieldName(config)} must be in format zipcode 4. (EX. xxxx)`
        ),
    },
    ssn: {
      key: 'ssn',
      validator: FormControlValidators.patternWithName(
        // eslint-disable-next-line max-len
        /^((?!219099999|078051120|123456789|111111111|222222222|333333333|444444444|555555555|666666666|777777777|888888888|999999999)(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4})$/,
        'ssn'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'ssn',
          `${this.getFieldName(config)} must be in XXXXXXXXX format`
        ),
    },
    ssnDigits: {
      key: 'ssnDigits',
      validator: FormControlValidators.patternWithName(
        /^(?!(000|666|9))(\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4})$/,
        'ssnDigits'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'ssnDigits',
          `${this.getFieldName(
            config
          )} must be in XXXXXXXXX format and not contain leading digits 900-999 or 666`
        ),
    },
    itin: {
      key: 'itin',
      validator: FormControlValidators.patternWithName(
        /^(9\d{2})([ \-]?)([7]\d|8[0-8])([ \-]?)(\d{4})$/,
        'itin'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'itin',
          `${this.getFieldName(
            config
          )} should have 9 digits, start with 9 and 4th and 5th digits are between 70-88. (EX. 900701234)`
        ),
    },
    taxId: {
      key: 'taxId',
      validator: FormControlValidators.patternWithName(/^[0-9]{9}$/, 'taxId'),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'taxId',
          `${this.getFieldName(config)} must be in XX-XXXXXXX format`
        ),
    },
    zipcode: {
      key: 'zipcode',
      validator: FormControlValidators.patternWithName(
        /^[0-9]{5,5}$/,
        'zipcode'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'zipcode',
          `${this.getFieldName(config)} must be in format zipcode. (EX. xxxxx)`
        ),
    },
    zipcodeCanada: {
      key: 'zipcodeCanada',
      validator: FormControlValidators.patternWithName(
        /^[a-zA-Z0-9_]{6,6}$/,
        'zipcodeCanada'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'zipcodeCanada',
          `${this.getFieldName(config)} must be a valid Canada zipcode.`
        ),
    },
    monthDay: {
      key: 'monthDay',
      validator: FormControlValidators.patternWithName(
        /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])$/,
        'monthDay'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'monthDay',
          `${this.getFieldName(config)} must be in format MM/DD. (EX. 12/31)`
        ),
    },
    monthYear: {
      key: 'monthYear',
      validator: FormControlValidators.patternWithName(
        /^(0?[1-9]|1[0-2])\/(\d{4})$/,
        'monthYear'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'monthYear',
          `${this.getFieldName(
            config
          )} must be in format MM/YYYY. (EX. 01/2025)`
        ),
    },
    monthShortYear: {
      key: 'monthShortYear',
      validator: FormControlValidators.patternWithName(
        /^(0?[1-9]|1[0-2])\/(\d{2})$/,
        'monthShortYear'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'monthShortYear',
          `${this.getFieldName(config)} must be in format MM/YY. (EX. 01/25)`
        ),
    },
    endOfMonth: {
      validator: FormControlValidators.endOfMonth(),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'endOfMonth',
          `${config.label} must be the end of the month`
        ),
    },
    endOfYear: {
      validator: FormControlValidators.endOfYear(),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'endOfYear',
          `${config.label} must be the last day of the year`
        ),
    },
    validZipcode: {
      key: 'validZipcode',
      validator: () => true,
      message: (config) =>
        this.determineErrorMessage(
          config,
          'validZipcode',
          'Please enter a valid zipcode'
        ),
    },
    phone: {
      key: 'phone',
      validator: FormControlValidators.patternWithName(
        /^[1-9][0-9]{9,9}$/,
        'phone'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'phone',
          `${this.getFieldName(
            config
          )} must be in proper phone number format. (Ex: xxxxxxxxxx)`
        ),
    },
    bpPhone: {
      key: 'bpPhone',
      validator: FormControlValidators.patternWithName(
        /^(\d{3}-\d{3}-\d{4}){1}$/,
        'bpPhone'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'bpPhone',
          `${this.getFieldName(
            config
          )} must be in format phone. (EX. xxx-xxx-xxxx)`
        ),
    },
    cfPhone: {
      key: 'cfPhone',
      validator: FormControlValidators.patternWithName(
        /(^(\d{3}-\d{3}-\d{4}){1}$)|(^(\(\d{3}\) \d{3}-\d{4}){1}$)/,
        'cfPhone'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'cfPhone',
          `${this.getFieldName(
            config
          )} must be in format phone. (EX. (xxx) xxx-xxxx)`
        ),
    },
    email: {
      key: 'email',
      validator: FormControlValidators.patternWithName(
        // eslint-disable-next-line max-len
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'email'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'email',
          `${this.getFieldName(
            config
          )} must be in email format. (EX. example@website.com)`
        ),
    },
    social: {
      key: 'social',
      validator: FormControlValidators.patternWithName(
        /^[0-9]{9,9}$/,
        'social'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'social',
          `${this.getFieldName(
            config
          )} must be in format SSN. (EX. XXX-XX-XXXX)`
        ),
    },
    socialWithDashes: {
      key: 'social',
      validator: FormControlValidators.patternWithName(
        /^\d{3}-?\d{2}-?\d{4}$/,
        'socialWithDashes'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'socialWithDashes',
          `${this.getFieldName(
            config
          )} must be in format SSN. (EX. XXX-XX-XXXX)`
        ),
    },
    dea: {
      key: 'dea',
      validator: FormControlValidators.patternWithName(
        /^[a-zA-Z0-9]{2}[0-9]{7}$/,
        'dea'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'dea',
          `${this.getFieldName(
            config
          )} must be in format DEA format. (EX. AB1234567)`
        ),
    },
    required: {
      key: 'required',
      validator: FormControlValidators.zipRequired,
      message: (config) =>
        this.determineErrorMessage(
          config,
          'required',
          `${this.getFieldName(config)} is required.`
        ),
    },
    requiredTrue: {
      key: 'requiredTrue',
      validator: FormControlValidators.zipRequiredTrue,
      message: (config) =>
        this.determineErrorMessage(
          config,
          'required',
          `${this.getFieldName(config)} is required.`
        ),
    },
    requiredIfAvailable: {
      key: 'requiredIfAvailable',
      validator: (dependency) =>
        FormControlValidators.requiredIfAvailable(
          dependency,
          'requiredIfAvailable'
        ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'requiredIfAvailable',
          `${this.getFieldName(config)} is required.`
        ),
    },
    minCount: {
      key: 'minCount',
      validator: (dependency) =>
        FormControlValidators.minCount(dependency, 'minCount'),
      message: (config) => {
        const validator = this.getValidatorConfig(config, 'minCount');

        return this.determineErrorMessage(
          config,
          'minCount',
          `${config.label || 'This field'} has a minimum length of ${
            validator.dependency
          }`
        );
      },
    },
    excludePO: {
      key: 'excludePO',
      validator: FormControlValidators.patternWithName(
        /^(?![P,p,\][\.,\][O, o]).*/,
        'excludePO'
      ),
      message: (config) =>
        `${this.getFieldName(config)} cannot have P.O Box Number`,
    },
    maxCount: {
      key: 'maxCount',
      validator: (dependency) =>
        FormControlValidators.maxCount(dependency, 'maxCount'),
      message: (config) => {
        const validator = this.getValidatorConfig(config, 'maxCount');

        return this.determineErrorMessage(
          config,
          'maxCount',
          `${config.label || 'This field'} has a maximum length of ${
            validator.dependency
          }`
        );
      },
    },
    blacklistCharacters: {
      key: 'blacklistCharacters',
      validator: (config, dependency) =>
        FormControlValidators.blacklistCharacters(
          config,
          dependency,
          'blacklistCharacters'
        ),
      message: (config, control) => {
        const validator = this.getValidatorConfig(
          config,
          'blacklistCharacters'
        );
        const blacklistedSymbols = validator.dependency.filter(
          (blacklistedSym) => control.value.includes(blacklistedSym)
        );

        return this.determineErrorMessage(
          config,
          'blacklistCharacters',
          `Invalid character${
            blacklistedSymbols ? (blacklistedSymbols.length > 1 ? 's' : '') : ''
          } provided${
            blacklistedSymbols ? `: ${blacklistedSymbols.join('  ')}` : ''
          }`
        );
      },
    },
    arrayHasValue: {
      key: 'arrayHasValue',
      validator: () => FormControlValidators.arrayHasValue(),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'arrayHasValue',
          `${this.getFieldName(config)} required`
        ),
    },
    lettersAndSymbols: {
      key: 'lettersAndSymbols',
      validator: (dependency) =>
        FormControlValidators.lettersNumbersAndSymbols(
          'lettersAndSymbols',
          dependency
        ),
      message: (config) => {
        const validator = this.getValidatorConfig(config, 'lettersAndSymbols');
        const symbolDisplay = validator.dependency.join('');

        return this.determineErrorMessage(
          config,
          'lettersAndSymbols',
          `${this.getFieldName(
            config
          )} must contain letters or the following symbols: ${symbolDisplay}`
        );
      },
    },
    numbersAndSymbols: {
      key: 'numbersAndSymbols',
      validator: (dependency) =>
        FormControlValidators.lettersNumbersAndSymbols(
          'numbersAndSymbols',
          dependency
        ),
      message: (config) => {
        const validator = this.getValidatorConfig(config, 'numbersAndSymbols');
        const symbolDisplay = validator.dependency.join('');

        return this.determineErrorMessage(
          config,
          'numbersAndSymbols',
          `${this.getFieldName(
            config
          )} must contain numbers or the following symbols: ${symbolDisplay}`
        );
      },
    },
    lettersAndNumbersAndSymbols: {
      key: 'lettersAndNumbersAndSymbols',
      validator: (dependency) =>
        FormControlValidators.lettersNumbersAndSymbols(
          'lettersAndNumbersAndSymbols',
          dependency
        ),
      message: (config) => {
        const validator = this.getValidatorConfig(
          config,
          'lettersAndNumbersAndSymbols'
        );
        const symbolDisplay = validator.dependency.join('');

        return this.determineErrorMessage(
          config,
          'lettersAndNumbersAndSymbols',
          `${this.getFieldName(
            config
          )} must contain letters, numbers, or the following symbols: ${symbolDisplay}`
        );
      },
    },
    excludeSlashNotation: {
      key: 'excludeSlashNotation',
      validator: FormControlValidators.patternWithName(
        /\.\.\//,
        'excludeSlashNotation'
      ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'excludeSlashNotation',
          `${this.getFieldName(config)} must not contain the symbols "../"`
        ),
    },
    async: {
      key: 'async',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      validator: (dependency, name, prop, http) =>
        FormControlValidators.asyncValidationCheck(
          dependency,
          'async',
          prop,
          http
        ),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'async',
          `This ${this.getFieldName(config)} is taken. Please try a different ${
            config.label
          }.`
        ),
    },
    mbi: {
      key: 'mbi',
      validator: FormControlValidators.mbi(),
      message: (config) =>
        this.determineErrorMessage(
          config,
          'mbi',
          'Please enter a valid Medicare Beneficiary Number (MBI)'
        ),
    },
  };

  public groupValidators = {
    groupMatch: {
      validator: (validatorConfig: {
        prop1: string;
        prop2: string;
        name: string;
      }) => groupValidators.validateFormGroupMatch(validatorConfig),
      message: (config) => {
        const message = `${this.getFieldName(config)} must match.`;

        return message;
      },
    },
  };

  constructor(private http: HttpClient, private loggerService: LoggerService) {}

  /**
   * @description: This function takes a control configuration and retrieves the relevant validator function from
   *     above. There is also special logic to handle special cases for validators. EX. Dependency for the match or
   *     equals validators.
   * */

  public getFormControlValidators(
    controlConfiguration: AllControlsConfiguration,
    form?
  ): ValidatorFn[] {
    const validators: ValidatorFn[] = (controlConfiguration.validators || [])
      // eslint-disable-next-line array-callback-return
      .filter((validator) => {
        const name = typeof validator === 'object' ? validator.name : validator;
        if (name !== 'async') {
          return this.validators[name];
        }
      })
      .map((validator) => {
        const name = typeof validator === 'object' ? validator.name : validator;

        if (
          typeof validator === 'object' &&
          !isEmptyInputValue(validator.dependency)
        ) {
          if (
            validator.name === 'match' ||
            validator.name === 'not_match' ||
            validator.name === 'matchCaseInsensitive'
          ) {
            return this.validators[name].validator(validator.dependency, form);
          } else if (validator.name === 'blacklistCharacters') {
            return this.validators[name].validator(
              controlConfiguration,
              validator.dependency
            );
          } else if (
            validator.name === 'dateDoesNotExceedPreviousDate' ||
            validator.name === 'dateDoesNotPreceedPreviousDate'
          ) {
            return this.validators[name].validator(
              validator.dependency,
              validator.useRoot,
              validator.canEqual
            );
          } else {
            return this.validators[name].validator(validator.dependency);
          }
        } else if (typeof validator === 'object' && validator.name === 'max') {
          return this.validators[name].validator(validator.maxNum);
        } else if (typeof validator === 'object' && validator.name === 'min') {
          return this.validators[name].validator(validator.minNum);
        } else if (name === 'requiredIfAvailable') {
          return this.validators[name].validator(controlConfiguration);
        } else if (
          typeof validator === 'object' &&
          validator.name === 'maxInArr'
        ) {
          return this.validators[name].validator(
            form,
            validator.max,
            validator.prop
          );
        } else if (
          typeof validator === 'object' &&
          validator.name === 'lessThan'
        ) {
          return this.validators[name].validator(
            form,
            validator.compareTo,
            validator.useRoot
          );
        } else if (name === 'arrayHasValue') {
          return this.validators[name].validator();
        } else {
          return this.validators[name].validator;
        }
      });

    return validators;
  }

  public getFormControlAsyncValidators(
    controlConfiguration: AllControlsConfiguration
  ): AsyncValidatorFn[] {
    const validators: AsyncValidatorFn[] = (
      controlConfiguration.validators || []
    )
      // eslint-disable-next-line array-callback-return
      .filter((validator) => {
        const name = typeof validator === 'object' ? validator.name : validator;
        if (name === 'async') {
          return this.validators[name];
        }
      })
      .map((validator) => {
        const name = typeof validator === 'object' ? validator.name : validator;

        return this.validators[name].validator(
          validator.dependency,
          name,
          controlConfiguration.prop,
          this.http
        );
      });

    return validators;
  }

  public getFormGroupValidators(
    controlConfiguration: AllControlsConfiguration
  ): ValidatorFn[] {
    const validators: ValidatorFn[] = (
      controlConfiguration.groupValidators || []
    )
      .filter((validator) => this.groupValidators[validator.name])
      .map((validator) =>
        this.groupValidators[validator.name].validator(validator)
      );

    return validators;
  }

  public getFormControlValidator(validatorProp: string): ValidatorFn {
    return this.validators[validatorProp].validator;
  }

  public getValidatorConfig(config, name) {
    const { validators, start_date_validators, end_date_validators } = config;
    const allValidators = [
      ...(validators || []),
      ...(start_date_validators || []),
      ...(end_date_validators || []),
    ];

    return allValidators.find(
      (validator) => validator.name && validator.name === name
    );
  }

  public determineErrorMessage(config, validatorKey, defaultMessage) {
    if (config.message) {
      return config.message;
    } else if (config.errors && config.errors.hasOwnProperty(validatorKey)) {
      return config.errors[validatorKey];
    } else if (this.getValidatorConfig(config, validatorKey)?.message) {
      return this.getValidatorConfig(config, validatorKey).message;
    } else {
      return defaultMessage;
    }
  }

  getFieldName(config): string {
    let fieldName: string = config.label;
    if (!fieldName && config.validatorText) {
      fieldName = config.validatorText;
    } else {
      fieldName = 'This field';
    }

    return fieldName;
  }

  getMessage(config: DatePickerValidatorConfiguration, key: string) {
    const validator = this.getValidatorConfig(config, key);

    return this.determineErrorMessage(
      config,
      key,
      `${validator.date} date must be a valid date.`
    );
  }
}
