import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ButtonModule,
  CardModule,
  FormControlModule,
  FormGroupModule,
} from '@zipari/design-system';
import { RowItemComponent } from './row-item.component';

@NgModule({
  declarations: [RowItemComponent],
  exports: [RowItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    CardModule,
    FormControlModule,
    FormGroupModule,
    ButtonModule,
  ],
})
export class RowItemModule {}
