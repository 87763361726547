import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize',
})
export class FileSizePipe implements PipeTransform {
  transform(size: number, extension = 'MB') {
    const MegaByte = 1024;
    const precision = 2;

    return (size / (MegaByte * MegaByte)).toFixed(precision) + extension;
  }
}
