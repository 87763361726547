import {
  animate,
  animation,
  AnimationTriggerMetadata,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';

const accordionOpen = animation([
  style({ height: 0, opacity: 0 }),
  animate('236ms ease', style({ height: '*', opacity: 1 })),
]);

const accordionClose = animation([
  animate('236ms ease', style({ height: 0, opacity: 0 })),
]);

const accordionXOpen = animation([
  style({ width: 0, opacity: 0 }),
  animate('500ms ease', style({ width: '*', opacity: 1 })),
]);

const accordionXClose = animation([
  animate('500ms ease', style({ width: 0, opacity: 0 })),
]);

export const accordion: AnimationTriggerMetadata = trigger('accordion', [
  transition(':enter', [useAnimation(accordionOpen)]),
  transition(':leave', [useAnimation(accordionClose)]),
]);

export const accordionX: AnimationTriggerMetadata = trigger('accordionX', [
  transition(':enter', [useAnimation(accordionXOpen)]),
  transition(':leave', [useAnimation(accordionXClose)]),
]);
