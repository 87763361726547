import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormControlService } from '../../../../notes/form-control/form-control.service';
import { cloneObject } from '../../../../shared/utils/object';

import {
  buttonConfig,
  childOnlyConfig,
  depConfig,
  individualsCoveredConfig,
  spouseConfig,
  subscriberConfig,
} from './whose-covered.constants';

@Component({
  selector: 'whose-covered',
  templateUrl: './whose-covered.component.html',
  styleUrls: ['./whose-covered.component.scss'],
})
export class WhoseCoveredComponent
  implements OnInit, DoCheck, OnChanges, OnDestroy
{
  @Input() group;
  @Input() config;
  @Input() direction;

  @Output() formCreated = new EventEmitter();

  childOnlyConfig = cloneObject(childOnlyConfig);
  subscriberConfig = cloneObject(subscriberConfig);
  spouseConfig = cloneObject(spouseConfig);
  depConfig = cloneObject(depConfig);
  individualsCoveredConfig = cloneObject(individualsCoveredConfig);
  childOnlySub;
  spouseCoveredSub;
  depCoveredSub;
  dependentVal;
  childOnlyVal;
  whoseCoveredSelected;
  childLimit;
  buttonConfig = buttonConfig;

  prevDisabled;
  patchedValue;

  whoseCoveredGroup$: Subscription;
  whoseCoveredSelect$: Subscription;

  constructor(
    private formControlService: FormControlService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.handleConfigOverrides();

    this.addExtraConfigs();

    this.formControlService.addControlToFormGroup(
      this.whoseCoveredGroup,
      this.individualsCoveredConfig
    );

    this.prefillForm();
    this.handleDisablingComponent();

    this.whoseCoveredGroup$ = this.whoseCoveredGroup.valueChanges.subscribe(
      (allTheValues) => {
        if (this.group['prefillVal']) {
          this.patchedValue = this.group['prefillVal'];
        }
      }
    );

    this.whoseCoveredSelect$ =
      this.whoseCoveredGroup.controls.whose_covered.valueChanges.subscribe(
        (value) => {
          this.prefillForm();
        }
      );
  }

  ngDoCheck() {
    this.handleDisablingComponent();
  }

  ngOnChanges(changes) {
    if (
      'group' in changes &&
      changes.group.currentValue.controls.whoseCovered &&
      changes.group.currentValue.controls.whoseCovered.controls.whose_covered &&
      changes.group.currentValue.controls.whoseCovered.controls.whose_covered
        .value
    ) {
      this.setupForms(
        changes.group.currentValue.controls.whoseCovered.controls.whose_covered
          .value
      );
    }
  }

  ngOnDestroy() {
    if (this.whoseCoveredGroup$) {
      this.whoseCoveredGroup$.unsubscribe();
    }

    if (this.whoseCoveredSelect$) {
      this.whoseCoveredSelect$.unsubscribe();
    }
  }

  handleDisablingComponent() {
    if (this.config.isDisabled !== this.prevDisabled) {
      const newDisabledValue = this.config.isDisabled;

      const configs = [
        this.spouseConfig,
        this.subscriberConfig,
        this.childOnlyConfig,
        this.depConfig,
      ];

      this.individualsCoveredConfig.isDisabled = newDisabledValue;
      configs.map((memberConfig) => {
        memberConfig.controls = memberConfig.controls.map((controlConfig) => {
          controlConfig.isDisabled = newDisabledValue;

          return controlConfig;
        });
      });

      this.prevDisabled = this.config.isDisabled;
    }
  }

  handleConfigOverrides() {
    if (this.config.childLimit) {
      this.childLimit = this.config.childLimit;
    }

    if (this.config.button) {
      this.buttonConfig = this.config.button;
    }

    if (this.config.individualsCoveredOptions) {
      this.individualsCoveredConfig = this.config.individualsCoveredOptions;
    }
  }

  addExtraConfigs() {
    const extraConfigs = this.config.extraConfigs || {};

    if (extraConfigs.all) {
      extraConfigs.all.forEach((config) => {
        this.subscriberConfig.controls.push(cloneObject(config));
        this.spouseConfig.controls.push(cloneObject(config));
        this.depConfig.controls.push(cloneObject(config));
        this.childOnlyConfig.controls.push(cloneObject(config));
      });
    }

    if (extraConfigs.subscriber) {
      extraConfigs.subscriber.forEach((config) => {
        this.subscriberConfig.controls.push(cloneObject(config));
      });
    }

    if (extraConfigs.spouse) {
      extraConfigs.spouse.forEach((config) => {
        this.spouseConfig.controls.push(cloneObject(config));
      });
    }

    if (extraConfigs.dependents) {
      extraConfigs.dependents.forEach((config) => {
        this.depConfig.controls.push(cloneObject(config));
      });
    }

    if (extraConfigs.child_only) {
      extraConfigs.child_only.forEach((config) => {
        this.childOnlyConfig.controls.push(cloneObject(config));
      });
    }
  }

  public get whoseCoveredGroup(): UntypedFormGroup {
    return this.group.controls[this.config.prop];
  }

  public get whoseCoveredValue(): any {
    return this.group.controls[this.config.prop].value;
  }

  public addDependent(persistValue, numToAdd = null) {
    if (!this.whoseCoveredGroup.controls.dependents) {
      this.whoseCoveredGroup.addControl('dependents', new UntypedFormArray([]));
    }

    if (
      this.whoseCoveredGroup.controls.dependents instanceof UntypedFormArray
    ) {
      if (
        persistValue.dependents &&
        persistValue.dependents.length > 0 &&
        numToAdd === null
      ) {
        for (let i = 0; i < persistValue.dependents.length; i++) {
          this.whoseCoveredGroup.controls.dependents.push(
            new UntypedFormGroup({})
          );
        }
      } else {
        this.whoseCoveredGroup.controls.dependents.push(
          new UntypedFormGroup({})
        );
      }

      for (
        let currDepInd = 0;
        currDepInd < this.whoseCoveredGroup.controls.dependents.length;
        currDepInd++
      ) {
        this.depConfig.controls.forEach((config) => {
          if (
            !this.whoseCoveredGroup.controls.dependents['controls'][currDepInd]
              .controls[config.prop]
          ) {
            this.formControlService.addControlToFormGroup(
              this.whoseCoveredGroup.controls.dependents['controls'][
                currDepInd
              ],
              config,
              persistValue.dependents ? persistValue.dependents[currDepInd] : {}
            );
          }
        });
      }
    }
    this.dependentVal = this.whoseCoveredValue.dependents;
  }

  public addChildOnly(persistValue, numToAdd = null) {
    if (!this.whoseCoveredGroup.controls.dependents) {
      this.whoseCoveredGroup.addControl('dependents', new UntypedFormArray([]));
    }

    if (
      this.whoseCoveredGroup.controls.dependents instanceof UntypedFormArray
    ) {
      if (persistValue.dependents && numToAdd === null) {
        for (let i = 0; i < persistValue.dependents.length; i++) {
          this.whoseCoveredGroup.controls.dependents.push(
            new UntypedFormGroup({})
          );
        }
      } else {
        this.whoseCoveredGroup.controls.dependents.push(
          new UntypedFormGroup({})
        );
      }

      for (
        let currDepInd = 0;
        currDepInd < this.whoseCoveredGroup.controls.dependents.length;
        currDepInd++
      ) {
        this.childOnlyConfig.controls.forEach((config) => {
          if (
            !this.whoseCoveredGroup.controls.dependents['controls'][currDepInd]
              .controls[config.prop]
          ) {
            this.formControlService.addControlToFormGroup(
              this.whoseCoveredGroup.controls.dependents['controls'][
                currDepInd
              ],
              config,
              persistValue.dependents ? persistValue.dependents[currDepInd] : {}
            );
          }
        });
      }
    }

    this.dependentVal = this.whoseCoveredValue.dependents;
  }

  public addSubscriber(persistValue) {
    if (!this.whoseCoveredGroup.controls.subscriber) {
      this.whoseCoveredGroup.addControl('subscriber', new UntypedFormGroup({}));
    }

    this.subscriberConfig.controls.forEach((config) => {
      this.formControlService.addControlToFormGroup(
        this.whoseCoveredGroup.controls.subscriber,
        config,
        persistValue.subscriber || {}
      );
    });
  }

  public addSpouse(persistValue) {
    if (!this.whoseCoveredGroup.controls.spouse) {
      this.whoseCoveredGroup.addControl('spouse', new UntypedFormGroup({}));
    }

    this.spouseConfig.controls.forEach((config) => {
      this.formControlService.addControlToFormGroup(
        this.whoseCoveredGroup.controls.spouse,
        config,
        persistValue.spouse || {}
      );
    });
  }

  removeDependent(ind) {
    if (
      this.whoseCoveredGroup.controls.dependents instanceof UntypedFormArray
    ) {
      this.whoseCoveredGroup.controls.dependents.removeAt(ind);
    }

    this.dependentVal = this.whoseCoveredValue.dependents;
  }

  onChange(event) {
    const whosCovered = event.target.value;
    this.setupForms(whosCovered);
    this.whoseCoveredSelected = whosCovered;
    this.dependentVal = this.whoseCoveredValue.dependents;
    this.childOnlyVal = this.whoseCoveredValue.child_only;

    this.cdr.detectChanges();
  }

  prefillForm() {
    if (
      this.whoseCoveredGroup.controls &&
      this.whoseCoveredGroup.controls.whose_covered &&
      this.whoseCoveredGroup.controls.whose_covered.value
    ) {
      this.onChange({
        target: {
          value: this.whoseCoveredGroup.controls.whose_covered.value,
        },
      });
      this.cdr.detectChanges();
    } else {
      this.formCreated.emit();
    }
  }

  setupForms(whosCovered) {
    const persistValue =
      this.patchedValue || cloneObject(this.whoseCoveredValue || {});

    this.formControlService.removeAllControls(this.whoseCoveredGroup, {
      whose_covered: true,
    });
    switch (whosCovered) {
      case 'subscriber':
        this.addSubscriber(persistValue);
        break;
      case 'spouse':
        this.addSubscriber(persistValue);
        this.addSpouse(persistValue);
        break;
      case 'parent':
        this.addSubscriber(persistValue);
        this.addDependent(persistValue);
        break;
      case 'family':
        this.addSubscriber(persistValue);
        this.addSpouse(persistValue);
        this.addDependent(persistValue);
        break;
      case 'child_only':
        this.addChildOnly(persistValue);
        break;
      default:
        break;
    }

    this.patchedValue = null;
  }

  public get individualsCoveredControl(): AbstractControl {
    return this.whoseCoveredGroup.get(individualsCoveredConfig.prop);
  }

  public get subscriberControl(): AbstractControl {
    return this.whoseCoveredGroup.get('subscriber');
  }

  public get spouseControl(): AbstractControl {
    return this.whoseCoveredGroup.get('spouse');
  }

  public get dependentsControl(): AbstractControl {
    return this.whoseCoveredGroup.get('dependents');
  }
}
