import { Component, Input } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { BannerOptions } from '../../authentication.constants';

@Component({
  selector: 'auth-banner',
  templateUrl: './auth-banner-layout.component.html',
  styleUrls: ['./auth-banner-layout.component.scss'],
})
export class AuthBannerLayoutComponent {
  @Input() layoutOptions: BannerOptions;

  constructor(public route: ActivatedRoute) {}
}
