<!-- External Site Warning Modal -->
<modal
  [condition]="isModalOpen"
  [config]="this.getModalConfig(externalSiteModalConfig)"
  (cancel)="closeModal()"
  (keydown.enter)="closeModal()"
  data-test-id="ExternalSiteWarningModal"
>
  <ng-container body>
    <p [innerHTML]="formatModalBody()"></p>
  </ng-container>

  <ng-container footer>
    <zip-button
      [config]="{
        level: 'low',
        content: externalSiteModalConfig.footerCancelButton || 'Cancel'
      }"
      (zipButtonClicked)="closeModal()"
    >
    </zip-button>

    <zip-button
      [config]="{
        level: 'high',
        content: externalSiteModalConfig.footerProceedButton || 'Proceed'
      }"
      [dataNavEvent]="linkConfig"
      (zipButtonClicked)="continueConfirmed($event)"
    >
    </zip-button>
  </ng-container>
</modal>
