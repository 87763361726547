<div [zipBusy]="busy">
  <div class="container">
    <message-banner
      [config]="messageConfig"
      (actionClicked)="confirmToDelete()"
    ></message-banner>
  </div>
</div>
<modal
  *ngIf="confirm"
  [config]="config?.notification?.confirmModal"
  [condition]="confirm"
  (cancel)="confirmToDelete()"
>
  <ng-container body>
    <div>{{ config?.notification?.confirmModal.message }}</div>
    <div class="popup-button">
      <zip-button
        [config]="config?.notification?.confirmModal.cancelButton"
        (zipButtonClicked)="confirmClicked(false)"
      >
      </zip-button>
      <zip-button
        [config]="config?.notification?.confirmModal.confirmButton"
        (zipButtonClicked)="confirmClicked(true)"
      >
      </zip-button>
    </div>
  </ng-container>
</modal>

<modal
  #errorFlyout
  *ngIf="error"
  [config]="config?.errorModal"
  [condition]="error"
  (cancel)="toggleError()"
>
  <div body>{{ config?.errorModal.message }}</div>
</modal>
