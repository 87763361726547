import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Status, StatusType } from '@zipari/shared-ds-util-status';
import { enumToArray } from '../../shared/utils/object';

@Component({
  selector: 'status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  @Input() config: Status;
  @Input() status: StatusType;
  @Input() isMobileView = false;
  @Output() emitStatus = new EventEmitter<StatusType>(true);

  statusType = StatusType;

  ngOnInit() {
    if (!this.status) {
      if (this.config.matchColor) {
        this.getStatusType(this.config.matchColor); // Allows using a different value for label and color matching
      } else {
        this.getStatusType(this.config.label);
      }
      this.emitStatus.emit(this.status);
    }
  }

  getStatusType(label: string) {
    if (this.config.statuses) {
      const types = enumToArray(this.statusType);

      types.forEach((type) => {
        const currentStatus = this.config.statuses[type.id];
        if (!currentStatus) return;

        const listOfValues = currentStatus.map((status) => status['value']);
        const listOfLabels = currentStatus.map((status) => status['label']);

        if (
          (Array.isArray(currentStatus) &&
            (listOfValues.includes(label) || listOfLabels.includes(label))) ||
          currentStatus.name?.includes(label)
        ) {
          this.status = StatusType[type.name];
        }
      });
    } else {
      this.status = StatusType.EXPIRED;
    }
  }
}
