import { Component, Input } from '@angular/core';
import { RadioConfiguration } from '@zipari/shared-ds-util-form';

@Component({
  selector: 'control-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class ControlRadioComponent {
  @Input() config: RadioConfiguration;
  @Input() control: any;
  @Input() group: any;

  isArray(value: string | unknown[]): boolean {
    return Array.isArray(value);
  }
}
