export const editAuthConstants = {
  controlType: 'service_type',
  cardType: 'dynamicCard',
  defaultErrorMessages: 'sorry something went wrong.',
  diagnosis_codes: 'diagnosis_codes',
};

export const EditAuthCardsProp = {
  requestingProviderId: 'requesting_provider_id',
  servicingProvider: 'servicing_provider',
  servicingFacilityId: 'servicing_facility_id',
  servicingFacilityLocation: 'servicing_facility_location',
  servicingFacility: 'servicing_facility',
};

export const uploadDocument = {
  uploadDocumentType: 'document_type',
  documentIds: 'document_ids',
};
