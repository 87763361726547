import { Component, Input } from '@angular/core';
import { WindowService } from '@zipari/web-services';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { CX_CALLS } from '../../../../shared/constants/cx-calls.constant';
import { IdCard } from '../../../../shared/models/shared/IdCard.model';

@Component({
  selector: 'id-card-view',
  templateUrl: './id-card-view.component.html',
  styleUrls: ['./id-card-view.component.scss'],
})
export class IdCardViewComponent {
  @Input() documentApiEndpoint;
  @Input() isError;
  @Input() idCards: IdCard[];
  @Input() section;

  showModal = false;
  currentIdCard: IdCard;

  constructor(
    private windowService: WindowService,
    public analyticsService: AnalyticsService
  ) {}

  print(idCard: IdCard): void {
    if (this.section.printButton?.cx_event_key) {
      this.analyticsService.sendEvent(
        CX_CALLS[this.section.printButton.cx_event_key].event_key
      );
    }
    this.windowService.nativeWindow.open(
      `${this.documentApiEndpoint}${idCard.full_doc_id}`,
      '_blank'
    );
  }

  downloadHref(idCard: IdCard) {
    if (this.section.downloadButton?.cx_event_key) {
      this.analyticsService.sendEvent(
        CX_CALLS[this.section.downloadButton.cx_event_key].event_key
      );
    }
    this.windowService.nativeWindow.open(
      `${this.documentApiEndpoint}${idCard.full_doc_id}/?download=true`,
      '_self'
    );
  }

  enlarge(idCard: IdCard) {
    this.currentIdCard = idCard;
    this.scrollToTop();
    this.showModal = true;
  }

  handleModalClose() {
    this.showModal = !this.showModal;
  }

  scrollToTop() {
    const time = 16;
    const position = 20;
    const scrollToTop = window.setInterval(() => {
      const pos: number = window.pageYOffset;

      if (pos > 0) window.scrollTo(0, pos - position);
      else window.clearInterval(scrollToTop);
    }, time);
  }
}
