export function fullName(entity: any, lastNameFirst = false): string {
  let firstName = '';
  let lastName = '';
  let suffix = '';

  if (!entity) {
    return '';
  }
  if (entity.name) {
    if (entity.name.first || entity.name.first_name || entity.name.firstName) {
      firstName =
        entity.name.first || entity.name.first_name || entity.name.firstName;
    }
    if (entity.name.last || entity.name.last_name || entity.name.lastName) {
      lastName =
        entity.name.last || entity.name.last_name || entity.name.lastName;
    }
    if (entity.name.suffix) {
      suffix = entity.name.suffix;
    }
  }
  if (entity.first_name || entity.firstName) {
    firstName = entity.first_name || entity.firstName;
  }
  if (entity.last_name || entity.lastName) {
    lastName = entity.last_name || entity.lastName;
  }
  if (entity.suffix) {
    suffix = entity.suffix;
  }

  if (lastNameFirst && firstName && lastName) {
    return suffix
      ? `${lastName}, ${firstName} ${suffix}`
      : `${lastName}, ${firstName}`;
  }

  if (suffix) {
    return `${firstName} ${lastName} ${suffix}`;
  }

  return `${firstName} ${lastName}`;
}
