import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AccordionModule,
  BadgeModule,
  ButtonModule,
  LinkListModule,
  ModelAttributeModule,
  ModelAttributeSectionsModule,
  SidePanelModule,
  TabsModule,
  ZipBusyModule,
  ModalModule,
} from '@zipari/design-system';

import { TableModule } from '../../../shared/modules';
import { DetailTabsModule } from '../../shared/modules/detail-tabs/detail-tabs.module';

import { ClaimAppealDetailModule } from '../claim-appeal-detail/claim-appeal-detail.module';
import { ClaimReconsiderationModule } from '../claim-appeal/claim-reconsideration/claim-reconsideration.module';
import { ClaimDeterminationModule } from '../claim-appeal/claim-redetermination/claim-redetermination.module';
import { ClaimDetailRoutingModule } from './claim-detail-routing.module';
import { ClaimDetailComponent } from './claim-detail.component';
import { ClaimLinesModule } from './claim-lines/claim-lines.module';
import { FinancialOverviewModule } from './financial-overview/financial-overview.module';
import { OverviewModule } from './overview/overview.module';

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    RouterModule,
    AccordionModule,
    TabsModule,
    ModelAttributeModule,
    ButtonModule,
    LinkListModule,
    BadgeModule,
    DetailTabsModule,
    ZipBusyModule,
    SidePanelModule,
    ModelAttributeSectionsModule,
    ClaimDetailRoutingModule,
    OverviewModule,
    FinancialOverviewModule,
    ClaimLinesModule,
    ModalModule,
    ClaimAppealDetailModule,
    ClaimReconsiderationModule,
    ClaimDeterminationModule,
  ],
  exports: [ClaimDetailComponent],
  declarations: [ClaimDetailComponent],
  providers: [],
})
export class ClaimDetailModule {}
