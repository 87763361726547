import { Pipe, PipeTransform } from '@angular/core';

/* check if string has more than 60 characters. If it does return options.showAll else return the text on one line  */

@Pipe({
  name: 'readMore',
})
export class ReadMorePipe implements PipeTransform {
  transform(
    text: string,
    options = {
      maxCharacters: 60,
      showAll: false,
    }
  ): string {
    return !options.showAll
      ? text.substring(0, options.maxCharacters)
      : `${text}&nbsp;`;
  }
}
