<ng-container *ngIf="control && config">
  <input
    [attr.aria-label]="config.ariaLabel"
    [attr.placeholder]="config.placeholder ? config.placeholder : null"
    [attr.readonly]="config?.isDisabled ? config?.isDisabled : null"
    [attr.type]="config.type"
    [id]="config.id || config.prop"
    class="form-control"
    ngDefaultControl
    [readOnly]="config.readonly"
    [name]="config.prop || config.name"
    [formControl]="control"
  />
</ng-container>
