import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  getPaginatorConfig,
  PaginatorConfiguration,
} from '@zipari/design-system';
import { Subscription } from 'rxjs';
import { SquidexConfig } from '@zipari/shared-data-access-squidex';
import { MessageBannerConfig } from '@zipari/shared-ds-util-messages';
import { httpErrorToMessageBanner } from '@zipari/web-services';
import { firstValueFrom } from 'rxjs';
import { DocumentCenterDataService } from '../../services/document-center-data.service';
import { DocumentCenterEventService } from '../../services/document-center-events.service';
import { DocumentCenterSquidexDataService } from '../../services/document-center-squidex-data.service';
import { downloadDocument } from '../../services/document-center.helper';
import { DocumentCenterStateService } from '../../services/document-center-state.service';
import { DocumentEventType } from '../../util/document-center.constants';
import {
  APIResponse,
  DocumentCenterConfig,
  DocumentCenterState,
  DocumentCenterTab,
  UserDetails,
} from '../../util/document-center.model';
import { cloneObject } from '@zipari/web-utils';
import { DatagridAction } from '@zipari/shared-ds-util-datagrid';

@Component({
  selector: 'zipari-tab-content',
  templateUrl: './tab-content.component.html',
  styleUrls: ['./tab-content.component.scss'],
})
export class TabContentComponent implements OnInit, OnDestroy {
  documentCenterConfig!: DocumentCenterConfig;
  squidexConfig!: SquidexConfig;
  activeTab!: DocumentCenterTab;
  data: Record<string, unknown>[] = [];
  pageSize = 10;
  errorMessage: MessageBannerConfig | null = null;
  showErrorMessage = false;
  paginator: PaginatorConfiguration | null = null;
  currentPage = 1;
  filterFormValue: Record<string, unknown> | null = null;
  sortColumn = '';
  documentCenterDataLoading = false;
  userDetails: UserDetails | null = null;
  stateSubscription!: Subscription;

  constructor(
    public router: Router,
    private documentCenterStateService: DocumentCenterStateService,
    private documentCenterDataService: DocumentCenterDataService,
    private SquidexDataService: DocumentCenterSquidexDataService,
    private documentCenterEventService: DocumentCenterEventService
  ) {}

  ngOnInit(): void {
    this.stateSubscription = this.documentCenterStateService
      .getState()
      .subscribe((state: DocumentCenterState) => {
        this.setActiveTabAndConfig(state);
      });
  }

  setActiveTabAndConfig(state): void {
    this.data = [];
    this.filterFormValue = null;
    this.currentPage = 1;
    this.sortColumn = '';
    this.showErrorMessage = false;
    if (state.activeTab) {
      this.activeTab = cloneObject(state.activeTab);
    }
    if (state.documentCenterConfig) {
      this.documentCenterConfig = state.documentCenterConfig;
      this.pageSize = this.documentCenterConfig.paginator
        ? this.documentCenterConfig.paginator
        : this.pageSize;
      this.paginator = getPaginatorConfig(
        {},
        this.pageSize,
        this.currentPage,
        0
      );
    }
    if (state.userDetails) {
      this.userDetails = state.userDetails;
    }
    this.squidexConfig = state.squidexConfig;
    this.getDocumentCenterData();
  }

  getDocumentCenterData(): void {
    if (this.activeTab.apiEndPoint || this.activeTab.squidexDocumentsEndPoint) {
      this.getDocuments();
    } else if (
      this.squidexConfig?.enabled &&
      this.squidexConfig?.enableForModule?.resourcesFormsDocuments &&
      this.activeTab.squidexContentEndPoint
    ) {
      this.getSquidexContent();
    }
  }

  async getSquidexContent(): Promise<void> {
    this.documentCenterDataLoading = true;
    try {
      if (this.activeTab.squidexContentEndPoint) {
        const content = await firstValueFrom(
          this.SquidexDataService.getSquidexContent(this.activeTab)
        );
        if (this.activeTab.accordionLayout) {
          this.activeTab.accordionLayout = content.items?.length
            ? content.items[0].data
            : null;
        }
        this.documentCenterDataLoading = false;
        this.showErrorMessage = false;
      }
    } catch (error) {
      this.handleError(error);
      if (this.activeTab.accordionLayout) {
        this.activeTab.accordionLayout = null;
      }
    }
  }

  handleError(error: any): void {
    this.documentCenterDataLoading = false;
    if (error instanceof HttpErrorResponse) {
      this.errorMessage = httpErrorToMessageBanner(error);
      this.showErrorMessage = true;
    }
  }

  async getDocuments(): Promise<void> {
    this.documentCenterDataLoading = true;
    try {
      if (this.paginator) {
        this.paginator = { ...this.paginator, currentPage: this.currentPage };
        const documentsResponse: APIResponse = await firstValueFrom(
          this.documentCenterDataService.getDocumentsData(
            this.activeTab,
            this.squidexConfig,
            this.paginator,
            this.filterFormValue,
            this.userDetails,
            this.sortColumn
          )
        );
        this.documentCenterDataLoading = false;
        this.showErrorMessage = false;
        this.data = documentsResponse.results;
        const totalRecords = documentsResponse.count;

        this.paginator = getPaginatorConfig(
          {},
          this.pageSize,
          this.currentPage,
          totalRecords
        );
      }
    } catch (error) {
      this.data = [];
      this.handleError(error);
    }
  }

  setCurrentPage(page: number): void {
    this.currentPage = page;
    this.getDocuments();
  }

  filterDocuments(filterFormValue: Record<string, unknown>): void {
    this.filterFormValue = filterFormValue;
    this.currentPage = 1;
    this.documentCenterEventService.captureEvent({
      type: DocumentEventType.filtersApplied,
      filters: filterFormValue,
    });
    this.getDocuments();
  }

  downloadAction(target: DatagridAction): void {
    downloadDocument(this.squidexConfig, this.activeTab, target.row);
    this.documentCenterEventService.captureEvent({
      type: DocumentEventType.documentViewed,
      documentDetails: target.row,
    });
  }

  documentSelected(target: Record<string, unknown>): void {
    downloadDocument(this.squidexConfig, this.activeTab, target);
    this.documentCenterEventService.captureEvent({
      type: DocumentEventType.documentViewed,
      documentDetails: target,
    });
  }

  sortDocuments(columnProp: string): void {
    this.currentPage = 1;
    this.sortColumn = this.handleOrderingParam(columnProp);
    this.getDocuments();
  }

  handleOrderingParam(prop: string): string {
    if (prop && prop === this.sortColumn) {
      return prop.startsWith('-') ? prop : `-${prop}`;
    } else return prop;
  }

  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }
}
