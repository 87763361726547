import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ErrorEmptyComponent } from './error-empty.component';

@NgModule({
  declarations: [ErrorEmptyComponent],
  imports: [CommonModule],
  exports: [ErrorEmptyComponent],
})
export class ErrorEmptyModule {}
