import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'rich-text',
  templateUrl: './rich-text.component.html',
  styleUrls: [
    '../../../../notes/form-control/form-control.component.scss',
    './rich-text.component.scss',
  ],
  encapsulation: ViewEncapsulation.None,
})
export class RichTextComponent implements ControlValueAccessor, OnDestroy {
  @Input() group: UntypedFormGroup;
  @Input() config;
  @Input() direction;
  public destroy = new Subject();

  public value: string;

  propagateChange = function (_: any) {
    return;
  };

  onTouched = function () {
    return;
  };

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  onSelectionChanged(data) {
    this.group.valueChanges.pipe(takeUntil(this.destroy)).subscribe(data);
  }

  onContentChanged(data) {
    this.group.valueChanges.pipe(takeUntil(this.destroy)).subscribe(data);
  }

  ngOnDestroy() {
    this.destroy.next(void 0);
    this.destroy.complete();
  }
}
