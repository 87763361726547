<div
  class="resources"
  [ngClass]="{
    'resources--horizontal': config.isHorizontal,
    'resources--vertical': !config.isHorizontal
  }"
  *ngIf="config.resources"
>
  <div class="resource" *ngFor="let resource of config.resources.resources">
    <icon class="resource__icon" [name]="resource.icon"> </icon>

    <p
      class="t-alt resource__description"
      [innerHTML]="resource.description"
    ></p>

    <footer class="resource__footer u-flex u-justifyEnd">
      <ng-template
        [ngTemplateOutlet]="link"
        [ngTemplateOutletContext]="{ resource: resource }"
      ></ng-template>
    </footer>
  </div>
</div>

<ng-container *ngIf="config.resources">
  <card
    class="resources__card"
    *ngFor="let resource of config.resources.resources"
  >
    <div title class="resources__card-title">
      <icon [name]="resource.icon"></icon>
      <p
        class="resources__card-description"
        [innerHTML]="resource.description"
      ></p>
    </div>
    <div footer class="resources__card-footer u-flex u-justifyEnd">
      <div class="u-justifyEnd">
        <ng-template
          [ngTemplateOutlet]="link"
          [ngTemplateOutletContext]="{ resource: resource }"
        ></ng-template>
      </div>
    </div>
  </card>
</ng-container>

<ng-template #link let-resource="resource">
  <div class="resources__footer--link">
    <ds-link
      [linkConfig]="getLinkConfigs(resource)"
      [externalSiteModalConfig]="externalSiteModalConfig"
      (linkClicked)="handleLinkClicked(resource)"
    >
      <span class="resource__label">
        {{ resource.label }}
      </span>
    </ds-link>
  </div>
</ng-template>
