import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  ButtonModule,
  FormControlModule,
  TableModule,
} from '@zipari/design-system';

// replace `NewComponentBoilerplateComponent` with your component name
import { ManageAccessRequestsComponent } from './manage-access-requests.component';
@NgModule({
  declarations: [ManageAccessRequestsComponent],
  exports: [ManageAccessRequestsComponent],
  imports: [
    RouterModule,
    ButtonModule,
    TableModule,
    FormControlModule,
    CommonModule,
  ],
})
export class ManageAccessRequestsModule {}
