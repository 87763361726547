import { UntypedFormGroup } from '@angular/forms';

export enum customFormElements {
  address = 'address',
  autopay = 'autopay',
  basicAddress = 'basicAddress',
  companyAddress = 'companyAddress',
  brandColor = 'brandColor',
  checkboxMatrix = 'checkboxMatrix',
  dropdownOther = 'dropdownOther',
  formArray = 'formArray',
  formGroupArray = 'formGroupArray',
  hidden = 'hidden',
  industryGroup = 'industryGroup',
  inputNA = 'inputNA',
  multiAddress = 'multiAddress',
  officeHours = 'officeHours',
  optionalDate = 'optionalDate',
  passwords = 'passwords',
  richText = 'richText',
  productIcon = 'productIcon',
  ssnMultiField = 'ssnMultiField',
  systemIcon = 'systemIcon',
  termsAndConditions = 'termsAndConditions',
  speechRecognition = 'speechRecognition',
  whoseCovered = 'whoseCovered',
}

export interface CustomComponent {
  config?: any;
  form?: UntypedFormGroup;
  formCreated?: any;
}
