import { Injectable } from '@angular/core';
import { DatagridColumn } from '@zipari/shared-ds-util-datagrid';
import { EventInfo } from '@zipari/shared-ds-util-digital-data';

@Injectable({
  providedIn: 'root',
})
export class DatagridDigitalDataService {
  getDataLoadSortEvent(column): EventInfo {
    return {
      effect: `Sort by ${column.label}`,
      eventAction: 'sortByColumn',
      eventName: 'Sort Data Grid By Column',
      eventPoints: 200,
      timeStamp: new Date(),
      type: 'sort',
    };
  }

  getDataLoadDownloadEvent(column: DatagridColumn): EventInfo {
    if (column.digitalData) {
      return {
        ...column.digitalData,
        eventPoints: 200,
        timeStamp: new Date(),
      };
    }

    return {
      effect: `${column.label}`,
      eventAction: 'downloadData',
      eventName: 'Download Data',
      eventPoints: 200,
      timeStamp: new Date(),
      type: 'download',
    };
  }
}
