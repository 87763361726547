import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule, TableModule } from '@zipari/design-system';

import { ClaimLinesComponent } from './claim-lines.component';

@NgModule({
  declarations: [ClaimLinesComponent],
  exports: [ClaimLinesComponent],
  imports: [CommonModule, TableModule, ButtonModule],
})
export class ClaimLinesModule {}
