import { Component, EventEmitter, Output } from '@angular/core';
import SuspendUserEventService from '../../services/suspend-user-event.service';
import SuspendUserService from '../../services/suspend-user.service';
import ActivateUserService from '../../services/activate-user.service';
@Component({
  selector: 'footer-dynamic-button',
  templateUrl: './footer-dynamic-button.component.html',
})
export class FooterDynamicButtonComponent {
  @Output() handleNextStep: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public suspendUserEventService: SuspendUserEventService,
    public suspendUserService: SuspendUserService,
    public activateUserService: ActivateUserService
  ) {}

  navigateToNextStep(): void {
    this.handleNextStep.emit();
  }
}
