<div class="provisioner">
  <div class="column-header" role="heading" aria-level="1">
    {{ config?.header }}
  </div>
  <div class="column-subheader">
    {{ config?.subheader }}
  </div>
  <div class="column-container">
    <div
      class="provisioner-column"
      *ngFor="let column of columns; let i = index"
    >
      <provisioner-column
        #columns
        id="provisionerColumn"
        [column]="i"
        [config]="column"
        [bootStrapId]="column.bootStrapId"
        (cellClicked)="cellClicked($event)"
        (cellSearched)="cellSearched($event)"
        (cellSelected)="cellSelected($event)"
      >
      </provisioner-column>
    </div>
  </div>
</div>
