import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { cloneObject, deepCompare } from '../../../../shared/utils/object';
import { FilterOptionsConfig } from '../../models';
import { SetFilterValues } from '../zip-table.constants';
import { FormControlValidatorsService } from './../../../../notes/form-control/shared/validators/validators.service';
import { TableFilter } from './table-filter.models';

const defaultDebounceTimeForFilterRequests = 250;
@Component({
  selector: 'table-filters',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss'],
})
export class TableFilterComponent implements OnChanges {
  tableFilters;
  formGroup: UntypedFormGroup;
  formGroupSubscription: Subscription;
  prevFilters;
  filterSub: Subscription;
  setupFilter = new Subject<TableFilter[]>();
  debounceTime = defaultDebounceTimeForFilterRequests;
  filtersSame = true;
  hasDefaultDropdownSelection = false;
  newFilters: TableFilter[];
  firstFilterLoad = true;

  @Input() filters: TableFilter[];
  @Input() controlType: string;
  @Input() filterOptions: FilterOptionsConfig;
  @Input() setFilterValues: SetFilterValues;
  @Output() value: EventEmitter<TableFilter[]> = new EventEmitter<
    TableFilter[]
  >();
  @Output() customMethod: EventEmitter<any> = new EventEmitter();
  modalOpen = false;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // when enter is clicked submit the form as a filter
    const enterKeyCode = 13;
    if (event.which === enterKeyCode) {
      this.cleanAndSubmitForm();
      this.submitForm();
    }
  }

  constructor(
    private formControlValidatorsService: FormControlValidatorsService
  ) {}

  ngOnChanges(changes) {
    // checks to see if the filters have actually changed or if we have just added the 'value' onto the object
    // if things have changed then setup the filters to work properly
    // IMPORTANT: make sure that we use the clone object function on the previous filters otherwise funky stuff is
    // going to happen

    this.filterSub = this.setupFilter
      .pipe(debounceTime(this.debounceTime))
      .subscribe((newFilters: TableFilter[]) => {
        this.newFilters = newFilters;
        if (!this.filterOptions || !this.filterOptions.searchOnSubmit) {
          if (!this.firstFilterLoad) {
            this.customEventMethod({ event: this.newFilters, context: this });
          } else {
            this.firstFilterLoad = false;
          }
          this.value.emit(newFilters);
        }
      });

    if ('filters' in changes && changes.filters.currentValue) {
      if (
        !this.prevFilters ||
        (this.prevFilters &&
          !deepCompare(this.prevFilters, changes.filters.currentValue, {
            value: true,
          }))
      ) {
        this.prevFilters = changes.filters.currentValue;
        this.setupFilters(cloneObject(this.prevFilters));
        if (this.hasDefaultDropdownSelection) {
          this.cleanAndSubmitForm();
        }
      }
    }

    if ('setFilterValues' in changes && changes.setFilterValues.currentValue) {
      this.applyFilterValues(changes.setFilterValues.currentValue);
    }

    const valueChangesDebounceTime = 500;
    this.formGroupSubscription = this.formGroup.valueChanges
      .pipe(debounceTime(valueChangesDebounceTime))
      .subscribe(() => {
        this.cleanAndSubmitForm();
      });
  }

  customEventMethod({ event: event, context: context }) {
    // if a `customMethod` has been set on the component, emit event on that method.
    if (this.customMethod.observers.length) {
      // passing `event`and component `context` so can access component data in customMethod.
      this.customMethod.emit({ event: event, context: context });
    }
  }

  private updateTableFiltersControl(): void {
    this.tableFilters.map((filter) => {
      filter.control = this.formGroup.get(filter.prop);
    });
  }

  setupFilters(filters) {
    this.formGroup = new UntypedFormGroup({});
    this.tableFilters = filters.map(this.createZipFormControl.bind(this));

    this.filtersSame = filters.every(
      (filter) => filter.prop === filters[0].prop
    );
  }

  cleanAndSubmitForm() {
    // when we create a new user, the newFilter[0].value is set to `null`, because the search was empty on creation
    // so when we call for the list of clients because search is `null` we end up with a call to clients that has null QP
    // this in turn returns an empty list. filtering out falsy values to prevent this issue.

    // update table filters control with form group controls
    this.updateTableFiltersControl();

    const newFilters = this.tableFilters
      .filter((filter) => !!filter.control.value)
      .map((filter) => {
        const controlValue = filter.control.value;
        const encodedValue = filter.encodeURI
          ? encodeURIComponent(controlValue)
          : controlValue;

        // add only non empty key-value pairs
        filter.value =
          typeof controlValue === 'object'
            ? Object.fromEntries(
                Object.entries(controlValue).filter(([key, value]) => !!value)
              )
            : encodedValue;

        return filter;
      });

    this.setupFilter.next(newFilters);
  }

  createZipFormControl(config) {
    // special backwards compatible things
    if (config.type === 'searchField') {
      config.type = 'text';
    }
    if (config.type === 'checkbox' || config.type === 'checkbox') {
      config.type = 'boolean';
    }
    if (
      (config.type === 'select' || config.type === 'select') &&
      config.options
    ) {
      config.type = 'dropdown';
      config.options = config.options.map((option) => {
        option['title'] = option.name;

        return option;
      });
    }
    const control = new UntypedFormControl('', []);

    this.setUpValidators(config, control);

    const newZipFormControl = Object.assign(config, {
      prop: config.key || config.prop,
      key: config.key || config.prop,
      label: config.label || config.text,
      controlNameFull: true,
      control: control,
    });
    if (config.type === 'dropdown' && config.default) {
      this.hasDefaultDropdownSelection = true;
      control.patchValue(config.default);
      newZipFormControl.value = config.default;
    }

    newZipFormControl.controlProp =
      config.id || newZipFormControl.key.split('.').pop();
    this.formGroup.addControl(newZipFormControl.controlProp, control);

    return newZipFormControl;
  }

  setUpValidators(config, control: UntypedFormControl) {
    if (config.validators?.length > 0) {
      const allValidators = {
        ...this.formControlValidatorsService.validators,
        ...this.formControlValidatorsService.groupValidators,
      };
      const controlValidators = [];

      config.validators.forEach((validatorKey: string) => {
        controlValidators.push(allValidators[validatorKey].validator);
      });
      control.setValidators(controlValidators);
    }
  }

  /** Set filter form field values */
  applyFilterValues(setFilterValues: SetFilterValues) {
    // Clear current filter values
    if (setFilterValues.clearAll) this.formGroup.reset();

    const newFilters = this.tableFilters.map((filter) => {
      const values = setFilterValues.values;
      const id = filter.id;
      if (values && id && values[id] !== undefined) {
        if (this.formGroup.get(filter.controlProp)) {
          this.formGroup.get(filter.controlProp).patchValue(values[id]);
        }
      }

      return filter;
    });

    this.setupFilter.next(newFilters);
  }

  resetFilters() {
    this.formGroup.reset();

    this.cleanAndSubmitForm();
  }

  onButtonClick(target: any): void {
    switch (target.action) {
      case 'submit':
        this.submitForm();
        break;
      case 'clear':
        this.clearForm();
        break;
      default:
        break;
    }
  }

  submitForm(): void {
    this.customEventMethod({ event: this.newFilters, context: this });
    this.value.emit(this.newFilters);
  }

  clearForm(): void {
    this.formGroup.reset();
  }

  modalToggle() {
    this.modalOpen = !this.modalOpen;
  }

  onModalClick(target: any = null): void {
    if (target && target.action) {
      this.onButtonClick(target);
    }
    this.modalOpen = false;
  }

  getCSSClasses(filter) {
    return {
      [filter.customCSSClass]: filter.customCSSClass,
      ['table__filter--small']: filter.size && filter.size === 'small',
      ['table__filter--medium']:
        (filter.size && filter.size === 'medium') || !filter.size,
      ['table__filter--large']: filter.size && filter.size === 'large',
    };
  }
}
