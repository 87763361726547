<aside class="Sidebar">
  <ng-container
    *ngIf="sidePanelInfo$ | async as sidePanelInfo; else sidePanelLoading"
  >
    <ng-container *ngFor="let sideBarSection of sideBarSections">
      <div
        class="Sidebar__Section"
        *ngIf="showSidePanelSection(sideBarSection)"
      >
        <header class="Sidebar__Header">
          <h6
            role="heading"
            aria-level="1"
            [innerHTML]="sideBarSection?.label"
          ></h6>
        </header>
        <section class="Sidebar__Body">
          <div *ngFor="let attribute of sideBarSection.attributes">
            <model-attribute
              class="Sidebar__Attribute"
              [context]="sidePanelInfo"
              [config]="attribute"
              direction="column"
            ></model-attribute>
          </div>
          <div
            *ngIf="
              sideBarSection.format === 'link-list' &&
              sideBarSection.links.length > 0
            "
          >
            <link-list
              [dataList]="sideBarSection.links"
              (clicked)="linkClicked($event)"
            ></link-list>
          </div>
        </section>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #sidePanelLoading>
    <spinner [asOverlay]="false" size="small" flexCenter="true"></spinner>
  </ng-template>
</aside>
