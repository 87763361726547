<div class="SiteLocations" *ngIf="config">
  <div class="SiteLocations__Header">
    <h4 class="t-bold" [innerHTML]="config.title"></h4>
    <p class="SiteLocations__SubHeader" [innerHTML]="config.subTitle"></p>
  </div>
  <div class="SiteLocations__FiltersContainer">
    <form-group
      [form]="filterForm"
      [configs]="config.filters?.controls"
      class="SiteLocations__FiltersContainer__Filters"
    ></form-group>
    <div class="SiteLocations__FiltersContainer__FiltersActions">
      <zip-button [config]="config.searchButton" (zipButtonClicked)="search()">
      </zip-button>
    </div>
  </div>
  <div class="SiteLocations__CardContainer" *ngIf="!showError && !loading">
    <div class="SiteLocations__CardContainer__Header">
      <div>
        <icon
          [name]="selectIcon"
          [config]="{
            type: 'pointer',
            fill: selectedRows.length > 0 ? 'primary' : 'text--1'
          }"
          (click)="toggleAllRows()"
        >
        </icon>
      </div>
      <div>
        <p class="t-bold" [innerHTML]="cardsListHeader"></p>
      </div>
    </div>
    <div>
      <ng-container *ngFor="let location of locations?.results">
        <card
          class="cardholder card item skinny-badge"
          [config]="{ hideSubHeader: true }"
          class="SiteLocations__CardContainer__Card"
        >
          <div body class="SiteLocations__CardContainer__CardBody">
            <div class="SiteLocations__CardContainer__Checkbox">
              <icon
                tabindex="0"
                [name]="
                  isRowSelected(location) ? 'checkbox_active' : 'checkbox'
                "
                [config]="{
                  type: 'pointer',
                  fill: isRowSelected(location) ? 'primary' : 'initial'
                }"
                (click)="toggleRow(location)"
                (keydown.space)="toggleRow(location)"
              >
              </icon>
            </div>
            <div class="SiteLocations__CardContainer__Content">
              <div><p class="t-bold" [innerHtml]="location.name"></p></div>
              <div class="SiteLocations__CardContainer__Details">
                <ng-container
                  *ngFor="
                    let attribute of config.locationCard?.details?.attributes
                  "
                >
                  <model-attribute
                    [context]="location"
                    [config]="attribute"
                    direction="column"
                  ></model-attribute>
                </ng-container>
              </div>
            </div>
            <div class="SiteLocations__CardContainer__Review">
              <a
                class="t-link t-bold t-data"
                [routerLink]="location.id"
                [innerHTML]="config.locationCard?.reviewLabel"
              >
              </a>
            </div>
          </div>
        </card>
      </ng-container>
    </div>
    <div *ngIf="paginator?.totalPages > 1 && locations?.results.length > 0">
      <paginator (pageSelected)="setCurrentPage($event)" [config]="paginator">
      </paginator>
    </div>
  </div>
  <div *ngIf="showError && !loading" class="SiteLocations__ErrorContainer">
    <p [innerHTML]="errorMessage"></p>
  </div>
  <spinner *ngIf="loading" [asOverlay]="true" size="medium"></spinner>
</div>
