import { Condition } from '@zipari/shared-ds-util-form';

export enum CurrencyDisplay {
  'code' = 'code',
  'symbol' = 'symbol',
  'symbol-narrow' = 'symbol-narrow',
}

export enum DateDisplay {
  'short' = 'short',
  'medium' = 'medium',
  'long' = 'long',
  'full' = 'full',
  'shortDate' = 'shortDate',
  'mediumDate' = 'mediumDate',
  'longDate' = 'longDate',
  'fullDate' = 'fullDate',
}

export enum columnFormats {
  SUCCESS_ERROR = 'SUCCESS_ERROR',
  LINK = 'LINK',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  LIST = 'LIST',
  ACTIONS = 'ACTIONS',
  FILTERED_ACTIONS = 'FILTERED_ACTIONS',
  CONDITIONAL_ACTION = 'CONDITIONAL_ACTION',
  STATUS = 'STATUS',
  RADIO = 'RADIO',
}

export class LinkConfiguration {
  link?: any;
  noLink?: boolean;
}

export class CellTypeLinkFormatOption extends LinkConfiguration {
  routeInternal: boolean;
  route: string;
}

export class CellTypeFullNameFormatOption extends LinkConfiguration {
  nameTemplate?: string;
}

export class CellTypeAddressFormatOption extends LinkConfiguration {
  addressTemplate?: string;
}

export class CellTypeDateCurrencyFormatOption extends LinkConfiguration {
  currencyCode?: string;
  display: CurrencyDisplay | string | boolean = 'symbol';
  digitsInfo?: string;
  locale?: string;
}

export class CellTypeDateFormatOption extends LinkConfiguration {
  format: DateDisplay = DateDisplay.mediumDate;
}

export class ConditionalAction {
  icon: string;
  displayText?: string;
  conditionProp: string;
  conditionValues: string[];
}

export interface CellClickEvent {
  action?: ConditionalAction;
  context?: {
    col?: ColumnConfig;
    row?: Record<string, string>;
    val?: string;
  };
}

export interface ColumnClickEvent {
  type: string;
  options: ColumnClickEventFilterOptions;
}

interface ColumnClickEventFilterOptions {
  id: string;
  clearAll: boolean;
}

export class ColumnConfig {
  accessibilityMessage?: string;
  format?: columnFormats;
  formatOptions?:
    | CellTypeFullNameFormatOption
    | CellTypeAddressFormatOption
    | CellTypeDateCurrencyFormatOption
    | CellTypeDateFormatOption
    | CellTypeLinkFormatOption;

  /**
   * Conditions used for conditional formats (conditionalCellTypes)
   */
  conditions?: Condition;

  /**
   * String property that points to the data object that we are using for this column
   * can be deeply nested into the object with . delimination
   *
   * e.g:
   * prop value to see propValue2
   * {prop: {sub_prop: [propValue1, propValue2]}}
   * prop: 'prop.sub_prop.1'
   */
  prop: string;

  /** String property that provides a static value that all cells within this column will contain. */
  text?: string;

  /** Name of the column to be displayed in the header cell */
  name: string;

  /** Relative width of the column */
  width?: number = 1;

  /** priority this column should have in relation to other columns */
  priority?: number = 0;
  /** Tooltip to display for this specific column */
  tooltip?: string;

  /** Component to inject for proper display of non-trivial data */
  customComponentName?: string;

  /** Map of custom options to pass in to component */
  customOptions?: any;

  /** Determines whether this field can be sorted upon */
  sortable = true;

  stackedIcons = false;

  /** Icon to display next to cell value */
  icon: string;

  /** Actions to display within a table */
  actions?: [];

  /*
   * Action that gets displayed based on a value of a prop in the row
   */
  conditionalAction?: ConditionalAction;

  /** If ACTIONS list (eg: filetypes) should be conditional based on whether they exist
   *  in a specified array property of the row data
   */
  conditionalArrayProp?: string;
  conditionalMatchProp?: string;

  /** a prop to use for specifically sorting */
  sortByProp: string;

  /** Flag to determine success vs error text */
  successText?: string;

  clickEvent?: ColumnClickEvent;

  /** INTERNAL USE: the original index within the columns array */
  originalIndex?;

  /** INTERNAL USE: A fake header that is used to do some displaying */
  mockProp?;

  /** INTERNAL USE: A fake header that is used to do some displaying */
  mockLabel?;

  /** If client uses a prop for status other than 'status' */
  statusProp?;

  noValueMessage?: string = '--';

  isHidden?: boolean;

  columnHeaderIcon?: string;

  columnHeaderIconLabel?: string;

  constructor(options: any, originalIndex?) {
    Object.assign(this, options);
    this.originalIndex = originalIndex;
  }
}
