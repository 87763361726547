export interface SquidexConfig {
  url: string;
  appName: string;
  clientId: string;
  clientSecret: string;
  enabled?: boolean;
  enableForModule?: {
    footer: boolean;
    resources: boolean;
    documents: boolean;
  };
}

export interface SquidexTokenResponse {
  access_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
}

export interface SquidexContentFlatResponse {
  items: SquidexContentFlatResponseItem[];
  statuses: SquidexStatuses[];
  total: number;
  _links: any;
}

export interface SquidexAssetsFolderResponse {
  items: SquidexAssetsFolderResponseItem[];
  path: [];
  total: number;
  _links: any;
}

export interface SquidexStatuses {
  color: string;
  status: string;
}

export interface SquidexContentFlatResponseItem {
  id: string;
  created: string;
  createdBy: string;
  lastModified: string;
  lastModifiedBy: string;
  newStatus: string;
  newStatusColor: string;
  schema: string;
  SchemaName: string;
  status: string;
  statusColor: string;

  // this field is where the actual content in a response is stored
  data: object;
}

export interface SquidexAssetsFolderResponseItem {
  id: string;
  parentId: string;
  folderName: string;
  version: number;
}

export const schemaId = 'schemaId';
