import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AccordionModule,
  ButtonModule,
  FormGroupModule,
  ModalModule,
  ModelAttributeModule,
  TabsModule,
} from '@zipari/design-system';
import { ZipBusyModule } from '../../../shared/modules/zip-busy';

import { DetailTabsModule } from '../../shared/modules/detail-tabs/detail-tabs.module';
import { TabViewModule } from '../../shared/modules/tab-view/tab-view.module';

import { ProviderGroupDetailComponent } from './provider-group-detail.component';

@NgModule({
  declarations: [ProviderGroupDetailComponent],
  imports: [
    CommonModule,
    ZipBusyModule,
    ModelAttributeModule,
    ButtonModule,
    DetailTabsModule,
    TabViewModule,
    ModalModule,
    AccordionModule,
    TabsModule,
    FormGroupModule,
  ],
  exports: [ProviderGroupDetailComponent],
})
export class ProviderGroupDetailModule {}
