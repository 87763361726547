import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  CXCaller,
  CxCallerConfig,
  CxContext,
  CxContextApp,
  CxContextDevice,
  CxContextOS,
  CxContextPage,
} from '@zipari/cx';
import { of } from 'rxjs';
import { UAParser } from 'ua-parser-js';
import { createSHA256Hash } from './encryption';
import { PostHogService } from './posthog.service';

import { WindowService } from './window.service';

export interface CxConfig {
  endpoint: string;
  source_system_name: string;
  tenant_name: string;
  cxEnabled: boolean;
  usePostHogForCX: boolean;
  secretKey?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CXCaptureService {
  public calls: Record<string, unknown> | undefined;
  public consumer_id: string | undefined;
  public consumer_id_hash: string | undefined;
  private endpoint = '/api/cx/capture/events/';
  private context: CxContext;
  private cxEnabled = false;
  private usePostHogForCX = false;

  get timestamp(): string {
    return new Date().toISOString();
  }

  get timezone(): string {
    const today = new Date();
    const hour = 60;
    const timezoneOffset = today.getTimezoneOffset() / hour;

    return `UTC${timezoneOffset > 0 ? '-' : '+'}${timezoneOffset}`;
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private window: WindowService,
    private titleService: Title,
    private postHogService: PostHogService
  ) {
    this.context = {};
  }

  initialize(
    cxConfig: CxConfig,
    config: CxCallerConfig,
    calls: {
      source_system_name: string;
      tenant_name: string;
      cx_calls: {
        [key: string]: {
          event_key: string;
        };
      };
    },
    app: {
      name: string;
      version: string;
    },
    consumer_id: string
  ) {
    const { secretKey } = cxConfig;

    new CXCaller(config);
    this.calls = calls;
    this.consumer_id = consumer_id;
    this.consumer_id_hash = createSHA256Hash(consumer_id, secretKey || '');

    this.cxEnabled = cxConfig.cxEnabled;
    this.usePostHogForCX = cxConfig.usePostHogForCX;

    this.context = new CxContext(config, {
      app: new CxContextApp({
        name: app.name,
        version: app.version,
      }),
      timezone: this.timezone,
    });

    this.setPageContext();
    this.setDeviceAndOSContext();
  }

  setPageContext() {
    this.route.queryParams.subscribe((params: Params) => {
      this.context.page = new CxContextPage({
        path: this.router.url,
        referrer: document.referrer,
        title: this.titleService.getTitle(),
        url: this.window.nativeWindow.location.href,
      });

      if (!!params && Object.keys(params).length > 0) {
        const paramKeys: string[] = Object.keys(params);
        let queryString = paramKeys.length > 0 ? '?' : '';

        paramKeys.forEach((key: string, index: number) => {
          queryString += `${key}=${params[key]}${
            index !== paramKeys.length - 1 ? '&' : ''
          }`;
        });

        this.context.page.search = queryString;
      }
    });
  }

  setDeviceAndOSContext() {
    const result = new UAParser().getResult();
    const { device, os } = result;

    this.context.device = new CxContextDevice({
      manufacturer: device.vendor,
      model: device.model,
      type: device.type,
    });

    this.context.os = new CxContextOS(os);
  }

  sendEvent(key: string, attributes?: object) {
    if (this.context) {
      if (this.cxEnabled) {
        this.context.timestamp = this.timestamp;

        const payload = this.createCXpayload(key, attributes);

        this.http
          .post(this.endpoint, {
            payload: payload,
            key,
          })
          .subscribe();
      }

      if (this.usePostHogForCX) {
        this.postHogService.captureEvent(key, { ...attributes });
      }
    }
  }

  subscribeEvent(key: string, attributes?: object) {
    if (this.context) {
      this.context.timestamp = this.timestamp;

      const payload = this.createCXpayload(key, attributes);

      return this.http.post(this.endpoint, {
        payload: payload,
        key,
      });
    }

    return of(null);
  }

  createCXpayload(key: string, attributes?: object) {
    const cxPayload = {
      name: key,
      source_system_name: this.calls?.['source_system_name'],
      authentication_flag: true,
      consumer_id: this.consumer_id,
      consumer_id_hash: this.consumer_id_hash,
      dictionary_attributes: attributes,
      context: this.context,
    };

    return cxPayload;
  }
}
