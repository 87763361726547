import { Component, Input } from '@angular/core';
import { ClinicalReveiwIndicationContext } from '../../../templates/transfer-of-care/transfer-of-care-detail.model';

@Component({
  selector: 'clinical-review-list',
  templateUrl: './clinical-review-list.component.html',
  styleUrls: ['./clinical-review-list.component.scss'],
})
export class ClinicalReviewListComponent {
  @Input() listItem: ClinicalReveiwIndicationContext;

  getUpdatedListItem(item: string): string {
    if (typeof item == 'string') {
      return item?.split(/[(][0-9]/)[0];
    }
  }
}
