import * as svgson from 'svgson';

import { cloneObject } from './object';

export interface FormattedConfig {
  [key: string]: object | string | FormattedConfig;
}

export function formatConfig(configuration: object): FormattedConfig {
  const config = cloneObject(configuration);

  Object.keys(config).forEach((key) => {
    if (!!config[key] && typeof config[key] === 'object') {
      if (config[key]['__is_array']) {
        config[key] = objectArrayToArray(config[key]);
      } else {
        config[key] = formatConfig(config[key]);
      }
    }
  });

  return config;
}

export function objectArrayToArray(
  objectArray: Record<any, any>
): Record<any, any>[] | null {
  const array: any[] = [];

  Object.keys(objectArray).forEach((key) => {
    const item = objectArray[key];

    if (!!item && key !== '__is_array') {
      const element: Record<any, any> = {};

      Object.keys(item).forEach((k) => {
        if (!!item[k] && !!item[k]['__is_array']) {
          element[k] = objectArrayToArray(item[k]);
        } else {
          element[k] = item[k];
        }
      });

      array.push(element);
    }
  });

  array.sort((a, b) => (a.position < b.position ? -1 : 1));

  return array.length > 0 ? array : null;
}

export function applyIconsFromConfig(icons: any) {
  const parser = new DOMParser();
  const svgString = svgson.stringify(icons);
  const defs = parser.parseFromString(svgString, 'image/svg+xml');
  const oldLibrary = document.getElementById('svgDefs');

  if (oldLibrary) {
    document.getElementsByTagName('body')[0].removeChild(oldLibrary);
  }

  defs.documentElement.setAttribute('id', 'svgDefs');
  document.getElementsByTagName('body')[0].appendChild(defs.documentElement);
}
