import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule, FormControlModule } from '@zipari/design-system';
import { ZipBusyModule } from '../../../modules/zip-busy';
import { PipeModule } from '../../../pipes/pipe.module';
import { AttachmentPillComponent } from '../attachment-pill/attachment-pill.component';
import { AddNewModalModule } from '../../../modules/add-row-modal/add-new-modal.module';
import { MessageComponent } from './message.component';

@NgModule({
  declarations: [MessageComponent, AttachmentPillComponent],
  imports: [
    CommonModule,
    ButtonModule,
    ZipBusyModule,
    FormControlModule,
    AddNewModalModule,
    PipeModule,
  ],
})
export class MessageModule {}
