<modal
  *ngIf="config"
  [config]="config.modal"
  [condition]="showModal"
  (cancel)="closeModal.emit()"
>
  <div body>
    <p [innerHTML]="config.modal.description"></p>
    <div class="ReplacementUserForm__FormContainer">
      <checkbox-list
        [config]="config.replacementUsersForm"
        [checkboxOptions]="associatedTaxIds"
        [showSelectAll]="false"
        (selectedOptionList)="handleListSelection($event)"
      >
      </checkbox-list>
    </div>
  </div>

  <div class="ReplacementUserForm__Footer" footer>
    <zip-button
      [config]="config.footer.cancelButton"
      (zipButtonClicked)="closeModal.emit()"
    ></zip-button>
    <zip-button
      [config]="config.footer.submitButton"
      (zipButtonClicked)="submitClicked.emit()"
    ></zip-button>
  </div>
</modal>
