import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { BadgeConfig } from '@zipari/design-system';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { APIList } from '@zipari/web-services';
import { noop, Subject, Subscription, takeUntil } from 'rxjs';
import { ErrorModel } from '../../../shared/models/Errors.model';
import { CX_CALLS } from '../../../shared/constants/cx-calls.constant';
import Claim from '../../../shared/models/shared/Claim.model';
import ClaimLine from '../../../shared/models/shared/ClaimLine.model';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import {
  APIService,
  ConfigService,
  FormattingService,
} from '../../../shared/services';
import { GlobalConfig } from '../../../app.constants';
import { ClaimDetailService } from './claim-detail.service';
import {
  ClaimDetailConfig,
  ClaimDetailTabConfig,
} from './ClaimDetailConfig.model';
import { CLAIM_APPEAL } from './claim-detail.constant';
import { ClaimDetailToggleFeatureService } from './claim-detail-toggle-feature.service';

@Component({
  selector: 'claim-detail',
  templateUrl: './claim-detail.component.html',
  styleUrls: ['./claim-detail.component.scss'],
})
export class ClaimDetailComponent implements OnInit, OnDestroy {
  claim: Claim;
  config: ClaimDetailConfig;
  routeBackLabel: string;
  eligibilityView;
  buttonConfig: ButtonConfig;
  busy: Subscription;
  dataLoaded: boolean;
  activeTab: number;
  activeTabObj: any;
  tabLabels: string[] = [];
  tabsConfig: ClaimDetailTabConfig[] = [];
  modifiedBadgeConfig: any[] = [];
  errorHandled: boolean = false;
  memberId: number;
  showClaimAppealWarningMessage: boolean;
  claimAppealWarningMessage: string;
  claimAppealTypeSelected: string;
  showPopupMessageForReconsideration: boolean = true;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private api: APIService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private formatService: FormattingService,
    private datePipe: DatePipe,
    private claimDetailService: ClaimDetailService,
    public analyticsService: AnalyticsService,
    public claimDetailToggleFeatureService: ClaimDetailToggleFeatureService
  ) {}

  get claimNumber(): string {
    return this.route.snapshot?.params.claim_number;
  }

  get isClaimAppealsFeatureEnabled(): boolean {
    const globalConfig: GlobalConfig =
      this.configService.getPageConfig('global');

    return globalConfig?.toggleFeatures?.enableClaimAppeals || false;
  }

  ngOnInit(): void {
    this.config = this.configService.getPageConfig('claim_detail');
    this.applyToggleFeature();
    this.getClaim();
    this.setTabLabels();
    this.claimDetailService.config = this.config;
    let targetRoute: UrlSegment;
    if (this.route?.snapshot) {
      targetRoute =
        this.route?.snapshot['_urlSegment']?.segments[
          this.route?.snapshot['_urlSegment']?.segments.length - 1
        ];
    }

    this.activeTab = this.tabsConfig.indexOf(
      this.tabsConfig.find((target: any) => target.prop === targetRoute.path)
    );
    this.activeTabObj = this.tabsConfig?.find(
      (target: any) => target.prop === targetRoute.path
    );
    if (this.config)
      this.claimDetailService.tabsConfig =
        this.config[this.activeTabObj?.targetConfigKey];
  }

  applyToggleFeature(): void {
    if (!this.isClaimAppealsFeatureEnabled) {
      this.claimDetailToggleFeatureService.removeClaimAppealsTabConfig(
        this.config
      );
      this.claimDetailToggleFeatureService.removeClaimAppealsMessageConfig(
        this.config
      );
      this.claimDetailToggleFeatureService.removeClaimAppealsTabDetailConfig(
        this.config
      );
      this.claimDetailToggleFeatureService.removeActionButtonConfig(
        this.config
      );
    }
  }

  recentlyViewCall(data): void {
    const payload = {
      member_id: data.patient.id,
      member_first_name: data.patient.name.first_name,
      member_last_name: data.patient.name.last_name,
    };
    this.busy = this.api
      .post(this.config['recently_viewed_endpoint'].endpoint, payload)
      .subscribe(noop);
  }

  getClaim(): void {
    this.route.params?.subscribe((params: any) => {
      this.busy = this.claimDetailService
        .getClaim(this.route.snapshot.params.claim_number)
        .subscribe(
          (response: Claim) => {
            this.claim = response;
            this.isClaimAppealsFeatureEnabled &&
              this.claim.id &&
              this.claimDetailService.getClaimAppealsSummary(this.claim.id);
            this.recentlyViewCall(this.claim);
            this.memberId = this.claim.patient.id;
            this.claimDetailService.setClaim(this.claim);
            response.claim_lines?.map(
              (item: any) =>
                (item['procedure_code'] = item.procedures?.[0]?.procedure_code)
            );
            this.eligibilityView = 'id' in this.route.snapshot?.params;
            this.routeBackLabel = this.eligibilityView
              ? `${this.claim.patient?.name?.first_name} ${this.claim.patient?.name?.last_name}`
              : `All Claims`;

            this.getClaimLines(this.claim.claim_number);
            this.formatDates();
            this.formatConfigValues();
            this.formatButtonConfig();
            this.setBadgeColor();

            /*
             * ERHO: TODO: fix this last-minute crap
             */
            this.iterateThroughSidePanel();
            this.isClaimAppealsFeatureEnabled &&
              this.claimDetailService.claimAppealSummary$
                ?.pipe(takeUntil(this.destroy$))
                .subscribe();
          },
          (err: ErrorModel) => {
            this.errorHandled = true;
            this.dataLoaded = true;
          }
        );
    });
  }

  navigateClaimForm(event): void {
    const { noRedeterminationMessage, existReconsiderationMessage } =
      this.config?.existingAppealsModal || {};
    const isNavigateToRedetermination =
      !this.claimDetailService.isDeterminationExist &&
      event.route === CLAIM_APPEAL.REDETERMINATION_ROUTE;
    const isNavigateToReconsideration =
      this.claimDetailService.isDeterminationExist &&
      !this.claimDetailService.isReconsiderationExist &&
      event.route === CLAIM_APPEAL.RECONSIDERATION_ROUTE;
    const claimAppealWarningMessage =
      !this.claimDetailService.isDeterminationExist &&
      !this.claimDetailService.isReconsiderationExist
        ? noRedeterminationMessage
        : existReconsiderationMessage;

    if (isNavigateToRedetermination || isNavigateToReconsideration) {
      this.router.navigate([`${event.route}`], { relativeTo: this.route });
    }
    switch (event.route) {
      case CLAIM_APPEAL.RECONSIDERATION_ROUTE: {
        this.showClaimAppealWarningMessage = true;
        this.claimAppealWarningMessage = claimAppealWarningMessage;
        this.claimAppealTypeSelected = CLAIM_APPEAL.RECONSIDERATION;
        break;
      }
      case CLAIM_APPEAL.REDETERMINATION_ROUTE: {
        this.showClaimAppealWarningMessage = true;
        this.claimAppealWarningMessage =
          this.config?.existingAppealsModal?.existRedeterminationMessage || '';
        this.claimAppealTypeSelected = CLAIM_APPEAL.REDETERMINATION;
        break;
      }
    }
  }

  navigateToClaimAppealReconsideration(): void {
    const showRedeterminationMessage =
      this.claimAppealTypeSelected === CLAIM_APPEAL.RECONSIDERATION &&
      this.showPopupMessageForReconsideration &&
      !this.claimDetailService.isDeterminationExist &&
      this.claimDetailService.isReconsiderationExist;

    if (showRedeterminationMessage) {
      this.showClaimAppealWarningMessage = true;
      this.claimAppealWarningMessage =
        this.config?.existingAppealsModal?.noRedeterminationMessage || '';
      this.showPopupMessageForReconsideration = false;
    } else {
      this.router.navigate([`${CLAIM_APPEAL.RECONSIDERATION_ROUTE}`], {
        relativeTo: this.route,
      });
    }
  }

  navigateToClaimAppealsForm(): void {
    if (this.claimAppealTypeSelected === CLAIM_APPEAL.RECONSIDERATION) {
      this.navigateToClaimAppealReconsideration();
    } else if (this.claimAppealTypeSelected === CLAIM_APPEAL.REDETERMINATION) {
      this.router.navigate([`${CLAIM_APPEAL.REDETERMINATION_ROUTE}`], {
        relativeTo: this.route,
      });
    }
  }

  getClaimLines(claimNumber: string): void {
    this.busy = this.api
      .get(`/api/provider-portal/claims/${claimNumber}/lines`)
      .subscribe((lines: APIList<ClaimLine>) => {
        this.claim['lines_total'] = lines.count;
        this.dataLoaded = true;
      });
  }

  iterateThroughSidePanel(): void {
    this.config.sidebar.sections.forEach((section: any) => {
      if (section.links) {
        // since we need to show forms other than with eob_document_id adding a filter to remove
        // links when eob_document_id is empty string
        section.links = section.links.filter(
          (link: any) =>
            link.targetString !== 'eob_document_id' ||
            (link.endpoint && this.claim[link.targetString])
        );
        section.links.forEach((link: any) => {
          if (link.endpoint) {
            const newUrl: string = link.endpoint.replace(
              link.targetString,
              this.claim[link.targetString]
            );
            // ERHO: Temp while UCare still sends doc for download
            link.href = newUrl;
          } else {
            // ERHO: CRAP
            // This craps is added here to make sure we can see the link other
            // then with target string 'eob_document_id'
            // if there is not other link and no eob_document_id download section will not show at due to data
            // dependency in config;
            if (this.config.sidebar.useDummyData) {
              this.claim['eob_document_id'] = '327236';
            }
          }
        });
      }
    });
  }

  linkClicked(event): void {
    /*
     * ERHO: TODO: Figure out if need cxCapture object in config
     */
    if (CX_CALLS[event.event_key])
      this.analyticsService.sendEvent(CX_CALLS[event.event_key].event_key);
  }

  tabSelected(ev): void {
    this.activeTab = ev;

    this.router
      .navigate([this.tabsConfig[ev]?.url], { relativeTo: this.route })
      .then((status: boolean) => {
        const targetRoute: UrlSegment =
          this.route.snapshot['_urlSegment'].segments[
            this.route.snapshot['_urlSegment'].segments.length - 1
          ];
        this.activeTabObj = this.tabsConfig.find(
          (target: any) => target.prop === targetRoute.path
        );
        this.claimDetailService.tabsConfig =
          this.config[this.activeTabObj.targetConfigKey];
      });
  }

  setTabLabels(): any {
    this.config &&
      Object.keys(this.config.tabs).map((tab: any) => {
        this.tabLabels[this.config.tabs[tab].priority] =
          this.config.tabs[tab].label;
        this.tabsConfig[this.config.tabs[tab].priority] = this.config.tabs[tab];
      });
  }

  setBadgeColor(): void {
    const badgeStatusNumber: number = 2;
    // If status is coming in multiple words the replace it by under score so item can be configured through underscore in config.
    const status: string = this.claim.claim_status
      ?.toLowerCase()
      .trim()
      .replace(/ /g, '_');
    const statusIndex: number =
      this.modifiedBadgeConfig.findIndex(
        (badge: BadgeConfig) => badge.label?.toLowerCase() === 'status'
      ) || badgeStatusNumber;
    // if status is not in mapping list then leave to default status
    if (this.modifiedBadgeConfig[statusIndex]?.status_mapping?.[status]) {
      this.modifiedBadgeConfig[statusIndex].status =
        this.modifiedBadgeConfig[statusIndex].status_mapping[status];
    }
  }

  formatButtonConfig(): void {
    if (this.config.backButton) {
      this.buttonConfig = this.config.backButton;
      this.buttonConfig['content'] = `${this.routeBackLabel}`;
    } else {
      this.buttonConfig = {
        content: `${this.routeBackLabel}`,
        icon: 'chevron_left',
        level: 'text',
      };
    }
  }

  formatDates(): void {
    Object.keys(this.claim).forEach((key: string) => {
      if (key.includes('date') && this.claim[key]) {
        const date: Date = new Date(this.claim[key]);
        if (this.isValidDate(date)) {
          this.claim[key] = this.datePipe.transform(date, 'MM/dd/yyyy', 'GMT');
        }
      }
    });
  }

  isValidDate(date: Date): boolean {
    return date instanceof Date && !isNaN(date as unknown as number);
  }

  formatConfigValues(): void {
    if (this.config.badges) {
      const keys: string[] = Object.keys(this.config.badges);
      keys.map((key: string) => {
        const newBadgeConfig: BadgeConfig =
          this.formatService.formatConfigValueAsTemplate(
            this.config.badges[key],
            this.claim
          );
        this.modifiedBadgeConfig.push(newBadgeConfig);
      });
    }
  }

  routeBack(): void {
    if (this.eligibilityView) {
      this.router.navigate([
        `/provider-portal/patients/${this.route.snapshot.params['id']}/claims`,
      ]);
    } else {
      if (CX_CALLS[this.config.backButton.event_key]) {
        this.analyticsService.sendEvent(
          CX_CALLS[this.config.backButton.event_key].event_key
        );
      }
      this.router.navigate([`/provider-portal/claims`]);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
