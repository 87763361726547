import { Directive, AfterViewInit, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[postInit]',
})
export class PregnancyRiskAssesmentDirective implements AfterViewInit {
  @Output() postInit: EventEmitter<any> = new EventEmitter();

  ngAfterViewInit() {
    this.postInit.emit();
  }
}
