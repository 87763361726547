<section class="ErrorEmpty" *ngIf="config">
  <ng-container *ngIf="config.image_url">
    <img
      class="ErrorEmpty__Image"
      [src]="config.image_url"
      [attr.alt]="config.alt || 'Error Empty Image'"
    />
  </ng-container>

  <div>
    <ng-container *ngIf="config.title">
      <h4 class="ErrorEmpty__Title">{{ config.title }}</h4>
    </ng-container>

    <ng-container *ngIf="config.description">
      <p class="ErrorEmpty__Description" [innerHTML]="config.description"></p>
    </ng-container>
  </div>
</section>
