import { createAction, props } from '@ngrx/store';

export const getConfiguration = createAction(
  '[Sidebar Table View | Init] Get Configuration',
  props<{ config: any }>()
);

export const loadDatagridData = createAction(
  '[Sidebar Table View | Init] Load Datagrid Data',
  props<{ payload: any }>()
);

export const setActiveTab = createAction(
  '[Sidebar Table View | Tabs] Set Active Tab',
  props<{ tab: any }>()
);

export const setPaginatorCurrentPage = createAction(
  '[Sidebar Table View | Paginator] Set Current Page',
  props<{ page: number }>()
);

export const sortedSuccess = createAction(
  'type: [Claims | Sort] Sorted - Success ',
  props<{ payload?: any }>()
);

export const sortedError = createAction(
  'type: [Claims | Sort] Sorted - Error ',
  props<{ payload?: any }>()
);
