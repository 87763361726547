import { ButtonConfig } from '@zipari/shared-ds-util-button';

export class MessageBannerConfig {
  messageHeading?: string;
  message!: string;
  icon?: string;
  type!: BannerTypes;
  actionIcon?: string;
  actionIconAltText?: string;
  actionText?: string;
  actionButton?: ButtonConfig;
  hideBanner?: boolean;
}

export enum BannerTypes {
  error = 'error',
  warning = 'warning',
  success = 'success',
  information = 'information',
}
