<modal
  *ngIf="modalConfig"
  [config]="modalConfig"
  [condition]="showModal"
  (cancel)="closeErrorModal()"
>
  <div body>
    <div class="Modal__Content">
      <p [innerHTML]="errorMessage || defaultErrorMessage"></p>
    </div>
  </div>
  <div footer class="Modal__Button">
    <zip-button
      [config]="buttonConfig"
      (zipButtonClicked)="closeErrorModal()"
    ></zip-button>
  </div>
</modal>
