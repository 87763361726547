import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  Datagrid,
  DatagridAction,
  DatagridColumn,
  DatagridFormat,
  DatagridSortDirection,
  DatagridTextWithIconConfiguration,
  DatagridTooltip,
  RowCondition,
} from '@zipari/shared-ds-util-datagrid';
import { randomString } from '@zipari/shared-util-common';
import { FormattingService } from '../../shared/services/formatting.service';
import { getValue } from '../../shared/utils/get-value';
import { hasOwnNestedProperty } from '../../shared/utils/hasOwnNestedProperty';
import { DatagridDigitalDataService } from './datagrid-digital-data.service';
import { getIconParentId } from './datagrid.helper';

@Component({
  selector: 'datagrid',
  styleUrls: ['./datagrid.component.scss'],
  templateUrl: './datagrid.component.html',
})
export class DatagridComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() clearSelectedRows: boolean;
  @Input() config: Datagrid;
  @Input() data: object[];
  @Input() isLoading: boolean;
  @Input() selectableRows: boolean;
  @Input() sortOrder: string; // which property is the grid sorted by
  @Input() condition: RowCondition;
  @Input() hasAnErrorOccurred = false;
  @Input() footerDetail: object;
  @Output() actionClicked = new EventEmitter<DatagridAction>();
  @Output() datagridRowClicked = new EventEmitter<object>();
  @Output() datagridRowSelected = new EventEmitter<any>();
  @Output() datagridSsoRowSelected = new EventEmitter<any>();
  @Output() selectionActionClicked = new EventEmitter<object[]>();
  @Output() sortClicked: EventEmitter<string> = new EventEmitter();
  /**
   * @deprecated
   * @param sort is deprecated in favor of sortClicked. Please remove from your code
   */
  @Output() sort: EventEmitter<string> = new EventEmitter();
  @ViewChild('datagridBody') datagridBody;
  @ViewChild('datagridShadowLeft') datagridShadowLeft;
  @ViewChild('datagridShadowRight') datagridShadowRight;

  defaultRows: object[];
  formats = DatagridFormat;
  isScrollable: boolean;
  rows: object[];
  selectedRows: object[] = [];
  sortDirections = DatagridSortDirection;
  dataGridId: string = randomString();
  public selectedRowsTitle = 'Rows Selected';
  public limit = 2; // default limit to show the number of items in expanding list column
  get selectIcon(): string {
    return this.areAllRowsSelected()
      ? 'checkbox_active'
      : this.selectedRows.length > 0
      ? 'checkbox_all'
      : 'checkbox';
  }

  constructor(
    private renderer: Renderer2,
    private formattingService: FormattingService,
    public digitalDataGridService: DatagridDigitalDataService
  ) {}

  public get rowsSelectedStatus(): string {
    return this.config.selectedRowsTitle || this.selectedRowsTitle;
  }

  @HostListener('scroll', ['$event'])
  onScroll($event) {
    const scrollLeft = $event.target.scrollLeft;
    const scrollWidth = $event.target.scrollWidth;
    const clientWidth = $event.target.clientWidth;

    if (scrollLeft > 0) {
      this.renderer.setStyle(
        this.datagridShadowLeft.nativeElement,
        'opacity',
        '1'
      );
      this.renderer.setStyle(
        this.datagridShadowLeft.nativeElement,
        'left',
        `${scrollLeft}px`
      );
    } else {
      this.renderer.setStyle(
        this.datagridShadowLeft.nativeElement,
        'opacity',
        '0'
      );
    }

    if (scrollLeft + clientWidth === scrollWidth) {
      this.renderer.setStyle(
        this.datagridShadowRight.nativeElement,
        'opacity',
        '0'
      );
    } else {
      this.renderer.setStyle(
        this.datagridShadowRight.nativeElement,
        'opacity',
        '1'
      );
      this.renderer.setStyle(
        this.datagridShadowRight.nativeElement,
        'right',
        `-${scrollLeft}px`
      );
    }
  }

  ngOnInit() {
    this.rows = this.getData();
    this.defaultRows = this.getData();
    this.isScrollable = this.config.isScrollable && this.rows.length > 0;
    this.isLoading = !!this.isLoading;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) this.rows = this.getData();
    if (changes.clearSelectedRows) this.selectedRows = [];
    // Update isScrollable here to account for delays in receiving data
    this.isScrollable = this.config.isScrollable && this.rows.length > 0;
    this.hideUnusedColumns();
  }

  /** `getBoundingClientRect()` gives improper position when inside tabs. */
  ngAfterViewInit() {
    if (this.unscrollableFillWindow(this.config)) {
      setTimeout(() => window.dispatchEvent(new Event('resize')));
    }
  }

  unscrollableFillWindow(config: Datagrid): boolean {
    return config?.fillWindow && !config.isScrollable;
  }

  getFormattedValue(row: object, column: Partial<DatagridColumn>): any {
    const formattedValue = this.formattingService.restructureValueBasedOnFormat(
      getValue(row, column.prop),
      column
    );

    return formattedValue === 0 ? '0' : formattedValue;
  }

  getFormattedToolTipValue(row: object, column: Partial<DatagridColumn>): any {
    const formattedValue = this.formattingService.restructureValueBasedOnFormat(
      getValue(row, column.tooltipProp),
      column,
      'TEXT'
    );

    return formattedValue === 0 ? '0' : formattedValue;
  }

  getStatusLabel(column: any, rowStatusValue: string) {
    if (column?.statuses) {
      return (
        []
          .concat(
            ...Object.keys(column.statuses).map(
              (statusKey) => column.statuses[statusKey]
            )
          )
          .find((status) => status.value === rowStatusValue)?.label ||
        rowStatusValue
      );
    }

    return rowStatusValue;
  }

  /** The maximum height of the datagrid body should be set to show the entire datagrid page. */
  setDatagridBodyHeight() {
    this.renderer.setStyle(
      this.datagridBody.nativeElement,
      'max-height',
      `${
        window.innerHeight -
        this.datagridBody.nativeElement.getBoundingClientRect().top
      }px`
    );
  }

  viewDetail(row, columnProp: string, routeToProp?: string) {
    if (routeToProp) {
      this.datagridRowSelected.emit({ uniqueCellProp: routeToProp, ...row });
    } else {
      this.datagridRowSelected.emit({ uniqueCellProp: columnProp, ...row });
    }
  }

  viewSSODetail(row, columnRoute: string, columnOptions) {
    if (columnOptions.length > 0) {
      this.datagridSsoRowSelected.emit({
        ...row,
        route: columnRoute,
        options: columnOptions,
      });
    } else {
      this.datagridSsoRowSelected.emit({
        ...row,
        route: columnRoute,
        options: [],
      });
    }
  }

  onActionClicked(row: any, action: string, clickEvent?: Event) {
    const targetElementId: string = clickEvent
      ? getIconParentId(clickEvent)
      : '';

    this.actionClicked.emit({ row, action, targetElementId });
  }

  // Toggle the selection of rows
  toggleAllRows() {
    this.selectedRows = this.selectedRows.length !== 0 ? [] : [...this.rows];
  }

  toggleRow(event, row) {
    event.stopPropagation();
    event.preventDefault();
    !this.isRowSelected(row)
      ? this.selectedRows.push(row)
      : this.selectedRows.splice(this.selectedRows.indexOf(row), 1);
  }

  isRowSelected(row) {
    return this.selectedRows.includes(row);
  }

  isClickable(row, condition) {
    return row[condition?.key] && row[condition?.key] === condition?.val;
  }

  areAllRowsSelected() {
    return this.rows.every((row) => this.isRowSelected(row));
  }

  getColumnWidth(column: DatagridColumn) {
    return column.width && this.rows.length > 0 && column.width;
  }

  isActionableColumn(columnFormat): boolean {
    return (
      columnFormat === this.formats.DOWNLOAD ||
      columnFormat === this.formats.ICON ||
      columnFormat === this.formats.ACTION_ICONS
    );
  }

  showHeaderLabel(column: any, formats: any, rows: any): boolean {
    // check if the column should show it's label based on it's format.
    // currently all column formats except ACTION_ICONS show their label by default
    // only show a ACTION_ICONS formatted col's label if the showActionIconsLabel flag is true
    const showFormatsLabel =
      column.format === formats.ACTION_ICONS
        ? column.showActionIconsLabel || false
        : true;

    return (
      showFormatsLabel && (0 < rows?.length || this.config?.noData?.showHeader)
    );
  }

  // SORTING
  sortByProperty(prop: string) {
    this.sortClicked.emit(prop);
    this.sort.emit(prop); // keeping for backward compatibility
  }

  handleColumnSortByClick(event: Event, column: DatagridColumn) {
    event.stopPropagation();
    event.preventDefault();
    const propToSortBy = this.getSortingProperty(column);
    column.sortable && this.sortByProperty(propToSortBy);
  }

  getSortingProperty(column: DatagridColumn) {
    return column.sortByProp ? column.sortByProp : column.prop;
  }

  getData() {
    return this.data ? [...this.data] : [];
  }

  hideUnusedColumns() {
    if (this.config.hideUnusedColumns && this.rows?.length > 0) {
      let newColumns = [];
      /* eslint-disable-next-line */
      this.rows.forEach((dataRow: any) => {
        const columns = this.config.columns.filter((column) =>
          hasOwnNestedProperty(dataRow, column.prop)
        );
        newColumns = [
          ...newColumns,
          ...columns.filter((column) => newColumns.indexOf(column) < 0),
        ];
      });
      this.config = { ...this.config, columns: newColumns };
    }
  }

  /**
   * Gets type of value (it can be of type: string, number, object etc)
   * @param {any} value
   * @returns type of value
   */
  getTypeOf(value: any): any {
    return typeof value;
  }

  /**
   * Gets restructured value based on value, column and type details
   * @param {string} value
   * @param {DatagridColumn} column
   * @param {string} type
   * @returns formatted value
   */
  getFormattedObjectValue(
    value: string,
    column: Partial<DatagridColumn>,
    type: string
  ): string {
    return this.formattingService.restructureValueBasedOnFormat(
      value,
      column,
      type
    );
  }

  /**
   * Gets tooltip text based on value and column details
   * @param {string} value
   * @param {DatagridColumn} column
   * @returns formatted tooltip text
   */
  getFormattedTooltipObjectValue(
    value: DatagridTextWithIconConfiguration,
    column: Partial<DatagridColumn>
  ): DatagridTooltip {
    return {
      description: this.formattingService.restructureValueBasedOnFormat(
        value.tooltip,
        column,
        'TEXT'
      ),
      leftAlign: value.leftAlign || false,
      directionTop: value.directionTop || false,
    };
  }
}
