import { Component, Input } from '@angular/core';
import { DocumentCenterEventService } from '../../services/document-center-events.service';
import { DocumentEventType } from '../../util/document-center.constants';
import {
  AccordionField,
  AccordionLayoutConfiguration,
} from '../../util/document-center.model';

@Component({
  selector: 'zipari-accordion-layout',
  templateUrl: './accordion-layout.component.html',
  styleUrls: ['./accordion-layout.component.scss'],
})
export class AccordionLayoutComponent {
  @Input() accordionConfig!: AccordionLayoutConfiguration;

  constructor(private documentCenterEventService: DocumentCenterEventService) {}

  documentViewed(field: AccordionField): void {
    window.open(field.url, '_blank');
    this.documentCenterEventService.captureEvent({
      type: DocumentEventType.documentViewed,
    });
  }
}
