import { Component, Input, OnInit } from '@angular/core';
import { RippleSizesValue } from './ripple.enum';
import { RippleSizes } from './ripple.model';

@Component({
  selector: 'ripple',
  templateUrl: './ripple.component.html',
  styleUrls: ['./ripple.component.scss'],
})
export class RippleComponent implements OnInit {
  @Input() isFullPage = true;
  @Input() flexCenter: boolean;
  @Input() positionRelative: boolean;
  @Input() size: RippleSizes;

  ngOnInit() {
    this.isFullPage = this.isFullPage === undefined ? true : this.isFullPage;
  }

  public getSize() {
    switch (this.size) {
      case RippleSizes.small:
        return RippleSizesValue.small;

      case RippleSizes.medium:
        return RippleSizesValue.medium;

      case RippleSizes.large:
        return RippleSizesValue.large;

      case RippleSizes.extraLarge:
      default:
        return RippleSizesValue.extraLarge;
    }
  }
}
