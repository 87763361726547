import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import {
  LinkConfig,
  NavigationLinkTargets,
} from '@zipari/shared-ds-util-navigation';
import { ExternalSiteModalConfig } from '../external-site-warning-modal/external-site-warning-modal.model';

interface Resource {
  icon: string;
  description: string;
  label: string;
  route?: string;
  href?: string;
  isExternalRoute?: boolean;
  useExternalSiteModal?: boolean;
  target?: '_blank' | '';
  button?: ButtonConfig;
}

interface ResourceGrid {
  isHorizontal?: boolean;
  resources: {
    resources: Resource[];
  };
}

@Component({
  selector: 'resource-grid',
  templateUrl: './resource-grid.component.html',
  styleUrls: ['./resource-grid.component.scss'],
})
export class ResourceGridComponent {
  @Input() config: ResourceGrid;
  @Input() externalSiteModalConfig: ExternalSiteModalConfig;
  @Output() clicked = new EventEmitter();

  handleLinkClicked(event) {
    this.clicked.emit(event);
  }

  getLinkConfigs(config: Resource): LinkConfig {
    return {
      label: config.label,
      externalLink: config.isExternalRoute ? config.href : null,
      useExternalSiteModal: !!config.useExternalSiteModal,
      route: config.route ? config.route : null,
      target: config.target === '_blank' ? NavigationLinkTargets.new_tab : null,
      button: config.button || null,
    };
  }
}
