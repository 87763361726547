<div class="header-cell">
  <div [ngClass]="{ inactive: state == 'inactive' }" class="header-cell__title">
    {{ title }}
  </div>
  <br />
  <form-group
    *ngIf="searchBar && state == 'active'"
    [configs]="searchBar"
    [form]="formGroup"
  >
  </form-group>
</div>
