import { Pipe, PipeTransform } from '@angular/core';
import { stringBuilder } from '../utilities';

/* Convert a object into a string */

@Pipe({
  name: 'stringBuilder',
})
export class StringBuilderPipe implements PipeTransform {
  transform(value: string, context: any): string {
    if (value && context) {
      return stringBuilder(value, context);
    }

    return value;
  }
}
