import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ButtonModule,
  CheckboxListModule,
  DSAuthModule,
  FormControlModule,
  FormGroupModule,
  ModalModule,
} from '@zipari/design-system';

import { ZipBusyModule } from '../zip-busy';

import { AuthCardModule } from './auth-card/auth-card.module';
import { AuthenticationComponent } from './authentication.component';
import { AuthBannerLayoutModule } from './layouts/auth-banner-layout/auth-banner-layout.module';
import { AuthStepLayoutModule } from './layouts/auth-step-layout/auth-step-layout.module';
import { ReplacementUsersModalComponent } from './replacement-users-modal/replacement-users-modal.component';

@NgModule({
  declarations: [AuthenticationComponent, ReplacementUsersModalComponent],
  imports: [
    CommonModule,
    AuthCardModule,
    AuthBannerLayoutModule,
    AuthStepLayoutModule,
    ZipBusyModule,
    DSAuthModule,
    ModalModule,
    ButtonModule,
    FormGroupModule,
    FormControlModule,
    CheckboxListModule,
  ],
  exports: [AuthenticationComponent],
})
export class AuthenticationModule {}
