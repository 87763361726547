<ng-container *ngIf="textConfig">
  <div class="Typeahead__Control">
    <control-text
      (inputChanged)="onInputChange($event)"
      autocomplete="off"
      [config]="textConfig"
      [control]="control"
      class="TypeAheadInput"
    >
    </control-text>
    <div (click)="clearInput()" class="Typeahead--icon-close">
      <icon *ngIf="showClose" name="close" [config]="{ size: 'small' }"></icon>
    </div>
  </div>
  <ng-container typeaheadWithDropdown *ngIf="config['canListAllOptions']">
    <div
      class="u-flex u-justifyEnd Typeahead--icons"
      (click)="getAllExternalOptions()"
      [class.Typeahead--icons-input-icon]="config.icon"
      [class.Typeahead--icons-close]="showAllOptions"
    >
      <icon
        *ngIf="!showClose && !showAllOptions && !loading"
        name="carat_down"
      ></icon>
      <icon
        *ngIf="!showClose && showAllOptions && !loading"
        class="Typeahead--close"
        name="close"
        [config]="{ size: 'small' }"
      ></icon>
      <preloader *ngIf="loading" size="small"></preloader>
    </div>
  </ng-container>
  <p class="Typeahead__no-results" *ngIf="noSearchResults && config?.noResults">
    {{ config?.noResults }}
  </p>
</ng-container>

<accessible-options
  *ngIf="showMenu"
  [onSiblingKeyDown]="keyDown$"
  [options]="filteredOptions"
  (selected)="select($event)"
>
</accessible-options>

<accessible-options
  *ngIf="showAllOptions && !filteredOptions.length"
  [options]="allOptions"
  (selected)="select($event)"
>
</accessible-options>

<div
  *ngIf="showAllOptions && allOptions?.length === 0"
  class="Typeahead__no-results"
>
  <p class="Typeahead__no-results-text">No Results</p>
</div>
