<ng-container
  *ngIf="authorizationDetailsContext?.additional_properties?.length > 0"
>
  <card
    id="{{ config?.id }}"
    [config]="{ hideSubHeader: true }"
    class="Card__Holder"
  >
    <div title class="Card__Header">
      <div class="left" [innerHTML]="config?.header"></div>
    </div>

    <div body>
      <div class="Title__border"></div>
      <div class="TabView__List">
        <ul class="TabList__Item">
          <ng-container
            *ngFor="
              let listItem of authorizationDetailsContext?.clinical_review_data
            "
          >
            <ng-container
              *ngIf="listItem?.guidelineTitle; else ClinicalReviewList"
            >
              <header class="Clinical__Review__Header">
                <p
                  class="Clinical__Review__Header__Text"
                  [innerHTML]="listItem.guidelineTitle"
                ></p>
              </header>
            </ng-container>
            <ng-template #ClinicalReviewList>
              <clinical-review-list
                [listItem]="listItem"
              ></clinical-review-list>
            </ng-template>
          </ng-container>
        </ul>

        <ng-container
          *ngIf="
            isClinicalReviewNoDataMessageDisplay ||
            authorizationDetailsContext?.clinical_review_data?.length < 1
          "
        >
          <p class="t-data" [innerHTML]="config?.noDataMessage"></p>
        </ng-container>
      </div>
    </div>
  </card>
</ng-container>
