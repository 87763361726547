import { createFeatureSelector, createSelector } from '@ngrx/store';

import { NavigationState } from './ngrx-navigation.reducers';

export const selectNavigationState =
  createFeatureSelector<NavigationState>('navigation');

export const selectNavigationConfig = createSelector(
  selectNavigationState,
  (state: NavigationState) => state
);

export const selectToolbarConfig = createSelector(
  selectNavigationState,
  (state: NavigationState) => state.toolbarConfig
);

export const selectOpenMenuItem = createSelector(
  selectNavigationState,
  (state: NavigationState) => state.openItem
);

export const selectActiveNavItem = createSelector(
  selectNavigationState,
  (state: NavigationState) => state.activeItem
);

export const selectIsMobileMenuOpen = createSelector(
  selectNavigationState,
  (state: NavigationState) => state.isMobileMenuOpen
);

export const selectNavigationIcons = createSelector(
  selectNavigationState,
  (state: NavigationState) => state?.icons
);
