import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonModule,
  FormControlModule,
  ModelAttributeModule,
} from '@zipari/design-system';
import { SharedModule } from '../../../shared/shared.module';
import { ZipBusyModule } from '../../../shared/modules/zip-busy';
import { IdCardViewComponent } from './id-card-view/id-card-view.component';
import { IdCardsComponent } from './id-cards.component';

@NgModule({
  declarations: [IdCardsComponent, IdCardViewComponent],
  imports: [
    CommonModule,
    SharedModule,
    ZipBusyModule,
    ModelAttributeModule,
    ButtonModule,
    FormControlModule,
  ],
  exports: [IdCardsComponent],
})
export class IdCardsModule {}
