export const defaultFileUploaderRestrictions = {
  title:
    'You must submit supporting documentation from your provider. Attachments must be legible for your claim to be processed.',
  fileTypes: 'application/pdf,image/jpg,image/jpeg,image/png',
  description:
    'You may attach up to 20 documents up to 18MB. We only support PDF, JPEG, JPG, PNG, TIF files.',
  fileSizeLimit: 18,
  fileSizeWarning:
    'You may attach up to 3 documents up to 3MB. We only support PDF, JPEG, JPG, PNG, TIF files.',
  fileAttachmentLimit: 3,
};

export const defaultAddDocumentationButton = {
  icon: 'add',
  size: 'fullWidth',
  level: 'high',
  content: 'Add Documentation',
};

export const defaultFileUpload = {
  icon: null,
  level: 'high',
  content: 'Browse Files',
};
