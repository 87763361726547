import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { accordion } from '../../shared/animations';
import { AccordionConfiguration } from '../../shared/interfaces/accordion.inteface';

@Component({
  selector: 'accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  animations: [accordion],
})
export class AccordionComponent implements OnInit {
  /** @deprecated - use config['header'] */
  @Input() header: string;
  /** @deprecated - use config['header'] */
  @Input() icon: string;
  /** hide accordion content instead of destroying it via *ngIf
   * @deprecated - use config['header']
   */
  @Input() hideWhenCollapsed: boolean;
  /** @deprecated - use config['isHeaderReversed'] */
  @Input() isHeaderReversed: boolean;
  /** @deprecated - use config['card'] */
  @Input() card: boolean;

  @Input() config: AccordionConfiguration;
  @Input() content: TemplateRef<any>;
  @Input() headerAction: TemplateRef<any>;
  @Input() subHeader: TemplateRef<any>;
  @Input() headerTemplateTitle: TemplateRef<any>;
  @Input() isOpen = false;

  @Output() toggle = new EventEmitter<any>();

  ngOnInit(): void {
    if (!this.config) {
      this.config = this.initConfig();
      this.config['id'] = +Date.now();
    }
  }

  public initConfig(): AccordionConfiguration {
    const config: AccordionConfiguration = {};
    const inputs = [
      'header',
      'headerAction',
      'icon',
      'hideWhenCollapsed',
      'content',
      'isHeaderReversed',
      'card',
      'isHeaderBold',
    ];

    inputs.forEach((input) => {
      if (typeof this[input] !== 'undefined') {
        config[input] = this[input];
      }
    });

    // for backwards compatibility. the header used to be bold only if `this.card` was true.
    if (typeof config.isHeaderBold === 'undefined' && this.card) {
      config.isHeaderBold = true;
    }

    return config;
  }

  public toggleContent(): void {
    this.isOpen = !this.isOpen;

    this.toggle.emit(this.isOpen);
  }

  // Prevent closing tab when user clicks on link/action in header
  /* istanbul ignore next */
  onActionClick($event: MouseEvent) {
    $event.stopPropagation();
  }
}
