import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { FormControlService } from '../../../notes/form-control/form-control.service';
import { fade, slideUp } from '../../../shared/animations';
import { ILLUSTRATION_URLS } from '../../../shared/constants';
import { BaseCVAComponent } from '../custom-form-element/base-cva.component';
import { FormGroupArrayConfig } from './form-group-array.config';

@Component({
  selector: 'form-group-array',
  templateUrl: './form-group-array.component.html',
  styleUrls: ['./form-group-array.component.scss'],
  providers: [
    /* eslint-disable @typescript-eslint/no-use-before-define */
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormGroupArrayComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormGroupArrayComponent),
      multi: true,
    },
  ],
  animations: [fade, slideUp],
})
export class FormGroupArrayComponent
  extends BaseCVAComponent
  implements OnInit, OnDestroy
{
  @Input() config: FormGroupArrayConfig;
  @Input() group: UntypedFormGroup;
  @Input() context: object[];
  formArray: UntypedFormArray;
  openedAccordionIndex: number[] = [];

  illustrationUrls = ILLUSTRATION_URLS;

  /* istanbul ignore next */
  constructor(private formService: FormControlService) {
    super();
  }

  get selfFromParent(): UntypedFormArray {
    return this.group && this.group.get(this.config.prop)
      ? (this.group.get(this.config.prop) as UntypedFormArray)
      : null;
  }

  /* istanbul ignore next */
  ngOnInit() {
    this.config = new FormGroupArrayConfig({ ...this.config });
    this.formArray = this.group
      ? this.selfFromParent
      : new UntypedFormArray([]);

    const isRequiredAndIsEmpty =
      this.formArray.controls?.length === 0 && !this.config.allowEmpty;
    /**
     * Added else condition to write value in the existing form
     */
    if (isRequiredAndIsEmpty) {
      this.addGroup();
    } else {
      this.writeValue(this.formArray.value);
    }
  }

  addGroup(values = {}, index = -1) {
    const formGroup = new UntypedFormGroup({});
    this.config.controls.forEach((control) => {
      const value =
        values[control.prop] !== undefined ? values[control.prop] : null;
      this.formService.addControlToFormGroup(formGroup, control, {
        [control.prop]: value,
      });
    });

    if (index !== -1) {
      this.formArray.insert(index, formGroup);
      this.openedAccordionIndex.push(index + 1);
    } else {
      this.formArray.push(formGroup);
      this.openedAccordionIndex.push(this.formArray.length - 1);
    }
  }

  removeGroup(index) {
    this.formArray.removeAt(index);
  }

  addDuplicate(data) {
    if (data.values && Array.isArray(data.values)) {
      data.values.forEach((value, index) => {
        if (index === data.indexval) {
          this.addGroup(value, index);
        }
      });
    }
  }

  writeValue(values: any) {
    if (values && Array.isArray(values)) {
      values.forEach((value, index) => {
        if (this.formArray.get([index])) {
          this.formArray.at(index).patchValue(value);
        } else {
          this.addGroup(value);
        }
      });
    } else {
      this.addGroup();
    }
  }

  setDisabledState(disabled: boolean) {
    disabled ? this.formArray.disable() : this.formArray.enable();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
