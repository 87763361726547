import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { OutOfNetworkConfig } from '../../../templates/authorization-submission/authorization-submission.model';
@Component({
  selector: 'detail-tabs',
  templateUrl: './detail-tabs.component.html',
  styleUrls: ['./detail-tabs.component.scss'],
})
export class DetailTabsComponent implements OnInit {
  @Input() tabConfig;
  @Input() tabData;
  @Input() customInitActiveTab;
  @Input() outOfNetwork: OutOfNetworkConfig;
  @Output() selectedTab: EventEmitter<any> = new EventEmitter<any>();
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() customMethod: EventEmitter<any> = new EventEmitter();
  activeTab;

  ngOnInit() {
    if (this.customInitActiveTab) {
      this.tabSelected(this.customInitActiveTab);
    } else {
      this.activeTab = 0;
    }
  }

  customEventMethod({ event: event, context: context }) {
    // if a `customMethod` has been set on the component, emit event on that method.
    if (this.customMethod?.observers?.length) {
      // passing `event`and component `context` so can access component data in customMethod.
      this.customMethod?.emit({ event: event, context: context });
    }
  }

  tabSelected(ev) {
    this.customEventMethod({ event: ev, context: this });
    this.activeTab = ev;
    this.selectedTab?.emit(this.activeTab);
  }

  onSave(event: any) {
    this.save?.emit(event);
  }
}
