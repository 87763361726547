import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../../../shared/services';
import { GenericServices } from '../../../shared/services/generic.service';
import { ClaimAppealConfig } from '../claim-redetermination/claim-redetermination.model';
import { getAppealType } from '../claim-appeal.helper';

@Component({
  selector: 'claim-reconsideration',
  templateUrl: './claim-reconsideration.component.html',
  styleUrls: ['./claim-reconsideration.component.scss'],
})
export class ClaimReconsiderationComponent implements OnInit {
  public config: ClaimAppealConfig;
  public appealType: string;
  readonly claimNumber: string = this.route.snapshot?.params?.claim_number;
  readonly pageName: string = this.route.snapshot?.data?.pageName;

  constructor(
    private route: ActivatedRoute,
    public configService: ConfigService,
    public genericServices: GenericServices
  ) {}

  ngOnInit(): void {
    this.config = this.configService.getPageConfig(this.pageName);
    this.appealType = getAppealType(this.pageName);
  }
}
