import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SpinnerComponent } from './spinner.component';
import { SpinnerService } from './spinner.service';
import { ZipBusyComponent } from './zip-busy.component';
import { ZipBusyDirective } from './zip-busy.directive';

@NgModule({
  imports: [CommonModule, OverlayModule],
  exports: [ZipBusyDirective],
  declarations: [ZipBusyComponent, ZipBusyDirective, SpinnerComponent],
  providers: [SpinnerService],
})
export class ZipBusyModule {}
