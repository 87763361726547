import { Pipe, PipeTransform } from '@angular/core';
import { ObjectStatus, ObjectStatuses } from '@zipari/shared-ds-util-status';

@Pipe({
  name: 'statusMap',
})
export class StatusMapPipe implements PipeTransform {
  transform(status: string) {
    return typeof status === 'string' ? `status--${status.toLowerCase()}` : '';
  }
}

@Pipe({
  name: 'statusBackground',
})
export class StatusBackgroundPipe implements PipeTransform {
  transform(hideBackground: boolean): string {
    return hideBackground ? ' no-background ' : '';
  }
}

@Pipe({
  name: 'statusFitContent',
})
export class StatusFitContentPipe implements PipeTransform {
  transform(fitContent: boolean) {
    return fitContent ? 'StatusWidth--fitContent' : '';
  }
}

type StatusType = 'success' | 'info' | 'error' | 'pending' | 'expired';
@Pipe({
  name: 'getStatusTypeFromValue',
})
export class GetStatusTypeFromValuePipe implements PipeTransform {
  transform(config: ObjectStatuses, statusValue: string): StatusType {
    let statusType: StatusType;
    Object.keys(config).forEach((type: StatusType) => {
      const statusObject = config[type].find(
        (objectStatus: ObjectStatus) =>
          objectStatus.value.toLowerCase() === statusValue.toLowerCase()
      );
      if (statusObject) {
        statusType = type;
      }
    });

    return statusType ? statusType : null;
  }
}
