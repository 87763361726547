import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DigitalDataModule } from '../../shared/modules/digital-data/digital-data.module';

import { IconModule } from '../icon/icon.module';
import { PaginatorComponent } from './paginator.component';

@NgModule({
  declarations: [PaginatorComponent],
  imports: [CommonModule, IconModule, DigitalDataModule],
  exports: [PaginatorComponent],
})
export class PaginatorModule {}
