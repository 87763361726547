<div class="wrapper">
  <div class="address" *ngFor="let addressType of typeProps">
    <label class="form-control__label">
      <p class="t-data t-bold t-capitalize">{{ addressType }}</p>
    </label>

    <div class="basic-address__row">
      <form-control
        *ngFor="let addressConfig of addressLine1[addressType] | keyvalue"
        class="basic-address__control"
        [control]="
          formGroup.get(config.prop).get(addressType).get(addressConfig.key)
        "
        [config]="addressConfig.value"
        direction="grouping"
      >
      </form-control>
    </div>

    <div class="basic-address__row basic-address__row--2">
      <form-control
        *ngFor="let addressConfig of addressLine2[addressType] | keyvalue"
        class="basic-address__control"
        [control]="
          formGroup.get(config.prop).get(addressType).get(addressConfig.key)
        "
        [config]="addressConfig.value"
        direction="grouping"
      >
      </form-control>
    </div>
  </div>
</div>
