import { ButtonConfig } from '@zipari/shared-ds-util-button';

export class MessagePageConfig {
  backButton: ButtonConfig;
  endpoint: string;
  header: MessageHeaderConfig;
  reply: ReplyConfig;
}

export class ReplyConfig {
  attachment: any;
  attachmentButton: ButtonConfig;
  backButton: ButtonConfig;
  cancelMessage: ButtonConfig;
  replyButton: ButtonConfig;
  replyForm: {
    content: MessageReplyFormConfig;
    attachment_ids: MessageReplyFormConfig;
  };
  sendMessage: ButtonConfig;
  success: any;
}

export class MessageHeaderConfig {
  label?: string;
  subject?: string;
  category?: string;
  name?: string;
  case_number?: string | number;
}

export class MessageReplyFormConfig {
  label?: string;
  prop: string;
  type?: string;
  maxLength?: number;
}

export const messagesBaseUrl = '/api/provider-portal/message-center/threads/';

export const messagesEndpoints = {
  bulkArchiveUrl: `${messagesBaseUrl}bulk-archive/`,
  bulkUnarchiveUrl: `${messagesBaseUrl}bulk-unarchive/`,
  unreadCount: `${messagesBaseUrl}unread-count/`,
};
