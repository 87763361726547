import { HttpParams } from '@angular/common/http';
import { PaginatorConfiguration } from '@zipari/design-system';
import { ProviderAdminSearchParams } from '../provider-admins.model';

export const getProviderAdminApiParams = (
  paginator: PaginatorConfiguration,
  sortKey?: string | null,
  providerAdminSearchParams?: ProviderAdminSearchParams
): HttpParams => {
  let params = new HttpParams().set('page', paginator.currentPage);

  params = params.set('role', 'Provider Admin');

  if (paginator.rowsPerPage) {
    params = params.set('page_size', paginator.rowsPerPage);
  }

  if (sortKey) {
    params = params.set('ordering', sortKey);
  }

  if (providerAdminSearchParams?.taxId) {
    params = params.set('tax_id', providerAdminSearchParams.taxId);
  }

  if (providerAdminSearchParams?.emailAddress) {
    params = params.set(
      'email_address',
      providerAdminSearchParams.emailAddress
    );
  }

  return params;
};
