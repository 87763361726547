import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { objectToArray } from '@zipari/shared-util-common';
import { SquidexConfig } from '@zipari/shared-data-access-squidex';
import { DocumentCenterEventService } from './services/document-center-events.service';
import { DocumentEventType } from './util/document-center.constants';
import {
  DocumentCenterConfig,
  DocumentCenterTab,
  UserDetails,
} from './util/document-center.model';
import { DocumentCenterStateService } from './services/document-center-state.service';
import { cloneObject } from '@zipari/web-utils';
@Component({
  selector: 'zipari-document-center',
  templateUrl: './document-center.component.html',
  styleUrls: ['./document-center.component.scss'],
})
export class DocumentCenterComponent implements OnInit {
  documentCenterConfig!: DocumentCenterConfig;
  squidexConfig!: SquidexConfig;
  tabLabels: string[] = [];
  tabs: DocumentCenterTab[] = [];
  activeTab!: DocumentCenterTab;
  selectedTabIndex = 0;
  userDetails: UserDetails | null = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private documentCenterEventService: DocumentCenterEventService,
    private documentCenterStateService: DocumentCenterStateService
  ) {}

  ngOnInit(): void {
    this.documentCenterConfig =
      this.route.snapshot?.data['documentCenterConfig'];
    this.squidexConfig = this.route.snapshot?.data['squidexConfig'];
    this.userDetails = this.route.snapshot?.data['userDetails'];
    this.setTabs();
    this.setState();
    this.documentCenterEventService.captureEvent({
      type: DocumentEventType.documentCenterInitialized,
    });
  }

  setTabs(): void {
    this.setTabsFromConfig();
    let tabName = '';
    this.route.firstChild?.paramMap.subscribe((params: Params) => {
      tabName = params.get('tab');
    });

    if (tabName) {
      this.updateTabFromRoute(tabName);
    } else {
      this.setActiveTab();
    }
  }

  setActiveTab(activeTab?: DocumentCenterTab): void {
    if (this.tabs.length) {
      this.activeTab = activeTab ? activeTab : this.tabs[0];
      if (!activeTab) {
        this.selectedTabIndex = 0;
        this.router.navigate([this.activeTab.link], {
          relativeTo: this.route,
        });
      }
      this.documentCenterEventService.captureEvent({
        type: DocumentEventType.documentTabSelected,
        activeTab: this.activeTab.prop,
      });
    }
  }

  tabSelected(tab: DocumentCenterTab): void {
    if (!tab) {
      return;
    }
    this.activeTab = cloneObject(tab);
    this.setState();
    this.router.navigate([this.activeTab.link], {
      relativeTo: this.route,
    });
    this.documentCenterEventService.captureEvent({
      type: DocumentEventType.documentTabSelected,
      activeTab: this.activeTab.prop,
    });
  }

  setState(): void {
    this.documentCenterStateService.setState({
      activeTab: cloneObject(this.activeTab),
      documentCenterConfig: this.documentCenterConfig,
      userDetails: this.userDetails,
      squidexConfig: this.squidexConfig,
    });
  }

  setTabsFromConfig(): void {
    this.tabs = this.documentCenterConfig?.tabs
      ? objectToArray(this.documentCenterConfig.tabs)?.sort((a, b) =>
          a.priority < b.priority ? -1 : 1
        )
      : [];
    this.tabLabels = this.tabs.map((tab) => tab.label);
  }

  updateTabFromRoute(routerTabName: string): void {
    const activeTab = this.tabs.find(
      (tab) => tab.link?.replace('/', '').toLowerCase() === routerTabName
    );
    this.selectedTabIndex = this.tabs.findIndex(
      (tab) => tab.link?.replace('/', '').toLowerCase() === routerTabName
    );
    this.setActiveTab(activeTab);
  }
}
