<article *ngIf="reviewClaimConfig" class="ReviewForm">
  <h4
    class="ReviewForm__Header t-bold"
    [innerHTML]="reviewClaimConfig.header"
  ></h4>

  <p
    class="ReviewForm__SubHeader t-data"
    *ngIf="reviewClaimConfig.subHeader"
    [innerHTML]="reviewClaimConfig.subHeader"
  ></p>

  <div class="Badge__Container" *ngIf="currentStep === CONFIRMATION_STEP">
    <badge
      [config]="badge.value"
      [context]="appealForm"
      *ngFor="let badge of reviewClaimConfig.badgeItems | keyvalue"
    ></badge>
  </div>

  <card
    class="ReviewForm__Card"
    [config]="{ hideSubHeader: true }"
    *ngFor="let card of reviewClaimConfig.cards | keyvalue"
    [ngStyle]="{ order: card.value.position }"
  >
    <div title>
      <h6 class="t-bold" [innerHTML]="card.value.title"></h6>
      <p [innerHTML]="card.value.subHeader"></p>
    </div>
    <div header-action>
      <icon
        *ngIf="card.value.showEditIcon"
        name="edit"
        role="button"
        (click)="editDetail.emit(card.key)"
      ></icon>
    </div>

    <div body class="ReviewForm__CardBody">
      <model-attribute
        [ngStyle]="{ 'grid-area': attribute.grid }"
        *ngFor="let attribute of card.value.attributes"
        class="ReviewForm__Attribute"
        [config]="attribute"
        [context]="appealForm"
      >
      </model-attribute>
      <ng-container *ngIf="card.key == 'additionalDocumentation'">
        <div class="ReviewForm__DocumentHeader">
          <icon
            name="pdf"
            [config]="{ fill: 'black' }"
            class="ReviewForm__DocumentIcon"
          >
          </icon>
          <p class="t-bold" [innerHtml]="card.value.documentsHeader"></p>
        </div>
        <div
          class="ReviewForm__Document"
          *ngFor="let document of documentUploadService.uploadedFiles"
        >
          <a
            class="t-data t-link"
            (click)="downloadDocument(document.id)"
            [innerHtml]="document.file_name"
          ></a>
        </div>
      </ng-container>
      <p
        class="ReviewForm__CardDescription t-data"
        *ngIf="card.value.cardDescription"
        [innerHtml]="card.value.cardDescription"
      ></p>
    </div>
  </card>
</article>
