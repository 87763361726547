import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../../notes/button/button.module';
import { ExternalSiteWarningModule } from '../external-site-warning-modal/external-site-warning.module';
import { LinkComponent } from './link.component';

@NgModule({
  declarations: [LinkComponent],
  imports: [CommonModule, ButtonModule, ExternalSiteWarningModule],
  exports: [LinkComponent],
})
export class LinkModule {}
