<section class="layout--list">
  <section class="layout--list__content">
    <header class="layout--list__header">
      <h4 class="t-bold">Portal Users</h4>

      <zip-button
        (click)="onNewUserClick()"
        level="high"
        content="New User"
        icon="add"
      >
      </zip-button>
    </header>

    <zip-table [options]="config?.table"></zip-table>
  </section>
</section>

<section *ngIf="modalIsVisible" @fade class="modal__mask"></section>

<section *ngIf="modalIsVisible" @slideRight class="modal modal--panel">
  <header class="modal__header">
    <p class="t-data t-bold">{{ modal.header }}</p>
  </header>

  <section class="modal__body">
    <div *ngFor="let formGroup of modal.formGroups" class="form-group">
      <header class="form-group__header">
        <h6 class="t-bold title">{{ formGroup.header }}</h6>
      </header>

      <form-control
        *ngFor="let control of formGroup.controls"
        [config]="control"
        [control]="form.get(formGroup.prop).get(control.prop)"
        [group]="form.get(formGroup.prop)"
        direction="row"
      >
      </form-control>
    </div>
  </section>

  <footer class="modal__footer">
    <zip-button (click)="onCancelClick()" content="Cancel" level="low">
    </zip-button>

    <zip-button
      [disabled]="form.invalid"
      (click)="onSaveClick()"
      content="Save"
      level="high"
    >
    </zip-button>
  </footer>
</section>
