import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  BadgeModule,
  ButtonModule,
  CardModule,
  FormControlModule,
  FormGroupModule,
  MessageBannerModule,
  ModalModule,
  ModelAttributeModule,
  FileUploaderModule,
} from '@zipari/design-system';
import { ZipBusyModule } from '../../../shared/modules/zip-busy/zip-busy.module';
import { FlyoutDiagnosisModule } from '../../shared/modules/flyout-diagnosis/flyout-diagnosis.module';
import { FlyoutDropdownModule } from '../../shared/modules/flyout-dropdown/flyout-dropdown.module';
import { ReferralSubmissionComponent } from './referral-submission.component';

@NgModule({
  declarations: [ReferralSubmissionComponent],
  exports: [ReferralSubmissionComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormGroupModule,
    ButtonModule,
    ZipBusyModule,
    ModelAttributeModule,
    CardModule,
    ModalModule,
    FormControlModule,
    FlyoutDropdownModule,
    FlyoutDiagnosisModule,
    FileUploaderModule,
    BadgeModule,
    MessageBannerModule,
  ],
})
export class ReferralSubmissionModule {}
