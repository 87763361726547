import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MAT_DATE_FORMATS } from '@angular/material/core';
import { IconModule } from '../../icon/icon.module';
import { DatePickerComponent } from './date-picker.component';
import { datePickerFormat } from './date-picker.constants';

const matMomentDateFormats = {
  parse: {
    dateInput: datePickerFormat.dateInput,
  },
  display: {
    dateInput: datePickerFormat.displayDate,
    monthYearLabel: datePickerFormat.monthYearLabel,
    dateA11yLabel: datePickerFormat.dateA11yLabel,
    monthYearA11yLabel: datePickerFormat.monthYearA11yLabel,
  },
};
@NgModule({
  declarations: [DatePickerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    IconModule,
  ],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: matMomentDateFormats }],
  exports: [DatePickerComponent],
})
export class DatePickerModule {}
