import { Pipe, PipeTransform } from '@angular/core';
import { Condition } from '@zipari/shared-ds-util-form';
import { BusinessRulesService } from '../services/business-rules.service';
import { LoggerService } from '../services/logger.service';

@Pipe({ name: 'zipConditional' })
export class ConditionalPipe implements PipeTransform {
  constructor(
    private businessRulesService: BusinessRulesService,
    private loggerService: LoggerService
  ) {}

  transform(context: any, options: Condition): boolean {
    if (options) {
      return this.businessRulesService.retrieveResultFromBusinessRule(
        options,
        context
      );
    }

    this.loggerService.warn(
      'ConditionalPipe warning. You must pass options of the type "Condition" to this pipe.'
    );

    return false;
  }
}
