import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  DatagridModule,
  ModalModule,
  PaginatorModule,
  SpinnerModule,
  FormGroupModule,
  ButtonModule,
  ErrorEmptyModule,
} from '@zipari/design-system';
import { ProviderAdminsComponent } from './provider-admins.component';
import { ProviderAdminsListComponent } from './provider-admins-list/provider-admins-list.component';

const routes: Routes = [
  {
    path: '',
    component: ProviderAdminsComponent,
    data: {
      pageName: 'provider-admins',
    },
  },
];

@NgModule({
  declarations: [ProviderAdminsComponent, ProviderAdminsListComponent],
  exports: [ProviderAdminsComponent, RouterModule],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    DatagridModule,
    PaginatorModule,
    SpinnerModule,
    ModalModule,
    FormGroupModule,
    ButtonModule,
    ErrorEmptyModule,
  ],
  providers: [],
})
export class ProviderAdminsModule {}
