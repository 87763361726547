import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AccordionModule,
  ButtonModule,
  CardModule,
  CheckboxListModule,
  FormControlModule,
  FormGroupModule,
  IconModule,
  MessageBannerModule,
  ModalModule,
  ModelAttributeModule,
  SpinnerModule,
  TabsModule,
} from '@zipari/design-system';
import { ZipBusyModule } from '../../../shared/modules/zip-busy';
import { DetailTabsModule } from '../../shared/modules/detail-tabs/detail-tabs.module';
import { ViewerProvisioningModule } from '../user-provisioning/view/viewer/viewer-provisioning.module';
import { ManagePortalUsersCreateUserModule } from '../manage-portal-users-create-user/manage-portal-users-create-user.module';
import { ApiErrorMessageModalModule } from '../../shared/modules/api-error-message-modal/api-error-message-modal.module';
import { ManagePortalUsersDetailComponent } from './manage-portal-users-detail.component';
import { SuspendUserModalComponent } from './components/suspend-user-modal/suspend-user-modal.component';
import { SuspendUserComponent } from './components/suspend-user/suspend-user.component';
import { CheckListCardComponent } from './components/check-list-card/check-list-card.component';
import { FooterDynamicButtonComponent } from './components/footer-dynamic-button/footer-dynamic-button.component';
import { ReviewSuspendUserComponent } from './components/review-suspend-user/review-suspend-user.component';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';

@NgModule({
  declarations: [
    ManagePortalUsersDetailComponent,
    SuspendUserModalComponent,
    SuspendUserComponent,
    CheckListCardComponent,
    FooterDynamicButtonComponent,
    ReviewSuspendUserComponent,
    ActivateUserComponent,
  ],
  imports: [
    CommonModule,
    ModelAttributeModule,
    DetailTabsModule,
    ButtonModule,
    ZipBusyModule,
    ModalModule,
    FormControlModule,
    ViewerProvisioningModule,
    TabsModule,
    AccordionModule,
    FormGroupModule,
    IconModule,
    ManagePortalUsersCreateUserModule,
    MessageBannerModule,
    CardModule,
    CheckboxListModule,
    SpinnerModule,
    ApiErrorMessageModalModule,
  ],
  exports: [ManagePortalUsersDetailComponent],
})
export class ManagePortalUsersDetailModule {}
