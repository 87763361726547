import {
  AfterViewInit,
  Component,
  Directive,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneObject } from '@zipari/web-utils';
import { Subject, takeUntil, tap } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { isEqual } from 'lodash';
import { AllControlsConfiguration } from '@zipari/shared-ds-util-form';
import { ConfigService } from '../../../../../../shared/services';
import {
  AdditionalDetailsModalConfig,
  ControlOptions,
  OperatingHour,
  PhoneNumber,
  ProviderIdentifierPayload,
  SectionItems,
  SiteLocation,
  SiteLocationEditFormFileds,
  SiteLocationsEditFormConfiguration,
  SiteLocationsProfileConfig,
  SiteLocationsProfileEditConfig,
} from '../../../site-locations-attestation/site-locations-attestation.model';
import { SiteLocationsProfileService } from '../../services/site-locations-profile.service';
import {
  BUTTON_TYPE,
  BUTTON_TYPE_NAME,
  PROVIDER_IDENTIFIERS,
  SITELOCATION_CONSTANTS,
  SITE_LOCATION_PROFILE_FORMAT_OPTIONS,
} from '../../../site-locations-attestation/site-location-attestation.constant';
import { ProviderUpdateToolService } from '../../../provider-update-tool.service';
import { SiteLocationsProfileHttpService } from '../../services/site-locations-profille-http.service';
import SiteLocationAttestationEventService from '../../../site-locations-attestation/site-location-attestation.event.service';
import {
  camelCase,
  camelToSnakeCase,
  toCamel,
} from '../../../../../../shared/utilities/case-conversion.utils';
import { AdministrationAttributes } from '../../../site-locations-attestation/site-location-attestation.constant';
import { SiteLocationDetailsContextKeys } from '../../../site-locations/details/manage-site-location-detail.constant';
import { setFormatOption } from '../../../../../../shared/utilities/set-format-option.utils';
import { LocationHoursComponent } from '../../../controls/location-hours/location-hours.component';
import { getFormattedTime } from '../../../../../../shared/utilities/dates';
import { AnalyticsService } from '../../../../../../shared/services/analytics.service';
import { CX_CALLS } from '../../../../../../shared/constants/cx-calls.constant';
import { setAdditionalDetailPayload } from '../../../site-locations-attestation/site-location-attestation.helper';

@Directive({
  selector: '[editButtonClicked]',
})
export class SetDropdownValueOnEditClicked implements AfterViewInit {
  @Output() editButtonClicked: EventEmitter<object> = new EventEmitter();

  ngAfterViewInit() {
    this.editButtonClicked.emit();
  }
}
@Component({
  selector: 'site-locations-profile',
  templateUrl: './site-locations-profile.component.html',
  styleUrls: ['./site-locations-profile.component.scss'],
})
export class SiteLocationsProfileComponent implements OnInit, OnDestroy {
  @ViewChild(LocationHoursComponent) locationHours: LocationHoursComponent;

  config: SiteLocationsProfileConfig;
  editProfileFlyoutConfig: SiteLocationsProfileEditConfig;
  context: any;
  id: string;

  editModal: boolean;
  openAccordionIndex: number | undefined;
  locationHoursForm = new UntypedFormGroup({});
  editFields = new UntypedFormGroup({});
  locationOperatingHours: object = {};
  hasControlChange: boolean = false;
  hasArrayControlChange: boolean = false;
  payload: any = {};
  editResponse: SiteLocation;
  formGroupSnapshot: any;
  arrayControlSnapshot;
  showAdditionalDetailsModal: boolean;
  additionalDetailsForm: UntypedFormGroup = new UntypedFormGroup({});
  isLoading: boolean = true;
  locationOpenDays: string[] = [];
  hasLoctionHoursFormChanged: boolean = false;
  siteLocationMessage: string;

  private destroy = new Subject();
  constructor(
    private configService: ConfigService,
    private route: ActivatedRoute,
    private siteLocationsProfileService: SiteLocationsProfileService,
    public providerUpdateToolService: ProviderUpdateToolService,
    private siteLocationsProfileHttpService: SiteLocationsProfileHttpService,
    private siteLocationAttestationEventService: SiteLocationAttestationEventService,
    private analyticsService: AnalyticsService
  ) {}

  get additionalDetailsModalConfig() {
    return this.config.siteLocationDetails?.additionalDetailsModal;
  }

  ngOnInit(): void {
    this.setSiteLocationProfileConfig();
    this.getSiteLocationDetails();
    this.captureSiteLocationsReviewEvent();
    this.locationHoursFormValueChange();
  }

  captureSiteLocationsReviewEvent(): void {
    this.analyticsService.sendEvent(
      CX_CALLS.pp_site_location_reviewed.event_key
    );
  }

  onDropdowArrayCreated(): void {
    this.onArrayControlsValueChange();
  }

  onFormCreated(): void {
    this.onFormGroupValueChange();
  }

  onArrayControlsValueChange(): void {
    const initialValue = this.providerUpdateToolService.getFormGroup(
      SITELOCATION_CONSTANTS.editSiteDetails
    )?.value;
    this.arrayControlSnapshot = initialValue;
    this.providerUpdateToolService
      .getFormGroup(SITELOCATION_CONSTANTS.editSiteDetails)
      ?.valueChanges.subscribe((value) => {
        this.hasArrayControlChange = Object.keys(initialValue).some(
          (key) => !isEqual(value[key], initialValue[key])
        );
      });
  }

  onFormGroupValueChange(): void {
    const formGroupInitialValue = this.editFields.value;
    this.formGroupSnapshot = this.editFields.value;
    this.editFields.valueChanges.subscribe((value) => {
      this.hasControlChange = Object.keys(formGroupInitialValue).some(
        (key) => !isEqual(value[key], formGroupInitialValue[key])
      );
    });
  }

  locationHoursFormValueChange(): void {
    this.locationHoursForm.valueChanges.subscribe(() => {
      this.hasLoctionHoursFormChanged =
        this.locationHours && !this.locationHours?.validate();
    });
  }

  getSiteLocationDetails(): void {
    this.context = {};
    this.siteLocationsProfileHttpService
      .getSiteLocationsDetailsById(this.config, this.id)
      .pipe(
        tap((response: SiteLocation) => {
          response = camelCase(response);
          this.context = cloneObject(response);
          this.updateSiteLocationContext(response, this.context);
        }),
        takeUntil(this.destroy)
      )
      .subscribe();
  }

  updateSiteLocationContext(siteLocationDetail: SiteLocation, context): void {
    const phoneNumbers: string[] = [];
    if (siteLocationDetail?.phoneNumbers) {
      siteLocationDetail.phoneNumbers.forEach((phoneNumber: PhoneNumber) => {
        phoneNumbers.push(phoneNumber.phoneNumber);
      });
      (context.phoneNumbers as unknown) = phoneNumbers;
    }

    this.siteLocationsProfileService
      .updateFacilityList(siteLocationDetail, this.config)
      .then((response) => {
        this.context.facilityList = response;
        this.isLoading = false;
      });

    if (siteLocationDetail?.operatingHours) {
      this.context.operatingHours =
        this.siteLocationsProfileService.updateContextOperatingHours(
          siteLocationDetail
        );
      siteLocationDetail.operatingHours.forEach(
        (operatingHour: OperatingHour) => {
          operatingHour.openingTime = getFormattedTime(
            operatingHour?.openingTime,
            SITELOCATION_CONSTANTS.hourTwentyFour
          );
          operatingHour.closingTime = getFormattedTime(
            operatingHour?.closingTime,
            SITELOCATION_CONSTANTS.hourTwentyFour
          );
          operatingHour?.day
            ? (this.context[operatingHour.day] = operatingHour)
            : null;
        }
      );
    }

    if (siteLocationDetail?.locationContacts) {
      this.siteLocationsProfileService.updateContextLocationContact(
        siteLocationDetail,
        this.editProfileFlyoutConfig,
        this.context
      );
    }
    this.filterProfileSectionAttributes();
  }

  setSiteLocationProfileConfig(): void {
    const pageName = this.route.snapshot?.data[SITELOCATION_CONSTANTS.pageName];
    const siteLocationConfig = this.configService.getPageConfig(
      SITELOCATION_CONSTANTS.siteLocationsAttestation
    );
    this.config = siteLocationConfig ? siteLocationConfig[pageName] : {};
    this.config.reviewPractitionersButton[BUTTON_TYPE] =
      BUTTON_TYPE_NAME.PRACTITIONER;
    this.siteLocationAttestationEventService.emitDynamicButtonConfig(
      this.config.reviewPractitionersButton
    );
    this.siteLocationAttestationEventService.emitSelectedSiteLocations([]);
    this.id = this.route.snapshot.params.id;

    this.addFormatOptions(this.config);
    this.editProfileFlyoutConfig = this.config?.siteLocationDetails?.editFlyout;
  }

  addFormatOptions(config: SiteLocationsProfileConfig): void {
    config.siteLocationDetails.sections.forEach((section: SectionItems) => {
      SITE_LOCATION_PROFILE_FORMAT_OPTIONS.forEach(
        (item: { prop: string; key: string }) => {
          setFormatOption(section.fields.attributes, item.prop, item.key);
        }
      );
    });
  }

  setDropdownValueOnEditClicked(): void {
    if (this.editProfileFlyoutConfig?.formFields) {
      this.editProfileFlyoutConfig.formFields.forEach(
        (formField: SiteLocationEditFormFileds) => {
          if (formField?.controls) {
            formField.controls.forEach(
              (control: SiteLocationsEditFormConfiguration) => {
                this.toggleDateControl(this.editFields.controls, control);
                const option = (control.options as ControlOptions[])?.find(
                  (_option: ControlOptions) =>
                    _option.value?.id == this.editFields.value.type?.id
                );
                if (option) {
                  this.editFields.controls[control.prop].setValue(
                    option.value,
                    { emitEvent: false }
                  );
                }
              }
            );
          }
        }
      );
    }
  }

  toggleDateControl(
    editFieldsControl: object,
    control: SiteLocationsEditFormConfiguration
  ): void {
    const dateFieldControl = editFieldsControl[control.enableOnTrue];
    if (
      control?.enableOnTrue &&
      dateFieldControl &&
      editFieldsControl[control.prop]
    ) {
      if (!editFieldsControl[control.prop].value) {
        dateFieldControl.disable();
      }
      editFieldsControl[control.prop].valueChanges.subscribe(
        (value: object) => {
          if (value) {
            dateFieldControl.enable();
          } else {
            dateFieldControl.reset();
            dateFieldControl.setValue(null);
            dateFieldControl.disable();
          }
        }
      );
    }
  }

  openEditModal(index?: number): void {
    this.editModal = true;
    this.openAccordionIndex = index;
  }

  closeEditModal(): void {
    this.editModal = false;
  }

  isDisable(): boolean {
    return (
      !this.hasControlChange &&
      !this.hasArrayControlChange &&
      this.hasLoctionHoursFormChanged
    );
  }

  updatePayload(): void {
    const editFieldsValue: any = this.editFields.value;
    const arrayControlsValue: any = this.providerUpdateToolService.getFormGroup(
      SITELOCATION_CONSTANTS.editSiteDetails
    )?.value;

    this.setPayloadForEditFormGroup(editFieldsValue, arrayControlsValue);
    this.setPayloadForArrayControlsValue(editFieldsValue, arrayControlsValue);
    this.setAdditionalDetailsFormValueToPayload();
    this.setLocationHoursPayload();
  }

  setLocationHoursPayload(): void {
    const hours: OperatingHour[] = [];
    Object.entries(this.locationHoursForm.value).forEach((value: any) => {
      if (
        this.locationOpenDays.find((day: string) => {
          if (
            day === value[0] &&
            (value[1][SITELOCATION_CONSTANTS.start]?.length ||
              value[1][SITELOCATION_CONSTANTS.end]?.length)
          ) {
            return true;
          } else return false;
        })
      ) {
        const hour: object = {};
        hour[SITELOCATION_CONSTANTS.openingTime] = getFormattedTime(
          value[1][SITELOCATION_CONSTANTS.start],
          SITELOCATION_CONSTANTS.hourTwelve
        );
        hour[SITELOCATION_CONSTANTS.day] = value[0];
        hour[SITELOCATION_CONSTANTS.closingTime] = getFormattedTime(
          value[1][SITELOCATION_CONSTANTS.end],
          SITELOCATION_CONSTANTS.hourTwelve
        );
        hours.push(hour);
      }
    });

    this.payload = {
      ...this.payload,
      operating_hours: hours,
    };
  }

  setPayloadForEditFormGroup(editFieldsValue, arrayControlsValue) {
    Object.keys(editFieldsValue).forEach((key) => {
      if (
        !isEqual(editFieldsValue[key], this.formGroupSnapshot[key]) &&
        !key.includes(SITELOCATION_CONSTANTS.administrationAttributes)
      ) {
        {
          if (key.includes(SITELOCATION_CONSTANTS.contact)) {
            this.payload[SITELOCATION_CONSTANTS.locationContacts] = [];
            this.payload[SITELOCATION_CONSTANTS.locationContacts].push(
              this.setLocationContacts(editFieldsValue, arrayControlsValue)
            );
          } else if (key.includes(SITELOCATION_CONSTANTS.identifier)) {
            this.payload[SITELOCATION_CONSTANTS.identifiers] =
              this.getProviderIdentifiers(editFieldsValue);
          } else {
            this.payload = {
              ...this.payload,
              [key]: editFieldsValue[key],
            };
          }
        }
      }

      Object.keys(AdministrationAttributes).forEach((attribute) => {
        this.setPayloadForAdministrationAttributes(
          key,
          attribute,
          editFieldsValue
        );
      });
    });
  }

  setPayloadForAdministrationAttributes(
    key: string,
    attribute: string,
    editFieldsValue
  ): void {
    if (
      key.includes(
        SITELOCATION_CONSTANTS.culturalCompetencyTrainingCompletionDate
      ) &&
      attribute ===
        SITELOCATION_CONSTANTS.culturalCompetencyTrainingCompletionDate
    ) {
      this.payload[SITELOCATION_CONSTANTS.administrationAttributesKey] = {
        ...this.payload[SITELOCATION_CONSTANTS.administrationAttributesKey],
        [AdministrationAttributes[attribute]]: editFieldsValue[key]
          ? editFieldsValue[key]
          : this.context.administrationAttributes
              ?.culturalCompetencyTrainingCompletionDate,
      };
    } else if (key.includes(attribute)) {
      this.payload[SITELOCATION_CONSTANTS.administrationAttributesKey] = {
        ...this.payload[SITELOCATION_CONSTANTS.administrationAttributesKey],
        [AdministrationAttributes[attribute]]: editFieldsValue[key]
          ? editFieldsValue[key]
          : false,
      };
    }
  }

  setPayloadForArrayControlsValue(editFieldsValue, arrayControlsValue) {
    Object.keys(arrayControlsValue).forEach((key) => {
      if (
        arrayControlsValue &&
        this.arrayControlSnapshot &&
        !isEqual(arrayControlsValue[key], this.arrayControlSnapshot[key])
      ) {
        const modifyArrayControls = this.modifyArrayControls(
          arrayControlsValue[key]
        );
        if (key === SITELOCATION_CONSTANTS.phoneNumbers) {
          this.payload = {
            ...this.payload,
            [key]: modifyArrayControls.map((phoneNumber: string) => ({
              phone_number: phoneNumber,
              extension: 1,
            })),
          };
        } else if (key === SITELOCATION_CONSTANTS.locationContacts) {
          this.payload[SITELOCATION_CONSTANTS.locationContacts] = [];
          this.payload[SITELOCATION_CONSTANTS.locationContacts].push(
            this.setLocationContacts(editFieldsValue, arrayControlsValue)
          );
        } else if (key === SITELOCATION_CONSTANTS.facilityList) {
          const facilityId = modifyArrayControls.map((facility) => facility.id);
          this.payload[SITELOCATION_CONSTANTS.affiliatedHospitalFacilityIds] =
            facilityId;
        } else {
          this.payload = {
            ...this.payload,
            [key]: modifyArrayControls,
          };
        }
      }
    });
  }

  setAdditionalDetailsFormValueToPayload(): void {
    if (this.additionalDetailsForm?.value?.effective_date) {
      this.payload[SITELOCATION_CONSTANTS.changeRequestEffectiveDate] =
        this.additionalDetailsForm?.value?.effective_date;
    }
    if (this.additionalDetailsForm?.value?.notes) {
      this.payload[SITELOCATION_CONSTANTS.changeRequestNotes] =
        this.additionalDetailsForm?.value?.notes;
    }

    this.payload = setAdditionalDetailPayload(
      this.payload,
      this.additionalDetailsForm.value
    );
  }

  setLocationContacts(editFieldsValue: any, arrayControlsValue: any): object {
    let locationContactPayload: any = cloneObject(
      this.context[SITELOCATION_CONSTANTS.locationContact]
    );

    if (arrayControlsValue?.location_contacts) {
      locationContactPayload = {
        ...locationContactPayload,
        [SITELOCATION_CONSTANTS.phoneNumbers]: [],
      };
      locationContactPayload[SITELOCATION_CONSTANTS.phoneNumbers] =
        this.modifyArrayControls(arrayControlsValue?.location_contacts);
      locationContactPayload[SITELOCATION_CONSTANTS.phoneNumbers] =
        locationContactPayload[SITELOCATION_CONSTANTS.phoneNumbers]?.map(
          (item: string) => ({
            phone_number: item,
            extension: 1,
          })
        );
    }

    if (editFieldsValue?.location_contact_full_name) {
      locationContactPayload[SITELOCATION_CONSTANTS.name] = {
        ...locationContactPayload[SITELOCATION_CONSTANTS.name],
        full_name: editFieldsValue.location_contact_full_name,
      };
    }

    if (editFieldsValue?.location_contact_prefix) {
      locationContactPayload[SITELOCATION_CONSTANTS.name] = {
        ...locationContactPayload[SITELOCATION_CONSTANTS.name],
        prefix: editFieldsValue.location_contact_prefix,
      };
      locationContactPayload[SITELOCATION_CONSTANTS.title] =
        editFieldsValue.location_contact_prefix;
    }

    if (editFieldsValue?.location_contact_email_address) {
      locationContactPayload[SITELOCATION_CONSTANTS.emailAddress] =
        editFieldsValue.location_contact_email_address;
    }

    if (editFieldsValue?.location_contact_fax) {
      locationContactPayload[SITELOCATION_CONSTANTS.fax] =
        editFieldsValue.location_contact_fax;
    }

    if (editFieldsValue?.contact_address) {
      locationContactPayload[SITELOCATION_CONSTANTS.addresses] = [];
      locationContactPayload[SITELOCATION_CONSTANTS.addresses].push(
        editFieldsValue.contact_address
      );
    }

    if (locationContactPayload) {
      Object.keys(locationContactPayload[SITELOCATION_CONSTANTS.name])?.forEach(
        (key: string) =>
          locationContactPayload[SITELOCATION_CONSTANTS.name][key] ===
            undefined &&
          delete locationContactPayload[SITELOCATION_CONSTANTS.name][key]
      );
      Object.keys(locationContactPayload).forEach(
        (key: string) =>
          locationContactPayload[key] === undefined &&
          delete locationContactPayload[key]
      );
    }
    const firstIndex = 0;
    if (
      locationContactPayload[SITELOCATION_CONSTANTS.addresses]?.[firstIndex]
    ) {
      Object.keys(
        locationContactPayload[SITELOCATION_CONSTANTS.addresses][firstIndex]
      ).forEach((key) => {
        if (
          locationContactPayload[SITELOCATION_CONSTANTS.addresses][firstIndex][
            key
          ] === null
        ) {
          delete locationContactPayload[SITELOCATION_CONSTANTS.addresses][
            firstIndex
          ][key];
        }
      });
    }

    return camelToSnakeCase(locationContactPayload);
  }

  modifyArrayControls(controls: object[]) {
    return controls
      .map((item: any) => {
        if (!!item.selection) return item.selection;
      })
      .filter(this.notEmpty);
  }

  notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
  }

  cancelEdit(): void {
    this.showAdditionalDetailsModal = false;
    this.editModal = true;
  }

  editSubmit(additionalDetailsModalConfig: AdditionalDetailsModalConfig): void {
    this.locationOpenDays = this.locationHours
      ? this.locationHours.getEnabled()
      : this.locationOpenDays;

    if (
      this.providerUpdateToolService.showModal(additionalDetailsModalConfig)
    ) {
      this.showAdditionalDetailsModal = true;
      this.editModal = false;
    } else {
      this.submit();
    }
  }

  submit(): void {
    this.showAdditionalDetailsModal = false;
    this.updatePayload();
    if (!this.isDisable()) {
      this.payload[SITELOCATION_CONSTANTS.id] = this?.id;
      this.siteLocationsProfileHttpService
        .updateSiteLocationEditDetails(this.config, this.id, this.payload)
        .pipe(
          tap(
            (res: SiteLocation) => {
              this.editResponse = res;
              this.siteLocationMessage =
                this.editResponse?.[
                  this.config?.siteLocationDetails?.confirmationLocationProp
                ]?.toString();
              this.isLoading = true;
              this.ngOnInit();
              this.closeEditModal();
              if (this.additionalDetailsForm) {
                this.additionalDetailsForm.reset();
              }
            },
            () => {
              this.closeEditModal();
            }
          )
        )
        .subscribe();
    }
  }

  filterProfileSectionAttributes(): void {
    this.config.siteLocationDetails?.sections?.map((element: any) => {
      if (!this.additionalDetailsModalConfig?.enableNotes) {
        element.fields.attributes = this.filterAdditionalDetails(
          element.fields.attributes,
          toCamel(SiteLocationDetailsContextKeys.changeRequestNotes)
        );
      }
      if (!this.additionalDetailsModalConfig?.enableEffectiveDate) {
        element.fields.attributes = this.filterAdditionalDetails(
          element.fields.attributes,
          toCamel(SiteLocationDetailsContextKeys.changeRequestEffectiveDate)
        );
      }

      return element;
    });
  }

  filterAdditionalDetails(additionalDetails: any[], prop: string): any[] {
    return additionalDetails
      ? additionalDetails.filter((control: any) => control.prop !== prop)
      : [];
  }

  getProviderIdentifiers(formValue: object): ProviderIdentifierPayload[] {
    return this.config.siteLocationDetails.editFlyout.formFields[0].controls
      ?.filter(
        (control: AllControlsConfiguration) =>
          control?.prop === PROVIDER_IDENTIFIERS[toCamel(control.prop)]
      )
      ?.map((control: AllControlsConfiguration) => ({
        identifier: formValue[control?.prop],
        type: control?.label,
      }));
  }

  ngOnDestroy(): void {
    this.destroy.next(void 0);
    this.destroy.complete();
    this.siteLocationAttestationEventService.emitSelectedSiteLocations([]);
    this.siteLocationAttestationEventService.emitDynamicButtonConfig({});
    this.siteLocationAttestationEventService.emitDynamicButtonClickEvent('');
  }
}
