import { Pipe, PipeTransform } from '@angular/core';
import { isEmptyObj } from '../utilities';

/**
 * IsNotEmptyPipe to check for falsy along with empty object values
 */
@Pipe({
  name: 'isNotEmpty',
})
export class IsNotEmptyPipe implements PipeTransform {
  transform(value: any): boolean {
    return !!value && !isEmptyObj(value);
  }
}
