export enum SubmissionCategory {
  draft = 'draft',
  submitted = 'submitted',
}

export enum MedicalCategory {
  dental = 'dental',
  medical = 'medical',
  pharmacy = 'pharmacy',
}

export enum AccidentalDetails {
  accidentType = 'accidentType',
  accidentalInjury = 'accidentalInjury',
  fault = 'fault',
  accidentDate = 'accidentDate',
}

export enum AccidentType {
  autoAccident = 'auto_accident',
  workplaceAccident = 'workplace_accident',
  otherAccident = 'other_accident',
  autoAccidentData = 'Auto Accident',
  workRelatedAccident = 'Work Related Accident',
  accidentalInjury = 'Accidental Injury',
}

export enum FaultType {
  member = 'member',
  other = 'other',
  user = 'User',
  otherParty = 'Other Party',
}

export enum ClaimSubmissionForms {
  patientInformation = 'patientInformation',
  providerInformation = 'providerInformation',
  serviceDetails = 'serviceDetails',
  dentalTreatment = 'dentalTreatment',
  claimLineInformation = 'claimLineInformation',
}

export enum InformationButtons {
  cancelButton = 'CancelClaimSubmission',
  continueButton = 'ContinueToClaimSubmission',
}

export const mobileHeaders = {
  ignoreMargin: true,
  customHeader: true,
};

export enum ClaimSubType {
  member = 'member',
}

export enum ClaimSubmissionFooterButtons {
  save = 'save',
  review = 'review',
  submit = 'submit',
  back = 'back',
}
export const claimSubmissionPages = {
  form: 'form',
  instructionsPage: 'instructionsPage',
  confirmationPage: 'confirmationPage',
};

export const reviewKeys = {
  patientInformation: 'patientInformation',
  serviceDetails: 'serviceDetails',
};

export enum DentalPayloadCategory {
  dentalClaimLineSubmission = 'dental_claimline_submission',
  dentalClaimSubmission = 'dental_claim_submission',
}

export enum RequestTypePayload {
  dentalClaim = 'dental_claim',
  estimateOfEligibleBenefits = 'estimate_of_eligible_benefits',
  dentalClaimData = 'Submit a Dental Claim',
  estimateOfEligibleBenefitsData = 'Request an estimate of eligible benefits',
}

export const yes = 'Yes';
export const no = 'No';
export const acceptHeaderKey = 'Accept';
