import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { TemplateRefDirective } from './template-ref.directive';
import { TabLabel } from './tabs.constants';

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit {
  @Input() tabLabels: (TabLabel | string)[] = [];
  @Input() tabTemplates: TemplateRef<any>[] = [];
  @Input() selectedIndex = 0;
  @Input() pageTabs: boolean;
  @Input() noTabBorder: boolean;

  @Output() tabSelected: EventEmitter<any> = new EventEmitter<any>();
  @ContentChildren(TemplateRefDirective)
  projectedTemplates: QueryList<TemplateRefDirective>;
  projectedTemplatesList: TemplateRefDirective[];

  onSelect(index) {
    this.selectedIndex = index;
    this.tabSelected.emit(index);
  }

  ngAfterContentInit() {
    this.projectedTemplatesList = this.projectedTemplates.toArray();
  }
}
