import { NgModule } from '@angular/core';
import { DigitalDataClickEventDirective } from '../../directives/digital-data/digital-data-click-event.directive';
import { DigitalDataLoadEventDirective } from '../../directives/digital-data/digital-data-load-event.directive';
import { DigitalDataExternalNavEventDirective } from '../../directives/digital-data/digital-data-nav-event.directive';

@NgModule({
  declarations: [
    DigitalDataClickEventDirective,
    DigitalDataExternalNavEventDirective,
    DigitalDataLoadEventDirective,
  ],
  exports: [
    DigitalDataClickEventDirective,
    DigitalDataExternalNavEventDirective,
    DigitalDataLoadEventDirective,
  ],
})
export class DigitalDataModule {}
