import { Injectable } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { getValue } from '@zipari/web-utils';
import { ApiListResponse } from '../../../../../shared/models/shared/ApiListResponse.model';
import { stateOptions } from '../../controls/address.constants';
import { ProviderUpdateToolService } from '../../provider-update-tool.service';
import { SITELOCATION_CONSTANTS } from '../../site-locations-attestation/site-location-attestation.constant';
import {
  SiteLocation,
  SiteLocationEditFormFileds,
  SiteLocationsEditFormConfiguration,
  SiteLocationsProfileEditConfig,
} from '../../site-locations-attestation/site-locations-attestation.model';
import { SiteLocationsProfileHttpService } from './site-locations-profille-http.service';
import { populateDropdown, setControlProps } from './site-locations.helper';

@Injectable({
  providedIn: 'root',
})
export class SiteLocationsProfileEditService {
  constructor(
    private siteLocationsProfileHttpService: SiteLocationsProfileHttpService,
    private formBuilder: UntypedFormBuilder,
    public providerUpdateToolService: ProviderUpdateToolService
  ) {}

  updateEditFlyoutConfig(
    siteLocationDetail: SiteLocation,
    editProfileFlyoutConfig: SiteLocationsProfileEditConfig
  ): void {
    const editArrayFields = {};
    const editContext = {};
    const editState = {};

    if (editProfileFlyoutConfig) {
      editProfileFlyoutConfig.formFields.forEach(
        (formField: SiteLocationEditFormFileds) => {
          this.updateArrayControls(
            siteLocationDetail,
            formField,
            editArrayFields,
            editContext,
            editState
          );
          this.updateFormFieldsControls(formField, siteLocationDetail);
        }
      );
    }

    this.providerUpdateToolService.setFormGroup(
      SITELOCATION_CONSTANTS.editSiteDetails,
      this.formBuilder.group(editArrayFields)
    );
    this.providerUpdateToolService.setContextData(
      SITELOCATION_CONSTANTS.editSiteDetails,
      editContext
    );
    this.providerUpdateToolService.setInitState(
      SITELOCATION_CONSTANTS.editSiteDetails,
      editState
    );
  }

  updateFormFieldsControls(
    formField: SiteLocationEditFormFileds,
    context: object
  ): void {
    if (formField?.controls) {
      formField.controls.forEach(
        (control: SiteLocationsEditFormConfiguration) => {
          if (
            (control.type as string) === SITELOCATION_CONSTANTS.basicAddress &&
            control.cityStateZipConfig?.length
          ) {
            this.updateAddressControls(control, context);
          } else if (control.apiEndpoint) {
            this.siteLocationsProfileHttpService
              .getControlOptions(control.apiEndpoint)
              .subscribe((resp: ApiListResponse<unknown>) => {
                control.options = populateDropdown(
                  resp.results,
                  control.dropdownDisplayProp
                );
                control = setControlProps(control, context);
              });
          } else if (
            control.defaultProp ||
            control.defaultStartProp ||
            control.defaultEndProp
          ) {
            control = setControlProps(control, context);
          }
        }
      );
    }
  }

  updateArrayControls(
    siteLocationDetail: SiteLocation,
    formfield: SiteLocationEditFormFileds,
    editArrayFields: object,
    editContext: object,
    editState: object
  ): void {
    if (formfield.array_controls) {
      formfield.array_controls.forEach(
        (control: SiteLocationsEditFormConfiguration) => {
          editArrayFields[control.prop] = this.formBuilder.array([
            this.formBuilder.group({
              selection: [
                '',
                this.providerUpdateToolService.getValidators(
                  control.validatorType
                ),
              ],
            }),
          ]);
          if (control.defaultProp) {
            editContext[control.prop] = getValue(
              siteLocationDetail,
              control.defaultProp
            );
            editState[control.prop] = false;
          }
        }
      );
    }
  }

  updateAddressControls(
    control: SiteLocationsEditFormConfiguration,
    context: object
  ): void {
    if (control.cityStateZipConfig) {
      const stateControl = control.cityStateZipConfig.find(
        (_control: SiteLocationsEditFormConfiguration) =>
          _control.prop === SITELOCATION_CONSTANTS.state
      );

      if (stateControl) {
        stateControl.options = stateOptions;
      }
      this.setAddressControls(control.cityStateZipConfig, context);
    }
    if (control.streetAddressConfig) {
      this.setAddressControls(control.streetAddressConfig, context);
    }
  }

  setAddressControls(
    control: SiteLocationsEditFormConfiguration[],
    context: object
  ): void {
    control.forEach((formControl: SiteLocationsEditFormConfiguration) => {
      if (
        formControl.defaultProp ||
        formControl.defaultStartProp ||
        formControl.defaultEndProp
      ) {
        formControl = setControlProps(formControl, context);
      }
    });
  }
}
