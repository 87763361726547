import { Injectable } from '@angular/core';
import { PaginatorConfiguration } from '@zipari/design-system';
import { camelCase } from '@zipari/shared-util-common';
import { map, Observable } from 'rxjs';
import { APIResponse, APIService } from '../../../../shared/services';
import {
  ProviderAdmin,
  ProviderAdminSearchParams,
} from '../provider-admins.model';
import { getProviderAdminApiParams } from './provider-admin-list.helper';

@Injectable({
  providedIn: 'root',
})
export class ProviderAdminListService {
  constructor(private apiService: APIService) {}

  getProviderAdminList(
    endPoint: string,
    paginator: PaginatorConfiguration,
    providerAdminSearchParams?: ProviderAdminSearchParams,
    sortKey: string | null = null
  ): Observable<APIResponse<ProviderAdmin>> {
    return this.apiService
      .get(
        endPoint,
        getProviderAdminApiParams(paginator, sortKey, providerAdminSearchParams)
      )
      .pipe(map((response) => camelCase(response)));
  }
}
