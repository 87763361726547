import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AccordionModule,
  ButtonModule,
  CardModule,
  FormControlModule,
  FormGroupModule,
  ModalModule,
  ModelAttributeModule,
  SpinnerModule,
} from '@zipari/design-system';
import { UiAdditionalDetailModalModule } from '@zipari/provider-portal/ui-additional-detail-modal';
import { PipeModule } from '../../../../../../shared/pipes/pipe.module';
import { SharedModule } from '../../../../../../shared/shared.module';
import { DropdownArrayModule } from '../../../controls/dropdown-array/dropdown-array.module';
import { InputArrayModule } from '../../../controls/input-array/input-array.module';
import { LocationHoursModule } from '../../../controls/location-hours/location-hours.module';
import { SiteLocationMessageComponent } from '../../components/site-location-message/site-location-message.component';
import {
  SetDropdownValueOnEditClicked,
  SiteLocationsProfileComponent,
} from './site-locations-profile.component';

@NgModule({
  declarations: [
    SiteLocationsProfileComponent,
    SetDropdownValueOnEditClicked,
    SiteLocationMessageComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormControlModule,
    ButtonModule,
    SharedModule,
    PipeModule,
    CardModule,
    ModelAttributeModule,
    AccordionModule,
    FormGroupModule,
    ModalModule,
    DropdownArrayModule,
    InputArrayModule,
    LocationHoursModule,
    SpinnerModule,
    UiAdditionalDetailModalModule,
  ],
  exports: [SiteLocationsProfileComponent, SiteLocationMessageComponent],
})
export class SiteLocationsProfileModule {}
