import { getCSSVariableValue } from '../../shared/utils/css-variables';

export async function loadIconFonts(): Promise<FontFace[]> {
  const systemIconsUrl = getCSSVariableValue('--icon-source-system');
  const systemIconsFontFace = new FontFace(
    'System Icons',
    `url(${systemIconsUrl}) format(\'woff\')`
  );

  const productIconsUrl = getCSSVariableValue('--icon-source-product');
  const productIconsFontFace = new FontFace(
    'Product Icons',
    `url(${productIconsUrl}) format(\'woff\')`
  );

  try {
    await Promise.all([
      systemIconsFontFace.load(),
      productIconsFontFace.load(),
    ]).then((fonts: FontFace[]) => {
      fonts.forEach((font) => {
        document.fonts.add(font);
      });
    });
  } catch (error) {
    return;
  }
}
