import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import {
  ButtonModule,
  FormControlModule,
  TableModule,
} from '@zipari/design-system';
import { FormGroupModule } from '@zipari/design-system';
import { ZipBusyModule } from '../../modules/zip-busy/zip-busy.module';
import { AddNewModalModule } from '../../modules/add-row-modal/add-new-modal.module';
import { SharedModule } from '../../shared.module';
import { ListComponent } from './list.component';
import {
  LIST_SERVICE,
  ListService,
  ListServiceInterface,
} from './list.service';

export interface ListConfig {
  listService: Type<ListServiceInterface>;
}

@NgModule({
  declarations: [ListComponent],
  imports: [
    CommonModule,
    ButtonModule,
    TableModule,
    FormControlModule,
    FormGroupModule,
    SharedModule,
    ZipBusyModule,
    AddNewModalModule,
  ],
  exports: [ListComponent],
  providers: [ListService],
})
export class ListModule {
  static forRoot(listConfig?: ListConfig): ModuleWithProviders<ListModule> {
    return {
      ngModule: ListModule,
      providers: [
        {
          provide: LIST_SERVICE,
          useClass: (listConfig && listConfig.listService) || ListService,
        },
      ],
    };
  }
}
