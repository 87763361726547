import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'single-checkbox',
  templateUrl: './single-checkbox.component.html',
})
export class SingleCheckboxComponent implements OnInit {
  @Input() config: any;
  @Input() control: UntypedFormControl;
  @Input() disabled: any;

  ngOnInit() {
    if (!this.control?.value) {
      this.control.setValue(this.config.isCheckedByDefault, {
        emitEvent: false,
      });
    }
  }

  public get checkboxLabel(): string {
    if (
      this.config &&
      this.config.hasOwnProperty('checkboxLabel') &&
      this.requiredCheckboxLabel
    ) {
      return `${this.config.checkboxLabel} ${this.requiredCheckboxLabel}`;
    }

    return this.config.checkboxLabel;
  }

  public get requiredCheckboxLabel(): string {
    if (
      !this.config.showCheckboxLabelRequired ||
      !this.config.hasOwnProperty('requiredLabel')
    ) {
      return null;
    }

    let label: string;
    if (typeof this.config.requiredLabel === 'string') {
      label = this.config.requiredLabel;
    } else {
      label = '*';
    }

    return label;
  }
}
