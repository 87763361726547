<div class="">
  <div [ngClass]="{ 'custom-alignment': layoutOptions?.isCustomAligned }">
    <div class="auth--card">
      <div
        [ngClass]="{ 'auth--card--container': !layoutOptions?.isCustomAligned }"
      >
        <ng-content select="[authCard]"></ng-content>
      </div>
    </div>

    <div
      [ngClass]="{
        'custom-auth-banner__hero': layoutOptions?.isCustomAligned,
        'auth-banner__hero': !layoutOptions?.isCustomAligned
      }"
    >
      <img [src]="layoutOptions.heroImg" />
    </div>
  </div>

  <div class="more-info">
    <div class="header">
      <img [src]="layoutOptions?.header?.icon" />
      <h4 class="t-bold" [innerHTML]="layoutOptions?.header?.text"></h4>
    </div>

    <div class="sections">
      <div class="section" *ngFor="let section of layoutOptions?.sections">
        <h4 class="t-bold">{{ section?.title }}</h4>

        <div class="underline"></div>

        <div class="section--text" *ngIf="section.type === 'text'">
          <p class="t-data" [innerHTML]="section?.text"></p>
        </div>

        <div class="section--list" *ngIf="section?.type === 'list'">
          <div class="list--item" *ngFor="let item of section.list">
            <i class="icon icon--medium">{{ item.icon || 'check' }}</i>
            <p class="t-data" [innerHTML]="item?.text"></p>
          </div>
        </div>

        <div class="section--links" *ngIf="section.type === 'links'">
          <p
            *ngIf="section?.description"
            class="t-data"
            [innerHTML]="section?.description"
          ></p>

          <a
            target="_blank"
            class="t-data t-link"
            [href]="link.url"
            *ngFor="let link of section?.links"
          >
            {{ link.label }}
          </a>
        </div>

        <zip-button [config]="section?.button"></zip-button>
      </div>
    </div>
  </div>
</div>
