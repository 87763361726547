import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { pipe, Subject } from 'rxjs';
import { debounceTime, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { FormControlService } from '../../../../notes/form-control/form-control.service';
import { BaseCVAComponent } from './../base-cva.component';
import { industryConfigs } from './industries.constants';
import { IndustriesService } from './industries.service';

const quarterSecond = 250;

interface IndustriesComponentConfig {
  isDisabled: boolean;
  messages: { error: string; loading: string };
  validators: Validator[];
}

export interface IndustryConfig {
  key?: string;
  label: string;
  messages: { error: string; loading: string };
  options: unknown[];
  prop: string;
  selectionRequired: boolean;
  type: string;
  validators: unknown[];
}

@Component({
  selector: 'industries',
  templateUrl: './industries.component.html',
  providers: [
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    {
      multi: true,
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IndustriesComponent),
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IndustriesComponent),
    },
    IndustriesService,
  ],
})
export class IndustriesComponent
  extends BaseCVAComponent
  implements OnInit, OnDestroy
{
  @Input() config: IndustriesComponentConfig;
  @Input() direction;
  debounceTime = quarterSecond;
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  industryTitleConfig = industryConfigs.industryTitle;
  industryTitleInput = new Subject<string>();
  loadingIndustryTitle = false;
  loadingSicCodes = false;
  showIndustryTitleError = false;
  showSicCodeError = false;
  sicCodeConfig: IndustryConfig = industryConfigs.sicCode;
  sicCodeInput = new Subject<string>();

  constructor(
    private industriesService: IndustriesService,
    public formControlService: FormControlService
  ) {
    super();
  }

  ngOnInit() {
    this.initFormGroup();
    this.setDisabledState();
    this.initSicCodeInput();
    this.initIndustryTitleInput();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  initFormGroup(): void {
    this.setConfig();
    this.formControlService.addControlToFormGroup(
      this.formGroup,
      this.industryTitleConfig
    );
    this.formControlService.addControlToFormGroup(
      this.formGroup,
      this.sicCodeConfig
    );
  }

  /** Subscribe to SIC code changes to build industry title typeahead options. */
  initSicCodeInput(): void {
    this.sicCodeInput
      .pipe(
        tap(() => {
          this.startSicLoading();
        }),
        this.pipeIndustryInput()
      )
      .subscribe((response) => {
        this.buildIndustryTitleTypeaheads(response);
      });
  }

  startSicLoading(): void {
    this.loadingSicCodes = true;
    this.formGroup.get(this.industryTitleProp()).reset();
  }

  buildIndustryTitleTypeaheads(response): void {
    this.showIndustryTitleError = false;
    this.loadingSicCodes = false;
    if (!response?.results?.length) {
      this.showSicCodeError = true;
      this.formGroup.get(this.industryTitleProp())?.reset();
    } else {
      this.showSicCodeError = false;
      this.sicCodeConfig = {
        ...this.sicCodeConfig,
        options: this.buildIndustryOptions(
          response.results,
          this.sicCodeProp()
        ),
      };
    }
  }

  /** Subscribe to industry title changes to build siccode typeahead options. */
  initIndustryTitleInput(): void {
    this.industryTitleInput
      .pipe(
        tap(() => {
          this.startIndustryLoading();
        }),
        this.pipeIndustryInput()
      )
      .subscribe((response) => {
        this.buildSICCodeTypeaheads(response);
      });
  }

  startIndustryLoading(): void {
    this.loadingIndustryTitle = true;
    this.formGroup.get(this.sicCodeProp()).reset();
  }

  buildSICCodeTypeaheads(response): void {
    this.loadingIndustryTitle = false;
    this.showSicCodeError = false;
    if (!response.results.length) {
      this.showIndustryTitleError = true;
      this.formGroup.get(this.sicCodeProp())?.reset();
    } else {
      this.showIndustryTitleError = false;
      this.industryTitleConfig = {
        ...this.industryTitleConfig,
        options: this.buildIndustryOptions(
          response.results,
          this.industryTitleProp()
        ),
      };
    }
  }

  setConfig() {
    this.industryTitleConfig = {
      ...industryConfigs.industryTitle,
      validators:
        this.config.validators || industryConfigs.industryTitle.validators,
      messages: this.config.messages || industryConfigs.industryTitle.messages,
    };
    this.sicCodeConfig = {
      ...industryConfigs.sicCode,
      validators: this.config.validators || industryConfigs.sicCode.validators,
      messages: this.config.messages || industryConfigs.sicCode.messages,
    };
  }

  setDisabledState() {
    this.config.isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  onSICCodeInput(sicCode: string) {
    this.sicCodeConfig = { ...this.sicCodeConfig, options: [] };
    this.sicCodeInput.next(sicCode);
  }

  onIndustryTitleInput(industryTitle: string) {
    this.industryTitleConfig = { ...this.industryTitleConfig, options: [] };
    this.industryTitleInput.next(industryTitle);
  }

  onSICCodeSelect(selected) {
    this.formGroup
      .get(this.industryTitleProp())
      .patchValue(selected.industry.industry_title);
  }

  onIndustryTitleSelect(selected) {
    this.formGroup
      .get(this.sicCodeProp())
      .patchValue(selected.industry.sic_code);
  }

  pipeIndustryInput() {
    return pipe(
      debounceTime(this.debounceTime),
      takeUntil(this.destroy),
      map((value) => this.buildParams(value)),
      mergeMap((params) => this.industriesService.getIndustries(params))
    );
  }

  buildParams(value): any {
    const sicCodeNumbers = /[0-9]+/.exec(value);

    return sicCodeNumbers
      ? { sic_code: sicCodeNumbers }
      : { industry_title: value };
  }

  buildIndustryOptions(industries, valueParam) {
    return industries.map((industry) => ({
      industry: industry,
      label: `${industry.sic_code} - ${industry.industry_title}`,
      value: String(industry[valueParam]),
    }));
  }

  validate(_: AbstractControl): ValidationErrors | null {
    return this.formGroup.valid ? null : { custom: true };
  }

  industryTitleProp() {
    return this.industryTitleConfig.prop;
  }

  sicCodeProp() {
    return this.sicCodeConfig.prop;
  }
}
