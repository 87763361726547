import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileSizePipe } from './fileSize.pipe';
import { FileTitlePipe } from './fileTitle.pipe';
import { FilterPipe } from './filter.pipe';

@NgModule({
  declarations: [
    // StringBuilderPipe
    FileSizePipe,
    FileTitlePipe,
    FilterPipe,
  ],
  imports: [CommonModule],
  exports: [
    // StringBuilderPipe
    FileSizePipe,
    FileTitlePipe,
    FilterPipe,
  ],
})
export class PipeModule {}
