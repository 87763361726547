import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
  ControlConfiguration,
  EntryCardConfig,
} from '../../claim-redetermination/claim-redetermination.model';
import { DocumentUploadService } from '../../services/document-upload.service';
import { AppealConstant, ClaimAppealProps } from '../../claim-appeal.constants';
import {
  daysGreaterThanMaxDays,
  daysLessThanMinDays,
  enableDisableControls,
  getControlByProp,
  prePopulateBillingProviderFormValue,
  setContactInformation,
  updateProviderInformation,
} from '../../claim-appeal.helper';
import {
  PractitionerDetails,
  SiteLocation,
} from '../../../provider-update-tool/site-locations-attestation/site-locations-attestation.model';
import Claim from '../../../../../shared/models/shared/Claim.model';
import { ClaimDetailService } from '../../../claim-detail/claim-detail.service';
import { ProviderSelectionModalConfig } from '../../../authorization-submission/authorization-submission.model';
import { ErrorModalConfig } from '../../../manage-portal-users-detail/manage-portal-users-details.model';
import { getObjectProperty } from '../../../../../shared/utilities/get-object-property.utils';
import { SubmitClaimAppealService } from '../../services/submit-claim-appeal.service';

@Component({
  selector: 'entry-form-card',
  templateUrl: './entry-form-card.component.html',
  styleUrls: ['./entry-form-card.component.scss'],
})
export class EntryFormCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() config: EntryCardConfig;
  @Input() appealForm: UntypedFormGroup;
  @Input() providerSelectionModalConfig: ProviderSelectionModalConfig;
  @Input() claimId: string | number;
  @Input() errorModal: ErrorModalConfig;
  @Input() facilityNameControl: ControlConfiguration;
  @Input() providerNameControl: ControlConfiguration[];
  @Input() hasReturnedFromReviewPage: boolean;

  showMinDaysAllowedErrorMsg = false;
  showMaxDaysAllowedWarning = false;
  documentContext: UntypedFormArray;
  claimDetail$: Observable<Claim>;
  showErrorModal: boolean;
  errorMessage: string;

  private datePropSubscription$ = new Subject();
  private requestorPropSubscription$ = new Subject();
  private providerPropSubscription$ = new Subject();

  constructor(
    public documentUploadService: DocumentUploadService,
    public formControlService: FormControlService,
    public claimDetailService: ClaimDetailService,
    public submitClaimAppealService: SubmitClaimAppealService
  ) {}

  get shouldShowInitialDateWarningMsg() {
    return this.showMinDaysAllowedErrorMsg || this.showMaxDaysAllowedWarning;
  }

  get initialDateWarningMsgConfig() {
    const {
      dateOfInitialDeterminationWarning,
      dateOfInitialDeterminationError,
    } = this.config?.cards?.requestInformation || {};

    return this.showMaxDaysAllowedWarning
      ? dateOfInitialDeterminationWarning
      : dateOfInitialDeterminationError;
  }

  ngOnInit(): void {
    if (this.claimId && !this.hasReturnedFromReviewPage) {
      this.getClaimDetail();
    }
    this.initFormChangesListener();
    if (this.documentUploadService.fileAttachments?.length > 0) {
      this.setFilesFromAPI(this.documentUploadService.fileAttachments);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.appealForm?.currentValue !== changes.appealForm?.previousValue
    ) {
      this.appealForm = changes.appealForm.currentValue;
    }
  }

  getClaimDetail(): void {
    this.claimDetail$ = this.claimDetailService.getClaimDetail(
      this.claimId,
      this.appealForm,
      this.config.cards.billingProviderInformation
    );

    this.claimDetail$.subscribe({
      next: (claim) => {
        this.submitClaimAppealService.claimDetail = claim;
        prePopulateBillingProviderFormValue(
          this.config,
          this.appealForm,
          claim
        );
      },
      error: (error) => {
        this.displayModalAndErrorMessage(error.error?.errors[0]?.user_error);
      },
    });
  }

  initFormChangesListener(): void {
    this.appealForm
      ?.get(ClaimAppealProps.dateProp)
      ?.valueChanges.pipe(takeUntil(this.datePropSubscription$))
      .subscribe(this.showWarningMessage.bind(this));

    this.appealForm
      ?.get(ClaimAppealProps.sameAsRequestor)
      ?.valueChanges.pipe(takeUntil(this.requestorPropSubscription$))
      .subscribe(setContactInformation.bind(this, this.appealForm));

    this.appealForm
      ?.get(ClaimAppealProps.requestingProvider)
      ?.valueChanges.pipe(takeUntil(this.providerPropSubscription$))
      .subscribe(this.updateBillingProviderInformation.bind(this));

    this.appealForm
      ?.get(ClaimAppealProps.disputeTypeProp)
      ?.valueChanges.pipe(takeUntil(this.providerPropSubscription$))
      .subscribe(this.showHideDisputeTypeOther.bind(this));
  }

  updateBillingProviderInformation(
    providerInformation: SiteLocation | PractitionerDetails | null
  ): void {
    const billingProviderFormControls =
      this.config.cards?.billingProviderInformation?.form?.controls;

    if (providerInformation && billingProviderFormControls) {
      this.submitClaimAppealService.providerInformation =
        updateProviderInformation(providerInformation);
      Object.values(billingProviderFormControls)?.forEach(
        (formControl: any) => {
          if (formControl?.prop) {
            this.appealForm =
              this.claimDetailService.updateBillingProviderFormControl(
                this.appealForm,
                providerInformation,
                this.config.cards?.billingProviderInformation
              );
            const providerInfo = updateProviderInformation(providerInformation);
            const getKeyValue = getObjectProperty(
              providerInfo,
              formControl.prop
            );
            const formProperty = this.appealForm?.get(formControl.prop);

            formProperty?.patchValue(
              getKeyValue || (getKeyValue === false ? false : null)
            );
          }
        }
      );
    }
  }

  showWarningMessage(): void {
    const { dateProp, reasonOfLateFiling } = ClaimAppealProps;

    const date = this.appealForm?.get(dateProp)?.value;

    if (date) {
      const cardConfig = this.config?.cards?.requestInformation;

      if (cardConfig?.minDaysAllowed) {
        this.showMinDaysAllowedErrorMsg = daysLessThanMinDays(
          date,
          cardConfig.minDaysAllowed
        );
      }

      if (cardConfig?.maxDaysAllowed) {
        this.showMaxDaysAllowedWarning = daysGreaterThanMaxDays(
          date,
          cardConfig.maxDaysAllowed
        );
      }

      if (this.showMaxDaysAllowedWarning) {
        if (cardConfig) {
          this.formControlService.addControlToFormGroup(
            this.appealForm,
            getControlByProp(cardConfig, reasonOfLateFiling)
          );
        }
      } else {
        this.appealForm.removeControl(reasonOfLateFiling);
      }
      enableDisableControls(this.appealForm, this.showMinDaysAllowedErrorMsg);
    }
  }

  showHideDisputeTypeOther(): void {
    const { disputeTypeProp, disputeTypeOther } = ClaimAppealProps;
    const cardConfig = this.config?.cards?.requestInformation;
    const disputeTypeOption = this.appealForm.get(disputeTypeProp)?.value;

    if (disputeTypeOption.value === AppealConstant.disputeTypeOther) {
      cardConfig &&
        this.formControlService.addControlToFormGroup(
          this.appealForm,
          getControlByProp(cardConfig, disputeTypeOther)
        );
    } else {
      this.appealForm.removeControl(disputeTypeOther);
    }
  }

  fileUploaded(fileList: File[]): void {
    const documentType = this.appealForm?.get(
      ClaimAppealProps.documentType
    )?.value;
    const documentUploadUrl =
      this.config?.cards.additionalDocumentation?.documentUploadUrl;

    if (documentUploadUrl) {
      this.documentUploadService.uploadFile(
        fileList,
        documentUploadUrl,
        documentType?.value
      );
    }
    this.documentUploadService.fileAttachments = fileList;
  }

  removeFile(fileId: number): void {
    this.documentUploadService.removeFile(fileId);
    this.documentUploadService.uploadCompleted$.next(false);
  }

  displayModalAndErrorMessage(errorMessage: string): void {
    this.errorMessage = errorMessage;
    this.showErrorModal = true;
  }

  errorModalClicked(): void {
    this.showErrorModal = false;
  }

  ngOnDestroy(): void {
    this.datePropSubscription$.next(true);
    this.requestorPropSubscription$.next(true);
    this.providerPropSubscription$.next(true);
    this.documentUploadService.uploadAttachmentSubscription?.unsubscribe();
  }

  public setFilesFromAPI(filesList: File[]): void {
    this.documentContext = new UntypedFormArray(
      filesList.map((file: File) => new UntypedFormControl(file))
    );
  }
}
