import { Component, Input, OnInit } from '@angular/core';
import { camelCase } from '../../../../shared/utilities/case-conversion.utils';
import {
  ClinicalReveiwIndicationContext,
  ClinicalReviewGuidelineContext,
  mcgClinicalReview,
} from '../../../templates/transfer-of-care/transfer-of-care-detail.model';
import { MCGDataParserService } from '../../services/mcg-data-parser/mcg-data-parser.service';

@Component({
  selector: 'clinical-review-card',
  templateUrl: './clinical-review-card.component.html',
  styleUrls: ['./clinical-review-card.component.scss'],
})
export class ClinicalReviewCardComponent implements OnInit {
  @Input() authorizationDetailsContext;
  @Input() config;
  isClinicalReviewNoDataMessageDisplay: boolean = false;
  clinicalReviewTabData: ClinicalReveiwIndicationContext[] = [];
  guidelineHeader: {
    guidelineTitle: string;
  };

  constructor(private mcgDataParserService: MCGDataParserService) {}

  ngOnInit(): void {
    this.setClinicalReivewContext(this.authorizationDetailsContext);
  }

  setClinicalReivewContext(authorizationDetailsContext): void {
    if (authorizationDetailsContext?.additional_properties?.length > 0) {
      const mcgClinicalReviewTabData =
        authorizationDetailsContext.additional_properties.find(
          (item: any) => item.category === mcgClinicalReview
        );

      const guideline: ClinicalReviewGuidelineContext[] = camelCase(
        mcgClinicalReviewTabData?.property_dictionary?.Episode?.Guidelines
          ?.Guideline
      );

      if (guideline) {
        guideline.forEach((guidelineItem: ClinicalReviewGuidelineContext) => {
          const clinicalReveiwIndication: ClinicalReveiwIndicationContext =
            guidelineItem?.outlines?.outline?.indications?.indication;

          this.guidelineHeader = {
            guidelineTitle: `${guidelineItem?.orgOtg} - ${guidelineItem?.guidelineName} - (${guidelineItem?.pubCode})`,
          };
          this.setClinicalReviewTabData(clinicalReveiwIndication);
        });
      } else {
        this.isClinicalReviewNoDataMessageDisplay = true;
      }
    }
  }

  setClinicalReviewTabData(
    clinicalReveiwIndication: ClinicalReveiwIndicationContext
  ): void {
    if (clinicalReveiwIndication) {
      this.clinicalReviewTabData.push(
        this.guidelineHeader,
        ...this.mcgDataParserService.parseMCGData(
          clinicalReveiwIndication?.indication
        )
      );
      this.authorizationDetailsContext = {
        ...this.authorizationDetailsContext,
        clinical_review_data: this.clinicalReviewTabData,
      };
    } else {
      this.isClinicalReviewNoDataMessageDisplay = true;
    }
  }
}
