import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LinkListItem } from './link-list.model';

@Component({
  selector: 'link-list',
  templateUrl: './link-list.component.html',
  styleUrls: ['./link-list.component.scss'],
})
export class LinkListComponent {
  @Input() dataList: LinkListItem[];

  @Output() clicked = new EventEmitter();

  @Output() customMethod: EventEmitter<any> = new EventEmitter();

  linkClicked(data: LinkListItem, context?: string) {
    this.clicked.emit(data);

    if (this.customMethod.observers.length) {
      this.customMethod.emit({
        event: data,
        context,
      });
    }
  }
}
