/* eslint-disable no-underscore-dangle */
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ProviderUpdateToolService } from '../../provider-update-tool.service';

@Component({
  selector: 'location-hours',
  templateUrl: './location-hours.component.html',
  styleUrls: ['./location-hours.component.scss'],
})
export class LocationHoursComponent implements OnInit {
  @Input() context: any = {};
  @Input() config: any;
  @Input() location_hours: any;
  @Input() errorMessage: any;

  options: any = ['Closed', 'Open'];
  error_monday: boolean = false;
  error_tuesday: boolean = false;
  error_wednesday: boolean = false;
  error_thursday: boolean = false;
  error_friday: boolean = false;
  error_saturday: boolean = false;
  error_sunday: boolean = false;
  monday = [];
  show_monday: boolean = false;
  _monday = 'Closed';
  tuesday = [];
  show_tuesday: boolean = false;
  _tuesday: string = 'Closed';
  wednesday = [];
  show_wednesday: boolean = false;
  _wednesday: string = 'Closed';
  thursday = [];
  show_thursday: boolean = false;
  _thursday: string = 'Closed';
  friday = [];
  show_friday: boolean = false;
  _friday: string = 'Closed';
  saturday = [];
  show_saturday: boolean = false;
  _saturday: string = 'Closed';
  sunday = [];
  show_sunday: boolean = false;
  _sunday: string = 'Closed';

  constructor(public mp: ProviderUpdateToolService) {}

  public getEnabled() {
    const enabled: any[] = [];
    if (this.show_monday) enabled.push('monday');
    if (this.show_tuesday) enabled.push('tuesday');
    if (this.show_wednesday) enabled.push('wednesday');
    if (this.show_thursday) enabled.push('thursday');
    if (this.show_friday) enabled.push('friday');
    if (this.show_saturday) enabled.push('saturday');
    if (this.show_sunday) enabled.push('sunday');

    return enabled;
  }

  ngOnInit() {
    // TODO: Simple Refactor
    this.monday = [this.config.find((item: any) => item.prop === 'monday')];
    if (this.monday[0]['start'] || this.monday[0]['end']) {
      this.show_monday = true;
      this._monday = 'Open';
    }
    this.tuesday = [this.config.find((item: any) => item.prop === 'tuesday')];
    if (this.tuesday[0]['start'] || this.tuesday[0]['end']) {
      this.show_tuesday = true;
      this._tuesday = 'Open';
    }
    this.wednesday = [
      this.config.find((item: any) => item.prop === 'wednesday'),
    ];
    if (this.wednesday[0]['start'] || this.wednesday[0]['end']) {
      this.show_wednesday = true;
      this._wednesday = 'Open';
    }
    this.thursday = [this.config.find((item: any) => item.prop === 'thursday')];
    if (this.thursday[0]['start'] || this.thursday[0]['end']) {
      this.show_thursday = true;
      this._thursday = 'Open';
    }
    this.friday = [this.config.find((item: any) => item.prop === 'friday')];
    if (this.friday[0]['start'] || this.friday[0]['end']) {
      this.show_friday = true;
      this._friday = 'Open';
    }
    this.saturday = [this.config.find((item: any) => item.prop === 'saturday')];
    if (this.saturday[0]['start'] || this.saturday[0]['end']) {
      this.show_saturday = true;
      this._saturday = 'Open';
    }
    this.sunday = [this.config.find((item: any) => item.prop === 'sunday')];
    if (this.sunday[0]['start'] || this.sunday[0]['end']) {
      this.show_sunday = true;
      this._sunday = 'Open';
    }
  }

  getObjectProperty(obj, keys) {
    return keys.split('.').reduce(function (result, key) {
      return result[key];
    }, obj);
  }

  changeSuit(day: any, event: any) {
    this[`show_${day}`] = event.target.value === 'Open';
    if (event.target.value === 'Closed') this.validate();
  }

  checkStartEnd(day: string) {
    const control: UntypedFormGroup = this.location_hours.get(day);
    if (!control) return false;
    const start: UntypedFormControl = control.get(
      'start'
    ) as UntypedFormControl;
    const end: UntypedFormControl = control.get('end') as UntypedFormControl;
    if (!start?.value || !end?.value) return true;
    else return false;
  }

  validate(): boolean {
    let retBool: boolean = true;
    if (this.show_monday && this.checkStartEnd('monday')) {
      this.error_monday = true;
      retBool = retBool && false;
    } else {
      this.error_monday = false;
    }
    if (this.show_tuesday && this.checkStartEnd('tuesday')) {
      this.error_tuesday = true;
      retBool = retBool && false;
    } else {
      this.error_tuesday = false;
    }
    if (this.show_wednesday && this.checkStartEnd('wednesday')) {
      this.error_wednesday = true;
      retBool = retBool && false;
    } else {
      this.error_wednesday = false;
    }
    if (this.show_thursday && this.checkStartEnd('thursday')) {
      this.error_thursday = true;
      retBool = retBool && false;
    } else {
      this.error_thursday = false;
    }
    if (this.show_friday && this.checkStartEnd('friday')) {
      this.error_friday = true;
      retBool = retBool && false;
    } else {
      this.error_friday = false;
    }
    if (this.show_saturday && this.checkStartEnd('saturday')) {
      this.error_saturday = true;
      retBool = retBool && false;
    } else {
      this.error_saturday = false;
    }
    if (this.show_sunday && this.checkStartEnd('sunday')) {
      retBool = retBool && false;
      this.error_sunday = true;
    } else {
      this.error_sunday = false;
    }

    // loop through and check to see which value has invalid
    return retBool;
  }
}
