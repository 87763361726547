<form-control
  [group]="group"
  [config]="dropdownConfig"
  [control]="group.get(config.prop)"
>
</form-control>

<form-control
  *ngIf="otherControl"
  [group]="group"
  [config]="otherTextConfig"
  [control]="otherControl"
>
</form-control>
