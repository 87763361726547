import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { ButtonModule, FormControlModule } from '@zipari/design-system';
import { TableFilterComponent } from './table-filter.component';

@NgModule({
  imports: [CommonModule, FormsModule, ButtonModule, FormControlModule],
  declarations: [TableFilterComponent],
  exports: [TableFilterComponent],
})
export class TableFilterModule {}
