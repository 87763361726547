import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIService } from '../../../shared/services';
import {
  CreateUserConfig,
  ManageAccessCreateUser,
} from './managePortalUsersCreateUser.model';

@Injectable({
  providedIn: 'root',
})
export class ManagePortalUsersCreateUserHttpService {
  constructor(private api: APIService) {}

  updateUserDetails(
    endpoint: string,
    payload: object
  ): Observable<CreateUserConfig> {
    return this.api.patch(endpoint, payload);
  }

  getUserDetailsByEmail(endpoint: string): Observable<ManageAccessCreateUser> {
    return this.api.get(endpoint);
  }
}
