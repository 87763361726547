<article class="ProviderAdmins">
  <div class="ProviderAdmins__Content" *ngIf="config">
    <div class="ProviderAdmins__HeaderContainer">
      <h4
        class="t-bold ProviderAdmins__HeaderContainerTitle"
        [innerHTML]="config.title"
      ></h4>
      <p [innerHTML]="config.subTitle"></p>
    </div>
    <provider-admins-list [config]="config"></provider-admins-list>
  </div>
</article>
