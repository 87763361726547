import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ModelAttributeModule,
  SpinnerModule,
  StatusModule,
  TableModule,
  ButtonModule,
  TabsModule,
  BadgeModule,
  SidebarMainContainerModule,
  LinkListModule,
} from '@zipari/design-system';
import { InfoListModule } from '../../shared/modules/info-list/info-list.module';
import { SideBarModule } from '../../shared/modules/side-bar/side-bar.module';
import { ClaimAppealDetailComponent } from './claim-appeal-detail.component';
import { ClaimAppealDetailRoutingModule } from './claim-appeal-detail.routing.module';
import { DisputeResponseComponent } from './dispute-response/dispute-response.component';
import { MemberInfoComponent } from './member-info/member-info.component';
import { RequestorInfoComponent } from './requestor-info/requestor-info.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { ServiceInfoComponent } from './service-info/service-info.component';

@NgModule({
  declarations: [
    ClaimAppealDetailComponent,
    DisputeResponseComponent,
    MemberInfoComponent,
    RequestorInfoComponent,
    ContactInfoComponent,
    ServiceInfoComponent,
  ],
  exports: [ClaimAppealDetailComponent],
  imports: [
    CommonModule,
    ModelAttributeModule,
    TableModule,
    StatusModule,
    SpinnerModule,
    ButtonModule,
    TabsModule,
    ClaimAppealDetailRoutingModule,
    BadgeModule,
    InfoListModule,
    SidebarMainContainerModule,
    LinkListModule,
    SideBarModule,
  ],
})
export class ClaimAppealDetailModule {}
