import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { IconModule } from '../../notes/icon/icon.module';

import { LinkListComponent } from './link-list.component';

@NgModule({
  declarations: [LinkListComponent],
  imports: [CommonModule, RouterModule, IconModule],
  exports: [LinkListComponent],
})
export class LinkListModule {}
