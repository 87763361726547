import { FocusKeyManager } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { TypeaheadOptionConfiguration } from '@zipari/shared-ds-util-form';
import { EMPTY, Observable } from 'rxjs';

import { OptionComponent } from './option.component';

@Component({
  selector: 'accessible-options',
  templateUrl: './options-dropdown.component.html',
  styleUrls: ['./options-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsDropdownComponent implements AfterViewInit {
  /**
   * typeahead options available
   */
  @Input() options: TypeaheadOptionConfiguration[] = [];

  /**
   * keyboard input
   */
  @Input() onSiblingKeyDown: Observable<any> = EMPTY;

  /**
   * displayed option children
   */
  @ViewChildren(OptionComponent) renderedOptions: QueryList<OptionComponent>;

  /**
   * option selected event
   */
  @Output() selected = new EventEmitter<any>();

  /**
   * keyboard focus helper
   */
  private keyManager: FocusKeyManager<OptionComponent>;

  /**
   * boolean used to determine whether a tooltip should be shown
   */
  public showTooltip = false;

  /**
   * string that should be displayed as tooltip
   */
  public tooltipText: string;

  ngAfterViewInit() {
    this.keyManager = new FocusKeyManager(this.renderedOptions).withWrap();
    this.onSiblingKeyDown.subscribe((event) => {
      this.onKeyDown(event);
    });
  }

  /**
   * emit selected event on option click
   * @param option option selected on click
   */
  select(option) {
    this.selected.emit(option);
  }

  /**
   * handle keyboard events
   */
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowDown':
        this.keyManager.setNextItemActive();
        break;
      case 'ArrowUp':
        this.keyManager.setPreviousItemActive();
        break;
      default:
        this.keyManager.onKeydown(event);
    }
  }

  /**
   * called on option hover to determine if a tooltip should be shown
   * @param option the option being moused over
   */
  determineTooltip(option) {
    if (option.tooltip) {
      this.showTooltip = true;
      this.tooltipText = option.tooltip;
    } else {
      this.showTooltip = false;
      this.tooltipText = null;
    }
  }
}
