<div
  [ngClass]="{
    'u-flex u-justifyCenter u-alignCenter u-maxXY': flexCenter,
    'overlay--spinner': asOverlay
  }"
>
  <div
    class="spinner"
    [ngClass]="{
      'spinner--small': size === sizes.small,
      'spinner--medium': size === sizes.medium,
      'spinner--large': size === sizes.large
    }"
  ></div>
</div>
