import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AccordionModule,
  BannerModule,
  ButtonModule,
  CardModule,
  DatagridModule,
  FormControlModule,
  FormGroupModule,
  ModalModule,
  ModelAttributeModule,
  PaginatorModule,
  TableModule,
  TabsModule,
} from '@zipari/design-system';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ZipBusyModule } from '../../../../../shared/modules/zip-busy/zip-busy.module';
import { DataGridComponent } from './data-grid.component';

@NgModule({
  declarations: [DataGridComponent],
  exports: [DataGridComponent],
  imports: [
    CommonModule,
    CardModule,
    BannerModule,
    TableModule,
    RouterModule,
    FormControlModule,
    FormGroupModule,
    ButtonModule,
    ModalModule,
    ModelAttributeModule,
    ZipBusyModule,
    AccordionModule,
    ReactiveFormsModule,
    TabsModule,
    DatagridModule,
    PaginatorModule,
  ],
})
export class DataGridModule {}
