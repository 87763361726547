<ng-container *ngFor="let attachment of formArray?.controls; let i = index">
  <div class="file-info">
    <div class="u-flex">
      <icon
        class="file-info__icon"
        name="pdf"
        [config]="{
          format: 'svg',
          size: 'medium'
        }"
      >
      </icon>
      <p class="file-info__name">{{ attachment?.value?.name }}</p>
    </div>
    <p class="file-info__size">
      {{ attachment?.value?.size | filesize }}
    </p>

    <icon
      class="file-info__close"
      name="close"
      [config]="{
        format: 'svg',
        size: 'small'
      }"
      (click)="closeClicked.emit(i)"
      *ngIf="showCloseIcon"
    >
    </icon>
  </div>

  <div class="progressbar">
    <ng-container
      *ngIf="i + 1 === formArray?.controls?.length && total; else done"
    >
      <div class="progressbar__total">
        <div
          [ngClass]="{ progressbar__error: error }"
          class="progressbar__partial"
          [ngStyle]="progressWidth"
        ></div>
      </div>
      <p class="progressbar__percentage" *ngIf="!error">{{ total }}% done</p>
    </ng-container>
    <ng-template #done>
      <div class="progressbar__total">
        <div
          [ngClass]="{ progressbar__error: error }"
          class="progressbar__partial"
        ></div>
      </div>
      <p class="progressbar__percentage" *ngIf="!error">100% done</p>
    </ng-template>
  </div>
</ng-container>
