export const termsAndConditions = {
  prop: 'terms',
  type: 'checkbox',
  options: [
    {
      label: 'I accept these terms and conditions',
      value: 'true',
    },
  ],
  validators: ['required'],
};
