import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MaskDirective } from '../../../shared/directives/mask.directive';
import { ControlTextComponent } from '../text/text.component';

export const browser = {
  safari: 'Safari',
  firefox: 'Firefox',
  edge: 'MS-Edge',
  chrome: 'Chrome',
  ie: 'IE',
};

@Component({
  selector: 'control-tel',
  templateUrl: './tel.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaskDirective),
      multi: true,
    },
  ],
})
export class ControlTelComponent extends ControlTextComponent {
  constructor(deviceService: DeviceDetectorService) {
    super(deviceService);
  }
}
