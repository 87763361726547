<form [zipBusy]="busy" [formGroup]="group" *ngIf="group">
  <div class="form-control__label__wrapper">
    <div class="left">
      <label class="form-control__label t-bold">
        <p class="t-data t-bold">{{ config?.label }}</p>
        <p *ngIf="isRequired()" class="t-data t-bold spaced-star">{{ '*' }}</p>
      </label>
      <label class="form-control__label">
        <p [innerHTML]="config?.subheader" class="subheader"></p>
      </label>
    </div>
    <div
      *ngIf="!group?.get(config?.prop).value"
      class="right isCursor"
      (click)="toggleFlyout()"
    >
      <i class="icon icon--medium">search</i>
    </div>
    <div
      *ngIf="group?.get(config?.prop).value && !config?.isDisabled"
      class="right isCursor bottom-line"
      (click)="remove(config?.cx_event_key)"
    >
      <p class="remove">Remove</p>
    </div>
  </div>
  <div *ngIf="!group?.get(config?.prop).value" class="form-container">
    <div class="form-control form-control--dropdown">
      <select
        role="listbox"
        formControlName="{{ config?.prop }}"
        (change)="selectedProvider(group?.get(config?.prop).value)"
      >
        <option value="default">Select One</option>
        <option *ngFor="let c of config?.options" [ngValue]="c.value">
          {{ c.label }}
        </option>
      </select>
      <div class="u-flex u-alignCenter u-justifyEnd u-fillRemaining">
        <icon
          [config]="{ format: 'svg', size: 'small' }"
          [name]="config.icon || 'carat_down'"
          class="dropdown__chevron"
        ></icon>
      </div>
    </div>
  </div>
  <div *ngIf="group?.get(config?.prop).value as propValue">
    <div class="left" *ngIf="isServicingReferringProvider()">
      <label
        *ngIf="config?.displayFields.name && propValue"
        class="form-control__label"
      >
        <p class="t-data t-bold">
          {{ propValue[config?.displayFields.name]['full_name'] }}
        </p>
      </label>
      <p
        *ngIf="showOutOfNetworkStatusPill"
        class="OutOfNetwork__StatusPill Pending"
      >
        {{ outOfNetwork?.outOfNetworkLabel }}
      </p>
    </div>

    <div class="left" *ngIf="config?.prop === 'procedure_id'">
      <label
        *ngIf="config?.displayFields.code && propValue"
        class="form-control__label"
      >
        <p class="t-data t-bold">{{ propValue[config?.displayFields.code] }}</p>
      </label>
    </div>
    <div class="left" *ngIf="config?.prop === 'servicing_facility_id'">
      <label
        *ngIf="config?.displayFields.name && propValue"
        class="form-control__label"
      >
        <p class="t-data t-bold">{{ propValue[config?.displayFields.name] }}</p>
      </label>
      <p
        *ngIf="showOutOfNetworkStatusPill"
        class="OutOfNetwork__StatusPill Pending"
      >
        {{ outOfNetwork?.outOfNetworkLabel }}
      </p>
    </div>
    <!-- load this from config -->
    <div
      class="right"
      *ngIf="
        config?.prop === 'requesting_provider_id' ||
        config?.prop === 'servicing_provider'
      "
    >
      <label
        *ngIf="config?.displayFields.address && propValue"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Address:</p>
        <p *ngIf="!propValue[config?.displayFields.address]?.length">
          {{ noSpace }}
        </p>
        <div
          class="address"
          *ngIf="
            propValue[config?.displayFields.address]?.length &&
            propValue[config?.displayFields.address][0]['address']
          "
        >
          {{
            propValue[config?.displayFields.address][0]['address'][
              'street_name_1'
            ]
          }}<br />
          {{
            propValue[config?.displayFields.address][0]['address'][
              'street_name_2'
            ]
          }}<br />
          {{
            propValue[config?.displayFields.address][0]['address']['city_name']
          }},
          {{ propValue[config?.displayFields.address][0]['address']['state'] }}
          {{
            propValue[config?.displayFields.address][0]['address']['zip_code']
          }}
        </div>
      </label>
      <label
        *ngIf="config?.displayFields.group_npi && propValue"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Group NPI:</p>
        <p>{{ propValue[config?.displayFields.group_npi] || noSpace }}</p>
      </label>
      <label
        *ngIf="config?.displayFields.id_number && propValue"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">ID Number:</p>
        <p>{{ propValue[config?.displayFields.id_number] || noSpace }}</p>
      </label>
      <label
        *ngIf="config?.displayFields.specialty && propValue"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Primary Specialty:</p>
        <p *ngIf="propValue[config?.displayFields.specialty]">
          {{ propValue[config?.displayFields.specialty] }}
        </p>
        <p *ngIf="!propValue[config?.displayFields.specialty]">{{ noSpace }}</p>
      </label>
    </div>
    <div
      *ngIf="
        config?.prop == 'servicing_provider_id' ||
        config?.prop == 'referring_provider_id'
      "
      class="right"
    >
      <label
        *ngIf="config?.displayFields.address && propValue"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Address:</p>

        <p *ngIf="!propValue[config?.displayFields.address]?.length">
          {{ noSpace }}
        </p>
        <div
          class="address"
          *ngIf="
            propValue[config?.displayFields.address].length &&
            propValue[config?.displayFields.address][0]['address']
          "
        >
          {{
            propValue[config?.displayFields.address][0]['address'][
              'street_name_1'
            ]
          }}<br />
          {{
            propValue[config?.displayFields.address][0]['address'][
              'street_name_2'
            ]
          }}<br />
          {{
            propValue[config?.displayFields.address][0]['address']['city_name']
          }},
          {{ propValue[config?.displayFields.address][0]['address']['state'] }}
          {{
            propValue[config?.displayFields.address][0]['address']['zip_code']
          }}
        </div>
      </label>
      <label
        *ngIf="config?.displayFields.group_npi && propValue"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">NPI:</p>
        <p>{{ propValue[config?.displayFields.group_npi] || noSpace }}</p>
      </label>
      <label
        *ngIf="config?.displayFields.id_number && propValue"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">ID Number:</p>
        <p>{{ propValue[config?.displayFields.id_number] || noSpace }}</p>
      </label>
      <label
        *ngIf="config?.displayFields.specialty && propValue"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Primary Specialty:</p>
        <p *ngIf="propValue[config?.displayFields.specialty].length">
          {{ propValue[config?.displayFields.specialty][0]['name'] }}
        </p>
        <p *ngIf="!propValue[config?.displayFields.specialty]?.length">
          {{ noSpace }}
        </p>
      </label>
    </div>
    <div class="right" *ngIf="config?.prop === 'servicing_facility_id'">
      <label
        *ngIf="config?.displayFields.address && propValue"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Address:</p>
        <p *ngIf="!propValue[config?.displayFields.address]">{{ noSpace }}</p>
        <div class="address" *ngIf="propValue[config?.displayFields.address]">
          {{ propValue[config?.displayFields.address]['street_name_1'] }}<br />
          {{ propValue[config?.displayFields.address]['street_name_2'] }}<br />
          {{ propValue[config?.displayFields.address]['city_name'] }},
          {{ propValue[config?.displayFields.address]['state'] }}
          {{ propValue[config?.displayFields.address]['zip_code'] }}
        </div>
      </label>
      <label
        *ngIf="config?.displayFields.group_npi && propValue"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Group NPI:</p>
        <p>{{ propValue[config?.displayFields.group_npi] || noSpace }}</p>
      </label>
      <label
        *ngIf="config?.displayFields.id_number && propValue"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">ID Number:</p>
        <p>{{ propValue[config?.displayFields.id_number] || noSpace }}</p>
      </label>
      <label
        *ngIf="config?.displayFields.specialty && propValue"
        class="form-control__label"
      >
        <p class="spacing t-data t-bold">Primary Specialty:</p>
        <p *ngIf="propValue[config?.displayFields.specialty]?.length">
          {{ propValue[config?.displayFields.specialty][0]['name'] }}
        </p>
        <p *ngIf="!propValue[config?.displayFields.specialty]?.length">
          {{ noSpace }}
        </p>
      </label>
    </div>
    <div class="right" *ngIf="config?.prop === 'procedure_id'">
      <label
        *ngIf="config?.displayFields.description && propValue"
        class="form-control__label"
      >
        <p>{{ propValue[config?.displayFields.description] || noSpace }}</p>
      </label>
    </div>
    <div class="title-border"></div>
  </div>
  <!-- </div> -->
  <div
    *ngIf="
      group?.get(config?.prop)?.invalid &&
      (group?.get(config?.prop)?.dirty || group?.get(config?.prop)?.touched)
    "
  >
    <div class="error" *ngIf="group?.get(config?.prop).errors">
      <icon
        name="warning"
        style="height: 1.125rem; padding-left: 1rem; padding-right: 0.5rem"
        ><i
          class="icon icon--font ng-star-inserted icon--small"
          style="color: red"
        >
          warning
        </i>
      </icon>
      <p class="t-caption t-error">
        {{ config?.errorMessage || 'Please enter a valid input.' }}
      </p>
    </div>
  </div>
</form>

<modal
  #flyout
  *ngIf="showModal"
  [config]="config?.flyout"
  [condition]="showModal"
  (cancel)="toggleFlyout()"
>
  <div body>
    <div class="header-associate">
      <p>
        {{ config?.flyout?.subheader }}
      </p>
    </div>
    <zip-table
      *ngIf="loaded"
      (selectedRadioRow)="selectedRadioRow($event)"
      [options]="config?.flyout.table"
      (customMethod)="callCxEvent($event)"
    >
    </zip-table>
  </div>
  <div footer>
    <zip-button
      [disabled]="!selectedItem"
      level="high"
      [content]="config?.flyout?.submitButton"
      (click)="add()"
    ></zip-button>
  </div>
</modal>

<modal
  *ngIf="outOfNetwork?.isOutOfNetworkEnabled && showOutOfNetworkModal"
  [config]="outOfNetwork?.outOfNetworkModal?.outOfNetworkModalBody"
  [condition]="outOfNetwork?.isOutOfNetworkEnabled && showOutOfNetworkModal"
  (cancel)="closeOutOfNetworkModal()"
  class="OutOfNetworkModal"
>
  <div body>
    <div class="header-associate">
      <p>
        {{ outOfNetwork.outOfNetworkModal?.outOfNetworkModalBody?.description }}
      </p>
    </div>
  </div>
  <div footer class="OutOfNetworkModal__Footer">
    <zip-button
      [config]="outOfNetwork.outOfNetworkModal?.footer?.cancelButton"
      (click)="closeOutOfNetworkModal()"
    ></zip-button>
    <zip-button
      [config]="outOfNetwork.outOfNetworkModal?.footer?.confirmButton"
      (click)="confirm()"
    ></zip-button>
  </div>
</modal>
