import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Option } from '@zipari/design-system';
import { RequestAccessDataService } from '../service/request-access-data.service';
import { ReplacementUsersModalConfig } from './replacement-users-modal.model';

@Component({
  selector: 'replacement-users-modal',
  templateUrl: './replacement-users-modal.component.html',
  styleUrls: ['./replacement-users-modal.component.scss'],
})
export class ReplacementUsersModalComponent {
  @Input() config: ReplacementUsersModalConfig;
  @Input() showModal: boolean;
  @Input() associatedTaxIds: Option[];
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectedItems: EventEmitter<Option[]> = new EventEmitter<
    Option[]
  >();

  constructor(public requestAccessDataService: RequestAccessDataService) {}

  modalClosed(): void {
    this.closeModal.emit();
  }

  submitClick(): void {
    this.submitClicked.emit();
  }

  handleListSelection(items: Option[]): void {
    this.selectedItems.emit(items);
  }
}
