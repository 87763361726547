<ng-container *ngFor="let arrayGroup of formArray.controls; let i = index">
  <card class="FormGroupArray__Card">
    <ng-container title>
      <form-group-array-title
        [config]="config"
        [index]="i"
        [formArray]="formArray"
      ></form-group-array-title>
    </ng-container>
    <form-group-array-header
      header-action
      [config]="config"
      [formArray]="formArray"
      [index]="i"
      (removeGroupClicked)="removeGroup($event)"
    ></form-group-array-header>

    <div [formGroup]="arrayGroup" body-group>
      <form-group-array-description
        [config]="config"
        [arrayGroup]="arrayGroup"
        [context]="context ? context[i] : null"
      ></form-group-array-description>
    </div>
    <form-group-array-content-footer
      [config]="config"
      [formArray]="formArray"
      [index]="i"
      (addDuplicateClicked)="addDuplicateForm($event)"
      footer
    ></form-group-array-content-footer>
  </card>
</ng-container>
