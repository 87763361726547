export const ILLUSTRATION_URLS = {
  COFFEE_BREAK:
    'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/coffee-break.svg',
  EMPTY_1: 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/empty-1.svg',
  EMPTY_2: 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/empty-2.svg',
  EMPTY_3: 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/empty-3.svg',
  EMPTY_4: 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/empty-4.svg',
  ERROR_1: 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/error-1.svg',
  ERROR_2: 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/error-2.svg',
  GET_STARTED:
    'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/get-started.svg',
  SEARCHING_1:
    'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/searching-1.svg',
  SEARCHING_2:
    'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/searching-2.svg',
  SEARCHING_3:
    'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/searching-3.svg',
  SEARCHING_4:
    'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/searching-4.svg',
  SENT: 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/sent.svg',
  SUCCESS: 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/success.svg',
  ORDER_COMPLETE:
    'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/order-complete.svg',
  WAITING_1:
    'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/waiting-1.svg',
  WAITING_2:
    'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/waiting-2.svg',
  WELCOME_1:
    'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/welcome-1.svg',
  WELCOME_2:
    'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/welcome-2.svg',
  BAD_GATEWAY: 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/502.svg',
  NOT_FOUND: 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/404.svg',
  FAMILY: 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/family.svg',
  UPGRADE: 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/upgrade.svg',
};
