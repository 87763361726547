export class DatePickerValidatorConfiguration {
  date?: string;
  dependency?: string;
  message?: string;
  name?: string;
  start_date_validators?: DatePickerValidatorConfiguration[];
  end_date_validators?: DatePickerValidatorConfiguration[];
}

export const invalidDateErrorMessage = 'Please enter a valid date';
export const validDateKey = 'validDate';
export const startValidDateKey = 'startValidDate';
export const endValidDateKey = 'endValidDate';
export const birthDateKey = 'birthDateDate';
export const future60DaysKey = 'future60Days';
export const last60DaysKey = 'last60Days';
export const sepQualifyingDateKey = 'sepQualifyingDate';
export const reasonableDateKey = 'reasonableDate';
export const dateInFutureIncludingCurrentMonthKey =
  'dateInFutureIncludingCurrentMonth';
export const dateInFutureKey = 'dateInFuture';
export const dateInPastKey = 'dateInPast';
export const dateWithinDaysFutureKey = 'dateWithinDaysFuture';
export const dateWithinDaysPastKey = 'dateWithinDaysPast';
export const numberOfDays = 5;
