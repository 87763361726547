import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonSearchModule } from '../../../../notes/button/button-search/button-search.module';
import { ButtonModule } from '../../../../notes/button/button.module';
import { FormControlModule } from '../../../../notes/form-control/form-control.module';
import { ModalModule } from '../../../../notes/modal/modal.module';
import { FormGroupModule } from '../../../form-group/form-group.module';
import { TableFilterComponent } from './table-filter.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    FormControlModule,
    FormGroupModule,
    ModalModule,
    ButtonSearchModule,
  ],
  declarations: [TableFilterComponent],
  exports: [TableFilterComponent],
})
export class TableFilterModule {}
