import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoadingState } from './loader.reducer';

export const selectLoadingState =
  createFeatureSelector<LoadingState>('loading-state');

export const selectLoader = createSelector(
  selectLoadingState,
  (state: LoadingState) => state.loading
);
