import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormControlModule, FormGroupModule } from '@zipari/design-system';
import { ZipBusyModule } from '../../../../shared/modules/zip-busy/zip-busy.module';
import { HeaderCellModule } from '../header-cell/header-cell.module';
import { ItemCellModule } from '../item-cell/item-cell.module';
import { ProvisionerColumnComponent } from './provisioner-column.component';

@NgModule({
  declarations: [ProvisionerColumnComponent],
  exports: [ProvisionerColumnComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormControlModule,
    FormGroupModule,
    HeaderCellModule,
    ZipBusyModule,
    ItemCellModule,
  ],
})
export class ProvisionerColumnModule {}
