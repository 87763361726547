import { Component, Input } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { formatGridColumns } from '../../../../../shared/utils/css-grid';
import { FormGroupArrayConfig } from '../../form-group-array.config';

@Component({
  selector: 'form-group-array-description',
  templateUrl: './form-group-array-description.component.html',
  styleUrls: ['./form-group-array-description.component.scss'],
})
export class FormGroupArrayDescriptionComponent {
  @Input() arrayGroup: UntypedFormArray;
  @Input() config: FormGroupArrayConfig;
  @Input() context: object[];

  setGridColumns(columns: number) {
    return formatGridColumns(columns);
  }
}
