export enum MemberTypes {
  self = 'Subscriber',
  subscriber = 'Subscriber',
  spouse = 'Spouse',
  'domestic partner' = 'Spouse',
  life_partner = 'Spouse',
  dependent = 'Dependent',
  child = 'Dependent',
  parent = 'Dependent',
  grandparent = 'Dependent',
  grandson = 'Dependent',
  uncle_or_aunt = 'Dependent',
  nephew_or_niece = 'Dependent',
  cousin = 'Dependent',
  foster_child = 'Dependent',
  son_in_law_or_daughter_in_law = 'Dependent',
  brother_in_law_or_sister_in_law = 'Dependent',
  mother_in_law_or_father_in_law = 'Dependent',
  sibling = 'Dependent',
  ward = 'Dependent',
  step_parent = 'Dependent',
  step_child = 'Dependent',
  sponsored_dependent = 'Dependent',
  ex_spouse = 'Dependent',
}
