import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IdCardsComponent } from '../id-cards/id-cards.component';
import { AccumulatorsComponent } from './accumulators/accumulators.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { DataTableComponent } from './data-table/data-table.component';
import { PatientDetailComponent } from './patient-detail.component';
import { PATIENT_DETAIL_URLS } from './patientDetailConfig.model';

const routes: Routes = [
  {
    path: '',
    component: PatientDetailComponent,
    children: [
      {
        path: PATIENT_DETAIL_URLS.BENEFITS,
        component: BenefitsComponent,
        data: {
          activeTab: PATIENT_DETAIL_URLS.BENEFITS,
        },
      },
      {
        path: PATIENT_DETAIL_URLS.IDCARDS,
        component: IdCardsComponent,
        data: {
          activeTab: PATIENT_DETAIL_URLS.IDCARDS,
        },
      },
      {
        path: PATIENT_DETAIL_URLS.ACCUMULATORS,
        component: AccumulatorsComponent,
        data: {
          activeTab: PATIENT_DETAIL_URLS.ACCUMULATORS,
        },
      },
      {
        path: PATIENT_DETAIL_URLS.CLAIMS,
        component: DataTableComponent,
        data: {
          activeTab: PATIENT_DETAIL_URLS.CLAIMS,
        },
      },
      {
        path: PATIENT_DETAIL_URLS.AUTHORIZATIONS,
        component: DataTableComponent,
        data: {
          activeTab: PATIENT_DETAIL_URLS.AUTHORIZATIONS,
        },
      },
      {
        path: PATIENT_DETAIL_URLS.PREGNANCY_FORM,
        component: DataTableComponent,
        data: {
          activeTab: PATIENT_DETAIL_URLS.PREGNANCY_FORM,
        },
      },
      {
        path: PATIENT_DETAIL_URLS.REFERRALS,
        component: DataTableComponent,
        data: {
          activeTab: PATIENT_DETAIL_URLS.REFERRALS,
        },
      },
      {
        path: PATIENT_DETAIL_URLS.ELIGIBLILITY_HISTORY,
        component: DataTableComponent,
        data: {
          activeTab: PATIENT_DETAIL_URLS.ELIGIBLILITY_HISTORY,
        },
      },
      {
        path: '**',
        redirectTo: PATIENT_DETAIL_URLS.BENEFITS,
        pathMatch: 'prefix',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PatientDetailRoutingModule {}
