<ng-container *ngIf="config?.label">
  <label class="form-control__label" [ngClass]="{ 'is-required': required }">
    <div class="u-flex u-alignBaseline u-maxX">
      <p class="t-data t-bold">
        {{ config?.label }} <span *ngIf="required">*</span>
      </p>
    </div>
  </label>
</ng-container>

<div class="ssn-group__controls">
  <form-control
    *ngFor="let ctrl of ssnControlConfigs"
    [group]="formGroup"
    [config]="ctrl"
    [control]="formGroup.get(ctrl.prop)"
  >
  </form-control>
</div>

<ng-container *ngIf="errors">
  <div class="u-flex ssn-group__error">
    <icon
      name="warning"
      [config]="{
        size: 'small',
        set: 'system',
        format: 'svg',
        color: 'error'
      }"
    >
    </icon>
    <p [attr.aria-label]="displayedError" class="t-caption t-error">
      {{ config.message }}
    </p>
  </div>
</ng-container>
