import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RippleModule } from '../ripple/ripple.module';
import { PreloaderComponent } from './preloader.component';

@NgModule({
  declarations: [PreloaderComponent],
  imports: [CommonModule, RippleModule],
  exports: [PreloaderComponent],
})
export class PreloaderModule {}
