import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IconModule } from '../../icon/icon.module';

import { ControlPasswordComponent } from './control-password.component';

@NgModule({
  declarations: [ControlPasswordComponent],
  imports: [CommonModule, FormsModule, IconModule, ReactiveFormsModule],
  exports: [ControlPasswordComponent],
})
export class ControlPasswordModule {}
