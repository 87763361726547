import { Component, TemplateRef } from '@angular/core';

import { ZipBusyService } from './zip-busy.service';

@Component({
  selector: 'zip-busy',
  templateUrl: './zip-busy.component.html',
})
export class ZipBusyComponent {
  public promiseTracker;
  public position: string;
  public style: any = {};
  public customTemplate: TemplateRef<any>;

  constructor(promiseTracker: ZipBusyService) {
    this.promiseTracker = promiseTracker;
  }
}
