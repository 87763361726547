import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../button/button.module';
import { ExpandingListComponent } from './expanding-list.component';

@NgModule({
  declarations: [ExpandingListComponent],
  imports: [CommonModule, ButtonModule],
  exports: [ExpandingListComponent],
})
export class ExpandingListModule {}
