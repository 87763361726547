import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  FormControlModule,
  FormGroupModule,
  ModalModule,
} from '@zipari/design-system';
import { ControlsGroupArrayComponent } from './controls-group-array.component';

@NgModule({
  declarations: [ControlsGroupArrayComponent],
  exports: [ControlsGroupArrayComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormControlModule,
    FormGroupModule,
    ReactiveFormsModule,
    ModalModule,
  ],
})
export class ControlsGroupArrayModule {}
