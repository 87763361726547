import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { FormGroupArrayConfig } from '../../form-group-array.config';

@Component({
  selector: 'form-group-array-header',
  templateUrl: './form-group-array-header.component.html',
  styleUrls: ['./form-group-array-header.component.scss'],
})
export class FormGroupArrayHeaderComponent {
  @Input() config: FormGroupArrayConfig;
  @Input() formArray: UntypedFormArray;
  @Input() index: number;
  @Output() removeGroupClicked: EventEmitter<number> = new EventEmitter();

  removeGroup(index) {
    this.removeGroupClicked.emit(index);
  }
}
