import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { APIList } from '../interfaces';

export interface ZipcodeLocationData {
  cities: {
    city_name: string;
    zipcodes: any[];
  }[];
  county_code: string;
  county_name: string;
  plan_type_name: string[];
  plan_year: number[];
  state_code: string;
  state_name: string;
  zipcodes: any[];
}

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  private cache = new Map<string, Observable<ZipcodeLocationData[]>>();
  constructor(private httpClient: HttpClient) {}

  public getZipcodeLocationData(
    zipCode: string
  ): Observable<ZipcodeLocationData[]> {
    if (this.cache.has(zipCode)) {
      return this.cache.get(zipCode);
    }
    const zipcodeData$ = this.httpClient
      .get<APIList<ZipcodeLocationData>>(
        `api/enrollment/locations/zipcodes/${zipCode}/`
      )
      .pipe(
        map((response) => response.results),
        shareReplay(),
        catchError((err) => of([]))
      );
    this.cache.set(zipCode, zipcodeData$);

    return zipcodeData$;
  }

  public getStateForZipCode(zipcode: string): Observable<string> {
    return this.getZipcodeLocationData(zipcode).pipe(
      map((zipcodeData) => zipcodeData[0] && zipcodeData[0].state_code)
    );
  }

  public getCountyNameForZipCode(zipcode: string): Observable<string> {
    return this.getZipcodeLocationData(zipcode).pipe(
      map((zipcodeData) => zipcodeData[0] && zipcodeData[0].county_name)
    );
  }

  /**
   * returns formatted city or county options to populate dropdown based on  provided zipcode
   * @param zipCode
   * @param type
   */
  public getCityOrCountyOverride(zipCode, type: 'city_name' | 'county') {
    switch (type) {
      case 'city_name':
        return this.getCityOptions(zipCode);
      case 'county':
        return this.getCountyOptions(zipCode);
      default:
        break;
    }
  }

  /**
   * transform zipcode response and return an array of city options w/ duplicates removed
   * @param zipcode
   */
  public getCityOptions(
    zipcode: string
  ): Observable<{ label: string; value: string }[]> {
    const optionsSet = new Set();

    return this.getZipcodeLocationData(zipcode).pipe(
      map((data) => {
        data.forEach((zipData) => {
          if (zipData.cities) {
            zipData.cities.forEach((city) => {
              optionsSet.add(city.city_name);
            });
          } else if (zipData.county_name) {
            optionsSet.add(zipData.county_name);
          }
        });
        let cityOptions = [];
        optionsSet.forEach((value) => {
          cityOptions = [
            ...cityOptions,
            ...[
              {
                label: value,
                value: value,
              },
            ],
          ];
        });

        return cityOptions;
      })
    );
  }

  getCountyOptions = (
    zipcode: string
  ): Observable<{ label: string; value: any }[]> =>
    this.getZipcodeLocationData(zipcode).pipe(
      map((zipcodeData) =>
        zipcodeData.map(({ county_code, county_name }) => {
          const newCodeData = {
            label: county_name,
            value: county_code,
          };

          return newCodeData;
        })
      )
    );
}
