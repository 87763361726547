import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DropdownConfiguration } from '@zipari/shared-ds-util-form';
import { Observable, of } from 'rxjs';
import { shareReplay, tap, catchError, delay, map } from 'rxjs/operators';
import { APIResponse } from './api.service';

export interface ZipcodeLocationData {
  cities: {
    city_name: string;
    zipcodes: any[];
  }[];
  county_code: string;
  county_name: string;
  plan_type_name: string[];
  plan_year: number[];
  state_code: string;
  state_name: string;
  zipcodes: any[];
}

export interface VerifyAddressResponse {
  address?: {
    city: string;
    state: string;
    street_name_1: string;
    street_name_2: string;
    zip_code: string;
  };
  id?: string;
  errors?: any;
}

@Injectable()
export class AddressService {
  readonly verifyAddressEndpoint = 'api/locations/verify_us_address/';
  public cache = new Map<string, Observable<ZipcodeLocationData[]>>();

  constructor(private httpClient: HttpClient) {}

  public getZipcodeLocationData(
    zipCode: string
  ): Observable<ZipcodeLocationData[]> {
    if (this.cache.has(zipCode)) {
      return this.cache.get(zipCode);
    }
    const zipcodeData$ = this.httpClient
      .get<APIResponse<ZipcodeLocationData>>(
        `api/enrollment/locations/zipcodes/${zipCode}/`
      )
      .pipe(
        map((response: any) => response.results),
        shareReplay(),
        catchError((err: any) => of([]))
      );
    this.cache.set(zipCode, zipcodeData$);

    return zipcodeData$;
  }

  public validateAddress(address) {
    const tempAddress = {
      ...address,
      ...{ zip_code: `${address.zip_code}-0013` },
    };

    return of(tempAddress).pipe(delay(1000));
  }

  public verifyAddress(addressToVerify): Observable<VerifyAddressResponse> {
    const payload = {
      street_name_1: addressToVerify.street_name_1 || addressToVerify.address_1,
      street_name_2: addressToVerify.street_name_2 || addressToVerify.address_2,
      city: addressToVerify.city || addressToVerify.city_name,
      state: addressToVerify.state,
      zipcode: addressToVerify.zipcode,
      zip_code: addressToVerify.zipcode,
    };

    return this.httpClient
      .post<VerifyAddressResponse>(this.verifyAddressEndpoint, payload)
      .pipe(
        map((response: any) => {
          if (response.errors) {
            return response;
          }
          const { address, id } = response;

          return {
            id,
            address: {
              ...address,
              zip_code: address.zip_code.replace(/-\d*/, ''),
            },
          };
        })
      );
  }

  public getStateForZipCode(zipcode: string): Observable<string> {
    return this.getZipcodeLocationData(zipcode).pipe(
      map((zipcodeData: any) => zipcodeData[0] && zipcodeData[0].state_code)
    );
  }

  /**
   * returns formatted city or county options to populate dropdown based on  provided zipcode
   * @param zipCode
   * @param type
   */
  public getCityOrCountyOverride(zipCode, type: 'city' | 'county') {
    switch (type) {
      case 'city':
        return this.getCityOptions(zipCode);
      case 'county':
        return this.getCountyOptions(zipCode);
      default:
        break;
    }
  }

  /**
   * transform zipcode response and return an array of city options w/ duplicates removed
   * @param zipcode
   */
  public getCityOptions(
    zipcode: string
  ): Observable<{ label: string; value: string }[]> {
    return this.getZipcodeLocationData(zipcode).pipe(
      map((data: any) =>
        data.reduce(
          (cityOptions: { label: string; value: string }[], zipData) => {
            // cities w/ dupes removed - formatted for dropdown
            const newOptions = zipData.cities.reduce(
              (formattedOptions, city) => {
                if (
                  !cityOptions.some(
                    (option): any => option.value === city.city_name
                  )
                ) {
                  formattedOptions.push({
                    label: city.city_name,
                    value: city.city_name,
                  });
                }

                return formattedOptions;
              },
              []
            );

            return cityOptions.concat(newOptions);
          },
          []
        )
      )
    );
  }

  public getCountyOptions(
    zipcode: string,
    utils?: { config: DropdownConfiguration; control: UntypedFormControl }
  ): Observable<{ label: string; value: any }[]> {
    return this.getZipcodeLocationData(zipcode).pipe(
      map((zipcodeData: any) =>
        zipcodeData.map(({ county_code, county_name }) => ({
          label: county_name,
          value: county_code,
        }))
      ),
      tap((zipCodeData: any) => {
        if (utils) {
          if (utils.config) {
            utils.config['options'] = zipCodeData;
          }

          if (zipCodeData.length === 1 && utils.control) {
            utils.control.patchValue(zipCodeData[0].value);
          }
        }
      })
    );
  }
}
