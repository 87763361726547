import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PortalUser } from '../../../shared/models/shared/PortalUser.model';
import { ConfigService } from '../../../shared/services';
import { mockPortalUser } from './mock-portal-user';

export class PortalUserPageResponse {
  count: number;
  previous: number;
  next: number;
  results: PortalUser[];
}

@Injectable()
export class PortalUsersService {
  config: any;
  portalUser$ = new BehaviorSubject<PortalUser>(new PortalUser());
  portalUsersEndpoint: string;

  get portalUser() {
    return this.portalUser$.asObservable();
  }

  constructor(private configService: ConfigService, private http: HttpClient) {
    this.updatePortalUser();
    this.loadConfig();
  }

  /** Update portal user behavior subject with real value */
  updatePortalUser() {
    this.getMockPortalUser().subscribe(
      (portalUser: PortalUser) => {
        this.portalUser$.next(portalUser);
      },
      (error: Error) => console.error(`Failed to get portal user: ${error}`)
    );
  }

  /** Load configuration from `tenant-configs` */
  loadConfig() {
    this.config = this.configService.getPageConfig('portal-users');
    this.portalUsersEndpoint = this.config?.endpoint;
  }

  /** Return mock portal user */
  getMockPortalUser(): Observable<PortalUser> {
    return of(mockPortalUser);
  }

  /** Request all portal users from the backend */
  getPortalUsersPage(page: number): Observable<PortalUserPageResponse> {
    return this.http.get<PortalUserPageResponse>(this.pageUrl(page));
  }

  /** Composer URL for a particular page of the portal users endpoint. */
  pageUrl = (page: number) => this.portalUsersEndpoint?.concat(`?page=${page}`);

  /** Get a particular portal user from the backend */
  getPortalUser(name: string) {
    return this.http.get<PortalUser>(
      this.portalUserUrl(name),
      this.applicationJson()
    );
  }

  createPortalUser(newUser: PortalUser): Observable<PortalUser> {
    return this.http.post<PortalUser>(
      this.portalUsersEndpoint,
      newUser,
      this.applicationJson()
    );
  }

  /** Put edited portal user to the backend */
  editPortalUser(portalUser: PortalUser): Observable<PortalUser> {
    return this.http.put<PortalUser>(
      this.portalUserUrl(portalUser.user_name),
      portalUser,
      this.applicationJson()
    );
  }

  /** Add deactivation data to portal user and PUT it to the backend  */
  deactivateUser(
    portalUser: PortalUser,
    date: string,
    sendEmail: boolean
  ): Observable<PortalUser> {
    const deactivatedPortalUser = this.addDeactivationData(
      portalUser,
      date,
      sendEmail
    );

    return this.editPortalUser(deactivatedPortalUser);
  }

  /** Add deactivation date and send deactivation email flag to a portal user's data */
  addDeactivationData(
    portalUser: PortalUser,
    deactivationDate: string,
    sendEmail: boolean
  ): PortalUser {
    portalUser.data.deactivation_date = deactivationDate;
    portalUser.data.send_deactivation_email = sendEmail;

    return portalUser;
  }

  applicationJson = () =>
    Object.assign(
      {},
      { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }
    );
  portalUserUrl = (name?: string) =>
    this.portalUsersEndpoint?.concat(String(name));
}
