import { Component, OnInit } from '@angular/core';

import Claim from '../../../../shared/models/shared/Claim.model';
import { ClaimDetailService } from '../claim-detail.service';
import { FinancialOverviewTabConfig } from '../ClaimDetailConfig.model';

@Component({
  selector: 'financial-overview',
  templateUrl: './financial-overview.component.html',
  styleUrls: ['./financial-overview.component.scss'],
})
export class FinancialOverviewComponent implements OnInit {
  config: FinancialOverviewTabConfig;
  data: Claim;

  constructor(private claimDetailService: ClaimDetailService) {}

  ngOnInit(): void {
    this.config = this.claimDetailService.tabsConfig;
    this.data = this.claimDetailService.claim;
  }
}
