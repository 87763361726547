<modal
  (cancel)="isMobileFiltersOpen = false"
  [condition]="isMobileFiltersOpen"
  [config]="modalConfig"
>
  <section body *ngIf="!!tab?.filters">
    <form-group
      [form]="filtersForm"
      [configs]="tab?.filters?.controls"
    ></form-group>
  </section>

  <ng-container footer>
    <div
      class="u-flex u-justifyBetween u-maxX"
      *ngIf="
        documentCenterConfig?.page?.buttonClearFilters ||
        documentCenterConfig?.page?.buttonApplyFilters
      "
    >
      <zip-button
        *ngIf="documentCenterConfig?.page?.buttonClearFilters"
        data-testId="DocumentCenterClearFiltersMobile"
        (zipButtonClicked)="clearFilters.emit()"
        [config]="documentCenterConfig.page.buttonClearFilters"
      >
      </zip-button>

      <zip-button
        *ngIf="documentCenterConfig?.page?.buttonApplyFilters"
        data-testId="DocumentCenterApplyFiltersMobile"
        (zipButtonClicked)="applyFilters.emit()"
        [config]="documentCenterConfig.page.buttonApplyFilters"
      >
      </zip-button>
    </div>
  </ng-container>
</modal>
