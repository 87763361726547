import { Injectable } from '@angular/core';
import { EventInfo, MemberInfo } from '@zipari/shared-ds-util-digital-data';

@Injectable({
  providedIn: 'root',
})
export class DigitalDataService {
  setPageObject(page) {
    window.digitalData.page = page;
  }
  setMemberObject(member: MemberInfo) {
    window.digitalData.member = member;
  }
  pushEventObject(eventObject: EventInfo) {
    window.digitalData.event.push(eventObject);
  }
}
