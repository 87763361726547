export const transferOfCareConstants = {
  editAuthUrl: '/provider-portal/authorizations/',
  authStatus: 'pended',
};

export const InNetworkStatuses = ['preferred', 'in'];
export const OutNetworkStatus = 'out';
export const requestingProviderId = 'requesting_provider_id';

export enum OutOfNetworkProps {
  servicingProviderNetworkStatus = 'servicing_provider_network_status',
  servicingFacilityNetworkStatus = 'servicing_facility_network_status',
  servicingProvider = 'servicing_provider',
  servicingFacilityId = 'servicing_facility_id',
}
