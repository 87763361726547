<div class="accumulators">
  <div
    class="accumulators__description"
    *ngIf="config?.description"
    [innerHTML]="config.description"
  ></div>

  <div class="accumulators__filters" *ngIf="formGroup">
    <form-control
      class="filter"
      *ngIf="
        formGroup.get(config.filters.prop) && config.filters?.options.length > 0
      "
      [group]="formGroup"
      [control]="formGroup.get(config.filters.prop)"
      [config]="config.filters"
      direction="filter"
    >
    </form-control>
  </div>

  <div class="progress-bar__view">
    <div *ngIf="benefits">
      <div *ngFor="let section of config.sections">
        <header class="section__header">
          <h6 class="section__header__text">
            {{ section.header }}
          </h6>
        </header>
        <div *ngFor="let bar of section.progressBars | filter : config.filters">
          <div class="progress-bar">
            <progress-bar [data]="bar"></progress-bar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
