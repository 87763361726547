import { Pipe, PipeTransform } from '@angular/core';

import { getValue } from '../utilities/get-value';

/*
    Pipe that returns a value from an object and makes sure that the value is a number...
    if the provided value is not a number it returns 0
*/

@Pipe({
  name: 'safeNumber',
})
export class SafeNumberPipe implements PipeTransform {
  transform(obj: any, key: string): number {
    let valueFromObj = getValue(obj || {}, key);

    // try to convert strings to numbers but fail silently
    if (
      valueFromObj &&
      typeof valueFromObj !== 'number' &&
      typeof valueFromObj === 'string'
    ) {
      try {
        valueFromObj = Number.parseFloat(valueFromObj);
      } catch (err) {
        //
      }
    }

    return valueFromObj && typeof valueFromObj === 'number' ? valueFromObj : 0;
  }
}
