import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { FormControlService } from '../../../../notes/form-control/form-control.service';
import { CountryCode } from '../../../../shared/constants/addresses.constant';
import { getValue } from '../../../../shared/utils/get-value';
import { cloneObject } from '../../../../shared/utils/object';
import { BaseCVAComponent } from '../base-cva.component';
import {
  ABBREVIATED_STATE_OPTIONS,
  address1Config,
  address2Config,
  CANADIAN_STATE_OPTIONS,
  cityConfig,
  cityDropdownConfig,
  CompanyAddressConfig,
  countryConfig,
  countyCodeConfig,
  countyConfig,
  STATE_OPTIONS,
  stateConfig,
  stateTextboxConfig,
  zipCodeConfig,
} from './company-address.constants';

@Component({
  selector: 'company-address',
  templateUrl: './company-address.component.html',
  styleUrls: ['./company-address.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompanyAddressComponent),
      multi: true,
    },
  ],
})
export class CompanyAddressComponent
  extends BaseCVAComponent
  implements OnInit, OnDestroy
{
  @Input() config: {
    configs: { [controlName: string]: CompanyAddressConfig };
    grid: any;
    isDisabled: boolean;
    label: string;
    populateCity: boolean;
    prop: string;
    zipcodeCountyOnly: boolean;
  };
  @Input() direction = '';

  @Output() addressValidationChange = new EventEmitter();

  address1Config;
  address2Config;
  cityConfig;
  zipCodeConfig;
  stateConfig;
  countyConfig;
  countryConfig;

  cityDropdownConfig = cloneObject(cityDropdownConfig);
  countyCodeConfig = cloneObject(countyCodeConfig);
  stateTextboxConfig = cloneObject(stateTextboxConfig);

  isOtherCountrySelected: boolean;

  constructor(private formControlService: FormControlService) {
    super();
  }

  ngOnInit(): void {
    this.formGroup = new UntypedFormGroup({});

    if (this.config.configs) {
      this.zipCodeConfig = new CompanyAddressConfig(
        this.mergeConfig(zipCodeConfig.prop),
        'zipCodeConfig'
      );

      if (!this.config.zipcodeCountyOnly) {
        this.address1Config = new CompanyAddressConfig(
          this.mergeConfig(address1Config.prop),
          'address1Config'
        );
        this.address2Config = new CompanyAddressConfig(
          this.mergeConfig(address2Config.prop),
          'address2Config'
        );
      } else {
        this.zipCodeConfig.grid.col = '1 / 3';
      }

      if (this.config.populateCity) {
        this.cityDropdownConfig = new CompanyAddressConfig(
          this.mergeConfig(cityDropdownConfig.prop),
          'cityDropdownConfig'
        );
      } else {
        this.cityConfig = new CompanyAddressConfig(
          this.mergeConfig(cityConfig.prop),
          'cityConfig'
        );
      }

      this.countryConfig = new CompanyAddressConfig(
        this.mergeConfig(countryConfig.prop),
        'countryConfig'
      );
      this.countyConfig = new CompanyAddressConfig(
        this.mergeConfig(countyConfig.prop),
        'countyConfig'
      );
      this.stateTextboxConfig = new CompanyAddressConfig(
        this.mergeConfig(stateConfig.prop),
        'stateTextboxConfig'
      );
      this.stateConfig = new CompanyAddressConfig(
        this.mergeConfig(stateConfig.prop),
        'stateConfig'
      );
      this.stateConfig.options =
        this.config.configs[stateConfig.prop] &&
        this.config.configs[stateConfig.prop].abbreviated
          ? ABBREVIATED_STATE_OPTIONS
          : STATE_OPTIONS;
    }

    const zipStateCountyConfig = [
      this.zipCodeConfig,
      this.stateTextboxConfig,
      this.stateConfig,
      this.countyConfig,
      this.countryConfig,
    ];
    const addressCityConfig = [
      this.address1Config,
      this.address2Config,
      this.config.populateCity ? this.cityDropdownConfig : this.cityConfig,
    ];
    const allAddressConfig = [
      ...(!this.config.zipcodeCountyOnly ? addressCityConfig : []),
      ...zipStateCountyConfig,
    ];

    allAddressConfig.forEach((config) => {
      if (config && !config.hidden) {
        this.formControlService.addControlToFormGroup(this.formGroup, config);
      }
    });

    this.formGroup
      .get(this.countryConfig.prop)
      .valueChanges.pipe(takeUntil(this.destroy))
      .subscribe(() => {
        this.handleCountryChanged();
        this.addressValidationChange.emit(this.formGroup);
      });

    this.formGroup
      .get(zipCodeConfig.prop)
      .valueChanges.pipe(takeUntil(this.destroy))
      .subscribe((change) => {
        this.handleCountryChanged();
      });

    this.initCityDropdownOption();
    this.initCountyOption();
  }

  handleCountryChanged() {
    // reset these values
    [
      this.formGroup.controls[this.address1Config.prop],
      this.formGroup.controls[this.address2Config.prop],
      this.formGroup.controls[this.countyConfig.prop],
      this.formGroup.controls[this.cityConfig.prop],
      this.formGroup.controls[this.zipCodeConfig.prop],
    ].forEach((control) => {
      control.setValue(null, { onlySelf: true, emitEvent: false });
    });

    this.updateStateConfig();
    this.refreshStateControl();

    this.updateZipConfig();
    this.refreshZipControl();

    this.isOtherCountrySelected = this.selectedCountry === CountryCode.Other;
  }

  triggerValidation() {
    Object.keys(this.formGroup.controls).forEach((key) => {
      this.formGroup.controls[key].markAsTouched();
    });
  }

  get selectedCountry(): CountryCode {
    return this.formGroup.get(this.countryConfig.prop).value;
  }

  refreshStateControl() {
    const stateValue = this.formGroup.controls[this.stateConfig.prop].value;
    this.formGroup.removeControl(this.stateConfig.prop);
    this.formControlService.addControlToFormGroup(
      this.formGroup,
      this.stateConfig
    );

    if (this.stateConfig?.options.find((a) => a.value === stateValue)) {
      this.formGroup.controls[this.stateConfig.prop].setValue(stateValue);
    }
  }

  refreshZipControl() {
    const zipValue = this.formGroup.controls[this.zipCodeConfig.prop].value;
    this.formGroup.removeControl(this.zipCodeConfig.prop);
    this.formControlService.addControlToFormGroup(
      this.formGroup,
      this.zipCodeConfig
    );

    if (
      this.formGroup.controls[this.countryConfig.prop].value !==
      CountryCode.Other
    ) {
      this.formGroup.controls[this.zipCodeConfig.prop].setValue(zipValue);
    }
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
  }

  public writeValue(value: any) {
    if (value) {
      this.formGroup.patchValue(value, { emitEvent: true });
    }
  }

  mergeConfig(configSection: string): any {
    const id = `${this.config.prop}_${configSection}`;
    if (!this.config.configs[configSection]) {
      return { id };
    }

    return { ...this.config.configs[configSection], id };
  }

  /*
        If persisted value - create dropdown option for display
    */
  private initCityDropdownOption() {
    const value = getValue(this.formGroup.value, this.cityDropdownConfig.prop);
    if (value) {
      this.cityDropdownConfig.options = [
        {
          label: value,
          value,
        },
      ];
    }
  }

  /*
        If persisted value - create dropdown option for display
        This avoids lag when waiting for initial API response
        Set before form control && placeholder initialization to avoid lifecycle errors
    */
  private initCountyOption() {
    const value = getValue(this.formGroup.value, this.countyConfig.prop);
    if (value) {
      this.countyCodeConfig.options = [
        {
          label: value.county_name,
          value: value.county_code,
        },
      ];
    }
  }

  /**
   * Sets the dropdown options for the selected country
   */
  private updateStateConfig() {
    this.stateConfig = {
      ...this.stateConfig,
      options:
        this.selectedCountry === CountryCode.UnitedStates
          ? STATE_OPTIONS
          : CANADIAN_STATE_OPTIONS,
    };
  }

  /**
   * Sets the validation and editability of the zip code for the selected country
   */
  private updateZipConfig() {
    const validators = [
      'required',
      {
        name: 'forcedError',
        message: 'ZIP Code is invalid',
      },
    ];

    switch (this.selectedCountry) {
      case CountryCode.UnitedStates:
        this.zipCodeConfig.maxLength = 5;
        this.zipCodeConfig.default = '';
        this.zipCodeConfig.isDisabled = false;
        validators.push('zipcode');
        break;
      case CountryCode.Canada:
        this.zipCodeConfig.maxLength = 6;
        this.zipCodeConfig.default = '';
        this.zipCodeConfig.isDisabled = false;
        validators.push('zipcodeCanada');
        break;
      case CountryCode.Other:
        this.zipCodeConfig.maxLength = 5;
        this.zipCodeConfig.default = '00000';
        this.zipCodeConfig.isDisabled = true;
        validators.push('numeric');
        break;
      default:
        break;
    }

    this.zipCodeConfig.validators = validators;
  }
}
