<div *ngIf="config?.accordions; else loading">
  <div *ngFor="let accordion of config?.accordions">
    <accordion
      [header]="accordion.header"
      [card]="false"
      [content]="accordionsTemplate"
    ></accordion>

    <ng-template #accordionsTemplate>
      <div
        class="accordion-content"
        *ngFor="let field of accordion.accordionFields"
      >
        <div class="t-bold link-item">
          <div class="link-item__title" (click)="handleLinkClick(field)">
            <i
              *ngIf="field.icon"
              class="icon icon--medium link-item__icon"
              [ngStyle]=""
              [ngClass]=""
              >{{ field.icon }}
            </i>
            <p class="t-bold t-link">{{ field.title }}</p>
          </div>
          <div class="link-item__desc">
            <p [innerHTML]="field.description"></p>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #loading>
  <spinner size="medium" flexCenter="true"></spinner>
</ng-template>
