<modal
  *ngIf="showFlyout && modalConfig && modalConfig?.flyout; let flyoutConfig"
  [config]="modalConfig.flyout"
  [condition]="flyoutConfig"
  (cancel)="closeFlyoutModal()"
>
  <div body>
    <div class="Modal__SubHeader">
      <p [innerHTML]="modalConfig.flyout?.subheader"></p>
    </div>
    <zip-table
      *ngIf="tableConfig"
      (selectedRows)="getSelectedRows($event)"
      [options]="tableConfig"
      [preSelectedData]="_preSelectedRows"
    >
    </zip-table>
  </div>
  <div footer>
    <zip-button
      [disabled]="!isRowSelected"
      [config]="modalConfig?.submitButton"
      (zipButtonClicked)="addSelectedRows()"
    ></zip-button>
  </div>
</modal>
