import { Pipe, PipeTransform } from '@angular/core';
import { NavigationLink } from '@zipari/shared-ds-util-navigation';
import { getTopLevelRoute } from '../../../../shared/utils/getTopLevelRoute.util';

@Pipe({
  name: 'isLinkActive',
  pure: true,
})
export class IsLinkActivePipe implements PipeTransform {
  transform(link: NavigationLink, activeRoute: string): boolean {
    const linkIsExactMatch =
      activeRoute && getTopLevelRoute(link?.route) === activeRoute;
    const linkIsParentOfActive = link.items?.find(
      (linkItem) => linkItem.route === activeRoute
    );

    return linkIsExactMatch || !!linkIsParentOfActive;
  }
}
