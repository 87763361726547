import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { ModelAttributeConfig } from '@zipari/shared-ds-util-configuration';
import { ModalHeader } from '@zipari/shared-ds-util-modal';

export interface ToolbarDropdownOption {
  [displayProperty: string]: string;
}

export interface ToolbarConfiguration {
  options: ToolbarDropdownOption[];
  selectedOption: ToolbarDropdownOption;
  displayProperty: string;
  isDisabled?: boolean;
}

export enum NavigationLinkTargets {
  new_tab = 'tab',
  new_window = 'window',
}

export interface NavigationUnreadCount {
  endpoint: string | undefined;
  index: number;
}

export interface NavigationUnreadResponse {
  unread_count: number;
}

export class NavigationLink {
  label?: string;
  icon?: string;
  description?: string;
  route?: string;
  isExternalRoute?: boolean;
  hasSubItems?: boolean;
  target?: NavigationLinkTargets;
  items?: NavigationLink[];
  count_api?: string;
  count?: string; // string instead of number for leading zeroes
  key?: string;
  position?: string;
  useExternalSiteModal?: boolean;
  showIcon?: boolean;
  transaction_code?: string; // MTM event transaction code
  transaction_label?: string; // MTM event transaction description
  removeLinkStyles?: boolean;
}

export class NavigationToolbarDropdown {
  options!: object[];
  selectedOption!: object;
  displayProperty!: string;
  isOpen!: boolean;
  isDisabled?: boolean;
}

export interface NavModalType {
  icon: string;
  label: string;
  attributes: ModelAttributeConfig[];
}

export interface LanguageSwitcher {
  enabled: boolean;
  listOfLanguages: {
    languages: LanguageConfig[];
  };
  mobileOptions?: {
    title: string;
    icon: string;
    saveButton: ButtonConfig;
    cancelButton: ButtonConfig;
  };
}

export interface LanguageConfig {
  label: string;
  key: string;
  value: string;
}

export class NavigationConfiguration {
  buttons?: {
    auth?: {
      login: ButtonConfig;
      logout: ButtonConfig;
    };
    contactUs?: {
      buttonText: string;
      modal: {
        header: ModalHeader;
        title?: string;
        phone?: NavModalType;
        online?: NavModalType;
        mail?: NavModalType;
        email?: NavModalType;
      };
    };
  };
  enableProviderSearchForPolicy?: {
    active?: boolean;
    preactive?: boolean;
    terminated?: boolean;
    canceled?: boolean;
  };
  dropdown!: ToolbarConfiguration;
  hideToolbar?: boolean;
  icons!: NavigationLink[];
  links!: NavigationLink[];
  isPoliciesDropdownDisabled!: boolean;

  /**
   * URL to go to when the logo is hit.
   *
   * Can be set with `logged in` vs `not logged in` cohorts for different behaviors, such as default
   * to `/` when logged in, and to go to a different route before logging in, such as `/auth/login`
   * for example
   */
  logoUrl?: string;
  showCoBrandLogo?: boolean;
  languagesSwitcher?: LanguageSwitcher;
}

export class NavigationStateData {
  isUserLoggedIn!: boolean;
  isMobileMenuOpen!: boolean;
  openMenuItem!: NavigationLink;
  activeNavItem!: NavigationLink;
  iconData!: NavigationLink[];
}
