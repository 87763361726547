<div
  class="Badge"
  [ngClass]="{
    'Badge--small': badgeConfig?.size === badgeSizes.small,
    'Badge--large': badgeConfig?.size === badgeSizes.large,
    'Badge--success': badgeConfig?.status === statusType.SUCCESS,
    'Badge--pending': badgeConfig?.status === statusType.PENDING,
    'Badge--error': badgeConfig?.status === statusType.ERROR,
    'Badge--info': badgeConfig?.status === statusType.INFO,
    'Badge--expired': badgeConfig?.status === statusType.EXPIRED,
    'Badge--action': badgeConfig?.actions && !badgeConfig?.status,
    'Badge--flat': badgeConfig?.isFlat
  }"
>
  <div class="Badge__indicator"></div>

  <ng-container *ngIf="!badgeConfig.isCustomTemplate; else customTemplate">
    <div
      class="Badge__Content"
      [ngClass]="{
        'Badge--ContentCentered': badgeConfig.centerContent
      }"
    >
      <icon
        *ngIf="badgeConfig.icon && badgeConfig.iconName"
        class="Badge__Icon"
        [config]="badgeConfig.icon"
        [name]="badgeConfig.iconName"
      ></icon>

      <h4
        class="Badge__Value"
        [ngClass]="{
          '--long': badgeConfig?.value?.length > largeTruncateSize,
          '--extra-long':
            badgeConfig?.value?.length > extraLargeTruncateSize &&
            !badgeConfig.truncateLong
        }"
        isEllipsisActive
      >
        {{ badgeConfig?.value }}
      </h4>

      <p
        class="Badge__Label"
        [ngClass]="{
          'Badge__label--link': badgeConfig?.link
        }"
        (click)="badgeConfig?.link && linkClicked.emit($event)"
      >
        {{ badgeConfig?.label }}
      </p>
      <ng-content select="[extraContent]"></ng-content>
    </div>
    <status
      class="Badge__status"
      *ngIf="badgeConfig?.status && badgeConfig?.statusLabel"
      [config]="{ label: badgeConfig.statusLabel }"
      [status]="badgeConfig.status"
    >
    </status>

    <section class="Badge__actions" *ngIf="badgeConfig?.actions">
      <div class="Badge__action" *ngFor="let action of badgeConfig?.actions">
        <icon
          class="Badge__icon"
          [name]="action.icon"
          [config]="{
            type: 'pointer',
            fill: getIconFill()
          }"
        >
        </icon>

        <p>{{ action.label }}</p>
      </div>
    </section>
  </ng-container>

  <ng-template #customTemplate>
    <div class="u-maxXY">
      <ng-content></ng-content>
    </div>
  </ng-template>
</div>
