import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { stringBuilder } from '@zipari/web-utils';

import Claim from '../../../../shared/models/shared/Claim.model';
import ClaimLine from '../../../../shared/models/shared/ClaimLine.model';
import { ClaimDetailService } from '../claim-detail.service';
import { ClaimLineTabConfig } from '../ClaimDetailConfig.model';

@Component({
  selector: 'claim-lines',
  templateUrl: './claim-lines.component.html',
  styleUrls: ['./claim-lines.component.scss'],
})
export class ClaimLinesComponent implements OnInit {
  config: ClaimLineTabConfig;
  claim: Claim;
  claimLines!: ClaimLine[];

  constructor(
    private claimDetailService: ClaimDetailService,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.config = this.claimDetailService.tabsConfig;
    this.claim = this.claimDetailService.claim;
    if (this.config && this.config.table) {
      this.config.table.endpoint = stringBuilder(
        this.config.table.endpoint,
        this.claim
      );
    }
    if (this.claim) {
      this.claimLines = this.claim.claim_lines;
    }
  }

  navToClaimLine(ev): void {
    this.router.navigate([ev.context.row.line_number], {
      relativeTo: this.route,
    });
  }
}
