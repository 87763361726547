export const first_options: any[] = [
  {
    label: 'Week 1',
    value: 1,
  },
  {
    label: 'Week 2',
    value: 2,
  },
  {
    label: 'Week 3',
    value: 3,
  },
  {
    label: 'Week 4',
    value: 4,
  },
  {
    label: 'Week 5',
    value: 5,
  },
  {
    label: 'Week 6',
    value: 6,
  },
  {
    label: 'Week 7',
    value: 7,
  },
  {
    label: 'Week 8',
    value: 8,
  },
  {
    label: 'Week 9',
    value: 9,
  },
  {
    label: 'Week 10',
    value: 10,
  },
  {
    label: 'Week 11',
    value: 11,
  },
  {
    label: 'Week 12',
    value: 12,
  },
  {
    label: 'Week 13',
    value: 13,
  },
];

export const second_options: any[] = [
  {
    label: 'Week 14',
    value: 14,
  },
  {
    label: 'Week 15',
    value: 15,
  },
  {
    label: 'Week 16',
    value: 16,
  },
  {
    label: 'Week 17',
    value: 17,
  },
  {
    label: 'Week 18',
    value: 18,
  },
  {
    label: 'Week 19',
    value: 19,
  },
  {
    label: 'Week 20',
    value: 20,
  },
  {
    label: 'Week 21',
    value: 21,
  },
  {
    label: 'Week 22',
    value: 22,
  },
  {
    label: 'Week 23',
    value: 23,
  },
  {
    label: 'Week 24',
    value: 24,
  },
  {
    label: 'Week 25',
    value: 25,
  },
  {
    label: 'Week 26',
    value: 26,
  },
];

export const third_options: any[] = [
  {
    label: 'Week 27',
    value: 27,
  },
  {
    label: 'Week 28',
    value: 28,
  },
  {
    label: 'Week 29',
    value: 29,
  },
  {
    label: 'Week 30',
    value: 30,
  },
  {
    label: 'Week 31',
    value: 31,
  },
  {
    label: 'Week 32',
    value: 32,
  },
  {
    label: 'Week 33',
    value: 33,
  },
  {
    label: 'Week 34',
    value: 34,
  },
  {
    label: 'Week 35',
    value: 35,
  },
  {
    label: 'Week 36',
    value: 36,
  },
  {
    label: 'Week 37',
    value: 37,
  },
  {
    label: 'Week 38',
    value: 38,
  },
  {
    label: 'Week 39',
    value: 39,
  },
  {
    label: 'Week 40',
    value: 40,
  },
];

export const pregnancyRiskAssesmentErrorMessage = 'This field is required';

export const tobaccoUse = {
  tobaccoUseProp: 'tobacco_options',
  formGroup: 'formGroup',
  tobaccoUseCardId: 'tobacco_use',
  tobaccoType: 'tobacco',
  controlType: 'checkbox',
};

export const alternateAddressPropNames = {
  alternateAddress: 'alternate_address',
  streetName1: 'street_name_1',
  streetName2: 'street_name_2',
  city: 'city_name',
  state: 'state',
  zipCode: 'zip_code',
};
