import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// Zip-UI
import { ButtonModule } from '@zipari/design-system';
import { ZipBusyModule } from '../zip-busy';
// Components
import { GenericCellComponent } from './components/cells/generic/generic-cell.component';
import { HeaderCellComponent } from './components/cells/header/header-cell.component';
import { TableFilterModule } from './components/filters/table-filter.module';
import { FooterComponent } from './components/footer/footer.component';

import { GenericRowComponent } from './components/rows/generic-row/generic-row.component';
import { GroupHeaderRowComponent } from './components/rows/group-header-row/group-header-row.component';
import { HeaderRowComponent } from './components/rows/header-row/header-row.component';

import { ZipTable2Component } from './components/zip-table2.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ButtonModule,
    TableFilterModule,
    ZipBusyModule,
  ],
  exports: [ZipTable2Component],
  declarations: [
    ZipTable2Component,
    FooterComponent,
    GenericRowComponent,
    HeaderRowComponent,
    GenericCellComponent,
    HeaderCellComponent,
    GroupHeaderRowComponent,
  ],
})
export class TableModule {}
