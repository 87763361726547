import { UntypedFormGroup } from '@angular/forms';
import { getValue } from '@zipari/web-utils';
import { camelCase } from '@zipari/shared-util-common';
import { getObjectProperty } from '../../../shared/utilities/get-object-property.utils';
import { getCurrentDate } from '../../../shared/utilities/dates';
import {
  DefaultMemberNetwork,
  AppealConstant,
  ClaimAppealProps,
  DefaultProviderLocation,
  ReadonlyFormControls,
} from './claim-appeal.constants';
import {
  ClaimAppealSubmissionAPIResponse,
  ControlConfiguration,
  DropdownOptions,
  EntryCard,
  claimCardConfig,
} from './claim-redetermination/claim-redetermination.model';

export const setContactInformation = (
  ClaimAppealForm: UntypedFormGroup
): void => {
  const controlValue = ClaimAppealForm?.get(
    ClaimAppealProps.sameAsRequestor
  )?.value;
  const { sameAsRequestor, contactName, contactPhoneNumber, contactEmail } =
    ClaimAppealProps;

  const useNull = !controlValue?.includes(sameAsRequestor);

  ClaimAppealForm.get(contactName)?.setValue(
    useNull ? null : ClaimAppealForm.value.requestorName
  );
  ClaimAppealForm.get(contactPhoneNumber)?.setValue(
    useNull ? null : ClaimAppealForm.value.requestorPhoneNumber
  );
  ClaimAppealForm.get(contactEmail)?.setValue(
    useNull ? null : ClaimAppealForm.value.requestorEmail
  );
};

export const enableDisableControls = (
  ClaimAppealForm: UntypedFormGroup,
  showMinDaysAllowedErrorMsg: boolean
): void => {
  Object.keys(ClaimAppealForm.controls)
    ?.filter((controlKey: string) => !ReadonlyFormControls.includes(controlKey))
    .forEach((key: string) => {
      showMinDaysAllowedErrorMsg
        ? ClaimAppealForm?.get(key)?.disable()
        : ClaimAppealForm?.get(key)?.enable();
    });
};

export const populateDropdown = (
  results: object[],
  prop: string
): DropdownOptions[] =>
  results.map((item) => ({ label: getValue(item, prop), value: item }));

export const getControlByProp = (
  card: claimCardConfig,
  prop: string
): ControlConfiguration | undefined =>
  card['form']?.['controls'].find(
    (formControl: ControlConfiguration) => formControl.prop === prop
  );

export const daysGreaterThanMaxDays = (
  date: Date,
  maxDaysAllowed: number
): boolean =>
  new Date(date).getTime() <
  new Date().getTime() - maxDaysAllowed * 24 * 60 * 60 * 1000;

export const daysLessThanMinDays = (
  date: Date,
  minDaysRequired: number
): boolean => {
  const fromDate = new Date().getTime() - minDaysRequired * 24 * 60 * 60 * 1000;
  const toDate = new Date().getTime();
  const selectedDate = new Date(date).getTime();

  return selectedDate <= toDate && selectedDate >= fromDate;
};

export const getClaimsProviderInformation = (providerInfo) => {
  if (!providerInfo?.length) return;

  const [providerLocation] = providerInfo;
  const {
    name: { firstName: providerFirstName, lastName: providerLastName },
    npi: providerNpi,
    providerId: providerId,
    id: id,
    isInNetwork: isNetworkProvider,
  } = providerLocation;

  const {
    streetName1: providerStreetName1,
    streetName2: providerStreetName2,
    cityName: providerCity,
    state: providerState,
    zipCode: providerZipCode,
  } = providerLocation?.address || DefaultProviderLocation.address;

  const providerInformation = {
    providerFirstName,
    providerLastName,
    providerStreetName1,
    providerStreetName2,
    providerCity,
    providerState,
    providerZipCode,
    providerNpi,
    isNetworkProvider,
    id,
    providerId,
  };

  return providerInformation;
};

export const getProviderLocationInformation = (providerInfo) => {
  const {
    name: { firstName: providerFirstName, lastName: providerLastName },
    npi: providerNpi,
    providerId: providerId,
    id: id,
  } = providerInfo;
  const [providerLocation] = providerInfo?.providerLocations;
  const {
    streetName1: providerStreetName1,
    streetName2: providerStreetName2,
    cityName: providerCity,
    state: providerState,
    zipCode: providerZipCode,
  } = providerLocation?.address || DefaultProviderLocation.address;

  const { isInNetwork: isNetworkProvider } =
    providerInfo?.memberNetwork || DefaultMemberNetwork;

  const providerInformation = {
    providerFirstName,
    providerLastName,
    providerStreetName1,
    providerStreetName2,
    providerCity,
    providerState,
    providerZipCode,
    providerNpi,
    isNetworkProvider,
    providerId,
    id,
  };

  return providerInformation;
};

export const getFacilityProviderInformation = (providerInfo) => {
  if (!providerInfo) return;

  const {
    name: facilityName,
    npi: providerNpi,
    facilityId: facilityId,
    id: id,
  } = providerInfo;
  const {
    streetName1: providerStreetName1,
    streetName2: providerStreetName2,
    cityName: providerCity,
    state: providerState,
    zipCode: providerZipCode,
  } = providerInfo.address || DefaultProviderLocation.address;

  const { isInNetwork: isNetworkProvider } =
    providerInfo?.memberNetwork || DefaultMemberNetwork;

  const providerInformation = {
    facilityName,
    providerStreetName1,
    providerStreetName2,
    providerCity,
    providerState,
    providerZipCode,
    providerNpi,
    id,
    facilityId,
    isNetworkProvider,
  };

  return providerInformation;
};

export const updateProviderInformation = (providerInfo) => {
  if (providerInfo?.providerLocations) {
    return getProviderLocationInformation(providerInfo);
  } else if (providerInfo?.providers) {
    return getClaimsProviderInformation(providerInfo.providers);
  } else {
    return getFacilityProviderInformation(providerInfo);
  }
};

export const prePopulateBillingProviderFormValue = (
  config,
  reconsiderationForm,
  formValue: object
) => {
  const billingProviderForm = config.cards?.billingProviderInformation?.form;

  if (billingProviderForm) {
    Object.values(billingProviderForm?.controls).forEach((formControl: any) => {
      if (formControl?.prop) {
        const providerInfo = updateProviderInformation(camelCase(formValue));
        const getKeyValue = getObjectProperty(providerInfo, formControl?.prop);
        const formProperty = reconsiderationForm?.get(formControl?.prop);

        formProperty?.patchValue(
          getKeyValue || (getKeyValue === false ? false : null)
        );
      }
    });
  }
};

export const updateBillingProviderContext = (appealForm) => {
  const billingProviderInformation = appealForm[AppealConstant.billingProvider];

  const {
    first_name: providerFirstName,
    last_name: providerLastName,
    npi: providerNpi,
    is_in_network: isNetworkProvider,
  } = billingProviderInformation || { first_name: '', last_name: '' };

  const {
    street_name_1: providerStreetName1,
    street_name_2: providerStreetName2,
    city_name: providerCity,
    state: providerState,
    zip_code: providerZipCode,
  } = billingProviderInformation?.address || DefaultProviderLocation.address;

  return {
    providerFirstName,
    providerLastName,
    providerStreetName1,
    providerStreetName2,
    providerCity,
    providerState,
    providerZipCode,
    providerNpi,
    isNetworkProvider,
  };
};

export const getOptionLabel = (
  disputeTypeValue: string,
  prop: string,
  controls: ControlConfiguration[]
): string => {
  const disputeType = controls?.find((control) => control.prop == prop);

  return (
    disputeType?.options?.find(
      (option) => option.value[AppealConstant.valueProp] === disputeTypeValue
    )?.label || '--'
  );
};

export const updateClaimAppealResponse = (
  claimAppealDetail: ClaimAppealSubmissionAPIResponse,
  cardsConfig: EntryCard
) => {
  claimAppealDetail.questions_responses?.forEach((question) => {
    claimAppealDetail[question.question_id] = question.response;
  });

  if (cardsConfig.requestInformation?.form?.controls) {
    claimAppealDetail.dispute_type = getOptionLabel(
      claimAppealDetail.dispute_type,
      ClaimAppealProps.disputeTypeProp,
      cardsConfig.requestInformation.form.controls
    );
  }
  if (cardsConfig.serviceInformation?.form?.controls) {
    claimAppealDetail.dispute_priority = getOptionLabel(
      claimAppealDetail.dispute_priority,
      ClaimAppealProps.priority,
      cardsConfig.serviceInformation.form.controls
    );
  }
};

export const prePopulateFormFields = (userData, appealForm) => {
  const requestorName = `${userData.first_name} ${userData.last_name}`;

  if (requestorName) {
    appealForm?.get(ClaimAppealProps.requestorName)?.setValue(requestorName);
  }
  appealForm?.get(ClaimAppealProps.dateOfAppeal)?.setValue(getCurrentDate());
};

export const getAppealType = (pageName: string) => {
  const { claimRedetermination, redetermination, reconsideration } =
    AppealConstant;

  return pageName === claimRedetermination ? redetermination : reconsideration;
};
