<ng-container *ngIf="config?.type !== 'checkbox'">
  <model-attribute class="ReviewCards" [config]="config" [context]="context">
  </model-attribute>
</ng-container>

<div *ngIf="config?.type === 'checkbox'" class="ReviewCard__Checkbox">
  <p
    class="ReviewCards"
    *ngFor="let checkboxValue of checkboxValues"
    [innerHTML]="checkboxValue"
  ></p>
</div>
