import { Component, OnInit } from '@angular/core';

import { SitemapUrl } from '../shared/models/shared/SitemapUrl';
import { ConfigService } from '../shared/services';
import { objectToArray } from '../shared/utilities/object';

@Component({
  selector: 'sitemap',
  template: `
    <ng-container *ngIf="urls && urls.length > 0">
      <h1 style="margin-bottom: 10px" role="heading">Sitemap</h1>
      <ng-container *ngFor="let url of urls">
        <p style="font-size: 24px">{{ url.label }}</p>
        <p *ngIf="url.path">
          <strong
            ><a class="link" style="font-size: 24px" [routerLink]="url.path">{{
              url.path
            }}</a></strong
          >
        </p>
        <p *ngIf="!url.path" style="color:tomato;">Route Pending...</p>
        <br />
      </ng-container>
    </ng-container>
  `,
})
export class SitemapComponent implements OnInit {
  urls: SitemapUrl[];
  config: any;

  constructor(private configService: ConfigService) {}

  /**
   * TODO: Create guard so this route is only accessible to certain Zipari Users in higher envs
   * Will add more functionality to this going forward but this is for initial score
   **/

  ngOnInit(): void {
    this.config = this.configService.getPageConfig('sitemap');
    this.formatUrls();
  }

  formatUrls(): void {
    this.urls = objectToArray(this.config?.urls);
  }
}
