import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { onResize } from '../../../../../shared/utils/mobile-view-responsive';
import { FormGroupArrayConfig } from '../../form-group-array.config';

@Component({
  selector: 'form-group-array-accordion-content',
  templateUrl: './form-group-array-accordion-content.component.html',
  styleUrls: ['./form-group-array-accordion-content.component.scss'],
})
export class FormGroupArrayAccordionContentComponent {
  @Input() formArray: UntypedFormArray;
  @Input() config: FormGroupArrayConfig;
  @Input() context: object[];
  @Output() addDuplicateClicked: EventEmitter<{
    values: unknown;
    indexval: number;
  }> = new EventEmitter();
  @Output() removeGroupClicked: EventEmitter<number> = new EventEmitter();
  @Input() openedAccordionIndex: number[];

  get isMobileView(): boolean {
    return onResize();
  }

  addDuplicateForm(data) {
    this.addDuplicateClicked.emit({
      values: data.values,
      indexval: data.indexval,
    });
  }

  removeGroup(index) {
    this.removeGroupClicked.emit(index);
  }
}
