import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { AuthService, ConfigService } from '../../../../shared/services';
import { PatientDetailService } from '../patient-detail.service';
import { BenefitsTabConfig } from '../patientDetailConfig.model';

@Component({
  selector: 'benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss'],
})
export class BenefitsComponent implements OnInit {
  config: BenefitsTabConfig;
  planBenefits: any[];

  constructor(
    private patientDetailService: PatientDetailService,
    public authService: AuthService,
    public configService: ConfigService,
    public analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.planBenefits = this.patientDetailService.context.planBenefits;
    this.config = this.patientDetailService.tabsConfig;
  }
}
