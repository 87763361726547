import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  AccordionModule,
  ButtonModule,
  CardModule,
  FormControlModule,
  FormGroupModule,
  ModalModule,
  ModelAttributeModule,
  TableModule,
  TabsModule,
} from '@zipari/design-system';
import { ZipBusyModule } from '../../../../shared/modules/zip-busy/zip-busy.module';
import { FlyoutDropdownModule } from '../flyout-dropdown/flyout-dropdown.module';
import { ServiceDetailsComponent } from './service-details.component';

@NgModule({
  declarations: [ServiceDetailsComponent],
  exports: [ServiceDetailsComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormControlModule,
    FormGroupModule,
    ButtonModule,
    TableModule,
    ZipBusyModule,
    ModalModule,
    ModelAttributeModule,
    ZipBusyModule,
    AccordionModule,
    ReactiveFormsModule,
    TabsModule,
    CardModule,
    FlyoutDropdownModule,
  ],
})
export class ServiceDetailsModule {}
