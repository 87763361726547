import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIList, PaginatorConfiguration } from '@zipari/design-system';
import {
  SquidexConfig,
  SquidexContentFlatResponse,
  SquidexContentFlatResponseItem,
} from '@zipari/shared-data-access-squidex';
import { map, Observable } from 'rxjs';
import {
  APIResponse,
  DocumentCenterTab,
  UserDetails,
} from '../util/document-center.model';
import { DocumentCenterSquidexDataService } from './document-center-squidex-data.service';
import { getDocumentsApiParams } from './document-center.helper';

@Injectable({
  providedIn: 'root',
})
export class DocumentCenterDataService {
  constructor(
    private http: HttpClient,
    private SquidexDataService: DocumentCenterSquidexDataService
  ) {}

  getDocumentsData(
    activeTab: DocumentCenterTab,
    squidexConfig: SquidexConfig,
    paginator: PaginatorConfiguration,
    filtersFormvalue: Record<string, unknown> | null = null,
    userDetails: UserDetails | null = null,
    sortKey: string | null = null
  ): Observable<APIResponse> {
    if (
      squidexConfig?.enabled &&
      squidexConfig?.enableForModule?.resourcesFormsDocuments &&
      activeTab.squidexDocumentsEndPoint
    ) {
      return this.SquidexDataService.getSquidexContent(
        activeTab,
        squidexConfig,
        paginator,
        filtersFormvalue,
        userDetails,
        sortKey
      ).pipe(
        map((response) => this.transformDocumentsResponseFormat(response))
      );
    } else {
      return this.getDocuments(
        activeTab.apiEndPoint ? activeTab.apiEndPoint : '',
        getDocumentsApiParams(activeTab, paginator, filtersFormvalue, sortKey)
      ).pipe(
        map((response) => this.transformDocumentsResponseFormat(response))
      );
    }
  }

  getDocuments(
    endPoint: string,
    params: HttpParams
  ): Observable<APIList<Document>> {
    return this.http.get<APIList<Document>>(endPoint, {
      params: params,
    });
  }

  transformDocumentsResponseFormat(
    response: SquidexContentFlatResponse | APIList<Document>
  ): APIResponse {
    return {
      count: 'total' in response ? response.total : response.count,
      results:
        'items' in response
          ? response.items.map((item: SquidexContentFlatResponseItem) => {
              return { ...item, ...item.data };
            })
          : response.results,
    };
  }
}
