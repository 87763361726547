import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  dropdownChange$ = new Subject();

  announceChange(event) {
    this.dropdownChange$.next(event);
  }
}
