<article class="main">
  <site-locations-alert></site-locations-alert>
  <section *ngIf="config?.notification" [zipBusy]="busy">
    <div *ngFor="let obj of response">
      <notification
        [config]="config"
        [data]="obj"
        (callNotification)="getNotification($event)"
      ></notification>
    </div>
  </section>
  <section *ngIf="config?.banner" class="banner-bg">
    <banner [config]="config.banner"></banner>
  </section>
  <section class="container">
    <div
      *ngIf="config['left-tables']"
      [ngClass]="
        _.size(config['left-tables']) > 1 ? 'left-column' : 'collapse-width'
      "
    >
      <div *ngFor="let key of _.keys(config['left-tables'])">
        <div
          *ngIf="config['left-tables'][key]['showThisTable']"
          class="card-space"
        >
          <custom-card
            [config]="config['left-tables'][key]['table']"
          ></custom-card>
        </div>
      </div>
    </div>
    <div
      *ngIf="config['right-tables'] || config['education_provider']"
      [ngClass]="
        _.size(config['left-tables']) > 1 ? 'right-column' : 'full-width'
      "
    >
      <div *ngFor="let key of _.keys(config['right-tables'])">
        <div
          *ngIf="config['right-tables'][key]['showThisTable']"
          class="card-space"
        >
          <data-grid [config]="config['right-tables'][key]"></data-grid>
        </div>
      </div>
      <div
        *ngIf="
          config.education_provider && config.education_provider.showEduProvider
        "
        class="card-space"
      >
        <div class="row header-row">
          <div class="row-item row-header">
            <p class="t-bold t-data row-header">
              {{ config?.education_provider?.title }}
            </p>
          </div>
        </div>
        <resource-grid [config]="config.education_provider"> </resource-grid>
      </div>
    </div>
  </section>
</article>
