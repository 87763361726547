import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup } from '@angular/forms';

interface SystemIcon {
  label: string;
  value: string;
}

enum IconSet {
  system = 'system',
  product = 'product',
}

@Component({
  selector: 'system-icon',
  templateUrl: './system-icon.component.html',
  styleUrls: [
    '../../../../notes/form-control/form-control.component.scss',
    './system-icon.component.scss',
  ],
})
export class SystemIconComponent
  implements ControlValueAccessor, AfterViewInit
{
  @Input() group: UntypedFormGroup;
  @Input() config;
  @Input() direction;
  @Input() set: IconSet;

  public value: string;
  public selectedIcon;
  public menuOpen: boolean;

  public icons: SystemIcon[];

  constructor(private ref: ChangeDetectorRef) {}

  propagateChange = (_: any) => {
    return;
  };

  onTouched = () => {
    return;
  };

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  ngAfterViewInit(): void {
    if (this.config) {
      const defs =
        document.getElementsByTagName('defs') &&
        document.getElementsByTagName('defs')[0]
          ? document.getElementsByTagName('defs')[0].children
          : null;

      if (defs) {
        this.icons = Array.from(defs)
          .filter((symbol) => this.set === symbol.getAttribute('data-set'))
          .map((symbol) => this.formatIcon(symbol.id))
          .sort((a, b) => (a.label < b.label ? -1 : 1));

        this.icons.forEach((icon) => {
          const initial =
            this.group.get(this.config.prop) &&
            this.group.get(this.config.prop).value
              ? this.group.get(this.config.prop).value
              : null;

          if (icon.value === initial) {
            // Delay is required to resolve Angular Change detection issue.
            setTimeout(() => {
              this.selectIcon(icon);
            });
          }
        });
      }
    } else {
      throw new Error('A configuration must be provided');
    }
  }

  formatIcon(id: string): SystemIcon {
    if (typeof id === 'string') {
      const value = id
        .replace(/([A-Z]+)/g, ' $1')
        .replace(/([A-Z][a-z])/g, ' $1')
        .replace(/icon-/g, '');

      const label = value
        ? value
            .replace(/_/g, ' ')
            .replace(/-/g, ' ')
            .replace(/\//, '')
            .replace(/\//g, ' - ')
            .replace(
              /\w\S*/g,
              (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            )
            .replace(/Csv/g, 'CSV')
            .replace(/Csr/g, 'CSR')
            .replace(/Id/g, 'ID')
            .replace(/Active/g, '- Active')
        : null;

      return {
        label,
        value,
      };
    }
  }

  selectIcon(icon): void {
    this.selectedIcon = icon;
    this.group.patchValue({
      [this.config.prop]: icon ? icon.value : null,
    });
    this.group.markAsDirty();
    this.menuOpen = false;
    this.ref.markForCheck();
  }
}
