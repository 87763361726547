import {
  Component,
  DoCheck,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { FormControlService } from '../../../../notes/form-control/form-control.service';

import { BaseCVAComponent } from './../base-cva.component';

/*
    Composite control to capture following behavior:
        - User can either enter a value in the configured input
            OR
        - User can check the "N/A" checkbox (hence the name of this component)
*/
@Component({
  selector: 'input-na',
  templateUrl: './input-na.component.html',
  styleUrls: ['./input-na.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNaComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputNaComponent),
      multi: true,
    },
  ],
})
export class InputNaComponent
  extends BaseCVAComponent
  implements Validator, OnInit, OnDestroy, DoCheck
{
  @Input() config: {
    inputValidators: any[];
    naLabel: string;
    prop: string;
    isInputDisabledOnly?: boolean;
    [key: string]: any;
  };
  @Input() direction;
  @Input() control;

  inputConfig;
  checkboxConfig;
  naValue = '';
  naProp = 'not_applicable';

  constructor(private formControlService: FormControlService) {
    super();
  }

  public get inputControl() {
    return this.formGroup.get(this.config.prop);
  }

  ngOnInit() {
    this.checkboxConfig = {
      isDisabled: this.config.isDisabled ? true : false,
      prop: this.naProp,
      type: 'singleCheckbox',
      checkboxLabel: this.config.naLabel,
    };

    this.config.isDisabled = this.config.isDisabled
      ? true
      : this.config.isInputDisabledOnly
      ? true
      : false;

    this.inputConfig = {
      ...this.config,
      validators: this.config.inputValidators,
      type: this.config.inputType || 'text',
    };

    this.formGroup = new UntypedFormGroup({});
    this.formControlService.addControlToFormGroup(
      this.formGroup,
      this.inputConfig
    );
    this.formControlService.addControlToFormGroup(
      this.formGroup,
      this.checkboxConfig
    );
  }

  ngDoCheck() {
    if (this.control && !this.control.valid) {
      const errors = this.inputControl.errors;
      const otherControlErrors = this.control.errors;

      this.inputControl.setErrors(
        Object.assign(otherControlErrors || {}, errors || {})
      );
    }
  }

  public writeValue(value: any) {
    if (value || value === this.naValue) {
      if (value === this.naValue) {
        this.inputControl.disable({ emitEvent: false });
        this.formGroup.patchValue({
          [this.config.prop]: null,
          [this.naProp]: true,
        });
      } else {
        this.formGroup.patchValue({
          [this.config.prop]: value,
          [this.naProp]: false,
        });
      }
    }
  }

  public registerOnChange(fn: any) {
    this.formGroup.valueChanges
      .pipe(
        takeUntil(this.destroy),
        map((changes) => {
          // Did user check N/A checkbox
          if (changes && changes[this.naProp]) {
            this.inputControl.setValue(null, { emitEvent: false });
            this.inputControl.reset(null, { emitEvent: false });
            this.inputControl.disable({ emitEvent: false });

            return this.naValue;
          }

          this.inputControl.enable({ emitEvent: false });
          this.inputControl.markAsTouched({ onlySelf: true });

          return this.inputControl.value;
        })
      )
      .subscribe(fn);
  }

  public validate(control: AbstractControl): ValidationErrors {
    return this.formGroup.valid ? null : this.inputControl.errors;
  }

  // Control is valid if input is valid or if the user checks the N/A checkbox
  public registerOnValidatorChange?(fn: () => void): void {
    this.formGroup.statusChanges
      .pipe(
        takeUntil(this.destroy),
        map(
          (status) =>
            (this.inputControl.valid && this.control.valid) ||
            this.formGroup.get(this.naProp).value
        )
      )
      .subscribe(fn);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
