import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RippleComponent } from './../ripple/ripple.component';

@NgModule({
  declarations: [RippleComponent],
  imports: [CommonModule],
  exports: [RippleComponent],
})
export class RippleModule {}
