<div class="ExpandingArticle" #articleElement>
  <p class="ExpandingArticle__Text" data-test-id="expandingArticleText">
    <span
      #articleText
      [id]="articleId"
      [innerHTML]="text"
      class="ui-description"
      [ngClass]="{ ExpandingArticle__Expanded: isExpanded }"
    >
    </span>
    <zip-button
      class="ExpandingArticle__Ellipsis"
      (click)="toggleExpand()"
      [config]="{
        content: isExpanded ? config.shrinkText : config.expandText,
        type: 'text'
      }"
      [attr.aria-controls]="articleId"
      [attr.aria-expanded]="isExpanded"
      data-test-id="expandingArticleExpander"
    >
    </zip-button>
  </p>
</div>
