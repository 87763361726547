<p *ngIf="data">
  <icon class="DataGrid__Icon" *ngIf="data.icon" [name]="data.icon"></icon>
  <span
    class="DataGrid__TextWithIcon"
    *ngIf="!data.icon"
    [ngStyle]="{ 'background-color': data.color }"
  ></span>
  <span class="DataGrid__Text" [ngClass]="{ 't-bold': isTextBold }">{{
    data.value || '—'
  }}</span>
</p>
