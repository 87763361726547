import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  BadgeModule,
  ButtonModule,
  CardModule,
  FormControlModule,
  FormGroupModule,
  ModalModule,
  ModelAttributeModule,
} from '@zipari/design-system';
import { ZipBusyModule } from '../../../shared/modules/zip-busy/zip-busy.module';
import { FlyoutDropdownModule } from './controls/flyout-dropdown/flyout-dropdown.module';
import { PhoneArrayModule } from './controls/phone-array/phone-array.module';
import { PregnancyRiskAssesmentComponent } from './pregnancy-risk-assesment.component';
import { PregnancyRiskAssesmentDirective } from './pregnancy-risk-assesment.directive';
import { PregnancyFormGroupComponent } from './controls/pregnancy-form-group/pregnancy-form-group.component';
import { ErrorMessageModule } from '../../shared/modules/error-message/error-message.module';

@NgModule({
  declarations: [
    PregnancyRiskAssesmentComponent,
    PregnancyRiskAssesmentDirective,
    PregnancyFormGroupComponent,
  ],
  exports: [PregnancyRiskAssesmentComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormGroupModule,
    ButtonModule,
    ZipBusyModule,
    ModelAttributeModule,
    CardModule,
    ModalModule,
    FormControlModule,
    PhoneArrayModule,
    FlyoutDropdownModule,
    BadgeModule,
    ErrorMessageModule,
  ],
})
export class PregnancyRiskAssesmentModule {}
