import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AccordionModule,
  ButtonModule,
  ModelAttributeModule,
  TableModule,
  FormGroupModule,
  MessageBannerModule,
} from '@zipari/design-system';
import { ZipBusyModule } from '../zip-busy/zip-busy.module';
import { SharedModule } from '../../shared.module';
import { AddOrEditModalComponent } from './add-or-edit-modal.component';

@NgModule({
  declarations: [AddOrEditModalComponent],
  imports: [
    CommonModule,
    ButtonModule,
    ZipBusyModule,
    FormGroupModule,
    SharedModule,
    ModelAttributeModule,
    AccordionModule,
    TableModule,
    MessageBannerModule,
  ],
  exports: [AddOrEditModalComponent],
})
export class AddNewModalModule {}
