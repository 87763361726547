import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ControlCheckboxModule } from '../form-control/checkbox/checkbox.module';
import { CheckboxListComponent } from './checkbox-list.component';

@NgModule({
  declarations: [CheckboxListComponent],
  imports: [
    CommonModule,
    MatInputModule,
    MatCheckboxModule,
    ControlCheckboxModule,
  ],
  exports: [CheckboxListComponent],
})
export class CheckboxListModule {}
