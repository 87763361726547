import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { auditTime, catchError, filter, tap } from 'rxjs/operators';
import { cloneObject } from '../../../shared/utils/object';

import {
  FormControlOptionConfiguration,
  TypeaheadConfiguration,
} from '@zipari/shared-ds-util-form';
import { FormControlService } from '../form-control.service';
import { ControlTextComponent } from '../text/text.component';
import { stringBuilder } from '../../../../lib/shared/utils/string-builder';
import { CustomEndpoint } from './control-typeahead.model';

// eslint-disable-next-line @typescript-eslint/naming-convention
const TYPEAHEAD_SEARCH_DEFAULT_DEBOUNCE_MS = 500;
@Component({
  selector: 'control-typeahead',
  styleUrls: ['./control-typeahead.component.scss'],
  templateUrl: './control-typeahead.component.html',
})
export class ControlTypeaheadComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Output() inputChanged = new EventEmitter<any>();
  @Output() inputCleared = new EventEmitter<null>();
  @Output() selected = new EventEmitter<any>();
  @Input() config: TypeaheadConfiguration;
  @Input() control: UntypedFormControl;
  @Input() group;
  @Input() customEndpoint: CustomEndpoint;

  @ViewChild(ControlTextComponent) input;

  public keyDown$: Observable<KeyboardEvent>;
  public showMenu = false;
  public filteredOptions = [];
  public noSearchResults = false;
  showAllOptions = false;
  allOptions: any;
  textConfig;
  requiredSelectValiditySub: Subscription;
  loading: boolean;
  showClose = false;
  private keySubscription;

  constructor(
    public formControlService: FormControlService,
    private focusMonitor: FocusMonitor,
    private elRef: ElementRef
  ) {}

  @HostListener('document:click')
  clickoutAndCloseTooltip() {
    if (this.showAllOptions && this.allOptions.length === 0)
      this.showAllOptions = false;
    this.noSearchResults = false;
  }

  ngOnInit() {
    this.showClose = !!this.control?.value?.length;
    this.textConfig = {
      type: 'text',
      prop: this.config.prop,
      placeholder: this.config.placeholder || null,
      icon: this.config.icon || null,
      ariaLabel: this.config.label || this.config.placeholder || null,
      isDisabled: this.config.isDisabled,
    };

    this.config.options = this.config.options ? this.config.options : [];
    this.setupFilteredOptions(this.config.options);

    const debounceTime = this.config.endpointDebounce
      ? this.config.endpointDebounce
      : TYPEAHEAD_SEARCH_DEFAULT_DEBOUNCE_MS;

    /**
     * subscribe to value changes for fetching dynamic typeahead options
     */
    this.control.valueChanges
      .pipe(
        tap((value) => (this.showClose = !!value?.length)),
        auditTime(debounceTime),
        filter((value) => value !== null)
      )
      .subscribe((value) => {
        const endPoint =
          this.config.endpoint || this.config.customGetAllEndpoint;
        const defaultMinCharToStartSearch = 3;
        const minNumberOfCharBeforeSearch = !this.config.minCharToStartSearch
          ? defaultMinCharToStartSearch
          : this.config.minCharToStartSearch;
        const zeroCharsForSearch = 0;
        if (value.length >= minNumberOfCharBeforeSearch) {
          endPoint
            ? this.getExternalOptions(endPoint)
            : this.setupFilteredOptions(this.config.options);
        }
        if (value.length === zeroCharsForSearch) {
          this.filteredOptions = [];
          this.noSearchResults = false;
        }
      });

    /**
     * listen for host blur
     */
    this.focusMonitor.monitor(this.elRef, true).subscribe((event) => {
      /**
       * falsy event means host element was blurred
       */
      const miliseconds = 10;
      setTimeout(() => {
        this.showMenu = !!event;
      }, miliseconds);
    });

    if (this.requireSelect) {
      this.requiredSelectValiditySub = this.control.valueChanges.subscribe(
        (val) => {
          if (!this.checkForValidSelection(val)) {
            this.control.setErrors({ customErrMessage: true });
          } else {
            this.control.setErrors(null);
          }
        }
      );
    }
  }

  clearInput() {
    this.control.setValue(null);
    this.showClose = false;
    this.filteredOptions = [];
    this.inputCleared.emit();
  }

  checkForValidSelection(val) {
    if (!val) {
      return true;
    }

    return (
      this.config &&
      this.config.options &&
      this.config.options.find((option) => option.value === val)
    );
  }

  ngAfterViewInit() {
    /**
     * observable of input keydown events
     */
    this.keyDown$ = fromEvent(this.input.input.nativeElement, 'keydown');

    /**
     * subscribe to input keydown stream
     */
    this.keySubscription = this.keyDown$.subscribe(() => {
      this.showMenu = true;
    });
  }

  ngOnChanges() {
    this.setupFilteredOptions(this.config.options);
  }

  ngOnDestroy() {
    /**
     * clean up subscription when destroyed
     */
    if (this.keySubscription) {
      this.keySubscription.unsubscribe();
    }

    if (this.requiredSelectValiditySub) {
      this.requiredSelectValiditySub.unsubscribe();
    }
  }

  getValueLabelPairs(options): { value: string; label: string }[] {
    const firstOption: any = options[0];
    let optionsWithValueLabelPairs: any[];
    if (typeof firstOption === 'string') {
      optionsWithValueLabelPairs = options.map((o) => ({ value: o, label: o }));
    } else if (firstOption?.label && firstOption?.value) {
      optionsWithValueLabelPairs = options;
    }

    return optionsWithValueLabelPairs;
  }

  getAllExternalOptions() {
    if (!this.showAllOptions) {
      this.loading = true;
      const endpoint = this.config?.customGetAllEndpoint;
      this.formControlService
        .getFormControlOptions(endpoint)
        .pipe(
          catchError((err: unknown) => {
            this.allOptions = [];

            return of(err);
          })
        )
        .subscribe((options) => {
          const optionsWithValueLabelPairs = this.getValueLabelPairs(options);
          if (options && options?.length > 0) {
            this.allOptions = optionsWithValueLabelPairs;
          } else {
            this.allOptions = [];
          }
          this.loading = false;
          this.showAllOptions = !this.showAllOptions;
        });
    } else {
      this.showAllOptions = false;
    }
  }

  formatOptionsWithLabelValue(
    options: any[]
  ): { label: string; value: string }[] {
    if (options.length === 0) return;
    const option1 = options[0];
    if (typeof option1 === 'string') {
      return options.map((option) => ({ label: option, value: option }));
    }

    return options;
  }

  /**
   * patch selected value, hide menu, clear errors
   * @param option option selected from typeahead dropdown
   */
  public select(option: any) {
    this.selected.emit(option);
    this.control.patchValue(option.value);
    this.showMenu = false;
    this.showAllOptions = false;
  }

  public onInputChange(value) {
    this.inputChanged.emit(value);
  }

  public getCustomEndpoint(endpoint: string) {
    return stringBuilder(endpoint, this.customEndpoint.values);
  }

  public get value() {
    if (typeof this.control.value === 'number') {
      return this.control.value.toString();
    }

    return this.control.value || '';
  }

  public get requireSelect() {
    return this.config.requireSelect;
  }

  /**
   * fetch external options if endpoint provided to config
   */
  private getExternalOptions(endPoint: string) {
    const externalEndPoint =
      endPoint && this.customEndpoint
        ? `${this.getCustomEndpoint(decodeURI(endPoint))}${this.value}`
        : `${endPoint}${this.value}`;
    this.formControlService
      .getFormControlOptions(externalEndPoint, this.config.endpointTemplate)
      .subscribe((formattedOptions) => {
        this.config.options = formattedOptions;

        this.setupFilteredOptions(formattedOptions);
      });
  }

  /** Filter out current options based on the typed value... and then highlight the relevant term searched on */
  private setupFilteredOptions(options = []) {
    if (!options.length) {
      this.noSearchResults = !this.filteredOptions.length && this.control.dirty;
      return;
    }
    const clonedOptions = this.formatOptionsWithLabelValue(
      cloneObject(options)
    );

    // eslint-disable-next-linebak @zipari/no-assigning-over-temp-variables-in-list-operators
    this.filteredOptions = clonedOptions
      .filter((option: FormControlOptionConfiguration) => {
        const optionTest =
          option.label && option.label.toLowerCase()
            ? option.label.toLowerCase()
            : '';
        const testAgainst =
          this.value && this.value.toLowerCase()
            ? this.value.toLowerCase()
            : '';

        return this.value && optionTest.includes(testAgainst);
      })
      .map((option) => {
        /**
         * expression matching current typed search term
         */

        const searchRegEx = new RegExp(`${this.value}(?!([^<])*?>)`, 'gi');
        option.label = option.label.replace(
          searchRegEx,
          (match) =>
            `<b class="form-control__menu__option__highlight">${match}</b>`
        );

        return option;
      });

    if (
      this.config.showOptionsOnOpen &&
      !this.value &&
      clonedOptions &&
      !this.filteredOptions.length
    ) {
      this.filteredOptions = clonedOptions;
    } else {
      this.noSearchResults = !this.filteredOptions.length && this.value.length;
    }
  }
}
