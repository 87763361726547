import { Directive, HostBinding, Input } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { BREAKPOINTS } from '../constants';
import { WindowService } from '../services/window.service';

@Directive({
  selector: '[zipGridCell]',
})
export class GridCellDirective {
  private _grid;
  @Input('zipGridCell')
  private set grid(val) {
    if (val && typeof val === 'object') {
      this._grid = val || { col: '', row: '', justify: '' };
    } else {
      this._grid = val || 'auto / auto / auto / auto';
    }
  }

  get isMobile(): boolean {
    return this.window.nativeWindow.innerWidth < BREAKPOINTS.SMALL;
  }

  constructor(private sanitizer: DomSanitizer, private window: WindowService) {}

  @HostBinding('style.grid-area')
  public get gridArea(): SafeStyle {
    if (typeof this._grid === 'string') {
      return !this.isMobile
        ? this.sanitizer.bypassSecurityTrustStyle(this._grid)
        : 'auto / 1 / auto / -1';
    }
  }

  @HostBinding('style.grid-row')
  public get gridRow(): SafeStyle {
    if (!this.isMobile && typeof this._grid === 'object') {
      return this.sanitizer.bypassSecurityTrustStyle(this._grid.row);
    }
  }

  @HostBinding('style.grid-column')
  public get gridColumn(): SafeStyle {
    if (!this.isMobile && typeof this._grid === 'object') {
      return this.sanitizer.bypassSecurityTrustStyle(this._grid.col);
    }
  }

  @HostBinding('style.justify-self')
  public get justifySelf(): SafeStyle {
    return !this.isMobile
      ? this.sanitizer.bypassSecurityTrustStyle(this._grid.justify)
      : null;
  }
}
