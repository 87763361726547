import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClaimAppealDetailComponent } from './claim-appeal-detail.component';
import { DisputeResponseComponent } from './dispute-response/dispute-response.component';
import { CLAIM_APPEAL_DETAIL_URLS } from './claim-appeal-detail.constants';
import { MemberInfoComponent } from './member-info/member-info.component';
import { RequestorInfoComponent } from './requestor-info/requestor-info.component';
import { ContactInfoComponent } from './contact-info/contact-info.component';
import { ServiceInfoComponent } from './service-info/service-info.component';

const routes: Routes = [
  {
    path: '',
    component: ClaimAppealDetailComponent,
    children: [
      {
        path: CLAIM_APPEAL_DETAIL_URLS.DISPUTE_RESPONSE,
        component: DisputeResponseComponent,
        data: {
          activeTab: 'dispute-response',
        },
      },
      {
        path: CLAIM_APPEAL_DETAIL_URLS.MEMBER_INFO,
        component: MemberInfoComponent,
        data: {
          activeTab: 'requestor-info',
        },
      },
      {
        path: CLAIM_APPEAL_DETAIL_URLS.SERVICE_INFO,
        component: ServiceInfoComponent,
        data: {
          activeTab: 'service-info',
        },
      },
      {
        path: CLAIM_APPEAL_DETAIL_URLS.REQUESTOR_INFO,
        component: RequestorInfoComponent,
        data: {
          activeTab: 'requestor-info',
        },
      },
      {
        path: CLAIM_APPEAL_DETAIL_URLS.CONTACT_INFO,
        component: ContactInfoComponent,
        data: {
          activeTab: 'contact-info',
        },
      },
      {
        path: '**',
        redirectTo: CLAIM_APPEAL_DETAIL_URLS.DISPUTE_RESPONSE,
        pathMatch: 'prefix',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClaimAppealDetailRoutingModule {}
