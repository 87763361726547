/* eslint-disable @typescript-eslint/naming-convention */
export enum DatagridFormat {
  ACTION_ICONS = 'ACTION_ICONS',
  ATTACHMENT = 'ATTACHMENT',
  CURRENCY = 'CURRENCY',
  DATE = 'DATE',
  DATE_RANGE = 'DATE_RANGE',
  DOWNLOAD = 'DOWNLOAD',
  HEADER = 'HEADER',
  ICON = 'ICON',
  ICON_ONLY = 'ICON_ONLY',
  LINK = 'LINK',
  SSO_LINK = 'SSO_LINK',
  LIST = 'LIST',
  STATUS = 'STATUS',
  TEXT = 'TEXT',
  BOOLEAN_ICON = 'BOOLEAN_ICON',
  BUTTON = 'BUTTON',
  TEXTWITHICON = 'TEXTWITHICON',
  TEXT_WITH_TOOLTIP = 'TEXT_WITH_TOOLTIP',
  EXPANDING_LIST = 'EXPANDING_LIST',
}

export enum DatagridSortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
  NOT_SORTED = 'no_sort',
}

export interface DatagridAction {
  row: any;
  action: string;
  targetElementId?: string;
}

export interface RowCondition {
  key: string;
  val: string;
}
