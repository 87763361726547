export default interface authorizationService {
  length_of_stay_admit_date: string;
  servicing_facility_location_id: string;
  quantity: number;
  approved_quantity: string;
  length_of_stay_requested_days: string;
  servicing_provider_name: {
    first_name: string;
    suffix: string;
    last_name: string;
    post_nominal_letters: string;
    middle_name: string;
    full_name: string;
    prefix: string;
  };
  unit_type: string;
  length_of_stay_notes: string;
  cost: number;
  effective_date: string;
  termination_date: string;
  servicing_provider_id: string;
  id: number;
  status_reason: string;
  is_primary: boolean;
  length_of_stay_discharge_date: string;
  status: string;
  notes: string;
  frequency: string;
  procedure_id: string;
  procedure: {
    termination_date: string;
    id: string;
    description: string;
    code: string;
    coding_system: string;
    effective_date: string;
  };
  servicing_facility_location: any;
  servicing_provider: any;
  frequency_option_label: string;
}

export interface ClinicalReveiwIndicationContext {
  bulletStatus?: string; // if BulletStatus is 1: List do not contain any sub-lists. // if BulletStatus is 2: List contain sub-lists.
  indicationId?: string;
  intro?: string;
  indication?: ClinicalReveiwIndicationContext[];
  content?: string;
  type?: string;
  logicType?: string;
  selected?: string; // Selected true/false indicates listItem is selected or not.
  guidelineTitle?: string;
}

export interface ClinicalReviewGuidelineContext {
  addDate: string;
  addDateStr: string;
  addUser: string;
  customDisclaimer: string;
  customDisclaimerNote: string;
  guidelineName: string;
  hSIM: string;
  insertDate: string;
  insertDateStr: string;
  isCustom: string;
  isMCR: string;
  los: string;
  mCRDisclaimer: string;
  nextReviewDateStr: string;
  orgOtg: string;
  outlines: ClinicalReviewOutlinesContext;
  pubCode: string;
  publication: string;
  version: string;
}

export interface ClinicalReviewOutlinesContext {
  outline: {
    hSIM: string;
    reviewerName: string;
    status: string;
    path: string;
    admissionsupportdefId: string;
    indications: {
      indication: ClinicalReveiwIndicationContext;
    };
    sectionTitle: string;
    updateDateStr: string;
    isPrimary: string;
    indicationMetStr: string;
    logicType: string;
    updateDate: string;
  };
}

export enum ClinicalReviewStatus {
  selected = 'true',
  unSelected = 'false',
}

export enum BulletStatus {
  one = '1',
  two = '2',
}

export const mcgClinicalReview = 'mcg_clinical_review';
