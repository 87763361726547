import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ModelAttributeConfig } from '@zipari/shared-ds-util-configuration';

import { ClaimAppealDetailDataService } from '../claim-appeal-detail.data.service';
import {
  ClaimAppealDetailConfig,
  ContactInformation,
} from '../../../../shared/models/shared/ClaimAppealDetail.model';
import { ConfigService } from '../../../../shared/services';

@Component({
  selector: 'contact-response',
  templateUrl: './contact-info.component.html',
})
export class ContactInfoComponent implements OnInit, OnDestroy {
  config: ClaimAppealDetailConfig;
  contactInformation$: Observable<ContactInformation>;
  attributes: ModelAttributeConfig[];
  claimAppealDetailsLoaded$: Subscription;

  constructor(
    private claimAppealDetailDataService: ClaimAppealDetailDataService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.config = this.configService.getPageConfig('claim-appeal-detail');
    this.attributes =
      this.config?.contactInformationTab.contactInformation.attributes;
    this.claimAppealDetailsLoaded$ =
      this.claimAppealDetailDataService.claimAppealDetailsLoaded$.subscribe(
        (loaded: boolean) => {
          if (loaded)
            this.contactInformation$ =
              this.claimAppealDetailDataService.getContact();
        }
      );
  }

  ngOnDestroy(): void {
    this.claimAppealDetailsLoaded$?.unsubscribe();
  }
}
