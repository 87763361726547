/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disablebak @zipari/no-assigning-over-temp-variables-in-list-operators */
import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  appDisplayNames,
  DEFAULT_UNMASKED_CHAR,
  defaultCurrencyOptions,
  defaultDateOptions,
  formatTypes,
} from '@zipari/shared-ds-util-format';
import { MemberTypes } from '../constants/format-member-types';
import {
  dateContainsTimezone,
  getDateWithoutTimezoneOffset,
  getFormattedDate,
  numDaysBetweenDates,
} from '../utils/dates';
import { getValue } from '../utils/get-value';
import { cloneObject, isObj } from '../utils/object';
import { stringBuilder } from '../utils/string-builder';

@Injectable({
  providedIn: 'root',
})
export class FormattingService {
  constructor(private currencyPipe: CurrencyPipe, private datePipe: DatePipe) {}

  restructureValueBasedOnFormat(initialValue, config, format?: string) {
    let value;

    format = format || config.format;

    // allows for cascading formats in an array, i.e. ['CURRENCY', 'LINK'] to produce a link formatted as currency
    if (Array.isArray(format)) {
      const originalFormat = [...config.format];
      value = initialValue;
      format.forEach((f) => {
        config.format = f;
        value = this.restructureValueBasedOnFormat(value, config, f);
      });
      config.format = originalFormat;

      return value;
    } else if (typeof initialValue === 'object' && initialValue?.value) {
      return initialValue;
    }

    switch (format) {
      case formatTypes.MASKED:
        if (!initialValue || typeof initialValue !== 'string') {
          return '';
        }

        let numberOfCharactersUnmasked = DEFAULT_UNMASKED_CHAR;

        // if a different mapping is provided then use that over the default
        if (config.formatOptions && config.formatOptions.unmaskedCharacters) {
          numberOfCharactersUnmasked = config.formatOptions.unmaskedCharacters;
        }

        let result = '';

        for (let i = 0; i < initialValue.length; i++) {
          if (i < initialValue.length - numberOfCharactersUnmasked) {
            result += 'X';
          } else {
            result += initialValue[i];
          }
        }

        value = result;
        break;
      case formatTypes.APP_DISPLAY_NAME:
        const displayNameMapping = cloneObject(appDisplayNames);
        if (!initialValue || typeof initialValue !== 'string') {
          return '';
        }

        // if a different mapping is provided then use that over the default display name mapping
        if (config.formatOptions) {
          Object.assign(displayNameMapping, config.formatOptions);
        }

        value = displayNameMapping[initialValue] || initialValue;
        break;
      case formatTypes.PERCENT:
        if (
          typeof initialValue === 'string' &&
          initialValue.indexOf('%') >= 0
        ) {
          value = initialValue;
          break;
        }

        if (!initialValue || typeof initialValue !== 'string') {
          return '';
        }

        value = `${initialValue}%`;
        break;
      case formatTypes.ID:
        if (
          typeof initialValue === 'string' &&
          initialValue.indexOf('#') >= 0
        ) {
          value = initialValue;
          break;
        }

        if (!initialValue || typeof initialValue !== 'string') {
          return '';
        }

        value = `#${initialValue}`;
        break;
      case formatTypes.SNAKE_CASE_TO_TITLE:
        if (!initialValue || typeof initialValue !== 'string') {
          return '';
        }

        value = initialValue
          .split('_')
          .map(function (item) {
            return item.charAt(0).toUpperCase() + item.substring(1);
          })
          .join(' ');
        break;
      case formatTypes.SNAKE_CASE_TO_TITLE_CAPITALIZATION:
        if (!initialValue || typeof initialValue !== 'string') {
          return '';
        }

        const str = initialValue.replace(/_/g, ' ');
        value = str.charAt(0).toUpperCase() + str.substring(1);

        break;
      case formatTypes.FULL_NAME:
        if (typeof initialValue !== 'object') {
          value = initialValue;
        } else {
          if (config.formatOptions && config.formatOptions.template) {
            value = stringBuilder(config.formatOptions.template, initialValue);
          } else {
            if (initialValue.full_name) {
              value = initialValue.full_name;
            } else {
              const firstName =
                initialValue.first_name ||
                initialValue.firstName ||
                initialValue.first ||
                '';
              let middle =
                initialValue.middle_initial ||
                initialValue.middleInitial ||
                initialValue.middle ||
                initialValue.middle_name ||
                '';
              let lastName =
                initialValue.last_name ||
                initialValue.lastName ||
                initialValue.last ||
                '';
              let suffix = initialValue.suffix;

              if (middle) {
                middle = ` ${middle}`;
              } else {
                middle = '';
              }

              if (lastName) {
                lastName = ` ${lastName}`;
              } else {
                lastName = '';
              }

              if (suffix) {
                suffix = ` ${suffix}`;
              } else {
                suffix = '';
              }

              value = `${firstName}${middle}${lastName}${suffix}`;
            }
          }

          value = value.trim();
        }
        break;
      case formatTypes.ADDRESSES:
        if (typeof initialValue !== 'object') {
          return '';
        }
        if (config.formatOptions && config.formatOptions.template) {
          value = initialValue.map((val) =>
            stringBuilder(config.formatOptions.template, val)
          );
        } else {
          value = initialValue.map((initial) =>
            this.formatAddress(config, initial, value, true)
          );
        }
        break;
      case formatTypes.ADDRESS:
        if (typeof initialValue !== 'object') {
          return '';
        }

        if (config.formatOptions && config.formatOptions.template) {
          value = stringBuilder(config.formatOptions.template, initialValue);
        } else {
          value = this.formatAddress(config, initialValue, value);
        }
        break;
      case formatTypes.CURRENCY:
        if (
          typeof initialValue === 'string' &&
          initialValue.indexOf('$') >= 0
        ) {
          value = initialValue;
        } else {
          if (typeof initialValue === 'string') {
            try {
              initialValue = Number.parseFloat(initialValue);
            } catch (err) {
              throw new Error('Currency must be a number');
            }
          }

          value = this.currencyPipe.transform(
            initialValue,
            config.formatOptions && config.formatOptions.currencyCode
              ? config.formatOptions.currencyCode
              : defaultCurrencyOptions.currencyCode,
            config.formatOptions && config.formatOptions.display
              ? config.formatOptions.display
              : defaultCurrencyOptions.display,
            config.formatOptions && config.formatOptions.digitsInfo
              ? config.formatOptions.digitsInfo
              : defaultCurrencyOptions.digitsInfo,
            config.formatOptions && config.formatOptions.locale
              ? config.formatOptions.locale
              : defaultCurrencyOptions.locale
          );
        }

        break;
      case formatTypes.DATE:
        value = this.formatAsDate(initialValue, config.formatOptions);
        break;
      case formatTypes.DATE_OF_BIRTH_AND_AGE:
        /*
                    sample config: {
                        "label" : "Date Of Birth (Age)"
                        "value": "${subscriber.birth_date} (${subscriber.age})",
                        "format": "DATE_OF_BIRTH_AND_AGE"
                    }
                    We wrap the age within parenthesis in the config, so the end result should be something like:
                    "01/01/1990 (31)"
                */
        value = '';
        if (initialValue) {
          const dateOfBirth: string = this.formatAsDate(
            initialValue,
            config.formatOptions
          );
          const regExp = new RegExp(/\(.*\)/g);
          const match: string[] = initialValue.match(regExp);
          const age = match && match.length ? match[0] : '';
          value = `${dateOfBirth} ${age}`;
        }
        break;
      case formatTypes.DATE_RANGE:
        if (!initialValue || !isObj(initialValue)) {
          value = initialValue;
        } else {
          if (
            !config.formatOptions ||
            !config.formatOptions.startProp ||
            !config.formatOptions.endProp
          ) {
            value = initialValue;
          } else {
            const dateConfig = {
              format: formatTypes.DATE,
              ...config.formatOptions,
            };

            const startDate = getValue(
              initialValue,
              config.formatOptions.startProp
            );
            const startDateFormatted = this.restructureValueBasedOnFormat(
              startDate,
              dateConfig
            );
            const endDate = getValue(
              initialValue,
              config.formatOptions.endProp
            );
            const endDateFormatted = this.restructureValueBasedOnFormat(
              endDate,
              dateConfig
            );

            value = `${startDateFormatted} - ${endDateFormatted}`;
          }
        }
        break;

      case formatTypes.INBOX_DATE:
        if (!initialValue || initialValue === 'null') {
          value = '';
        } else {
          const formatDate = new Date(initialValue).getTime();
          const oneDay = 1 * 24 * 60 * 60 * 1000;
          const today = new Date().getTime();

          if (today - formatDate >= oneDay) {
            // Your Date time is more than 1 days from now
            value = this.datePipe.transform(
              initialValue,
              config.formatOptions && config.formatOptions.format
                ? config.formatOptions.format
                : defaultDateOptions.format
            );
          } else {
            return new DatePipe('en-US').transform(initialValue, 'h:mm a');
          }
        }
        break;

      case formatTypes.LIST:
        const mapping = config?.formatOptions?.mapping;
        const mappingTemplate = config?.formatOptions?.mappingTemplate;
        const removeDupes = config?.formatOptions?.removeDupes;
        const keysToValues = config?.formatOptions?.keysToValues;
        const sortOrder = config?.formatOptions?.sortOrder;
        const keyValueList = config?.formatOptions?.keyValueList;
        const objectMapping = config?.formatOptions?.objectMapping;

        if (keyValueList && typeof initialValue === 'object') {
          const keyValueStringArr = Object.entries(initialValue).map(
            ([k, v]) => `${k}: ${v}`
          );
          initialValue = keyValueStringArr;
        }

        if (mapping && initialValue) {
          value = initialValue.map((item) => getValue(item, mapping));
        } else if (mappingTemplate && initialValue) {
          value = initialValue.map((item) =>
            stringBuilder(mappingTemplate, item)
          );
        } else {
          value = initialValue;
        }

        if (objectMapping && typeof initialValue === 'object') {
          value = Object.keys(initialValue).map((key: string) =>
            this.mapValuesByKey(initialValue[key], objectMapping)
          );
        }
        // Optional boolean. If `true` will return only the unique values of the array.
        // e.g.:
        // `value` { ['medicare_advantage', 'medicare_advantage', 'dental'] }
        // Config  { formatOptions: { removeDupes: true } }
        if (removeDupes && Array.isArray(value)) {
          value = value.filter((item, index) => value.indexOf(item) === index);
        }
        // Optional array of key/value pairs. If provided, it will convert the data
        // from its key to its value if a match is found.
        // e.g.:
        // `value` { ['medicare_advantage', 'd_snp'] }
        // Config  { formatOptions: { keysToValues: [ { key: 'medicare_advantage', value: 'Medicare Advantage'},
        //                                            { key: 'd_snp', value: 'D-SNP'}]}}
        if (keysToValues && Array.isArray(value)) {
          value = value.map((item) => {
            const match = keysToValues.find(({ key }) => key === item);

            return match ? match.value : item;
          });
        }
        // Optional array. If a sort order is passed then it will sort by these values.
        // e.g.:
        // `value` { ['Dental', 'Vision', 'Medicare Advantage', 'D-SNP'] }
        // Config  { formatOptions: { sortOrder: ['Medicare Advantage', 'D-SNP', 'Dental', 'Vision']}}
        if (sortOrder && Array.isArray(value)) {
          value.sort((a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b));
        }
        break;
      case formatTypes.YES_NO:
        if (typeof initialValue === 'boolean') {
          value = initialValue
            ? config?.formatOptions?.truthyLabel || 'Yes'
            : config?.formatOptions?.falsyLabel || 'No';
        } else if (
          typeof initialValue === 'string' &&
          (initialValue === 'true' || initialValue === 'false')
        ) {
          value =
            initialValue === 'true'
              ? config?.formatOptions?.truthyLabel || 'Yes'
              : config?.formatOptions?.falsyLabel || 'No';
        } else if (initialValue === '' && !config.noValueMessage) {
          value = 'No';
        } else {
          value = initialValue;
        }
        break;
      case formatTypes.YES_NO_NA:
        if (typeof initialValue === 'boolean') {
          value = initialValue ? 'Yes' : 'No';
        } else {
          const defaultNAValue = 'N/A';
          const naValue = config.formatOptions && config.formatOptions.naValue;
          value = naValue || defaultNAValue;
        }
        break;
      case formatTypes.BOOLEAN_ICON:
        if (typeof initialValue === 'boolean') {
          value = initialValue ? 'check' : 'close';
        } else {
          value = 'close';
        }
        break;
      case formatTypes.LICENSE_ACTIVE:
        value =
          initialValue === 'a' || initialValue === 'A' ? 'Active' : 'Inactive';
        break;
      case formatTypes.STATUS:
        const statusProp =
          config.formatOptions && config.formatOptions.statusProp
            ? config.formatOptions.statusProp
            : 'is_active';

        value = getValue(initialValue, statusProp) ? 'Active' : 'Inactive';
        break;
      case formatTypes.BANK_OR_CHECK:
        if (typeof initialValue === 'string') {
          value =
            initialValue === 'bank' || initialValue === 'Automatic Bank Draft'
              ? 'Automatic Bank Draft'
              : 'Check/Money Order';
        }
        break;
      case formatTypes.PAYMENT_FREQUENCY:
        if (typeof initialValue === 'string') {
          value =
            initialValue === 'one' || initialValue === 'One Time'
              ? 'One Time'
              : initialValue === 'recurring'
              ? 'Recurring'
              : initialValue;
        }
        break;
      case formatTypes.PHONE:
        value = this.formatPhoneNumber(config, initialValue);
        break;
      case formatTypes.PHONE_AND_TYPE:
        value = Array.isArray(initialValue)
          ? initialValue.map((phoneInfo) =>
              this.parsePhoneAndType(config, phoneInfo)
            )
          : [this.parsePhoneAndType(config, initialValue)];
        break;
      case formatTypes.TAXID:
        if (
          typeof initialValue === 'string' &&
          initialValue.indexOf('-') === -1
        ) {
          value = `${initialValue.substring(0, 2)}-${initialValue.substring(
            2,
            9
          )}`;
        } else {
          value = initialValue;
        }
        break;
      case formatTypes.CREDIT_CARD:
        if (typeof initialValue === 'number') {
          initialValue = initialValue.toString();
        }
        if (initialValue.indexOf('xxxx ') >= 0) {
          value = initialValue;
        } else {
          const displayedNum = initialValue.substr(-4);
          let hiddenNums = '';
          for (let i = 1; i < initialValue.length - 3; i++) {
            hiddenNums += 'x';
            if (i % 4 === 0 && i !== 0) {
              hiddenNums += ' ';
            }
          }

          value = `${hiddenNums} ${displayedNum}`;
        }

        break;
      case formatTypes.SSN:
        if (typeof initialValue === 'number') {
          initialValue = initialValue.toString();
        } else if (typeof initialValue !== 'string') {
          value = '';
          break;
        }

        if (initialValue.length >= 4) {
          value = `xxx-xx-${initialValue.substring(initialValue.length - 4)}`;
        } else {
          value = initialValue;
        }
        break;
      case formatTypes.VALUE_MAP:
        if (!config.formatOptions || !config.formatOptions.map) {
          value = initialValue;
          break;
        }
        value = config.formatOptions.map[initialValue] || initialValue;
        break;
      case formatTypes.MEMBER_TYPE:
        initialValue = initialValue.toLowerCase();
        const knownType = MemberTypes[initialValue];

        value = knownType || 'Other'; // default to 'Other' for all unknown types

        break;
      case formatTypes.NUM_DAYS_FROM_DATE:
        if (!initialValue) {
          return '';
        }
        let dateToCompare = initialValue;
        if (typeof dateToCompare === 'string') {
          const dateStr = getDateWithoutTimezoneOffset(dateToCompare);
          dateToCompare = getFormattedDate(dateStr);
        }

        const currDate = getFormattedDate(new Date());
        const numDaysFromDate = numDaysBetweenDates(currDate, dateToCompare);
        value = isNaN(numDaysFromDate) ? '' : numDaysFromDate.toString();
        break;
      case formatTypes.FROM_OBJECT_LIST:
        const { idPath, idValue, key } = config.formatOptions;
        if (Array.isArray(initialValue)) {
          const matchingObject = initialValue.find(
            (obj) => getValue(obj, idPath) === idValue
          );
          value = getValue(matchingObject, key);
        }
        break;
      case formatTypes.LINK:
      case formatTypes.ACTIONS:
      default: {
        value = initialValue || config.noValueMessage;
        break;
      }
    }

    if (
      value === null ||
      value === undefined ||
      value === 'undefined' ||
      value === 'null' ||
      value === null
    ) {
      value = '';
    }

    value = this.handleFallbackProps(config, value, (currentFallbackProp) => {
      const newConfig = {
        ...config,
        prop: currentFallbackProp,
        fallbackProps: null,
      };

      return this.restructureValueBasedOnFormat(initialValue, newConfig);
    });

    return value;
  }

  mapValuesByKey(
    mapSource: Record<string, unknown>[] | Record<string, unknown>,
    mappingKey: string
  ): unknown {
    if (Array.isArray(mapSource)) {
      return mapSource.map((value) => this.mapValuesByKey(value, mappingKey));
    } else {
      return getValue(mapSource, mappingKey);
    }
  }

  formatConfigValueAsTemplate(config: any, context: any) {
    const newConfig = cloneObject(config);
    // get value for field
    if (newConfig.value && typeof newConfig.value === 'string') {
      if (newConfig.value.indexOf('getValue:') >= 0) {
        const prop = newConfig.value.split('getValue:')[1];
        newConfig.value = getValue(context, prop);
      } else {
        newConfig.value = stringBuilder(newConfig.value, context);
      }
    }
    // get value of conditional flag (show/hide field based on dependency)
    if (newConfig.conditional && newConfig.conditional.value) {
      if (newConfig.conditional.value.indexOf('getValue:') >= 0) {
        const prop = newConfig.conditional.value.split('getValue:')[1];
        newConfig.conditional.value = getValue(context, prop);
      } else {
        newConfig.conditional.value = stringBuilder(
          newConfig.conditional.value,
          context
        );
      }
    }

    return newConfig;
  }

  formatPhoneNumber(phoneConfig: any, initialValue: any): any {
    let parsedPhoneNumber;

    if (typeof initialValue === 'string') {
      initialValue = initialValue.replace(/[^\d]/g, '');
      if (initialValue.length === 10) {
        const areaCodeSegment =
          phoneConfig.formatOptions && phoneConfig.formatOptions.nonAP
            ? `${initialValue.substring(0, 3)}-`
            : `(${initialValue.substring(0, 3)}) `;
        parsedPhoneNumber = `${areaCodeSegment}${initialValue.substring(
          3,
          6
        )}-${initialValue.substring(6, 10)}`;
      } else if (initialValue.length === 11) {
        const areaCodeSegment =
          phoneConfig.formatOptions && phoneConfig.formatOptions.nonAP
            ? `${initialValue.substring(1, 4)}-`
            : `(${initialValue.substring(1, 4)}) `;
        parsedPhoneNumber = `${areaCodeSegment}${initialValue.substring(
          4,
          7
        )}-${initialValue.substring(7, 11)}`;
      } else {
        parsedPhoneNumber = initialValue;
      }
    } else {
      parsedPhoneNumber = initialValue;
    }

    return parsedPhoneNumber;
  }

  handleFallbackProps(config, value, callback) {
    if (
      config.fallbackProps &&
      typeof value === 'string' &&
      value.trim() === ''
    ) {
      for (let i = 0; i < config.fallbackProps.length; i++) {
        const currentFallbackProp = config.fallbackProps[i];

        value = callback(currentFallbackProp);

        if (typeof value === 'string' && value.trim() !== '') {
          break;
        }
      }
    }

    return value;
  }

  parsePhoneAndType(config, phoneData: any): any {
    let parsedPhoneType = '';
    if (config.formatOptions?.map && phoneData) {
      parsedPhoneType = `(${
        config.formatOptions.map[phoneData.type] ||
        phoneData.type.charAt(0).toUpperCase() + phoneData.type.slice(1)
      })`;
    }

    const parsedPhoneNumber = this.formatPhoneNumber(
      config,
      phoneData.phone_number || ''
    );

    return `${parsedPhoneNumber} ${parsedPhoneType}`;
  }

  private formatAddress(config, initialValue, value, bulk?: boolean) {
    let newValue = {
      ...initialValue,
      label:
        config && !config.useConfigAddressLabel
          ? initialValue.address_type_display_name ||
            initialValue.address_type_label ||
            initialValue.address_type ||
            initialValue.type ||
            ''
          : null,
      street_name_1:
        initialValue.street_name_1 ||
        initialValue.address1 ||
        initialValue.address_1 ||
        '',
      street_name_2:
        initialValue.street_name_2 ||
        initialValue.address2 ||
        initialValue.address_2 ||
        '',
      city: initialValue.city || initialValue.city_name || '',
      county:
        initialValue.county_name ||
        initialValue.county_display ||
        initialValue.county ||
        '',
      state:
        initialValue.state ||
        initialValue.state_name ||
        initialValue.state_code ||
        '',
      zip_code:
        initialValue.zip_code || initialValue.zip || initialValue.zipcode || '',
      country_name:
        initialValue.country_name || initialValue.country_code || '',
    };
    // just in case county is an object make sure to try to grab the name
    if (typeof newValue.county === 'object') {
      newValue = {
        ...newValue,
        county:
          newValue.county.county ||
          newValue.county.county_name ||
          newValue.county.county_display ||
          '',
      };
    }
    if (config.formatOptions && config.formatOptions.multiline) {
      const thirdLine = [
        newValue.city ? `${newValue.city}, ` : null,
        newValue.state ? `${newValue.state} ` : null,
        newValue.zip_code ? `${newValue.zip_code}` : null,
      ]
        .filter((initialValueField) => Boolean(initialValueField))
        .join('');
      value = [
        newValue.street_name_1,
        newValue.street_name_2,
        thirdLine,
        newValue.county,
        newValue.country_name,
      ]
        .filter((addressField) => Boolean(addressField))
        .join('\n');
    } else {
      // added for extra formatting of address
      if (newValue.street_name_2) {
        newValue = {
          ...newValue,
          street_name_2: `${newValue.street_name_2}`,
        };
      }
      // Use null for value if missing key address information
      if (newValue.city && newValue.state) {
        value = bulk
          ? stringBuilder(
              // eslint-disable-next-line max-len
              '<p class="t-bold t-data t-capitalize width-max-content">${label}</p><p class="width-max-content">${street_name_1}</p><p class="width-max-content">${street_name_2}</p><p class="width-max-content">${city}, ${state} ${zip_code}</p>',
              newValue
            )
          : stringBuilder(
              '${street_name_1}${street_name_2} ${city}, ${state} ${zip_code}',
              newValue
            );
      } else {
        value = null;
      }
    }

    return value;
  }

  private formatAsDate(
    dateValue: string | number,
    options: { format?: string; originalTimezone?: string; timezone?: string }
  ): string {
    // Timezone of the original dateValue, used in timezone conversion when there is no timezone in the dateValue string itself.
    // Format is: 'Z' or '+hh:mm' or '-hh:mm' (time zone designator format from https://www.w3.org/TR/NOTE-datetime)
    const originalTimezone = options?.originalTimezone;

    // Timezone to format the date with
    const timezone = options?.timezone;

    if (!dateValue || dateValue === 'null') return '';

    if (originalTimezone && !dateContainsTimezone(`${dateValue}`))
      dateValue = `${dateValue}${originalTimezone}`;

    try {
      if (typeof dateValue !== 'string') dateValue = dateValue.toString();
      dateValue = dateValue.trim();
      if (dateValue.length > 10) dateValue = dateValue.split(' ')[0]; // correct date/time eg: 2020-01-01 05:38:00

      const formatOption =
        options && options.format ? options.format : defaultDateOptions.format;

      return this.datePipe.transform(dateValue, formatOption, timezone);
    } catch {
      return dateValue as string; // if transform not posisble, return orriginal value
    }
  }
}
