import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { camelCase } from '../../../../shared/utilities/case-conversion.utils';
import {
  ProviderMultiLocationModalConfig,
  ProviderSelectionConfig,
  ProviderSelectionModalConfig,
} from '../../../templates/authorization-submission/authorization-submission.model';
import {
  PractitionerDetails,
  SiteLocation,
} from '../../../templates/provider-update-tool/site-locations-attestation/site-locations-attestation.model';

@Component({
  selector: 'site-location-and-practitioner',
  templateUrl: './site-location-and-practitioner.component.html',
  styleUrls: ['./site-location-and-practitioner.component.scss'],
})
export class SiteLocationAndPractitionerComponent implements OnInit {
  @Input() config: ProviderSelectionConfig;
  @Input() group: UntypedFormGroup;
  @Input() providerData: SiteLocation | PractitionerDetails | null;
  @Input() providerSelectionModalConfig: ProviderSelectionModalConfig;
  @Input() providerMultiLocationModal: ProviderMultiLocationModalConfig;
  @Input() outOfNetwork: any;
  @Input() showOutOfNetworkStatus: boolean;

  ngOnInit(): void {
    this.config = camelCase(this.config);
  }
}
