import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ModelAttributeConfig } from '@zipari/shared-ds-util-configuration';
import { ClaimAppealDetailDataService } from '../claim-appeal-detail.data.service';
import { ConfigService } from '../../../../shared/services';
import {
  ClaimAppealDetailConfig,
  DisputeResponse,
} from '../../../../shared/models/shared/ClaimAppealDetail.model';
import { ClaimAppealType } from '../../claim-detail/claim-detail.constant';

@Component({
  selector: 'dispute-response',
  templateUrl: './dispute-response.component.html',
})
export class DisputeResponseComponent implements OnInit, OnDestroy {
  config: ClaimAppealDetailConfig;
  disputeInformation$: Observable<DisputeResponse>;
  claimAppealDetailsLoaded$: Subscription;

  constructor(
    private claimAppealDetailDataService: ClaimAppealDetailDataService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.config = this.configService.getPageConfig('claim-appeal-detail');
    this.claimAppealDetailsLoaded$ =
      this.claimAppealDetailDataService.claimAppealDetailsLoaded$.subscribe(
        (loaded: boolean) => {
          if (loaded) {
            this.disputeInformation$ =
              this.claimAppealDetailDataService.getDisputeResponse();
          }
        }
      );
  }

  getHeader(): string | undefined {
    return this.claimAppealDetailDataService.selectedClaimAppealType ===
      ClaimAppealType.reconsideration
      ? this.config?.reconsiderationDisputeResponseTab?.disputeInformation.label
      : this.config?.redeterminationDisputeResponseTab?.disputeInformation
          .label;
  }

  getConfig(): ModelAttributeConfig[] {
    return this.claimAppealDetailDataService.selectedClaimAppealType ===
      ClaimAppealType.reconsideration
      ? this.config?.reconsiderationDisputeResponseTab?.disputeInformation
          .attributes
      : this.config?.redeterminationDisputeResponseTab?.disputeInformation
          .attributes;
  }

  ngOnDestroy(): void {
    this.claimAppealDetailsLoaded$?.unsubscribe();
  }
}
