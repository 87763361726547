<article [zipBusy]="busy" class="centered-base_page">
  <div class="centered-base_page__content">
    <div class="column-container">
      <div class="column-header" role="heading" aria-level="1">
        {{ config?.header }}
      </div>
      <div *ngIf="config?.headerButton">
        <zip-button
          (click)="addLocation($event)"
          [config]="config?.headerButton"
        >
        </zip-button>
      </div>
    </div>
    <zip-table
      [options]="config?.table"
      (cellClicked)="navigateToDetail($event)"
      (deleteClicked)="deleteClicked($event)"
    >
    </zip-table>
  </div>
</article>

<modal
  *ngIf="showModal"
  [ngStyle]="{ 'z-index: ': 99999 }"
  [config]="config?.delete_modal"
  [condition]="showModal"
  (cancel)="handleModalCancel()"
>
  <div body>
    <div *ngIf="config?.delete_modal?.subheader" class="header-associate">
      <p>
        {{ config?.delete_modal?.subheader }}
      </p>
    </div>
    <header class="form-group__header">
      <accordion
        [icon]="config?.delete_modal.fieldsAccordianIcon"
        [isOpen]="true"
        [header]="config?.delete_modal.fieldsAccordianHeader"
        [content]="selectedList"
      ></accordion>
    </header>
    <ng-template #selectedList>
      <div *ngFor="let item of itemsToDelete; let index = index">
        <div class="row">
          <div class="column">
            <h6 class="t-bold">
              {{
                getObjectProperty(item.row, config?.delete_modal.fields.name)
              }}
            </h6>
            <p
              *ngIf="state == 'edit'"
              class="t-bold isCursor t-link"
              (click)="remove(index)"
            >
              Remove
            </p>
            <div *ngIf="state != 'edit' && deleteResponses?.length">
              <h6
                class="t-data t-bold"
                *ngIf="config?.delete_modal?.successHeader"
              >
                {{ config?.delete_modal?.successHeader }}
              </h6>
              <h6
                class="t-data"
                *ngIf="config?.delete_modal?.confirmationLocation"
              >
                {{
                  getObjectProperty(
                    deleteResponses[index],
                    config?.delete_modal?.confirmationLocation
                  )
                }}
              </h6>
            </div>
          </div>
          <div class="column">
            <div
              *ngFor="
                let _item of config?.delete_modal.fields.rightPanel;
                let index = index
              "
            >
              <model-attribute
                [context]="item.row"
                [config]="_item"
              ></model-attribute>
              <br />
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <header class="form-group__header">
      <accordion
        [icon]="config?.delete_modal.deleteFormAccordianIcon"
        [isOpen]="true"
        [header]="config?.delete_modal.deleteFormAccordianHeader"
        [content]="TerminationForm"
      ></accordion>
    </header>
    <ng-template #TerminationForm>
      <div *ngIf="state == 'edit'">
        <form-group
          [configs]="config?.delete_modal.controls"
          [form]="terminateFlyoutFields"
        ></form-group>
      </div>
      <div *ngIf="state != 'edit'">
        <div *ngFor="let row of config?.delete_modal?.controls">
          <p class="t-data t-bold" ng-reflect-ng-class="[object Object]">
            {{ row.label }}
          </p>
          <p class="t-data" ng-reflect-ng-class="[object Object]">
            {{
              genericServices.formatControl(
                terminateFlyoutFields.controls[row.prop],
                row
              )
            }}
          </p>
          <br />
        </div>
      </div>
    </ng-template>
  </div>
  <div footer>
    <zip-button
      [disabled]="isDisabled()"
      *ngIf="state == 'edit'"
      level="high"
      [content]="config?.delete_modal?.submitButton"
      (click)="submit()"
    ></zip-button>
    <zip-button
      *ngIf="state != 'edit'"
      level="high"
      [content]="config?.delete_modal?.backButton"
      (click)="handleModalCancel($event)"
    ></zip-button>
  </div>
</modal>

<modal
  *ngIf="error"
  [config]="config.errorModal"
  [condition]="error"
  (cancel)="toggleError()"
>
  <div body id="errorMessage">{{ config.errorModal.message }}</div>
</modal>
