/* eslint-disable */
export enum nonMarketSegmentConfigs {
  footer = 'footer',
  messages = 'messages',
  notifications = 'notifications',
  profile = 'profile',
  global = 'global',
  login = 'login',
  register = 'register',
  forgot_password = 'forgot-password',
  reset_password = 'reset-password',
  // dynamically added commented for the reference
  //'session-expired' = 'session-expired',
}

export enum validBrokerPortalMarketSegments {
  // dynamically added commented for the reference
  // 'large-group' = 'large-group',
  // 'small-group' = 'small-group',
  individual = 'individual',
  medicare = 'medicare',
}

export enum validShoppingMarketSegments {
  individual = 'individual',
  medicare = 'medicare',
  // dynamically added commented for the reference
  //'small-group' = 'small-group',
}

export class ConfigApiResponse {
  ANGULAR_PARAMS: any;
  APP: any;
  APP_NAME: string;
  CSRF_TOKEN: string;
  SESSION_COOKIE_AGE: number;
  TENANT_NAME: string;
}
