import { FormGroupModule } from '@zipari/design-system';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '@zipari/design-system';
import { FileUploaderComponent } from './file-uploader.component';
import { FileUploaderProgressComponent } from './file-uploader-progress/file-uploader-progress.component';
import { FileUploaderDropZoneComponent } from './file-uploader-dropzone/file-upload-dropdozone.component';
import { FileUploaderButtonComponent } from './file-uploader-button/file-uploader-button.component';

@NgModule({
  declarations: [
    FileUploaderComponent,
    FileUploaderButtonComponent,
    FileUploaderDropZoneComponent,
    FileUploaderProgressComponent,
  ],
  imports: [CommonModule, ButtonModule, FormGroupModule],
  exports: [
    FileUploaderComponent,
    FileUploaderButtonComponent,
    FileUploaderDropZoneComponent,
  ],
})
export class FileUploaderModule {}
