<article class="centered-base_page">
  <div class="gray">
    <header class="t-bold layout--detail__main__breadcrumb">
      <zip-button
        class="t-bold"
        [config]="config.backButton"
        (zipButtonClicked)="back()"
      ></zip-button>
    </header>
    <div class="header-row">
      <div>
        <section>
          <h3 class="t-bold" role="heading" aria-level="1">
            {{ context?.name?.first_name }} {{ context?.name?.last_name }}
          </h3>
        </section>
        <section *ngIf="config?.verbiage">
          <p>{{ config?.verbiage }}</p>
        </section>
        <section *ngIf="editResponse">
          <h6 class="t-data t-bold" *ngIf="config?.successHeader">
            {{ config?.successHeader }}
          </h6>
          <h6 class="t-data" *ngIf="config?.confirmationLocation">
            {{ getObjectProperty(editResponse, config?.confirmationLocation) }}
          </h6>
        </section>
      </div>
      <div *ngIf="config?.headerButton && currentTab == 1">
        <zip-button (click)="associateSite()" [config]="config?.headerButton">
        </zip-button>
      </div>
    </div>
  </div>
  <!-- <detail-tabs *ngIf="loaded" [tabConfig]="config.tabs" [tabData]="context" (save)="onSave($event)"></detail-tabs> -->
  <div class="tab-container">
    <tabs
      (tabSelected)="handleTabSelected($event)"
      [selectedIndex]="selectedTab"
      [tabLabels]="config.tabs.labels"
      [tabTemplates]="[profile, associated]"
    >
    </tabs>
  </div>
</article>

<ng-template #profile>
  <div *ngIf="loaded" class="tab-body">
    <div
      *ngFor="
        let section of (config?.tabs?.tabConfigs)[0]?.sections;
        let i = index
      "
    >
      <header
        *ngIf="section.header"
        class="tab-view__header"
        [ngClass]="setHeaderCSS(section.headerEditIcon)"
        (click)="openEditModalIfNeeded($event, section.headerEditIcon, i)"
      >
        <div *ngIf="section.headerIcon">
          <i
            class="header-icon-position"
            [ngStyle]="section.headerIcon.styles"
            [ngClass]="section.headerIcon.classString"
            >{{ section.headerIcon.name }}
          </i>
        </div>
        <h6 class="tab-view__header__text t-bold">
          {{ section.header }}
        </h6>
        <div *ngIf="section.headerEditIcon" (click)="openEditModal(section, i)">
          <i
            class="header-edit-icon-position icon icon--medium"
            [ngStyle]="section.headerEditIcon.styles"
            >edit
          </i>
        </div>
      </header>
      <div class="tab-view__three-column">
        <div class="three-column__attrs" *ngFor="let item of section.fields">
          <model-attribute
            [context]="context"
            [config]="item"
          ></model-attribute>
        </div>
      </div>
      <br />
      <br />
    </div>
  </div>
</ng-template>
<ng-template #associated>
  <div class="tab-body">
    <div class="top">
      <zip-table
        *ngIf="tableReady"
        (cellClicked)="navigateToDetail($event)"
        [options]="(config?.tabs?.tabConfigs)[1]?.table"
        (deleteClicked)="deleteClicked($event)"
      >
      </zip-table>
    </div>
  </div>
</ng-template>

<modal
  #terminateFlyout
  *ngIf="showModal"
  [ngStyle]="{ 'z-index: ': 99999 }"
  [config]="(config?.tabs?.tabConfigs)[1]?.delete_modal"
  [condition]="showModal"
  (cancel)="handleModalCancel()"
>
  <div body>
    <div
      *ngIf="(config?.tabs?.tabConfigs)[1]?.delete_modal?.subheader"
      class="header-associate"
    >
      <p>
        {{ (config?.tabs?.tabConfigs)[1]?.delete_modal?.subheader }}
      </p>
    </div>
    <div class="confirmation" *ngIf="deleteState != 'edit' && termResponse">
      <h6
        class="t-data t-bold"
        *ngIf="(config?.tabs?.tabConfigs)[1]?.delete_modal?.successHeader"
      >
        {{ (config?.tabs?.tabConfigs)[1]?.delete_modal?.successHeader }}
      </h6>
      <h6
        class="t-data"
        *ngIf="
          (config?.tabs?.tabConfigs)[1]?.delete_modal?.confirmationLocation
        "
      >
        {{
          getObjectProperty(
            termResponse,
            (config?.tabs?.tabConfigs)[1]?.delete_modal?.confirmationLocation
          )
        }}
      </h6>
    </div>
    <header class="form-group__header">
      <accordion
        [icon]="(config?.tabs?.tabConfigs)[1]?.delete_modal.fieldsAccordianIcon"
        [isOpen]="true"
        [header]="
          (config?.tabs?.tabConfigs)[1]?.delete_modal.fieldsAccordianHeader
        "
        [content]="selectedList"
      ></accordion>
    </header>
    <ng-template #selectedList>
      <div *ngFor="let item of itemsToDelete; let index = index">
        <div class="row">
          <div class="column">
            <h6 class="t-bold">
              {{
                getObjectProperty(
                  item.row,
                  (config?.tabs?.tabConfigs)[1]?.delete_modal.fields.name
                )
              }}
            </h6>
            <p
              *ngIf="deleteState == 'edit'"
              class="t-bold isCursor t-link"
              (click)="remove(index)"
            >
              Remove
            </p>
          </div>
          <div class="column">
            <div
              *ngFor="
                let _item of (config?.tabs?.tabConfigs)[1]?.delete_modal.fields
                  .rightPanel;
                let index = index
              "
            >
              <model-attribute
                [context]="item.row"
                [config]="_item"
              ></model-attribute>
              <br />
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <header class="form-group__header">
      <accordion
        [icon]="
          (config?.tabs?.tabConfigs)[1]?.delete_modal.deleteFormAccordianIcon
        "
        [isOpen]="true"
        [header]="
          (config?.tabs?.tabConfigs)[1]?.delete_modal.deleteFormAccordianHeader
        "
        [content]="TerminationForm"
      ></accordion>
    </header>
    <ng-template #TerminationForm>
      <div *ngIf="deleteState == 'edit'">
        <form-group
          [configs]="(config?.tabs?.tabConfigs)[1]?.delete_modal.controls"
          [form]="terminateFlyoutFields"
        ></form-group>
      </div>
      <div *ngIf="deleteState != 'edit'">
        <div
          *ngFor="
            let row of (config?.tabs?.tabConfigs)[1]?.delete_modal?.controls
          "
        >
          <p class="t-data t-bold">{{ row.label }}</p>
          <p class="t-data">
            {{
              genericServices.formatControl(
                terminateFlyoutFields.controls[row.prop],
                row
              )
            }}
          </p>
          <br />
        </div>
      </div>
    </ng-template>
  </div>
  <div footer>
    <zip-button
      [disabled]="
        !this.terminateFlyoutFields.valid || !this.itemsToDelete?.length
      "
      *ngIf="deleteState == 'edit'"
      level="high"
      [content]="(config?.tabs?.tabConfigs)[1]?.delete_modal?.submitButton"
      (click)="submitDelete()"
    ></zip-button>
    <zip-button
      *ngIf="deleteState != 'edit'"
      level="high"
      [content]="(config?.tabs?.tabConfigs)[1]?.delete_modal?.backButton"
      (click)="handleModalCancel($event)"
    ></zip-button>
  </div>
</modal>

<modal
  #errorFlyout
  *ngIf="error"
  [config]="config?.errorModal"
  [condition]="error"
  (cancel)="toggleError()"
>
  <div body id="errorMessage">{{ config?.errorModal.message }}</div>
</modal>

<modal
  #editFlyout
  *ngIf="editModal"
  [config]="(config?.tabs?.tabConfigs)[0]?.editFlyout"
  [condition]="editModal"
  (cancel)="toggleEdit()"
>
  <div body>
    <div
      *ngIf="(config?.tabs?.tabConfigs)[0]?.editFlyout?.subheader"
      class="header-associate"
    >
      <p>
        {{ (config?.tabs?.tabConfigs)[0]?.editFlyout?.subheader }}
      </p>
    </div>
    <div
      class="form-group"
      *ngFor="
        let group of (config?.tabs?.tabConfigs)[0]?.editFlyout.formFields;
        let idx = index
      "
    >
      <header class="form-group__header">
        <accordion
          [isOpen]="idx === openAccordionIdx || openAccordionIdx == -1"
          [header]="group.header"
          [content]="contentTemplate"
        ></accordion>
      </header>
      <ng-template #contentTemplate>
        <form-group [configs]="group.details.controls" [form]="editFields">
        </form-group>
        <div *ngFor="let control of group.additionalDetails.controls">
          <div [ngSwitch]="control.type">
            <input-array
              [key]="'editDetails'"
              [config]="control"
              *ngSwitchCase="'input'"
            ></input-array>
            <dropdown-array
              [key]="'editDetails'"
              [config]="control"
              *ngSwitchCase="'dropdown'"
            ></dropdown-array>
            <controls-group-array
              [key]="'editDetails'"
              [config]="control"
              *ngSwitchCase="'formGroupArray'"
              [stateDeaInfoModal]="group.stateDeaInfoModal"
            ></controls-group-array>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div footer>
    <zip-button
      [disabled]="isDisabled()"
      level="high"
      [content]="(config?.tabs?.tabConfigs)[0]?.editFlyout.submitButton"
      (click)="editSubmit(additionalDetailsModalConfig)"
    ></zip-button>
  </div>
</modal>

<modal
  #associateFlyout
  *ngIf="associateModal"
  [config]="config?.associateFlyout"
  [condition]="associateModal"
  (cancel)="toggleAssociate()"
>
  <div body>
    <div [ngSwitch]="addState">
      <div *ngIf="config?.associateFlyout?.subheader" class="header-associate">
        <p>
          {{ config?.associateFlyout?.subheader }}
        </p>
      </div>
      <div class="associate-table" *ngSwitchCase="'add'">
        <zip-table
          *ngIf="associateFlyoutReady"
          (selectedRows)="handleSelectedRows($event)"
          [options]="config?.associateFlyout.table"
        >
        </zip-table>
      </div>
      <div class="selected-row__review" *ngSwitchCase="'review'">
        <div *ngFor="let selection of selectedRows; let i = index">
          <!-- <h5 class="t-bold spaced-out">{{ getObjectProperty(selection, config?.associateFlyout?.displayField) }}
                        <span class="selected--row__header--required red">*</span>
                    </h5> -->
          <div class="row">
            <div class="column">
              <h6 class="t-bold">
                {{
                  getObjectProperty(
                    selection.row,
                    config?.associateFlyout?.fields.name
                  )
                }}
              </h6>
            </div>
            <div class="column">
              <div
                *ngFor="
                  let _item of config?.associateFlyout?.fields.rightPanel;
                  let index = index
                "
              >
                <model-attribute
                  [context]="selection.row"
                  [config]="_item"
                ></model-attribute>
              </div>
            </div>
          </div>
          <form-group
            [configs]="config?.associateFlyout?.controls"
            [form]="getAssociatedFields(i)"
          ></form-group>
        </div>
      </div>
      <div *ngSwitchCase="'confirm'">
        <div class="confirmation" *ngIf="addResponse">
          <h6
            class="t-data t-bold"
            *ngIf="config?.associateFlyout?.successHeader"
          >
            {{ config?.associateFlyout?.successHeader }}
          </h6>
          <h6
            class="t-data"
            *ngIf="config?.associateFlyout?.confirmationLocation"
          >
            {{
              getObjectProperty(
                addResponse,
                config?.associateFlyout?.confirmationLocation
              )
            }}
          </h6>
        </div>
        <div *ngFor="let selection of selectedRows; let i = index">
          <div class="row">
            <div class="column">
              <h6 class="t-bold">
                {{
                  getObjectProperty(
                    selection.row,
                    config?.associateFlyout?.fields.name
                  )
                }}
              </h6>
            </div>
            <div class="column">
              <div
                *ngFor="
                  let _item of config?.associateFlyout?.fields.rightPanel;
                  let index = index
                "
              >
                <model-attribute
                  [context]="selection.row"
                  [config]="_item"
                ></model-attribute>
              </div>
            </div>
          </div>
          <div *ngFor="let row of config?.associateFlyout?.controls">
            <p class="t-data t-bold">{{ row.label }}</p>

            <p class="t-data" *ngIf="!row.dropdownDisplayProp">
              {{
                genericServices.formatControl(
                  getAssociatedFields(i).controls[row.prop],
                  row
                )
              }}
            </p>
            <p
              class="t-data"
              *ngIf="
                row.dropdownDisplayProp && row.dropdownDisplayProp == 'name'
              "
            >
              {{
                genericServices.formatControl(
                  getAssociatedFields(i).controls[row.prop],
                  row
                ).name
              }}
            </p>

            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div footer>
    <zip-button
      [disabled]="hasAssociateSelections()"
      *ngIf="addState == 'add'"
      level="high"
      [content]="config?.associateFlyout?.nextButton"
      (click)="reviewAssociated($event)"
    ></zip-button>
    <zip-button
      [disabled]="isAssociateDisabled()"
      *ngIf="addState == 'review'"
      level="high"
      [content]="config?.associateFlyout?.submitButton"
      (click)="submitAddAssociated()"
    ></zip-button>
  </div>
</modal>

<pp-additional-detail-modal
  *ngIf="showAdditionalDetailsModal"
  [config]="additionalDetailsModalConfig"
  [showAdditionalDetailsModal]="showAdditionalDetailsModal"
  [additionalDetailsForm]="additionalDetailsForm"
  (closeModal)="cancelEdit()"
  (submitClicked)="submit()"
></pp-additional-detail-modal>
