import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import {
  LinkListModule,
  ModelAttributeModule,
  SpinnerModule,
} from '@zipari/design-system';
import { SideBarComponent } from './side-bar.component';

@NgModule({
  declarations: [SideBarComponent],
  exports: [SideBarComponent],
  imports: [CommonModule, ModelAttributeModule, SpinnerModule, LinkListModule],
})
export class SideBarModule {}
