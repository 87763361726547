import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { FormGroupArrayConfig } from '../../form-group-array.config';

@Component({
  selector: 'form-group-array-footer',
  templateUrl: './form-group-array-footer.component.html',
  styleUrls: ['./form-group-array-footer.component.scss'],
})
export class FormGroupArrayFooterComponent {
  @Input() config: FormGroupArrayConfig;
  @Input() formArray: UntypedFormArray;
  @Input() context: object[];
  @Output() addFormGroupClicked: EventEmitter<void> = new EventEmitter();

  addFormGroup() {
    this.addFormGroupClicked.emit();
  }

  public hideAddButton(): boolean {
    return this.config?.maxLength > this.formArray?.length;
  }
}
