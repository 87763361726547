export const officeHourConfigs = [
  {
    isDisabled: false,
    label: '',
    prop: 'monday',
    readonly: false,
    type: 'checkbox',
    options: [
      {
        label: 'Monday',
        value: 'monday',
      },
    ],
  },
  {
    isDisabled: false,
    label: '',
    prop: 'tuesday',
    readonly: false,
    type: 'checkbox',
    options: [
      {
        label: 'Tuesday',
        value: 'tuesday',
      },
    ],
  },
  {
    isDisabled: false,
    label: '',
    prop: 'wednesday',
    readonly: false,
    type: 'checkbox',
    options: [
      {
        label: 'Wednesday',
        value: 'wednesday',
      },
    ],
  },
  {
    isDisabled: false,
    label: '',
    prop: 'thursday',
    readonly: false,
    type: 'checkbox',
    options: [
      {
        label: 'Thursday',
        value: 'thursday',
      },
    ],
  },
  {
    isDisabled: false,
    label: '',
    prop: 'friday',
    readonly: false,
    type: 'checkbox',
    options: [
      {
        label: 'Friday',
        value: 'friday',
      },
    ],
  },
  {
    isDisabled: false,
    label: '',
    prop: 'saturday',
    readonly: false,
    type: 'checkbox',
    options: [
      {
        label: 'Saturday',
        value: 'saturday',
      },
    ],
  },
  {
    isDisabled: false,
    label: '',
    prop: 'sunday',
    readonly: false,
    type: 'checkbox',
    options: [
      {
        label: 'Sunday',
        value: 'sunday',
      },
    ],
  },
];

export const timeConfig = {
  isDisabled: false,
  label: '',
  prop: 'doesntmatter',
  readonly: false,
  type: 'time',
  validators: ['required'],
};

export const times = ['from', 'to'];
