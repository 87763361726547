<!-- Dropdown with possible enrollments types -->
<div class="whose-covered--form whose-covered--dropdown">
  <div class="form--controls">
    <form-control
      [group]="whoseCoveredGroup"
      [control]="individualsCoveredControl"
      [config]="individualsCoveredConfig"
      (change)="onChange($event)"
    >
    </form-control>
  </div>
</div>

<ng-container [ngSwitch]="whoseCoveredSelected">
  <ng-container *ngSwitchCase="'subscriber'">
    <ng-container *ngTemplateOutlet="subTemplate"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'spouse'">
    <ng-container *ngTemplateOutlet="subTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="spouseTemplate"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'parent'">
    <ng-container *ngTemplateOutlet="subTemplate"></ng-container>
    <ng-container
      *ngTemplateOutlet="depTemplate; context: { childOnly: false }"
    ></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'family'">
    <ng-container *ngTemplateOutlet="subTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="spouseTemplate"></ng-container>
    <ng-container
      *ngTemplateOutlet="depTemplate; context: { childOnly: false }"
    ></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'child_only'">
    <ng-container
      *ngTemplateOutlet="depTemplate; context: { childOnly: true }"
    ></ng-container>
  </ng-container>
</ng-container>

<!-- Subscriber -->
<ng-template #subTemplate>
  <div class="whose-covered--form subscriber--form">
    <div class="whose-covered--section--header">
      <icon
        name="person"
        [config]="{
          size: 'medium',
          set: 'system',
          format: 'svg'
        }"
      >
      </icon>
      <h6 class="t-bold">{{ subscriberConfig.text.subscriber }}</h6>
    </div>
    <div class="whose-covered__questions">
      <ng-container *ngFor="let config of subscriberConfig?.controls">
        <div class="form--controls">
          <form-control
            [group]="subscriberControl"
            [config]="config"
            [control]="subscriberControl.get(config.prop)"
          >
          </form-control>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- Spouse -->
<ng-template #spouseTemplate>
  <div
    class="whose-covered--form spouse--form"
    *ngIf="whoseCoveredValue.spouse"
  >
    <div class="whose-covered--section--header">
      <icon
        name="patients"
        [config]="{
          size: 'medium',
          set: 'system',
          format: 'svg'
        }"
      >
      </icon>
      <h6 class="t-bold">{{ spouseConfig.text.spouse }}</h6>
    </div>

    <div class="whose-covered__questions">
      <ng-container *ngFor="let config of spouseConfig.controls">
        <div class="form--controls">
          <form-control
            [group]="spouseControl"
            [config]="config"
            [control]="spouseControl.get(config.prop)"
          >
          </form-control>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- Dependent -->
<ng-template #depTemplate let-childOnly="childOnly">
  <div class="whose-covered--form">
    <ng-container *ngFor="let dep of dependentVal; let i = index">
      <div class="whose-covered--section--header">
        <icon
          name="patients"
          [config]="{
            size: 'medium',
            set: 'system',
            format: 'svg'
          }"
        >
        </icon>
        <h6 class="t-bold">{{ depConfig.text.child }} {{ i + 1 }}</h6>

        <div class="remove--dependent" *ngIf="dependentVal.length > 1">
          <icon
            name="close"
            [config]="{
              size: 'medium',
              set: 'system',
              format: 'svg'
            }"
            (click)="removeDependent(i)"
          >
          </icon>
        </div>
      </div>

      <div class="whose-covered__questions">
        <ng-container *ngIf="childOnly; else depTemplateFormControls">
          <ng-container
            *ngFor="let config of childOnlyConfig.controls; let x = index"
          >
            <div
              class="dependent__controls"
              [ngClass]="{ 'dependent__controls--extra': x > 1 }"
            >
              <div class="form--controls">
                <form-control
                  [group]="dependentsControl.get(i)"
                  [config]="config"
                  [control]="dependentsControl[i].get(config.prop)"
                >
                </form-control>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-template #depTemplateFormControls>
          <ng-container
            *ngFor="let config of depConfig.controls; let x = index"
          >
            <div
              class="dependent__controls"
              [ngClass]="{ 'dependent__controls--extra': x > 1 }"
            >
              <div class="form--controls">
                <form-control
                  [group]="dependentsControl.get(i)"
                  [config]="config"
                  [control]="dependentsControl.get(i).get(config.prop)"
                >
                </form-control>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </ng-container>

    <ng-container *ngIf="childOnly; else depTemplate">
      <zip-button
        *ngIf="dependentVal.length < childLimit"
        [icon]="buttonConfig.icon"
        [level]="buttonConfig.level"
        [content]="buttonConfig.content"
        (zipButtonClicked)="addChildOnly(whoseCoveredValue, 1)"
      >
      </zip-button>
    </ng-container>
    <ng-template #depTemplate>
      <zip-button
        *ngIf="dependentVal.length < childLimit"
        [icon]="buttonConfig.icon"
        [level]="buttonConfig.level"
        [content]="buttonConfig.content"
        (zipButtonClicked)="addDependent(whoseCoveredValue, 1)"
      >
      </zip-button>
    </ng-template>
  </div>
</ng-template>
