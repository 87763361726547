<div
  tabindex="0"
  [attr.aria-label]="config.ariaLabel"
  class="form-control__wrapper--date-range"
  *ngIf="timeRangeConfig"
>
  <control-text
    *ngFor="let date of ['start', 'end']"
    [control]="timeRangeForm.get(date)"
    [config]="timeRangeConfig[date]"
  >
  </control-text>
</div>
