import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DigitalDataModule } from '../../shared/modules/digital-data/digital-data.module';
import { ButtonModule } from '../button/button.module';
import { IconModule } from '../icon/icon.module';
import { TooltipComponent } from './tooltip.component';
import { TooltipDirective } from './tooltip.directive';

@NgModule({
  declarations: [TooltipComponent, TooltipDirective],
  imports: [CommonModule, DigitalDataModule, IconModule, ButtonModule],
  exports: [TooltipComponent, TooltipDirective],
})
export class TooltipModule {}
