import {
  AfterContentInit,
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { MASKS } from '@zipari/shared-ds-util-format';
import { fromEvent, of } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[zipMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaskDirective),
      multi: true,
    },
  ],
})
export class MaskDirective implements ControlValueAccessor, AfterContentInit {
  @Input() zipMask: any;
  @Input() formControl: UntypedFormControl;
  innerValue;
  persistMask;

  constructor(private elRef: ElementRef) {}

  ngAfterContentInit(): void {
    if (
      this.zipMask &&
      MASKS[this.zipMask] &&
      MASKS[this.zipMask].blur &&
      this.elRef.nativeElement.value
    ) {
      this.handleBlur(this.elRef.nativeElement.value);
    }

    // Update form control value on blur if parent formGroup has updateOn blur setting
    if (this.formControl.parent.updateOn === 'blur') {
      fromEvent(this.elRef.nativeElement, 'blur').subscribe(() => {
        this.formControl.patchValue(this.value);
      });
    }
  }

  @HostListener('input', ['$event'])
  onInput(event) {
    const value = this.elRef.nativeElement.value;
    if (this.zipMask && MASKS[this.zipMask]) {
      this.writeValue(this.unmaskValue(value));
      if (
        event.data ||
        event.inputType === 'insertFromPaste' ||
        // `event.type === input` is an Edge/IE fix, but don't want to run mask on delete
        (event.type === 'input' && event.inputType !== 'deleteContentBackward')
      ) {
        this.elRef.nativeElement.value = this.maskValue(value);
      }
    }

    if (!this.zipMask) {
      this.writeValue(value);
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event) {
    const value = this.elRef.nativeElement.value;
    if (
      value &&
      this.zipMask &&
      MASKS[this.zipMask] &&
      MASKS[this.zipMask].blur
    ) {
      this.handleBlur(value);
    }

    this.onTouched();
  }

  @HostListener('focus', ['$event'])
  onFocus(event) {
    if (this.persistMask && this.zipMask) {
      const value = this.persistMask;
      this.writeValue(this.unmaskValue(value));
      this.elRef.nativeElement.value = value;
    }
  }

  handleBlur(value) {
    this.persistMask = value;
    this.writeValue(this.unmaskValue(value));
    this.elRef.nativeElement.value = this.blurValue(value);
  }

  maskValue(value) {
    if (!value) {
      return '';
    }

    return MASKS[this.zipMask].mask(value);
  }

  unmaskValue(value) {
    if (!value) {
      return '';
    }

    return MASKS[this.zipMask].unmask(value);
  }

  blurValue(value) {
    if (!value) {
      return '';
    }

    return MASKS[this.zipMask].blur(value);
  }

  // tslint:disable-next-line: no-empty
  public onChange: any = (_) => {};
  // tslint:disable-next-line: no-empty
  public onTouched: any = () => {};

  get value(): any {
    return this.innerValue;
  }

  writeValue(value: string): void {
    if (!this.zipMask) {
      this.innerValue = value;
      this.persistMask = value;
      this.elRef.nativeElement.value = value;
      this.onChange(value);
    } else {
      of(value)
        .pipe(
          // Filter here prevents infinite loops when parent also implements CVA
          filter(() => value !== this.innerValue)
        )
        .subscribe(() => {
          if (value) {
            this.elRef.nativeElement.value = this.maskValue(value);
          }

          this.persistMask = this.maskValue(value);
          this.innerValue = value;

          if (value === null) {
            this.elRef.nativeElement.value = '';
          }

          this.onChange(value);
        });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = (value) => {
      // Don't want to update value on all changes if parent formGroup has updateOn blur
      if (
        !this.formControl.parent ||
        this.formControl.parent.updateOn !== 'blur'
      ) {
        this.formControl.patchValue(value);
        this.formControl.markAsDirty();
      }

      return fn;
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
