<div class="overview">
  <div class="overview__attrs">
    <div *ngFor="let claimAttribute of config?.attributeList?.attributes">
      <model-attribute
        class="attr"
        [context]="claim"
        [config]="claimAttribute"
      ></model-attribute>
      <p
        *ngIf="
          claimAttribute.formatOptions?.statusProp &&
          showOutOfNetworkBadge(
            claimAttribute?.formatOptions?.statusProp,
            claim
          )
        "
        class="OutOfNetwork__StatusPill Pending"
      >
        {{ claimDetailService?.config?.outOfNetwork?.outOfNetworkLabel }}
      </p>
    </div>
  </div>

  <div>
    <header class="overview__table__title__text" *ngIf="config?.tableTitle">
      <h6>{{ config.tableTitle }}</h6>
    </header>
  </div>

  <div class="overview__table">
    <zip-table
      [options]="config?.table"
      [data]="claim[config?.tableDataKey]"
    ></zip-table>
  </div>
</div>
