import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AttachmentMetaData } from '../messages.model';
import { WindowService } from '@zipari/web-services';
@Component({
  selector: 'attachment-pill',
  templateUrl: './attachment-pill.component.html',
  styleUrls: ['./attachment-pill.component.scss'],
})
export class AttachmentPillComponent {
  @Input() attachments: AttachmentMetaData[];
  @Input() showCloseIcon = false;

  @Output() removeAttachment = new EventEmitter();

  constructor(private windowService: WindowService) {}

  openAttachment(id: number, event: MouseEvent) {
    // clicking on it should stop folding the expanded message, which is the parent of AttachmentPillComponent
    event.stopPropagation();

    return this.windowService.nativeWindow.open(
      `/api/documents/${id}/?download=true`
    );
  }
}
