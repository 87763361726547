import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModelAttributeModule } from '@zipari/design-system';

import { FinancialOverviewComponent } from './financial-overview.component';

@NgModule({
  declarations: [FinancialOverviewComponent],
  exports: [FinancialOverviewComponent],
  imports: [CommonModule, ModelAttributeModule],
})
export class FinancialOverviewModule {}
