<article class="layout--detail" [zipBusy]="busy">
  <article class="layout--detail__content" *ngIf="!!dataLoaded">
    <aside class="layout--detail__aside">
      <ng-container *ngFor="let section of sidebar.sections">
        <div [ngSwitch]="section.format">
          <header class="layout--detail__aside__header" *ngIf="section.label">
            <h6 role="heading" aria-level="1">{{ section.label }}</h6>
          </header>

          <section
            class="auth-detail__aside__attrs"
            *ngSwitchCase="'attribute-list'"
          >
            <div *ngFor="let attribute of section.attributes" class="Details">
              <model-attribute
                [context]="context"
                [config]="attribute"
                direction="column"
              >
              </model-attribute>
              <p
                *ngIf="
                  attribute.formatOptions?.statusProp &&
                  showOutOfNetworkBadge(
                    attribute.formatOptions?.statusProp,
                    context
                  )
                "
                class="OutOfNetwork__StatusPill Pending"
              >
                {{ config?.outOfNetwork?.outOfNetworkLabel }}
              </p>
            </div>
          </section>
          <section
            class="auth-detail__aside__attrs"
            *ngSwitchCase="'custom-link-list'"
          >
            <link-list [dataList]="context.formattedDocumentslist"></link-list>
          </section>
        </div>
      </ng-container>
    </aside>

    <main class="layout--detail__main">
      <header class="layout--detail__main__breadcrumb">
        <zip-button
          [config]="buttonConfig"
          (zipButtonClicked)="routeBack(buttonConfig)"
        ></zip-button>
        <div *ngIf="config?.actionButton">
          <zip-button
            [config]="config?.actionButton"
            (zipButtonClicked)="
              navigateSubNav(config?.actionButton?.timeInHHMMSS, $event)
            "
          ></zip-button>
        </div>
      </header>

      <section
        class="layout--detail__main__section__header"
        *ngIf="config.badges"
      >
        <div
          class="auth-detail__badge"
          *ngFor="let badge of modifiedBadgeConfig"
        >
          <badge class="u-fillRemaining" [config]="badge"></badge>
        </div>
      </section>
      <div class="table-first-column-width-adjust">
        <section class="layout--detail__main__section">
          <ng-container *ngIf="!config.isTabsEnable; else detailTabs">
            <tab-view
              [config]="config.tabs"
              [data]="context"
              [selectedTab]="0"
              [outOfNetwork]="config?.outOfNetwork"
            ></tab-view>
          </ng-container>
          <ng-template #detailTabs>
            <detail-tabs
              [tabConfig]="config.tabs"
              [tabData]="context"
              [customInitActiveTab]="0"
              [outOfNetwork]="config?.outOfNetwork"
            ></detail-tabs>
          </ng-template>
        </section>
      </div>
    </main>
  </article>
</article>
<modal
  #errorFlyout
  *ngIf="errorPopup"
  [config]="config?.errorModal"
  [condition]="errorPopup"
  (cancel)="onClick()"
>
  <div body>{{ config?.errorModal.message }}</div>
</modal>
