export const TYPE_DROPDOWN_OPTIONS = [
  {
    label: 'No Format Selected',
    value: '',
  },
  {
    label: 'Text',
    value: 'text',
  },
  {
    label: 'Checkbox',
    value: 'checkbox',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Dropdown',
    value: 'dropdown',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Password',
    value: 'password',
  },
  {
    label: 'Radio',
    value: 'radio',
  },
  {
    label: 'U.S. & Overseas Territories',
    value: 'states',
  },
  {
    label: 'Switch',
    value: 'switch',
  },
  {
    label: 'Textarea',
    value: 'textarea',
  },
  {
    label: 'Toggle',
    value: 'toggle',
  },
];
