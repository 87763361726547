import { OnInit } from '@angular/core';
import { Directive, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[preInit]',
})
export class NgSwitchPreInitDirective implements OnInit {
  @Output() preInit: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.preInit.emit();
  }
}
