import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '@zipari/design-system';
import { AuthBannerLayoutComponent } from './auth-banner-layout.component';

@NgModule({
  declarations: [AuthBannerLayoutComponent],
  imports: [CommonModule, ButtonModule],
  exports: [AuthBannerLayoutComponent],
})
export class AuthBannerLayoutModule {}
