import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { AttributeList } from '@zipari/design-system';
import {
  DisputeResponse,
  MemberInformation,
  RequestorInformation,
  ServiceInformation,
  ServicingProvider,
} from '../../../../shared/models/shared/ClaimAppealDetail.model';

@Component({
  selector: 'info-list',
  templateUrl: './info-list.component.html',
  styleUrls: ['./info-list.component.scss'],
})
export default class InfoListComponent {
  @Input() header: string;
  @Input() attributeList: AttributeList;
  @Input() context$: Observable<
    | DisputeResponse
    | MemberInformation
    | ServiceInformation
    | ServicingProvider
    | RequestorInformation
  >;
}
