import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimAppealSummary } from '../../../../shared/models/shared/ClaimAppeals.model';
import { ClaimDetailService } from '../claim-detail.service';
import { ClaimAppealsTabConfig } from '../ClaimDetailConfig.model';
import { ClaimAppealRouteSummary } from '../../../../shared/models/shared/ClaimDetail.model';

@Component({
  selector: 'clammAppeals',
  templateUrl: './claim-appeals.component.html',
})
export class ClaimAppealsComponent implements OnInit {
  config: ClaimAppealsTabConfig;
  claimAppealSummary$: Observable<ClaimAppealSummary[]>;

  constructor(
    private claimDetailService: ClaimDetailService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.config = this.claimDetailService.tabsConfig;
    this.claimAppealSummary$ = this.claimDetailService.claimAppealSummary$;
  }

  /**
   * @description the method will navigate to the claim details page
   * @param claimAppealSummary
   */
  navigateToClaimAppealDetail(
    selectedClaimAppeal: ClaimAppealRouteSummary
  ): void {
    const { appealId, disputeLevel } = selectedClaimAppeal.context.row;

    this.router.navigate([appealId, disputeLevel], { relativeTo: this.route });
  }
}
