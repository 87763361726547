import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsModule } from '../tabs/tabs.module';
import { TabViewModule } from '../tab-view/tab-view.module';
import { SharedModule } from '../../../../shared/shared.module';
import { DetailTabsComponent } from './detail-tabs.component';

@NgModule({
  declarations: [DetailTabsComponent],
  imports: [CommonModule, TabsModule, TabViewModule, SharedModule],
  exports: [DetailTabsComponent],
})
export class DetailTabsModule {}
