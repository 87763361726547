import { NavigationLinkTargets } from '@zipari/shared-ds-util-navigation';

export enum FooterLineTypes {
  address = 'address',
  header = 'header',
  icon = 'icon',
  image = 'image',
  link = 'link',
  longText = 'long-text',
  modalLink = 'modal-link',
  phone = 'phone',
  text = 'text',
  title = 'title',
}

export type PortalFooterLine =
  | AddressFooterLine
  | ImageFooterLine
  | LinkFooterLine
  | ModalFooterLine
  | PhoneNumberFooterLine
  | TextFooterLine;

export interface FooterRow {
  columns: PortalFooterLine[][];
  marginBottom: string;
}

export interface FooterLine {
  readonly type: FooterLineTypes;
}

export interface AddressFooterLine extends FooterLine {
  line1: string;
  line2?: string;
}

export interface ImageFooterLine extends FooterLine {
  source: string;
  title: string;
  height?: string;
  width?: string;
}

export interface LinkFooterLine extends TextFooterLine {
  route: string;
  useExternalSiteModal?: boolean;
  target?: NavigationLinkTargets;
  isExternalRoute?: boolean;
  showIcon?: boolean;
  removeLinkStyles?: boolean;
}

export interface ModalFooterLine extends TextFooterLine {
  modal: {
    header: {
      title: string;
    };
    size: string;
    body: string;
    isOpen: boolean;
    footer: {
      cancel: string;
      proceed: string;
      link: string;
    };
  };
}

export interface PhoneNumberFooterLine extends TextFooterLine {
  phone: string;
}

export interface TextFooterLine extends FooterLine {
  label: string;
  textAlignCenter?: boolean;
}

export interface PortalFooterConfiguration {
  rows?: PortalFooterConfigurationRows;
}

export interface PortalFooterConfigurationRows {
  [rowKey: string]: PortalFooterConfigurationRow;
}

export interface PortalFooterConfigurationRow {
  columns: PortalFooterConfigurationColumns;
  key: string;
  marginBottom: string;
  priority: string;
}

export interface PortalFooterConfigurationColumns {
  [columnIndex: string]: {
    [itemIndex: string]: PortalFooterLine;
  };
}
