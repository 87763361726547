import { ClaimAppealDetailTabsConfig } from '../../../shared/models/shared/ClaimAppealDetail.model';

export const initialValueTabConfig: ClaimAppealDetailTabsConfig = {
  label: '',
  priority: 0,
  prop: '',
  targetConfigKey: '',
  url: '',
};

export const CLAIM_APPEAL_DETAIL_URLS = {
  CLAIM_APPEAL_DETAIL: 'claim-appeal-detail',
  DISPUTE_RESPONSE: 'dispute-response',
  MEMBER_INFO: 'member-info',
  REQUESTOR_INFO: 'requestor-info',
  CONTACT_INFO: 'contact-info',
  SERVICE_INFO: 'service-info',
};

export const ClaimAppealQuestionProps = {
  additionalInformation: 'Question_additionalInformation',
  notAgreeReason: 'Question_notAgreeReason',
};

export const ClaimAppealType = {
  reconsideration: 'reconsideration',
  redetermination: 'redetermination',
};

export const QuestionReasonOfLateFiling = 'Question_reasonOfLateFiling';
export const DocumentDefaultLabel = 'Document';
