import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ButtonModule, TableModule } from '@zipari/design-system';
import {
  LIST_SERVICE,
  ListService,
} from '../../../shared/templates/list/list.service';
import { AddNewModalModule } from '../../../shared/modules/add-row-modal/add-new-modal.module';
import { ZipBusyModule } from '../../../shared/modules/zip-busy';
import { ListConfig } from '../../../shared/templates/list/list.module';
import { ManagePortalUsersComponent } from './manage-portal-users.component';
@NgModule({
  declarations: [ManagePortalUsersComponent],
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    ZipBusyModule,
    AddNewModalModule,
  ],
  exports: [ManagePortalUsersComponent],
})
export class ManagePortalUsersModule {
  static forRoot(
    listConfig?: ListConfig
  ): ModuleWithProviders<ManagePortalUsersModule> {
    return {
      ngModule: ManagePortalUsersModule,
      providers: [
        {
          provide: LIST_SERVICE,
          useClass: (listConfig && listConfig.listService) || ListService,
        },
      ],
    };
  }
}
