import { Component, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  BRAND_COLORS,
  BrandColorLabels,
  BrandColorSet,
} from './brand-color.constant';

@Component({
  selector: 'brand-color',
  templateUrl: './brand-color.component.html',
  styleUrls: [
    '../../../../notes/form-control/form-control.component.scss',
    './brand-color.component.scss',
  ],
})
export class BrandColorComponent implements ControlValueAccessor, OnInit {
  @Input() group: UntypedFormGroup;
  @Input() config;
  @Input() direction;

  public brandColorLabels = BrandColorLabels;

  public value: string;
  public selectedColor;
  public menuOpen: boolean;
  public palette: BrandColorSet[] = BRAND_COLORS;

  constructor() {}

  propagateChange = (_: any) => {
    return;
  };

  onTouched = () => {
    return;
  };

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  ngOnInit() {
    this.setColor();

    const control = this.group.get(this.config.prop) as UntypedFormControl;

    control.valueChanges.subscribe((color) => {
      this.setColor();
    });
  }

  setColor() {
    if (this.group && this.config) {
      this.palette.forEach((set) => {
        set.colors.forEach((color) => {
          if (color.value === this.group.get(this.config.prop).value) {
            this.selectedColor = color;
          }
        });
      });
    } else {
      throw 'Both a FormGroup and configuration must be provided';
    }
  }

  getLabel(key) {
    if (typeof key === 'string') {
      return key
        .replace(/([A-Z]+)/g, ' $1')
        .replace(/([A-Z][a-z])/g, ' $1')
        .replace(/--/g, ' ')
        .replace(
          /\w\S*/g,
          (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        );
    }
  }

  getHex(variable) {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(variable)
      .trim();
  }

  selectColor(color) {
    this.selectedColor = color;
    this.group.patchValue({
      [this.config.prop]: color.value,
    });
    this.group.markAsDirty();
    this.menuOpen = false;
  }
}
