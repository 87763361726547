import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
})
export class ReadMoreComponent implements OnInit {
  @Input() text: string;
  @Output() click: EventEmitter<any> = new EventEmitter();

  clicked = false;
  showButton = false;
  textCount: number;

  ngOnInit() {
    this.textCount = this.text.length;

    if (this.textCount) {
      this.showButton =
        this.textCount > this.pipeOptions.maxCharacters ? true : false;
    }
  }

  readClicked() {
    this.clicked = !this.clicked;
  }

  public get pipeOptions() {
    return {
      showAll: this.clicked,
      maxCharacters: 60,
    };
  }
}
