<section
  class="Banner"
  [ngClass]="{ 'Banner--Full': config.isFullWidthImage }"
  [ngStyle]="{
    'background-image': config.isFullWidthImage
      ? 'url(' + config.image + ')'
      : 'none'
  }"
>
  <div class="container">
    <div
      class="u-flex u-alignCenter"
      [ngClass]="{
        'Banner--Reverse': reverse,
        'u-justifyCenter': config.centerContent
      }"
    >
      <div class="Banner__Text">
        <h3
          class="Banner__Title"
          [ngClass]="{ 'u-textCenter': config.centerContent }"
        >
          {{ config.title }}
        </h3>
        <h3
          class="Banner__Subtitle"
          [ngClass]="{ 'u-textCenter': config.centerContent }"
        >
          {{ config.subtitle }}
        </h3>
      </div>
      <div
        class="Banner__Image--Wrapper"
        *ngIf="!config.isFullWidthImage && config.image?.length > 1"
      >
        <div
          class="Banner__Image"
          [title]="config.title"
          [ngStyle]="{
            'background-image': 'url(' + config.image + ')'
          }"
        ></div>
      </div>
    </div>
  </div>
</section>
