<article class="ActivateUser" *ngIf="config">
  <div class="ActivateUser__Stepper">
    <div class="ActivateUser__StepperContent">
      <ng-container
        *ngFor="let step of config.activateUserSteps.attributes; let i = index"
      >
        <div class="ActivateUser__StepperItem">
          <p
            [ngClass]="{ 't-bold': state === step.prop }"
            class="t-data"
            [innerHTML]="step.label"
          ></p>
          <icon
            *ngIf="i < config.activateUserSteps.attributes.length - 1"
            [name]="'chevron_right'"
          ></icon>
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="activateUserService.groupsDetail$ | async as group; else loading">
    <div class="ActivateUser__Content">
      <ng-container *ngIf="state === 'selectGroups'; else review">
        <check-list-card
          [config]="config.selectGroups"
          [data]="group"
          (selectedItems)="getSelectedGroups($event)"
        ></check-list-card>
      </ng-container>

      <ng-template #review>
        <review-suspend-user
          [config]="config.review"
          (editDetail)="editStep($event)"
        ></review-suspend-user>
      </ng-template>
    </div>
  </div>

  <ng-template #loading>
    <spinner [asOverlay]="true" size="medium"></spinner>
  </ng-template>

  <div class="ActivateUser__Footer">
    <zip-button
      class="ActivateUser__FooterButton"
      [config]="config.buttons?.cancelButton"
      (zipButtonClicked)="navigateToPreviousPage()"
    >
    </zip-button>
    <footer-dynamic-button
      class="ActivateUser__FooterButton"
      (handleNextStep)="handleNextStep()"
    ></footer-dynamic-button>
  </div>
</article>

<api-error-message-modal
  [modalConfig]="config?.errorModal?.modalConfig"
  [buttonConfig]="config?.errorModal?.submitButton"
  [showModal]="showErrorModal"
  [errorMessage]="errorMessage"
  (submitButtonClicked)="errorModalSubmitClicked()"
></api-error-message-modal>
