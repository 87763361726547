import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentCenterComponent } from './document-center.component';
import { DocumentCenterRoutingModule } from './document-center-routing.module';
import {
  AccordionModule,
  ButtonModule,
  DatagridModule,
  FormGroupModule,
  GridLayoutModule,
  IconModule,
  MessageBannerModule,
  ModalModule,
  PaginatorModule,
  PreloaderModule,
  TabsModule,
} from '@zipari/design-system';
import { SquidexDataService } from '@zipari/shared-data-access-squidex';
import {
  FormatDocumentCenterTab,
  GetErrorMessagePipe,
} from './pipes/document-center.pipes';
import { AccordionLayoutComponent } from './components/accordion-layout/accordion-layout.component';
import { DocumentFiltersComponent } from './components/document-filters/document-filters.component';
import { MobileFiltersComponent } from './components/mobile-filters/mobile-filters.component';
import { TabContentComponent } from './components/tab-content/tab-content.component';

@NgModule({
  imports: [
    CommonModule,
    DocumentCenterRoutingModule,
    TabsModule,
    FormGroupModule,
    DatagridModule,
    PaginatorModule,
    ButtonModule,
    GridLayoutModule,
    PreloaderModule,
    ModalModule,
    MessageBannerModule,
    AccordionModule,
    IconModule,
  ],
  declarations: [
    DocumentCenterComponent,
    GetErrorMessagePipe,
    FormatDocumentCenterTab,
    AccordionLayoutComponent,
    DocumentFiltersComponent,
    MobileFiltersComponent,
    TabContentComponent,
  ],
  providers: [SquidexDataService],
  exports: [DocumentCenterComponent],
})
export class DocumentCenterModule {}
