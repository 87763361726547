import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './loader.actions';

export interface LoadingState {
  loading: boolean;
}

export const initialLoadingState: LoadingState = {
  loading: false,
};

const httpEventReducer = createReducer(
  initialLoadingState,

  on(actions.showLoader, (state) => ({
    ...state,
    loading: true,
  })),
  on(actions.hideLoader, (state) => ({
    ...state,
    loading: false,
  }))
);

export function getHttpEventReducer(state: LoadingState, action: Action) {
  return httpEventReducer(state, action);
}
