import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { FileUploaderService } from '../file-uploader.service';

@Component({
  selector: 'file-uploader-button',
  templateUrl: './file-uploader-button.component.html',
})
export class FileUploaderButtonComponent {
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @Input() disabled: boolean;
  @Input() fileUploadButton: ButtonConfig;

  constructor(
    public fileUploadService: FileUploaderService,
    private host: ElementRef<HTMLInputElement>
  ) {}

  onUploadDocumentClick() {
    this.fileInput.nativeElement.click();
  }

  onFileSelected(event: { target: HTMLInputElement }) {
    // this starts it off
    this.fileUploadService.handleFileInput(event.target.files);
    this.fileInput.nativeElement.value = null;
  }
}
