import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';

import { CheckboxConfiguration } from '@zipari/shared-ds-util-form';
import { FormControlService } from '../form-control.service';

@Component({
  selector: 'control-checkbox',
  templateUrl: './checkbox.component.html',
})
export class ControlCheckboxComponent implements OnInit, DoCheck {
  @Input() config: CheckboxConfiguration;
  @Input() control: UntypedFormArray;
  @Input() disabled: any;

  extraText = {};
  otherConfig = {
    type: 'text',
    prop: 'other',
    placeholder: null,
  };
  optionsCache;

  constructor(
    public formControlService: FormControlService,
    public cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!this.config.options) {
      this.config.options = [
        {
          label: this.config.prop,
          value: this.control.value,
        },
      ];
    }

    this.setCheckboxOptionsIsDisabled(this.config);

    // do a check for type 'other'
    this.config.options.forEach((option) => {
      if (option.type === 'other') {
        this.otherConfig.placeholder = option.placeholder;
        if (option.prop) {
          this.otherConfig.prop = option.prop;
        }
        if (!this.extraText[option.label]) {
          this.extraText[option.label] = new UntypedFormControl(null);
          if (option.prop) {
            option.value = option.prop;
          } else option.value = 'other';
        }
      }
    });

    this.optionsCache = JSON.stringify(this.config.options || {});
  }

  ngDoCheck() {
    const newOptionsToCheck = JSON.stringify(this.config.options || {});

    if (newOptionsToCheck !== this.optionsCache) {
      this.setCheckboxOptionsIsDisabled(this.config);

      const newFormControls = [];
      this.config.options.forEach((option) => {
        const formArray: UntypedFormArray = this.control;
        let individualControl = formArray.controls.find(
          (control) => control['prop'] === option.value
        );

        if (!individualControl) {
          individualControl = new UntypedFormControl(null);
        }
        newFormControls.push(individualControl);
      });

      // remove any previous controls
      this.formControlService.removeAllControls(this.control);

      // loop through and set the new controls
      newFormControls.forEach((control, ind) => {
        this.control.setControl(ind, control);
      });

      // set the new options cache
      this.optionsCache = newOptionsToCheck;

      this.cdr.detectChanges();
    }
  }

  /**
   * Set's each checkbox option's isDisabled prop if the high level config.isDisabled is true
   *
   * @param config this component's config
   */
  setCheckboxOptionsIsDisabled(config: CheckboxConfiguration): void {
    if (config.isDisabled && config.options) {
      config.options = config.options.map((checkbox) => ({
        ...checkbox,
        isDisabled: config.isDisabled,
      }));
    }

    if (config.readonly && config.options) {
      config.options = config.options.map((checkbox) => ({
        ...checkbox,
        readonly: config.readonly,
      }));
    }
  }

  toggle(option, ind) {
    const currControl = this.extraText[option.label]
      ? this.extraText[option.label]
      : this.control.controls[ind];

    this.handleNullCheckbox(option);

    if (currControl.value === option.value) {
      currControl.patchValue(null);
    } else {
      currControl.patchValue(option.value);
    }
  }

  //get ID of checkbox
  getCheckboxId(config: CheckboxConfiguration, index: number) {
    const textId = config.id || config.prop || config.name;
    return `${textId}-${index}`;
  }

  /** ignores all controls but also allows for the ability to ignore particular indices */
  uncheckAllControls(ignoreControlInd?: number) {
    this.control.controls.forEach(
      (control: UntypedFormControl, ind: number) => {
        if (ind !== ignoreControlInd) {
          control.patchValue(null);
        }
      }
    );
  }

  /** makes sure that if you are setting the form control to null, other values are set to null
   * EX: For checkboxes labelled, Person1 (value person1), None of the above (value null). Clicking None of the above
   * will make the person 1 checkbox unchecked and clicking Person 1 when None of the Above is checked will uncheck
   * None of the above
   * */
  handleNullCheckbox(option) {
    let nullInd: number;

    // Find the index where the null option is
    this.config.options.forEach((optionConfig, ind) => {
      if (optionConfig.value === 'null') {
        nullInd = ind;
      }
    });

    if (option.value === 'null') {
      this.uncheckAllControls(nullInd);
    } else if (option.value !== 'null' && nullInd >= 0) {
      const nullControl = this.control.at(nullInd);
      nullControl.patchValue(null);
    }
  }
}
