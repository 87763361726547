import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  AllControlsConfiguration,
  FormControlOptionConfiguration,
} from '@zipari/shared-ds-util-form';
import { FormControlService } from '@zipari/design-system';
import * as __ from 'lodash';
import { Subject, catchError, of, takeUntil, tap } from 'rxjs';
import { objectToArray } from '@zipari/shared-util-common';
import { Group } from '../../../shared/models/shared/UserPayloadModels/UserEditModel.model';
import { APIService, ConfigService } from '../../../shared/services';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { getObjectProperty } from '../../../shared/utilities/get-object-property.utils';
import { camelToSnakeCase } from '../../../shared/utilities/case-conversion.utils';
import { stateOptions } from '../provider-update-tool/controls/address.constants';
import { CX_CALLS } from '../../../shared/constants/cx-calls.constant';
import { AuthConfig, AuthIDP, GlobalConfig } from '../../../app.constants';
import { AccessRequested } from '../../../shared/modules/authentication/model/request-access';
import {
  CreateUserConfig,
  ManageAccessCreateUser,
} from './managePortalUsersCreateUser.model';
import { RequestPayloadFormatService } from './request-payload-format.service';
import {
  createUserConstants,
  FormControlConstant,
} from './manage-portal-users-create-user.constants';
import { ManagePortalUsersCreateUserHttpService } from './manage-portal-users-create-user.http.service';
@Component({
  selector: 'manage-portal-users-create-user',
  templateUrl: './manage-portal-users-create-user.component.html',
  styleUrls: ['./manage-portal-users-create-user.component.scss'],
})

// rename component name to component folder name Camelcased with `Component` at the end
export class ManagePortalUsersCreateUserComponent implements OnInit, OnDestroy {
  config;
  context;
  createUserConfig: CreateUserConfig;
  newUserFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  cantPost: boolean;
  postMessage: any = createUserConstants.postMessage;
  access_id: string;
  role: string;
  index: number = 0;
  manageAccessUser: ManageAccessCreateUser;
  existingUserID: number | string;
  initialGroups: Group[];
  showErrorModal: boolean = false;
  errorMessage: string;
  globalConfig: GlobalConfig;
  authConfig: AuthConfig;
  lengthOfCard: number = 0;
  private destroy$ = new Subject();
  public enableReplacementUserWithTaxId: boolean;

  constructor(
    private route: ActivatedRoute,
    public configService: ConfigService,
    private api: APIService,
    private formControlService: FormControlService,
    public router: Router,
    private requestPayloadFormatService: RequestPayloadFormatService,
    public analyticsService: AnalyticsService,
    private createUserHttpService: ManagePortalUsersCreateUserHttpService
  ) {}

  get isMultipleGroupsSelectionEnabled(): boolean {
    return (
      !!this.globalConfig?.toggleFeatures?.enableMultiGroupManagement ?? false
    );
  }

  ngOnInit(): void {
    this.config = this.configService.getPageConfig(
      this.route.snapshot.data.pageName
    );
    this.lengthOfCard = objectToArray(this.config.cards).length;
    this.globalConfig = this.configService.getPageConfig('global');
    this.authConfig = this.configService.getPageConfig('auth');
    this.updateGroupControl();
    this.buildForm();
    this.role = this.route.snapshot.data.role;
    if (
      this.route.snapshot.data.manageAccess &&
      this.route.snapshot.params.access_id
    ) {
      this.access_id = this.route.snapshot.params.access_id;
      this.config.buttons.submitButton.content =
        createUserConstants.manageAccess;
      this.populateAccessData();
    }
    // To add state options from constant file
    this.getStateOptions(this.index, createUserConstants.profileStateProp);
    this.enableReplacementUserWithTaxId =
      this.globalConfig.toggleFeatures?.enableReplacementUserWithTaxId;
  }

  updateGroupControl() {
    const firstIndex = '0';
    if (this.isMultipleGroupsSelectionEnabled) {
      this.config.cards[firstIndex].formConfig = this.config.cards[
        firstIndex
      ].formConfig.filter(
        (control: AllControlsConfiguration) =>
          control.prop !== createUserConstants.groupId
      );
    } else {
      this.config.cards[firstIndex].formConfig = this.config.cards[
        firstIndex
      ].formConfig.filter(
        (control: AllControlsConfiguration) =>
          control.prop !== createUserConstants.multipleGroup
      );
    }
  }

  populateAccessData(): void {
    const manageAccessCreateUser = camelToSnakeCase(ManageAccessCreateUser);
    this.api
      .get(`${this.config.endpoints.user}/${this.access_id}`)
      .subscribe((access_user: typeof manageAccessCreateUser) => {
        this.manageAccessUser = access_user;
        this.buildForm();
      });
    this.newUserFormGroup.value[createUserConstants.accessRequestId] =
      this.access_id;
  }

  buildForm(): void {
    const { practiceTaxId, accessRequested, accessRequestedFor } =
      createUserConstants;

    Object?.values(this.config[createUserConstants.cards]).forEach(
      (card: any) => {
        Object?.values(card[createUserConstants.formConfig]).forEach(
          (controlConfig: any) => {
            if (
              controlConfig?.type === createUserConstants.dropdown &&
              this.config[createUserConstants.dropdownPropKey][
                controlConfig?.prop
              ]
            ) {
              this.populateDropdownsFromApi(controlConfig);
              const getKeyValue = getObjectProperty(
                this.manageAccessUser,
                controlConfig.apiProp
              );
              if (getKeyValue) {
                this.newUserFormGroup
                  .get(controlConfig.prop)
                  ?.setValue(getKeyValue);
              }
            } else {
              const control = new UntypedFormControl('', []);
              const newFormControl = Object.assign(controlConfig, {
                control: control,
              });
              this.formControlService.addControlToFormGroup(
                this.newUserFormGroup,
                newFormControl
              );

              if (
                this.enableReplacementUserWithTaxId &&
                controlConfig.prop === practiceTaxId
              ) {
                this.populateTaxId(accessRequested);
              } else {
                this.populateTaxId(accessRequestedFor);
                if (controlConfig.prop !== practiceTaxId) {
                  const getKeyValue = getObjectProperty(
                    this.manageAccessUser,
                    controlConfig?.apiProp
                  );
                  this.newUserFormGroup
                    .get(controlConfig.prop)
                    ?.setValue(getKeyValue);
                }
              }
              this.newUserFormGroup
                .get(controlConfig.prop)
                .valueChanges.subscribe((changes: any) => {
                  // TODO: add the changes needed
                });
            }
          }
        );
      }
    );
  }

  getStateOptions(index: number, stateProp: string): void {
    for (const control of this.config?.cards[index]?.formConfig) {
      if (control?.prop === stateProp) {
        control.options = stateOptions;
      }
    }
  }

  populateDropdownsFromApi(controlConfig): void {
    const apiConfigObject: any =
      this.config[createUserConstants.dropdownPropKey][controlConfig.prop];
    const dropdownEndpoint: string = apiConfigObject.apiEndpoint;
    const labelProp: string = apiConfigObject.labelProp;
    const valueProp: string = apiConfigObject.valueProp;
    const newOptions: FormControlOptionConfiguration[] = [];
    this.api.get(dropdownEndpoint).subscribe(
      (resp: any) => {
        resp.results?.forEach((item: any) => {
          newOptions.push({
            label: item[labelProp],
            value: item[valueProp],
          });
        });

        controlConfig.options = newOptions;
        const control = new UntypedFormControl('', []);
        const newFormControl = Object.assign(controlConfig, {
          control: control,
        });
        this.formControlService.addControlToFormGroup(
          this.newUserFormGroup,
          newFormControl
        );
        this.newUserFormGroup
          .get(controlConfig.prop)
          .valueChanges.subscribe(() => {
            // TODO: do the necessary action
          });
      },
      (err: any) => {
        this.cantPost = true;
        if (err.error.errors[0].user_error) {
          this.postMessage = err.error.errors[0].user_error;
        }
      }
    );
  }

  nextButtonClick(): void {
    const inValidControls = this.config.cards[
      `${this.index}`
    ].formConfig.filter(
      (control: AllControlsConfiguration) =>
        control?.validators?.includes(FormControlConstant.required) &&
        this.newUserFormGroup.controls[`${control.prop}`]?.status ===
          FormControlConstant.invalid
    );

    inValidControls.forEach((control: AllControlsConfiguration) =>
      this.newUserFormGroup.controls[`${control?.prop}`].markAsTouched()
    );

    if (inValidControls.length == 0) this.index = this.index + 1;
    if (this.index === this.lengthOfCard - 1) {
      // To add state options from constant file
      this.getStateOptions(this.index, createUserConstants.practiceStateProp);
    }
  }

  submitButtonClick(): void {
    const submitObject = __.cloneDeep(this.newUserFormGroup.value);
    const userDetailEndpoint = `${this.config.submitAPI}${submitObject?.email_address}`;

    if (this.newUserFormGroup.valid) {
      if (this.access_id) {
        submitObject[createUserConstants.accessRequestId] = Number(
          this.access_id
        );
      }
      if (this.config.idp === createUserConstants.zipari) {
        submitObject[createUserConstants.userName] =
          this.newUserFormGroup.value.email_address;
      }

      if (
        this.isMultipleGroupsSelectionEnabled &&
        this.newUserFormGroup.value?.multiple_group
      ) {
        submitObject[createUserConstants.multipleGroup] =
          this.newUserFormGroup.value.multiple_group.map(
            (item: object) => item[createUserConstants.row]?.id
          );
      }

      const requestAccessPayload: CreateUserConfig =
        this.requestPayloadFormatService.getCreateUserPayload(
          submitObject,
          this.globalConfig?.toggleFeatures?.enableMultiGroupManagement,
          this.authConfig?.idp
        );
      this.api.post(this.config.submitAPI, requestAccessPayload).subscribe(
        (resp: any) => {
          /*
           * Changing idp_id to target taken from config. Will need to add this to BH config
           */
          this.router.navigate(
            [
              `provider-portal/${this.role}/user-provisioning/edit/${
                resp[this.config.targetRespValue]
              }`,
            ],
            {
              state: { id: this.access_id },
            }
          );
          this.cxCaptureHandler(submitObject);
        },
        (err: any) => {
          if (
            err.error?.errors[0]?.user_error.includes(
              createUserConstants.emailAddress
            )
          ) {
            this.cantPost = true;
            this.postMessage =
              this.config.errorMessage || err.error?.errors[0]?.user_error;
            this.getUserDetails(userDetailEndpoint);
          } else {
            this.displayModalAndErrorMessage(err.error?.errors[0]?.user_error);
          }
        }
      );
    } else {
      const controls = this.newUserFormGroup.controls;
      Object.keys(controls).forEach((c: any) => controls[c].markAsTouched());
    }
  }

  errorModalSubmitClicked(): void {
    this.showErrorModal = false;
  }

  updateExistingUserGroups(): void {
    if (this.isMultipleGroupsSelectionEnabled) {
      let payload = {};
      let groups: { id: string | number }[] = [];
      const endpoint = `${this.config.submitAPI}${this.existingUserID}/`;

      if (this.newUserFormGroup.value?.multiple_group) {
        groups = this.initialGroups?.concat(
          this.newUserFormGroup.value.multiple_group.map((group: object) => ({
            id: group[createUserConstants.row]?.id,
          }))
        );
        groups = groups?.filter(
          (group: Group, index: number) =>
            groups?.findIndex((groupItem) => groupItem.id === group.id) ===
            index
        );
      }

      payload = {
        ...payload,
        groups: groups,
        status: 'Active',
      };

      this.createUserHttpService
        .updateUserDetails(endpoint, payload)
        .pipe(
          takeUntil(this.destroy$),
          tap((resp: CreateUserConfig) => {
            const provisioningUrl = `provider-portal/${
              this.role
            }/user-provisioning/edit/${resp[this.config.targetRespValue]}`;
            this.cancelModal();
            this.router.navigate([provisioningUrl], {
              state: { id: this.access_id },
            });
          }),
          catchError((error) => {
            this.displayModalAndErrorMessage(
              error.error?.errors[0]?.user_error
            );

            return of(null);
          })
        )
        .subscribe();
    } else {
      this.cancelModal();
    }
  }

  displayModalAndErrorMessage(errorMessage: string): void {
    this.showErrorModal = true;
    this.errorMessage = errorMessage;
  }

  getUserDetails(userDetailEndpoint: string): void {
    this.createUserHttpService
      .getUserDetailsByEmail(userDetailEndpoint)
      .pipe(
        takeUntil(this.destroy$),
        tap((user: ManageAccessCreateUser) => {
          this.existingUserID = user.id;
          this.initialGroups = user.groups.map((groupItem: Group) => ({
            id: groupItem.id,
          }));
        }),
        catchError((error) => {
          this.displayModalAndErrorMessage(error.error?.errors[0]?.user_error);

          return of(null);
        })
      )
      .subscribe();
  }

  cxCaptureHandler(submitObject): void {
    let cx_event_key = '';
    const attributes = {};

    switch (submitObject.role) {
      case createUserConstants.providerAdmin:
        cx_event_key = CX_CALLS.pp_provider_admin_created.event_key;
        attributes[createUserConstants.role] = submitObject.role;
        attributes[createUserConstants.jobTitle] = submitObject.job_title;
        this.analyticsService.sendEvent(cx_event_key, attributes);

        break;
      case createUserConstants.officeStaff:
        cx_event_key = CX_CALLS.pp_providerofficestaff_created.event_key;
        attributes[createUserConstants.role] = submitObject.role;
        attributes[createUserConstants.jobTitle] = submitObject.job_title;
        this.analyticsService.sendEvent(cx_event_key, attributes);
    }
  }

  cancelButtonClick(): void {
    this.access_id
      ? this.router.navigate([
          `provider-portal/${this.role}/manage-access-request-detail/${this.access_id}`,
        ])
      : this.router.navigate([
          `provider-portal/${this.role}/manage-portal-users/`,
        ]);
  }

  cancelModal(): void {
    this.cantPost = false;
  }

  prevStep(): void {
    this.index = this.index - 1;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  private populateTaxId(property: string): void {
    const taxIdsForReplacement = getObjectProperty(
      this.manageAccessUser,
      property
    );
    const { practiceTaxId, accessRequested } = createUserConstants;

    if (taxIdsForReplacement?.length) {
      const taxIds =
        property === accessRequested
          ? taxIdsForReplacement.map(
              (replaceTaxId: AccessRequested) => replaceTaxId.tax_id
            )
          : taxIdsForReplacement;
      this.newUserFormGroup.get(practiceTaxId)?.setValue(taxIds.toString());
    }
  }
}
