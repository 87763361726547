import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { saveAs } from 'file-saver';
import { ClaimSubmissionReviewAttributes } from '../../claim-submission.model';
import { ClaimSubmissionService } from '../../claim-submission.service';
import { ClaimSubmissionDocumentData } from '../../submittedClaim';

@UntilDestroy()
@Component({
  selector: 'lib-supporting-documentation-review',
  templateUrl: './supporting-documentation-review.component.html',
  styleUrls: ['./supporting-documentation-review.component.scss'],
})
export class SupportingDocumentationReviewComponent {
  @Input() documentationConfig: ClaimSubmissionReviewAttributes;
  @Input() supportingDocumentationUploadedFiles;
  @Input() summaryPageDocumentUploadedFiles: ClaimSubmissionDocumentData[];
  @Input() isEditable = true;
  @Input() documentUploadUrl: string;
  @Output() editIconClickedValue: EventEmitter<void> = new EventEmitter<void>();

  constructor(private claimSubmissionService: ClaimSubmissionService) {}

  editIconClicked() {
    this.editIconClickedValue.emit();
  }

  get getSupportingDocumentContent(): boolean {
    return (
      this.documentationConfig?.supportingDocumentationContent &&
      (this.supportingDocumentationUploadedFiles.length > 0 ||
        this.summaryPageDocumentUploadedFiles.length > 0)
    );
  }

  public downloadDocument(documentId: string, documentName: string): void {
    this.claimSubmissionService
      .downloadDocument(documentId, this.documentUploadUrl)
      .pipe(untilDestroyed(this))
      .subscribe(
        (document: Blob) => {
          saveAs(document, documentName);
        },
        (err: HttpErrorResponse) => err
      );
  }
}
