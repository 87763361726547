export const emailContactConfig = [
  {
    label: 'Customer Support',
    value: 'email@domain.com',
    format: 'EMAIL',
  },
];

export const brokerAssistanceConfigsFromConstantFile = [
  {
    label: 'Broker',
    value: '${first_name} ${last_name}',
  },
  {
    label: 'Id',
    value: '${broker_id}',
  },
  {
    label: 'Phone',
    value: '${phone}',
    format: 'PHONE',
  },
  {
    label: 'Email',
    value: '${email_address}',
    format: 'EMAIL',
  },
];

export const memberId = 'member_id';
