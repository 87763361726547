import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { CX_CALLS } from '../../../../shared/constants/cx-calls.constant';
import { ApiListResponse } from '../../../../shared/models/shared/ApiListResponse.model';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { APIService } from '../../../../shared/services/api.service';
import { AuthService } from '../../../../shared/services/auth.service';
import {
  getCurrentDate,
  getCurrentTime,
} from '../../../../shared/utilities/dates';
import SiteLocationAttestationEventService from './site-location-attestation.event.service';
import {
  CxEngagementResponse,
  Practitioner,
  SiteLocationAttestationParams,
  SiteLocationsAlertConfig,
  ReviewPractitionersConfig,
  UserInformation,
  SubmitPractitionerEventAttributesConfig,
  SubmitEventPractitionerDetails,
  AttestedSiteLocationDetail,
} from './site-locations-attestation.model';

@Injectable({
  providedIn: 'root',
})
export default class SiteAttestationDataService {
  private siteLocationAttestationEndpoint: string =
    '/api/provider-portal/update-tool/facility-locations/';
  private siteLocationPractitionersEndpoint: string =
    '/api/provider-portal/update-tool/facility-location-associated-practitioners/';
  private userInformationEndPoint: string = '/api/provider-portal/admin/users/';
  private locations: AttestedSiteLocationDetail;
  private practitioners: SubmitEventPractitionerDetails[] = [];
  private user: UserInformation = new UserInformation();
  constructor(
    private apiService: APIService,
    private authService: AuthService,
    private siteLocationAttestationEventService: SiteLocationAttestationEventService,
    private analyticsService: AnalyticsService,
    private router: Router
  ) {}

  submitSiteLocationAttestation(
    siteLocationAttestationParams: SiteLocationAttestationParams[]
  ): Observable<{
    locations: AttestedSiteLocationDetail;
    user: UserInformation;
  }> {
    return this.apiService
      .patch(
        this.siteLocationAttestationEndpoint,
        siteLocationAttestationParams
      )
      .pipe(
        tap((locations) => {
          this.locations = {
            name: [],
            npi: [],
            address1: [],
            address2: [],
            state: [],
            city: [],
            zipCode: [],
          };
          this.user.taxId = [];
          locations.updates.forEach((location) => {
            this.locations.name?.push(location.name);
            this.locations.npi?.push(location.npi);
            this.locations.address1?.push(location.address?.street_name_1);
            this.locations.address2?.push(location.address?.street_name_2);
            this.locations.state?.push(location.address?.state);
            this.locations.city?.push(location.address?.city_name);
            this.locations.zipCode?.push(location.address?.zip_code);
            this.user.taxId?.push(location.tax_id);
          });

          if (
            this.siteLocationAttestationEventService.totalSiteLocationCount <=
            this.locations.name.length
          ) {
            // all site has been attested;
            this.analyticsService.sendEvent(
              CX_CALLS.pp_providers_attested.event_key
            );
          }
        }),
        concatMap(() =>
          this.apiService.get(
            `${this.userInformationEndPoint}${this.authService.getIdp_id()}/`
          )
        ),
        tap((user) => {
          this.user.firstName = user.first_name;
          this.user.lastName = user.last_name;
          this.user.userName = user.user_name;
          this.user.emailAddress = user.email_address;
          this.user.dateSubmitted = new Date(
            getCurrentDate()
          ).toLocaleDateString('en-US');
          this.user.timeSubmitted = getCurrentTime();
        }),
        map(() => ({
          locations: this.locations,
          user: this.user,
        }))
      );
  }

  createTerminatePractitionerPayload(
    terminatePractitionersList: { row: Practitioner }[],
    terminateDetailsForm: UntypedFormGroup
  ): object {
    const facilityLocationProviders = [];
    terminatePractitionersList.forEach(
      (practitioner: { row: Practitioner }) => {
        const locationProvider = {
          termination_date: terminateDetailsForm.value.deactivation_date,
          facility_location_id: practitioner.row.facility_location_id,
          provider_id: practitioner.row.provider_id,
          last_updated_notes: terminateDetailsForm.value.notes,
          id: practitioner.row.id,
        };
        facilityLocationProviders.push(locationProvider);
      }
    );

    return {
      should_fail_on_error: false,
      operation_type: 'update',
      facility_location_providers: facilityLocationProviders,
    };
  }

  getCxEngagement(
    config: SiteLocationsAlertConfig
  ): Observable<ApiListResponse<CxEngagementResponse>> {
    return this.apiService
      .get(config?.endpoint)
      .pipe(map((response: ApiListResponse<CxEngagementResponse>) => response));
  }

  getPractitionersDetail(
    practitionerId: string,
    config: ReviewPractitionersConfig
  ) {
    return this.apiService.get(
      `${config.editPractitioner?.endpoint}${practitionerId}/`
    );
  }

  public getSiteLocationPractitioners(): Observable<SubmitPractitionerEventAttributesConfig> {
    return this.apiService
      .get(
        `${
          this.siteLocationPractitionersEndpoint
        }${this.setCurrentRouteId()}?is_terminated=false`
      )
      .pipe(
        tap((practitioners) => {
          this.practitioners = [];
          practitioners.results.forEach((practitioner: Practitioner) => {
            this.practitioners.push({
              practitioner: practitioner.provider_full_name,
              npi: practitioner.provider_npi,
            });
          });
        }),
        map(() => {
          const {
            first_name: firstName,
            last_name: lastName,
            user_name: userName,
            email_address: emailAddress,
          } = this.authService.user;

          return {
            practitioners: this.practitioners,
            user: {
              firstName,
              lastName,
              userName,
              emailAddress,
              dateSubmitted: new Date(getCurrentDate()).toLocaleDateString(
                'en-US'
              ),
              timeSubmitted: getCurrentTime(),
            },
          };
        })
      );
  }

  setCurrentRouteId(): string {
    let route = this.router.routerState.root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route?.snapshot?.params?.id;
  }
}
