import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';
import { ButtonModule } from '../../../notes/button/button.module';
import { FormControlModule } from '../../../notes/form-control/form-control.module';
import { IconModule } from '../../../notes/icon/icon.module';
import { StateAutoCompleteDirective } from '../../../shared/directives/state-autocomplete.directive';
import { FormGroupDirectivesModule } from '../../../shared/modules/grid-cell/form-group-directives.module';
import { FormattingService } from '../../../shared/services/formatting.service';
import { AutopayComponent } from './autopay/autopay.component';
import { BaseAddressDropdownComponent } from './base-address-dropdown.component';
import { BaseCVAComponent } from './base-cva.component';
import { BasicAddressComponent } from './basic-address/basic-address.component';
import { BrandColorComponent } from './brand-color/brand-color.component';
import { CitiesComponent } from './cities/cities.component';
import { CompanyAddressComponent } from './company-address/company-address.component';
import { CountiesComponent } from './counties/counties.component';
import { CustomFormElementComponent } from './custom-form-element.component';
import { DropdownOtherComponent } from './dropdown-other/dropdown-other.component';
import { FormArrayComponent } from './form-array/form-array.component';
import { HiddenComponent } from './hidden/hidden.component';
import { IndustriesComponent } from './industries/industries.component';
import { InputNaComponent } from './input-na/input-na.component';
import { OfficeHoursComponent } from './office-hours/office-hours.component';
import { OptionalDateComponent } from './optional-date/optional-date.component';
import { PasswordsComponent } from './passwords/passwords.component';
import { RichTextComponent } from './rich-text/rich-text.component';
import { SpeechRecognitionComponent } from './speech-recognition/speech-recognition.component';
import { SsnMultiFieldComponent } from './ssn-multi-field/ssn-multi-field.component';
import { SystemIconComponent } from './system-icon/system-icon.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { WhoseCoveredComponent } from './whose-covered/whose-covered.component';

@NgModule({
  declarations: [
    AutopayComponent,
    BaseCVAComponent,
    BasicAddressComponent,
    BaseAddressDropdownComponent,
    BrandColorComponent,
    CitiesComponent,
    CountiesComponent,
    CompanyAddressComponent,
    CustomFormElementComponent,
    DropdownOtherComponent,
    FormArrayComponent,
    HiddenComponent,
    IndustriesComponent,
    InputNaComponent,
    OfficeHoursComponent,
    OptionalDateComponent,
    PasswordsComponent,
    RichTextComponent,
    SsnMultiFieldComponent,
    StateAutoCompleteDirective,
    SystemIconComponent,
    TermsAndConditionsComponent,
    WhoseCoveredComponent,
    SpeechRecognitionComponent,
  ],
  imports: [
    CommonModule,
    FormControlModule,
    ButtonModule,
    ReactiveFormsModule,
    FormGroupDirectivesModule,
    FormsModule,
    IconModule,
    QuillModule.forRoot(),
  ],
  providers: [FormattingService, DatePipe, CurrencyPipe],
  exports: [
    CustomFormElementComponent,
    OptionalDateComponent,
    BasicAddressComponent,
    BaseAddressDropdownComponent,
    BaseCVAComponent,
    BasicAddressComponent,
    BrandColorComponent,
    CitiesComponent,
    CountiesComponent,
    CompanyAddressComponent,
    CustomFormElementComponent,
    DropdownOtherComponent,
    FormArrayComponent,
    HiddenComponent,
    IndustriesComponent,
    InputNaComponent,
    OfficeHoursComponent,
    OptionalDateComponent,
    PasswordsComponent,
    RichTextComponent,
    SsnMultiFieldComponent,
    StateAutoCompleteDirective,
    SystemIconComponent,
    TermsAndConditionsComponent,
    WhoseCoveredComponent,
    SpeechRecognitionComponent,
  ],
})
export class CustomFormElementModule {}
