import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DatePickerService {
  maxDate: BehaviorSubject<Date> = new BehaviorSubject(null);
  maxDate$: Observable<Date> = this.maxDate.asObservable();
  minDate: BehaviorSubject<Date> = new BehaviorSubject(null);
  minDate$: Observable<Date> = this.minDate.asObservable();

  setMinDate(date: Date) {
    this.minDate.next(date);
  }

  setMaxDate(date: Date) {
    this.maxDate.next(date);
  }

  checkDateType(date: Date | number | string, operator: 'max' | 'min') {
    if (!date) {
      return null;
    }

    const dateResult = new Date();
    let dateAsDate: Date;

    if (typeof date === 'number') {
      let dateAsNumber = date as number;

      if (operator === 'min') {
        dateAsNumber = -dateAsNumber;
      }

      dateAsDate = new Date(
        dateResult.setDate(dateResult.getDate() + dateAsNumber)
      );
    }

    if (typeof date == 'string') {
      dateAsDate = new Date(date);
    }

    if (date instanceof Date) {
      dateAsDate = date;
    }

    return dateAsDate;
  }
}
