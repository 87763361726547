/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { GLOBAL_URLS, PROVIDER_PORTAL_URLS } from '../app-urls.constant';
import { GUARDS } from '../shared/guards/guards.constant';
import { MasterGuard } from '../shared/guards/master-guard/master.guard';
import { AuthenticationComponent } from '../shared/modules/authentication/authentication.component';
import {
  ListComponent,
  MessagesComponent,
  NotificationsComponent,
} from '../shared/templates';
import { MessageComponent } from '../shared/templates/messages/message/message.component';
import { ProviderPortalComponent } from './provider-portal.component';
import { SitemapComponent } from './sitemap.component';
import {
  DashboardComponent,
  PortalUserDetailComponent,
  PortalUsersListComponent,
} from './templates';
import { AuthorizationSubmissionComponent } from './templates/authorization-submission/authorization-submission.component';
import { ClaimLineDetailComponent } from './templates/claim-line-detail/claim-line-detail.component';
import { ClaimSubmissionComponent } from './templates/claim-submission/claim-submission.component';
import { DefaultDashboardComponent } from './templates/default-dashboard/default-dashboard.component';
import { ManageAccessRequestDetailComponent } from './templates/manage-access-request-detail/manage-access-request-detail.component';
import { ManageAccessRequestsComponent } from './templates/manage-access-requests/manage-access-requests.component';
import { ManagePortalUsersCreateUserComponent } from './templates/manage-portal-users-create-user/manage-portal-users-create-user.component';
import { ManagePortalUsersDetailComponent } from './templates/manage-portal-users-detail/manage-portal-users-detail.component';
import { ManagePortalUsersComponent } from './templates/manage-portal-users/manage-portal-users.component';
import { NewDashboardComponent } from './templates/new-dashboard/new-dashboard.component';
import { PregnancyRiskAssesmentComponent } from './templates/pregnancy-risk-assesment/pregnancy-risk-assesment.component';
import { ProviderGroupCreateComponent } from './templates/provider-group-create/provider-group-create.component';
import { ProviderGroupDetailComponent } from './templates/provider-group-detail/provider-group-detail.component';
import { ProviderGroupsComponent } from './templates/provider-groups/provider-groups.component';
import { ManagePractitionerAddComponent } from './templates/provider-update-tool/practitioners/add-new/manage-practitioner-add.component';
import { ManagePractitionerDetailComponent } from './templates/provider-update-tool/practitioners/details/manage-practitioner-detail.component';
import { ManagePractitionerListComponent } from './templates/provider-update-tool/practitioners/list/manage-practitioner-list.component';
import { ManageSiteLocationAddComponent } from './templates/provider-update-tool/site-locations/add-new/manage-site-location-add.component';
import { ManageSiteLocationDetailComponent } from './templates/provider-update-tool/site-locations/details/manage-site-location-detail.component';
import { ManageSiteLocationListComponent } from './templates/provider-update-tool/site-locations/list/manage-site-location-list.component';
import { ReferralSubmissionComponent } from './templates/referral-submission/referral-submission.component';
import { TransferOfCareDetailComponent } from './templates/transfer-of-care/transfer-of-care-detail.component';
import { EditUserProvisioningComponent } from './templates/user-provisioning/edit/edit-user-provisioning.component';
import { EditAuthorizationComponent } from './templates/edit-authorization/edit-authorization.component';
import { SuspendUserComponent } from './templates/manage-portal-users-detail/components/suspend-user/suspend-user.component';
import { ClaimReconsiderationComponent } from './templates/claim-appeal/claim-reconsideration/claim-reconsideration.component';
import { ActivateUserComponent } from './templates/manage-portal-users-detail/components/activate-user/activate-user.component';
import { ClaimRedeterminationComponent } from './templates/claim-appeal/claim-redetermination/claim-redetermination.component';

const routes: Routes = [
  {
    path: '',
    component: ProviderPortalComponent,
    canActivate: [MasterGuard],
    data: {
      trigger: {
        operator: 'OR',
        guards: [GUARDS.PAYERADMINGUARD, GUARDS.PROVIDERADMINGUARD],
      },
    },
    children: [
      {
        path: PROVIDER_PORTAL_URLS.URLS.AUTH,
        component: AuthenticationComponent,
        data: { page: 'login' },
      },
      {
        path: PROVIDER_PORTAL_URLS.URLS.REGISTER,
        component: AuthenticationComponent,
        data: { page: 'register', queryParams: 'token' },
      },
      {
        path: PROVIDER_PORTAL_URLS.URLS.FORGOT_PASSWORD,
        component: AuthenticationComponent,
        data: { page: 'forgot-password' },
      },
      {
        path: PROVIDER_PORTAL_URLS.URLS.RESET_PASSWORD,
        component: AuthenticationComponent,
        data: { page: 'reset-password' },
      },
      {
        path: PROVIDER_PORTAL_URLS.URLS.OAUTH,
        component: OktaCallbackComponent,
      },
      {
        path: PROVIDER_PORTAL_URLS.URLS.REQUESTACCESS,
        component: AuthenticationComponent,
        data: { page: 'request-access' },
      },
      {
        path: PROVIDER_PORTAL_URLS.URLS.TERMSANDCONDITIONS,
        component: AuthenticationComponent,
        data: { page: 'terms-and-conditions' },
      },
      {
        path: PROVIDER_PORTAL_URLS.URLS.SITEMAP,
        component: SitemapComponent,
      },
    ],
  },
  {
    path: 'provider-portal',
    component: ProviderPortalComponent,
    canActivate: [MasterGuard],
    data: {
      trigger: {
        operator: 'OR',
        guards: [GUARDS.PAYERADMINGUARD, GUARDS.PROVIDERADMINGUARD],
      },
    },
    children: [
      {
        path: GLOBAL_URLS.DASHBOARD,
        component: DefaultDashboardComponent,
      },
      {
        path: GLOBAL_URLS.OLD_DASHBOARD,
        component: DashboardComponent,
      },
      {
        path: GLOBAL_URLS.NEW_DASHBOARD,
        component: NewDashboardComponent,
      },
      {
        path: GLOBAL_URLS.AUTHORIZATIONS,
        component: ListComponent,
        data: {
          pageName: 'authorizations',
          useRole: true,
          event_key: 'pp_authorization-list_viewed',
        },
      },
      {
        path: `${GLOBAL_URLS.AUTHORIZATIONS}/:auth_id`,
        component: TransferOfCareDetailComponent,
        data: {
          pageName: 'authorization_detail',
          event_key: 'pp_authorization-details_viewed',
        },
      },
      {
        path: `${GLOBAL_URLS.AUTHORIZATIONS}/:auth_id/edit-auth`,
        component: EditAuthorizationComponent,
      },
      {
        path: PROVIDER_PORTAL_URLS.URLS.REFERRALS,
        component: ListComponent,
        data: { pageName: 'referrals', event_key: 'pp_referrals-list_viewed' },
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.REFERRALS}/:referral_id`,
        component: TransferOfCareDetailComponent,
        data: {
          pageName: 'referral_detail',
          event_key: 'pp_referral-details_viewed',
        },
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.REFERRALS}`,
        component: ListComponent,
        data: { pageName: 'referrals', useRole: true },
      },
      {
        path: GLOBAL_URLS.CLAIMS,
        component: ListComponent,
        data: { pageName: 'claims', useRole: true },
      },
      {
        path: GLOBAL_URLS.BENEFITS,
        component: ListComponent,
        data: { pageName: 'benefits', useRole: true },
      },
      {
        path: `${GLOBAL_URLS.CLAIMS}/:claim_number`,
        loadChildren: () =>
          import('./templates/claim-detail/claim-detail.module').then(
            (m: any) => m.ClaimDetailModule
          ),
      },
      {
        path: `${GLOBAL_URLS.CLAIMS}/:claim_id/claim-line/:claim_line`,
        component: ClaimLineDetailComponent,
      },
      {
        path: PROVIDER_PORTAL_URLS.URLS.PATIENTS,
        component: ListComponent,
        data: { pageName: 'patients', useRole: true },
      },
      {
        path: PROVIDER_PORTAL_URLS.URLS.ELIGIBILITY,
        component: ListComponent,
        data: { pageName: 'patients', useRole: true },
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.CLAIMS}/:claim_number/${PROVIDER_PORTAL_URLS.URLS.CLAIM_RECONSIDERATION.BASE}`,
        component: ClaimReconsiderationComponent,
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.CLAIMS}/:claim_number/${PROVIDER_PORTAL_URLS.URLS.CLAIM_REDETERMINATION.BASE}`,
        component: ClaimRedeterminationComponent,
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.PATIENTS}/:id/claims/:claim_number`,
        loadChildren: () =>
          import('./templates/claim-detail/claim-detail.module').then(
            (m: any) => m.ClaimDetailModule
          ),
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.PATIENTS}/:id/claims/:claim_id/claim-line/:claim_line`,
        component: ClaimLineDetailComponent,
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.PATIENTS}/:id/authorizations/:auth_id`,
        component: TransferOfCareDetailComponent,
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.PATIENTS}/:id/referrals/:referral_id`,
        component: TransferOfCareDetailComponent,
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.PATIENTS}/:id/${PROVIDER_PORTAL_URLS.URLS.CREATE_REFERRAL.BASE}`,
        component: ReferralSubmissionComponent,
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.PATIENTS}/:id/${PROVIDER_PORTAL_URLS.URLS.CREATE_AUTHORIZATION.BASE}`,
        component: AuthorizationSubmissionComponent,
        data: { pageName: 'create-authorization' },
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.PATIENTS}/:id/${PROVIDER_PORTAL_URLS.URLS.CREATE_CLAIM.BASE}`,
        component: ClaimSubmissionComponent,
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.PATIENTS}/:id/${PROVIDER_PORTAL_URLS.URLS.PREGNANCY_RISK_ASSESSMENT.BASE}`,
        component: PregnancyRiskAssesmentComponent,
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.PATIENTS}/:id`,
        loadChildren: () =>
          import('./templates/patient-detail/patient-detail.module').then(
            (m: any) => m.PatientDetailModule
          ),
      },
      {
        path: PROVIDER_PORTAL_URLS.URLS.PORTAL_USERS,
        component: PortalUsersListComponent,
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.PORTAL_USERS}/:username`,
        component: PortalUserDetailComponent,
      },
      {
        path: PROVIDER_PORTAL_URLS.URLS.PRACTITIONERS.BASE,
        component: ManagePractitionerListComponent,
      },
      {
        path: PROVIDER_PORTAL_URLS.URLS.SITE_LOCATIONS.BASE,
        component: ManageSiteLocationListComponent,
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.PRACTITIONERS.BASE}/create`,
        component: ManagePractitionerAddComponent,
        children: [
          {
            path: '**',
            redirectTo: PROVIDER_PORTAL_URLS.URLS.PRACTITIONERS.BASE,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.SITE_LOCATIONS.BASE}/create`,
        component: ManageSiteLocationAddComponent,
        children: [
          {
            path: '**',
            redirectTo: PROVIDER_PORTAL_URLS.URLS.SITE_LOCATIONS.BASE,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.PRACTITIONERS.BASE}/detail/:id`,
        component: ManagePractitionerDetailComponent,
        children: [
          {
            path: '**',
            redirectTo: PROVIDER_PORTAL_URLS.URLS.PRACTITIONERS.BASE,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.SITE_LOCATIONS.BASE}/detail/:id`,
        component: ManageSiteLocationDetailComponent,
        children: [
          {
            path: '**',
            redirectTo: PROVIDER_PORTAL_URLS.URLS.SITE_LOCATIONS.BASE,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.PROVISIONING.BASE}/${PROVIDER_PORTAL_URLS.URLS.PROVISIONING.EDIT}/:id`,
        component: EditUserProvisioningComponent,
        data: { pageName: 'edit-user-provisioning' },
      },
      {
        path: GLOBAL_URLS.DOCUMENTS,
        loadChildren: () =>
          import(
            './templates/document-center-wrapper/document-center-wrapper.module'
          ).then((m: any) => m.DocumentCenterWrapperModule),
      },
      {
        path: GLOBAL_URLS.SETTINGS,
        component: ManagePortalUsersDetailComponent,
        data: { pageName: 'user-profile' },
      },
      {
        path: GLOBAL_URLS.MESSAGES,
        component: MessagesComponent,
        data: { pageName: 'messages', useRole: true },
      },
      {
        path: `${GLOBAL_URLS.MESSAGES}/:id`,
        component: MessageComponent,
        data: { pageName: 'message', useRole: true },
      },
      {
        path: GLOBAL_URLS.NOTIFICATIONS,
        component: NotificationsComponent,
        data: {
          pageName: 'notifications',
          event_key: 'pp_notification-center_viewed',
        },
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.BASE}`,
        children: [
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.DASHBOARD}`,
            component: DefaultDashboardComponent,
            data: { pageName: 'dashboard', role: 'payer-admin' },
          },
          {
            path: GLOBAL_URLS.OLD_DASHBOARD,
            component: DashboardComponent,
            data: { pageName: 'dashboard', role: 'payer-admin' },
          },
          {
            path: GLOBAL_URLS.NEW_DASHBOARD,
            component: NewDashboardComponent,
            data: { pageName: 'dashboard', role: 'payer-admin' },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.PROVISIONING.BASE}/${PROVIDER_PORTAL_URLS.URLS.PROVISIONING.EDIT}/:id`,
            component: EditUserProvisioningComponent,
            data: { pageName: 'edit-user-provisioning', role: 'payer-admin' },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.MANAGE_USERS}`,
            component: ListComponent,
            data: { pageName: 'manage-users', role: 'payer-admin' },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.MANAGE_ACCESS}`,
            component: ManageAccessRequestsComponent,
            data: {
              pageName: 'manage-access-requests',
              role: 'payer-admin',
              event_key: 'pp_manageaccess-list_viewed',
            },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.MANAGE_ACCESS_DETAIL}/:id`,
            component: ManageAccessRequestDetailComponent,
            data: {
              pageName: 'manage-access-request-detail',
              role: 'payer-admin',
            },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.MANAGE_ACCESS}/create-user/:access_id`,
            component: ManagePortalUsersCreateUserComponent,
            data: {
              manageAccess: 'true',
              pageName: 'manage-portal-users-create-user',
              role: 'payer-admin',
            },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.MANAGEPORTALUSERS}`,
            component: ManagePortalUsersComponent,
            data: {
              pageName: 'manage-portal-users',
              role: 'payer-admin',
              event_key: 'pp_manageportalusers-list_viewed',
            },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.MANAGEPORTALUSERS}/create-user`,
            component: ManagePortalUsersCreateUserComponent,
            data: {
              pageName: 'manage-portal-users-create-user',
              role: 'payer-admin',
            },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.MANAGEPORTALUSERS}/detail/:idp_id`,
            component: ManagePortalUsersDetailComponent,
            data: {
              pageName: 'manage-portal-users-detail',
              role: 'payer-admin',
            },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.MANAGEPORTALUSERS}/detail/:idp_id/${PROVIDER_PORTAL_URLS.URLS.SUSPEND_USER}`,
            component: SuspendUserComponent,
            data: { role: 'payer-admin' },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.MANAGEPORTALUSERS}/detail/:idp_id/${PROVIDER_PORTAL_URLS.URLS.ACTIVATE_USER}/:access_id`,
            component: ActivateUserComponent,
            data: { role: 'payer-admin' },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.MANAGEPORTALUSERS}/detail/:idp_id/${PROVIDER_PORTAL_URLS.URLS.ACTIVATE_USER}`,
            component: ActivateUserComponent,
            data: { role: 'provider-admin' },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.PROVISIONING.BASE}/${PROVIDER_PORTAL_URLS.URLS.PROVISIONING.EDIT}/:id`,
            component: EditUserProvisioningComponent,
            data: { pageName: 'edit-user-provisioning', role: 'payer-admin' },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.MANAGE_ACCESS}/create-user/provision-tax-ids/:id`,
            component: EditUserProvisioningComponent,
            data: { pageName: 'edit-user-provisioning', role: 'payer-admin' },
          },
          {
            path: PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.PRACTITIONERS.BASE,
            component: ManagePractitionerListComponent,
          },
          {
            path: PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.SITE_LOCATIONS.BASE,
            component: ManageSiteLocationListComponent,
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.PRACTITIONERS.BASE}/create`,
            component: ManagePractitionerAddComponent,
            children: [
              {
                path: '**',
                redirectTo:
                  PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.PRACTITIONERS.BASE,
                pathMatch: 'full',
              },
            ],
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.SITE_LOCATIONS.BASE}/create`,
            component: ManageSiteLocationAddComponent,
            children: [
              {
                path: '**',
                redirectTo:
                  PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.SITE_LOCATIONS.BASE,
                pathMatch: 'full',
              },
            ],
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.PRACTITIONERS.BASE}/detail/:id`,
            component: ManagePractitionerDetailComponent,
            children: [
              {
                path: '**',
                redirectTo:
                  PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.PRACTITIONERS.BASE,
                pathMatch: 'full',
              },
            ],
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.SITE_LOCATIONS.BASE}/detail/:id`,
            component: ManageSiteLocationDetailComponent,
            children: [
              {
                path: '**',
                redirectTo:
                  PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.SITE_LOCATIONS.BASE,
                pathMatch: 'full',
              },
            ],
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.PROVIDER_GROUPS}`,
            component: ProviderGroupsComponent,
            data: { pageName: 'provider-groups', role: 'payer-admin' },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.PROVIDER_GROUPS}/create-group`,
            component: ProviderGroupCreateComponent,
            data: { pageName: 'provider-group-create' },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.PROVIDER_GROUPS}/:id`,
            component: ProviderGroupDetailComponent,
            data: { pageName: 'provider-groups-detail' },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PAYERADMIN.PROVIDER_ADMINS}`,
            loadChildren: () =>
              import('./templates/provider-admins/provider-admins.module').then(
                (m: any) => m.ProviderAdminsModule
              ),
          },
        ],
      },
      {
        path: `${PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.BASE}`,
        children: [
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.DASHBOARD}`,
            component: DefaultDashboardComponent,
            data: { pageName: 'dashboard', role: 'provider-admin' },
          },
          {
            path: GLOBAL_URLS.OLD_DASHBOARD,
            component: DashboardComponent,
            data: { pageName: 'dashboard', role: 'provider-admin' },
          },
          {
            path: GLOBAL_URLS.NEW_DASHBOARD,
            component: NewDashboardComponent,
            data: { pageName: 'dashboard', role: 'provider-admin' },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.MANAGEPORTALUSERS}`,
            component: ManagePortalUsersComponent,
            data: {
              pageName: 'manage-portal-users',
              role: 'provider-admin',
              event_key: 'pp_manageportalusers-list_viewed',
            },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.MANAGEPORTALUSERS}/create-user`,
            component: ManagePortalUsersCreateUserComponent,
            data: {
              pageName: 'manage-portal-users-create-user',
              role: 'provider-admin',
            },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.MANAGEPORTALUSERS}/detail/:idp_id`,
            component: ManagePortalUsersDetailComponent,
            data: {
              pageName: 'manage-portal-users-detail',
              role: 'provider-admin',
            },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.MANAGEPORTALUSERS}/detail/:idp_id/${PROVIDER_PORTAL_URLS.URLS.SUSPEND_USER}`,
            component: SuspendUserComponent,
            data: { role: 'provider-admin' },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.PROVISIONING.BASE}/${PROVIDER_PORTAL_URLS.URLS.PROVISIONING.EDIT}/:id`,
            component: EditUserProvisioningComponent,
            data: {
              pageName: 'edit-user-provisioning',
              role: 'provider-admin',
            },
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.SETTINGS}`,
            component: ManagePortalUsersDetailComponent,
          },
          {
            path: PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.PRACTITIONERS.BASE,
            component: ManagePractitionerListComponent,
          },
          {
            path: PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.SITE_LOCATIONS.BASE,
            component: ManageSiteLocationListComponent,
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.SITE_LOCATIONS.ATTESTATION}`,

            loadChildren: () =>
              import(
                './templates/provider-update-tool/site-locations-attestation/site-location-attestation.module'
              ).then((m: any) => m.SiteLocationAttestationModule),
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.PRACTITIONERS.BASE}/create`,
            component: ManagePractitionerAddComponent,
            children: [
              {
                path: '**',
                redirectTo:
                  PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.PRACTITIONERS.BASE,
                pathMatch: 'full',
              },
            ],
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.SITE_LOCATIONS.BASE}/create`,
            component: ManageSiteLocationAddComponent,
            children: [
              {
                path: '**',
                redirectTo:
                  PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.SITE_LOCATIONS.BASE,
                pathMatch: 'full',
              },
            ],
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.PRACTITIONERS.BASE}/detail/:id`,
            component: ManagePractitionerDetailComponent,
            children: [
              {
                path: '**',
                redirectTo:
                  PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.PRACTITIONERS.BASE,
                pathMatch: 'full',
              },
            ],
          },
          {
            path: `${PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.SITE_LOCATIONS.BASE}/detail/:id`,
            component: ManageSiteLocationDetailComponent,
            children: [
              {
                path: '**',
                redirectTo:
                  PROVIDER_PORTAL_URLS.URLS.PROVIDERADMIN.SITE_LOCATIONS.BASE,
                pathMatch: 'full',
              },
            ],
          },
        ],
      },
      {
        path: '**',
        redirectTo: GLOBAL_URLS.DASHBOARD,
        pathMatch: 'prefix',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProviderPortalRoutingModule {}
