import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import { SpinnerComponent } from './spinner.component';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  overlayRef: OverlayRef;

  constructor(private overlay: Overlay) {}

  addSpinner() {
    // Use CDK to attach spinner to page
    this.overlayRef = this.overlay.create({});
    const spinnerComponent = new ComponentPortal(SpinnerComponent);
    this.overlayRef.attach(spinnerComponent);
  }

  removeSpinner() {
    this.overlayRef.detach();
  }
}
