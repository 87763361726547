import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlTextModule } from '../text/text.module';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { ControlDateRangeComponent } from './date-range.component';

@NgModule({
  declarations: [ControlDateRangeComponent],
  imports: [CommonModule, DatePickerModule, ControlTextModule],
  exports: [ControlDateRangeComponent],
})
export class ControlDateRangeModule {}
