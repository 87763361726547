import { Injectable } from '@angular/core';
import { isAndroidDevice } from '../utils';

// return the global native browser window object
const windowRef = (): Window & typeof globalThis => window;

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  get nativeWindow(): Window {
    return windowRef();
  }

  postMessageForMobileDevices(message: string): void {
    const messageHandlers = (this.nativeWindow as unknown as any)?.webkit
      ?.messageHandlers;

    console.log('nativeWindow.webkit.messageHandlers', messageHandlers);
    if (messageHandlers?.iosMessageHandler) {
      console.log('messageHandlers.iosMessageHandlers.postMessage', message);
      messageHandlers.iosMessageHandler.postMessage(message);
    } else if (isAndroidDevice()) {
      if (messageHandlers?.androidMessageHandler) {
        console.log(
          'messageHandlers.androidMessageHandler.postMessage',
          message
        );
        messageHandlers.androidMessageHandler.postMessage(message);
      } else {
        console.log('messageHandlers.messageHandler.postMessage', message);
        messageHandlers?.messageHandler?.postMessage(message);
      }
    } else if (messageHandlers?.jsHandlers) {
      console.log('messageHandlers?.jsHandlers.postMessage', message);
      messageHandlers?.jsHandlers.postMessage(message);
    } else {
      console.log('nativeWindow.postMessage', message);
      this.nativeWindow.postMessage(message);
    }
  }
}
