import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ControlSwitchComponent } from './switch.component';

@NgModule({
  declarations: [ControlSwitchComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [ControlSwitchComponent],
})
export class ControlSwitchModule {}
