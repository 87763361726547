import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DigitalDataModule } from '../../../shared/modules/digital-data/digital-data.module';

import { IconModule } from '../../icon/icon.module';
import { ButtonModule } from '../button.module';
import { ButtonSearchComponent } from './button-search.component';

@NgModule({
  declarations: [ButtonSearchComponent],
  imports: [CommonModule, IconModule, DigitalDataModule, ButtonModule],
  exports: [ButtonSearchComponent],
})
export class ButtonSearchModule {}
