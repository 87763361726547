import { Component, OnInit } from '@angular/core';
import { getValue } from '@zipari/web-utils';

import ClaimDetail from '../../../../shared/models/shared/ClaimDetail.model';
import Claim from '../../../../shared/models/shared/Claim.model';
import { OutNetworkStatus } from '../claim-detail.constant';

import { ClaimDetailService } from '../claim-detail.service';
import { OverviewTabConfig } from '../ClaimDetailConfig.model';

@Component({
  selector: 'overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  config: OverviewTabConfig;
  claim: Claim;

  constructor(private claimDetailService: ClaimDetailService) {}

  ngOnInit(): void {
    this.config = this.claimDetailService.tabsConfig;
    this.claim = this.claimDetailService.claim;
  }

  showOutOfNetworkBadge(status: string, context: ClaimDetail): boolean {
    const claimDetailsConfig = this.claimDetailService.config;

    return (
      !!claimDetailsConfig?.outOfNetwork?.isOutOfNetworkEnabled &&
      getValue(context, status) === OutNetworkStatus
    );
  }
}
