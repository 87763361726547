import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule, FormControlModule } from '@zipari/design-system';
import { TableModule } from '../../../shared/modules';
import { EligibilityUsersListComponent } from './list/eligibility-users-list.component';

@NgModule({
  declarations: [EligibilityUsersListComponent],
  imports: [CommonModule, FormControlModule, ButtonModule, TableModule],
})
export class EligibilityUsersModule {}
