/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-misused-new */
// @ts-ignore

import { DigitalData } from '@zipari/shared-ds-util-digital-data';

// Spec: https://www.w3.org/TR/css-font-loading/
export {};

declare global {
  var CONFIG: any;
  // const FontFace: FontFace;

  interface Document {
    fonts: FontFaceSet;
  }

  interface Window {
    digitalData: DigitalData;
    // FontFace: FontFace; // Commented out because posthog-js also declares this.
  }

  type CSSOMString = 'auto' | 'block' | 'fallback' | 'optional' | 'swap';
  type FontFaceLoadStatusZip = 'unloaded' | 'loading' | 'loaded' | 'error';
  type FontFaceSetStatus = 'loading' | 'loaded';

  interface FontFaceDescriptors {
    family: CSSOMString;
    style?: string;
    weight?: string;
    stretch?: string;
    unicodeRange?: string;
    variant?: string;
    featureSettings?: string;
  }

  interface FontFace extends FontFaceDescriptors {
    readonly status: FontFaceLoadStatusZip;
    readonly loaded: Promise<FontFace>;
    // @ts-ignore-next-line
    variationSettings: CSSOMString;
    // @ts-ignore-next-line
    display: CSSOMString;
    new (
      family: string,
      source: string | ArrayBuffer,
      descriptors?: FontFaceDescriptors
    ): FontFace;
    load(): Promise<FontFace>;
  }

  interface FontFaceSet extends Iterable<FontFace> {
    readonly status: FontFaceSetStatus;
    readonly ready: Promise<FontFaceSet>;
    add(font: FontFace): void;
    check(font: string, text?: string): Boolean; // throws exception
    load(font: string, text?: string): Promise<FontFace[]>;
    delete(font: FontFace): void;
    clear(): void;
  }
}

window.digitalData = window.digitalData || {
  event: [] as any,
  page: {} as any,
  member: {} as any,
};
