import { validMasks } from '@zipari/shared-ds-util-format';
import { Condition } from './business-rules.model';

export enum nonLabelPositions {
  topRight = 'topRight',
  bottomRight = 'bottomRight',
  bottomLeft = 'bottomLeft',
}

export enum controlTypes {
  description = 'description',
  fill = 'fill',
  checkbox = 'checkbox',
  color = 'color',
  date = 'date',
  datePicker = 'datePicker',
  dateRange = 'dateRange',
  timeRange = 'timeRange',
  dropdown = 'dropdown',
  month = 'month',
  number = 'number',
  password = 'password',
  radio = 'radio',
  singleCheckbox = 'singleCheckbox',
  states = 'states',
  switch = 'switch',
  text = 'text',
  textarea = 'textarea',
  tel = 'tel',
  time = 'time',
  toggle = 'toggle',
  typeahead = 'typeahead',
  group = 'group',
  document = 'document',
  hidden = 'hidden',
  optionalDate = 'optionalDate',
}

export type AllControlsConfiguration =
  | CheckboxConfiguration
  | DropdownConfiguration
  | DatePickerConfiguration
  | PasswordConfiguration
  | RadioConfiguration
  | SwitchConfiguration
  | TextConfiguration
  | TextAreaConfiguration
  | TelConfiguration
  | TimeConfiguration
  | ToggleConfiguration
  | TypeaheadConfiguration;

export enum formControlDirections {
  default = 'default',
  filter = 'filter',
  search = 'search',
  row = 'row',
  action = 'action',
  grouping = 'grouping',
  'row-even' = 'row-even',
}

export type textAlignTypes = 'left' | 'right' | 'center';

export class FormControlConfiguration {
  ariaLabel?: string;
  controls?: FormControlConfiguration[]; // group types can have nested controls
  customCSSClass?: string;
  defaultValue?: string;
  default?: string;
  description?: string;
  fill?: string;
  encodeURI?: boolean;
  endpoint?: string;
  endpointTemplate?: {
    label: string;
    value: string;
  };
  excludeOn?: string;
  focus?: any;
  focusDisplay?: any; // TODO: move to PasswordConfiguration
  grid?: string;
  groupValidators?: any[];
  hint?: string;
  hintPosition?: nonLabelPositions;
  icon?: string;
  iconRight?: string; // DEPRECATED: use `icon` + `isIconRight` boolean
  id?: string;
  info?: {
    title: string;
    description: string;
    image?: string;
  };
  isDisabled?: boolean;
  isIconRight?: boolean;
  isLabelOneLine?: boolean;
  isRequiredRedAsterisk?: boolean;
  label?: string;
  link?: string;
  linkPosition?: nonLabelPositions;
  mask?: validMasks | string;
  fallbackMask?: validMasks | string;
  /**
   * @deprecated  Use a number value instead of Date and String
   */
  max?: number | Date | string;
  /**
   * @deprecated  Use a number value instead of Date and String
   */
  min?: number | Date | string;
  maxLength?: number;
  name?: string;
  placeholder?: string;
  prop!: string;
  readonly?: boolean;
  remainingCount?: number;
  requiredLabel?: any;
  scrollOptions?: ScrollOptions;
  tooltip?: string;
  tooltipLeftAlign?: boolean;
  type!: controlTypes;
  validators?: any[];
  conditions?: Condition[];
  options?: FormControlOptionConfiguration[];
  enableRequiredAsterisk?: boolean;
  enableOptionalLabel?: boolean;
  verification?: {
    icon: string;
    iconFill: string;
    verificationMessage: string;
    enabled?: boolean;
  };
  required?: boolean;
}

export class FormControlOptionConfiguration {
  ariaLabel?: string;
  isDisabled?: boolean;
  name?: string;
  label!: string;
  value!: boolean | number | string;
  icon?: string;
}

export class TypeaheadOptionConfiguration extends FormControlOptionConfiguration {
  tooltip?: string;
}

export class CheckboxOptionConfiguration extends FormControlOptionConfiguration {
  type?: 'other';
  placeholder?: any;
  isRequiredRedAsterisk?: boolean;
  multiLines?: { label: string }[];
  prop?: string;
}

export class CheckboxConfiguration extends FormControlConfiguration {
  override type = controlTypes.checkbox;
  inline?: boolean;
  override options!: CheckboxOptionConfiguration[];
  override isRequiredRedAsterisk?: boolean;
  isTopAligned?: boolean;
  isBold?: boolean;
}

export class DropdownConfiguration extends FormControlConfiguration {
  override type = controlTypes.dropdown;
  override options!: FormControlOptionConfiguration[];
  override isRequiredRedAsterisk?: boolean;

  /**
   * shows an option label as static text instead of a dropdown if there is only one option when set to true.
   */
  ifOneOptionShowStaticText?: boolean;

  /**
   * used to set text-align style value on the static text
   */
  alignStaticText?: textAlignTypes;

  hideIfOnlyOneOption?: boolean;
  /**
   * An option is created for the 'Placeholder' property in the dropdown config, this option
   * puts that created option as the last item in the dropdown.
   */
  putPlaceholderDefaultAsLastItem?: boolean;

  /**
   * This value determines if a placeholder should be used at all
   *
   * This is necessary as current behavior is that a default placeholder is used if none is provided
   */
  usePlaceholder?: boolean;

  /**
   *  Hides the select placeholder in scenarios where there is only only option
   */
  hidePlaceholderIfOneOption?: boolean;
  iconSize?: string;
}

export class DatePickerConfiguration extends FormControlConfiguration {
  override type = controlTypes.date;
  override default?: string;
  isLocked?: boolean;
  showIcon?: boolean;
  iconClass?: string;
}

export class PasswordConfiguration extends FormControlConfiguration {
  canToggle?: boolean;
  override max?: number | Date;
  override maxLength?: number;
  override type = controlTypes.text;
  override isRequiredRedAsterisk?: boolean;
}

export class RadioConfiguration extends FormControlConfiguration {
  alignment?: any;
  inline?: boolean;
  override options!: FormControlOptionConfiguration[];
  override type = controlTypes.radio;
  override isRequiredRedAsterisk?: boolean;
  isTopAligned?: boolean;
}

export class SwitchConfiguration extends FormControlConfiguration {
  override type = controlTypes.switch;
  override isRequiredRedAsterisk?: boolean;
}

export class TextConfiguration extends FormControlConfiguration {
  override type = controlTypes.text;
  unit?: string;
  override isRequiredRedAsterisk?: boolean;
  autocomplete?: 'off' | 'name' | 'email' | string;
}

export class TextAreaConfiguration extends FormControlConfiguration {
  override type = controlTypes.textarea;
  resize?: boolean;
  override isRequiredRedAsterisk?: boolean;
}

export class TelConfiguration extends FormControlConfiguration {
  override type = controlTypes.tel;
  unit?: `string`;
  override isRequiredRedAsterisk?: boolean;
  override maxLength?: number;
  autocomplete?: 'off' | string;
}

export class TimeConfiguration extends FormControlConfiguration {
  override type = controlTypes.time;
  override isRequiredRedAsterisk?: boolean;
}

export class ToggleConfiguration extends FormControlConfiguration {
  override options!: FormControlOptionConfiguration[];
  strictAll?: boolean;
  strictIfRequired?: boolean;
  isFullWidth?: boolean;
  override type = controlTypes.toggle;
  override isRequiredRedAsterisk?: boolean;
}

export class TypeaheadConfiguration extends FormControlConfiguration {
  override options?: FormControlOptionConfiguration[];
  override type = controlTypes.typeahead;
  override endpoint?: string;
  override endpointTemplate?: any;
  endpointDebounce?: number;
  noResults?: string;
  requireSelect?: boolean;
  errors?: any;
  showOptionsOnOpen?: boolean;
  override isRequiredRedAsterisk?: boolean;
  /**
   * Endpoint for listing all options
   */
  customGetAllEndpoint?: string;
  /**
   * Render the drop caret in typeahead input when true.
   * Clicking it will make an api call and render list of all options available
   */
  canListAllOptions?: boolean;
  minCharToStartSearch?: number;
}

export const exampleCheckboxConfig: CheckboxConfiguration = {
  prop: 'testProp',
  type: controlTypes.checkbox,
  ariaLabel: 'Checkbox Label',
  label: 'Checkbox Label',
  options: [
    {
      label: 'Option 1',
      value: 'option1',
    },
    {
      label: 'Option 2',
      value: 'option2',
    },
  ],
};

export const exampleDropdownConfig: DropdownConfiguration = {
  prop: 'testProp',
  type: controlTypes.dropdown,
  ariaLabel: 'Dropdown Label',
  label: 'Dropdown Label',
  options: [
    {
      label: 'Option 1',
      value: 'option1',
    },
    {
      label: 'Option 2',
      value: 'option2',
    },
  ],
};

export const exampleRadioConfig: RadioConfiguration = {
  prop: 'testProp',
  type: controlTypes.radio,
  ariaLabel: 'Checkbox Label',
  label: 'Checkbox Label',
  options: [
    {
      label: 'Option 1',
      value: 'option1',
    },
    {
      label: 'Option 2',
      value: 'option2',
    },
  ],
};

export const exampleSwitchConfig: SwitchConfiguration = {
  prop: 'testProp',
  type: controlTypes.switch,
  label: 'Switch Label',
  ariaLabel: 'Switch Label',
};

export const exampleTextConfig: TextConfiguration = {
  prop: 'testProp',
  type: controlTypes.text,
  label: 'Text Label',
  ariaLabel: 'Text Label',
};

export const exampleTextareaConfig: TextAreaConfiguration = {
  prop: 'testProp',
  type: controlTypes.textarea,
  label: 'Textarea Label',
  ariaLabel: 'Textarea Label',
};

export const exampleTelConfig: TelConfiguration = {
  prop: 'testProp',
  type: controlTypes.tel,
  label: 'Tel Label',
  ariaLabel: 'Tel Label',
};

export const exampleTimeConfig: TimeConfiguration = {
  prop: 'testProp',
  type: controlTypes.time,
  label: 'Time Label',
  ariaLabel: 'Time Label',
};

export const exampleToggleConfig: ToggleConfiguration = {
  prop: 'testProp',
  type: controlTypes.toggle,
  ariaLabel: 'Toggle Label',
  label: 'Toggle Label',
  options: [
    {
      label: 'Option 1',
      value: 'option1',
    },
    {
      label: 'Option 2',
      value: 'option2',
    },
  ],
};

export const exampleTypeaheadConfig: TypeaheadConfiguration = {
  prop: 'testProp',
  type: controlTypes.typeahead,
  label: 'Typeahead Label',
  ariaLabel: 'Typeahead Label',
};

export const defaultDropdownPlaceholderText = 'Select One';
export const zipcode4Length = 4;
