import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  PractitionerDetails,
  SiteLocation,
} from '../../../../templates/provider-update-tool/site-locations-attestation/site-locations-attestation.model';
import {
  AddressLocationConfig,
  OutOfNetworkConfig,
  ProviderMultiLocationModalConfig,
  ProviderSelectionConfig,
  ProviderSelectionModalConfig,
} from '../../../../templates/authorization-submission/authorization-submission.model';

@Component({
  selector: 'provider-selection-popup',
  templateUrl: './provider-selection-popup.component.html',
  styleUrls: ['./provider-selection-popup.component.scss'],
})
export class ProviderSelectionPopupComponent {
  @Input() config: ProviderSelectionConfig;
  @Input() group: UntypedFormGroup;
  @Input() shouldCloseProviderSelectionPopup: boolean;
  @Input() providerSelectionModalConfig: ProviderSelectionModalConfig;
  @Input() providerMultiLocationModal: ProviderMultiLocationModalConfig;
  @Input() outOfNetwork: OutOfNetworkConfig;
  @Output() closeProviderSelectionPopup = new EventEmitter();
  @Output() selectedProvider = new EventEmitter();
  @Output() selectedAddress = new EventEmitter();
  @Output() isOutOfNetwork = new EventEmitter();

  shouldOpenProviderFlyout: boolean;
  shouldOpenFacilityFlyout: boolean;

  closeSelectionPopup(): void {
    this.closeProviderSelectionPopup.emit();
  }

  openFacilityFlyout(): void {
    this.shouldOpenFacilityFlyout = true;
  }

  openProviderFlyout(): void {
    this.shouldOpenProviderFlyout = true;
  }

  getSelectedProvider(
    provider: SiteLocation | PractitionerDetails | null
  ): void {
    this.selectedProvider.emit(provider);
  }

  closeProviderFlyout(): void {
    this.shouldOpenProviderFlyout = false;
    this.shouldOpenFacilityFlyout = false;
    this.closeSelectionPopup();
  }

  getSelectedAddress(address: AddressLocationConfig) {
    this.selectedAddress.emit(address);
  }

  getOutOfNetwork() {
    this.isOutOfNetwork.emit();
  }
}
