import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup } from '@angular/forms';
import { FormControlService } from '../../../../notes/form-control/form-control.service';
import { fade } from '../../../../shared/animations';
import { SpeechRecognitionService } from './speech-recognition.service';

@Component({
  selector: 'speech-recognition',
  templateUrl: './speech-recognition.component.html',
  styleUrls: [
    '../../../../notes/form-control/form-control.component.scss',
    './speech-recognition.component.scss',
  ],
  animations: [fade],
})
export class SpeechRecognitionComponent
  implements ControlValueAccessor, OnInit
{
  @Input() group: UntypedFormGroup;
  @Input() config;
  @Input() direction;

  public value: string;

  constructor(
    private formControlService: FormControlService,
    public speechRecognition: SpeechRecognitionService
  ) {
    this.speechRecognition.init();
  }

  propagateChange = (_: any) => {
    return;
  };

  onTouched = () => {
    return;
  };

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  writeValue(value: any): void {
    this.group.patchValue({
      [this.config.prop]: value,
    });
  }

  ngOnInit(): void {
    this.formControlService.addControlToFormGroup(this.group, this.config);
  }

  startSpeechRecognition(): void {
    this.speechRecognition.start();
  }

  stopSpeechRecognition(): void {
    this.speechRecognition.stop();

    if (this.speechRecognition.text) {
      this.writeValue(this.speechRecognition.text);
    }
  }
}
