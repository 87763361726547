import { Component, Input } from '@angular/core';

/*
    This component is the dumb version of the smart zip-busy.component
*/
@Component({
  selector: 'spinner',
  template: `
    <div
      class="loader__mask"
      [ngClass]="{ 'is-aligned-top': position === 'top' }"
    >
      <div class="loader"></div>
    </div>
  `,
})
export class SpinnerComponent {
  @Input() position: string;
}
