import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Column2 } from '../../../models/column.model';
import { ZipTable2 } from '../../../models/zip-table.model';
import { ZipTable2Service } from '../../../services/zip-table2.service';

@Component({
  selector: 'zip-table-header-row-2',
  templateUrl: './header-row.component.html',
})
export class HeaderRowComponent {
  @Input() columns: Column2[];
  @Input() external: boolean;
  @Input() options: ZipTable2;
  @Input() detailView;
  @Input() allExpanded;
  @Input() multiExpandedAllowed;
  @Input() grouping;
  @Input() weHaveData;
  @Output() expandAllRows: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSortEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() editClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteClicked: EventEmitter<any> = new EventEmitter<any>();

  constructor(public ztService: ZipTable2Service) {}

  /** emits to the zip table component to initiate sorting
   * @param $event - the click event that initiated this sort
   * */
  fireSortEvent($event) {
    this.onSortEvent.emit($event);
  }

  markAllRowsAsSelected(event) {
    event.target.checked
      ? this.ztService.markAllRowsAsSelected()
      : this.ztService.unselectAllRows();
  }
}
