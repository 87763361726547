import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MatExpansionModule } from '@angular/material/expansion';
import { ButtonModule } from '../../notes/button/button.module';
import { CardModule } from '../../notes/card/card.module';
import { ErrorEmptyModule } from '../../notes/error-empty/error-empty.module';
import { FormControlModule } from '../../notes/form-control/form-control.module';
import { IconModule } from '../../notes/icon/icon.module';

import { FormGroupDirectivesModule } from '../../shared/modules/grid-cell/form-group-directives.module';

import { ConditionalToggleComponent } from './conditional-toggle/conditional-toggle.component';
import { CheckboxMatrixComponent } from './custom-form-element/checkbox-matrix/checkbox-matrix.component';
import { CustomFormElementModule } from './custom-form-element/custom-form-element.module';
import { MultiAddressComponent } from './custom-form-element/multi-address/multi-address.component';
import { FormGroupArrayAccordionContentComponent } from './form-group-array/components/form-group-array-accordion-content/form-group-array-accordion-content.component';
import { FormGroupArrayContentFooterComponent } from './form-group-array/components/form-group-array-content-footer/form-group-array-content-footer.component';
import { FormGroupArrayContentComponent } from './form-group-array/components/form-group-array-content/form-group-array-content.component';
import { FormGroupArrayDescriptionComponent } from './form-group-array/components/form-group-array-description/form-group-array-description.component';
import { FormGroupArrayFooterComponent } from './form-group-array/components/form-group-array-footer/form-group-array-footer.component';
import { FormGroupArrayHeaderComponent } from './form-group-array/components/form-group-array-header/form-group-array-header.component';
import { FormGroupArrayTitleComponent } from './form-group-array/components/form-group-array-title/form-group-array-title.component';
import { FormGroupArrayComponent } from './form-group-array/form-group-array.component';
import { FormGroupComponent } from './form-group.component';
import { SetGridPositionPipe } from './form-group.pipe';

@NgModule({
  declarations: [
    FormGroupComponent,
    FormGroupArrayComponent,
    ConditionalToggleComponent,
    CheckboxMatrixComponent,
    MultiAddressComponent,
    SetGridPositionPipe,
    FormGroupArrayContentComponent,
    FormGroupArrayAccordionContentComponent,
    FormGroupArrayTitleComponent,
    FormGroupArrayFooterComponent,
    FormGroupArrayDescriptionComponent,
    FormGroupArrayContentFooterComponent,
    FormGroupArrayHeaderComponent,
  ],
  imports: [
    CommonModule,
    CustomFormElementModule,
    FormControlModule,
    ButtonModule,
    ReactiveFormsModule,
    FormGroupDirectivesModule,
    CardModule,
    ErrorEmptyModule,
    IconModule,
    MatExpansionModule,
  ],
  exports: [FormGroupComponent, SetGridPositionPipe],
})
export class FormGroupModule {}
