<ng-container *ngIf="filteredConfig">
  <div
    *ngIf="
      isMobileMenu ||
      (navigator.isIpad && !navigator.isPortrait) ||
      !navigator.isIpad
    "
    class="NavLinks"
  >
    <ng-container *ngFor="let link of filteredConfig.links">
      <ng-container *ngIf="link.route; else linkWithItems">
        <span class="u-maxY u-maxX">
          <ds-link
            class="NavLink u-maxX"
            tabindex="0"
            [ngClass]="{ 'is-active': link | isLinkActive : activeRoute }"
            [linkConfig]="getLinkConfig(link)"
            [externalSiteModalConfig]="externalSiteModalConfig"
            (keydown.enter)="onLinkClick({ event: $event, link: link })"
            (linkClicked)="onLinkClick({ event: $event, link: link })"
            (openExternalLinkModal)="$event.stopPropagation()"
          >
            <div class="NavLink__Label u-maxX">
              <div class="u-flex u-alignCenter u-maxX">
                <icon
                  *ngIf="link.icon"
                  class="NavLink__icon"
                  [name]="link.icon"
                ></icon>
                <p class="NavLink__Title">{{ link.label }}</p>
              </div>
            </div>
          </ds-link>
        </span>
      </ng-container>

      <ng-template #linkWithItems>
        <span class="u-maxY u-maxX">
          <span
            class="NavLink"
            role="button"
            tabindex="0"
            [attr.aria-controls]="createDOMId(link.label)"
            [attr.aria-expanded]="isMenuOpen(link)"
            [ngClass]="{
              'is-open': isMenuOpen(link),
              'is-active': link | isLinkActive : activeRoute
            }"
            (click)="onLinkClick({ event: $event, link: link })"
            (keydown.space)="onLinkClick({ event: $event, link: link })"
          >
            <div class="NavLink__Label u-maxX">
              <div class="u-flex u-alignCenter u-maxX">
                <icon
                  *ngIf="link.icon"
                  class="NavLink__icon"
                  [name]="link.icon"
                ></icon>
                <p class="NavLink__Title">{{ link.label }}</p>
                <icon
                  *ngIf="link.isExternalRoute && link.showIcon"
                  [name]="link.icon ? link.icon : 'open_in_new'"
                  [config]="{ type: 'link' }"
                ></icon>
              </div>

              <icon
                *ngIf="link.items"
                class="NavLink__Toggle"
                name="chevron_down"
                [config]="{
                  type: 'pointer',
                  transform:
                    data.openMenuItem && link === data.openMenuItem
                      ? 'rotate180'
                      : null,
                  fill:
                    data.activeNavItem && link === data.activeNavItem
                      ? 'nav-link-icon-color-active'
                      : 'nav-link-icon-color'
                }"
              >
              </icon>
            </div>

            <ng-container *ngIf="data.openMenuItem; let openMenuItem">
              <lib-expandable-menu
                [attr.id]="createDOMId(link.label)"
                class="NavLink__DropdownContent"
                [link]="link"
                [data]="data"
                [externalSiteModalConfig]="externalSiteModalConfig"
                [openMenuItem]="openMenuItem"
                (linkClicked)="onLinkClick($event)"
              ></lib-expandable-menu>
            </ng-container>
          </span>
        </span>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
