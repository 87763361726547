<span
  class="FormGroupArrayTitle"
  [style.font-size]="
    config.titleSize ? 'var(--' + config.titleSize + '-font-size)' : 'inherit'
  "
  [style.line-height]="
    config.titleSize ? 'var(--' + config.titleSize + '-line-height)' : 'inherit'
  "
>
  {{ config.title }}
  <span *ngIf="formArray.controls.length > 1">({{ index + 1 }})</span>
</span>
