import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  AccordionModule,
  ButtonModule,
  FormControlModule,
  FormGroupModule,
  MessageBannerModule,
  ModalModule,
  ModelAttributeModule,
  TableModule,
  TabsModule,
} from '@zipari/design-system';
import { UiAdditionalDetailModalModule } from '@zipari/provider-portal/ui-additional-detail-modal';
import { ZipBusyModule } from '../../../../../shared/modules/zip-busy/zip-busy.module';
import { ControlsGroupArrayModule } from '../../controls/controls-group-array/controls-group-array.module';
import { DropdownArrayModule } from '../../controls/dropdown-array/dropdown-array.module';
import { InputArrayModule } from '../../controls/input-array/input-array.module';
import { ProviderUpdateToolService } from '../../provider-update-tool.service';
import { ManagePractitionerDetailComponent } from './manage-practitioner-detail.component';

@NgModule({
  declarations: [ManagePractitionerDetailComponent],
  exports: [ManagePractitionerDetailComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormControlModule,
    FormGroupModule,
    ButtonModule,
    TableModule,
    ZipBusyModule,
    MessageBannerModule,
    ModalModule,
    ModelAttributeModule,
    AccordionModule,
    ReactiveFormsModule,
    DropdownArrayModule,
    InputArrayModule,
    TabsModule,
    ControlsGroupArrayModule,
    UiAdditionalDetailModalModule,
  ],
  providers: [ProviderUpdateToolService],
})
export class ManagePractitionerDetailModule {}
