import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  BadgeModule,
  ButtonModule,
  CardModule,
  FileUploaderModule,
  FormControlModule,
  IconModule,
  MessageBannerModule,
  ModelAttributeModule,
  SpinnerModule,
} from '@zipari/design-system';
import { ApiErrorMessageModalModule } from '../../../shared/modules/api-error-message-modal/api-error-message-modal.module';
import { SiteLocationAndPractitionerModule } from '../../../shared/modules/site-location-and-practitioner/site-location-and-practitioner.module';
import { EntryFormCardComponent } from './entry-form-card/entry-form-card.component';
import { SideBarModule } from '../../../shared/modules/side-bar/side-bar.module';
import { ReviewComponent } from './review/review.component';
import { EntryFormComponent } from './entry-form/entry-form.component';
import { ClaimAppealWrapperComponent } from './claim-appeal-wrapper/claim-appeal-wrapper.component';

@NgModule({
  declarations: [
    EntryFormCardComponent,
    ReviewComponent,
    ClaimAppealWrapperComponent,
    EntryFormComponent,
  ],
  exports: [
    EntryFormCardComponent,
    ReviewComponent,
    ClaimAppealWrapperComponent,
    EntryFormComponent,
  ],
  imports: [
    CommonModule,
    ButtonModule,
    CardModule,
    FormControlModule,
    ApiErrorMessageModalModule,
    SiteLocationAndPractitionerModule,
    SpinnerModule,
    FileUploaderModule,
    MessageBannerModule,
    ModelAttributeModule,
    IconModule,
    SideBarModule,
    BadgeModule,
  ],
})
export class SharedModule {}
