import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ClaimReconsiderationComponent } from './claim-reconsideration.component';

@NgModule({
  declarations: [ClaimReconsiderationComponent],
  exports: [ClaimReconsiderationComponent],
  imports: [CommonModule, SharedModule],
})
export class ClaimReconsiderationModule {}
