import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2, RendererFactory2 } from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  Title,
} from '@angular/platform-browser';
import {
  NavigationEnd,
  RouteConfigLoadEnd,
  Router,
  Event,
} from '@angular/router';
import { Keepalive } from '@ng-idle/keepalive';
import { filter, tap } from 'rxjs/operators';
import { applyIconsFromConfig } from '@zipari/web-utils';
import { GlobalConfig, KeepaliveConfig, TimeoutConfig } from './app.constants';
import { AuthService, ConfigService, IdleService } from './shared/services';
import { applyTheme } from './helpers/apply-theme';
import { fetchGoogleAnalyticsScript } from './shared/constants/analytics';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public isAppLoading: boolean = true;
  public idleState: string;
  public lastPing: Date;
  public typeface: SafeResourceUrl | undefined;
  public globalConfig: GlobalConfig;
  private renderer: Renderer2;

  constructor(
    public authService: AuthService,
    public idleService: IdleService,
    public configService: ConfigService,
    public title: Title,
    private keepalive: Keepalive,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private rendererFactory: RendererFactory2
  ) {
    this.globalConfig =
      this.configService.getPageConfig<GlobalConfig>('global');
    this.typeface = applyTheme(
      this.configService.getPageConfig('theme'),
      this.sanitizer
    );
    this.applyIcons();
    const { googleAnalytics } = this.globalConfig?.analytics;
    this.setupTimeouts(this.globalConfig.timeout);
    this.setTitle(this.globalConfig.title);
    this.setupRouterCheckForAuth();

    this.renderer = this.rendererFactory.createRenderer(null, null);
    if (
      googleAnalytics &&
      googleAnalytics.gTagId &&
      googleAnalytics.enableGoogleAnalytics
    ) {
      this.renderNoScript(googleAnalytics.gTagId);
      this.renderWindowScript(googleAnalytics.gTagId);
    }
    // running this here because it starts in top level of app and affects multiple files downstream
    this.runAPAfixes();
  }

  applyIcons(): void {
    const icons = this.configService.getPageConfig('icon_library');
    applyIconsFromConfig(icons);
  }

  ngOnInit() {
    this.typeface = applyTheme(
      this.configService.getPageConfig('theme'),
      this.sanitizer
    );
  }

  runAPAfixes() {
    // going to look into checking for if an input with type 'date' exists on page and *then* attaching this
    // browser compatibility datepicker. Needs more investigation. For now, sadly this is what we have to document
    // for ADA compliance

    const yearSelect = document.getElementsByClassName('yearSelect')[0];
    const monthSelect = document.getElementsByClassName('monthSelect')[0];
    yearSelect.parentElement?.parentElement?.setAttribute(
      'role',
      'contentinfo'
    );

    yearSelect.setAttribute('title', 'year select');
    monthSelect.setAttribute('title', 'month select');
  }

  setTitle(titleConfig: string): void {
    if (titleConfig) {
      this.title.setTitle(titleConfig);
    }
  }

  setupTimeouts(timeoutConfig: TimeoutConfig): void {
    const config: TimeoutConfig = new TimeoutConfig(
      timeoutConfig
    ) as TimeoutConfig;

    this.idleService.setupIdle(config.idle);
    this.setupKeepalive(config.keepalive as KeepaliveConfig);
  }

  /** documentation can be found @ https://hackedbychinese.github.io/ng2-idle/ */
  setupKeepalive(keepaliveConfig: KeepaliveConfig): void {
    if (keepaliveConfig.enabled) {
      this.keepalive.onPing.subscribe(() => {
        this.lastPing = new Date();

        this.http.get(keepaliveConfig.endpoint).subscribe({
          next: (keepAlivePayload) => {
            if (!keepAlivePayload?.['authenticated'])
              this.idleService.handleLogout();
          },
          error: (_err: Error) => {
            if (keepaliveConfig.error.link) {
              window.location.assign(keepaliveConfig.error.link);
            } else if (keepaliveConfig.error.route) {
              this.router.navigate([keepaliveConfig.error.route]);
            }
          },
        });
      });

      // Sets the ping keepaliveInterval to 15 seconds
      this.keepalive.interval(keepaliveConfig.interval);
    }
  }

  setupRouterCheckForAuth(): void {
    // Subscribe to router events to show loading indicator when app modules (e.g. broker, shopping portals) are
    // compiling
    // navigate to app route when url redirect fails
    this.router.events
      .pipe(
        tap((event: Event) => {
          if (event instanceof NavigationEnd && this.checkForNonAppUrl(event)) {
            this.router.navigate([this.configService.appRoute], {
              queryParamsHandling: 'merge',
            });
          }
          if (
            this.authService.loggedInUser &&
            this.authService.loggedInUser.app_user_data &&
            this.idleService.enabled &&
            !this.idleService.isIdling
          ) {
            this.idleService.resetTimeout();
          } else if (this.idleService.isIdling) {
            this.idleService.stopTimeout();
          }
        }),
        filter((event: Event) => event instanceof RouteConfigLoadEnd)
      )
      .subscribe(() => {
        this.isAppLoading = false;
      });
  }

  private checkForNonAppUrl(event: NavigationEnd): boolean {
    return event.urlAfterRedirects.split('?')[0] === '/';
  }

  /**
   * TODO: This should be handled by the Django Admin's "Additional HTML" field
   */
  private renderScript(gTagId: string, noScript = false) {
    const elementType = noScript ? 'noscript' : 'script';
    const tagElement = this.renderer.createElement(elementType);
    const topComment = this.renderer.createComment(
      `Google Tag Manager (${elementType}) LOCAL`
    );
    const bottomComment = this.renderer.createComment(
      `End Google Tag Manager (${elementType})`
    );

    // Add type attribute only to script tag
    !noScript && (tagElement.type = 'application/javascript');

    // setting innerHTML was not working for some reason so I had to use DOMParser
    const scriptContent = fetchGoogleAnalyticsScript(gTagId, noScript);
    const scriptHTML = new DOMParser().parseFromString(
      scriptContent.trim(),
      'text/html'
    );

    tagElement.append(scriptHTML.body.firstChild);

    this.renderer.appendChild(document.head, topComment);
    this.renderer.appendChild(document.head, tagElement);
    this.renderer.appendChild(document.head, bottomComment);
  }

  private renderNoScript(gTagId: string) {
    this.renderScript(gTagId, true);
  }

  private renderWindowScript(gTagId: string) {
    this.renderScript(gTagId);
  }
}
