import { ElementRef } from '@angular/core';
import { ModalTypes } from '@zipari/shared-ds-util-modal';

export const listenToTabKeydown = (
  focusableElements: string,
  parentElement: ElementRef
) => {
  document.addEventListener('keydown', (e) => {
    const tabKeyCode = 9;
    if (e.key === 'Tab' || e.keyCode === tabKeyCode) {
      const focusable = parentElement?.nativeElement
        ?.querySelector('#modalContainer')
        ?.querySelectorAll(focusableElements);
      if (focusable.length) {
        const first = focusable[0];
        const last = focusable[focusable.length - 1];
        const isShiftKeyPressed = !!e.shiftKey;
        // shift-tab pressed on first input in dialog
        if (isShiftKeyPressed) {
          if (e.target === first) {
            last.focus();
            e.preventDefault();
          }
        } else {
          // tab pressed on last input in dialog
          if (e.target === last) {
            first.focus();
            e.preventDefault();
          }
        }
      }
    }
  });
};

export const determineIconFill = (
  useAltStyle: boolean,
  modalType: ModalTypes
) => {
  return useAltStyle
    ? modalType === ModalTypes.flyout
      ? 'modal-alt-panel-header-text'
      : 'modal-alt-header-text'
    : modalType === ModalTypes.flyout
    ? 'modal-panel-header-text'
    : 'modal-header-text';
};
