import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../../../shared/services';
import { getAppealType } from '../claim-appeal.helper';
import { ClaimAppealConfig } from './claim-redetermination.model';

@Component({
  selector: 'claim-redetermination',
  templateUrl: './claim-redetermination.component.html',
  styleUrls: ['./claim-redetermination.component.scss'],
})
export class ClaimRedeterminationComponent implements OnInit {
  public config: ClaimAppealConfig;
  public appealType: string;
  readonly claimNumber: string = this.route.snapshot?.params?.claim_number;
  readonly pageName: string = this.route.snapshot?.data?.pageName;

  constructor(
    private route: ActivatedRoute,
    public configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.config = this.configService.getPageConfig(this.pageName);
    this.appealType = getAppealType(this.pageName);
  }
}
