import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { camelCase } from '@zipari/shared-util-common';
import { APIResponse, APIService } from '../../../../shared/services';
import { PortalUser } from '../../../../shared/models/shared/UserPayloadModels/PortalUser.model';
import { Group } from '../../../../shared/models/shared/UserPayloadModels/UserEditModel.model';
import { CheckListItem } from '../manage-portal-users-details.model';
@Injectable({
  providedIn: 'root',
})
export default class ActivateUserService {
  public selectedGroups: CheckListItem[] = [];
  public groupsDetail$: Observable<CheckListItem[]>;

  constructor(private api: APIService) {}

  getGroupsList(endpoint: string): void {
    this.groupsDetail$ = this.api.get(endpoint).pipe(
      map((response) => this.updateGroupsListData(response)),
      catchError((error) => throwError(error))
    );
  }

  updateGroupsListData(response: APIResponse<Group>): CheckListItem[] {
    const newOptions: CheckListItem[] = [];
    response.results?.forEach((group: Group) => {
      newOptions.push({
        label: group?.name,
        value: group,
      });
    });

    return newOptions;
  }

  activateUser(endpoint: string, groupIds: object): Observable<PortalUser> {
    return this.api.post(endpoint, groupIds).pipe(
      map((response) => camelCase(response)),
      catchError((error) => throwError(error))
    );
  }
}
