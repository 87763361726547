<section class="FormGroupArrayAccordionContent">
  <ng-container
    *ngFor="let arrayGroup of formArray.controls; let i = index"
    class="FormGroupArrayAccordionContent__Body"
  >
    <mat-expansion-panel
      class="mat-elevation-z0"
      [expanded]="openedAccordionIndex.includes(i)"
    >
      <hr class="FormGroupArrayAccordionContent__Separator" />
      <mat-expansion-panel-header>
        <mat-panel-title>
          <form-group-array-title
            [config]="config"
            [index]="i"
            [formArray]="formArray"
          ></form-group-array-title>
        </mat-panel-title>
        <mat-panel-description
          class="u-flex u-alignCenter u-justifyEnd u-fillRemaining"
          *ngIf="!isMobileView"
        >
          <form-group-array-header
            [config]="config"
            [formArray]="formArray"
            [index]="i"
            (removeGroupClicked)="removeGroup($event)"
          ></form-group-array-header>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-panel-description>
        <div [formGroup]="arrayGroup" body-group>
          <form-group-array-description
            [config]="config"
            [arrayGroup]="arrayGroup"
            [context]="context ? context[i] : null"
          ></form-group-array-description>
        </div>
      </mat-panel-description>

      <div class="FormGroupArrayAccordionContent__FooterActions">
        <ng-container
          *ngIf="
            isMobileView &&
            ((formArray?.length > 1 && !(i === 0 && config.protectMain)) ||
              config.allowEmpty)
          "
        >
          <zip-button
            (zipButtonClicked)="removeGroup(i)"
            [config]="config?.removeButton"
          >
          </zip-button>
        </ng-container>
        <form-group-array-content-footer
          [config]="config"
          [formArray]="formArray"
          [index]="i"
          (addDuplicateClicked)="addDuplicateForm($event)"
        ></form-group-array-content-footer>
      </div>
    </mat-expansion-panel>
  </ng-container>
</section>
