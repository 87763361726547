export function getValue(data: any | any[], prop: any) {
  const getter: any = getterForProp(prop);

  return getter(data, prop);
}

/**
 * Returns the appropriate getter function for this kind of prop.
 * If prop == null, returns the emptyStringGetter.
 */

/* istanbul ignore next */
function getterForProp(prop: any) {
  if (prop == null || prop == undefined) {
    return () => '';
  }

  if (typeof prop === 'number') {
    return numericIndexGetter;
  } else {
    // deep or simple
    if (prop.indexOf('.') !== -1) {
      return deepValueGetter;
    } else {
      return shallowValueGetter;
    }
  }
}

/**
 * Returns the value at this numeric index.
 */

/* istanbul ignore next */
function numericIndexGetter(row: any[], index: number): any {
  if (row == null) {
    return '';
  }
  // mimic behavior of deepValueGetter
  if (!row || index == null) {
    return row;
  }

  const value = row[index];
  if (value == null) {
    return '';
  }

  return value;
}

/**
 * Returns the value of a field.
 * (more efficient than deepValueGetter)
 */

/* istanbul ignore next */
function shallowValueGetter(obj: any, fieldName: string): any {
  if (obj == null) {
    return '';
  }
  if (!obj || !fieldName) {
    return obj;
  }

  const value = obj[fieldName];
  if (value == null) {
    return '';
  }

  return value;
}

/**
 * Returns a deep object given a string. zoo['animal.type']
 */

/* istanbul ignore next */
function deepValueGetter(obj: any, path: string): any {
  if (obj == null) {
    return '';
  }
  if (!obj || !path) {
    return obj;
  }

  // check if path matches a root-level field
  // { "a.b.c": 123 }
  let current = obj[path];
  if (current !== undefined) {
    return current;
  }

  current = obj;
  const split = path.split('.');

  if (split.length) {
    for (let i = 0; i < split.length; i++) {
      current = current[split[i]];

      // if found undefined, return empty string
      if (current === undefined || current === null) {
        return '';
      }
    }
  }

  return current;
}
