import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  ButtonModule,
  CardModule,
  FormControlModule,
  FormGroupModule,
} from '@zipari/design-system';
import { ViewUserProvisioningComponent } from './view-user-provisioning.component';
import { ViewerProvisioningModule } from './viewer/viewer-provisioning.module';

@NgModule({
  declarations: [ViewUserProvisioningComponent],
  exports: [ViewUserProvisioningComponent],
  imports: [
    CommonModule,
    RouterModule,
    CardModule,
    FormControlModule,
    FormGroupModule,
    ButtonModule,
    ViewerProvisioningModule,
  ],
})
export class ViewUserProvisioningModule {}
