import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { concat, of } from 'rxjs';
import { distinctUntilChanged, filter, mergeMap, tap } from 'rxjs/operators';
import { FormControlService } from '../../../notes/form-control/form-control.service';

import { AddressService } from '../../../shared/services/address.service';
import { BaseCVAComponent } from './base-cva.component';

/*
    Base class contains shared funtionality for city and county dropdown components
*/
@Component({
  selector: 'base-address-dropdown',
  template: '<div></div>',
})
export class BaseAddressDropdownComponent extends BaseCVAComponent {
  addressFormGroup;
  config: any = {};

  constructor(
    private addressService: AddressService,
    private formControlService: FormControlService
  ) {
    super();
  }

  get zipCodeControl(): UntypedFormControl {
    return this.addressFormGroup.get('zip_code');
  }

  /**
   * Initialization logic for component
   * @param addressFormGroup parent form group
   * @param config form control config
   * @param type are we initializing a city or county component
   */
  baseInit(addressFormGroup, config, type: 'city_name' | 'county') {
    const zipCodeLimit = 5;
    this.addressFormGroup = addressFormGroup;
    this.config = config;

    this.formGroup = new UntypedFormGroup({});
    this.formControlService.addControlToFormGroup(this.formGroup, this.config);

    this.zipCodeControl &&
      concat(of(this.zipCodeControl.value), this.zipCodeControl.valueChanges)
        .pipe(
          distinctUntilChanged(),
          tap((zipcodeValue) => {
            if (!zipcodeValue) {
              this.config.options = this.config.options.filter(
                (opt) => opt.value === null
              );
            }
          }),
          filter(
            (zipcodeValue) =>
              zipcodeValue &&
              zipcodeValue.length &&
              zipcodeValue.length === zipCodeLimit
          ),
          mergeMap((zipCodeValue) =>
            this.addressService.getCityOrCountyOverride(zipCodeValue, type)
          )
        )
        .subscribe((options: any[]) => {
          this.handleZipError(this.zipCodeControl, options);
          /*
                        Patch value if only one option available
                        Dont update if zip pristine (e.g. persisted from wf)
                        Null if many options (dont auto select for user)
                    */
          this.addOptionsKeepPlaceholder(options);
          this.config = { ...this.config, options };
          if (options.length === 1) {
            this.formGroup.patchValue({ [this.config.prop]: options[0].value });
          } else if (!this.zipCodeControl.pristine) {
            this.formGroup.patchValue({ [this.config.prop]: null });
          }
        });
  }

  handleZipError(formControl: UntypedFormControl, options = []) {
    if (options.length) {
      formControl.setErrors(null);
    } else {
      formControl.setErrors({ forcedError: !options.length });
    }
  }

  /**
   * clear any existing options and add new options from api
   * keep placeholder if it exists
   * placeholder added on dropdown init if it doesn't yet exist
   */
  addOptionsKeepPlaceholder(options) {
    const placeholderOption = this.config.options.filter(
      (option) => option.value === null
    );

    this.config.options = [...placeholderOption, ...options];
  }
}
