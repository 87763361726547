import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, Observable, of, tap } from 'rxjs';

import { camelCase } from '@zipari/shared-util-common';
import { FormControlService } from '@zipari/design-system';
import { UntypedFormGroup } from '@angular/forms';
import Claim from '../../../shared/models/shared/Claim.model';
import {
  ClaimAppeal,
  ClaimAppealSummary,
} from '../../../shared/models/shared/ClaimAppeals.model';
import { APIService } from '../../../shared/services';

import { ClaimAppealProps } from '../claim-appeal/claim-appeal.constants';
import {
  claimCardConfig,
  ControlConfiguration,
} from '../claim-appeal/claim-redetermination/claim-redetermination.model';
import { getControlByProp } from '../claim-appeal/claim-appeal.helper';
import { ClaimDetailConfig } from './ClaimDetailConfig.model';
import {
  CLAIM_APPEAL,
  ClaimAppealType,
  locationsUrl,
} from './claim-detail.constant';

@Injectable({
  providedIn: 'root',
})
export class ClaimDetailService {
  claim: Claim;
  claimId: string;
  claimAppealSummary$: Observable<ClaimAppealSummary[]>;
  config: ClaimDetailConfig;
  tabsConfig: any;
  isDeterminationExist: boolean;
  isReconsiderationExist: boolean;

  constructor(
    private api: APIService,
    public formControlService: FormControlService
  ) {}

  public getClaim(claimNumber: string | number): Observable<Claim> {
    return this.api
      .get(`/api/provider-portal/claims/${claimNumber}/`)
      .pipe(tap((claim) => (this.claimId = claim.id?.toString())));
  }

  public setClaim(claim: Claim) {
    this.claim = claim;
  }

  public getClaimAppeals(
    claimNumber: string | number
  ): Observable<ClaimAppeal[]> {
    return this.api
      .get(`/api/provider-portal/claims/${claimNumber}/appeals`)
      .pipe(
        map((response) => camelCase(response.results)),
        catchError(() => of([]))
      );
  }

  public getClaimAppealsSummary(claimNum: string | number): void {
    let claimAppealSummary: ClaimAppealSummary[] = [];

    this.claimAppealSummary$ = this.getClaimAppeals(claimNum).pipe(
      tap((claimAppeals: ClaimAppeal[]) => {
        claimAppealSummary = [];
        claimAppeals.forEach((claimAppeal: ClaimAppeal) =>
          claimAppealSummary.push({
            status: claimAppeal.status,
            requestedDate: claimAppeal.requestedDate,
            disputeLevel: ClaimAppealType[claimAppeal.disputeLevel],
            claimNumber: claimAppeal.claimId,
            appealId: claimAppeal.id,
          })
        );
      }),
      tap(() => this.setClaimAppealsExistStatus(claimAppealSummary)),
      map(() => claimAppealSummary)
    );
  }

  public setClaimAppealsExistStatus(
    claimAppealSummary: ClaimAppealSummary[]
  ): void {
    this.isDeterminationExist = claimAppealSummary.some(
      (appeal) => appeal.disputeLevel === CLAIM_APPEAL.REDETERMINATION
    );
    this.isReconsiderationExist = claimAppealSummary.some(
      (appeal) => appeal.disputeLevel === CLAIM_APPEAL.RECONSIDERATION
    );
  }

  public getFacilityDetail(facilityId: string): Observable<any> {
    return this.api.get(`${locationsUrl}${facilityId}/`);
  }

  public updateBillingProviderFormControl(
    reconsiderationForm: UntypedFormGroup,
    providerData: object,
    card: claimCardConfig
  ): UntypedFormGroup {
    const facilityNameControl = getControlByProp(
      card,
      ClaimAppealProps.facilityName
    );
    const providerNameControl: ControlConfiguration[] = [];
    const providerFirstNameControl = getControlByProp(
      card,
      ClaimAppealProps.providerFirstName
    );
    const providerLastNameControl = getControlByProp(
      card,
      ClaimAppealProps.providerLastName
    );

    if (providerFirstNameControl)
      providerNameControl.push(providerFirstNameControl);

    if (providerLastNameControl)
      providerNameControl.push(providerLastNameControl);

    if (
      providerData?.[ClaimAppealProps.billingFacilityLocationId] ||
      providerData?.[ClaimAppealProps.facilityId]
    ) {
      this.formControlService.addControlToFormGroup(
        reconsiderationForm,
        facilityNameControl
      );
      providerNameControl?.forEach((control: ControlConfiguration) =>
        reconsiderationForm.removeControl(control.prop)
      );
    } else {
      providerNameControl?.forEach((control: ControlConfiguration) =>
        this.formControlService.addControlToFormGroup(
          reconsiderationForm,
          control
        )
      );
      reconsiderationForm.removeControl(facilityNameControl?.prop);
    }

    return reconsiderationForm;
  }

  getClaimDetail(
    claimId: string | number,
    appealForm: UntypedFormGroup,
    card: claimCardConfig
  ): Observable<Claim> {
    return this.getClaim(claimId).pipe(
      mergeMap((claim) => {
        appealForm = this.updateBillingProviderFormControl(
          appealForm,
          claim,
          card
        );
        if (claim?.[ClaimAppealProps.billingFacilityLocationId]) {
          return this.getFacilityDetail(
            claim[ClaimAppealProps.billingFacilityLocationId]
          );
        } else {
          return of(claim);
        }
      })
    );
  }
}
