<article class="SuspendUser" *ngIf="config">
  <div
    class="SuspendUser__Stepper"
    *ngIf="suspendUserService.suspensionType === 'partially'"
  >
    <div class="SuspendUser__StepperContent">
      <ng-container
        *ngFor="let step of config.suspendUserSteps.attributes; let i = index"
      >
        <div class="SuspendUser__StepperItem">
          <p
            [ngClass]="{ 't-bold': state === step.prop }"
            class="t-data"
            [innerHTML]="step.label"
          ></p>
          <icon
            *ngIf="i < config.suspendUserSteps.attributes.length - 1"
            [name]="'chevron_right'"
          ></icon>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="suspendUserDataService.groupsTaxIdsDetail$ | async; else loading">
    <div class="SuspendUser__Content" *ngIf="state">
      <ng-container
        *ngIf="state === 'review' || state === 'confirmation'; else checkList"
      >
        <review-suspend-user
          [config]="state === 'review' ? config.review : config.confirmation"
          (editDetail)="editStep($event)"
        ></review-suspend-user>
      </ng-container>

      <ng-template #checkList>
        <check-list-card
          [config]="
            state === 'selectGroups' ? config.selectGroups : config.selectTaxIds
          "
          [data]="
            state === 'selectGroups'
              ? suspendUserService.groups
              : suspendUserService.taxIds
          "
          (selectedItems)="
            state === 'selectGroups'
              ? handleSelectedGroups($event)
              : handleSelectedTaxIds($event)
          "
        ></check-list-card>
      </ng-template>
    </div>
  </div>

  <ng-template #loading>
    <spinner [asOverlay]="true" size="medium"></spinner>
  </ng-template>

  <div class="SuspendUser__Footer">
    <zip-button
      *ngIf="state !== 'confirmation'"
      class="SuspendUser__FooterButton"
      [config]="config.buttons?.cancelButton"
      (zipButtonClicked)="navigateToPreviousPage()"
    >
    </zip-button>
    <footer-dynamic-button
      class="SuspendUser__FooterButton SuspendUser__FooterButton--right"
      (handleNextStep)="handleNextStep()"
    ></footer-dynamic-button>
  </div>
</article>

<api-error-message-modal
  [modalConfig]="config?.errorModal?.modalConfig"
  [buttonConfig]="config?.errorModal?.submitButton"
  [showModal]="showErrorModal"
  [errorMessage]="errorMessage"
  (submitButtonClicked)="errorModalSubmitClicked()"
></api-error-message-modal>
