import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableTabConfig } from '../patientDetailConfig.model';
import { PatientDetailService } from '../patient-detail.service';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { TableCellClickEvent } from '../../../../shared/models/shared/CellClickEvent.model';

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit {
  config: DataTableTabConfig;
  data: any;
  constructor(
    private patientDetailService: PatientDetailService,
    private router: Router,
    public analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.config = this.patientDetailService.tabsConfig;
  }

  navigateToDetail(target: TableCellClickEvent): void {
    let targetRoute: string;
    let url: string;
    this.analyticsService.sendEvent(this.config?.cx_event_key);

    if (this.config?.targetRouteObj?.externalUrl) {
      url = target.context.row[this.config.targetRouteObj.externalUrl];
      window.open(url, '_blank');
    }
    if (this.config?.targetRouteObj?.customUrl) {
      const pattern: any = /[^${}]*(?=\})/g;
      const toReplace: string[] =
        this.config.targetRouteObj.customUrl.match(pattern);
      toReplace.forEach((item: any) => {
        if (item.length > 0) {
          url = this.config.targetRouteObj.customUrl.replace(
            new RegExp(`\\\${${item}}`, 'gi'),
            target.context.row[item]
          );
        }
      });
      window.open(url, '_blank');
    } else if (this.config?.targetRouteObj?.targetUrl) {
      // Get target id from data
      const id = target.context.row[this.config?.targetRouteObj?.targetId];
      if (!id) {
        // eslint-disable-next-line no-console
        console.log(
          'Either Target id is missing in config or data is corrupted'
        );
      }
      targetRoute = `${window.location.pathname}/${id}`;
      this.router.navigate([targetRoute]);
    }
  }
}
