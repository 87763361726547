import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[libOutsideClick]',
})
export class OutsideClickDirective {
  @Output() libOutsideClick = new EventEmitter();

  constructor(private element: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onOutsideClick(event: Event) {
    if (!this.element.nativeElement.contains(event.target)) {
      this.libOutsideClick.emit(event);
    }
  }
}
