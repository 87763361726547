import { Component, Input, OnInit } from '@angular/core';
import {
  FooterLineTypes,
  FooterRow,
  LinkFooterLine,
  PortalFooterConfiguration,
} from '@zipari/shared-ds-util-footer';
import {
  LinkConfig,
  NavigationLinkTargets,
} from '@zipari/shared-ds-util-navigation';
import { ExternalSiteModalConfig } from '../external-site-warning-modal/external-site-warning-modal.model';
import { WindowService } from '../../shared/services/window.service';
import { cloneObject, objectToArray } from '../../shared/utils/object';

@Component({
  selector: 'portal-footer',
  templateUrl: './portal-footer.component.html',
  styleUrls: ['./portal-footer.component.scss'],
})
export class PortalFooterComponent implements OnInit {
  @Input() config: PortalFooterConfiguration;
  @Input() externalSiteModalConfig: ExternalSiteModalConfig;
  types = FooterLineTypes;
  rows: FooterRow[];

  constructor(private window: WindowService) {}

  ngOnInit() {
    this.buildRows();
  }

  buildRows() {
    if (this.config?.rows) {
      const ownRows = cloneObject(objectToArray(this.config.rows));

      ownRows.forEach((row) => {
        if (row.columns) {
          row.columns = [
            ...objectToArray(row.columns).map((column) =>
              objectToArray(column)
            ),
          ];
        }
      });
      ownRows.sort((a, b) => (a['priority'] < b['priority'] ? -1 : 1));

      this.rows = ownRows;
    }
  }

  goTo(link: string) {
    this.window.nativeWindow.open(link, '_blank');
  }

  getLinkConfig(footerConfig: LinkFooterLine): LinkConfig {
    return {
      useExternalSiteModal: footerConfig.useExternalSiteModal,
      externalLink: footerConfig.isExternalRoute ? footerConfig.route : null,
      route: !footerConfig.isExternalRoute ? footerConfig.route : null,
      label: footerConfig.label,
      removeLinkStyles: footerConfig?.removeLinkStyles,
      target: footerConfig.target
        ? footerConfig.target
        : NavigationLinkTargets.new_tab,
    };
  }
}
