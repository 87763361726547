import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { debounceTime, Subscription } from 'rxjs';
import {
  DocumentCenterConfig,
  DocumentCenterTab,
} from '../../util/document-center.model';

@Component({
  selector: 'zipari-document-filters',
  templateUrl: './document-filters.component.html',
  styleUrls: ['./document-filters.component.scss'],
})
export class DocumentFiltersComponent implements OnDestroy, OnChanges {
  @Input() documentCenterConfig!: DocumentCenterConfig;
  @Input() tab!: DocumentCenterTab;
  @Output() filterFormValue: EventEmitter<Record<string, unknown>> =
    new EventEmitter();
  filtersForm: UntypedFormGroup = new UntypedFormGroup({});
  valueChangesDebounceTime = 500;
  filterFormSubscription!: Subscription;
  isMobileFiltersOpen = false;

  filterFormCreated(): void {
    if (!this.documentCenterConfig?.page?.buttonApplyFilters) {
      this.filterValueChangesListener();
    }
  }

  filterValueChangesListener(): void {
    this.filterFormSubscription = this.filtersForm.valueChanges
      .pipe(debounceTime(this.valueChangesDebounceTime))
      .subscribe((value: Record<string, unknown>) => {
        this.filterFormValue.emit(value);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['tab'].firstChange) {
      this.tab = changes.tab.currentValue;
      this.resetFiltersOnTabChange();
    }
  }

  resetFiltersOnTabChange(): void {
    this.UnsubscribeFilters();

    Object.keys(this.filtersForm.controls).forEach((control) => {
      this.filtersForm
        .get(control)
        .patchValue(null, { onlySelf: true, emitEvent: false });
    });

    setTimeout(() => {
      if (!this.documentCenterConfig?.page?.buttonApplyFilters) {
        this.filterValueChangesListener();
      }
    }, 100);
  }

  applyFilters(): void {
    this.filterFormValue.emit(this.filtersForm.value);
    this.isMobileFiltersOpen = false;
  }

  clearFilters(): void {
    this.filtersForm.reset();
    this.filterFormValue.emit(this.filtersForm.value);
    this.isMobileFiltersOpen = false;
  }

  getMobileFilterLabel(tabLabel: string): string {
    return this.documentCenterConfig?.hideFullDescription
      ? 'Filter'
      : `Filter ${tabLabel} Documents`;
  }

  ngOnDestroy(): void {
    this.UnsubscribeFilters();
  }

  UnsubscribeFilters(): void {
    if (this.filterFormSubscription) {
      this.filterFormSubscription.unsubscribe();
    }
  }
}
