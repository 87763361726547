import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  DSAuthModule,
  ButtonModule,
  CardModule,
  FormControlModule,
  FormGroupModule,
} from '@zipari/design-system';
import { ErrorMessageModule } from '../../../../provider-portal/shared/modules/error-message/error-message.module';
import { SharedModule } from '../../../shared.module';
import { AuthCardComponent } from './auth-card.component';

@NgModule({
  declarations: [AuthCardComponent],
  imports: [
    CommonModule,
    CardModule,
    FormGroupModule,
    FormControlModule,
    ButtonModule,
    DSAuthModule,
    SharedModule,
    ErrorMessageModule,
  ],
  exports: [AuthCardComponent],
})
export class AuthCardModule {}
