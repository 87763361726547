import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AccordionModule,
  ButtonModule,
  ExpandingArticleModule,
  FormControlModule,
  FormGroupModule,
  ModalModule,
  ModelAttributeModule,
  TableModule,
} from '@zipari/design-system';
import { AddNewModalModule } from '../../../../../shared/modules/add-row-modal/add-new-modal.module';
import { ZipBusyModule } from '../../../../../shared/modules/zip-busy/zip-busy.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { ManagePractitionerListComponent } from './manage-practitioner-list.component';

@NgModule({
  declarations: [ManagePractitionerListComponent],
  exports: [ManagePractitionerListComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormControlModule,
    TableModule,
    FormGroupModule,
    ButtonModule,
    ZipBusyModule,
    ModelAttributeModule,
    ExpandingArticleModule,
    ModalModule,
    AccordionModule,
    AddNewModalModule,
    SharedModule,
  ],
  providers: [],
})
export class ManagePractitionerListModule {}
