import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DeviceDetectorService } from 'ngx-device-detector';
import { IconModule } from '../../icon/icon.module';
import { ControlTextModule } from '../text/text.module';

import { ControlTelComponent } from './tel.component';

@NgModule({
  declarations: [ControlTelComponent],
  imports: [
    CommonModule,
    FormsModule,
    IconModule,
    ReactiveFormsModule,
    ControlTextModule,
  ],
  providers: [DeviceDetectorService],
  exports: [ControlTelComponent],
})
export class ControlTelModule {}
