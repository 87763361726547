import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '../../notes/button/button.module';
import { IconModule } from '../../notes/icon/icon.module';
import { ModalModule } from '../../notes/modal/modal.module';
import { DigitalDataModule } from '../../shared/modules/digital-data/digital-data.module';
import { LinkModule } from '../link/link.module';
import { PortalFooterComponent } from './portal-footer.component';
import { FooterEventPipe } from './portal-footer.pipe';

@NgModule({
  declarations: [PortalFooterComponent, FooterEventPipe],
  imports: [
    CommonModule,
    IconModule,
    LinkModule,
    ModalModule,
    ButtonModule,
    DigitalDataModule,
  ],
  exports: [PortalFooterComponent, FooterEventPipe],
})
export class PortalFooterModule {}
