import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TooltipService {
  parentContainerBoundingClientRect$ = new Subject();
  currentlyOpen: number;
  instanceCount = 0;
  tooltipTracker: boolean[] = [];
  instances$ = new BehaviorSubject(this.tooltipTracker);

  updateInstanceCount() {
    const isOpen = false;
    this.instanceCount++;
    this.tooltipTracker.push(isOpen);
    this.instances$.next(this.tooltipTracker);
  }

  setTooltipToOpenAndClosePrevious(id: number) {
    if (this.currentlyOpen === id) {
      this.tooltipTracker[id] = !this.tooltipTracker[id];
    } else {
      const previouslyOpened: number = this.currentlyOpen;
      this.tooltipTracker[previouslyOpened] = false;
      this.tooltipTracker[id] = true;
      this.currentlyOpen = id;
    }
    this.instances$.next(this.tooltipTracker);
  }

  setParentContainerBoundingClientRect(bcr) {
    this.parentContainerBoundingClientRect$.next(bcr);
  }

  close(id: number) {
    this.tooltipTracker[id] = false;
    this.instances$.next(this.tooltipTracker);
  }
}
