<ng-container *ngIf="config && control && group">
  <form
    novalidate
    *ngIf="config && control && group"
    [id]="config.id || config.prop"
    [formGroup]="group"
  >
    <fieldset
      role="radiogroup"
      [attr.aria-labelledby]="config.id || config.prop"
      [attr.readonly]="config.readonly ? config.readonly : null"
      [ngClass]="{
        FormControl__RadioGroup: config.inline === true
      }"
    >
      <label
        class="form-control--radio__wrapper"
        [ngClass]="{
          'form-control--radio__wrapper--inline': config.inline === true,
          'form-control--radio__wrapper--justify':
            config.alignment === 'justified',
          'form-control--radio__wrapper--top-aligned':
            config.isTopAligned === true
        }"
        [id]="(config.id || config.prop) + '_' + i"
        *ngFor="let radioConfig of config.options; let i = index"
      >
        <p
          class="t-data"
          *ngIf="radioConfig.label && config.alignment === 'justified'"
          [innerHTML]="radioConfig.label"
        ></p>

        <input
          #radio
          class="form-control form-control--radio"
          role="radio"
          type="radio"
          [attr.disabled]="config.isDisabled ? config.isDisabled : null"
          [attr.readonly]="config.readonly ? config.readonly : null"
          [attr.name]="config.prop"
          [attr.checked]="control.value === radioConfig.value ? true : null"
          [value]="radioConfig.value"
          [formControlName]="config.prop"
          [attr.aria-checked]="control.value === !!radioConfig.value"
          [attr.aria-invalid]="
            (control.dirty || control.touched) && !control.valid
          "
          [attr.aria-describedby]="(config?.id || config?.prop) + '-label'"
          [attr.aria-errormessage]="
            'error_' + (config?.id || config?.prop) + '-input'
          "
          [attr.aria-labelledby]="(config.id || config.prop) + '_' + i"
        />

        <p
          class="t-data RadioLabel"
          [ngClass]="{
            'form-control--radio__label--unchecked':
              control.value !== radioConfig.value && config.isDisabled
          }"
          *ngIf="radioConfig.label && !config.alignment"
        >
          <ng-container *ngIf="isArray(radioConfig.label); else defaultLabel">
            <div
              class="RadioLabelItems"
              *ngFor="let label of radioConfig.label"
            >
              <span
                class="RadioLabelItems__Key t-bold"
                [innerHTML]="label.key + ':'"
              ></span>
              <ng-container *ngIf="isArray(label.value); else stringValue">
                <div
                  class="RadioLabelItems__Value"
                  *ngFor="let value of label.value"
                >
                  <span [innerHTML]="value"></span>
                </div>
              </ng-container>
              <ng-template #stringValue>
                <span
                  class="RadioLabelItems__Value"
                  [innerHTML]="label.value"
                ></span>
              </ng-template>
            </div>
          </ng-container>
          <ng-template #defaultLabel>
            <span [innerHTML]="radioConfig.label"></span>
          </ng-template>
        </p>
      </label>
    </fieldset>
  </form>
</ng-container>
