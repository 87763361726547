import { Location } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormBuilder,
  AbstractControl,
  UntypedFormArray,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormattingService, FormControlService } from '@zipari/design-system';
import { getValue } from '@zipari/web-utils';
import { isEmpty } from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { APIService } from '../../../shared/services/api.service';
import { ConfigService } from '../../../shared/services/config.service';
import { DocumentsService } from '../../../shared/services/documents.service';
import Policy from '../../../shared/models/shared/Policy.model';
import Member from '../../../shared/models/shared/Member.model';
import { objectToArray } from '../../../shared/utilities/object';
import { GenericServices } from '../../shared/services/generic.service';
import { defaultFileUploaderRestrictions } from '../../../shared/constants/file-uploader.constant';
import { UploadDocumentConfig } from '../../../shared/models/shared/FileUploader.model';
import { ApiListResponse } from '../../../shared/models/shared/ApiListResponse.model';
import { ProviderUpdateToolService } from '../provider-update-tool/provider-update-tool.service';
import { CX_CALLS } from '../../../shared/constants/cx-calls.constant';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { DocumentUploadService } from '../claim-appeal/services/document-upload.service';
import {
  ProviderNetworkStatusKey,
  ProvidersKeys,
  uploadDocument,
} from './referral-submission.constants';
import { getDefaultFileUploaderConfig } from '../../../shared/helpers/file-uploader.helper';

@Component({
  selector: 'referral-submission',
  templateUrl: './referral-submission.component.html',
  styleUrls: ['./referral-submission.component.scss'],
})
export class ReferralSubmissionComponent
  implements OnInit, AfterViewChecked, OnDestroy
{
  id: any;
  config: any = {};
  context: { member: Member; coverage?: any } = { member: null };
  state: 'edit' | 'review' | 'confirm' = 'edit';
  formGroup = new UntypedFormGroup({});
  phoneFormGroup = new UntypedFormGroup({});
  controls: Map<any, any> = new Map<any, any>();
  payload: any = {};
  risks: any[] = [];
  busy: any[] = [];
  response: any;
  error: boolean = false;
  nonDependentEndpoints: string[];
  nonDependentEndpointStatus: boolean[] = [];
  loaded: boolean = false;
  noSpace: string = 'n/a';
  currentDocumentType: string;
  fileTooBig: boolean = false;
  hasFile: any = null;
  documentContext: UntypedFormArray;
  defaultFileUploaderRestrictions = defaultFileUploaderRestrictions;

  diagnosisDetails: any;
  _: any = _;

  constructor(
    private route: ActivatedRoute,
    public configService: ConfigService,
    public genericServices: GenericServices,
    private formControlService: FormControlService,
    public router: Router,
    private api: APIService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private location: Location,
    private el: ElementRef,
    public mp: ProviderUpdateToolService,
    private docService: DocumentsService,
    public analyticsService: AnalyticsService,
    private formattingService: FormattingService,
    public documentUploadService: DocumentUploadService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.genericServices.showFooter$.next(false);
    });
    this.id = this.route.snapshot.params.id;
    this.config = this.configService.getPageConfig('create-referral-new');
    this.noSpace = this.config?.noSpace;
    this.refactorReferral();
    this.buildPanel();
    this.buildForms();
    if (this.documentUploadService.fileAttachments?.length > 0) {
      this.setFilesFromAPI(this.documentUploadService.fileAttachments);
    }
  }

  refactorReferral() {
    if (this.config) {
      for (const cardKey of _.keys(this.config?.cards)) {
        for (const controlKey of _.keys(
          this.config?.cards[cardKey]?.controls
        )) {
          this.config.cards[cardKey].controls = Object.keys(
            this.config?.cards[cardKey]?.controls
          )
            .sort((a, b) =>
              this.config?.cards[cardKey]?.controls[a]['controlOrder'] >
              this.config?.cards[cardKey]?.controls[b]['controlOrder']
                ? 1
                : -1
            )
            .reduce((result, key) => {
              result[key] = this.config.cards[cardKey].controls[key];

              return result;
            }, {});
          for (const [key, value] of Object.entries(
            this.config?.cards[cardKey]?.controls
          )) {
            if (
              key.indexOf(controlKey) !== -1 &&
              key.indexOf('flyout') !== -1 &&
              controlKey.indexOf('flyout') === -1
            ) {
              Object.assign(this.config?.cards[cardKey]?.controls[controlKey], {
                flyout: value,
              });
              // To convert flyout table columns and filter object into array
              this.config.cards[cardKey].controls[
                controlKey
              ].flyout.table.columns = objectToArray(value['table'].columns);
              this.config.cards[cardKey].controls[
                controlKey
              ].flyout.table.filters = objectToArray(value['table'].filters);
              delete this.config?.cards[cardKey]?.controls[key];
            }
          }
        }
      }
      this.config.cards = Object.keys(this.config.cards)
        .sort((a, b) =>
          this.config?.cards[a]['cardOrder'] >
          this.config?.cards[b]['cardOrder']
            ? 1
            : -1
        )
        .reduce((result, key) => {
          result[key] = this.config.cards[key];

          return result;
        }, {});
    }
  }

  buildPanel(): void {
    const memberApi: Observable<Member> = this.api.get(
      this.setMemberId(this.config?.endpoints.member)
    );
    const coverageApi: Observable<ApiListResponse<Policy>> = this.api.get(
      this.setMemberId(this.config?.endpoints.coverage)
    );

    this.busy.push(
      forkJoin([memberApi, coverageApi]).subscribe(([member, coverage]) => {
        this.context.member = member;
        if (coverage.results) {
          this.context.coverage = coverage.results[0]?.product_coverages[0];
        }
        if (
          this.context?.member?.additional_fields
            ?.enrolled_medicare_savings_program
        ) {
          this.context.member.additional_fields.enrolled_medicare_savings_program =
            ' ';
        }

        if (this.context.coverage) {
          this.context.coverage.effective_date = this.formatDate(
            this.context.coverage.effective_date
          );
          this.context.coverage.termination_date = this.formatDate(
            this.context.coverage.termination_date
          );
        }

        this.loaded = true;
      })
    );
  }

  formatDate(date: string): string {
    return this.formattingService.restructureValueBasedOnFormat(date, {
      format: 'DATE',
    });
  }

  setMemberId(target: string): string {
    return target?.replace('${member_number}', this.id);
  }

  checkShowOnTrueValues(attr): boolean {
    let targetDataExists: boolean;
    let value: string | number;
    if (attr.showIfTrue) {
      value = this.getValue(this.context, attr);
      targetDataExists = value.toString().length > 0;
    } else {
      return false;
    }

    return targetDataExists;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  getValue(data: object, attr, val?: boolean): string | number {
    return val ? getValue(data, attr.value) : getValue(data, attr.prop);
  }

  buildForms() {
    for (const cardKey of _.keys(this.config?.cards)) {
      for (const controlKey of _.keys(this.config?.cards[cardKey]?.controls)) {
        this.controls.set(
          this.config?.cards[cardKey]?.controls[controlKey].prop,
          this.config?.cards[cardKey]?.controls[controlKey]
        );
        const _control: AbstractControl = new UntypedFormControl('', []);
        const newFormControl = Object.assign(
          this.config?.cards[cardKey]?.controls[controlKey],
          {
            control: _control,
          }
        );

        this.formControlService.addControlToFormGroup(
          this.formGroup,
          newFormControl
        );
        this.loadDataForControl(
          this.config?.cards[cardKey]?.controls[controlKey]
        );
      }
    }
    this.formGroup
      .get('document_type')
      ?.valueChanges.subscribe((changedValueOrIndex: string) => {
        this.currentDocumentType = changedValueOrIndex;
        if (this.currentDocumentType?.length)
          this.docService.setDocumentCategory(this.currentDocumentType);
      });
  }

  loadDataForControl(control) {
    const endpoint =
      this.config?.outOfNetwork?.isOutOfNetworkEnabled &&
      control.outOfNetworkApiEndpoint
        ? control.outOfNetworkApiEndpoint
        : control.apiEndpoint;

    if (!endpoint) return false;

    this.getDataForControl(endpoint, control);
  }

  async getDataForControl(endpoint: string, control: any): Promise<void> {
    const data = await this.getDropdownData(endpoint).toPromise();

    if (control.optionFormat) {
      const dropdownArray = data?.map((item: any, idx: Number) => {
        const stringConcatenateValue = control.optionFormat.reduce(
          (previousValue: string, currentValue: string) =>
            `${previousValue} ${getValue(item, currentValue)}`,
          ' '
        );

        return { ...item, label: stringConcatenateValue, value: idx };
      });
      control.options = this.populateDropdown(
        new Array<any>(),
        dropdownArray,
        control.dropdownDisplayProp
      );
    } else {
      control.options = this.populateDropdown(
        new Array<any>(),
        data,
        control.dropdownDisplayProp
      );
      if (control.addCustomValueInDropDown) {
        const customOption = {
          label: control.addCustomValueInDropDown,
          value: {
            code: '',
            description: control.addCustomValueInDropDown,
            id: null,
            name: '',
          },
        };
        control.options?.push(customOption);
      }
    }
  }

  getDropdownData(endpoint: string): Observable<any> {
    return this.api
      .get(endpoint)
      .pipe(map((response: any) => response?.results));
  }

  print(): void {
    window.print();
  }

  cancel(): void {
    if (this.genericServices.previousURL.includes('provider-portal')) {
      this.location.back();
    }
  }

  scrollTo(event: any): void {
    this.back(null);
    const formValue = this.formGroup.value || {};
    const eventAttributes = {
      type_of_service: formValue?.service_type_id?.short_description,
      requested_units: formValue?.service_unit_quantity,
      unit_type: formValue?.service_unit_type,
      priority: formValue?.priority,
      diagnosis_code: this.diagnosisDetails?.map(
        (element: any) => element?.row?.code
      ),
      diagnosis_description: this.diagnosisDetails?.map(
        (element: any) => element?.row?.description
      ),
      procedure_code: formValue?.procedure_id?.code,
      procedure_description: formValue?.procedure_id?.description,
      service_categroy: formValue?.service_category_id?.description,
      attachment_type: this.currentDocumentType,
    };

    this.analyticsService.sendEvent(
      CX_CALLS.pp_referral_entry_edited.event_key,
      eventAttributes
    );
    setTimeout(() => {
      const itemToScrollTo = document.getElementById(event);
      // null check to ensure that the element actually exists
      if (itemToScrollTo) {
        itemToScrollTo.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  populateDropdown(options: any[], results: any[], prop: string) {
    for (const item of results) {
      options.push({
        label: this.getObjectProperty(item, prop),
        value: item,
      });
    }

    return options;
  }

  getObjectProperty(obj, keys) {
    return keys.split('.').reduce(function (result, key) {
      return result[key];
    }, obj);
  }

  getDropdownDisplayValue(prop: string): string {
    const options: any[] = this.controls.get(prop).options;
    const option: any = options.find(
      (item: any) => item.value === this.formGroup.get(prop)?.value
    );
    if (option) {
      return option.label;
    }

    return this.noSpace;
  }

  back(event: any): void {
    if (this.state === 'review') this.state = 'edit';
  }

  review(): void {
    if (this.formGroup.valid && this.diagnosisDetails?.length > 0) {
      if (this.state === 'edit') this.state = 'review';
      window.scrollTo(0, 0);
      this.analyticsService.sendEvent(
        CX_CALLS.pp_referral_entry_reviewed.event_key
      );
    } else {
      this.formGroup.markAllAsTouched();
      setTimeout(() => {
        this.genericServices.scrollToFirstInvalidControl(this.el);
      });
    }
  }

  setFieldIfExists(obj: any, field_name: string) {
    if (obj && (typeof obj === 'number' || !isEmpty(obj))) {
      this.payload[field_name] = obj;
    }
  }

  selectedDiagnosisDetails(event) {
    this.diagnosisDetails = event;
  }

  buildPayload() {
    this.payload = {};
    // card 1:
    this.setFieldIfExists(
      this.formGroup.value?.referring_provider_id?.id,
      'referring_provider_id'
    );
    this.setFieldIfExists(this.id, 'member_id');

    // card 2:
    this.setFieldIfExists(
      this.formGroup.value?.service_category_id?.id,
      'service_category_id'
    );

    // card 3:
    this.setFieldIfExists(
      this.formGroup.value?.service_type_id?.id,
      'service_type_id'
    );

    // card 4:
    this.setFieldIfExists(this.formGroup.value?.priority, 'priority');

    // card 5:
    this.setFieldIfExists(this.setDiagnosis(), 'diagnoses');

    // card 6:
    this.setFieldIfExists(
      this.formGroup.value?.servicing_provider_id?.id,
      'servicing_provider_id'
    );

    // card 7:
    this.setFieldIfExists(
      this.formGroup.value?.servicing_facility_id?.id,
      'servicing_facility_location_id'
    );

    // card 8:
    this.setFieldIfExists(this.setProcedures(), 'procedures');
    this.setFieldIfExists(
      this.formGroup.value?.effective_date,
      'effective_date'
    );
    this.setFieldIfExists(
      this.formGroup.value?.termination_date,
      'termination_date'
    );
    this.setFieldIfExists(
      this.formGroup.value?.service_unit_type,
      'service_unit_type'
    );
    this.setFieldIfExists(
      this.formGroup.value?.service_unit_quantity,
      'service_unit_quantity'
    );

    // card 9:
    this.setFieldIfExists(this.getDocumentIds(), 'documents');

    // card 10:
    this.setFieldIfExists(this.setNotes(), 'notes');

    if (this.config?.outOfNetwork?.isOutOfNetworkEnabled) {
      this.setFieldIfExists(
        this.formGroup.value?.referring_provider_id?.member_network
          ?.network_status,
        ProviderNetworkStatusKey.referringProviderStatus
      );
      this.setFieldIfExists(
        this.formGroup.value?.servicing_provider_id?.member_network
          ?.network_status,
        ProviderNetworkStatusKey.servicingProviderStatus
      );
      this.setFieldIfExists(
        this.formGroup.value?.servicing_facility_id?.member_network
          ?.network_status,
        ProviderNetworkStatusKey.facilityProviderStatus
      );
    }
  }

  setNotes() {
    return [
      {
        note_type: 'service_note',
        note_content: this.formGroup.value?.service_notes,
      },
      {
        note_type: 'referral_note',
        note_content: this.formGroup.value?.referral_notes,
      },
      {
        note_type: 'additional_note',
        note_content: this.formGroup.value?.additional_notes,
      },
    ];
  }

  setProcedures() {
    return [
      {
        procedure_id: this.formGroup.value?.procedure_id?.id,
        is_primary: true,
      },
    ];
  }

  setDiagnosis() {
    const diagnosis: any[] = [];
    let is_primary = false;
    this.diagnosisDetails?.forEach((element: any) => {
      is_primary = element.row?.is_primary ? element.row.is_primary : false;
      diagnosis.push({ diagnosis_id: element.row?.id, is_primary: is_primary });
    });

    return diagnosis;
  }

  submit(): void {
    this.buildPayload();
    this.busy.push(
      this.api
        .post(this.config.endpoints.submitApi, {
          referral_request: this.payload,
          request_type: 'submit',
        })
        .subscribe(
          (resp: any) => {
            this.response = resp;
            if (this.state === 'review') {
              this.state = 'confirm';
              window.scrollTo(0, 0);
              const formValue = this.formGroup.value || {};
              const eventAttributes = {
                type_of_service: formValue?.service_type_id?.short_description,
                requested_units: formValue?.service_unit_quantity,
                unit_type: formValue?.service_unit_type,
                priority: formValue?.priority,
                diagnosis_code: this.diagnosisDetails?.map(
                  (element: any) => element?.row?.code
                ),
                diagnosis_description: this.diagnosisDetails?.map(
                  (element: any) => element?.row?.description
                ),
                procedure_code: formValue?.procedure_id?.code,
                procedure_description: formValue?.procedure_id?.description,
                service_categroy: formValue?.service_category_id?.description,
                attachment_type: this.currentDocumentType,
              };
              this.analyticsService.sendEvent(
                CX_CALLS?.pp_referral_entry_completed?.event_key,
                eventAttributes
              );
            }
          },
          (err: any) => {
            this.toggleError();
          }
        )
    );
  }

  getDefaultReviewBox(control: any) {
    const controlValue = this.formGroup.get(control.prop)?.value;
    if (control.type === 'date') {
      if (controlValue) {
        const value: any = controlValue;
        const dateObj = new Date(`${value}T00:00:00`);

        return new Intl.DateTimeFormat('en-US').format(dateObj);
      } else {
        return this.noSpace;
      }
    }

    return controlValue ? controlValue : this.noSpace;
  }

  toggleError() {
    this.error = !this.error;
  }

  showOutOfNetworkStatusPill(key: string, controlKey: string): boolean {
    return (
      this.config?.outOfNetwork?.isOutOfNetworkEnabled &&
      this.formGroup.get(this.config?.cards[key]?.controls[controlKey]?.prop)
        ?.value?.member_network?.is_in_network == false
    );
  }

  ngOnDestroy(): void {
    setTimeout(() => {
      this.genericServices.showFooter$.next(true);
    });
    this.documentUploadService.uploadedFiles = [];
    this.documentUploadService.uploadAttachmentSubscription?.unsubscribe();
  }

  areProvidersOutOfNetwork(): boolean {
    const outOfNetworkProvider = ProvidersKeys.find(
      (provider: string) =>
        this.formGroup.value[provider]?.member_network?.is_in_network == false
    );

    return outOfNetworkProvider ? true : false;
  }

  fileUploaded(fileList: File[]): void {
    const { documentUploadType, referralDocument, controlDocument } =
      uploadDocument;
    const documentType = this.formGroup?.get(documentUploadType)?.value;
    const uploadDocumentConfig =
      this.config?.cards[referralDocument]?.controls[controlDocument];
    const documentUploadUrl =
      uploadDocumentConfig?.documentUploadUrl ||
      uploadDocumentConfig?.documentCreate;

    if (documentUploadUrl) {
      this.documentUploadService.uploadFile(
        fileList,
        documentUploadUrl,
        documentType?.value
      );
    }
  }

  removeFile(fileId: number): void {
    this.documentUploadService.removeFile(fileId);
    this.documentUploadService.uploadCompleted$.next(false);
  }

  public setFilesFromAPI(filesList: File[]): void {
    this.documentContext = new UntypedFormArray(
      filesList.map((file: File) => new UntypedFormControl(file))
    );
  }

  public getDocumentIds(): number[] {
    return (
      this.documentUploadService.uploadedFiles.map(
        (uploadDocument) => uploadDocument.id
      ) || []
    );
  }

  public getDefaultUploadDocumentConfig(
    uploadDocumentConfig: UploadDocumentConfig
  ): UploadDocumentConfig {
    return getDefaultFileUploaderConfig(uploadDocumentConfig);
  }
}
