<section class="DocumentCenter">
  <div class="DocumentCenter__Content">
    <div class="DocumentCenter__Main DocumentCenter__Main--Full">
      <div class="DocumentCenter__Header">
        <h4
          class="DocumentCenter__Title"
          data-testId="documentCenterTitle"
          [innerHTML]="documentCenterConfig?.title"
        ></h4>
      </div>
      <section class="DocumentCenter__Tabs" *ngIf="!!tabs">
        <tabs
          (tabSelected)="tabSelected(tabs[$event])"
          [selectedIndex]="selectedTabIndex"
          [tabLabels]="tabLabels"
        >
        </tabs>
        <router-outlet></router-outlet>
      </section>
    </div>
  </div>
</section>
