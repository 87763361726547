import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoggingService } from './logging.service';

export class ActiveLanguageResponse {
  language: string;
  config: any;
}

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  public changeLanguageSubject = new BehaviorSubject('en');
  public activeLanguageSubject = new Subject<ActiveLanguageResponse>();
  private _activeLanguage;
  private languageStore;

  public set activeLanguage(language: string) {
    this._activeLanguage = language;
    this.activeLanguageSubject.next({
      language: this.activeLanguage,
      config: this.activeLanguageConfig,
    });
  }

  public get activeLanguage() {
    return this._activeLanguage;
  }

  public get activeLanguageConfig() {
    const config = this.languageStore[this.activeLanguage];

    if (!config) {
      this.loggingService.error(
        `configuration for language ${this.activeLanguage} not found`
      );

      return {};
    } else {
      return config;
    }
  }
  constructor(private loggingService: LoggingService) {
    this.retrieveLanguageStore();
    this.activeLanguage = 'en';
  }

  ngOnInit() {
    this.changeLanguageSubject.pipe(take(1)).subscribe((language) => {
      this.activeLanguage = this.languageStore[language];
    });
  }

  private retrieveLanguageStore() {
    // todo: we need to retrieve language store once we get everything from backend
    this.languageStore = {
      en: {},
      sp: {},
    };
  }
}
