import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModelAttributeModule } from '../../notes/model-attribute/model-attribute.module';
import { StatusModule } from './../../notes/status/status.module';
import { ModelAttributeSectionsComponent } from './model-attribute-sections.component';

@NgModule({
  declarations: [ModelAttributeSectionsComponent],
  imports: [CommonModule, ModelAttributeModule, StatusModule],
  exports: [ModelAttributeSectionsComponent],
})
export class ModelAttributeSectionsModule {}
