<section class="CheckboxList">
  <span *ngIf="showSelectAll && config?.controls">
    <span *ngFor="let checkbox of config.controls">
      <mat-checkbox
        #selectCheckbox
        [checked]="areAllOptionChecked"
        [indeterminate]="getIndeterminateStatus()"
        (change)="checkAllOptions()"
        (indeterminateChange)="checkForIndeterminateStatus()"
      >
        {{ checkbox.label }}
      </mat-checkbox>
    </span>
  </span>
  <span>
    <ul
      class="CheckboxList__CheckboxOptions"
      [ngClass]="{ CheckboxList__CheckboxListLayout: layout === 'horizontal' }"
    >
      <li
        *ngFor="let option of checkboxOptions"
        [ngClass]="{
          CheckboxList__CheckboxListOptionLayout: layout === 'horizontal'
        }"
      >
        <mat-checkbox
          class="CheckboxList_CheckboxOptions"
          [checked]="option?.defaultChecked && !option?.disabled"
          [disabled]="option?.disabled || null"
          (change)="setOptionCheckedStatus($event, option)"
        >
          {{ option.label }}
        </mat-checkbox>
      </li>
    </ul>
  </span>
</section>
