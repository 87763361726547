import { DatePipe } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';
import {
  AccidentType,
  ClaimSubmissionForms,
  ClaimSubType,
  DentalPayloadCategory,
  FaultType,
  MedicalCategory,
  no,
  RequestTypePayload,
  yes,
} from './claim-submission.constant';
import {
  ClaimLineItem,
  ClaimLinePayload,
  ClaimSubmissionPayload,
} from './claim-submission.model';
import { SubmittedClaimDetails } from './submittedClaim';

export const createClaimPayload = (
  formData: UntypedFormGroup,
  submissionStatus: string,
  claimType: string,
  documentIds: number[],
  selectedPatient: string[],
  policyId: string
) => {
  const claimSavedDate = new Date();
  const patientInformation = formData.get(
    ClaimSubmissionForms.patientInformation
  )?.value;
  const providerInformation = formData.get(
    ClaimSubmissionForms.providerInformation
  )?.value;
  const serviceDetails = formData.get(
    ClaimSubmissionForms.serviceDetails
  )?.value;
  const dentalTreatment = formData.get(
    ClaimSubmissionForms.dentalTreatment
  )?.value;
  const claimLineInformation = formData.get(
    ClaimSubmissionForms.claimLineInformation
  )?.value;
  const patientIdIndex = 0;
  const patientFullNameIndex = 1;
  const memberCoverageIdIndex = 2;

  let accidentType: string;
  switch (serviceDetails.accidentType) {
    case AccidentType.autoAccidentData:
      accidentType = AccidentType.autoAccident;
      break;
    case AccidentType.workRelatedAccident:
      accidentType = AccidentType.workplaceAccident;
      break;
    case AccidentType.accidentalInjury:
      accidentType = AccidentType.otherAccident;
      break;
    default:
      accidentType = serviceDetails.accidentType;
  }

  const faultPerson: string =
    serviceDetails.fault === FaultType.user
      ? FaultType.member
      : serviceDetails.fault === FaultType.otherParty
      ? FaultType.other
      : serviceDetails.fault;

  const claimLineObject = claimLineInformation.fg_array.map(
    (claimLine: ClaimLineItem) => {
      const claimLinePayload: ClaimLinePayload = {
        service_from_date: claimLine?.dateServiceFrom,
        service_to_date: claimLine?.dateServiceTo,
        billed_amount: claimLine?.providerCharge,
      };

      if (claimLine?.placeOfService) {
        claimLinePayload.place_of_service_code = claimLine?.placeOfService;
      }

      if (claimLine?.units) {
        claimLinePayload.service_units = claimLine?.units;
      }

      if (claimLine?.modifierCode) {
        claimLinePayload.modifiers = [
          {
            value: claimLine?.modifierCode,
          },
        ];
      }

      if (claimLine?.procedureCode) {
        claimLinePayload.procedures = [
          {
            code: claimLine?.procedureCode,
          },
        ];
      }

      if (claimLine?.diagnosisCode) {
        claimLinePayload.diagnoses_information = [
          {
            diagnosis: {
              code: claimLine?.diagnosisCode,
            },
          },
        ];
      }

      if (claimType === MedicalCategory.dental) {
        claimLinePayload.additional_properties = [
          {
            category: DentalPayloadCategory.dentalClaimLineSubmission,
            property_dictionary: {
              area_of_mouth: claimLine?.areaOfMouth || '',
              tooth_number_or_letter: claimLine?.toothNumber || '',
              tooth_surface: claimLine?.toothOfSurface || '',
              frequency: claimLine?.frequency || '',
            },
          },
        ];
      }

      return claimLinePayload;
    }
  );

  const payload: ClaimSubmissionPayload = {
    submission_date: new DatePipe('en-US').transform(
      claimSavedDate,
      'YYYY-MM-dd'
    ),
    submission_status: submissionStatus,
    claim_detail: {
      claim_type: claimType,
      claim_subtype: ClaimSubType.member,
      policy_id: policyId,
      member_coverage_id:
        selectedPatient?.length > 0
          ? selectedPatient[memberCoverageIdIndex]
          : '',
      patient: {
        id: selectedPatient?.length > 0 ? selectedPatient[patientIdIndex] : '',
        name: {
          full_name:
            selectedPatient?.length > 0
              ? selectedPatient[patientFullNameIndex]
              : '',
        },
      },
      providers: [
        {
          name: providerInformation?.providerName,
          tax_id: providerInformation?.providerTaxId || '',
          npi: providerInformation?.providerNpiNumber || '',
          address: {
            street_name_1: providerInformation?.providerAddressLine1 || '',
            street_name_2: providerInformation?.providerAddressLine2 || '',
            city_name: providerInformation?.city || '',
            state: providerInformation?.state || '',
            zip_code: providerInformation?.zipCode || '',
          },
        },
      ],
      patient_accident_information: {
        accident_type: accidentType,
        date_of_accident: serviceDetails.accidentDate,
        fault_person: faultPerson,
      },
    },
    claim_lines: claimLineObject,
    contact_information: {
      name: {
        full_name: patientInformation.contactName || '',
      },
    },
  };

  if (patientInformation.contactPhoneNumber) {
    payload.contact_information.phone_numbers = [
      {
        number: patientInformation.contactPhoneNumber,
        phone_number: patientInformation.contactPhoneNumber,
        is_primary: true,
      },
    ];
  }

  if (documentIds.length > 0) {
    payload.claim_detail.document_ids = documentIds;
  }

  if (claimType === MedicalCategory.dental) {
    let requestType: string;

    if (serviceDetails.requestType === RequestTypePayload.dentalClaimData) {
      requestType = RequestTypePayload.dentalClaim;
    } else if (
      serviceDetails.requestType ===
      RequestTypePayload.estimateOfEligibleBenefitsData
    ) {
      requestType = RequestTypePayload.estimateOfEligibleBenefits;
    } else {
      requestType = serviceDetails.requestType || '';
    }

    payload.other_insurer_name = serviceDetails.insuranceName || '';
    payload.other_policy_number = serviceDetails.policyIdNumber || '';
    payload.other_policy_holder_name = serviceDetails.policyHolderName || '';
    payload.submission_request_type = requestType;
    payload.additional_properties = [
      {
        category: DentalPayloadCategory.dentalClaimSubmission,
        property_dictionary: {
          is_orthodontic_treatment_included:
            dentalTreatment.orthodonticTreatmentIncluded === yes ? true : false,
          expected_length_of_treatment:
            dentalTreatment.expectedLengthOfTreatment || '',
          total_charge_for_active_treatment:
            dentalTreatment.totalChargeForActiveTreatment || '',
          is_initial_treatment:
            dentalTreatment.initialTreatment === yes ? true : false,
          appliance_placement_date: dentalTreatment.dateOfAppliancePlaced || '',
          is_prosthesis_replacement_included:
            dentalTreatment.serviceIncludeReplacementOfProsthesis === 'Yes'
              ? true
              : false,
          prosthesis_replacement_reason:
            dentalTreatment.reasonForReplacement || '',
          prosthesis_replacement_reason_description:
            dentalTreatment.otherReasonForReplacement || '',
        },
      },
    ];
  }

  return payload;
};

export const updatePatientInformation = (
  data: SubmittedClaimDetails,
  settingForm?: boolean
) => ({
  patientName: settingForm
    ? `${data.claimDetail.patient.id}-${data.claimDetail.patient?.name?.fullName}-${data.claimDetail.memberCoverageId}`
    : data.claimDetail.patient?.name?.fullName,
  contactPhoneNumber: data.contactInformation.phoneNumbers.find(
    (val) => val.isPrimary
  )?.phoneNumber,
  contactName: data.contactInformation.name.fullName,
});

export const updateProviderInformation = (data: SubmittedClaimDetails) => {
  const provider = data.claimDetail.providers[0];

  return {
    providerName: provider.name,
    providerTaxId: provider.taxId,
    providerNpiNumber: provider.npi,
    providerAddressLine1: provider.address?.streetName1,
    providerAddressLine2: provider.address?.streetName2,
    city: provider.address?.cityName,
    state: provider.address?.state,
    zipCode: provider.address?.zipCode,
  };
};

export const updateDentalTreatment = (data: SubmittedClaimDetails) => {
  const dentalTreatmentDetail = {
    orthodonticTreatmentIncluded: data.additionalProperties[0]
      .propertyDictionary.isOrthodonticTreatmentIncluded
      ? yes
      : no,
    expectedLengthOfTreatment:
      data.additionalProperties[0].propertyDictionary.expectedLengthOfTreatment,
    totalChargeForActiveTreatment:
      data.additionalProperties[0].propertyDictionary
        .totalChargeForActiveTreatment,
    initialTreatment: data.additionalProperties[0].propertyDictionary
      .isInitialTreatment
      ? yes
      : no,
    dateOfAppliancePlaced:
      data.additionalProperties[0].propertyDictionary.appliancePlacementDate,
    serviceIncludeReplacementOfProsthesis: data.additionalProperties[0]
      .propertyDictionary.isProsthesisReplacementIncluded
      ? yes
      : no,
    reasonForReplacement:
      data.additionalProperties[0].propertyDictionary
        .prosthesisReplacementReason,
    otherReasonForReplacement:
      data.additionalProperties[0].propertyDictionary
        .prosthesisReplacementReasonDescription,
  };

  return dentalTreatmentDetail;
};

export const updateServiceDetails = (data: SubmittedClaimDetails) => {
  let serviceDetail;
  const patientAccidentInformation =
    data.claimDetail.patientAccidentInformation;

  let accidentType: string;
  switch (patientAccidentInformation.accidentType) {
    case AccidentType.autoAccident:
      accidentType = AccidentType.autoAccidentData;
      break;
    case AccidentType.workplaceAccident:
      accidentType = AccidentType.workRelatedAccident;
      break;
    case AccidentType.otherAccident:
      accidentType = AccidentType.accidentalInjury;
      break;
    default:
      accidentType = patientAccidentInformation.accidentType;
  }

  const faultPerson: string =
    patientAccidentInformation.faultPerson === FaultType.member
      ? FaultType.user
      : patientAccidentInformation.faultPerson === FaultType.other
      ? FaultType.otherParty
      : patientAccidentInformation.faultPerson;

  if (data.claimDetail.claimType === MedicalCategory.medical) {
    serviceDetail = {
      accidentDate: patientAccidentInformation.dateOfAccident,
      accidentType: accidentType,
      accidentalInjury: patientAccidentInformation?.dateOfAccident ? yes : no,
      fault: faultPerson,
    };
  } else {
    let requestType: string;
    if (data.submissionRequestType === RequestTypePayload.dentalClaim) {
      requestType = RequestTypePayload.dentalClaimData;
    } else if (
      data.submissionRequestType ===
      RequestTypePayload.estimateOfEligibleBenefits
    ) {
      requestType = RequestTypePayload.estimateOfEligibleBenefitsData;
    } else {
      requestType = data.submissionRequestType;
    }

    serviceDetail = {
      accidentDate: patientAccidentInformation.dateOfAccident,
      accidentType: accidentType,
      accidentalInjury: patientAccidentInformation?.dateOfAccident ? yes : no,
      fault: faultPerson,
      insuranceName: data.otherInsurerName,
      policyIdNumber: data.otherPolicyNumber,
      policyHolderName: data.otherPolicyHolderName,
      otherInsurance: data.otherPolicyNumber ? yes : no,
      requestType: requestType,
    };
  }

  return serviceDetail;
};

export const getMedicalClaimLineInformation = (
  data: SubmittedClaimDetails
) => ({
  fg_array: data.claimLines.map((claimLine) => ({
    dateServiceFrom: claimLine.serviceFromDate,
    dateServiceTo: claimLine.serviceToDate,
    placeOfService: claimLine.placeOfServiceCode,
    procedureCode: claimLine.procedures[0]?.code,
    modifierCode: claimLine.modifiers[0]?.value,
    diagnosisCode: claimLine.diagnosesInformation[0]?.diagnosis.code,
    units: claimLine.serviceUnits,
    providerCharge: claimLine.billedAmount,
  })),
});

export const getDentalClaimLineInformation = (data: SubmittedClaimDetails) => ({
  fg_array: data.claimLines.map((claimLine) => ({
    dateServiceFrom: claimLine.serviceFromDate,
    dateServiceTo: claimLine.serviceToDate,
    procedureCode: claimLine.procedures[0]?.code,
    areaOfMouth:
      claimLine.additionalProperties[0]?.propertyDictionary.areaOfMouth,
    toothNumber:
      claimLine.additionalProperties[0]?.propertyDictionary.toothNumberOrLetter,
    toothOfSurface:
      claimLine.additionalProperties[0]?.propertyDictionary.toothSurface,
    frequency: claimLine.additionalProperties[0]?.propertyDictionary.frequency,
    providerCharge: claimLine.billedAmount,
  })),
});

export const updateClaimLineInformation = (data: SubmittedClaimDetails) => {
  if (data.claimDetail.claimType === MedicalCategory.medical) {
    return getMedicalClaimLineInformation(data);
  } else {
    return getDentalClaimLineInformation(data);
  }
};

export const updateClaimContext = (
  key: string,
  data: SubmittedClaimDetails
) => {
  let contextValue = {};
  switch (key) {
    case ClaimSubmissionForms.patientInformation:
      contextValue = updatePatientInformation(data);
      break;
    case ClaimSubmissionForms.providerInformation:
      contextValue = updateProviderInformation(data);
      break;
    case ClaimSubmissionForms.serviceDetails:
      contextValue = updateServiceDetails(data);
      break;
    case ClaimSubmissionForms.claimLineInformation:
      contextValue = updateClaimLineInformation(data);
      break;
    case ClaimSubmissionForms.dentalTreatment:
      contextValue = updateDentalTreatment(data);
      break;
  }

  return contextValue;
};
