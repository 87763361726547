import { Pipe, PipeTransform } from '@angular/core';

/*
    Pipe that will format address in object format to a string format
    e.g. 509 Prospect West, Seattle WA 98102
 */
@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(address: Record<string, string>): string {
    if (!address) {
      return '';
    }

    const street = address['street_name_1']
      ? `${address['street_name_1']}, `
      : '';
    const city = address['city'] ? `${address['city']} ` : '';
    const state = address['state'] ? `${address['state']} ` : '';
    const zip = address['zip_code'] ? `${address['zip_code']} ` : '';

    return `${street}${city}${state}${zip}`;
  }
}
