<ng-container
  *ngIf="claimAppealSummary$ | async as claimAppealSummary; else loading"
>
  <p [innerHTML]="config.message"></p>
  <zip-table
    id="claimAppeals"
    (cellClicked)="navigateToClaimAppealDetail($event)"
    [options]="config.table"
    [data]="claimAppealSummary"
  ></zip-table>
</ng-container>
<ng-template #loading>
  <spinner [asOverlay]="false" size="small" flexCenter="true"></spinner>
</ng-template>
