import { Injectable } from '@angular/core';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { BehaviorSubject, Observable } from 'rxjs';
import { SiteLocation } from './site-locations-attestation.model';

@Injectable({
  providedIn: 'root',
})
export default class SiteLocationAttestationEventService {
  private selectedSiteLocations = new BehaviorSubject<SiteLocation[]>([]);
  public selectedSiteLocations$ = this.selectedSiteLocations as Observable<
    SiteLocation[]
  >;

  private dynamicButtonConfig = new BehaviorSubject<ButtonConfig>({});
  public dynamicButtonConfig$ = this
    .dynamicButtonConfig as Observable<ButtonConfig>;

  private dynamicButtonClickEvent = new BehaviorSubject<string>('');
  public dynamicButtonClickEvent$ = this
    .dynamicButtonClickEvent as Observable<String>;

  private _totalSiteLocationCount: number = 0;

  get totalSiteLocationCount(): number {
    return this._totalSiteLocationCount;
  }

  set totalSiteLocationCount(count: number) {
    this._totalSiteLocationCount = count;
  }

  public emitSelectedSiteLocations(locations: SiteLocation[]): void {
    this.selectedSiteLocations.next(locations);
  }

  public emitDynamicButtonConfig(config: {}): void {
    this.dynamicButtonConfig.next(config);
  }

  public emitDynamicButtonClickEvent(dynamicButtonType: string): void {
    this.dynamicButtonClickEvent.next(dynamicButtonType);
  }
}
