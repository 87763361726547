/**
 * @ignore
 */
function sendWarning(msg?: string) {
  if (msg) {
    console.warn(
      `${msg}.. Please consult https://cx-engine.readme.io/reference for more information`
    );
  }
}

/**
 * Initial data you can provide to the cx caller.
 * @optional
 */
export class CxCallerInitData {
  /**
   * This contains additional information relevant to the request.
   */
  context?: CxContext;

  /**
   * Event Name
   */
  name?: string;

  /**
   * Source System Names must be unique and configured for capture. This value will be provided to you by a Zipari
   * analyst.
   */
  source_system_name?: string;

  /**
   * If the user is authenticated. Flagging "True" indicates that a consumer ID is available.
   */
  authentication_flag?: boolean;

  constructor(config: CxCallerConfig, options: CxCallerInitData = {}) {
    Object.assign(this, options);
  }
}

/**
 * Configuration object for setting up an instance of the CX Caller
 */
export class CxCallerConfig {
  /**
   * Endpoint provided by zipari team
   */
  endpoint = '';

  /**
   * Custom messages used throughout the caller
   */
  messages?: CxCallerMessages;

  constructor(options: any = {}) {
    let configErr = false;

    // set up mechanism to customize messages...
    //  1) defaults messages if nothing is provided
    //  2) can customize each message individually
    //  3) also allows a user to pass in a string so that all messages are given string
    if (!('message' in options)) {
      options.messages = new CxCallerMessages({});
    } else if (typeof options.messages === 'string') {
      const messages = new CxCallerMessages({});

      Object.keys(messages).forEach((key) => {
        messages[key as keyof CxCallerMessages] = options.messages;
      });

      options.messages = messages;
    } else {
      options.messages = new CxCallerMessages(options.messages);
    }

    if (!options.endpoint) {
      sendWarning(options.messages.no_endpoint);
      configErr = true;
    }

    if (!configErr) {
      Object.assign(this, options);
      console.log(options.messages.successful_setup);
    } else {
      sendWarning(options.messages.unsuccessful_setup);
    }
  }
}

export class CxContextPage {
  /**
   * URL path, represented minus protocol and host
   */
  path?: string;

  /**
   * Full URL of the previous page, consisting of protocol + host + path
   */
  referrer?: string;

  /**
   * Query string portion of the URL of the page
   */
  search?: string;

  /**
   * Page Title
   */
  title?: string;

  /**
   * Full URL, consisting of protocol + host + path
   */
  url?: string;

  constructor(options = {}) {
    Object.assign(this, options);
  }
}

export class CxContextDevice {
  /**
   * Device ID
   */
  id?: string;

  /**
   * Device Manufacturer
   */
  model?: string;

  /**
   * Model of Device
   */
  manufacturer?: string;

  /**
   * Device Name
   */
  name?: string;

  /**
   * The type of device - phone, tablet, web, etc.
   */
  type?: string;

  /**
   * A unique key for the app/device combination
   */
  token?: string;

  constructor(options = {}) {
    Object.assign(this, options);
  }
}

export class CxContextApp {
  /**
   * Application Name - IE, ZIpari Member Portal
   */
  name?: string;

  /**
   * Application version (x.x)
   */
  version?: string;

  /**
   * Application version with build info (x.x.x or x.x.x.x)
   */
  build?: string;

  constructor(options = {}) {
    Object.assign(this, options);
  }
}

export class CxContextLocation {
  /**
   * City name
   */
  city?: string;

  /**
   * Country Name
   */
  country?: string;

  /**
   * Latitude
   */
  latitude?: string;

  /**
   * Longitude
   */
  longitude?: string;

  constructor(options = {}) {
    Object.assign(this, options);
  }
}

export class CxContextNetwork {
  /**
   * If the device is connected to bluetooth
   */
  bluetooth?: boolean;

  /**
   * Network carrier name
   */
  carrier?: string;

  /**
   * If the device is connected to a cellular network
   */
  cellular?: boolean;

  /**
   * If the device is connecting through WiFi
   */
  wifi?: boolean;

  constructor(options = {}) {
    Object.assign(this, options);
  }
}

export class CxContextOS {
  /**
   * OS name
   */
  name?: string;

  /**
   * OS version number
   */
  version?: string;

  constructor(options = {}) {
    Object.assign(this, options);
  }
}

export class CxContextReferrer {
  /**
   * Payer tracking ID for referrer URL
   */
  id?: string;

  /**
   * Referrer Name
   */
  type?: string;

  constructor(options = {}) {
    Object.assign(this, options);
  }
}

/**
 * This contains additional information relevant to the request.
 */
export class CxContext {
  /**
   * IP address of the current user.
   */
  ip_address?: string;

  /**
   * Dictionary of information about the page currently being viewed by the user
   */
  page?: CxContextPage;

  /**
   * Dictionary of information describing the device the user is using to log into the application.
   */
  device?: CxContextDevice;

  /**
   * Dictionary with app and feature level information.
   */
  app?: CxContextApp;

  /**
   * Dictionary with information available about the user's geographical location.
   */
  location?: CxContextLocation;

  /**
   * Dictionary with information about the user's network.
   */
  network?: CxContextNetwork;

  /**
   * Dictionary with information about the user's operating system.
   */
  os?: CxContextOS;

  /**
   * Dictionary with information about where the user came from when they were referred to the current page - be it
   * another site or application.
   */
  referrer?: CxContextReferrer;

  /**
   * Timestamp when the event took place. Defaults to server time if not passed. Date format is ISO-8601
   */
  timestamp?: Date | string | number;

  /**
   * String representing the timezone from which the user is accessing the current site.
   */
  timezone?: string;

  constructor(config: CxCallerConfig, options: CxContext) {
    if (
      options.timestamp &&
      (typeof options.timestamp === 'string' ||
        typeof options.timestamp === 'number')
    ) {
      options.timestamp = new Date();
    }

    if (options.page) {
      options.page = new CxContextPage(options.page);
    }

    if (options.device) {
      options.device = new CxContextDevice(options.device);
    }

    if (options.app) {
      options.app = new CxContextApp(options.app);
    }

    if (options.location) {
      options.location = new CxContextLocation(options.location);
    }

    if (options.network) {
      options.network = new CxContextNetwork(options.network);
    }

    if (options.os) {
      options.os = new CxContextOS(options.os);
    }

    if (options.referrer) {
      options.referrer = new CxContextReferrer(options.referrer);
    }

    Object.assign(this, options);
  }
}

/**
 * The body of each dispatchCXEvent call
 */
export class CxCallBody {
  /**
   * Consumer ID - such as member ID, agent ID, etc.
   */
  consumer_id?: string | number;

  /**
   * Consumer ID Hash - such as member ID, agent ID, etc. with SHA256 encryption
   */
  consumer_id_hash?: string | number;

  /**
   * Source System Names must be unique and configured for capture. This value will be provided to you by a Zipari
   * analyst.
   */
  source_system_name?: string;

  /**
   * Payer-configurable transaction ID, used for tracking data throughout the system.
   */
  transaction_id?: string | number;

  /**
   * Contains a user's session ID if browsing an online application
   */
  session_id?: string | number;

  /**
   * If the user is authenticated. Flagging "True" indicates that a consumer ID is available.
   */
  authentication_flag = false;

  /**
   * The consumer recommendation ID to be updated by this event
   */
  consumer_recommendation_id?: string | number;

  /**
   * Event Name
   */
  name?: string;

  /**
   * Contains a list of data keys that can be used as additional parameters for the event
   */
  dictionary_attributes?: Record<string, never> = {};

  /**
   * This contains additional information relevant to the request.
   */
  context?: CxContext;

  constructor(
    config: CxCallerConfig,
    options: CxCallBody,
    previous_data: CxCallerInitData = {}
  ) {
    if (options) {
      let configErr = false;

      if (
        !('source_system_name' in options) &&
        !('source_system_name' in previous_data)
      ) {
        sendWarning(config.messages?.system_name_not_provided);

        configErr = true;
      } else {
        options.source_system_name =
          options.source_system_name || previous_data.source_system_name;
      }

      if (!('name' in options) && !('name' in previous_data)) {
        sendWarning(config.messages?.name_not_provided);
        configErr = true;
      } else {
        options.name = options.name || previous_data.name;
      }

      if (
        !('authentication_flag' in options) &&
        !('authentication_flag' in previous_data)
      ) {
        sendWarning(config.messages?.authentication_flag_not_provided);
        configErr = true;
      } else {
        // TODO: this can be simplified to options?.authentication_flag || previous_data.authentication_flag || false
        //  unless there is some reason to use not to?
        options.authentication_flag =
          'authentication_flag' in options
            ? options.authentication_flag
            : 'authentication_flag' in options
            ? !!previous_data.authentication_flag
            : !!previous_data.authentication_flag;
      }

      if (options.context) {
        options.context = new CxContext(config, options.context);
      }

      if (options.consumer_id && typeof options.consumer_id === 'number') {
        options.consumer_id = options.consumer_id.toString();
      }

      if (options.session_id && typeof options.session_id === 'number') {
        options.session_id = options.session_id.toString();
      }

      if (
        options.consumer_recommendation_id &&
        typeof options.consumer_recommendation_id === 'number'
      ) {
        options.consumer_recommendation_id =
          options.consumer_recommendation_id.toString();
      }

      if (
        options.transaction_id &&
        typeof options.transaction_id === 'number'
      ) {
        options.transaction_id = options.transaction_id.toString();
      }

      if (!configErr) {
        Object.assign(this, options);
      }
    } else {
      sendWarning(config.messages?.no_body_provided);
    }
  }
}

/**
 * Callbacks you can provide to occur on success and failure of all cx calls
 */
export class CxCallbacks {
  success = () => void 0;
  failure = () => void 0;

  constructor(options: CxCallbacks) {
    Object.assign(this, options || {});
  }
}

/**
 * Custom messages you can use to override messages.. change any key to null or empty string to not have it console.warn
 */
export class CxCallerMessages {
  no_body_provided?: string = 'Body of CX call not provided';
  system_name_not_provided?: string = 'System name not provided';
  name_not_provided?: string = 'Name not provided';
  authentication_flag_not_provided?: string =
    'Authentication flag not provided';
  unsuccessful_setup?: string = 'Configuration for CX failed';
  successful_setup?: string = 'Configuration for CX succeeded';
  no_endpoint?: string =
    'Configuration for CX failed because an endpoint was not provided';

  constructor(options: CxCallerMessages) {
    Object.assign(this, options || {});
  }
}
