import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ButtonModule, TableModule } from '@zipari/design-system';

import { AddNewModalModule } from '../../../shared/modules/add-row-modal/add-new-modal.module';
import { ZipBusyModule } from '../../../shared/modules/zip-busy';
import { ListConfig } from '../../../shared/templates/list/list.module';
import {
  LIST_SERVICE,
  ListService,
} from '../../../shared/templates/list/list.service';

import { ProviderGroupsComponent } from './provider-groups.component';

@NgModule({
  declarations: [ProviderGroupsComponent],
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    ZipBusyModule,
    AddNewModalModule,
  ],
  exports: [ProviderGroupsComponent],
})
export class ProviderGroupsModule {
  static forRoot(
    listConfig?: ListConfig
  ): ModuleWithProviders<ProviderGroupsModule> {
    return {
      ngModule: ProviderGroupsModule,
      providers: [
        {
          provide: LIST_SERVICE,
          useClass: (listConfig && listConfig.listService) || ListService,
        },
      ],
    };
  }
}
