<div class="u-flex">
  <form-control
    [control]="group.controls[passwordConfig.prop]"
    [group]="group"
    [config]="passwordConfig"
  >
  </form-control>

  <form-control
    [control]="group.controls[confirmPasswordConfig.prop]"
    [config]="confirmPasswordConfig"
    [group]="group"
  >
  </form-control>
</div>
