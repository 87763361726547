export function checkFileTypeRestriction(
  file: File,
  fileTypes: string
): boolean {
  if (fileTypes) {
    const types = fileTypes.split(',');
    const typeFound = types.find((type) => file.type === type);

    return !!typeFound;
  }

  return true;
}

function bytes2mb(bytes: number): number {
  const conversion = 0.000001;

  return bytes * conversion;
}

// takes in file size in mb
export function checkFileSizeRestriction(
  file: File,
  fileSizeRestriction: number
): boolean {
  if (fileSizeRestriction) {
    const fileSize = file.size;
    const mbSize = bytes2mb(fileSize);

    return mbSize <= fileSizeRestriction;
  }

  return true;
}
