<section>
  <div>
    <h3 class="manage-portal-users_header-form-text t-bold">
      {{ config.headerText }}
    </h3>
    <div class="manage-portal-users_header-descriptive-text">
      {{ config.descriptiveText }}
    </div>

    <div *ngIf="cantPost" class="manage-portal-users_modal">
      <div class="manage-portal-users_modal-header">
        <icon
          class="icon--error manage-portal-users_modal_justify-left"
          name="error"
          [config]="config.iconConfigs.errorIcon"
        ></icon>
        <icon
          class="cancelModal manage-portal-users_modal_justify-right"
          name="close"
          (click)="cancelModal()"
          [config]="config.iconConfigs.closeIcon"
        >
        </icon>
      </div>
      <div class="manage-portal-users_modal-message">
        {{ postMessage }}
      </div>

      <zip-button
        class="manage-portal-users_modal-button"
        [config]="config.buttons.modalCloseButton"
        (zipButtonClicked)="updateExistingUserGroups()"
      ></zip-button>
    </div>
  </div>
  <div class="manage-portal-users-create-user__card">
    <div body>
      <div title class="manage-portal-users-create-user__header-container">
        <h5
          class="manage-portal-users__header --typeface--1 t-bold --h5-font-size --h5-font-height"
        >
          {{ config.cards[index].cardHeader }}
        </h5>
      </div>
      <ng-container *ngFor="let control of (config?.cards)[index].formConfig">
        <ng-container
          *ngIf="
            control.type != 'multiple_group_selection';
            else selectMultipleGroup
          "
        >
          <form-control
            *ngIf="!control?.width"
            [group]="newUserFormGroup"
            [config]="control"
            [control]="newUserFormGroup.get(control.prop)"
          >
          </form-control>
          <div
            *ngIf="control.width"
            class="Form__Control--Width"
            [ngStyle]="{
              width: control.width,
              'margin-right': control.order === 1 ? '2%' : '0%'
            }"
          >
            <form-control
              [group]="newUserFormGroup"
              [config]="control"
              [control]="newUserFormGroup.get(control.prop)"
            >
            </form-control>
          </div>
        </ng-container>

        <ng-template #selectMultipleGroup>
          <add-multiple-groups
            [control]="control"
            [group]="newUserFormGroup"
            [config]="config?.addMultipleGroupConfig"
          ></add-multiple-groups>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div class="manage-portal-users__action-buttons-container">
    <div class="manage-portal-users__action-buttons-left">
      <zip-button
        class="manage-portal-users__manage-access-button inline-btn"
        [config]="config.buttons.cancelButton"
        (zipButtonClicked)="cancelButtonClick()"
      >
      </zip-button>
      <p
        class="t-data t-link prev-page-link"
        *ngIf="index > 0"
        (click)="prevStep()"
      >
        {{ config.buttons.link.label }}
      </p>
    </div>
    <div class="manage-portal-users__action-buttons-right">
      <zip-button
        *ngIf="config.buttons.nextButton && index < lengthOfCard - 1"
        class="manage-portal-users__submit-button"
        [config]="config.buttons.nextButton"
        (zipButtonClicked)="nextButtonClick()"
      >
      </zip-button>
      <zip-button
        *ngIf="config.buttons.submitButton && index === lengthOfCard - 1"
        class="manage-portal-users__submit-button"
        [config]="config.buttons.submitButton"
        (zipButtonClicked)="submitButtonClick()"
      >
      </zip-button>
      <zip-button
        *ngIf="config.buttons.manageAccessButton"
        class="manage-portal-users__manage-access-button"
        [config]="config.buttons.manageAccessButton"
        (zipButtonClicked)="manageAccessButtonClick()"
      >
      </zip-button>
    </div>
  </div>
</section>

<api-error-message-modal
  [modalConfig]="config?.errorModal?.modalConfig"
  [buttonConfig]="config?.errorModal?.submitButton"
  [showModal]="showErrorModal"
  [errorMessage]="errorMessage"
  (submitButtonClicked)="errorModalSubmitClicked()"
></api-error-message-modal>
