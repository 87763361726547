import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';

import { FormControlService } from '../../../../notes/form-control/form-control.service';
import { AddressService } from '../../../../shared/services/address.service';

import { getValue } from '../../../../shared/utils/get-value';
import { BaseAddressDropdownComponent } from './../base-address-dropdown.component';

@Component({
  selector: 'counties',
  templateUrl: './counties.component.html',
  styleUrls: ['./counties.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => CountiesComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => CountiesComponent),
      multi: true,
    },
  ],
})
export class CountiesComponent
  extends BaseAddressDropdownComponent
  implements OnInit, OnDestroy, Validator
{
  @Input() group;
  @Input() countyConfig;

  constructor(
    formControlService: FormControlService,
    addressService: AddressService
  ) {
    super(addressService, formControlService);
  }

  ngOnInit() {
    this.initCountyOption();
    this.baseInit(this.group, this.countyConfig, 'county');
  }

  /*
        If persisted value - create dropdown option for display
        This avoids lag when waiting for initial API response
        Set before form control && placeholder initialization to avoid lifecycle errors
    */
  initCountyOption() {
    const value = getValue(this.group.value, 'county');
    if (value) {
      this.countyConfig.options = [
        {
          label: value.county_name,
          value: value.county_code,
        },
      ];
    }
  }

  //  Propagate invalidity to parent form group
  public validate(control): ValidationErrors {
    if (control.controls) {
      return this.formGroup.valid ? null : [{ county: true }];
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
