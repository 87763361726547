import { Injectable } from '@angular/core';
import { ButtonConfig } from '@zipari/shared-ds-util-button';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export default class SuspendUserEventService {
  private dynamicButtonConfig = new BehaviorSubject<ButtonConfig>({});
  public dynamicButtonConfig$ = this
    .dynamicButtonConfig as Observable<ButtonConfig>;

  public emitDynamicButtonConfig(config: {}): void {
    this.dynamicButtonConfig.next(config);
  }
}
