import { FocusableOption } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'accessible-option',
  template:
    '<li class="form-control__menu__option" (click)="onClick()" [innerHTML]="option.label"></li>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionComponent implements FocusableOption {
  /**
   * current typeahead dropdown option
   */
  @Input() option;

  /**
   * option selection event
   */
  @Output() selected = new EventEmitter<any>();

  constructor(private host: ElementRef) {}

  /**
   * handle keyboard option select
   */
  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.selected.emit(this.option);
    }
  }

  focus() {
    this.host.nativeElement.focus();
  }

  /**
   * emit option when clicked
   */
  public onClick() {
    this.selected.emit(this.option);
  }
}
