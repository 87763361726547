<!-- TODO: refactor-->
<div [ngStyle]="{ display: 'flex', 'justify-content': 'space-between' }">
  <div>
    <p class="t-data t-bold" ng-reflect-ng-class="[object Object]">Monday</p>
  </div>
  <div class="form-control form-control--dropdown hours">
    <select
      role="listbox"
      (change)="changeSuit('monday', $event)"
      [(ngModel)]="_monday"
    >
      <option *ngFor="let c of options">{{ c }}</option>
    </select>
    <div class="u-flex u-alignCenter u-justifyEnd u-fillRemaining">
      <icon
        [config]="{ format: 'svg', size: 'small' }"
        [name]="config?.icon || 'carat_down'"
        class="dropdown__chevron"
      ></icon>
    </div>
  </div>
</div>
<form-group
  *ngIf="show_monday"
  [configs]="monday"
  [form]="location_hours"
  [context]="context"
>
</form-group>
<div *ngIf="error_monday" class="error">
  <icon name="warning" class="error_sub">
    <i class="icon icon--font ng-star-inserted icon--small" style="color: red">
      warning
    </i>
  </icon>
  <p class="t-caption t-error">
    {{ errorMessage || 'Please enter a valid input.' }}
  </p>
</div>
<div [ngStyle]="{ display: 'flex', 'justify-content': 'space-between' }">
  <div>
    <p class="t-data t-bold" ng-reflect-ng-class="[object Object]">Tuesday</p>
  </div>
  <div class="form-control form-control--dropdown hours">
    <select
      role="listbox"
      (change)="changeSuit('tuesday', $event)"
      [(ngModel)]="_tuesday"
    >
      <option *ngFor="let c of options">{{ c }}</option>
    </select>
    <div class="u-flex u-alignCenter u-justifyEnd u-fillRemaining">
      <icon
        [config]="{ format: 'svg', size: 'small' }"
        [name]="config?.icon || 'carat_down'"
        class="dropdown__chevron"
      ></icon>
    </div>
  </div>
</div>
<form-group
  *ngIf="show_tuesday"
  [configs]="tuesday"
  [form]="location_hours"
  [context]="context"
>
</form-group>
<div *ngIf="error_tuesday" class="error">
  <icon name="warning" class="error_sub">
    <i class="icon icon--font ng-star-inserted icon--small" style="color: red">
      warning
    </i>
  </icon>
  <p class="t-caption t-error">
    {{ errorMessage || 'Please enter a valid input.' }}
  </p>
</div>

<div [ngStyle]="{ display: 'flex', 'justify-content': 'space-between' }">
  <div>
    <p class="t-data t-bold" ng-reflect-ng-class="[object Object]">Wednesday</p>
  </div>
  <div class="form-control form-control--dropdown hours">
    <select
      role="listbox"
      (change)="changeSuit('wednesday', $event)"
      [(ngModel)]="_wednesday"
    >
      <option *ngFor="let c of options">{{ c }}</option>
    </select>
    <div class="u-flex u-alignCenter u-justifyEnd u-fillRemaining">
      <icon
        [config]="{ format: 'svg', size: 'small' }"
        [name]="config?.icon || 'carat_down'"
        class="dropdown__chevron"
      ></icon>
    </div>
  </div>
</div>
<form-group
  *ngIf="show_wednesday"
  [configs]="wednesday"
  [form]="location_hours"
  [context]="context"
>
</form-group>
<div *ngIf="error_wednesday" class="error">
  <icon name="warning" class="error_sub">
    <i class="icon icon--font ng-star-inserted icon--small" style="color: red">
      warning
    </i>
  </icon>
  <p class="t-caption t-error">
    {{ errorMessage || 'Please enter a valid input.' }}
  </p>
</div>

<div [ngStyle]="{ display: 'flex', 'justify-content': 'space-between' }">
  <div>
    <p class="t-data t-bold" ng-reflect-ng-class="[object Object]">Thursday</p>
  </div>
  <div class="form-control form-control--dropdown hours">
    <select
      role="listbox"
      (change)="changeSuit('thursday', $event)"
      [(ngModel)]="_thursday"
    >
      <option *ngFor="let c of options">{{ c }}</option>
    </select>
    <div class="u-flex u-alignCenter u-justifyEnd u-fillRemaining">
      <icon
        [config]="{ format: 'svg', size: 'small' }"
        [name]="config?.icon || 'carat_down'"
        class="dropdown__chevron"
      ></icon>
    </div>
  </div>
</div>
<form-group
  *ngIf="show_thursday"
  [configs]="thursday"
  [form]="location_hours"
  [context]="context"
>
</form-group>
<div *ngIf="error_thursday" class="error">
  <icon name="warning" class="error_sub">
    <i class="icon icon--font ng-star-inserted icon--small" style="color: red">
      warning
    </i>
  </icon>
  <p class="t-caption t-error">
    {{ errorMessage || 'Please enter a valid input.' }}
  </p>
</div>

<div [ngStyle]="{ display: 'flex', 'justify-content': 'space-between' }">
  <div>
    <p class="t-data t-bold" ng-reflect-ng-class="[object Object]">Friday</p>
  </div>
  <div class="form-control form-control--dropdown hours">
    <select
      role="listbox"
      (change)="changeSuit('friday', $event)"
      [(ngModel)]="_friday"
    >
      <option *ngFor="let c of options">{{ c }}</option>
    </select>
    <div class="u-flex u-alignCenter u-justifyEnd u-fillRemaining">
      <icon
        [config]="{ format: 'svg', size: 'small' }"
        [name]="config?.icon || 'carat_down'"
        class="dropdown__chevron"
      ></icon>
    </div>
  </div>
</div>
<form-group
  *ngIf="show_friday"
  [configs]="friday"
  [form]="location_hours"
  [context]="context"
>
</form-group>
<div *ngIf="error_friday" class="error">
  <icon name="warning" class="error_sub">
    <i class="icon icon--font ng-star-inserted icon--small" style="color: red">
      warning
    </i>
  </icon>
  <p class="t-caption t-error">
    {{ errorMessage || 'Please enter a valid input.' }}
  </p>
</div>

<div [ngStyle]="{ display: 'flex', 'justify-content': 'space-between' }">
  <div>
    <p class="t-data t-bold" ng-reflect-ng-class="[object Object]">Saturday</p>
  </div>
  <div class="form-control form-control--dropdown hours">
    <select
      role="listbox"
      (change)="changeSuit('saturday', $event)"
      [(ngModel)]="_saturday"
    >
      <option *ngFor="let c of options">{{ c }}</option>
    </select>
    <div class="u-flex u-alignCenter u-justifyEnd u-fillRemaining">
      <icon
        [config]="{ format: 'svg', size: 'small' }"
        [name]="config?.icon || 'carat_down'"
        class="dropdown__chevron"
      ></icon>
    </div>
  </div>
</div>
<form-group
  *ngIf="show_saturday"
  [configs]="saturday"
  [form]="location_hours"
  [context]="context"
>
</form-group>
<div *ngIf="error_saturday" class="error">
  <icon name="warning" class="error_sub">
    <i class="icon icon--font ng-star-inserted icon--small" style="color: red">
      warning
    </i>
  </icon>
  <p class="t-caption t-error">
    {{ errorMessage || 'Please enter a valid input.' }}
  </p>
</div>

<div [ngStyle]="{ display: 'flex', 'justify-content': 'space-between' }">
  <div>
    <p class="t-data t-bold" ng-reflect-ng-class="[object Object]">Sunday</p>
  </div>
  <div class="form-control form-control--dropdown hours">
    <select
      role="listbox"
      (change)="changeSuit('sunday', $event)"
      [(ngModel)]="_sunday"
    >
      <option *ngFor="let c of options">{{ c }}</option>
    </select>
    <div class="u-flex u-alignCenter u-justifyEnd u-fillRemaining">
      <icon
        [config]="{ format: 'svg', size: 'small' }"
        [name]="config?.icon || 'carat_down'"
        class="dropdown__chevron"
      ></icon>
    </div>
  </div>
</div>
<form-group
  *ngIf="show_sunday"
  [configs]="sunday"
  [form]="location_hours"
  [context]="context"
>
</form-group>
<div *ngIf="error_sunday" class="error">
  <icon name="warning" class="error_sub">
    <i class="icon icon--font ng-star-inserted icon--small" style="color: red">
      warning
    </i>
  </icon>
  <p class="t-caption t-error">
    {{ errorMessage || 'Please enter a valid input.' }}
  </p>
</div>
