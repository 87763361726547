export const productIconLigatures = [
  'children_health',
  'contract_violation',
  'medicaid',
  'medical_assistance',
  'tax_credit',
  'birth_adoption',
  'divorce',
  'lost_dependent',
  'marriage',
  'court',
  'domestic_abuse',
  'qle_error',
  'lost_coverage',
  'lost_employment',
  'moving',
  'calculator',
  'csr',
  'email',
  'folder',
  'heart',
  'id_card',
  'video',
  'wellness',
  'add_new',
  'announcement',
  'care',
  'change_request',
  'checklist_empty',
  'checklist_fail',
  'checklist_mixed',
  'checklist_pass',
  'clipboard',
  'clock',
  'contact',
  'costs',
  'cover_who',
  'coverage_lost',
  'coverage',
  'credentials',
  'delete',
  'edit',
  'file',
  'hospital',
  'multiple_people',
  'none',
  'other',
  'payment',
  'permissions',
  'prescriptions',
  'rx',
  'profile',
  'security',
  'tax_id',
  'npi',
];
