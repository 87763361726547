<zip-button
  *ngIf="!config.showDuplicateAction && !!config.addDuplicateButton"
  [ngClass]="{
    FormGroupArrayAccordionFooterContent__AddButton:
      config?.showFormWithAccordion
  }"
  [config]="config.addDuplicateButton"
  (zipButtonClicked)="addDuplicateForm(formArray.value, index)"
  [disabled]="isFormDisable()"
  data-test-id="duplicate-action-btn"
>
</zip-button>
