import { UntypedFormArray } from '@angular/forms';

export const errorDuration = 3000;

export class FileRestrictionMessages {
  fileTypes = 'Provided file does not meet file type requirements.';
  fileSizeLimit = 'Provided file does not meet file size requirements.';

  constructor(options?) {
    Object.assign(this, options);
  }
}

export class FileRestrictions {
  fileTypes: string;

  // in mb
  fileSizeLimit: number;
  fileSizeWarning: string;

  fileAttachmentLimit: number;

  acceptedTypes: string[];

  messages: FileRestrictionMessages;

  constructor(options: FileRestrictions) {
    Object.assign(this, options);
    this.messages = new FileRestrictionMessages(
      options && options.messages ? options.messages : {}
    );
  }
}

export const defaultResponseErrorMessage =
  'There was an error with your request. Please wait a moment and try again.';

export interface FileUploadInputs {
  disabled: boolean;
  cancel: boolean;
  progress: number | string;
  fileAttachment: UntypedFormArray;
  showCloseIcon: boolean;
  uploadErrorMessage: string;
}
