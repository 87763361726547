import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MainComponent } from './main/main.component';
import { MainModule } from './main/main.module';
import { SidebarMainContainerComponent } from './sidebar-main-container.component';
import { SidebarComponent } from './sidebar/sidebar.component';

import { SidebarModule } from './sidebar/sidebar.module';

@NgModule({
  declarations: [SidebarMainContainerComponent],
  imports: [CommonModule, SidebarModule, MainModule],
  exports: [SidebarMainContainerComponent, SidebarComponent, MainComponent],
})
export class SidebarMainContainerModule {}
