import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import { FormControlService } from '@zipari/design-system';

import { ErrorModel } from '../../../shared/models/Errors.model';
import { ConfigService } from '../../../shared/services';
import { objectToArray } from '../../../shared/utilities/object';

export class CreateGroupPayload {
  name: string;
  category: string;
  external_id?: number | string;
  parent?: number | string;
  tenant?: number | string;
}

@Component({
  selector: 'provider-group-create',
  templateUrl: './provider-group-create.component.html',
  styleUrls: ['./provider-group-create.component.scss'],
})
export class ProviderGroupCreateComponent implements OnInit {
  config;
  newGroupFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  cantPost: boolean;
  postMessage: any;
  formBuilt: boolean;
  groupCreated: boolean = false;
  newGroupData;
  activeStep: any;
  activeStepIdx: number = 0;
  errors: any[];
  showErrorModal: boolean;

  constructor(
    private configService: ConfigService,
    private formControlService: FormControlService,
    private router: Router,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.config = this.configService.getPageConfig('provider-group-create');
    if (this.config && this.config?.steps) {
      this.config.steps = objectToArray(this.config?.steps);
      this.config?.steps.forEach((step: any) => {
        step.cards = objectToArray(step?.cards);
        step?.cards.forEach((card: any) => {
          if (card.dataConfig) {
            card.dataConfig = objectToArray(card?.dataConfig);
          }
        });
        if (step.badges) {
          step.badges = objectToArray(step?.badges);
        }
      });
      this.activeStep = this.config?.steps[this.activeStepIdx];
    }
    this.buildForm();
  }

  iconClicked(): void {
    this.activeStepIdx -= 1;
    this.activeStep = this.config?.steps[this.activeStepIdx];
  }

  buttonClicked(target): void {
    switch (target.action) {
      case 'nextStep':
        this.activeStepIdx += 1;
        if (this.config && this.config.steps)
          this.activeStep = this.config?.steps[this.activeStepIdx];

        return;
      case 'prevStep':
        this.activeStepIdx -= 1;
        if (this.config && this.config.steps)
          this.activeStep = this.config?.steps[this.activeStepIdx];

        return;
      case 'toParentPage':
        this.router.navigate(['provider-portal/payer-admin/provider-groups']);

        return;
      case 'submit':
        this.submitForm();

        return;
    }
  }

  submitForm(): void {
    const payload = {
      ...this.newGroupFormGroup.value,
      tenant: this.configService.tenant,
    };
    this.http.post('/api/user/permissions/group/', payload).subscribe(
      (resp: any) => {
        this.newGroupData = resp;
        this.groupCreated = true;
        this.activeStepIdx += 1;
        this.activeStep = this.config?.steps[this.activeStepIdx];
      },
      (err: ErrorModel) => {
        this.showErrorModal = true;
        this.errors = err?.error?.errors[0].user_error.non_field_errors;
      }
    );
  }

  closeModal(): void {
    this.showErrorModal = false;
  }

  print(): void {
    window.print();
  }

  buildForm(): void {
    if (this.config && this.config['cards']) {
      Object.values(this.config['cards']).forEach((card: any) => {
        Object.values(card['formConfig']).forEach((controlConfig: any) => {
          const control: AbstractControl = new UntypedFormControl('', []);
          const newFormControl: AbstractControl = Object.assign(controlConfig, {
            control: control,
          });
          this.formControlService.addControlToFormGroup(
            this.newGroupFormGroup,
            newFormControl
          );
        });
      });
    }
    this.formBuilt = true;
  }

  getVal(data: object, attr: string): string {
    return data[attr];
  }
}
